import { Injectable } from '@angular/core';
import * as moment from 'moment';
import ReportFavorite = cad.ReportFavorite;
import {RouterService} from 'cad/core/services/router/router.service';
import {ScheduleAndViewReportApi} from 'cad/common/services/api/workflow/schedule-and-view-report/schedule-and-view-report';
import {AlertsService} from 'cad/core/services/alerts/alerts.service';
import { cloneDeep } from 'lodash';


@Injectable()
export class WorkflowFavService {

  constructor(
    private scheduleAndViewReportApi: ScheduleAndViewReportApi,
    private routerService: RouterService,
    private alertsService: AlertsService) {
  }

  /**
   * Take the user to the new Report Screen and copy this object into it.
   * @param reportIn
   */
  public goToReport(reportIn: ReportFavorite): void {
    let report: ReportFavorite = _.cloneDeep(reportIn);
    this.fixReportDates(report);

    this.navigateWithData(report, [ 'reports','schedule-and-view-reports', 'new' ]);
  }

  /**
   * Run this report and put a message/link to it in the alerts
   * @param reportIn
   */
  public runReport(reportIn: ReportFavorite): void {
    let report: ReportFavorite = _.cloneDeep(reportIn);
    this.fixReportDates(report);

    this.scheduleAndViewReportApi.save(report).subscribe((rv) => {
      this.alertsService.info(`${report.name} scheduled.  You may view this in the reportsScreen.`, null, false,
        `#/reports/schedule-and-view-reports/${rv.validatedObject.workflowExecutionRequestId}/details`, 'View Report');
    }, (error) => {
      this.alertsService.danger(`Could not run report ${report.name} because ${error}`);
    });
  }

  /**
   * Run this report and go to the result
   * @param reportIn
   */
  public runReportAndGo(reportIn: ReportFavorite): void {
    let report: ReportFavorite = _.cloneDeep(reportIn);
    this.fixReportDates(report);

    this.scheduleAndViewReportApi.save(report).subscribe((rv) => {
      this.navigateWithData(report, [ 'reports','schedule-and-view-reports', rv.validatedObject.workflowExecutionRequestId, 'details' ]);
    }, (error) => {
      this.alertsService.danger(`Could not run report ${report.name} because ${error}`);
    });
  }

  /**
   * Iterate over the favorites and set the paramSummary on each.
   * @param favs
   */
  public abbreviateParametersOnFavs(favs: ReportFavorite[]): ReportFavorite[] {
    let abbrFavs = cloneDeep(favs);
    abbrFavs.forEach((item) => {
      item = this.abbreviateParametersNoMut(item);
    });
    return abbrFavs;
  }

  /**
   * Create the paramSummary for the favorite.
   * @param fav
   */
  public abbreviateParameters(fav: ReportFavorite): void {
    fav.paramSummary = '';
    fav.parameterList.forEach((param) => {
      fav.paramSummary = this.appendValues(fav.paramSummary, `${param.name}: ${param.simpleValues[0]}`);
    });

    if (fav.emailNotifRequired) {
      fav.paramSummary = this.appendValues(fav.paramSummary, `EmailTo: ${fav.notifEmailAddresses}`);
    }
    if (fav.autoPrintRequested) {
      fav.paramSummary = this.appendValues(fav.paramSummary, `PrintTo: ${fav.printServer}`);
    }
  }

  public abbreviateParametersNoMut(fav: ReportFavorite): ReportFavorite {
    let abbrFav: ReportFavorite = cloneDeep(fav);
    abbrFav.paramSummary = '';
    abbrFav.parameterList.forEach((param) => {
      abbrFav.paramSummary = this.appendValues(abbrFav.paramSummary, `${param.name}: ${param.simpleValues[0]}`);
    });
    if (abbrFav.emailNotifRequired) {
      abbrFav.paramSummary = this.appendValues(abbrFav.paramSummary, `EmailTo: ${abbrFav.notifEmailAddresses}`);
    }
    if (abbrFav.autoPrintRequested) {
      abbrFav.paramSummary = this.appendValues(abbrFav.paramSummary, `PrintTo: ${abbrFav.printServer}`);
    }
    return abbrFav;
  }

  private appendValues(orig: string, values: string): string {
    return orig ? orig + `; ${values}` : `${values}`;
  }


  private fixReportDates(report: ReportFavorite): void {
    switch (report.dateAugmentor) {
      case 'CURRENT_MONTH': {
        this.updateDatesToCurrentMonth(report);
        break;
      }
      case 'CURRENT_MONTH_AT_DATE': {
        this.updateDatesToCurrentMonthAtDate(report);
        break;
      }
    }
  }

  private updateDatesToCurrentMonth(report: ReportFavorite): void {
    this.updateDates(report, new Date(), 'MM/DD/YYYY');
  }

  private updateDatesToCurrentMonthAtDate(report: ReportFavorite): void {
    let format = 'MM/DD/YYYY';
    let datePast: boolean = false;
    let syncDate: Date = new Date();

    for (let param of report.parameterList) {
      if (this.isParamDate(param)) {
        let date: Date = moment(param.simpleValues[0], format).toDate();
        if (date.getDate() <= syncDate.getDate()) {
          datePast = true;
          break;
        }
      }
    }

    if (!datePast) {
      syncDate = moment(syncDate).add(-1, 'month').toDate();
    }

    this.updateDates(report, syncDate, format);
  }

  private updateDates(report: ReportFavorite, syncDate: Date, format: string): void {
    report.parameterList.forEach((param) => {
      if (this.isParamDate(param)) {
        let origDate: moment.Moment = moment(param.simpleValues[0], format);
        let date: Date = origDate.toDate();
        date.setFullYear(syncDate.getFullYear(), syncDate.getMonth());
        date = this.fixDate(date, origDate);
        param.simpleValues[0] = moment(date).format(format);
      }
    });
  }

  private fixDate(date: Date, origDate: moment.Moment): Date {
    let dayOfMonth = origDate.toDate().getDate();

    //correct month rolling side effect of setFullYear when day is 29-31 and next month does not have that - it rolls to the first.
    while (dayOfMonth > date.getDate() + 4) {
      date = moment(date).add(-1, 'day').toDate();
    }

    //Correct end date if the original was the last date of that month.  happens when month you saved  has 30 days (or less) and current
    //month has more , like 31.
    if (origDate.daysInMonth() === dayOfMonth) {
      while (date.getDate() < moment(date).daysInMonth()) {
        date = moment(date).add(1, 'day').toDate();
      }
    }

    return date;

  }

  private isParamDate(param: any): boolean {
    return param.type && param.type === 'java.util.Calendar';
  }

  private navigateWithData(data: any, navigationPath: any[]): void {
    this.routerService.navigateWithData(data, navigationPath);
  }

}
