import { Injectable } from '@angular/core';
import { RowNode, GridOptionsWrapper, Column } from '@ag-grid-community/core';
import { Observable ,  of } from 'rxjs';
import { UiGridApi } from '..';
import { HasRequired } from 'src/ag-grid-wrapper/interfaces/has-required';
import { UiValidationRowNode } from 'src/ag-grid-wrapper/interfaces/ui-validation-row-node';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { ValidationPipeline } from 'cad/common/models/validation/validator-pipeline';

@Injectable()
export class RowValidationService {
  public addMessageString(row: RowNode, msg: string): void {
    (row as UiValidationRowNode).validationToolTip = msg;
  }

  public addMessageArray(row: RowNode, msgArray: string[]): void {
    if (msgArray) {
      (row as UiValidationRowNode).validationToolTip = msgArray.toString();
    }
  }

  public addMessageObject(row: RowNode, msgObj: any): void {
    const messageArray: string[] = [];
    if (msgObj) {
      _.forEach(msgObj, (message: any) => {
        if (typeof message === 'string') {
          messageArray.push(message);
        } else {
          messageArray.push(message.message);
        }

      });
    }
    this.addMessageArray(row, messageArray);
  }

  public validateRequiredColumns(grid: UiGridApi, row: RowNode): boolean {
    let errors: string[];
    let gridOptionsWrapper: GridOptionsWrapper = (grid as any).gridOptionsWrapper;

    errors = gridOptionsWrapper.getColumnApi().getAllColumns().filter((gridColumn: Column) => {
      const columnValue = grid.getValue(gridColumn.getColId(), row);
      return gridColumn.isVisible()
        && (_.isNil(columnValue) || columnValue === '')
        && (gridColumn.getColDef() as HasRequired).required;
    })
    .map((gridColumn: Column) => gridColumn.getColDef().headerName);
    (row as UiValidationRowNode).errorCells = _.uniq(errors);
    // grid.refreshCells();
    // grid.redrawRows();
    return !errors.length;
  }

  public validateColumns(grid: UiGridApi, row: RowNode, pipelineValidationResult: any): boolean {
    if(pipelineValidationResult) {
      let errors = Object.keys(pipelineValidationResult).filter( ( key ) => pipelineValidationResult[key].isValid === false);
      const errorHeaders = (grid as any).gridOptionsWrapper.gridOptions.columnDefs
      .filter((def) => pipelineValidationResult[def.field] && pipelineValidationResult[def.field].isValid === false )
      .map((def) => def.headerName);

      if (errors.length) {
        (row as UiValidationRowNode).errorCells = errorHeaders;

        const msgs = [];
        errors.forEach((error) => {
          if(pipelineValidationResult[error].messages && pipelineValidationResult[error].messages.length > 0) {
            pipelineValidationResult[error].messages.forEach( (msg) => {
              msgs.push(msg);
            });
          }
        });
        grid.rowEdit.setErrorRow(row, msgs.toString());
      } else {
        grid.rowEdit.removeErrorRow(row);
        (row as UiValidationRowNode).errorCells = [];
      }
      grid.refreshCells();
      return !errors.length;
    } else {
      return true;
    }

  }

  public validateRow(gridApi: UiGridApi, node: RowNode, validationPipeline: ValidationPipeline, validateRequired: boolean = true ) : Observable<boolean> {
    const hasAllRequiredColumns = this.validateRequiredColumns(gridApi, node);
    if(!validateRequired || hasAllRequiredColumns) {
      return validationPipeline.validate([ node.data ], gridApi).pipe(map( (x) => {
        const result = x[0];
        this.validateColumns(gridApi, node, result.validationResults);
        return result.successful;
      }));
    } else {
      gridApi.refreshCells();
      return of(false);
    }
  }

  public setMessage(row: RowNode, msg: any): void {
    if (msg instanceof Array) {
      this.addMessageObject(row, msg);
    } else if (typeof msg === 'string') {
      this.addMessageString(row, msg);
    }
  }

  private setErrorRows(grid: UiGridApi, row: RowNode): void {
    grid.rowEdit.setErrorRow(row);
  }
}
