<div (click)="dismiss()">
  <div class="alert alert--minimal">
    <div class="alert__icon">
      <mat-icon class="alert-color-red">warning</mat-icon>
    </div>

    <div class="alert__message">
      <p>A newer version of Messenger is available.  Please save your work and <a onclick="window.location.reload()">refresh</a> the page.</p>
      <p><a onclick="window.location.reload()">Refresh Now</a></p>
    </div>
  </div>
</div>
