import { NgModule, Injector } from '@angular/core';
import { BreadcrumbService } from './breadcrumb.service';

@NgModule({
  providers: [ BreadcrumbService ]
})
export class BreadcrumbModule {
  constructor(private injector: Injector) {
    injector.get(BreadcrumbService); // bootstrap the breadcrumb service so it is available before the first navigation
  }
}
