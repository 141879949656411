<div (click)="dismiss()">
  <div class="alert alert--minimal" *ngFor="let alert of alerts">
    <div class="alert__icon">
      <mat-icon [ngClass]="alert.alertClass">{{alert.icon}}</mat-icon>
    </div>

    <div class="alert__message">
      <p [innerHTML]="alert.msg"></p>
    </div>
  </div>
</div>
