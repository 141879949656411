import { Component, AfterViewInit } from '@angular/core';
import { TableColumnToggleBaseRenderer } from '../base/toggle-base-renderer';

@Component({
  moduleId: module.id + '',
  selector: 'table-column-link-renderer',
  template: `
    <div class="inner-cell right-align">{{params.value | currency:'USD':'symbol'}}</div>
  `,
  styles: [
    `.right-align {
    text-align: right !important;
    }`
  ]
})
export class TableColumnCurrencyRendererComponent extends TableColumnToggleBaseRenderer {
  params: any;
}
