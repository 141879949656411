import { Component, ViewChild } from '@angular/core';
// TODO: Change the following import to @angular/material once Material is upgraded to version 10.0.1
import { MatCalendarHeader } from '../mat-datepicker/calendar';

@Component({
  selector: 'ui-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: [ './calendar-header.component.less' ],
  exportAs: 'uiCalendarHeader'
})
export class UiCalendarHeaderComponent<D> {

  @ViewChild('matCalendarHeaderRef', { read: MatCalendarHeader }) public matCalendarHeaderRef: MatCalendarHeader<D>;

  constructor() {}

  /**
   * @description Handles the today/now button click callback to focus current date.
   */
  public onToday(): void {
    if (this.matCalendarHeaderRef && this.matCalendarHeaderRef.calendar) {
      if ((this.matCalendarHeaderRef.calendar as any)._dateAdapter) {
        this.matCalendarHeaderRef.calendar.activeDate = (this.matCalendarHeaderRef.calendar as any)._dateAdapter.today();
      }
      this.matCalendarHeaderRef.calendar.focusActiveCell();
    }
  }
}
