import { ViewEncapsulation, Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { RowNode } from '@ag-grid-community/core';

@Component({
  moduleId: module.id + '',
  selector: 'multiple-select',
  template: `<mat-checkbox (change)='select($event)' aria-label='check box'></mat-checkbox>`,
  encapsulation: ViewEncapsulation.None
})

export class RowMultipleSelectComponent implements AgRendererComponent {
  private params: any;
  private rowNode: RowNode;

  agInit(params: any): void {
    this.params = params;
    this.rowNode = params.node;
  }

  refresh (params: any): boolean {
    this.params = params;
    return true;
  }

  select($event: any): void {
    // this.params.node.setSelected($event.checked);
    // if ($event.checked === this.params.node.selected) { return; }
    this.params.node.setSelected($event.checked);
  }
}
