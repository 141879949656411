import { Injectable } from '@angular/core';
import { SecurityAuthApi } from 'cad/common/services/api/security/auth/auth';
import { SecurityLoginApi } from 'cad/common/services/api/security/login/login';
import { SecurityLogoutApi } from 'cad/common/services/api/security/logout/logout';
import { SecurityUserApi } from 'cad/common/services/api/security/user/user';

@Injectable()
export class SecurityApi {
  constructor(
    public auth: SecurityAuthApi,
    public user: SecurityUserApi,
    public login: SecurityLoginApi,
    public logout: SecurityLogoutApi,
  ) {}
}
