
import {map} from 'rxjs/operators';
import { UiFormComponent } from 'src/common/components/form/form.component';
import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AutoUnsubscribables, AutoUnsubscriber } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import { ValidationResultModel } from 'src/cad/common/models/validation-result/validation-result-model';
import { ItemController } from 'src/features/common/item/item.controller';
import { ItemDetailController } from 'src/features/common/item-detail/item-detail.controller';
import { AlertsService } from 'src/cad/core/services/alerts/alerts.service';
import { EmitterService } from 'cad/core/services/emitter/emitter.service';
import { DirtyFormService } from 'cad/common/services/save/dirty-form/dirty-form';
import { SaveFormService } from 'cad/common/services/save/save-form/save-form';
import { ItemApiService } from 'src/features/common/item/item-api.service';
import { RouterService } from 'src/cad/core/services/router/router.service';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

@Component({
  selector: 'ui-item-card',
  templateUrl: './item-card.component.html',
})
export class ItemCardComponent implements OnInit, OnDestroy {

  @Input('uiItemCardItemData') public get itemData(): any {
    return this._itemData;
  }
  public set itemData(val: any) {
    this._itemData = val;
    /**
     * when we get a new itemData (for example when navigating to a different BA)
     * we need to reset the form to not be dirty when all of the controls are being
     * repopulated they are forcing status changes on the form which in turn fires
     * off several form dirty events in the emitterService.  This timeout waits for
     * all of these events to fire off before resetting the form back to a pristine state.
     */
    if (this.itemController.model.enableSaveOnFormDirty) {
      setTimeout(() => {
        this.uiForm.markPristine();
      });
    }
  }
  @ViewChild(UiFormComponent) public uiForm: UiFormComponent;

  private _itemData: any;
  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  public constructor(
    private router: RouterService,
    private alertsService: AlertsService,
    private dirtyFormService: DirtyFormService,
    private saveFormService: SaveFormService,
    private emitterService: EmitterService,
    private itemApiService: ItemApiService,
    public itemController: ItemController,
    public itemDetailController: ItemDetailController
  ) {}

  ngOnInit(): void {
    // Could not find a way to add this to index.js as a _.default  stuck under preModel:
    if (this.itemDetailController.model.action) {
      if (!this.itemDetailController.model.action.iconName) {
        this.itemDetailController.model.action.iconName = 'more_vert';
      }

      //default the direction to left
      if (!this.itemDetailController.model.action.direction) {
        this.itemDetailController.model.action.direction = 'left';
      }

      //default the tooltip Position to top
      if (!this.itemDetailController.model.action.toolTipPosition) {
        this.itemDetailController.model.action.toolTipPosition = 'above';
      }
    }

    this.itemDetailController.initFormItem(this);
  }

  ngOnDestroy(): void {}

  public save(data: any): void {
    this.itemData.updateDt = moment().format();
    // Adding to check validations before save
    if (this.itemDetailController) {
      this.subs.newSub = this.itemDetailController.validateItemData(this.itemData)
      .subscribe((validationResult: ValidationResultModel) => {
        if (validationResult) {
          if (validationResult.isSuccess()) {
            this.itemDetailController.preSave(this.itemData);
            const saveCall = this.itemApiService.save(this.itemData).pipe(map((result) => {
              if (result.successful) {
                if (this.itemController.model && this.itemController.model.showAllMessagesOnSave) {
                  this.alertsService.showResultAlerts(result);
                }
                _.assign(this.itemData, result.validatedObject);
                this.itemDetailController.itemDataChanged(this.itemData);
                this.itemDetailController.postSave(this.itemData);
                return result.validatedObject;
              } else {
                if (result.errorMessages) {
                  throw result.errorMessages;
                } else if (result.failureMessages) {
                  throw result.failureMessages;
                } else if (result.messages) {
                  throw result.messages;
                } else {
                  throw [ 'Unexpected error attempting to save data.' ];
                }
              }
            }));

            if (data.skipSaveMessage) {
              this.subs.newSub = saveCall.subscribe();
            } else {
              this.subs.newSub = saveCall.subscribe(
                () => {
                },
                (errs) => {
                  this.saveFail(errs);
                },
                () => {
                  this.saveSuccess(this.uiForm);
                }
              );
            }
          } else {
            if (this.alertsService) {
              this.alertsService.showResultAlerts(validationResult);
            }
          }
        }
      });
    }
  }

  public setDirty(isDirty: boolean): void {
    this.emitterService.formDirtyEvent.next({ dirtyStatus: isDirty });
    this.dirtyFormService.setDirty(this.itemData, isDirty);
  }

  private saveSuccess(form: UiFormComponent): void {
    this.saveFormService.success(form)();
  }

  private saveFail(errors: any): void {
    if (_.isArray(errors)) {
      let msgs = errors.map((error: any) => error.message);
      this.saveFormService.fail(msgs)();
    }
  }
}
