import { NgModule } from '@angular/core';
import { PersonalInformationComponent } from 'src/cad/user-profile/components/personal-information/personal-information.component';
import { ContactInformationComponent } from 'src/cad/user-profile/components/contact-information/contact-information.component';
import { EmailNotificationComponent } from 'src/cad/user-profile/components/email-notification/email-notification.component';
import { NewContactProfileModalComponent } from 'src/cad/user-profile/components/new-contact-profile-modal/new-contact-profile-modal.component';
import { AddEmailNotificationModalComponent } from './modals/add-email-notification.component';
import { SidenavController } from 'src/features/common/sidenav/sidenav.controller';
import { SharedModule } from 'cad/shared/shared.module';
import { SidenavComponentsModule } from 'src/features/common/sidenav/sidenav-components.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ControllerFactoryComponent } from 'src/framing/controller-factory.component';
import { ProfileTypeListService } from 'cad/administration/shared/profile-type-list.service';

@NgModule({
  imports: [
    SharedModule,
    SidenavComponentsModule,
    CommonModule,
    RouterModule.forChild(
      [
        {
          path: '',
          component: ControllerFactoryComponent,
          data: {
            featureSidenav: {
              name: 'Sidenav',
              controller: SidenavController,
              model: {
                sidenavSections: {
                  sections: [
                    {
                      name: 'User Profile',
                      type: 'toggle',
                      state: '',
                      icon: 'person',
                      pages: [
                        {
                          name: 'Personal Information',
                          type: 'link',
                          state: '',
                          icon: 'my_location',
                          scroll: 'true'
                        },
                        {
                          name: 'Contact Information',
                          state: '',
                          type: 'link',
                          icon: 'business',
                          scroll: 'true'
                        },
                        {
                          name: 'Email Notification',
                          state: '',
                          type: 'link',
                          icon: 'people',
                          scroll: 'true'
                        }
                      ]
                    }
                  ]
                }
              },
            }
          },
          children: [ { path: '', component: PersonalInformationComponent } ]
        }
      ]),
  ],
  declarations: [
    PersonalInformationComponent,
    ContactInformationComponent,
    EmailNotificationComponent,
    NewContactProfileModalComponent,
    AddEmailNotificationModalComponent
  ],
  providers: [ ProfileTypeListService ],
  entryComponents: [
    PersonalInformationComponent,
    ContactInformationComponent,
    EmailNotificationComponent,
    NewContactProfileModalComponent,
    AddEmailNotificationModalComponent
  ]
})
export class UserProfileModule {}
