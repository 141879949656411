/**
 * Created by jrayavarap on 8/2/2017.
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NominationContactApi } from 'src/cad/common/services/api/nomination/nomination-contact/nomination-contact';
import { AutoUnsubscriber, AutoUnsubscribables } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import { ConfigService } from 'src/cad/core/services/config/config.service';

@Component({
  selector: 'contract-contacts',
  templateUrl: './contract-contacts.component.html',
})
export class ContractContactsComponent implements OnInit {

  public noticeContactPageUrl: string;
  public contractId: number;
  public contactData: any;
  @AutoUnsubscriber() protected subs: AutoUnsubscribables;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public configSvc: ConfigService,
    private nominationContactApi: NominationContactApi,
    public dialogRef: MatDialogRef<ContractContactsComponent>,
  ) {
    this.contractId = this.data.contractId;
    this.subs.newSub  = this.nominationContactApi.getById(this.contractId).subscribe((result: any) => {
      this.contactData = result;
    });
  }

  public ngOnInit(): void {
    if (this.configSvc) {
      this.noticeContactPageUrl = this.configSvc.getInfoPostBaseUrl() + '/notice/notice-contact-page';
    }
  }

  public cancel = (): void => {
    this.dialogRef.close();
  }
}
