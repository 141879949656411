import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiCache } from 'cad/common/services/api/api-cache';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class CodesApi {

  private readonly endpoint: string = 'codes';

  constructor(
    private apiHelper: ApiHelper,
    private apiCache: ApiCache,
  ) {}

  public getAssetCodes = (assetId: number): Observable<any> => {
    let params: HttpParams = new HttpParams();
    params = params.set('assetId', assetId.toString());
    return this.apiHelper.request(this.endpoint + '/asset', { cache: this.apiCache.forSession, cacheTag: this.endpoint, params });
  }

  public getCodeType = (assetId: number, type: any): Observable<any> => {
    let params: HttpParams = new HttpParams();
    params = params.set('assetId', assetId.toString());
    params = params.set('type', type.toString());
    return this.apiHelper.request(this.endpoint + '/asset/type', { cache: this.apiCache.for30Days, cacheTag: this.endpoint, params });
  }

  public getAccountingPeriodParamCodeType = (assetId: number) => {
    return this.getCodeType(assetId, 'ACCTPRDPARAM');
  }
}
