<ui-card id="details" [headline]="itemDetailController.model.cardName ? itemDetailController.model.cardName : (itemDetailController.model.name + ' Details')" [enableMax]="false" [enableExpand]="itemDetailController.model.enableDetailExpand">
  <div fxLayout="column" fxLayout.sm="column" class="col-padding">
    <div fxFlex *ngIf="itemDetailController.model.action && false != itemDetailController.model.action?.enabled" fxLayoutAlign="end center">
      <ui-float-button
        [attr.skip-disable]="itemDetailController.model.action.skipDisable ? 'true' : null"
        [iconName]="itemDetailController.model.action.iconName"
        [mainTooltip]="itemDetailController.model.action.tooltip"
        [direction]="itemDetailController.model.action.direction"
        [toolTipPosition]="itemDetailController.model.action.toolTipPosition"
        [actionItems]="itemDetailController.model.action.actionItems"
        [btnClick]="itemDetailController.model.action.click">
      </ui-float-button>
    </div>
    <ui-form formType="ItemForm" (onSave)="save($event)" (dirtyStatusChanged)="setDirty($event.dirtyStatus)">
      <ng-container [ngComponentOutlet]="itemController.view.form"></ng-container>
    </ui-form>
  </div>
</ui-card>
