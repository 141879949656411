import { Injectable, OnDestroy } from '@angular/core';
import { TableComponent } from 'src/ag-grid-wrapper/table/table.component';

@Injectable()
export class TableRegistryService implements OnDestroy {
  
  public tableComponentList: { [ key: string ]: TableComponent } = {};
  private tableKeyCounter: number = 0;
  
  public ngOnDestroy(): void {
    Object.keys(this.tableComponentList).forEach((key: string) => {
      this.removeTable(key);
    });
    this.tableComponentList = null;
  }
  
  public addTable(key: string, tableComponent: TableComponent): string {
    let tableKey: string = (key || this.getNextTableKey());
    
    this.tableComponentList[ tableKey ] = tableComponent;
    return tableKey;
  }
  
  public getTable(key: string): TableComponent {
    return this.tableComponentList[ key ];
  }
  
  public removeTable(key: string): void {
    this.tableComponentList[ key ] = null;
    delete this.tableComponentList[ key ];
  }
  
  private getNextTableKey(): string {
    this.tableKeyCounter++;
    return 'uiTable_' + this.tableKeyCounter;
  }
}
