
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import * as _ from 'lodash';

@Injectable()
export class EdiSecurityApi {

  public readonly endpoint: string = 'administration/edi-security';

  constructor(
    private apiHelper: ApiHelper
  ) {}

  public getById(id: number | string): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/' + id);
  }

  public find(params: any): Observable<any> {
    return (params && !_.isNil(params)) ? this.getById(params.ediSecurityId) : observableOf({});
  }

  public filter(params: any): Observable<any> {
    let returnVal: Observable<any>;

    if (params && _.has(params, 'populator')) {
      let paramsObj: any = _.omit(params, 'populator');
      let lookupActionName: string = _.get(params, 'populator');

      switch (lookupActionName) {
        case 'serviceReqLookup': {
          returnVal = this.getServiceRequesterData(paramsObj);
          break;
        }
        default: {
          break;
        }
      }
    } else {
      returnVal = this.apiHelper.request(this.endpoint + '/filter', { body: params });
    }
    return !_.isNil(returnVal) ? returnVal : observableOf([]);
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/dashboard');
  }

  public save(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save', { body: params });
  }

  public deleteItem(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/delete', { body: params });
  }

  public getServiceRequesterData(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/service-requester-data', { body: params });
  }

  public getDefaultOption(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/default-options', { body: params });
  }
}
