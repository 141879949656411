import { Injectable, Injector } from '@angular/core';
import { PinnedItemApi } from 'src/cad/common/services/api/nomination/pinned-item/pinned-item';

@Injectable()
export class ShipImbalPinnedContractApi extends PinnedItemApi {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = 'ship-imbal/shipper-imbal/matrix/pinned-contracts';
  }
}
