<!-- 
 Copy of MatSelect's template, customized to support our UiMatVirtualSelectComponent.

 The only thing we're changing is customizing the content projection to support our virtual
  component, which provides a filter to search values in long lists and optimizations for loading of large lists

 This HTML comes from https://github.com/angular/components/blob/10.2.7/src/material/select/select.html
 When upgrading Angular to version 11+, it's likely there'll be a need to re-grab the HTML 

 Having a shadow copy of an official Angular file is undesirable, but this is a firm upgrade from the previous
  solution of using a mixin to copy the MatSelect template + string-replace the <ng-content> tag (which is ineffective in Angular 9+,
  and with our implementation didn't seem to work with AOT)
 -->
<div cdk-overlay-origin class="mat-select-trigger" (click)="toggle()"
     #origin="cdkOverlayOrigin" #trigger>
  <div class="mat-select-value" [ngSwitch]="empty" [attr.id]="_valueId">
    <span class="mat-select-placeholder" *ngSwitchCase="true">{{placeholder ||
      '\u00A0'}}</span>
    <span class="mat-select-value-text" *ngSwitchCase="false"
          [ngSwitch]="!!customTrigger">
      <span *ngSwitchDefault>{{triggerValue || '\u00A0'}}</span>
      <ng-content select="mat-select-trigger" *ngSwitchCase="true"></ng-content>
    </span>
  </div>

  <div class="mat-select-arrow-wrapper">
    <div class="mat-select-arrow"></div>
  </div>
</div>

<ng-template cdk-connected-overlay cdkConnectedOverlayLockPosition
             cdkConnectedOverlayHasBackdrop
             cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
             [cdkConnectedOverlayScrollStrategy]="_scrollStrategy"
             [cdkConnectedOverlayOrigin]="origin"
             [cdkConnectedOverlayOpen]="panelOpen"
             [cdkConnectedOverlayPositions]="_positions"
             [cdkConnectedOverlayMinWidth]="_triggerRect?.width!"
             [cdkConnectedOverlayOffsetY]="_offsetY" (backdropClick)="close()"
             (attach)="_onAttached()" (detach)="close()">
  <div class="mat-select-panel-wrap" [@transformPanelWrap]>
    <div #panel role="listbox" tabindex="-1"
         class="mat-select-panel {{ _getPanelTheme() }}"
         [attr.id]="id + '-panel'" [attr.aria-multiselectable]="multiple"
         [attr.aria-label]="ariaLabel || null"
         [attr.aria-labelledby]="_getPanelAriaLabelledby()"
         [ngClass]="panelClass"
         [@transformPanel]="multiple ? 'showing-multiple' : 'showing'"
         (@transformPanel.done)="_panelDoneAnimatingStream.next($event.toState)"
         [style.transformOrigin]="_transformOrigin"
         [style.font-size.px]="_triggerFontSize"
         (keydown)="_handleKeydown($event)">
      <!-- Customization start:  Official mat-select just uses <ng-content></ng-content> here. -->
      <ng-content
                  select="ui-mat-virtual-select-header, [uiMatVirtualSelectHeader]">
      </ng-content>
      <ui-virtual-repeat [vrActivated]="true" #virtualPanel
                         [vrItems]="originalOptionList"
                         [vrChildHeight]="optionItemHeight"
                         [style.maxHeight.px]="selectPanelMaxHeight"
                         [vrItemsToBuffer]="itemsToBuffer"
                         (vrVirtualItemsChange)="virtualOptionList = $event">
        <ng-content></ng-content>
      </ui-virtual-repeat>
      <ng-container *ngIf="showCloseButton">
        <mat-divider></mat-divider>
        <div class="ui-mat-virtual-select-close-button" fxLayout="row"
             fxLayoutAlign="end center">
          <button type="button" mat-raised-button (click)="close()">CLOSE
          </button>
        </div>
      </ng-container>
      <!-- Customization end -->
    </div>
  </div>
</ng-template>