
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ItemApi } from 'src/features/common/item/item-api';

@Injectable()
export class PortfolioValuationApi extends ItemApi {

  private readonly endpoint: string = 'portfolio/valuation/';

  constructor(
    private apiHelper: ApiHelper,
  ) {
    super();
  }

  public getAccountingPeriod(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/accounting-period');
  }

  public getById = (params: any): Observable<any> => {
    return this.find(params);
  }

  public find(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/find', { body: params }).pipe(map((resp) => {
      if (resp && resp.address) {
        resp.address = resp.address.replace(/\n/g, ' ');
      }
      return resp;
    }));
  }

  public findAll = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint, { body: params });
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + 'filter', { body: params });
  }

  public reset = (item: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + item.contractId + '/reset', { body: item });
  }

  public del = (item: any, keepAdj: boolean): Observable<any> => {
    let secondary = keepAdj ? '/delete-keep-adj' : '/delete';
    return this.apiHelper.request(this.endpoint + item.contractId + secondary, { body: item });
  }

  public recent(): Observable<any> {
    // invoicing has no dashboard
    return observableOf([]);
  }

  public save(): Observable<any> {
    return observableOf({});
  }

  public deleteItem(item: any): Observable<any> {
    return observableOf({msg:'*** DELTE NOT IMPLEMENTED HERE ***'});
  }
}
