import { Injectable, NgZone } from '@angular/core';
import { Router, RouterState, Event, NavigationEnd, NavigationExtras, UrlTree } from '@angular/router';
import { Subscription ,  Observable } from 'rxjs';

@Injectable()
export class RouterService {
  private subscriptions: Subscription[] = [];
  private brokerData: any;
  private brokerCommands: any[];

  constructor(
    private router: Router,
    private ngZone: NgZone
  ) {
    this.init();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  /**
   */
  get routerState(): RouterState { return this.router.routerState; }

  /**
   */
  get events(): Observable<Event> { return this.router.events; }

  /**
   */
  get url(): string { return this.router.url; }

  /**
   * Direct access to angular router
   */
  get ngRouter(): Router { return this.router; }

  /**
   * Navigate to a new route. Forces change detect after navigation with ngZone.run()
   */
  public navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    return this.ngZone.run<Promise<boolean>>(() => this.router.navigate(commands, extras).then((result) => {
      return result;
    }));
  }

  /**
   * Navigate to a new route. Forces change detect after navigation with ngZone.run()
   */
  public navigateByUrl(url: string | UrlTree, extras?: NavigationExtras): Promise<boolean> {
    return this.ngZone.run<Promise<boolean>>(() =>
      this.router.navigateByUrl(url, extras).then((result) => result));
  }

  /**
   * Navigate to a new route and save data in the broker for the new route to fetch.
   */
  public navigateWithData(data: any, commands: any[], extras?: NavigationExtras): Promise<boolean> {
    this.brokerData = data;
    this.brokerCommands = commands;
    return this.navigate(commands, extras);
  }

  /**
   * Fetch navigation data saved in the broker before navigation.
   */
  public getNavigationData(): any {
    const result = this.brokerData;
    this.brokerData = null;
    this.brokerCommands = null;
    return result;
  }

  /**
   */
  private init(): void {
    this.subscriptions.push(this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.brokerData) {
          console.warn('Navigation data was brokered but not retreived', this.brokerCommands);
          //ToDo Get Nav data is happening after this.
          // this.brokerData = null;
          // this.brokerCommands = null;
        }
      }
    }));
  }
}
