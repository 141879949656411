import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoginDialogComponent } from 'cad/login/dialog/login-dialog.component';
import { RouterService } from 'src/cad/core/services/router/router.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  template: '',
})
export class LoginContentComponent implements OnDestroy, OnInit {

  private dialogRef: MatDialogRef<LoginDialogComponent>;

  private subscription: Subscription;

  constructor(
    private router: RouterService,
    private dialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    setTimeout(() => {
      this.openDialog();
    }); // delay by one cycle to prevent ExpressionChangedAfterItHasBeenCheckedError error
  }

  public ngOnDestroy(): void {
    if (this.subscription) { this.subscription.unsubscribe(); }
    if (this.dialogRef) { this.dialogRef.close(); }
  }

  private openDialog(): void {
    this.dialogRef = this.dialog.open(LoginDialogComponent, { disableClose: true });
  }
}
