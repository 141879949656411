import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class UsersApi {

  private readonly endpoint: string = 'administration/users/';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public currentUsers = (): Observable<any> => {
    return this.apiHelper.request(this.endpoint + 'current-users');
  }

  public getUserSession = (userName: string): Observable<any> => {
    let params: HttpParams = new HttpParams();
    params = params.set('user', userName);
    return this.apiHelper.request(this.endpoint + 'user-session', { params });
  }
}
