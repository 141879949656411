import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class AllocationExceptionApi {

  public endpoint: string = 'allocation/allocation-exception';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  // ========================================
  // implementation
  // ========================================

  public filter = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public findAll = (): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/filter', { });
  }

}
