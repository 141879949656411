import { Injectable } from '@angular/core';

import { Observable ,  Subject ,  ReplaySubject } from 'rxjs';

@Injectable()
export class MenuModel {
  public get menuItems$(): Observable<any> { return this.menuItemsSubject; }

  public menuItemsSubject: Subject<any> = new ReplaySubject(1);
}
