import { Injectable } from '@angular/core';
import { EmitterService } from 'cad/core/services/emitter/emitter.service';

@Injectable()
export class DirtyFormService {

  public constructor(
    private emitterService: EmitterService
  ) {}

  public setDirty = (item: any, isDirty: boolean): void => {
    if (!item) { return; }
    if ('dirtyStatus' in item && item.dirtyStatus !== cad.DirtyStatus.NEW) {
      isDirty ? item.dirtyStatus = cad.DirtyStatus.UPDATE : item.dirtyStatus = cad.DirtyStatus.NO_CHANGE;
    }
  }
  
}
