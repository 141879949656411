import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { CallLogIssue } from 'src/cad/administration/call-log/shared/interfaces/call-log-issue';
import { CallLogAction } from 'src/cad/administration/call-log/shared/interfaces/call-log-action';
import ValidationResult = cad.ValidationResult;

@Injectable()
export class IssueSubtopicActionApi {

  private readonly endpoint: string = 'administration/calllog';
  
  constructor(
    public apiHelper: ApiHelper
  ) {}
  
  public getIssues(callLogId: number): Observable<CallLogIssue[]> {
    return this.apiHelper.request(`${ this.endpoint }/${ callLogId }/issues`);
  }
  
  public getActions(callLogId: number): Observable<CallLogAction[]> {
    return this.apiHelper.request(`${ this.endpoint }/${ callLogId }/actions`);
  }
  
  public getIssueSubtopics(issueCd: string): Observable<string[]> {
    return this.apiHelper.request(`${ this.endpoint }/issueSubTopic/${ issueCd }`);
  }
  
  public saveIssueList(callLogId: number, saveDataList: CallLogIssue[]): Observable<ValidationResult<CallLogIssue[]>> {
    return this.apiHelper.request(`${ this.endpoint }/${ callLogId }/issues/save`, { body: saveDataList });
  }
  
  public saveActionList(callLogId: number, saveDataList: CallLogAction[]): Observable<ValidationResult<CallLogAction[]>> {
    return this.apiHelper.request(`${ this.endpoint }/${ callLogId }/actions/save`, { body: saveDataList });
  }
}
