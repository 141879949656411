import { Component, ChangeDetectionStrategy, Injector } from '@angular/core';

import { FeatureComponent } from 'src/framing/feature.component';
import { CadAppController } from '../../cad-app.controller';
import { CadAppModel } from '../../cad-app.model';
import { CadAppView } from '../../cad-app.view';

@Component({
  selector: 'ui-global-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.less' ],
})
export class HeaderComponent extends FeatureComponent<CadAppModel, CadAppView, CadAppController> {
  constructor(controller: CadAppController, injector: Injector) { super(controller, injector); }
}
