import { Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscriber, AutoUnsubscribables } from 'cad/shared/mixins/auto-unsubscriber.mixin';
import { UiProcessStatusService, ProcessStatusModel } from 'src/common/services/process-status/process-status.service';

@Component({
  selector: 'ui-process-status-button',
  templateUrl: './header-toolbar-process-status.component.html',
  styleUrls: [ './header-toolbar-process-status.component.less' ],
})
export class HeaderToolbarProcessStatusComponent implements OnInit, OnDestroy {

  public statusIcon: string = 'sync';
  public isSuccessStatus: boolean = false;
  public isWarningStatus: boolean = false;
  public isFailedStatus: boolean = false;
  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  constructor(
    public uiProcessStatusService: UiProcessStatusService
  ) {}

  public ngOnInit(): void {
    if (this.uiProcessStatusService) {
      this.subs.newSub = this.uiProcessStatusService.processList$
      .subscribe((processList: ProcessStatusModel[]) => {
        if (processList.length > 0
          && this.uiProcessStatusService.getSuccessProcesses().length === processList.length) {
          this.statusIcon = 'sync';
          this.isSuccessStatus = true;
          this.isWarningStatus = false;
          this.isFailedStatus = false;
        } else if (this.uiProcessStatusService.getFailedProcesses().length > 0) {
          this.statusIcon = 'sync_problem';
          this.isSuccessStatus = false;
          this.isWarningStatus = false;
          this.isFailedStatus = true;
        } else if (this.uiProcessStatusService.getFailedProcesses().length === 0
          && this.uiProcessStatusService.getWarningProcesses().length > 0) {
          this.statusIcon = 'sync_problem';
          this.isSuccessStatus = false;
          this.isWarningStatus = true;
          this.isFailedStatus = false;
        } else {
          this.statusIcon = 'sync';
          this.isSuccessStatus = false;
          this.isWarningStatus = false;
          this.isFailedStatus = false;
        }
      });
    }
  }

  public ngOnDestroy(): void {}

  public toggleProcessStatusPopup(): void {
    if (this.uiProcessStatusService) {
      this.uiProcessStatusService.toggle();
    }
  }
}
