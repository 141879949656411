
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import * as _ from 'lodash';

@Injectable()
export class AssetParamApi {

  public readonly endpoint: string = 'administration/table-maintenance/asset-param';

  constructor(
    private apiHelper: ApiHelper,
    private userModelService: UserModelService
  ) {}

  public getById(id: number | string): Observable<any> {
    let assetNumber: number | string = _.clone(id);

    if (_.isNil(assetNumber)) {
      return observableOf({});
    }
    return this.apiHelper.request(this.endpoint + '/' + assetNumber);
  }

  public find(params: any): Observable<any> {
    let assetNumber: number | string;

    if (params) {
      assetNumber = params.assetNbr;
    }
    return this.getById(assetNumber);
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public recent(): Observable<any> {
    // Dashboard/Recent does not apply to Asset Param
    return observableOf([]);
  }

  public save(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save', { body: params });
  }

  public deleteItem(params: any): Observable<any> {
    // Delete does not apply to Asset Param
    return observableOf({});
  }
}
