import { Injectable } from '@angular/core';
import { NomBaseDatesUtil } from 'src/cad/nominations/common/utils/nom-base-date/nom-base-date';

@Injectable()
export class NomDateUtil {
  constructor() {
    return NomDateFactory;
  }
}

class NomDateFactory extends NomBaseDatesUtil {
  constructor(c: any) {
    super();
    this.ctrl = c;
  }

  public searchBeginDateChanged = (ctrl?: any): any => {
    let obj = this.ctrl ? this.ctrl : ctrl;
    return (sDate: any, oldVal: any): void => {
      /* tslint:disable:no-param-reassign */
      if (oldVal) {
        oldVal = moment(oldVal);
      }
      if (sDate) {
        sDate = moment(sDate);
      }
      /* tslint:enable:no-param-reassign */

      if (sDate && oldVal && !sDate.isSame(oldVal) && obj.searchEndDate) {
        let startDate = sDate.startOf('day');
        let endDate = moment(obj.searchEndDate).startOf('day');
        let today = moment().startOf('day');

        if (startDate.isSame(today)) {
          // if start date is today then set the end date to today.
          obj.searchEndDate = startDate.startOf('day').format();
        } else if (startDate.isBefore(today) && startDate.month() < endDate.month()) {
          obj.searchEndDate = startDate.endOf('month').startOf('day').format();
        } else if (startDate.isBefore(today) &&
          startDate.month() === today.month() &&
          startDate.year() === today.year()) {
          // if the start date is less than the current date
          // then the end date should be current day - 1
          obj.searchEndDate = today.subtract(1, 'day').format();
        } else {
          obj.searchEndDate = startDate.endOf('month').startOf('day').format();
        }
        this.setStateParamDates();
      }
    };
  }

  public searchEndDateChanged = (ctrl?: any): any => {
    let obj = this.ctrl ? this.ctrl : ctrl;
    return (eDate: any, oldVal: any): void => {
      /* tslint:disable:no-param-reassign */
      if (oldVal) {
        oldVal = moment(oldVal);
      }
      if (eDate) {
        eDate = moment(eDate);
      }
      /* tslint:enable:no-param-reassign */

      if (eDate && oldVal && !eDate.isSame(oldVal) && obj.searchBeginDate) {
        let startDate = moment(obj.searchBeginDate).startOf('day');
        let endDate = eDate.startOf('day');
        let today = moment().startOf('day');

        if (endDate.isSame(today)) {
          obj.searchBeginDate = today.format();
        } else if (startDate.isSameOrBefore(today) && endDate.isAfter(today)) {
          obj.searchBeginDate = this.getDefaultStartDate();
        } else if (startDate.isAfter(endDate)) {
          obj.searchBeginDate = endDate.startOf('month').startOf('day').format();
        } else if (startDate.month() < endDate.month() || startDate.year() < endDate.year()) {
          obj.searchBeginDate = endDate.startOf('month').startOf('day').format();
        }
        this.setStateParamDates();
      }
    };
  }
}
