<h1 matDialogTitle>Add New Dashboard</h1>
<form (ngSubmit)="submit()" #dashForm="ngForm" fxLayout="column">
  <mat-dialog-content fxLayout="column">
    <ui-multi-checkbox
      name="dashboards"
      uiInput
      [(ngModel)]="selectedItems"
      placeholder="Dashboards"
      [items]="dashboards$|async"
      itemValue="code"
      itemLabel="value"
      itemLayout="column">
    </ui-multi-checkbox>

  </mat-dialog-content>

  <mat-dialog-actions fxLayoutGap="10px" fxLayoutAlign="end">
    <button type="button" mat-raised-button (click)="cancel()">Cancel</button>
    <button type="submit" mat-raised-button color="primary">Save</button>
  </mat-dialog-actions>
</form>
