import { Injector, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AssociationController } from 'src/features/common/association/association.controller';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ApiCache } from 'cad/common/services/api/api-cache';
import { AssociationApi } from 'src/features/common/association/association-api';

@Injectable()
export class PhysicalLocationExternalContactsApi implements AssociationApi {
  private readonly endpoint: string = 'physical/location/';
  private readonly suffix: string = '/external-contacts';

  static createAssociationApi(
    apiHelper: ApiHelper,
    associationController: AssociationController,
    injector: Injector,
  ): AssociationApi {
    return new PhysicalLocationExternalContactsApi(apiHelper, injector.get(ApiCache));
  }

  constructor(
    private apiHelper: ApiHelper,
    private apiCache: ApiCache,
  ) {}

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(
      this.endpoint + params.pointCd + this.suffix + '/lookup',
      { body: params, cache: this.apiCache.forSession, cacheTag: this.endpoint + params.pointCd + this.suffix + '/lookup' }
    );
  }

  public findAll = (pointCd: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + pointCd + this.suffix);
  }

  public getExternalContactHistory = (pointCd: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + pointCd + this.suffix + '/history');
  }

  public findAvailableExternalContacts = (pointCd: number | string, params?: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + pointCd + this.suffix + '/lookup', { body: params});
  }

  public save = (externalContacts: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + externalContacts[0].pointCd + this.suffix + '/save', { body: externalContacts });
  }
}
