import { Component } from '@angular/core';
import { BaseDashComponent } from 'src/cad/dashboard/views/content/components/base-dash.component';

@Component({
  selector: 'contact-dash',
  templateUrl: './contact-dash.component.html',
})
export class ContactDashComponent extends BaseDashComponent {
  public name: string = 'contact';
  public endPoint: string = 'associate/contact/dashboard';

  public getEmptyHeadline(): string {
    return 'No Recent Contacts';
  }
  public getDefaultHeadline(): string {
    return 'Recent Contacts';
  }
  public getName(): string {
    return this.name;
  }

  public onRowClicked = (event: any): void => {
    super.navigate([ 'data-administration','contact', event.data.contactId ]);
  }
}
