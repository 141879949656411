import { Component, ContentChild, Output, EventEmitter } from '@angular/core';
import { UiDatepickerComponent } from '../datepicker/datepicker.component';
import * as moment from 'moment-timezone';

@Component({
  selector: 'ui-datepicker-carosel',
  templateUrl: './datepicker-carosel.component.html',
  styles: [ `
    .disabled {
      color: rgba(0, 0, 0, 0.38);
    }
  ` ],
})
export class UiDatepickerCaroselComponent {

  @ContentChild(UiDatepickerComponent) datepicker: UiDatepickerComponent;
  @Output() public dayChanged: EventEmitter<any> = new EventEmitter<any>();

  public constructor() {}

  public addDay(): void {
    let theD: moment.Moment = moment(this.datepicker.model);

    if (this.datepicker.maxDate) {
      let maxD: moment.Moment = moment(this.datepicker.maxDate).startOf('day');

      if (theD >= maxD) { return; }
    }

    theD.add(1, 'day');
    this.setDateModel(theD);
    this.dayChanged.emit(theD);
  }

  public subtractDay(): void {
    let theD: moment.Moment = moment(this.datepicker.model);

    if (this.datepicker.minDate) {
      let minD: moment.Moment = moment(this.datepicker.minDate);

      if (theD <= minD) { return; }
    }

    theD.add(-1, 'day');
    this.setDateModel(theD);
    this.dayChanged.emit(theD);
  }

  private setDateModel(dateObj: moment.Moment): void {
    this.datepicker.matDatepickerRef.select(dateObj);
  }
}
