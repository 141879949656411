import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'src/cad/common/services/api/api-helper';

@Injectable()
export class CapacityReleaseOfferLocationsApi {
  private readonly endpoint: string = 'capacity-release/offer/';
  private readonly suffix: string = '/locations';

  constructor(private apiHelper: ApiHelper) {}

  public getById(auctionId: number | string): Observable<any> {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix);
  }

  public save(auctionId: number | string, offerData: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/save', { body: offerData });
  }

  public getMPLCodes(auctionId: number | string): Observable<any> {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/mpl-codes');
  }

  public getContractLocations(auctionId: number | string): Observable<any> {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/contract-locations');
  }

  public getMRPLLocations(auctionId: number | string): Observable<any> {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/mrpl-locations');
  }

  public getMDPLLocations(auctionId: number | string): Observable<any> {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/mdpl-locations');
  }

  public getLocationGroupLocations(auctionId: number | string): Observable<any> {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/location-group-locations');
  }

  public getExpandedSeasonLocations(auctionId: number | string, offerData: any): Observable<any> {
    return this.apiHelper.request(
      this.endpoint + auctionId + this.suffix + '/expanded-season-locations',
      { body: offerData }
    );
  }
}
