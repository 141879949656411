<div [ngSwitch]="type" (click)="isInvalid && $event.stopPropagation()">

  <div *ngSwitchCase="'dropdown'">
    <button mat-button mat-raised-button [mat-menu-trigger-for]="menu" [color]="option" [disabled]="isInvalid || securityDisabled" >
      {{label}} <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>

  <div class="arrow-drop-down" fxLayout="row" *ngSwitchCase="'split'">
    <button mat-button mat-raised-button [color]="option" [disabled]="isInvalid || securityDisabled">
      {{label}}
    </button>
    <button class="down-button" mat-button mat-raised-button [color]="option" [mat-menu-trigger-for]="menu" (click)="$event.stopPropagation()" [disabled]="isInvalid">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>

  <span *ngSwitchCase="'submit'">
    <button mat-button mat-raised-button type="submit" [color]="option" [disabled]="isInvalid || securityDisabled" [attr.skip-disable]="skipDisable">
      {{label}} <ng-container [ngTemplateOutlet]="template"></ng-container>
    </button>
  </span>

  <div *ngSwitchCase="'button-alert'">
      <button type="button" mat-button mat-raised-button (click)="showAlert()" [color]="option" [disabled]="isInvalid || securityDisabled" [attr.skip-disable]="skipDisable">
      {{label}} <ng-container [ngTemplateOutlet]="template"></ng-container>
      </button>
  </div>

  <div *ngSwitchCase="'flat-button-alert'">
      <button type="button" mat-button (click)="showAlert()" [color]="option" [disabled]="isInvalid || securityDisabled" [attr.skip-disable]="skipDisable">
      {{label}} <ng-container [ngTemplateOutlet]="template"></ng-container>
      </button>
  </div>

  <!--use type="button" to fall into this default-->
  <span *ngSwitchDefault>
    <button mat-button mat-raised-button [type]="type" [color]="option" [disabled]="isInvalid || securityDisabled" [attr.skip-disable]="skipDisable">
      {{label}} <ng-container [ngTemplateOutlet]="template"></ng-container>
    </button>
  </span>

</div>
<mat-menu #menu="matMenu" [overlapTrigger]="false">
    <ng-container [ngTemplateOutlet]="dropDownTemplate"></ng-container>
</mat-menu>
