import { Component, Input, forwardRef, OnInit, OnChanges, SimpleChanges, DoCheck } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { UIControlValueAccessor } from 'common/components/form/control-value-accessor';

@Component({
  selector: 'ui-yn-checkbox',
  template: `<mat-checkbox [(ngModel)]="boolVal" [labelPosition]="labelPosition" [disabled]="disabled" [required]="required">
                <ng-content></ng-content>
             </mat-checkbox>`,
  providers: [ { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UiYNCheckboxComponent), multi: true } ],
})
export class UiYNCheckboxComponent extends UIControlValueAccessor {
  @Input() required: string;
  @Input() disabled: boolean;
  @Input() labelPosition: string;

  protected _boolVal: boolean;

  get boolVal(): boolean {
    if (this.model) {
      this._boolVal = (this.model === 'Y');
    }
    return this._boolVal;
  }

  set boolVal(v: boolean) {
    this._boolVal = v;
    if (v === undefined || v === null) {
      this.model = v;
    } else if (v) {
      this.model = 'Y';
    } else {
      this.model = 'N';
    }
  }

}
