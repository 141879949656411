import { Component, Inject, forwardRef } from '@angular/core';
import { UiExpandableDirective } from '../expandable.directive';

@Component({
  selector: 'expandable-fullheight-button',
  templateUrl: './expandable-fullheight-button.component.html',
  styleUrls: [ '../expandable.less' ], })
export class ExpandableFullheightButtonComponent {
  constructor(@Inject(forwardRef(() => UiExpandableDirective)) public parent: UiExpandableDirective) {}
}
