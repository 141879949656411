import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'delete-modal',
  templateUrl: './delete-modal.component.html',
})
export class DeleteModalComponent implements OnInit {

  public noticeContactPageUrl: string;
  public message: string;
  public title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DeleteModalComponent>,
  ) {
    this.message = this.data.message;
    this.title = this.data.title;
  }

  public ngOnInit(): void {
  }

  public cancel = (): void => {
    this.dialogRef.close(false);
  }

  public submit = (): void => {
    this.dialogRef.close(true);
  }
}
