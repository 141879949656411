import { Component, Input } from '@angular/core';
import { EmitterService } from 'cad/core/services/emitter/emitter.service';

@Component({
  selector: 'ui-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: [
    './save-button.component.less',
  ],
})
export class SaveButtonComponent {
  @Input() public label: string;
  @Input() public disabled: boolean = false;

  constructor(private emitterService: EmitterService) {}

  public save (): void {
    this.emitterService.uiFormSubmitterClicked.next();
  }
}
