import { Directive, HostBinding } from '@angular/core';

/**
 * Fixed header that will be rendered above a select's options.
 */
@Directive({
  selector: 'ui-mat-virtual-select-header, [uiMatVirtualSelectHeader]',
})
export class UiMatVirtualSelectHeaderDirective {
  @HostBinding('class') headerClass: string = 'ui-mat-virtual-select-header';
}
