import { Injectable } from '@angular/core';
import { ApiCache } from 'src/cad/common/services/api/api-cache';
import { UserProfile } from 'src/cad/common/models/user/user-profile';
import {Availableasset} from 'cad/common/store/user/user-model';
/**
 * Created by jodell on 12/1/2016.
 */

@Injectable()
export class ProfileService {

  constructor(private apiCache: ApiCache) {
    this.apiCache = apiCache;
  }

  public storeProfile(user: string, profile: UserProfile): void {
    this.apiCache.forSession.put(user + 'profile', profile);
  }

  public retrieveProfile(user: string): UserProfile {
    let stringProfile = this.apiCache.forSession.get(user + 'profile');
    if (stringProfile) {
      return new UserProfile(stringProfile._asset, stringProfile._ba, user);
    }
    return null;
  }

  public createUserProfile(asset: Availableasset, ba: string, userId: string): UserProfile {
    let userProfile = new UserProfile(asset, ba, userId);
    this.storeProfile(userId, userProfile);
    return userProfile;
  }
}
