<ui-card [headline]="headline$|async" [cardToolBarItems]="menuItems$|async" [isExpanded]="true">
  <div *ngIf="itemData$|async;let itemData;">
    <ui-table *ngIf="itemData.length > 0" (rowClicked)="onRowClicked($event)" [data]="itemData" fxLayout="column" [suppressHorizontalScroll]="false">
      <ui-table-column name="parentContractId" type="numberStr" headerName="Svc Req K" [minWidth]="70" [maxWidth]="70"></ui-table-column>
      <ui-table-column name="contractType"
                       etCodeRenderer
                       codeType="CONTRACTTYPEIND"
                       headerName="Type Ind"></ui-table-column>
      <ui-table-column name="rateScheduleName" headerName="Rate Schedule" [minWidth]="70"></ui-table-column>
      <ui-table-column name="amendmentId" type="numberStr" headerName="Amend ID" [maxWidth]="38"></ui-table-column>
      <ui-table-column name="serviceRequesterName" headerName="Svc Req" [minWidth]="120"></ui-table-column>
      <ui-table-column name="approvalTypeCd" headerName="Approval Type" codeType="APPROVALTYPE" etCodeRenderer [minWidth]="100"></ui-table-column>
      <ui-table-column name="approvalStatusCd" headerName="Approval Status" etCodeRenderer codeType="APPROVALSTATUS" [minWidth]="90"></ui-table-column>
      <ui-table-column name="primaryTermBeginDate" headerName="Begin Date" type="date" [minWidth]="90" [maxWidth]="90"></ui-table-column>
      <ui-table-column name="primaryTermEndDate" headerName="End Date" type="date" [minWidth]="90" [maxWidth]="90"></ui-table-column>
    </ui-table>
  </div>
</ui-card>
