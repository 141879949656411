import { Component, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { TableColumnToggleRenderer } from '../checkbox/checkbox-renderer.component';

@Component({
  moduleId: module.id + '',
  selector: 'table-column-checkbox-editor',
  template: `
    <div class="editor" (keydown)="onKeyDown($event)" (click)="onClick($event)" tabindex="0">
      <span class="ag-selection-checkbox">
        <span class="ag-icon ag-icon-checkbox-{{isChecked() ? '' : 'un'}}checked"></span>
      </span>
    </div>
  `,
})
export class TableColumnCheckboxEditorComponent extends TableColumnToggleRenderer implements AfterViewInit {
  public mode: string;

  constructor(public el: ElementRef, public renderer: Renderer2) {
    super();
  }

  agInit(params: any): void {
    this.params = params;
    this.mode = 'edit';
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.el.nativeElement.querySelector('.editor').focus());
  }

  onClick(e: MouseEvent): void {
    this.valueChanged(e);
  }
}
