import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CoreServicesModule } from 'cad/core/services/core-services.module';
import { CoreUtilsModule } from 'cad/core/utils/core-utils.module';
import { throwIfAlreadyLoaded } from './module-import-guard';

@NgModule({
  imports: [
    CoreServicesModule,
    CoreUtilsModule,
  ],
  exports: [
    CoreServicesModule,
    CoreUtilsModule,
  ]
})
export class CoreModule {
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
