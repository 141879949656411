import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { RouterService } from 'src/cad/core/services/router/router.service';
import { ScheduleNominationData } from 'cad/flowing-gas/scheduling/autoscheduling/interfaces/schedule-nomination-data';
import { PoolOverrunData } from 'cad/flowing-gas/scheduling/autoscheduling/interfaces/pool-overrun-data';
import { NominationData } from 'cad/nominations/nomination/nomination-reconciliation/store/nom-recon-detail-data';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ViewPoolBalanceInfoApi {
  private readonly endpoint: string = 'flowing-gas/scheduling/view-pool-balance-info';

  constructor(
    public apiHelper: ApiHelper,
    public routerService: RouterService
  ) {}

  public filter(params: Partial<PoolOverrunData>): Observable<PoolOverrunData[]> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public getScheduleNomination(data: PoolOverrunData): Observable<ScheduleNominationData[]> {
    return this.apiHelper.request(this.endpoint + '/find', {body: data });
  }

  public getNominationList(data: ScheduleNominationData): Observable<NominationData[]> {
    return this.apiHelper.request(this.endpoint + '/noms', {body: data });
  }

  public generateAutoBalancingNoms(flowDate: string, cycle: string): Observable<NominationData[]> {
    const mmddyyyDate = moment(flowDate).format('MM/DD/YYYY');
    let params: HttpParams = new HttpParams();
    params = params.set('flowDate', mmddyyyDate);
    params = params.set('cycle', cycle);
    return this.apiHelper.request(this.endpoint + '/generateAutoBalancingNoms', { params, method: 'POST' });
  }
}
