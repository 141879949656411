import { Component, Inject, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take, concatMap, map } from 'rxjs/operators';
import { AlertsService } from 'src/cad/core/services/alerts/alerts.service';
import { ProfileTypeListService } from 'cad/administration/shared/profile-type-list.service';
import { CodesModel } from 'src/cad/common/models/codes/codes-model';
import { UiFormComponent } from 'src/common/components/form/form.component';
import { ContactNotification } from '../interfaces/contact-notification';
import { CodeValueData } from 'src/cad/shared/interfaces/code-value-data';
import { isNil, uniq, every, intersectionBy } from 'lodash';

@Component({
  selector: 'add-email-notification-modal',
  templateUrl: 'add-email-notification.component.html',
  styleUrls: [ 'add-email-notification.component.less' ]
})
export class AddEmailNotificationModalComponent implements OnInit, AfterViewInit {

  @ViewChild(UiFormComponent) public uiForm: UiFormComponent;
  public contactId: number;
  public contactNotificationList: ContactNotification[] = [];
  public selectedNotificationTypes: string[] = [];
  public disabledNotificationTypes: string[] = [];
  public disabledProfileTypes: { [ key: string ]: string[] } = {};
  public contactNotificationProfileTypeList: CodeValueData[] = [];
  public isDirty: boolean;

  constructor(
    public dialogRef: MatDialogRef<AddEmailNotificationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: any,
    public alertsService: AlertsService,
    public codesModel: CodesModel,
    public profileTypeListService: ProfileTypeListService
  ) {
    if (this.matDialogData.contactId) {
      this.contactId = this.matDialogData.contactId;
    }
    if (this.matDialogData.contactNotificationList) {
      this.contactNotificationList = this.matDialogData.contactNotificationList;
    }
  }

  ngOnInit(): void {
    this.codesModel.getCodesForType('CONTACTPROFILE')
    .pipe(
      concatMap((contactProfileTypeList: CodeValueData[]) => {
        // remove all other values not added to contact Info
        return this.profileTypeListService.currentProfileTypeList
        .pipe(
          map((currentContactProfileTypeList: string[]) =>
            intersectionBy(contactProfileTypeList, currentContactProfileTypeList.map((currentContactProfileType: string) => ({ cd: currentContactProfileType })), 'cd')
          ));
      }),
      take(1)
    )
    .subscribe((contactProfileTypeList: CodeValueData[]) => {
      this.contactNotificationProfileTypeList = contactProfileTypeList;
      this.updateDisabledNotificationTypes();
      this.contactNotificationList.forEach((contactNotification: ContactNotification) => this.updateDisabledProfileTypes(contactNotification.notificationTypeCd));
    });
  }

  ngAfterViewInit(): void {
    if (this.uiForm
      && this.uiForm.form
      && this.uiForm.form.control) {
      this.uiForm.form.control.enable();
    }
  }

  public addNotificationRows(): void {
    this.selectedNotificationTypes.forEach((notificationTypeCd: string) => {
      if (!this.isDuplicateType(this.contactNotificationList, notificationTypeCd)) {
        let newContactNotification: ContactNotification = new ContactNotification();
        newContactNotification.dirtyStatus = cad.DirtyStatus.NEW;
        newContactNotification.notificationTypeCd = notificationTypeCd;
        newContactNotification.contactId = this.contactId;
        newContactNotification.effBeginDt = dateUtil().getFirstDayOfMonth();
        newContactNotification.effEndDt = dateUtil().getDefaultEndDate();
        this.contactNotificationList.push(newContactNotification);
        this.updateDisabledProfileTypes(newContactNotification.notificationTypeCd);
      }
    });
    this.selectedNotificationTypes = [];
    this.updateDisabledNotificationTypes();
    this.evaluateFormDirtyStatus();
  }

  public removeNotificationRow(index: number): void {
    let contactNotification: ContactNotification = this.contactNotificationList[ index ];

    if (contactNotification.dirtyStatus === cad.DirtyStatus.NEW) {
      this.contactNotificationList.splice(index, 1);
    } else {
      contactNotification.dirtyStatus = cad.DirtyStatus.DELETE;
    }
    this.updateDisabledProfileTypes(contactNotification.notificationTypeCd);
    this.updateDisabledNotificationTypes();
    this.evaluateFormDirtyStatus();
  }

  public onSubmit(): void {
    if (this.contactNotificationList && this.contactNotificationList.length > 0) {
      this.dialogRef.close(this.contactNotificationList);
    }
  }

  public setFormDirty(): void {
    if (this.uiForm
      && this.uiForm.form
      && this.uiForm.form.control
      && !this.uiForm.form.control.dirty) {
      this.uiForm.dirtyStatusChanged.emit({ dirtyStatus: true });
    }
  }

  public formFieldChanged(index: number): void {
    if (this.contactNotificationList[ index ].dirtyStatus === cad.DirtyStatus.NO_CHANGE) {
      this.contactNotificationList[ index ].dirtyStatus = cad.DirtyStatus.UPDATE;
    }
    this.updateDisabledNotificationTypes();
    this.updateDisabledProfileTypes(this.contactNotificationList[ index ].notificationTypeCd);
    this.evaluateFormDirtyStatus();
  }

  public updateDisabledNotificationTypes(): void {
    if (this.contactNotificationList) {
      this.disabledNotificationTypes = [];
      uniq(this.contactNotificationList.map((contactNotification: ContactNotification) => contactNotification.notificationTypeCd))
      .forEach((notificationType: string) => {
        if (this.hasAllProfileTypes(notificationType)) {
          this.disabledNotificationTypes.push(notificationType);
        }
      });
    }
  }

  public updateDisabledProfileTypes(notificationTypeCd: string): void {
    if (!isNil(notificationTypeCd) && this.contactNotificationList) {
      this.disabledProfileTypes[ notificationTypeCd ] = this.contactNotificationProfileTypeList
      .map((contactNotificationProfileTypeCode: CodeValueData) => contactNotificationProfileTypeCode.cd)
      .filter((contactNotificationProfileType: string) => {
        return this.contactNotificationList.filter((contactNotification: ContactNotification) => !this.isDelete(contactNotification)).find(
            (contactNotification: ContactNotification) => contactNotification.notificationTypeCd === notificationTypeCd
            && contactNotification.profileTypeCd === contactNotificationProfileType
          );
      });
    }
  }

  public isDelete(contactNotification: ContactNotification): boolean {
    return contactNotification && contactNotification.dirtyStatus === cad.DirtyStatus.DELETE;
  }

  public isDuplicateType(contactNotificationList: ContactNotification[], notificationTypeCd: string): boolean {
    if (contactNotificationList) {
      return !!contactNotificationList.find((contactNotification: ContactNotification) => {
        return contactNotification.notificationTypeCd === notificationTypeCd
          && contactNotification.effBeginDt === dateUtil().getFirstDayOfMonth()
        && contactNotification.effEndDt === dateUtil().getDefaultEndDate();
      }) && this.hasAllProfileTypes(notificationTypeCd);
    }
    return false;
  }

  public hasAllProfileTypes(notificationTypeCd: string): boolean {
    let profilesByType: string[] = [];

    if (!isNil(notificationTypeCd) && this.contactNotificationList) {
      profilesByType = this.contactNotificationList.filter((contactNotification: ContactNotification) => {
        return contactNotification.notificationTypeCd === notificationTypeCd && !this.isDelete(contactNotification);
      }).map((contactNotification: ContactNotification) => contactNotification.profileTypeCd);
    }
    return every(
      this.contactNotificationProfileTypeList.map((contactNotificationProfileTypeCode: CodeValueData) => contactNotificationProfileTypeCode.cd),
      (profileType: string) => profilesByType.includes(profileType)
    );
  }

  public isSubmitDisabled(): boolean {
    if (this.uiForm) {
      return !(this.uiForm.form.valid && this.isDirty);
    }
    return true;
  }

  public evaluateFormDirtyStatus(): void {
    let hasDirtyStatus: boolean = !!this.contactNotificationList.find((contactNotification: ContactNotification) => {
      return (contactNotification.dirtyStatus === cad.DirtyStatus.NEW
      || contactNotification.dirtyStatus === cad.DirtyStatus.UPDATE
      || contactNotification.dirtyStatus === cad.DirtyStatus.DELETE);
    });
    if (hasDirtyStatus) {
      this.setFormDirty();
    }
  }
}
