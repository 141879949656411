import {
  Component,
  Injector,
  InjectionToken,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { NgComponentOutlet } from '@angular/common';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

export let TABLE_ROW_DATA: InjectionToken<string> = new InjectionToken<string>('TableRowParams');

@Component({
  moduleId: module.id + '',
  selector: 'detail-row',
  templateUrl: './detail-row.component.html',
  styles: [ ':host { display: block; }' ]
})
export class DetailRowComponent implements ICellRendererAngularComp, AfterViewInit, OnDestroy {

  @ViewChild(NgComponentOutlet) public ngComponentOutletRef: NgComponentOutlet;
  public params: any;
  public childComponent: any;
  public detailInjector: Injector;
  private previousHeight: number = 0;

  constructor(
    public injector: Injector,
    public elementRef: ElementRef
  ) {}

  agInit(params: any): void {
    this.params = params;
    this.detailInjector = Injector.create([
      {
        provide: TABLE_ROW_DATA,
        useFactory: () => params,
        deps: [],
      },
    ], this.injector);
  }

  ngAfterViewInit(): void {
    if (this.ngComponentOutletRef && (this.ngComponentOutletRef as any)._componentRef) {
      this.childComponent = (this.ngComponentOutletRef as any)._componentRef.instance;
      setTimeout(() => {
        if (this.params
          && this.params.node
          && this.params.api
          && this.elementRef
          && this.elementRef.nativeElement) {
          const height: number = this.elementRef.nativeElement.clientHeight;
          if (height !== this.previousHeight) {
            this.params.node.setRowHeight(height);
            this.params.api.onRowHeightChanged();
            this.previousHeight = height;
          }
        }
      }, 1200);
    }
  }

  ngOnDestroy(): void {
    this.params = null;
    this.detailInjector = null;
    this.childComponent = null;
  }

  refresh (params: any): boolean {
    this.params = params;
    return true;
  }
}
