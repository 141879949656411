
import {of as observableOf,  Observable } from 'rxjs';

import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class PhysicalAssetApi {
  private readonly endpoint: string = 'physical/asset';

  constructor(
    private apiHelper: ApiHelper,
  ) {}


  /*
  Find all assets in group.  This should be used more often.
   */
  public findAll = (): Observable<any> => {
    return this.apiHelper.request(this.endpoint).pipe(tap((data) => { data.assetId = data.number; }));
  }

  /*
  Find all assets without taking group into account.  This will not be used as often.
   */
  public findAllAssets(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/all').pipe(tap((data) => { data.assetId = data.number; }));
  }

  // TODO(ng2): this makes no sense. no id is pased in and this function would just do the same thing as findAll() --- the js version of this was the same. gmagolan
  public getById = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint).pipe(tap((data) => { data.assetId = data.number; }));
  }

  public save = (): Observable<void> => {
    // TODO(ng2): implement or remove
    return observableOf(undefined);
  }
}
