import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ApiHelper} from 'cad/common/services/api/api-helper';
import {StorageMonitoringSearch} from 'cad/flowing-gas/storage-monitoring/shared/interfaces/storage-monitoring-search';
import {StorageMonitoring} from 'cad/flowing-gas/storage-monitoring/shared/interfaces/storage-monitoring';
import {
  StorageMonitoringNavdata
} from 'cad/flowing-gas/storage-monitoring/shared/interfaces/storage-monitoring-navdata';
import {MatrixHeaderAndList} from 'cad/common/components/matrix/model/matrix-header-and-list.model';
import {
  StorageInformation as InjectionWithdrawalDailyDetailInformation
} from 'cad/flowing-gas/storage-monitoring/shared/interfaces/storage-information';
import {
  InjectionWithdrawalDailyDetail
} from 'cad/flowing-gas/storage-monitoring/injection-withdrawal-daily-detail/shared/interface/injection-withdrawal-daily-detail';


@Injectable()
export class StorageMonitoringApi {

  private readonly endpoint: string = 'flowing-gas/storage-monitoring';

  constructor(private apiHelper: ApiHelper) {
  }

  public getAll(filterData: StorageMonitoringSearch): Observable<StorageMonitoring[]> {
    return this.apiHelper.request(this.endpoint + '/dashboard', {body: filterData});
  }

  public getHeaderAndList(params: StorageMonitoringNavdata): Observable<MatrixHeaderAndList<InjectionWithdrawalDailyDetail,InjectionWithdrawalDailyDetailInformation>> {
    return this.apiHelper.request(this.endpoint + '/inj-withdraw-header-and-list/', {body: params});
  }

}
