

import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Controller } from 'src/framing/controller';
import { RouterService } from 'src/cad/core/services/router/router.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UiSidenavComponent } from 'common/components/sidenav/sidenav.component';

@Component({
  selector: 'ui-nav-toggle',
  templateUrl: './nav-toggle.component.html',
  styleUrls: [ './nav-toggle.component.less' ],
})
export class UiNavToggleComponent implements OnDestroy, OnInit {

  @Input() public section: any;
  public toggleState: boolean = false;

  @Output() private onToggle: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('toggleList') private element: any;
  private urls: any;
  private unSub: Subject<void> = new Subject<void>();

  constructor(
    private router: RouterService,
    private router2: Router,
    private injector: Injector,
    public uiSidenavComponent: UiSidenavComponent
  ) {}

  public ngOnInit(): void {
    this.section.state = this.section.state.replace('.', '');
    this.section.state = this.section.state.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
    this.activateCurrent();
    this.router.events.pipe(takeUntil(this.unSub)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activateCurrent();
      }
    });
  }

  public ngOnDestroy(): void {
    this.unSub.next();
    this.unSub.complete();
    this.element = null;
    this.section = null;
  }

  public navigate(): void {
    // just get the ActivatedRoute when needed... don't store it as an attribute as it contains references
    // views & ui-nav-toggle is in the sidenav so it doesn't get destroyed on all screen changes
    const activatedRoute: ActivatedRoute = this.injector.get(ActivatedRoute);

    if (this.section && this.section.state) {
      //there is an known issue with the router in version 5.x in which going up a level does not work well.
      //this is a work around.  This issue is fixed in version 7.x
      const sectionStateSplit = this.section.state.split('/');
      if (_.indexOf(sectionStateSplit, '..') >= 0) {
        this.upSegments(sectionStateSplit);
      } else {
        this.router.navigate([ this.section.state ], { relativeTo: activatedRoute });
      }
    } else {
      this.router.navigate([ Controller.buildUrlLink(activatedRoute.snapshot) ]);
    }
  }

  public toggle(): void {
    this.navigate();
    this.onToggle.emit(this.element);
  }

  public setPageActive(page: any): void {
    this.section.pages.forEach((p) => p.isActive = false);
    page.isActive = true;
  }

  private upSegments(stateArray: string[]): void {
    const url = this.router2.url.split('?')[ 0 ];
    const split = url.split('/');
    const index = _.lastIndexOf(stateArray, '..');
    let x = 0;
    while (x <= index) {
      x++;
      split.pop();
    }
    _.pull(stateArray, '..');
    const newStateSegments = stateArray.join('/');
    const newUrl = split.length > 1 ? split.join('/') : '/';
    this.router2.navigateByUrl(newUrl + '/' + newStateSegments);
  }

  private activateCurrent(): void {
    // just get the ActivatedRoute when needed... don't store it as an attribute as it contains references
    // views & ui-nav-toggle is in the sidenav so it doesn't get destroyed on all screen changes
    const activatedRoute: ActivatedRoute = this.injector.get(ActivatedRoute);
    let current = this.section && this.section.state
      ? this.router.ngRouter.createUrlTree([ this.section.state ], { relativeTo: activatedRoute })
      : this.router.ngRouter.createUrlTree([ Controller.buildUrlLink(activatedRoute.snapshot) ]);
    if (this.router.ngRouter.isActive(current, false)) {
      if (this.element) {
        this.uiSidenavComponent.activeSectionListElem = this.element.nativeElement;
      }
      this.uiSidenavComponent.expandSection(this.section);
    }
  }
}
