import { GridModule } from 'src/ag-grid-wrapper/grid.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from 'src/cad/app-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';
import { AllocationScheduleQuantityHeaderComponent } from '../common/components/header/allocation-schedule-quantity-for-operator/header.component';
import { UiSearchModule } from 'common/components/search/search.module';
import { UiBottomSheetService } from 'common/components/bottom-sheet/bottom-sheet.service';
import { PipesModule } from 'cad/common/filters/pipes.module';
import { SearchService } from 'common/services/search/search-service';
import { AuthInterceptorService } from 'common/services/http-interceptor/auth-interceptor-service';
import { PinnedSearchCacheService } from 'src/common/services/search/pinned-search-cache.service';
import { IdleService } from 'cad/core/services/session/idle-service';
import { NewItemInputService } from 'src/features/common/new-item/new-item-input.service';
import { MediumContentEditor } from './medium-editor/medium-editor.component';
import { FeatureFlagsDirective } from 'common/feature-flags/feature-flags-directive';
import { ScheduledQuantityForOperatorActionService } from 'cad/nominations/scheduled-quantity/shared/scheduled-quantity-for-operator-action.service';
import { ConfirmationComponent } from 'cad/shared/components/confirmation-box/confirmation.component';
import { ItemCardComponent } from 'src/features/common/item/components/item-card/item-card.component';
import { TableLookupComponent } from 'src/features/common/lookup/view/table-lookup/table-lookup.component';
import { LookupController } from 'src/features/common/lookup/lookup.controller';
import { DynamicLookupComponent } from 'src/features/common/modal/view/dynamic-lookup/dynamic-lookup.component';
import { ModalSkeletonComponent } from 'src/features/common/modal/view/modal-skeleton/modal-skeleton.component';
import { ModalController } from 'src/features/common/modal/modal.controller';
import { AutoFocusDirective } from 'common/components/form/auto-focus/auto-focus.directive';
import { CollapseDirective } from 'common/components/collapse/collapse.component';
import { DraggableModalDirective } from 'common/components/draggable/draggable-modal.directive';
import { EmbedDirective } from 'common/components/form/embed/embed.component';
import { LengthHintDirective } from 'common/components/form/mat-form-field/length-hint.directive';
import { ModelFormatterDirective } from 'common/components/form/model-formatter/model-formatter.directive';
import { NumberDirective } from 'common/components/form/lookup/numbers-only.directive';
import { TableDisableDirective } from 'common/components/form/disable/table.disable.directive';
import { UiDisableDirective } from 'common/components/form/disable/disable.directive';
import { UiExpandableDirective } from 'common/components/expandable/expandable.directive';
import { UiInputDigitDirective } from 'common/components/form/input-digit/input-digit.directive';
import { UiInputDirective } from 'common/components/form/input/input.directive';
import { UiInputHrefDirective } from 'common/components/form/input-href/input-href.directive';
import { UiInputMaskDirective } from 'common/components/form/input-mask/input-mask.directive';
import { UiLayoutColumnDirective } from 'common/components/layout/column/column.directive';
import { UiQueryDirective } from 'common/components/query/query.directive';
import { UiQueryFilterExcludeItemsDirective } from 'common/components/query/exclude-items.directive';
import { UiVirtualRepeatDirective } from 'common/components/virtual-repeat/virtual-repeat.directive';
import { WildcardTextValueAccessorDirective } from 'common/components/form/wildcard-text/wildcard-text.directive';
import { AutocompleteMenuComponent } from 'common/components/autocomplete-menu/autocomplete-menu.component';
import { ButtonAlertComponent } from 'common/components/form/button/button-alert/button-alert.component';
import { ButtonComponent } from 'common/components/form/button/button.component';
import { ButtonContentComponent } from 'common/components/form/button/button-content.component';
import { ButtonDropdownComponent } from 'common/components/form/button/button-dropdown/button-dropdown.component';
import { CadSearchComponent } from 'cad/common/components/search/search.component';
import { ExcelImportComponent } from 'common/components/excel-import/excel-import.component';
import { ExpandableButtonComponent } from 'common/components/expandable/expandable-button/expandable-button.component';
import { ExpandableFullheightButtonComponent } from 'common/components/expandable/expandable-button/expandable-fullheight-button.component';
import { FormGroupObserverComponent } from 'common/components/form/form-group-observer/form-group-observer.component';
import { SaveButtonComponent } from 'common/components/form/save-button/save-button.component';
import { SearchResultsComponent } from 'common/components/search/results-ng2/search-results.component';
import { UiAccordionComponent } from 'common/components/accordion/accordion.component';
import { UiAccordionPanelComponent } from 'common/components/accordion/accordion-panel/accordion-panel.component';
import { UiActionCardComponent } from 'common/components/layout/action-card/action-card.component';
import { UiAlertsButtonComponent } from 'common/components/alerts-button/alerts-button.component';
import { UiBottomSheetComponent } from 'common/components/bottom-sheet/bottom-sheet.component';
import { UiCalendarHeaderComponent } from 'common/components/form/datepicker/calendar-header.component';
import { UiDatepickerCaroselComponent } from 'common/components/form/datepicker-carosel/datepicker-carosel.component';
import { UiDatepickerComponent } from 'common/components/form/datepicker/datepicker.component';
import { UiFloatButtonComponent } from 'common/components/form/float-button/float-button.component';
import { UiFormComponent } from 'common/components/form/form.component';
import { UiFormYesNoComponent } from 'common/components/form/yesno/yesno.component';
import { UiFullscreenBodyComponent } from 'common/components/fullscreen/body/fullscreen-body.component';
import { UiFullscreenComponent } from 'common/components/fullscreen/fullscreen.component';
import { UiFullscreenFooterComponent } from 'common/components/fullscreen/footer/fullscreen-footer.component';
import { UiFullscreenHeaderComponent } from 'common/components/fullscreen/header/fullscreen-header.component';
import { UiLayoutArticleComponent } from 'common/components/layout/article/article.component';
import { UiLayoutCardComponent } from 'common/components/layout/card/card.component';
import { UiLayoutCardFillComponent } from 'common/components/layout/card-fill/card-fill.component';
import { UiLookupComponent } from 'common/components/form/lookup/lookup.component';
import { UiMdIconComponent } from 'common/components/form/icon/icon.component';
import { UiModalBodyComponent } from 'common/components/modal/body/modal-body.component';
import { UiModalFooterComponent } from 'common/components/modal/footer/modal-footer.component';
import { UiModalHeaderComponent } from 'common/components/modal/header/modal-header.component';
import { UiMultiCheckboxComponent } from 'common/components/form/check-box/multi-checkbox.component';
import { UiNavHrefComponent } from 'common/components/sidenav/nav-href/nav-href.component';
import { UiNavLinkComponent } from 'common/components/sidenav/nav-link/nav-link.component';
import { UiNavToggleComponent } from 'common/components/sidenav/nav-toggle/nav-toggle.component';
import { UiProcessStatusComponent } from 'common/components/process-status/process-status.component';
import { UiSidenavComponent } from 'common/components/sidenav/sidenav.component';
import { UiSplitPaneComponent } from 'common/components/layout/split-pane/split-pane.component';
import { UiStepperComponent } from 'common/components/stepper/stepper.component';
import { UiSwitchComponent } from 'common/components/form/switch/switch.component';
import { UiVirtualRepeatComponent } from 'common/components/virtual-repeat/virtual-repeat.component';
import { UiYNCheckboxComponent } from 'common/components/form/check-box/yn-checkbox.component';
import { UpdaterFieldComponent } from 'common/components/updater-field/updater-field.component';
import { AssociationComponent } from 'src/features/common/association/view/association/association.component';
import { DialogChipComponent } from 'src/features/common/association/view/dialog-chip/dialog-chip.component';
import { FormOnlyDialogComponent } from 'src/features/common/association/view/form-only-dialog/form-only-dialog.component';
import { FullscreenDialogComponent } from 'src/features/common/association/view/fullscreen-dialog/fullscreen-dialog.component';
import { FullscreenDialogHeaderComponent } from 'src/features/common/association/view/fullscreen-dialog-header/fullscreen-dialog-header.component';
import { FullscreenDialogFooterComponent } from 'src/features/common/association/view/fullscreen-dialog-footer/fullscreen-dialog-footer.component';
import { FullscreenDialogBodyComponent } from 'src/features/common/association/view/fullscreen-dialog-body/fullscreen-dialog-body.component';
import { SelectCodeEditorDirective } from 'cad/shared/table-renderers/select-code/select-code-editor.directive';
import { ETCodeRendererDirective } from 'cad/shared/table-renderers/et-code/et-code-renderer.directive';
import { DatepickerEditorDirective } from 'cad/shared/table-renderers/datepicker/datepicker-editor.directive';
import { DynamicTypeEditorDirective } from 'cad/shared/table-renderers/dynamictype/dynamictype-editor.directive';
import { PhoneNumberDirective } from 'cad/shared/table-renderers/phone-number/phone-number.directive';
import { WholeNumberDirective } from 'cad/shared/table-renderers/whole-number/whole-number.directive';
import { TimeEditorDirective } from 'cad/shared/table-renderers/time/time-editor.directive';
import { DunsNumberDirective } from 'cad/shared/table-renderers/duns-number/duns-number.directive';
import { LookupEditorDirective } from 'cad/shared/table-renderers/lookup/lookup-editor.directive';
import { ETDropdownRendererDirective } from 'cad/shared/table-renderers/et-dropdown/et-dropdown-renderer.directive';
import { PhoneOrTextDirective } from 'cad/shared/table-renderers/phone-or-text-editor/phone-or-text.directive';
import { IntegerNumberDirective } from 'cad/shared/table-renderers/interger-number/integer-number.directive';
import { SelectCodeEditorComponent } from 'cad/shared/table-renderers/select-code/select-code-editor.component';
import { EtCodeFilter } from 'cad/shared/table-renderers/et-code/et-code-filter';
import { CheckBoxBooleanFilter } from 'cad/shared/table-renderers/checkbox-boolean-filter/checkbox-boolean-filter';
import { ETCodeRendererComponent } from 'cad/shared/table-renderers/et-code/et-code-renderer.component';
import { ETCodeRendererCDComponent } from 'cad/shared/table-renderers/et-code/et-code-renderer-cd.component';
import { DatepickerEditorComponent } from 'cad/shared/table-renderers/datepicker/datepicker-editor.component';
import { DatepickerRendererComponent } from 'cad/shared/table-renderers/datepicker/datepicker-renderer.component';
import { PhoneNumberRendererComponent } from 'cad/shared/table-renderers/phone-number/phone-number-renderer.component';
import { PhoneNumberEditorComponent } from 'cad/shared/table-renderers/phone-number/phone-number-editor.component';
import { WholeNumberEditorComponent } from 'cad/shared/table-renderers/whole-number/whole-number-editor.component';
import { TimeRendererComponent } from 'cad/shared/table-renderers/time/time-renderer.component';
import { TimeEditorComponent } from 'cad/shared/table-renderers/time/time-editor.component';
import { DunsNumberRendererComponent } from 'cad/shared/table-renderers/duns-number/duns-number-renderer.component';
import { LookupEditorComponent } from 'cad/shared/table-renderers/lookup/lookup-editor.component';
import { PhoneOrTextRendererComponent } from 'cad/shared/table-renderers/phone-or-text-editor/phone-or-text-renderer.component';
import { PhoneOrTextEditorComponent } from 'cad/shared/table-renderers/phone-or-text-editor/phone-or-text-editor.component';
import { IntegerNumberEditorComponent } from 'cad/shared/table-renderers/interger-number/integer-number-editor.component';
import { UiMatVirtualSelectHeaderDirective } from 'cad/shared/forms/select/virtual-select/virtual-select-header.directive';
import { UiOptionDetailComponent } from 'cad/shared/forms/select/features/option-detail/option-detail.component';
import { UiSelectHeaderFilterComponent } from 'cad/shared/forms/select/features/select-header-filter/select-header-filter.component';
import { UiMatVirtualSelectComponent } from 'cad/shared/forms/select/virtual-select/virtual-select.component';
import { UiFormSelectComponent } from 'cad/shared/forms/select/select/select.component';
import { UiFormMultiSelectComponent } from 'cad/shared/forms/select/multi-select/multi-select.component';
import { MatFormFieldDirective } from 'common/components/form/mat-form-field/mat-form-field.directive';
import { UniqueNameValidatorDirective } from 'common/validators/unique-name/unique-name';
import { UniqueAbbrValidatorDirective } from 'common/validators/unique-abbr/unique-abbr';
import { CreditWorthinessValidatorDirective } from 'common/validators/credit-worthy/credit-worthy';
import { UiDateValidatorDirective } from 'common/validators/date/date';
import { UiDigitValidatorDirective } from 'common/validators/digit/digit';
import { UiNumberValidatorDirective } from 'common/validators/number/number';
import { MinNumberValidatorDirective } from 'common/validators/number/min-number-validator.directive';
import { MaxNumberValidatorDirective } from 'common/validators/number/max-number-validator.directive';
import { UiCustomValidatorDirective } from 'common/validators/custom-validator/custom-validator';

// TODO: decomission these (moved here to remove framing-component-outlet.module.ts and framing.module.ts)
import { FramingComponentOutletDirective } from 'src/framing/framing-component-outlet/framing-component-outlet.directive';
import { FramingRootComponent } from 'src/framing/framing-root.component';
import { ControllerFactoryComponent } from 'src/framing/controller-factory.component';
import { AbstractModelsModule } from 'src/cad/common/models/abstract-models.module';

@NgModule({
  imports: [
    AppMaterialModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    GridModule,
    HttpClientModule,
    PipesModule,
    RouterModule,
    UiSearchModule,
    AbstractModelsModule,
  ],
  declarations: [
    // TODO: decomission these (moved here to remove framing-component-outlet.module.ts and framing.module.ts)
    FramingComponentOutletDirective,
    ControllerFactoryComponent,
    FramingRootComponent,

    AllocationScheduleQuantityHeaderComponent,
    ConfirmationComponent,
    DynamicLookupComponent,
    FeatureFlagsDirective,
    ItemCardComponent,
    LoadingBarComponent,
    LookupController,
    MediumContentEditor,
    ModalController,
    ModalSkeletonComponent,
    TableLookupComponent,

    //common components
    AutoFocusDirective,
    CollapseDirective,
    DraggableModalDirective,
    EmbedDirective,
    LengthHintDirective,
    MatFormFieldDirective,
    ModelFormatterDirective,
    NumberDirective,
    TableDisableDirective,
    UiDisableDirective,
    UiExpandableDirective,
    UiInputDigitDirective,
    UiInputDirective,
    UiInputHrefDirective,
    UiInputMaskDirective,
    UiLayoutColumnDirective,
    UiQueryDirective,
    UiQueryFilterExcludeItemsDirective,
    UiVirtualRepeatDirective,
    WildcardTextValueAccessorDirective,
    AutocompleteMenuComponent,
    ButtonAlertComponent,
    ButtonComponent,
    ButtonContentComponent,
    ButtonDropdownComponent,
    CadSearchComponent,
    ExcelImportComponent,
    ExpandableButtonComponent,
    ExpandableFullheightButtonComponent,
    FormGroupObserverComponent,
    SaveButtonComponent,
    SearchResultsComponent,
    UiAccordionComponent,
    UiAccordionPanelComponent,
    UiActionCardComponent,
    UiAlertsButtonComponent,
    UiBottomSheetComponent,
    UiCalendarHeaderComponent,
    UiDatepickerCaroselComponent,
    UiDatepickerComponent,
    UiFloatButtonComponent,
    UiFormComponent,
    UiFormYesNoComponent,
    UiFullscreenBodyComponent,
    UiFullscreenComponent,
    UiFullscreenFooterComponent,
    UiFullscreenHeaderComponent,
    UiLayoutArticleComponent,
    UiLayoutCardComponent,
    UiLayoutCardFillComponent,
    UiLookupComponent,
    UiMdIconComponent,
    UiModalBodyComponent,
    UiModalFooterComponent,
    UiModalHeaderComponent,
    UiMultiCheckboxComponent,
    UiNavHrefComponent,
    UiNavLinkComponent,
    UiNavToggleComponent,
    UiProcessStatusComponent,
    UiSidenavComponent,
    UiSplitPaneComponent,
    UiStepperComponent,
    UiSwitchComponent,
    UiVirtualRepeatComponent,
    UiYNCheckboxComponent,
    UpdaterFieldComponent,

    // shared association components
    AssociationComponent,
    DialogChipComponent,
    FormOnlyDialogComponent,
    FullscreenDialogComponent,
    FullscreenDialogHeaderComponent,
    FullscreenDialogFooterComponent,
    FullscreenDialogBodyComponent,

    // shared table renderers components
    SelectCodeEditorDirective,
    ETCodeRendererDirective,
    DatepickerEditorDirective,
    DynamicTypeEditorDirective,
    PhoneNumberDirective,
    WholeNumberDirective,
    TimeEditorDirective,
    DunsNumberDirective,
    LookupEditorDirective,
    ETDropdownRendererDirective,
    PhoneOrTextDirective,
    IntegerNumberDirective,
    SelectCodeEditorComponent,
    EtCodeFilter,
    CheckBoxBooleanFilter,
    ETCodeRendererComponent,
    ETCodeRendererCDComponent,
    DatepickerEditorComponent,
    DatepickerRendererComponent,
    PhoneNumberRendererComponent,
    PhoneNumberEditorComponent,
    WholeNumberEditorComponent,
    TimeRendererComponent,
    TimeEditorComponent,
    DunsNumberRendererComponent,
    LookupEditorComponent,
    PhoneOrTextRendererComponent,
    PhoneOrTextEditorComponent,
    IntegerNumberEditorComponent,

    // shared forms
    UiMatVirtualSelectHeaderDirective,
    UiOptionDetailComponent,
    UiSelectHeaderFilterComponent,
    UiMatVirtualSelectComponent,
    UiFormSelectComponent,
    UiFormMultiSelectComponent,

    // common validators
    UniqueNameValidatorDirective,
    UniqueAbbrValidatorDirective,
    CreditWorthinessValidatorDirective,
    UiDateValidatorDirective,
    UiDigitValidatorDirective,
    UiNumberValidatorDirective,
    MinNumberValidatorDirective,
    MaxNumberValidatorDirective,
    UiCustomValidatorDirective,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    FlexLayoutModule,
    AppMaterialModule,
    GridModule,
    PipesModule,
    UiSearchModule,
    AllocationScheduleQuantityHeaderComponent,
    ItemCardComponent,
    LoadingBarComponent,
    MediumContentEditor,
    TableLookupComponent,
    FeatureFlagsDirective,

// TODO: decomission these (moved here to remove framing-component-outlet.module.ts and framing.module.ts)
    FramingComponentOutletDirective,
    ControllerFactoryComponent,
    FramingRootComponent,

    // common components
    AutoFocusDirective,
    CollapseDirective,
    DraggableModalDirective,
    EmbedDirective,
    LengthHintDirective,
    MatFormFieldDirective,
    ModelFormatterDirective,
    NumberDirective,
    TableDisableDirective,
    UiDisableDirective,
    UiExpandableDirective,
    UiInputDigitDirective,
    UiInputDirective,
    UiInputHrefDirective,
    UiInputMaskDirective,
    UiLayoutColumnDirective,
    UiQueryDirective,
    UiQueryFilterExcludeItemsDirective,
    UiVirtualRepeatDirective,
    WildcardTextValueAccessorDirective,
    AutocompleteMenuComponent,
    ButtonAlertComponent,
    ButtonComponent,
    ButtonContentComponent,
    ButtonDropdownComponent,
    CadSearchComponent,
    ExcelImportComponent,
    ExpandableButtonComponent,
    ExpandableFullheightButtonComponent,
    FormGroupObserverComponent,
    SaveButtonComponent,
    SearchResultsComponent,
    UiAccordionComponent,
    UiAccordionPanelComponent,
    UiActionCardComponent,
    UiAlertsButtonComponent,
    UiBottomSheetComponent,
    UiCalendarHeaderComponent,
    UiDatepickerCaroselComponent,
    UiDatepickerComponent,
    UiFloatButtonComponent,
    UiFormComponent,
    UiFormYesNoComponent,
    UiFullscreenBodyComponent,
    UiFullscreenComponent,
    UiFullscreenFooterComponent,
    UiFullscreenHeaderComponent,
    UiLayoutArticleComponent,
    UiLayoutCardComponent,
    UiLayoutCardFillComponent,
    UiLookupComponent,
    UiMdIconComponent,
    UiModalBodyComponent,
    UiModalFooterComponent,
    UiModalHeaderComponent,
    UiMultiCheckboxComponent,
    UiNavHrefComponent,
    UiNavLinkComponent,
    UiNavToggleComponent,
    UiProcessStatusComponent,
    UiSidenavComponent,
    UiSplitPaneComponent,
    UiStepperComponent,
    UiSwitchComponent,
    UiVirtualRepeatComponent,
    UiYNCheckboxComponent,
    UpdaterFieldComponent,

    // shared association components
    AssociationComponent,
    DialogChipComponent,
    FormOnlyDialogComponent,
    FullscreenDialogComponent,
    FullscreenDialogHeaderComponent,
    FullscreenDialogFooterComponent,
    FullscreenDialogBodyComponent,

    // shared table renderers components
    SelectCodeEditorDirective,
    ETCodeRendererDirective,
    DatepickerEditorDirective,
    DynamicTypeEditorDirective,
    PhoneNumberDirective,
    WholeNumberDirective,
    TimeEditorDirective,
    DunsNumberDirective,
    LookupEditorDirective,
    ETDropdownRendererDirective,
    PhoneOrTextDirective,
    IntegerNumberDirective,
    SelectCodeEditorComponent,
    EtCodeFilter,
    CheckBoxBooleanFilter,
    ETCodeRendererComponent,
    ETCodeRendererCDComponent,
    DatepickerEditorComponent,
    DatepickerRendererComponent,
    PhoneNumberRendererComponent,
    PhoneNumberEditorComponent,
    WholeNumberEditorComponent,
    TimeRendererComponent,
    TimeEditorComponent,
    DunsNumberRendererComponent,
    LookupEditorComponent,
    PhoneOrTextRendererComponent,
    PhoneOrTextEditorComponent,
    IntegerNumberEditorComponent,

    // shared forms
    UiMatVirtualSelectHeaderDirective,
    UiOptionDetailComponent,
    UiSelectHeaderFilterComponent,
    UiMatVirtualSelectComponent,
    UiFormSelectComponent,
    UiFormMultiSelectComponent,

    // common validators
    UniqueNameValidatorDirective,
    UniqueAbbrValidatorDirective,
    CreditWorthinessValidatorDirective,
    UiDateValidatorDirective,
    UiDigitValidatorDirective,
    UiNumberValidatorDirective,
    MinNumberValidatorDirective,
    MaxNumberValidatorDirective,
    UiCustomValidatorDirective,
  ],
  providers: [
    SearchService,
    PinnedSearchCacheService,
    UiBottomSheetService,
    AuthInterceptorService,
    IdleService,
    NewItemInputService,
    ScheduledQuantityForOperatorActionService
  ],
  entryComponents: [
    // TODO: decomission these (moved here to remove framing-component-outlet.module.ts and framing.module.ts)
    ControllerFactoryComponent,
    FramingRootComponent,

    ConfirmationComponent,
    DynamicLookupComponent,
    ModalSkeletonComponent,
    LookupController,
    ModalController,

    // common components
    ExpandableButtonComponent,
    ExpandableFullheightButtonComponent,
    UiProcessStatusComponent,
    ButtonAlertComponent,
    UiCalendarHeaderComponent,

    // shared association components
    DialogChipComponent,
    FormOnlyDialogComponent,
    FullscreenDialogComponent,

    // shared table renderers components
    SelectCodeEditorComponent,
    EtCodeFilter,
    CheckBoxBooleanFilter,
    ETCodeRendererComponent,
    ETCodeRendererCDComponent,
    DatepickerEditorComponent,
    DatepickerRendererComponent,
    PhoneNumberRendererComponent,
    PhoneNumberEditorComponent,
    WholeNumberEditorComponent,
    TimeRendererComponent,
    TimeEditorComponent,
    DunsNumberRendererComponent,
    LookupEditorComponent,
    PhoneOrTextRendererComponent,
    PhoneOrTextEditorComponent,
    IntegerNumberEditorComponent
  ]
})
export class SharedModule { }
