
import {switchMap, debounceTime, share} from 'rxjs/operators';
import { OnChanges, SimpleChanges } from '@angular/core';
import { AsyncValidator, AbstractControl, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidatorFn, Validator, Validators, ValidationErrors } from '@angular/forms';
import { Observable ,  Observer ,  Subject ,  Subscription } from 'rxjs';

/**
 *
 */
export interface ObservableValidatorSource {
  value: any;
  count: number;
}

/**
 *
 */
export interface ObservableValidatorResult {
  errors: ValidationErrors | null;
  count: number;
}

/**
 *
 */
export interface ObservableValidatorConfig {
  debounceTime?: number;
}

/**
 * @params observable ObservableValidatorFn takes an Observable<ObservableValidatorSource> that emits when the
 * form control (AbstractControl) should be validated.
 *
 * @return ObservableValidatorFn returns an Observable<ObservableValidatorResult> that emits the validation result.
 */
export interface ObservableValidatorFn {
  (observable: Observable<ObservableValidatorSource>): Observable<ObservableValidatorResult>;
}

/**
 *
 */
export abstract class ObservableValidator implements Validator {

  /**
   *
   */
  private valFn: AsyncValidatorFn | ValidatorFn = Validators.nullValidator;

  /**
   *
   */
  static create(validator: ObservableValidatorFn): AsyncValidatorFn {
    let sourceSubject = new Subject<ObservableValidatorSource>();
    let validatorObs = validator(sourceSubject.asObservable()).pipe(share());
    let count: number = 0;

    return (control: AbstractControl) => {
      let thisCount = count++;
      let value = _.clone(control.value);
      return Observable.create((obs: Observer<any>) => {
        let subscription = validatorObs.subscribe(
          (next: ObservableValidatorResult) => {
            if (next.count >= thisCount) {
              obs.next(next.errors);
              obs.complete();
              subscription.unsubscribe();
            }
          }
        );
        sourceSubject.next({ value, count: thisCount });
      });
    };
  }

  /**
   *
   */
  validate(control: AbstractControl): Promise<ValidationErrors | null> | ValidationErrors | null {
    return this.valFn(control);
  }

  /**
   *
   */
  onChanges(changes: SimpleChanges, config: ObservableValidatorConfig): void {
    if (config) {
      this.valFn = ObservableValidator.create((observable: Observable<ObservableValidatorSource>) => {
        return observable.pipe(
          debounceTime(config.debounceTime ? config.debounceTime :  1000),
          switchMap((next) => this.asyncValidate(next)),);
      });
    } else {
      console.error('ObservableValidator not configured properly', this);
      this.valFn = Validators.nullValidator;
    }
  }

  /**
   *
   */
  abstract asyncValidate(next: ObservableValidatorSource): Observable<ObservableValidatorResult>;
}
