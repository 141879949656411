<div class="select-editor"
     tabindex="-1">
  <mat-select (change)="onSelectClose()"
              [(ngModel)]="params.value">
    <mat-option *ngFor="let field of editDropdownOptionsArray"
                [value]="field[editDropdownValueId]">
      {{ field[editDropdownValueLabel] }}
    </mat-option>
  </mat-select>
</div>
