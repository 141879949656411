import { Directive, Input, ElementRef,  Optional  } from '@angular/core';
import { ModelFormatterDirective } from '../model-formatter/model-formatter.directive';

@Directive({
  selector: '[addWildCard]',
})
export class WildcardTextValueAccessorDirective  {
  @Input() addWildCard: string = 'suffix';

  private inputElementType: any = 'text';
  private excludeFromTransform: boolean = false;


  constructor(@Optional() private modelFormatter: ModelFormatterDirective, private elementRef: ElementRef) {
    if (this.modelFormatter) {
      this.modelFormatter.addModelTransform(this.addWildCardValue());
      this.modelFormatter.addViewTransform(this.viewTransform());
    }
  }

  private checkTypeTransform(): boolean {
    if (this.elementRef.nativeElement.type !== 'text') {
      this.excludeFromTransform = true;
    }
    return this.excludeFromTransform;
  }

  private addWildCardValue(): any {
    return (val: any): any => {
      if (!val || this.checkTypeTransform()) { return val; }

      let prefix = '';
      let suffix = '';

      let rv = this.getCleanValue(val);

      if (this.addWildCard === 'prefix') {
        prefix = '%';
      } else if (this.addWildCard === 'prefix_and_suffix') {
        suffix = prefix = '%';
      } else {
        suffix = '%';
      }

      return `${prefix}${rv}${suffix}`;
    };
  }

  private viewTransform(): any {
    return (val: any): any => {
      return this.getCleanValue(val);
    };
  }

  public getCleanValue(val: any): any {
    if (!val) { return val; }
    let rv = val;

    if (this.addWildCard === 'prefix' || this.addWildCard === 'prefix_and_suffix') {
      while (rv.startsWith('%')) {
        rv = rv.slice(1);
      }
    }
    if (this.addWildCard === 'prefix_and_suffix' || this.addWildCard === 'suffix' || this.addWildCard === '') {
      while (rv.endsWith('%')) {
        rv = rv.slice(0, -1);
      }
    }
    return rv;
  }
}
//
