
import {of as observableOf,  Subscription ,  Observable } from 'rxjs';

import {map, tap, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Api } from 'cad/common/services/api/api';
import { EmitterService } from 'cad/core/services/emitter/emitter.service';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import { UserDetail } from 'src/cad/common/models/user/user-details-model';
import {UserStoreService} from 'cad/common/store/core/services/user-store.service';

@Injectable()
export class CodesModel {
  private isLoaded: boolean = false;
  private subscriptions: Subscription[] = [];
  private rawCodes: any = {};

  constructor(
    private emitterService: EmitterService,
    private api: Api,
    private userModelService: UserModelService,
    private userStore: UserStoreService,
  ) {
    this.subscriptions.push(this.userStore.stateChanged.pipe(
      filter((userDetail)=> userDetail.state && userDetail.state.user))
      .subscribe((userDetail)=> {
        this.onUserContext(userDetail.state);
      }));
    if (this.userModelService.getCurrentContext() && !this.isLoaded) {
      this.subscriptions.push(this.getRawCodes().subscribe());
    }
  }

  public onUserContext(userDetail: UserDetail): void {
    if (userDetail) {
      this.subscriptions.push(this.getRawCodes(
        (userDetail.currentContext && !_.isNil(userDetail.currentContext.assetNbr))
          ? userDetail.currentContext.assetNbr
          : null
      ).subscribe());
    }
  }

  public getAllCodes = (assetNbr?: any): Observable<any> => {
    if (!assetNbr) {
      /* tslint:disable:no-param-reassign */
      assetNbr = this.userModelService.getCurrentAssetNbr();
      /* tslint:enable:no-param-reassign */
    }
    return this.api.codes.getAssetCodes(assetNbr);
  }

  public getRawCodes = (assetNbr?: any): Observable<any> => {
    if (!assetNbr) {
      if (this.userModelService) {
        /* tslint:disable:no-param-reassign */
        assetNbr = this.userModelService.getCurrentAssetNbr();
        /* tslint:enable:no-param-reassign */
      }
      if (!assetNbr) {
        return observableOf(null);
      }
    }
    return this.getAllCodes(assetNbr).pipe(tap((allCodes) => {
      this.rawCodes[ assetNbr ] = allCodes;
      _.each(allCodes.codeTypes, (codeType) => {
        _.each(codeType.codes, (code) => {
          if (_.isObject(code.cd)) {
            code.value = code.cd.value;
            code.cd = code.cd.cd;
          }
        });
      });
      this.isLoaded = true;
    }));
  }

  public getTypesForAsset = (): Observable<any> => {
    return this.getAllCodes().pipe(map((allCodes) => {
      return allCodes.codeTypes;
    }));
  }

  public getTypesForSpecifiedAsset = (asset: number): Observable<any> => {
    return this.getAllCodes(asset).pipe(map((allCodes) => {
      return allCodes.codeTypes;
    }));
  }

  public getCodesForTypeByAsset = (type: any, asset: number): Observable<any> => {
    return this.getTypesForSpecifiedAsset(asset).pipe(map((codeTypes) => {
      let codeType: any = _.find(codeTypes, { type });
      return codeType ? codeType.codes : [];
    }));
  }

  public getCodesForType = (type: any): Observable<any> => {
    return this.getTypesForAsset().pipe(map((codeTypes) => {
      let codeType: any = _.find(codeTypes, { type });
      return codeType ? codeType.codes : [];
    }));
  }

  public getCodes = (type: any): Observable<any> => {
    return this.getCodesForType(type);
  }

  public getValueForCode = (type: any, codeToGet: any): Observable<any> => {
    return this.getCodesForType(type).pipe(map((codeTypes) => {
      let code: any = _.find(codeTypes, { cd: codeToGet });
      return code ? code.value : null;
    }));
  }
}
