import { Injectable } from '@angular/core';
import { NomBaseDatesUtil } from 'src/cad/nominations/common/utils/nom-base-date/nom-base-date';

@Injectable()
export class EdiConfDateUtil {
  constructor() {
    return EdiConfDateFactory;
  }
}

class EdiConfDateFactory extends NomBaseDatesUtil {
  constructor(private c: any) {
    super();
    this.ctrl = c;
  }

  public setDefaultDates = (ctrl?: any): void => {
    let obj = this.ctrl ? this.ctrl : ctrl;
    if (obj.flowBeginDate === null) {
      _.assign(obj, {
        flowBeginDate: this.getDefaultStartDate(),
        flowEndDate: this.getDefaultStartDate(),
      });
    }
  }

  public getDefaultStartDate = (): string => {
    return moment().startOf('day').format();
  }

  public setRequestDates = (rowEntity: any, ctrl?: any): void => {
    let obj = this.ctrl ? this.ctrl : ctrl;
    _.assign(rowEntity, {
      flowBeginDate: moment(obj.flowBeginDate).startOf('day').format(),
      flowEndDate: moment(obj.flowEndDate).endOf('day').format(),
    });
  }

  public setStateParamDates = (ctrl?: any): void => {
    let obj = this.ctrl ? this.ctrl : ctrl;
    // _.assign(this.$stateParams, {
    //   flowBeginDate: obj.flowBeginDate,
    //   flowEndDate: obj.flowEndDate,
    // });
  }

  public searchBeginDateChanged = (ctrl?: any): any => {
    let obj = this.ctrl ? this.ctrl : ctrl;
    return (sDate?: any, oldVal?: any): void => {
      if (sDate) {
        obj.flowEndDate = moment(sDate).format();
      }
    };
  }

}
