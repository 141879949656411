import { Asset, AssetGroup } from 'src/common/types/cad.constants';
import { BaseValidatorService } from './base-validator.service';
import { ValidationPipeline } from '../../models/validation/validator-pipeline';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class ValidationPipelineFactoryService {

  constructor(private baseValidatorService: BaseValidatorService) {

  }

  public getValidationPipeline(scenario: string, tree: any, assetGroup?: AssetGroup, asset?: Asset): ValidationPipeline {
    if (tree[ scenario ]
      && assetGroup
      && tree[ scenario ][ assetGroup ]
      && asset
      && tree[ scenario ][ assetGroup ][ asset ]) {
      const validators = _.reject(_.flatten(tree[ scenario ][ assetGroup ][ asset ]), _.isNil);
      return new ValidationPipeline(validators, this.baseValidatorService);
    } else if (tree[ scenario ] && assetGroup && tree[ scenario][assetGroup ]) {
      const validators = _.reject(_.flatten(tree[ scenario ][ assetGroup ].default), _.isNil);
      return new ValidationPipeline(validators, this.baseValidatorService);
    } else if (tree[ scenario ]) {
      const validators = _.reject(_.flatten(tree[ scenario ].default), _.isNil);
      return new ValidationPipeline(validators, this.baseValidatorService);
    } else {
      return new ValidationPipeline([], this.baseValidatorService);
    }
  }
}
