import { Injectable } from '@angular/core';
import { NgModel } from '@angular/forms';

@Injectable()
export class NewItemInputService {
  private _modelList: NgModel[] = [];
  constructor() {
  }

  public addModel(model: NgModel): void {
    this._modelList.push(model);
  }
  public getModels(): NgModel[] {
    return this._modelList;
  }

  public destroy(): void {
    this._modelList.splice(0);
  }
}
