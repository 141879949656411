import { Injectable } from '@angular/core';
import { ApiCache } from 'cad/common/services/api/api-cache';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AlertsService } from 'cad/core/services/alerts/alerts.service';
import { ForecastContractApi } from '../forecast-contract/forecast-contract';

@Injectable()
export class ForecastAmendmentApi extends ForecastContractApi {
  constructor(
    apiHelper: ApiHelper,
    apiCache: ApiCache,
    alertService: AlertsService
  ) {
    super(apiHelper, apiCache, alertService);
    this.contractType = 'AMENDMENT';
  }
}
