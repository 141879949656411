import {
  Component, Input, Output, EventEmitter, Type, ComponentRef, ViewChild, OnInit,
  OnDestroy, AfterViewInit, DoCheck
} from '@angular/core';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import * as _ from 'lodash';
import { MatHorizontalStepper, MatStep } from '@angular/material/stepper';

/**
 * @description Interface for MatStep of the UiStepper component
 */
export interface UiStepperStepConfig {
  /**
   * @description Index of the MatStep component, used to order the wizard steps
   */
  index: number;
  /**
   * @description Component Type of the MatStep component's content
   */
  componentType: Type<any>;
  /**
   * @description Component Type of the footer component's content that will
   * be displayed mostly as step navigation action button.
   */
  footerComponentType?: Type<any>;
  /**
   * @description Input data to send to the MatStep component's instance
   */
  data?: any;
  /**
   * @description Label for the MatStep component
   */
  label?: string;
  /**
   * @description The reference of the ComponentRef of the stepComponentType
   */
  componentRef?: ComponentRef<any>;
  /**
   * @description The reference of the ComponentRef of the footer component that will
   * be displayed mostly as step navigation action button.
   */
  footerComponentRef?: ComponentRef<any>;
  /**
   * @description Determines whether the MatStep is editable or not
   * @default Defaults to MatStep's editable property
   */
  isEditable?: (stepConfig?: UiStepperStepConfig, matStepRef?: MatStep) => boolean;
  /**
   * @description Determines whether the MatStep is completed or not
   * @default Defaults to MatStep's completed property
   */
  isCompleted?: (stepConfig?: UiStepperStepConfig, matStepRef?: MatStep) => boolean;
  /**
   * @description Determines whether the MatStep is optional or not
   * @default Defaults to MatStep's optional property
   */
  isOptional?: (stepConfig?: UiStepperStepConfig, matStepRef?: MatStep) => boolean;
  /**
   * @description Determines whether the MatStep is hidden or not
   */
  isHidden?: boolean;
}

@Component({
  selector: 'ui-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: [ './stepper.component.less' ]
})
export class UiStepperComponent implements OnInit, DoCheck, AfterViewInit, OnDestroy {

  @Input() public isLinear: boolean = true;
  @Input() public stepperData: any = {};
  @Input()
  public get stepConfigList(): UiStepperStepConfig[] {
    return this._stepConfigList;
  }
  public set stepConfigList(value: UiStepperStepConfig[]) {
    if (_.isArray(value)) {
      this.rawStepConfigList = _.compact(value);
      this._stepConfigList = _.sortBy(_.reject(this.rawStepConfigList, { isHidden: true }), 'index');
    }
  }
  /**
   * @description Event emitted when the selected step has changed.
   * @type {EventEmitter<StepperSelectionEvent>}
   */
  @Output() public selectionChange: EventEmitter<StepperSelectionEvent> = new EventEmitter<StepperSelectionEvent>();
  /**
   * @description Event emitted when the stepper is submitted (ie last step is submitted)
   * @type {EventEmitter<any>}
   */
  @Output() public onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatHorizontalStepper) public matHorizontalStepperRef: MatHorizontalStepper;
  /**
   * @description Unfiltered step configurations.
   * @type {Array}
   */
  public rawStepConfigList: UiStepperStepConfig[] = [];
  public get lastStepIndex(): number {
    if (this._stepConfigList) {
      return this._stepConfigList.length - 1;
    }
    return -1;
  }
  private _stepConfigList: UiStepperStepConfig[] = [];

  constructor() {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.matHorizontalStepperRef) {
      this.matHorizontalStepperRef.selectionChange = this.selectionChange;
    }
  }

  ngDoCheck(): void {
    this.stepConfigList.forEach((stepConfig: UiStepperStepConfig) => {
      let matStepRef: MatStep = this.getMatStepRef(stepConfig);
      if (matStepRef) {
        if (_.isFunction(stepConfig.isEditable)) {
          matStepRef.editable = stepConfig.isEditable(stepConfig, matStepRef);
        }
        if (_.isFunction(stepConfig.isCompleted)) {
          matStepRef.completed = stepConfig.isCompleted(stepConfig, matStepRef);
        }
        if (_.isFunction(stepConfig.isOptional)) {
          matStepRef.optional = stepConfig.isOptional(stepConfig, matStepRef);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.selectionChange.complete();
    this.onSubmit.complete();
    this.selectionChange = null;
    this.onSubmit = null;
    this._stepConfigList.forEach((stepConfig: UiStepperStepConfig) => {
      if (stepConfig.footerComponentRef) {
        stepConfig.footerComponentRef.destroy();
        stepConfig.footerComponentRef = null;
      }
      if (stepConfig.componentRef) {
        stepConfig.componentRef.destroy();
        stepConfig.componentRef = null;
      }
    });
  }

  public onStepReady(
    compRef: ComponentRef<any>,
    stepConfig: UiStepperStepConfig,
    matStepRef: MatStep
  ): void {
    if (stepConfig) {
      stepConfig.componentRef = compRef;
      stepConfig.componentRef.instance.stepConfig = stepConfig;
      stepConfig.componentRef.instance.stepIndex = this.getStepIndex(stepConfig.componentRef);
      if (matStepRef) {
        stepConfig.componentRef.instance.matStepRef = matStepRef;
      }
      if (stepConfig.data) {
        _.assign(stepConfig.componentRef.instance, stepConfig.data);
      }
      stepConfig.componentRef.onDestroy(() => {
        let stepIndex: number = this.getStepIndex(stepConfig.componentRef);

        stepConfig.componentRef.instance.stepConfig = null;
        stepConfig.componentRef.instance.matStepRef = null;
        if (stepIndex > -1) {
          this._stepConfigList.splice(stepIndex, 1);
        }
      });
    }
  }

  public onFooterReady(compRef: ComponentRef<any>, stepConfig: UiStepperStepConfig): void {
    if (stepConfig) {
      stepConfig.footerComponentRef = compRef;
      stepConfig.footerComponentRef.instance.stepConfig = stepConfig;
      stepConfig.footerComponentRef.instance.matStepRef = this.getMatStepRef(stepConfig);
      stepConfig.footerComponentRef.onDestroy(() => {
        stepConfig.footerComponentRef.instance.stepConfig = null;
        stepConfig.footerComponentRef.instance.matStepRef = null;
      });
    }
  }

  public updateData(data: any): void {
    if (data) {
      _.assign(this.stepperData, data);
    }
  }

  public submitData(): void {
    this.onSubmit.emit(_.cloneDeep(this.stepperData));
  }

  public getStepIndex(stepComponentRef: ComponentRef<any>): number {
    return _.findIndex(this.stepConfigList, (stepConfigItem: UiStepperStepConfig) => {
      return stepConfigItem.componentRef === stepComponentRef;
    });
  }

  public getMatStepRef(stepConfig: UiStepperStepConfig): MatStep {
    if (stepConfig
      && stepConfig.componentRef
      && stepConfig.componentRef.instance
      && stepConfig.componentRef.instance.matStepRef) {
      return stepConfig.componentRef.instance.matStepRef;
    }
    return null;
  }
}
