import { Injectable } from '@angular/core';
import {of as observableOf,  Observable } from 'rxjs';
import { ApiHelper } from 'src/cad/common/services/api/api-helper';
import * as _ from 'lodash';
import { CRAwardData } from 'src/cad/capacity-release/shared/interfaces/cr-award-data';
import ValidationResult = cad.ValidationResult;
import { map } from 'rxjs/operators';

@Injectable()
export class CapacityReleaseOfferAwardsApi {
  private readonly endpoint: string = 'capacity-release/offer/';
  private readonly suffix: string = '/awards';

  constructor(private apiHelper: ApiHelper) {}

  public getById = (auctionId: number | string, awardId: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/' + awardId);
  }

  public find = (params: any): Observable<any> => {
    let auctionId: number = -1;
    let awardId: number = -1;

    if (params) {
      if (!_.isNil(params.relatedAuctionID)) {
        auctionId = params.relatedAuctionID;
      }
      if (!_.isNil(params.awardID)) {
        awardId = params.awardID;
      }
    }
    return this.getById(auctionId, awardId);
  }

  public getByOfferId = (offerId: number | string): Observable<any> => {
    return this.filter({ relatedOfferID: offerId }).pipe(map((items) => {
      if (items && items.length > 0) {
        return items[ 0 ];
      }
      return items;
    }));
  }

  public recent = (auctionId: number | string = -1, awardData: any = {}): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/dashboard', { body: awardData });
  }

  public filter = (awardData: any): Observable<any> => {
    let auctionId: number = -1;

    if (awardData && !_.isNil(awardData.relatedAuctionID)) {
      auctionId = awardData.relatedAuctionID;
    }
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/filter', { body: awardData });
  }

  public filterAwardsOnOfferId(awardData: Partial<CRAwardData>): Observable<CRAwardData[]> {
    let auctionId: number = -1;

    if (awardData && !_.isNil(awardData.relatedAuctionID)) {
      auctionId = awardData.relatedAuctionID;
    }
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/filter-strict', { body: awardData });
  }

  public save = (auctionId: number | string = -1, awardData: any = {}): Observable<any> => {
    return observableOf({msg:'*** SAVE IS NOT IMPLEMENTED, AWARD CANNOT BE MODIFIED ***'});
  }

  public deleteItem = (item: any): Observable<any> => {
    return observableOf({msg:'*** DELETE NOT IMPLEMENTED, AWARD CANNOT BE DELETED ***'});
  }

  public getComments = (auctionId: number | string, awardId: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix+ '/' + awardId + '/comments');
  }

  public getStorageData = (auctionId: number | string, awardId: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/' + awardId + '/storage-data');
  }

  public getContractDetail = (auctionId: number | string, contractId: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/contract-detail/' + contractId);
  }

  public getAwardServiceReqData = (baLookupCriteria: any): Observable<any> => {
    let auctionId: number = -1;

    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/service-requester', { body: baLookupCriteria });
  }

  public getBADesigneeList(auctionId: number | string, designeeBAId?: number | string): Observable<any> {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/ba-designee-list/' + designeeBAId || '-1');
  }

  public printAward(awardId: number): Observable<ValidationResult<void>> {
    return this.apiHelper.request(`${ this.endpoint }-1${this.suffix}/${ awardId }/print`);
  }
}
