import { Directive, ElementRef, forwardRef, HostListener, Input, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UIControlValueAccessor } from 'src/common/components/form/control-value-accessor';
import 'inputmask';

declare var Inputmask: any;

@Directive({
  selector: '[uiInputMask]',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UiInputMaskDirective), multi: true },
  ],
})
export class UiInputMaskDirective extends UIControlValueAccessor implements OnDestroy {
  @Input('uiInputMask')
  public get mask(): string { return this._mask; }
  public set mask(mask: string) {
    this._mask = mask;
    this.applyExpression(this.mask);
  }

  @Input('uiInputMaskOptions')
  public get maskOptions(): any {
    return this._maskOptions;
  }
  public set maskOptions(maskOptions: any) {
    this._maskOptions = maskOptions;
    if (this._maskOptions
      && this.elementRef
      && this.elementRef.nativeElement
      && this.elementRef.nativeElement.inputmask) {
      _.defaults(this._maskOptions, {
        showMaskOnHover: false
      });
      this._maskOptions.oncomplete = () => {
        this.model = this.elementRef.nativeElement.value;
      };
      this._maskOptions.onincomplete = () => {
        let unmaskedValue: any = this.elementRef.nativeElement.inputmask.unmaskedvalue();
        if (!_.isEqual(this.model, unmaskedValue) && unmaskedValue === '') {
          // clear the value on empty change
          this.model = undefined;
        }
      };
      this.elementRef.nativeElement.inputmask.option(this._maskOptions);
    }
  }
  @HostListener('blur') onHostBlur(): void {
    this.onBlur();
  }

  private _mask: string;
  private _maskOptions: any;

  constructor(
    private elementRef: ElementRef,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.maskOptions.oncomplete = undefined;
  }

  writeValue(value: any): void {
    Promise.resolve(null).then(() => {
      if (this.elementRef
        && this.elementRef.nativeElement) {
        this.elementRef.nativeElement.value = value;
      }
    });
    super.writeValue(value);
  }

  public applyExpression(passedExpression: string): void {
    if (!this.maskOptions) {
      this.maskOptions = {};
    }
    if (passedExpression) {
      const expressionOptions = _.clone(this.maskOptions);
      const im = new Inputmask(passedExpression, expressionOptions);
      im.mask(this.elementRef.nativeElement);
    } else {
      Inputmask.remove(this.elementRef.nativeElement);
    }
  }
}

Inputmask.extendAliases(getAliases());

function getAliases(): any {
  return {
    currency: {
      prefix: '$',
      groupSeparator: ',',
      alias: 'numeric',
      digits: 2,
      digitsOptional: !1
    },
    phoneExtension: {
      mask: '(999) 999-9999 ext. 9{0,20}',
      greedy: false,
      autoUnmask: true,
      removeMaskOnSubmit: true,
      onUnMask: onPhoneUnMask,
    },
    phone: {
      mask: '(999) 999-9999',
      autoUnmask: true,
      removeMaskOnSubmit: true,
      onUnMask: onPhoneUnMask,
    },
    time: {
      mask: '99:99',
      autoUnmask: true,
      removeMaskOnSubmit: true,
      onUnMask: onTimeUnMask,
    },
    /*using inputFormat which is easier to manage than defining a custom mask pattern
    * tried various patterns here instead of h:s tM all of them resulted in error*/
    time12: {
      alias: 'datetime',
      inputFormat: 'hh:MM TT',
      placeholder: '_',
      casing: 'upper',
      clearIncomplete: true,
      autoUnmask: true,
      removeMaskOnSubmit: true,
      onUnMask: (maskedValue: string, unmaskedValue: string): string => !unmaskedValue ? unmaskedValue : maskedValue
    }
  };
}

function onPhoneUnMask(maskedValue: string, unmaskedValue: string): string {
  const re = /\((\d{3})\)\s(\d{3})\-(\d{4})(\sext\.\s)(\d*)/;
  const str = maskedValue;
  const m = re.exec(str);
  if (m !== null) {
    if (m.index === re.lastIndex) {
      re.lastIndex++;
    }
    if (m[m.length - 1] !== '') {
      return m[1] + m[2] + m[3] + ' ext#' + m[5];
    } else {
      return unmaskedValue;
    }
  } else {
    return unmaskedValue;
  }
}

function onTimeUnMask(maskedValue: string, unmaskedValue: string): string {
  return maskedValue;
}
