import { Component, Input, OnDestroy, AfterViewChecked, ContentChildren, QueryList } from '@angular/core';
import { Subscription } from 'rxjs';

import { UiAccordionPanelComponent } from './accordion-panel/accordion-panel.component';

@Component({
  selector: 'ui-accordion',
  template: '<div class="ui-accordion-parent"><ng-content></ng-content></div>',
  styleUrls: [ './accordion.less' ],
})
export class UiAccordionComponent implements OnDestroy, AfterViewChecked {

  @Input() public viewSingle: boolean;
  @ContentChildren(UiAccordionPanelComponent) panels: QueryList<UiAccordionPanelComponent>;
  public panelList: UiAccordionPanelComponent[];

  private subscriptions: Subscription[] = [];

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.panelList = null;
    this.panels = null;
  }

  public ngAfterViewChecked(): void {
    this.panelList = this.panels.toArray();
    this.panelList.forEach((panel, i) => panel.panelId = i);
    this.panelList.forEach((panel) => {
      this.subscriptions.push(panel.toggleEvent.subscribe((id) => {
        this.closeOthers(id);
      }));
    });
  }

  public openPanel(panelId: number): void {
    this.closeOthers(panelId);
    this.panelList[panelId].isOpen = true;
    this.panelList[panelId].isOpenChange.emit(true);
  }

  public closeOthers = (openPanelId: any): void => {
    if (this.viewSingle) {
      this.panelList.forEach((panel) => {
        if (panel.panelId !== openPanelId) {
          panel.isOpen = false;
          panel.isOpenChange.emit(false);
        }
      });
    }
  }

  public closeAll = (): void => {
    this.panelList.forEach((panel) => {
      panel.isOpen = false;
    });
  }

  public openAll = (): void => {
    this.panelList.forEach((panel) => {
      panel.isOpen = true;
    });
  }
}
