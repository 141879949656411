import { Directive, Input, OnInit, OnChanges, Type } from '@angular/core';
import { UiGridApi } from 'src/ag-grid-wrapper';
import { TableComponent } from 'src/ag-grid-wrapper/table/table.component';
import { TableColumnComponent } from 'src/ag-grid-wrapper/column/column.component';
import { ModalController } from 'src/features/common/modal/modal.controller';
import { LookupEditorComponent } from './lookup-editor.component';
import { AutocompleteLookupModel } from 'src/common/components/form/lookup/autocomplete-lookup.model';
import * as _ from 'lodash';

@Directive({
  selector: 'ui-table-column[uiLookupEditor]',
})
export class LookupEditorDirective implements OnInit, OnChanges {
  
  @Input() public modalController: ModalController;
  @Input() public modalComponent: Type<any>;
  @Input() public lookupType: string;
  @Input() public useAutocomplete: boolean;
  @Input() public autocompleteModel: AutocompleteLookupModel;
  @Input() public autocompleteOptionItems: any[];
  @Input() public defaultSearchParams: {[key: string]: number | string};
  @Input() public constSearchParams: {[key: string]: number | string};
  @Input() public inputLocked: boolean;
  @Input() public searchOnColumns: string[];
  @Input() public itemData: any;
  @Input() public tableLookup: boolean = true;
  @Input() public volatileCellConfig: boolean = false;
  @Input() public updateOnSelection: boolean = true;
  @Input() public autoOpen: boolean;
  @Input() public modalWidth: string;
  @Input() public modalHeight: string;
  
  constructor(public tableColumn: TableColumnComponent) {}

  ngOnInit(): void {
    this.tableColumn.type = 'custom';
    this.tableColumn.customEditor = LookupEditorComponent;
    this.setCustomCellConfig();
  }

  ngOnChanges(): void {
    this.setCustomCellConfig();
  }

  setCustomCellConfig(): void {
    if (this.tableColumn && _.isNil(this.tableColumn.customCellConfig)) {
      this.tableColumn.customCellConfig = {
        modalController: this.modalController,
        modalComponent: this.modalComponent,
        lookupType: this.lookupType,
        useAutocomplete: this.useAutocomplete,
        autocompleteModel: this.autocompleteModel,
        autocompleteOptionItems: this.autocompleteOptionItems,
        defaultSearchParams: this.defaultSearchParams,
        constSearchParams: this.constSearchParams,
        searchOnColumns: this.searchOnColumns,
        inputLocked: this.inputLocked,
        itemData: this.itemData,
        tableLookup: this.tableLookup,
        updateOnSelection: this.updateOnSelection,
        autoOpen: this.autoOpen,
        modalWidth: this.modalWidth,
        modalHeight: this.modalHeight
      };
      if (this.volatileCellConfig) {
        this.refreshTableColDefs();
      }
    }
  }

  refreshTableColDefs(): void {
    if ((this.tableColumn as any).tableComponent.api) {
      let table: TableComponent = (this.tableColumn as any).tableComponent;
      let api: UiGridApi = table.api;
      let colDef = this.tableColumn.toColDef();
      table.columnDefs.forEach((col, i) => {
        if (col.field === this.tableColumn.field) {
          table.columnDefs[i] = colDef;
        }
      });
      api.setColumnDefs(table.columnDefs);
      api.sizeColumnsToFit();
    }
  }
}
