<ui-card [headline]="headline$|async" [cardToolBarItems]="menuItems$|async" [isExpanded]="true">
    <div *ngIf="itemData$|async;let itemData;">
        <ui-table *ngIf="itemData.length > 0" (rowClicked)="onRowClicked($event)" [data]="itemData" fxLayout="column" [suppressHorizontalScroll]="false" >
          <ui-table-column name="offeringID" 
                           headerName="Offer Number" 
                           [minWidth]="70" 
                           type = "numberStr" >
          </ui-table-column>
          <ui-table-column name="description" 
                           headerName="Pending Action Description" 
                           [minWidth]="90" 
                           type = "string" >
          </ui-table-column>
          <ui-table-column name="owningBAName" 
                           headerName="Releasing Shipper Name" 
                           [minWidth]="120"
                           Type = "string" >
          </ui-table-column>
          <ui-table-column name="preArrangedBidderName" 
                           headerName="Bidder Shipper Name" 
                           [minWidth]="120"
                           type = "string" >
          </ui-table-column>
          <ui-table-column name="contractNumber" 
                           headerName="Releasing Contract Id" 
                           [minWidth]="70" 
                           type = "numberStr" >
          </ui-table-column>
          <ui-table-column name="relStartDate" 
                           headerName="Release Start Date" 
                           [minWidth]="90"
                           type = "date" >
          </ui-table-column>
          <ui-table-column name="relEndDate" 
                           headerName="Release End Date" 
                           [minWidth]="90"
                           type="date" >
          </ui-table-column>
          <ui-table-column name="isPermanentRelease" 
                           headerName="Permanent" 
                           [minWidth]="50"
                            >
          </ui-table-column>
          <ui-table-column name="isBiddable" headerName="Biddable" [minWidth]="50" ></ui-table-column>
          <ui-table-column name="isPrearranged" headerName="Prearranged" [minWidth]="50" ></ui-table-column>
        </ui-table>
    </div>
</ui-card>