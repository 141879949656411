
import {of as observableOf,  Observable } from 'rxjs';

import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiCache } from 'cad/common/services/api/api-cache';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AlertsService } from 'cad/core/services/alerts/alerts.service';
import { ItemApi } from 'src/features/common/item/item-api';
import { ContractExecutionTypeEnum } from 'src/cad/contracts/shared/constants/contract-constants';
import { ContractFilterData } from 'src/cad/contracts/shared/interfaces/contract-filter';
import ValidationResult = cad.ValidationResult;

@Injectable()
export class AgreementContractApi extends ItemApi {

  private readonly endpoint: string = 'agreement/contract';
  protected contractType: string;
  constructor(
    public apiHelper: ApiHelper,
    public apiCache: ApiCache,
    public alertService: AlertsService,
  ) {
    super();
    this.contractType = 'CONTRACT';
  }

  public getById = (id: number) : Observable<mplus.ContractData> => {
    return this.apiHelper.request(`${ this.endpoint }/${ id }`).pipe(tap((val) => val[0]));
  }

  public find(params: any): Observable<any> {
    return this.getById(params.contractId);
  }
  
  public getContractStatus(contractId: number): Observable<string> {
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }/contract-status`);
  }

  public deleteItem(id: number): Observable<any> {
    // TODO: Implement Delete
    return observableOf({});
  }

  public findAll = () : Observable<ContractFilterData[]> => {
    return this.filter({});
  }

  public filter(params: Partial<ContractFilterData>): Observable<ContractFilterData[]> {
    params.isContract = this.contractType;
    return this.apiHelper.request(`${ this.endpoint }/filter`, { body: params });
  }

  public recent(): Observable<ContractFilterData[]> {
    return this.apiHelper.request(`${ this.endpoint }/dashboard`, { body: { isContract: this.contractType } });
  }

  public save(data: any): Observable<mplus.ContractData[]> {
    return this.apiHelper.request(this.endpoint, { body: data });
  }

  public getContractMessages = (contractId: number): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }/messages`);
  }

  public submitContractMessages = (messages: any[]): Observable<any> => {
    if (!messages || !messages.length) {
      console.warn('Messages: ' + messages);
      throw 'Invalid messages';
    }
    return this.apiHelper.request(`${ this.endpoint }/${ messages[0].agreementId }/messages`, { body: messages });
  }

  public submitContract = (contractId: number, quiet?: boolean): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }/submit`, { quiet });
  }
  
  public executeContract(contractId: number, type: ContractExecutionTypeEnum): Observable<ValidationResult> {
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }/${ type }/execute-contract`);
  }

  public withdrawContract = (contractId: number): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }/withdraw`);
  }

  public inactivateContract = (contractId: number): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }/inactivate`);
  }

  public unterminateContract = (contractId: number): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }/unterminate`);
  }

  public runSeasonalConfigurator = (contractId: number): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }/seasonal-configurator`);
  }

  public printContract = (contractId: number): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }/print`);
  }
  public printAmendment = (contractId: number): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }/amendment/print`);
  }
  
  public printExhibitAB(contractId: number): Observable<ValidationResult> {
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }/print-exhibit-a-b`);
  }
  
  public printExhibitC(contractId: number): Observable<ValidationResult> {
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }/print-exhibit-c`);
  }

  public printAddendum = (contractId: number): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }/print-addendum`);
  }

  public saveMinimizationList = (contractId: number, list: any[]): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }/minimization-list`, { body: list });
  }
}
