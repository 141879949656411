import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ItemApi } from 'cad/common/components/items/item/item-api';
import TransferContractData = mplus.TransferContractData;

@Injectable()
export class AllocationTransferApi implements ItemApi {
  protected readonly endpoint: string = 'allocation/transfer';

  constructor(protected apiHelper: ApiHelper) {}

  public getById(id: number | string): Observable<TransferContractData> {
    return this.apiHelper.request(this.endpoint + '/' + id);
  }

  public find(params: Partial<TransferContractData>): Observable<TransferContractData> {
    return this.getById(params.contractToContractXferId);
  }

  public filter(params: Partial<TransferContractData>): Observable<TransferContractData[]> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public recent(): Observable<TransferContractData[]> {
    return this.apiHelper.request(this.endpoint + '/dashboard');
  }

  public save(params: Partial<TransferContractData>): Observable<TransferContractData> {
    if (params.contractToContractXferId) {
      return this.apiHelper.request(this.endpoint + '/' + params.contractToContractXferId, { body: params });
    } else {
      return this.apiHelper.request(this.endpoint, { body: params, method: 'PUT' });
    }
  }

  public getDefaultTransferDate(): Observable<string> {
    return this.apiHelper.request(this.endpoint + '/defaultTransferDate');
  }

  public deleteItem(item: TransferContractData): Observable<any> {
    throw new Error('delete not implemented');
  }
}

