import { Injectable, Inject } from '@angular/core';
import { ApiCache } from 'cad/common/services/api/api-cache';
import * as _ from 'lodash';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';

export enum CACHE_SEARCH {
  ALLOCATION = 'ALLOCATION',
  SCHEDULE_QUANTITY_FOR_OPERATOR = 'SCHEDULE_QUANTITY_FOR_OPERATOR',
  EDI_CONFIRMATION = 'EDI_CONFIRMATION',
  PARKING_SERVICE = 'PARKING_SERVICE'
}

@Injectable()
export class PinnedSearchCacheService {
  private primaryKey: string;
  constructor(
    private cache: ApiCache,
    private userModelService: UserModelService,
    @Inject('cache') private _cache?: any
  ) {
    this.primaryKey = this._cache.cachePrimaryKey;
  }

  private getCurrentAssetNbr(): number {
    return this.userModelService.getCurrentAssetNbr();
  }

  setCached(param: any): void {
    const items: any[] = this.deleteItem(param[this.primaryKey]);
    this.updateCache(items.concat({ ...param, cacheDate: dateUtil().getCurrentDateTime(), assetNbr: this.getCurrentAssetNbr() }));
  }

  getCachedKeys(): any[] {
    const list = this.getCached().filter((item) => item.assetNbr === this.getCurrentAssetNbr());
    return _.sortBy(list, 'cacheDate').reverse();
  }

  getCachedItem(primaryKey: string): any {
    const list = this.getCached().filter((item) => item.assetNbr === this.getCurrentAssetNbr());
    return list ? list.find((item) => item[this.primaryKey] === primaryKey) : null;
  }

  deleteCachedItem(primaryKey: string): void {
    const items: any[] = this.deleteItem(primaryKey);
    this.updateCache(items);
  }

  private getCached(): any[] {
    return this.cache.for30Days.get(this._cache.cacheKey) || [];
  }

  private deleteItem(primaryKey: string): any[] {
    const items: any[] = this.getCached();
    return items.filter((item) => item[this.primaryKey] !== primaryKey);
  }

  private updateCache(param: any[]): void {
    this.cache.for30Days.put(this._cache.cacheKey, param);
  }
}
