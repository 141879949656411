import { Injectable } from '@angular/core';
import { StateModel } from 'src/cad/common/models/state/state-model';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import {CurrentContext, RootObject} from 'cad/common/store/user/user-model';

@Injectable()
export class AuthService {

  constructor(private userModelService: UserModelService,
              private stateModel: StateModel,) {
  }

  // ========================================
  // implementation
  // ========================================
  public authorize(): boolean {
    let user: RootObject = this.userModelService.getUser();
    let isAuthenticated: boolean = _.isEmpty(user) ? false : user.loggedIn;
    let authorize: boolean = false;

    if (this.stateModel.toState.data.auth) {
      let auth: any = this.getAuth(this.stateModel.toState.data.auth);
      if (auth.anonymous) {
        authorize = true;
      } else if (!isAuthenticated) {
        this.stateModel.returnToState = this.stateModel.toState;
        this.stateModel.returnToStateParams = this.stateModel.returnToStateParams;
        // this.$state.go('cad.login'); // FIXME(ng2)
      } else if (auth.enabled) {
        authorize = true;
      } else if (!auth.enabled) {
        // this.$state.go('cad.status.forbidden'); // FIXME(ng2)
      }
    } else {
      console.log('This route has not been properly secured.');
      authorize = true;
    }
    return authorize;
  }

  public getAuthorizationForAction = (userContext: CurrentContext, name: string): any => {
    return this.isUserAuthorized(userContext, name) || this.isUserAuthorized(userContext, name + '.all')
      || this.getOverride(userContext, name) || { visible: false, enabled: false };
  }

  private isUserAuthorized = (userContext: CurrentContext, actionName: string): any => {
    if (!userContext || !userContext.authList) {
      return { visible: false, enabled: false };
    }
    return _.find(userContext.authList, { componentName: actionName });
  }

  private getOverride = (userContext: CurrentContext, name: string): any => {
    let authParent: any = ''; // TODO(ng2): this doesn't look like the right type for the return value
    let nameVar = name;
    while (nameVar.indexOf('.') !== -1) {
      nameVar = nameVar.substr(0, nameVar.lastIndexOf('.'));
      if(nameVar === 'CustomerActivities.menu') {
        return undefined;
      }
      authParent = this.isUserAuthorized(userContext, nameVar + '.all');
      if (authParent) {
        break;
      }
    }
    if(authParent === '') {
      return undefined;
    }
    return authParent;
  }

  private getAuth = (name: string): any => {
    let cc = this.userModelService.getCurrentContext();
    if (cc) {
      return this.findAuth(name, cc) || this.findAuth(name + '.all', cc) || this.getOverride(cc, name) || {
        visible: false, enabled: false };
    }
    return null;
  }

  private findAuth = (name: any, currentContext: CurrentContext): any => {
    if (name === 'anonymous') {
      return { visible: true, enabled: true, anonymous: true };
    }
    if (currentContext && currentContext.authList) {
      return _.find(currentContext.authList, { componentName: name });
    }
  }
}
