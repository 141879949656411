import { Host, Component, Inject, forwardRef } from '@angular/core';
import { UiExpandableDirective } from '../expandable.directive';

@Component({
  selector: 'expandable-button',
  templateUrl: './expandable-button.component.html',
  styleUrls: [ '../expandable.less' ], })
export class ExpandableButtonComponent {
  constructor(@Host() @Inject(forwardRef(() => UiExpandableDirective)) public parent: UiExpandableDirective) {}
}
