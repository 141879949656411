import {ApiHelper} from 'cad/common/services/api/api-helper';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {SearchScheduledQuantityByCycle} from 'cad/nominations/scheduled-quantity/store/search-scheduled-quantity-by-cycle';
import {ScheduledQuantityByCycle} from 'cad/nominations/scheduled-quantity/store/scheduled-quantity-by-cycle';

@Injectable()
export class ScheduledQuantityByCycleApi {

  private readonly endpoint: string = 'schedule-quantity/schedule-quantity-matrix';

  constructor(
        private apiHelper: ApiHelper) {
  }

  public find(params: Partial<SearchScheduledQuantityByCycle>): Observable<ScheduledQuantityByCycle[]> {
    return this.apiHelper.request(this.endpoint + '/shipper-sq-by-cycle', {body: params});
  }

}
