import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'src/cad/common/services/api/api-helper';

@Injectable()
export class CapacityReleaseOfferAwardsLocationsApi {
  private readonly endpoint: string = 'capacity-release/offer/';
  private readonly suffix: string = '/awards';
  private readonly suffix2: string = '/locations';

  constructor(private apiHelper: ApiHelper) {}

  public getById = (auctionId: number | string, awardId: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/' + awardId + this.suffix2);
  }
}
