
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SecurityAuthApi } from 'cad/common/services/api/security/auth/auth';
import { AuthInterceptorService } from 'common/services/http-interceptor/auth-interceptor-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {


  constructor(private securityAuthApi: SecurityAuthApi, private authService: AuthInterceptorService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    // Get the auth header from the service.
    let authToken = this.securityAuthApi.getAuthToken();
    // Clone the request to add the new header.
    if(authToken) {
      authReq = req.clone({setHeaders: {Authorization: authToken}});
      console.log('here is the authtoken ', authToken);
    }
    if(req.url.endsWith('security/logout')) {
      authReq = req.clone({setHeaders: {Authorization: 'NTLM'}});
    }
    // Pass on the cloned request instead of the original request.
    //return next.handle(authReq);
    return next
      .handle(authReq).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          // todo handle a response if we need to
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            console.error('401 unauthorizied');
            this.authService.unauthorizedSubject.next();
          }
        }
      }));
  }
}
