import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from '../../api-helper';

@Injectable()
export class TransactionalPostingsCommentApi {
  endpoint: string = 'infopost/transactional-postings';
  constructor(private apiHelper: ApiHelper) {}

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/${params.contractId}/comments`, { method: 'GET' });
  }
}
