import { AgEditorComponent } from '@ag-grid-community/angular';

export class TableColumnToggleBaseEditor implements AgEditorComponent {
  public value: any;

  protected params: any;
  protected cancelBeforeStart: boolean;

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
    this.params.api.tabToNextCell();
    this.cancelBeforeStart = false;
  }

  getValue(): any {
    return this.value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  onKeyDown(e: KeyboardEvent): void {
    e.stopPropagation();
    e.preventDefault();
  }

  onClick(e: MouseEvent): void {
    alert('test');
  }

}
