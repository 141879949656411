import { Component, Injector, Input, Output, EventEmitter, ContentChild, TemplateRef } from '@angular/core';
import { ButtonAlertComponent } from './button-alert/button-alert.component';
import { BaseButtonComponent } from './base-button/base-button.component';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'ui-button-content',
  templateUrl: './button-content.component.html',
  styleUrls: [ './button-content.less' ],
})
export class ButtonContentComponent extends BaseButtonComponent {

  @Input() public label: string;
  @Input() public type: string;
  @Input() public isInvalid: boolean;
  /**
   * For whatever reason, a decision was made to made the "disabled" property
   * on ui-button be called "isInvalid".  I'm not a fan of this.  This getter
   * and setter is being used as essentially an alias to keep backwards compat
   * with code still using "isInvalid"
   */
  @Input() public set disabled(val: boolean) {
    this.isInvalid = val;
  }
  public get disabled(): boolean {
    return this.isInvalid;
  }
  @Input() public option: string;
  // alert config
  @Input() public dialogTitle: string;
  @Input() public dialogMessage: string;
  @Input() public dialogLabel: string;
  @Input() public dialogBtnText: string;
  @Input() public skipDisable: boolean;
  // toggle
  @ContentChild('template') template: TemplateRef<any>;
  @ContentChild('dropDownTemplate') dropDownTemplate: TemplateRef<any>;
  @Input() protected toggleChecked: boolean;
  @Output() protected toggleChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected matDialog: MatDialog;

  constructor (protected injector: Injector) {
    super(injector);
    if (injector) {
      this.matDialog = injector.get(MatDialog);
    }
  }

  public showAlert(): void {
    if (this.matDialog) {
      this.matDialog.open(ButtonAlertComponent, {
        width: '80%',
        data: {
          title: this.dialogTitle,
          message: this.dialogMessage,
          label: this.dialogLabel,
          btnText: this.dialogBtnText,
        }
      });
    }
  }

  public onToggleButtonChanged(event: MatButtonToggleChange): void {
    this.toggleChecked = event.source.checked;
    this.toggleChanged.emit(event.source.checked);
  }
}
