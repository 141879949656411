import { Pipe, PipeTransform } from '@angular/core';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import { CodesModel } from 'src/cad/common/models/codes/codes-model';

@Pipe({ name: 'etCode' })
export class ETCodePipe implements PipeTransform {

  constructor(
    private userModelService: UserModelService,
    private codesModel: CodesModel,
  ) {}

  transform(key: string | number, codeTypeStr: string, assetFilter?: number): any {
    let assetNbr = this.userModelService.getCurrentAssetNbr();
    let rv;

    //if we have an asset specific call here, use that
    if (assetFilter) {
      rv = this.getCodeValue(assetFilter, codeTypeStr, key);
    }

    //else fall back to the current asset (we'd like to be able to render this)
    if (!rv) {
      rv = this.getCodeValue(assetNbr, codeTypeStr, key);
    }

    if (rv) {
      return rv;
    }
    //otherwise return the code that was passed in.
    return key;
  }
  private getCodeValue(assetNbr: number, codeTypeStr: string, key: string | number): any {
    if ((this.codesModel as any).rawCodes[assetNbr]) { // bit of a hack to get access to private
      let asset = (this.codesModel as any).rawCodes[assetNbr]; // bit of a hack to get access to private
      let codeType: any = _.find(asset.codeTypes, { type: codeTypeStr });
      if (codeType && codeType.codes) {
        /* tslint:disable:no-param-reassign */
        key = _.isNumber(key) ? key.toString() : key;
        /* tslint:enable:no-param-reassign */
        let code: any = _.find(codeType.codes, { cd: key });
        if (code && code.value) {
          return code.value;
        }
      }
    }
    return undefined;
  }
}
