<div class="process-status-container">
  <h1 matDialogTitle
      uiDraggableModal
      uiDraggableModalContainer=".ui-floating-content">Active Processes</h1>
  <div fxLayout="column"
       fxLayoutAlign="center stretch">
    <div *ngFor="let processModel of processList; let isLast=last">
      <div fxLayout="row"
           fxLayoutAlign="stretch center"
           fxLayoutGap="10px">
        <ui-md-icon [tooltip]="processModel.statusTooltip"
                    [mdIconName]="processModel.statusIconName"
                    [mdIconClass]="processModel.statusIconClass"></ui-md-icon>
        <div fxFlex="auto">
          {{processModel.processName}}&nbsp;:&nbsp;
          <span class="{{processModel.statusIconClass}}">
            <strong>{{processModel.statusDescription}}</strong>
          </span>
        </div>
        <button mat-button
                mat-icon-button
                type="button"
                fxFlexAlign="end center"
                (click)="removeProcess(processModel.processName)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <mat-divider *ngIf="!isLast"></mat-divider>
    </div>
  </div>
  <div matDialogActions
       fxLayout="row"
       fxLayoutAlign="end center">
    <button mat-raised-button
            type="button"
            color="primary"
            (click)="hide()">HIDE
    </button>
  </div>
</div>
