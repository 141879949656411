
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import * as _ from 'lodash';

@Injectable()
export class AssociateContactNotificationApi {

  public endpoint: string = 'associate/contact/';
  public suffix: string = '/notifications';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public findAll = (contactId: string | number): Observable<any> => {
    return this.apiHelper.request(this.endpoint + contactId + this.suffix);
  }
  
  public filter(params: any): Observable<any> {
    if (params) {
      return this.apiHelper.request(this.endpoint
        + (!_.isNil(params.contactId) ? params.contactId : -1)
        + this.suffix
        + '/filter', { body: params });
    }
    return observableOf([]);
  }

  public save = (notifications: any[]): Observable<any> => {
    return this.apiHelper.request(this.endpoint + notifications[0].contactId + this.suffix, { body: notifications });
  }
}
