import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ApiHelper} from 'cad/common/services/api/api-helper';
import {
  ConfirmationReportExecutionSchedule
} from 'cad/administration/table-maintenance/confirmation-report-execution-schedule/shared/interfaces/confirmation-report-execution-schedule';
import {Workflow} from 'cad/administration/table-maintenance/shared/interfaces/workflow';
import ValidationResult = cad.ValidationResult;

@Injectable()
export class ConfirmationReportExecutionScheduleApi {

  private readonly endpoint: string = 'administration/table-maintenance/confirmation-report-exec-schedule';

  constructor(
    private apiHelper: ApiHelper) {
  }

  public getAll(): Observable<ConfirmationReportExecutionSchedule[]> {
    return this.apiHelper.request(this.endpoint +'/dashboard');
  }

  public saveConfirmationReportExecSchedule(params: ConfirmationReportExecutionSchedule[]): Observable<ValidationResult<ConfirmationReportExecutionSchedule[]>> {
    return this.apiHelper.request(this.endpoint + '/save', {body: params});
  }

  public filter(params: Workflow): Observable<Workflow[]> {
    return this.apiHelper.request(this.endpoint +'/workflowConfig',{ body: params });
  }

}
