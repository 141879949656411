//import * as moment from 'moment';
import * as moment from 'moment-timezone';

export function cadDateUtilInstance(): CadDateUtil {
  return CadDateUtil.instance();
}

const DATE_FORMAT_MM_DD_YYYY = 'MM/DD/YYYY';

export class CadDateUtil {

  private static singleton: CadDateUtil;

  public assetModel: any = { assetAbbr: '' };
  /** Regular expression to test for date patterns that do not parse correctly with Moment, the format needs to be supplied in order
   * for Moment to correctly parse the date with timezones, Ex: '5-1-24' gets parsed to '2024-04-30T23:00:00.000-0500' without
   * the supplied parse format for EST timezone setting. Moment parse format of 'MM DD YYYY' works for all the date patterns without the time.
   * Another example is '04/01/2023' gets formatted (setting the machine time to EST and using moment('04/01/2023').format())
   * to '2023-03-31T23:00:00.000-0500' without the parse format, when given the above parse format
   * (using moment('04/01/2023', 'MM DD YYYY').format()) it correctly outputs '2023-04-01T00:00:00.000-0500'
  */
  public nonRecognizedMomentDatePattern: RegExp = RegExp(/^(0?[1-9]|1[0-2])[\/-](0?[1-9]|1[0-9]|2[0-9]|3[01])[\/-](((19|2[0-9])[0-9]{2})|([0-9]{2}))$/);

  constructor() {
    /**
     * Default end dates differ between pipes, for this reason this utility is dependent
     * on the assetModel being set to give correct end dates.  If users instantiate their
     * own date utility, they could be given an incorrect default end date (FGT)
     */
    if (this instanceof CadDateUtil && CadDateUtil.singleton) {
      throw 'Cannot instantiate singleton: CadDateUtil.  Get a reference to the singleton instance by calling dateUtil()';
    }
    moment.tz.setDefault('America/Chicago');
  }

  public static instance(): CadDateUtil {
    if (!this.singleton) {
      this.singleton = new CadDateUtil();
    }
    return this.singleton;
  }

  public getCurrentDateTime = (): string => {
    return moment().format();
  }

  public getYesterdayDateTime = (): string => {
    return moment('0', 'hh'). subtract(1, 'days').format();
  }

  public getToday = (format?: string): string => {
    return moment().startOf('day').format(format || null);
  }

  public getTodayFormatted = (format: string = DATE_FORMAT_MM_DD_YYYY): string => {
    return this.getToday(format);
  }

  public getTommorrow = (format: string = DATE_FORMAT_MM_DD_YYYY): string => {
    return moment().add(1, 'day').format(format);
  }

  public getDayAfterTommorrow = (format: string = DATE_FORMAT_MM_DD_YYYY): string => {
    return moment().add(2, 'day').format(format);
  }

  public getYesterday = (format?: string): string => {
    return moment().subtract(1, 'day').format(format || null);
  }

  public getPrettyPrintDate = (): string => {
    return moment().format('MMMM Do YYYY');
  }

  public getPrintDateFormat = (input: any): string => {
    return moment(input).format('MM/DD/YYYY');
  }
  public getPrettyPrintDateTime = (): string => {
    return moment().format('dddd, MMMM Do, YYYY - hh:mm:ss A zz');
  }

  public getFormattedDate = (dateStr: string, format:string ='MM-DD-YYYY'): string => {
    return moment(dateStr).format(format);
  }

  public getFormattedDateTime = (dateTimeStr: string, format:string ='MM-DD-YYYY hh:mm:ss A zz'): string => {
    return moment(dateTimeStr).format(format);
  }

  public getDefaultEndDate(useLocalTime?: boolean): string {
    /*
    TODO: Refactor this into a global config
    of some sort to set system defaults for
    each asset.
    */

    let assetDefaults: any = {};
    _.defaults(assetDefaults, {
      ET: {
        defaultEndDate: moment([ '2999', '11', '31' ]),
      },
      defaultEndDate: moment([ '2200', '00', '01' ]),
    });

    let end = assetDefaults.defaultEndDate;

    if (assetDefaults[this.assetModel.assetGroupCd] && assetDefaults[this.assetModel.assetGroupCd].defaultEndDate) {
      end = assetDefaults[this.assetModel.assetGroupCd].defaultEndDate;
    }

    return useLocalTime ? end.local().startOf('day').format() : end.startOf('day').format();
  }

  public getDefaultStartDate = (): string => {
    return moment([ '1900', '00', '01' ]).startOf('day').format();
  }

  public convertDateStringToGivenFormat = (date: string, input: string, output: string = 'YYYY-MM-DD') : string => {
    return moment(date, input).format(output);
  }

  public getDateFromGivenFormat = (date: string, format: string): Date => {
    return moment(date, format).toDate();
  }

  public getDateFromDate = (date: Date, format: string): Date => {
    return moment(date, format).toDate();
  }

  public getStartOfToday = (): string => {
    return moment().startOf('day').format();
  }

  public getFirstDayOfMonth = (format?: string): string => {
    let date = new Date();
    let time = moment([ date.getFullYear(), date.getMonth() ]);
    return time.format(format || null);
  }

  public getFirstDayOfMonthFromGivenString = (date: string): Date => {
    return moment(date,'MM/YYYY').toDate();
  }

  public getFirstDayOfPreviousMonth = (): string => {
    let date = new Date();
    date.setDate(-1);
    return moment([ date.getFullYear(), date.getMonth() ]).format();
  }

  public getFirstDayOfNextMonthForGivenDate(input: string): string {
    return moment(input).add(1, 'month').startOf('month').format();
  }

  public getLastDayOfNextMonthForGivenDate = (input: any): string => {
    return moment(input).add(1, 'month').endOf('month').format();
  }

  /**
   * @description Returns last day of the current month in '2018-09-30T23:59:59.999-0500' format
   *              with an optional flag to reset the time portion to '00:00:00.000'
   *              Ex: resetTime = true => '2018-09-30T00:00:00.000-0500'
   *              resetTime = false/default => '2018-09-30T23:59:59.999-0500'
   * @param resetTime
   * @returns {string}
   */
  public getLastDayOfMonth = (resetTime?: boolean): string => {
    return resetTime
      ? moment().endOf('month').startOf('day').format()
      : moment().endOf('month').format();
  }

  /*
  Returns Day "02/28/2018" format
  */
  public getLastDateOfMonth = (dateString: string = DATE_FORMAT_MM_DD_YYYY): string => {
    return moment().endOf('month').format(dateString);
  }

  // Returns today's date if called before 12PM otherwise returns tomorrows date
  public getTodayBefore12pmElseTomorrow = (format: string = DATE_FORMAT_MM_DD_YYYY): string => {
    const now = moment();
    return now.hours() >= 12 ? now.add(1, 'day').format(format) : now.format(format);
  }

  // Returns today's date if called before 12PM otherwise returns tomorrows date
  public getTodayBefore12pmElseDayAfterTomorrow = (format: string = DATE_FORMAT_MM_DD_YYYY): string => {
    const now = moment();
    return now.hours() >= 12 ? now.add(2, 'day').format(format) : now.format(format);
  }

  /*
  Returns Day "02/28/2018" format
  */
  public getLastDayOfMonthForGivenDate = (input: any): string => {
    return moment(input).endOf('month').format(DATE_FORMAT_MM_DD_YYYY);
  }

  public getFirstDayOfMonthForGivenDate = (input: any): string => {
    return moment(input).startOf('month').format(DATE_FORMAT_MM_DD_YYYY);
  }

  /*
   Returns Day with TimeStamp "2018-04-30T00:00:00.000-0500" format
   */
  public getLastDateTSofMonthForGivenDate = (input: any): string => {
    return moment(input).endOf('month').startOf('day').format();
  }

  public getQuarter(input: Date): number {
    return moment(input).quarter();
  }

  public getDayMonthDateYearByGivenDate(input: Date): string {
    return moment(input).format('dddd, MMMM DD, YYYY');
  }

  public getMonthDateYearByGivenDate(input: Date): string {
    return moment(input).format('MMMM DD, YYYY');
  }

  public getQuarterYear(input: Date): string {
    const quarter = this.getQuarter(input);
    return [ 'first','second', 'third','fourth' ].filter((item,idx) => idx === quarter - 1).map((item) => `${item} Quarter ${moment(input).year()}`.toUpperCase())[0];
  }

  public filterItems = (items: any[], filter: any): any[] => {
    return _.filter(items, this.getFilter(filter));
  }

  public getFilter = (filter: any): (item: any) => boolean => {
    if (filter.from && filter.to) {
      return filterFromAndTo;
    } else if (filter.from) {
      return filterFrom;
    } else if (filter.to) {
      return filterTo;
    } else {
      return (item: any) => { return true; };
    }

    function filterFrom(item: any): boolean {
      return item.effBeginDt <= filter.from || item.effEndDt >= filter.from;
    }

    function filterTo(item: any): boolean {
      return item.effBeginDt <= filter.to || item.effEndDt >= filter.to;
    }

    function filterFromAndTo(item: any): boolean {
      return filterFrom(item) && filterTo(item);
    }
  }
  
  public getNonMomentParsedDate(dateValue: string | moment.Moment): moment.Moment {
    return (typeof dateValue === 'string' && this.nonRecognizedMomentDatePattern.test(dateValue.replace(/\s/g, '')))
      ? moment(dateValue, 'MM DD YYYY')
      : (moment.isMoment(dateValue) ? dateValue : moment(dateValue));
  }
}
