import { Component, Inject, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { TABLE_EDITOR_DATA } from 'src/ag-grid-wrapper';
import * as _ from 'lodash';

@Component({
  selector: 'table-time-editor',
  template: `
    <div class="time">
      <input #input
             matInput
             [(ngModel)]="params.value"
             [uiInputMask]="customCellConfig?.maskType"
             [uiInputMaskOptions]="{'autoUnmask':true,'removeMaskOnSubmit':true}"
             placeholder="">
    </div>
  `,
  styleUrls: [ './time-editor.component.less' ],
})
export class TimeEditorComponent implements AfterViewInit {

  @ViewChild('input', { read: ViewContainerRef }) public input: any;
  public get customCellConfig(): any {
    if (this.params
      && this.params.column
      && this.params.column.colDef) {
      return (_.isFunction(this.params.column.colDef.customCellConfig)
        ? this.params.column.colDef.customCellConfig(this.params)
        : this.params.column.colDef.customCellConfig);
    }
    return null;
  }

  constructor (@Inject(TABLE_EDITOR_DATA) public params: any) {}

  ngAfterViewInit(): void {
    // Do a setTimeout to allow time for the value to render
    setTimeout((): void => {
      if (this.input && this.input.element && this.input.element.nativeElement) {
        this.input.element.nativeElement.focus();
        this.input.element.nativeElement.select();
      }
    });
  }
}
