import { UserStoreService } from 'cad/common/store/core/services/user-store.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot } from '@angular/router';

import { SecurityModel } from 'src/features/common/security/security.model';
import { SecurityAuthorizer } from 'src/features/common/security/security-authorizer';

import { Observable } from 'rxjs';

import { SecurityAuthState } from './security-auth-state';
import { UserModelService } from 'cad/common/services/user/user-model-service';
import { RouterService } from 'src/cad/core/services/router/router.service';
import {UserService2} from 'cad/common/store/user/services/user.service';
import {CurrentContext} from 'cad/common/store/user/user-model';


@Injectable()
export class SecurityAuthorizerService extends SecurityAuthorizer {

  constructor(
    private router: RouterService,
    private userModelService: UserModelService,
    private userService: UserService2,
    private userStoreService: UserStoreService
  ) {
    super();
  }

  public canActivate(
    config: SecurityModel,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // console.log(`canActivate for ${state.url} : ${config.authorization}`);
    const allowed = this.isAuthorized(config, state);
    if (!allowed) {
      console.warn(`canActivate not allowed for ${state.url} : ${config.authorization}`);
    }
    return allowed;
  }

  public canActivateChild(
    config: SecurityModel,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // console.log(`canActivateChild for ${state.url} : ${config.authorization}`);
    const allowed = this.isAuthorized(config, state);
    if (!allowed) {
      console.warn(`canActivateChild not allowed for ${state.url} : ${config.authorization}`);
    }
    return allowed;
  }

  public canLoad(
    config: SecurityModel,
    route: Route,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // console.log(`canLoad for path '${route.path}', pathMatch '${route.pathMatch}' : ${config.authorization}`);
    const allowed = this.isAuthorized(config, route);
    if (!allowed) {
      console.warn(`canLoad not path '${route.path}', pathMatch '${route.pathMatch}' : ${config.authorization}`);
    }
    return allowed;
  }

  private isAuthorized(config: SecurityModel, route: Route | RouterStateSnapshot  ): boolean {
    let userState = this.userService.getCurrentContext();
    let isAuthenticated: boolean = _.isEmpty(userState) ? false : userState.loggedIn;
    let activate = false;
    if (config.authorization) {
      let auth: SecurityAuthState = this.getAuth(config.authorization);
      if (auth.anonymous) {
        activate = true;
      } else if (!isAuthenticated) {
        // this.stateModel.returnToState = this.stateModel.toState; // FIXME(ng2) -- is this still needed?
        // this.stateModel.returnToStateParams = this.stateModel.returnToStateParams; // FIXME(ng2) -- is this still needed?
        const url = route.hasOwnProperty('path') ? (route as Route).path : (route as RouterStateSnapshot).url   ;
        this.userStoreService.setPostLoginRedirect(url);
        this.router.navigate([ '/login' ]);
      } else if (auth.enabled) {
        activate = true;
      } else if (!auth.enabled) {
        this.router.navigate([ '/forbidden' ]);
      }
    } else {
      console.warn('This route has not been properly secured.');
      activate = true;
    }
    return activate;
  }

  private getAuth = (name: string): SecurityAuthState => {
    if (name === 'anonymous') {
      return { visible: true, enabled: true, anonymous: true };
    }
    let cc = this.userModelService.getCurrentContext();
    let auth: SecurityAuthState;
    let parent: string = name.slice(0, name.lastIndexOf('.'));
    if (cc) {
      auth = this.findAuth(name, cc) || this.findAuth(name + '.all', cc) || this.findAuth(parent + '.all', cc) || this.findOverride(name, cc);
    }
    return auth ? auth : { visible: false, enabled: false };
  }

  private findAuth = (name: any, cc: CurrentContext): SecurityAuthState => {
    return _.find(cc.authList, { componentName: name }) as SecurityAuthState;
  }

  private findOverride = (name: string, cc: CurrentContext): SecurityAuthState => {
    let nameVar = name;
    
    while (nameVar.indexOf('.') !== -1) {
      nameVar = nameVar.substr(0, nameVar.lastIndexOf('.'));
      if (nameVar === 'CustomerActivities.menu') {
        return null;
      }
      let auth = this.findAuth(nameVar + '.all', cc);
      if (auth) {
        return auth;
      }
    }
    return { visible: false, enabled: false };
  }
}
