import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { PhysicalLocationAssociatedMetersApi } from 'cad/common/services/api/physical/location/location-meters/location-meters';
import { PhysicalLocationMeterSchedulingSplitApi } from 'cad/common/services/api/physical/location/meter-scheduling-split/meter-scheduling-split';
import RBSCodeValue = mplus.RBSCodeValue;
import { ItemApi } from 'src/features/common/item/item-api';
import { PhysicalAssetApi } from 'cad/common/services/api/physical/asset/asset';
import { Asset } from 'cad/physical/interfaces/asset';
import { concatMap, shareReplay, map } from 'rxjs/operators';

@Injectable()
export class PhysicalLocationApi extends ItemApi {
  private readonly endpoint: string = 'physical/locations';
  private assets$: Observable<Asset[]>;

  constructor(
    public associatedMeters: PhysicalLocationAssociatedMetersApi,
    public meterSchedulingSplit: PhysicalLocationMeterSchedulingSplitApi,
    private apiHelper: ApiHelper,
    private physicalAssetApi: PhysicalAssetApi,
  ) {
    super();
  }

  // so changing assets will trigger a refresh.
  private initAssets(): void {
    this.assets$ = this.physicalAssetApi.findAll().pipe(shareReplay());
  }

  public filter(params: any): Observable<any> {
    return this.addAssetName(this.apiHelper.request(this.endpoint + '/filter', { body: params }));
  }

  public recent(): Observable<any> {
    return this.addAssetName(this.apiHelper.request(this.endpoint + '/dashboard'));
  }

  private addAssetName(locations$: Observable<{ assetNumber: number }[]>): Observable<{ assetNumber: number, assetName: string}[]> {
    this.initAssets();
    return locations$.pipe(concatMap((locations) =>
      this.assets$.pipe(map((assets) => {
        return locations.map((location) => {
          const foundAsset: Asset = assets.find((asset) => asset.number === location.assetNumber);
          return {...location, assetName: foundAsset && foundAsset.name ? foundAsset.name : location.assetNumber.toString() };
        });
      }))));
  }

  /* TODO: self.getById should be renamed to self.find */
  public getById = (id: number | string): Observable<any> => {
    // Add pointCd to Point Object so that the objects key matches location search.
    return this.apiHelper.request(this.endpoint + '/' + id).pipe(map((point) => {
      point.pointCd = point.code; return point;
    }));
  }

  public find(params: any): Observable<any> {
    return this.getById(params.pointCd);
  }

  public save(itemData: any): Observable<any> {
    return this.apiHelper.request(this.endpoint, { body: itemData });
  }

  public savePoint = (itemData: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/savePoint', { body: itemData });
  }

  public lookup = (): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/lookup');
  }

  public locationMeters = (id: any): Observable<RBSCodeValue> => {
    return this.apiHelper.request(this.endpoint + '/' + id + '/location-meters');
  }

  public findData = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/point-volume', { body: params });
  }

  public getParentSegments = (id: any, segmentCd: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/' + id + '/parent-segments/' + segmentCd);
  }

  public getAllPointsWithASurveyId = (surveyId: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/survey-points/' + surveyId);
  }

  public deleteItem(item: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/delete', { body: item });
  }

  public getMeterDetails = (id: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/' + id + '/copy-meter');
  }
}
