
import {filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { EmitterService } from 'cad/core/services/emitter/emitter.service';
import {UserStoreService} from 'cad/common/store/core/services/user-store.service';
import {StoreState} from 'cad/common/store/core/store-state';

@Injectable()
export class SecurityModel {

  private protectionMap: any = {};
  private subscriptions: Subscription[] = [];

  constructor(
    private emitterService: EmitterService,
    private userService: UserStoreService,
  ) {
    this.subscriptions.push(this.userService.stateChanged.pipe(
      filter((userDetails) => userDetails.state.user))
      .subscribe((userDetails) => this.onUserContext(userDetails.state)));
  }

  public buildProtectionMap = (authList: any): void => {
    this.protectionMap = {CustomerActivities: {}};
    if (authList) {
      for (let auth of authList) {
        //Customer activities are one deeper than all the other items.
        if (auth.componentName.startsWith('CustomerActivities')) {
          this.addItems(auth.componentName.slice(19), this.protectionMap.CustomerActivities, auth.enabled, auth.visible);
        } else {
          this.addItems(auth.componentName, this.protectionMap, auth.enabled, auth.visible);
        }
      }
    }
  }

  public getActionGroup = (context: any, actionGroup: any): any => {
    let rv = {};
    if (!_.isEmpty(context)) {
      rv = this.getContext(context)[ actionGroup ];
    }
    return rv;
  }

  public getContext = (context: any): any => {
    let rv = {};
    if (!_.isEmpty(this.protectionMap)) {
      rv = this.protectionMap[ context ];
    }
    return rv;
  }

  public getAuthList = (): any => {
    return this.protectionMap;
  }

  private addItems(componentName: string, mapPath: any, isEnabled: boolean, isVisible: boolean): void {
    let componentProps = componentName.split('.');
    if (!mapPath[ componentProps[ 0 ] ]) {
      mapPath[ componentProps[ 0 ] ] = {};
    }
    if (!mapPath[ componentProps[ 0 ] ][ componentProps[ 1 ] ]) {
      if (componentProps.length === 2) {
        mapPath[ componentProps[ 0 ] ][ componentProps[ 1 ] ] = {
          enabled: isEnabled,
          visible: isVisible
        };
      } else {
        mapPath[ componentProps[ 0 ] ][ componentProps[ 1 ] ] = {};
      }
    }

    for (let i = 2; i < componentProps.length; i++) {
      mapPath[ componentProps[ 0 ] ][ componentProps[ 1 ] ][ (componentProps[ i ]) ] = {
        enabled: isEnabled,
        visible: isVisible
      };
    }
  }

  private onUserContext(userDetail: StoreState): void {
    if (userDetail.user && userDetail.user.currentContext) {
      this.buildProtectionMap(userDetail.user.currentContext.authList);
    }
  }
}
