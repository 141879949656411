import { Injectable } from '@angular/core';
import { NomBaseDatesUtil } from 'src/cad/nominations/common/utils/nom-base-date/nom-base-date';

@Injectable()
export class ConfDateUtil {
  constructor() {
    return ConfDateFactory;
  }
}

class ConfDateFactory extends NomBaseDatesUtil {
  constructor(private c: any) {
    // super($stateParams);
    super();
    this.ctrl = c;
  }

  public setDefaultDates = (ctrl?: any): void => {
    let obj = this.ctrl ? this.ctrl : ctrl;
    if (obj.flowBeginDate === null || obj.flowBeginDate === undefined) {
      _.assign(obj, {
        flowBeginDate: this.getDefaultStartDate(),
      });
    }
  }

  public getDefaultStartDate = (): string => {
    if (moment().hour() >= 13) {
      return moment().startOf('day').add(1, 'day').format();
    } else {
      return moment().startOf('day').format();
    }
  }

  public setRequestDates = (rowEntity: any, ctrl?: any): void => {
    let obj = this.ctrl ? this.ctrl : ctrl;
    _.assign(rowEntity, {
      flowBeginDate: moment(obj.flowBeginDate).startOf('day').format(),
    });
  }

  public setStateParamDates = (ctrl?: any): void => {
    let obj = this.ctrl ? this.ctrl : ctrl;
    // _.assign(this.$stateParams, {
    //   flowBeginDate: obj.flowBeginDate,
    // });
  }

}
