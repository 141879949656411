<mat-sidenav-container class="side-nav-container">
  <mat-sidenav #alertsSidenav mode="over" align="end" position="end" class="alert-nav">
    <mat-toolbar class="alerts-header"
                 fxLayout="row">
      <div fxFlex=""><h3>Alerts</h3></div>
      <div fxLayout="column"
           fxLayoutAlign="end center">
        <button mat-button
                mat-icon-button
                type="button"
                matTooltip="Remove All Alerts"
                (click)="dismissAllAlerts()"
                [disabled]="!(alerts?.length > 0)">
          <mat-icon>delete_sweep</mat-icon>
        </button>
      </div>
    </mat-toolbar>
    <div class="alerts-wrapper">
      <div class="alert" *ngFor="let alert of alerts">

        <div class="alert__title">

          <div class="alert__icon">
            <mat-icon [ngClass]="alert.alertClass">{{alert.icon}}</mat-icon>
          </div>

          <h3 *ngIf="alert.screen" [ngClass]="alert.alertClass">
            {{alert.type}} - {{alert.screen}}
          </h3>

          <h3 *ngIf="!alert.screen" [ngClass]="alert.alertClass">
            {{alert.type}}
          </h3>

        </div><!-- end alert__title -->

        <div class="alert__datetime">
          {{alert.date|date:'short'}}
        </div>

        <div class="alert__message">
          <p [innerHTML]="alert.msg"></p>
        </div>

        <div class="alert__actions">
          <a mat-button class="mat-primary" [routerLink]="alert.url" (click)="toggle()" *ngIf="!alert.href">View</a>
          <a mat-button class="mat-primary" href="{{alert.href}}" (click)="toggle()" *ngIf="alert.href">{{alert.linkDesc}}</a>
          <button mat-button
                  mat-icon-button
                  class="mat-icon-button"
                  type="button"
                  aria-label="Remove Alert"
                  matTooltip="Remove Alert"
                  (click)="dismissAlert(alert)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>

    </div>
  </mat-sidenav>
</mat-sidenav-container>
