
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { CodeValueData } from 'cad/shared/interfaces/code-value-data';
import { ContactData } from 'src/cad/data-administration/shared/interfaces/contact';
import { AuctionData } from 'src/cad/capacity-release/shared/interfaces/auction-data';
import { CapRelCommentTemplate } from 'src/cad/capacity-release/shared/interfaces/caprel-comment-template';
import ValidationResult = cad.ValidationResult;

@Injectable()
export class CapacityReleaseOffersApi {
  private readonly endpoint: string = 'capacity-release/offer';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public filter = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public all = (): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/all');
  }

  public recent = (offerData: any = {}): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/dashboard', { body: offerData });
  }

  public getById = (auctionID: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/' + auctionID);
  }

  public find = (params: any): Observable<any> => {
    return this.getById(params.auctionID);
  }

  public getOffersToCopy = (filterData: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/offers-to-copy', { body: filterData });
  }

  public save = (itemData: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/save', { body: itemData });
  }

  public deleteItem = (item: any): Observable<any> => {
    return observableOf({msg:'*** DELTE NOT IMPLEMENTED HERE ***'});
  }

  public getStaticData = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/static-data', { body: params });
  }

  public getStorageData = (auctionID: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/storage-data/' + auctionID);
  }

  public getContracts = (): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/contracts');
  }

  public getContractDetail = (contractId: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/contract-detail/' + contractId);
  }

  public getNotificationContact = (): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/notification-contact');
  }

  public getCRAwardByReplacementContractID = (replacementContractId: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/award-data/' + replacementContractId);
  }

  public postOffer = (itemData: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/post-offer', { body: itemData });
  }

  public withdrawOffer = (itemData: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/withdraw-offer', { body: itemData });
  }
  
  public rejectOffer(itemData: AuctionData): Observable<ValidationResult> {
    return this.apiHelper.request(this.endpoint + '/reject-offer', { body: itemData });
  }
  
  public getValidRecallCyclesForOffer(offerId: number | string): Observable<CodeValueData[]> {
    return this.apiHelper.request(this.endpoint + '/' + offerId + '/valid-recall-cycles');
  }
  
  public getCurrentUserContact(): Observable<ContactData> {
    return this.apiHelper.request(this.endpoint + '/current-user-contact');
  }
  
  public hideOfferLocationsOnService(contractId: string | number): Observable<boolean> {
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }/hide-offer-locations`);
  }
  
  public printOffer(auctionID: number): Observable<ValidationResult<void>> {
    return this.apiHelper.request(`${ this.endpoint }/${ auctionID }/print`);
  }
  
  public getCommentTemplates(offerId: number): Observable<CapRelCommentTemplate[]> {
    return this.apiHelper.request(`${ this.endpoint }/${ offerId }/comments`);
  }
}
