<div fxLayoutAlign="center center" fxLayout="row">
  <div fxFlex="none">
    <button mat-icon-button
      (click)="subtractDay()"
      class="mat-icon-button"
      aria-label="Previous Day"
      [disabled]="datepicker.disabled">
      <mat-icon [ngClass]="{'disabled': datepicker.disabled}">navigate_before</mat-icon>
    </button>
  </div>

  <div fxFlex="auto">
    <ng-content></ng-content>
  </div>

  <div fxFlex="none">
    <button mat-icon-button
      (click)="addDay()"
      class="mat-icon-button"
      aria-label="Next Day"
      [disabled]="datepicker.disabled">
      <mat-icon [ngClass]="{'disabled': datepicker.disabled}">navigate_next</mat-icon>
    </button>
  </div>
</div>
