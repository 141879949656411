import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment-timezone';
import { timer ,  Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'idle-session',
  templateUrl: 'idle-session.component.html',
})
export class IdleSessionComponent implements OnDestroy {
  private diff: number;
  public seconds: number;
  public minutes: number;
  public minutesStr: string;
  public secondsStr: string;
  private readonly countdownValue: number;
  private readonly tickPeriod: number;
  private unsub: Subject<void> = new Subject<void>();

  constructor(public dialogRef: MatDialogRef<IdleSessionComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.countdownValue = data.timeToTimeout;
    this.tickPeriod = data.tickPeriod;
    this.diff = -(moment().diff(moment().add(this.countdownValue, 'minutes'))/this.tickPeriod);
    timer(1, this.tickPeriod)
      .pipe(takeUntil(this.unsub), take(this.countdownValue * 60), tap((x) => this.setTime(this.diff - x)))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }

  onNoClick(): void {
    this.dialogRef.close(true);
  }

  logout(): void {
    console.log('logging out');
    this.dialogRef.close(false);
  }


  private setTime(time: number): void {
    this.minutes = Math.floor(time / 60) % 60;
    let t = time - this.minutes * 60;
    this.seconds = Math.floor(t % 60);
    if(this.seconds<=9) {
      this.secondsStr = '0'+ this.seconds;
    } else { this.secondsStr = this.seconds.toString(); }
    if(this.minutes <= 9) {
      this.minutesStr = '0'+ this.minutes;
    } else { this.minutesStr = this.minutes.toString(); }
  }
}
