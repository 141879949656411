import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, Input } from '@angular/core';

import { UIControlValueAccessor } from '../control-value-accessor';
@Component({
  selector: 'ui-switch',
  templateUrl: './switch.component.html',
  providers: [ { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UiSwitchComponent), multi: true } ],
})
export class UiSwitchComponent extends UIControlValueAccessor {
  @Input() public required: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public checked: boolean = false;
  @Input() public color: string = 'primary';
  @Input() public label: string;
}
