import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  templateUrl: './new-version-snackbar.component.html',
  styleUrls: [ '../alerts-snackbar/alerts-snackbar.component.less', './new-version-snackbar.component.less' ],
})
export class NewVersionSnackbarComponent {

  public sbRef: MatSnackBarRef<NewVersionSnackbarComponent>; // set from NewVersionSnackbarComponent after NewVersionSnackbarComponent constructed

  public dismiss(): void {
    this.sbRef.dismiss();
  }
}
