import { NgModule } from '@angular/core';
import { SharedModule } from 'cad/shared/shared.module';
import { RunAsUserDialogComponent } from 'src/cad/login/dialog/run-as-user.component';
import { RunAsUserContentComponent } from 'src/cad/login/views/content/switch-user-content.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule.forChild([ { path: '', component: RunAsUserContentComponent } ]),
  ],
  declarations: [
    RunAsUserDialogComponent,
    RunAsUserContentComponent
  ],
  entryComponents: [ RunAsUserDialogComponent ]
})
export class RunAsUserModule {}
