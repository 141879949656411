
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { PhysicalMeterLocationApi } from 'cad/common/services/api/physical/meter/meter-locations/meter-locations';
import { ItemApi } from 'src/features/common/item/item-api';

@Injectable()
export class PhysicalMeterApi extends ItemApi {
  private readonly endpoint: string = 'physical/meters/';

  constructor(
    public meterLocation: PhysicalMeterLocationApi,
    private apiHelper: ApiHelper,
  ) {
    super();
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + 'filter', { body: params });
  }

  public find(params: any): Observable<any> {
    return this.getById(params.meterCd);
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(this.endpoint + 'dashboard');
  }

  /* TODO: self.getById should be renamed to self.find */
  public getById = (id: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + id).pipe(map((meter) => { meter.meterCd = meter.code; return meter; }));
  }

  public save(itemData: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + itemData.meterCd , { body: itemData });
  }

  public saveMeter = (itemData: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + itemData.meterCd + '/saveMeter', { body: itemData });
  }

  public deleteItem(item: any): Observable<any> {
    return observableOf({msg:'*** DELTE NOT IMPLEMENTED HERE ***'});
  }
}
