
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ContractContactData } from 'src/cad/contracts/shared/interfaces/contract-contact-data';

@Injectable()
export class ContractAssignmentInternalContactsApi {
  
  private readonly endpoint: string = 'agreement/contract';
  
  constructor(
    private apiHelper: ApiHelper
  ) {}
  
  public filter(params: ContractContactData): Observable<ContractContactData[]> {
    return this.apiHelper.request(`${ this.endpoint }/${ params.contractId }/contact/internal/filter/assignment`, { body: params }).pipe(
    map((contractContacts: ContractContactData[]) => {
      return contractContacts.map((contractContact: ContractContactData) => {
        contractContact.contactName = `${contractContact.firstName} ${contractContact.lastName}`;
        return contractContact;
      });
    }));
  }
}
