
import {of as observableOf,  Observable } from 'rxjs';

import {delay} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ScheduleAndViewReportApi } from 'cad/common/services/api/workflow/schedule-and-view-report/schedule-and-view-report';
import { ScheduleAndViewWorkflowApi } from 'cad/common/services/api/workflow/schedule-and-view-workflow/schedule-and-view-workflow';
import { WorkflowBaseApi } from 'cad/common/services/api/workflow/workflow-base/workflow-base';
import { WorkflowConfigApi } from 'cad/common/services/api/workflow/workflow-config/workflow-config';
import { WORKFLOW_CONSTANTS } from 'cad/common/variables/workflow-constants';

@Injectable()
export class WorkflowApi {

  constructor(
    public scheduleAndViewReport: ScheduleAndViewReportApi,
    public scheduleAndViewWorkflow: ScheduleAndViewWorkflowApi,
    public workflowBase: WorkflowBaseApi,
    public workflowConfig: WorkflowConfigApi,
  ) {}

  public status = (params: any): Observable<{ status: string }[]> => {
    return observableOf(_.map(_.values(WORKFLOW_CONSTANTS), (value: any) => {
      return (_.zipObject([ 'status' ], [ value ]) as { status: string });
    })).pipe(delay(0));
  }
}
