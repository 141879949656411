<div class="select-editor">
  <ui-lookup #uiLookupRef
             placeholder=""
             [ngModel]="params?.value"
             (ngModelChange)="onModelChange($event)"
             [lookupType]="customCellConfig?.lookupType"
             [tableLookup]="customCellConfig?.tableLookup"
             [lookupItem]="params?.node?.data"
             [useAutocomplete]="customCellConfig?.useAutocomplete"
             [autocompleteModel]="customCellConfig?.autocompleteModel"
             [autocompleteOptionItems]="customCellConfig?.autocompleteOptionItems"
             [defaultSearchParams]="customCellConfig?.defaultSearchParams"
             [constSearchParams]="customCellConfig?.constSearchParams"
             [modalController]="customCellConfig?.modalController"
             [modalComponent]="customCellConfig?.modalComponent"
             [modalWidth]="customCellConfig?.modalWidth"
             [modalHeight]="customCellConfig?.modalHeight"
             [itemData]="customCellConfig?.itemData"
             [inputLocked]="customCellConfig?.inputLocked">
  </ui-lookup>
</div>
