import { Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { AssociationController } from 'src/features/common/association/association.controller';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AssociationApi } from 'src/features/common/association/association-api';

export class AssociationApiService implements AssociationApi {

  static createAssociationApi(
    apiHelper: ApiHelper,
    associationController: AssociationController,
    injector: Injector,
  ): AssociationApi {
    return new AssociationApiService(apiHelper, associationController);
  }

  public constructor(
    private apiHelper: ApiHelper,
    private associationController: AssociationController,
  ) {}

  public findAll(itemId: any): Observable<any> {
    let map = {};
    map[this.associationController.fromController.model.primaryKey] = itemId;

    return this.apiHelper.request(this.apiHelper.url(this.associationController.model.endpoint, map));
  }

  public save(associations: any[]): Observable<any> {
    return this.apiHelper.request(this.apiHelper.url(this.associationController.model.endpoint, associations[0]), { body: associations });
  }
}
