import { Injectable } from '@angular/core';
import { ApiHelper } from '../../../api-helper';
import { CashoutDetails } from 'src/cad/flowing-gas/cashout/cashout-detail/interfaces/cashout-details';
import { Observable } from 'rxjs';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import { CashoutTier } from 'src/cad/flowing-gas/cashout/cashout-detail/interfaces/cashout-tier';
import { ImbalanceTrade } from 'src/cad/flowing-gas/cashout/cashout-detail/interfaces/imbalance-trade';
import { CashoutNetting } from 'src/cad/flowing-gas/cashout/cashout-detail/interfaces/cashout-netting';

@Injectable()
export class CashoutDetailsApi {
  private readonly endpoint: string = 'flowing-gas/cashout/detail';

  constructor(
    private apiHelper: ApiHelper,
    private userModelService: UserModelService) {
  }

  public filter(acctPeriod: string, assetNbr: number, flowPeriod: string): Observable<CashoutDetails[]> {
    const reqObj = {
      acctPeriod, assetNbr: this.userModelService.getCurrentContext().assetNbr, flowPeriod
    };
    return this.apiHelper.request(this.endpoint + '/filter', { body: reqObj });
  }

  public contractCashoutData(acctPeriod: string, assetNbr: number, oia: string, baId: number, flowPeriod: string): Observable<CashoutDetails[]> {
    const reqObj= {
      acctPeriod, assetNbr, oia, baId, flowPeriod
    };
    return this.apiHelper.request(this.endpoint + '/contract-cashout', { body: reqObj });
  }

  public cashoutTierData(flowPeriod: string, assetNbr: number, typeCd: string, contractId: number, acctPeriod: string, oia: string, baId: number): Observable<CashoutTier[]> {
    const reqObj= {
      flowPeriod, assetNbr, typeCd, contractId, acctPeriod, oia, baId
    };
    return this.apiHelper.request(this.endpoint + '/cashout-tier', { body: reqObj });
  }

  public imbalanceTradeData(acctPeriod: string, assetNbr: number, oia: string, baId: number, flowPeriod: string): Observable<ImbalanceTrade[]> {
    const reqObj= {
      acctPeriod, assetNbr, oia, baId, flowPeriod
    };
    return this.apiHelper.request(this.endpoint + '/imbal-trade', { body: reqObj });
  }

  public cashoutNettingData(acctPeriod: string,flowPeriod: string, oia: string, contractId: number): Observable<CashoutNetting[]> {
    const reqObj= {
      acctPeriod, flowPeriod, oia, contractId
    };
    return this.apiHelper.request(this.endpoint + '/netting', { body: reqObj });
  }
}
