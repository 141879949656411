<div class="ui-autocomplete-menu">
  <mat-form-field>
    <input matInput
           name="uiAutocompleteMenu"
           updateModelOn="blur"
           placeholder="{{buttonLabel}}"
           (focusin)="onPanelOpenChange(true)"
           (blur)="onPanelOpenChange(false)"
           [(ngModel)]="searchText"
           #ngModel="ngModel"
           [matAutocomplete]="uiAutocompleteMenu"
           [required]="requiredValue"
           (keyup)="valueChange($event)">
  </mat-form-field>
  <mat-autocomplete #uiAutocompleteMenu="matAutocomplete">
    <ui-virtual-repeat [vrActivated]="true"
                       [vrItems]="filteredItems | async"
                       [vrChildHeight]="AUTOCOMPLETE_OPTION_HEIGHT"
                       [style.maxHeight.px]="AUTOCOMPLETE_PANEL_HEIGHT"
                       [vrItemsToBuffer]="ITEMS_TO_BUFFER"
                       (vrVirtualItemsChange)="virtualItems = $event">
      <mat-option *ngFor="let item of virtualItems"
                 (onSelectionChange)="onSelectionChanged($event)"
                 [value]="item">
        <span [innerHTML]="item | highlightText : toHighlightText : 'gi' : searchByWord"></span>
        <span></span>
      </mat-option>
    </ui-virtual-repeat>
  </mat-autocomplete>
</div>
