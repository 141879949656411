import { Component, Injector, OnInit, InjectionToken } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';

import { ReplaySubject } from 'rxjs';

import * as _ from 'lodash';

export let TABLE_RENDERER_DATA: InjectionToken<string> = new InjectionToken<string>('TableColumnParams');

@Component({
  moduleId: module.id + '',
  selector: 'table-column-custom-renderer',
  templateUrl: './custom-renderer.component.html',
})
export class TableColumnCustomRendererComponent implements AgRendererComponent, OnInit {

  public params: any;
  public injector: Injector;
  public paramsChanged: ReplaySubject<any> = new ReplaySubject<any>();

  constructor(private parentInjector: Injector) {}

  ngOnInit(): void {
    this.injector = Injector.create([
      {
        provide: TABLE_RENDERER_DATA,
        useFactory: () => {
          this.params.paramsChanged$ = this.paramsChanged;
          return this.params;
        },
        deps: [],
      },
    ], this.parentInjector);
  }

  agInit(params: any): void {
    this.params = params;
    this.paramsChanged.next(params.value);
  }

  refresh(params: any): boolean {
    _.assign(this.params, params);
    this.paramsChanged.next(params.value);
    return true;
  }
}
