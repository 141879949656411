import { MatDialogRef } from '@angular/material/dialog';
import { AssociationController } from '../../association.controller';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FullscreenDialogComponent } from '../fullscreen-dialog/fullscreen-dialog.component';
/**
 * Created by jodell on 3/31/2017.
 */
@Component({
  selector: 'ui-header-association-dialog',
  templateUrl: './fullscreen-dialog-header.component.html',
  styleUrls: [ './fullscreen-dialog-header.component.less' ],
})
export class FullscreenDialogHeaderComponent {
  @Input() selectedContentIndex: number;
  @Input() associationController: AssociationController;
  @Output() nextTab: EventEmitter<boolean> = new EventEmitter<boolean>();

  public constructor(
    public dialogRef: MatDialogRef<FullscreenDialogHeaderComponent>
  ) {}

  previousTab(): void {
    this.nextTab.emit(false);
  }

  onDialogClose(): void {
    this.associationController.cleanupAssociationModal();
    this.dialogRef.close();
  }
}
