import { ICellRendererComp } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({ template: '' })
export abstract class TableColumnLabelboxRenderer implements ICellRendererComp {
  public value: any;
  public eGui: HTMLElement;
  public mode: string;

  protected params: any;
  protected modelType: any;
  protected appEl: Element;

  agInit(params: any): void {
    this.params = params;
    this.mode = 'render';
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = this.getTemplate(params.value);
  }

  public getValue(): any {
    return this.model;
  }

  ngOnDestroy(): void {
    if (this.eGui) {
      this.eGui = null;
    }
    this.params = null;
  }

  getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(params: any): boolean {
    if (this.eGui) {
      this.eGui.innerHTML = this.getTemplate(params.value);
    }
    return true;
  }


  afterGuiAttached(): void {
    if (this.mode === 'edit') {
      this.eGui.focus();
    }
  }

  private getTemplate(state: boolean): string {
    if (state) {
      return `
        <span class="ag-selection-labelbox" matTooltip="{{ params?.colDef.cellActiveTooltip }}">
          <mat-icon>label</mat-icon>
        </span>`;
    } else {
      return `
        <span class="ag-selection-labelbox" matTooltip="{{ params?.colDef.cellInactiveTooltip }}">
          <mat-icon>label_outline</mat-icon>
        </span>`;
    }
  }

  private setTemplate(): void {
    this.eGui.innerHTML = this.getTemplate(true);
  }

  get model(): any {
    return this.params.node.data[ this.params.column.colDef.field ];
  }

  set model(val: any) {
    this.params.node.data[ this.params.column.colDef.field ] = val;
  }

  valueChanged(e: any): void {
    this.model = !this.model;

    const gridRow: any = this.params.node;
    this.params.api.rowEdit.setGridRowDirty(this.params.api, gridRow);
    this.params.newValue = this.model;
    this.params.api.eventService.dispatchEvent({
      api: this.params.api,
      type: 'cellValueChanged',
      node: this.params.node,
      column: this.params.column }, this.params);

    if (this.model === false) {
      let activeLabelboxHeader = document.querySelector('.header-icon-active');
      if (activeLabelboxHeader) {
        activeLabelboxHeader.className =
          activeLabelboxHeader.className.replace('header-icon-active', 'header-icon-inactive');
      }
    }

    this.params.api.redrawRows({ node: [ gridRow ] });
    if (this.eGui) {
      this.setTemplate();
    }
  }
}
