<a mat-button [href]="href"
  [ngClass]="{'active': activeHref}">
  <div fxFlex
       fxLayout="row"
       class="button-wrapper">
    <mat-icon [matTooltip]="section.name" [matTooltipPosition]="'right'">{{section.icon}}</mat-icon>
    <div class="section-name" fxFlex>{{section.name}}</div>
    <mat-icon *ngIf="section.pages"
             [attr.aria-hidden]="true"
             class="md-toggle-icon">
      keyboard_arrow_down
    </mat-icon>
  </div>
</a>
