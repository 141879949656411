import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { StateModel } from 'cad/common/models/state/state-model';
import { AuthList, UserDetail } from 'cad/common/models/user/user-details-model';
import { SecurityApi } from 'cad/common/services/api/security/security';
import { AuthService } from 'cad/common/services/auth/auth';
import { UserPreferenceService } from 'cad/common/services/preferences/user-preferences';
import { UserStoreService } from 'cad/common/store/core/services/user-store.service';
import { UserService2 } from 'cad/common/store/user/services/user.service';
import { EmitterService } from 'cad/core/services/emitter/emitter.service';
import { VerticalEnum } from 'cad/dashboard/views/content/components/vertical.enum';
import { SelectDashDialogComponent } from 'cad/dashboard/views/content/select-dash-dialog.component';
import { AutoUnsubscribables, AutoUnsubscriber } from 'cad/shared/mixins/auto-unsubscriber.mixin';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, merge, shareReplay } from 'rxjs/operators';
import { DashboardModel } from 'src/cad/common/models/dashboard/dashboard-model';
import { DashboardConfig } from 'src/cad/common/services/dashboard/dashboard-config.model';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import { RouterService } from 'src/cad/core/services/router/router.service';
import UserPreferences = cad.UserPreferences;

@Component({
  selector: 'dashboard-content',
  templateUrl: './dashboard-content.component.html',
  styleUrls: [ './dashboard-content.component.less' ],
})
export class DashboardContentComponent implements OnDestroy, OnInit {
  @AutoUnsubscriber() public subs: AutoUnsubscribables;
  public VerticalEnum: typeof VerticalEnum = VerticalEnum;
  public userDetails$: Observable<UserDetail>;
  public noLogin: boolean = false;
  public userPrefs$: Observable<UserPreferences>;
  public showEditDash$: Observable<boolean>;
  private editMode: boolean = false;
  private dialogRef: MatDialogRef<SelectDashDialogComponent>;

  constructor(
    private routerService: RouterService,
    private activatedRoute: ActivatedRoute,
    private userModelService: UserModelService,
    private securityApi: SecurityApi,
    private stateModel: StateModel,
    private emitterService: EmitterService,
    private userPreferenceService: UserPreferenceService,
    private matDialog: MatDialog,
    private userService2: UserService2,
    private dashboardModel: DashboardModel,
    private userStoreService: UserStoreService,
    private authService: AuthService
  ) {}

  public ngOnInit(): void {
    this.noLogin =
      this.activatedRoute.snapshot &&
      this.activatedRoute.snapshot.queryParams &&
      (!!this.activatedRoute.snapshot.queryParams.nl || !!this.activatedRoute.snapshot.queryParams.li); // this is set from login matDialog cancel

    this.userPrefs$ = this.userPreferenceService.getPreferences();

    this.userDetails$ =
      of(this.userModelService.getUser()).pipe(merge(
        this.userStoreService.stateChanged.pipe(
          filter((data) => data && data.state && data.state.user),
          shareReplay(),
          map((data) => data.state.user))));

    this.showEditDash$ = this.activatedRoute.queryParams.pipe(map((param) =>
      !!param.edit && this.isLoggedIn() // This is set for the user context menu to 'Edit the dashboard'
    ), shareReplay());

    // Only attempt to login if the user is not currently authenticated
    if (this.noLoggedInUser()) {
      this.subs.newSub = this.securityApi.login.loginUserNTLM().subscribe(
        (next) => {},
        (error) => this.loginFailed(error)
      );
    }
  }

  public ngOnDestroy(): void {
    if (this.dialogRef) { this.dialogRef.close(); }
  }

  public hasAuth(dashboardName: string): Observable<boolean> {
    const dashConfig$: Observable<DashboardConfig> = this.dashboardModel.dashboardItems$
        .pipe(map((items) =>  items.find((item) => item.code === dashboardName)));

    return combineLatest(dashConfig$, this.userDetails$).pipe(map(([ dashConfig, userDetails ]) => {
      if(userDetails && userDetails.currentContext && dashConfig && dashConfig.auth) {
        const authInfo: AuthList = this.authService.getAuthorizationForAction(userDetails.currentContext, dashConfig.auth);
        return authInfo && authInfo.visible && authInfo.enabled;
      } else {
        return false;
      }
    }));
  }

  private isLoggedIn(): boolean {
    return (this.userService2.getCurrentContext() && this.userService2.getCurrentContext().loggedIn);
  }

  private noLoggedInUser(): boolean {
    return (!this.noLogin && (!this.userService2.getCurrentContext() ||!this.userService2.getCurrentContext().loggedIn));
  }

  private loginFailed(error: any): void {
    console.error('Failed NTLM Authentication', error);
    this.routerService.navigate([ '/login' ]);
  }

  public addDashboard(): void {
    this.dialogRef = this.matDialog.open(SelectDashDialogComponent, {data: this.userDetails$.pipe(filter((u) => !!u), map((u) => u.currentContext))});
  }

  public exitEditMode(): void {
    this.editMode = false;
    this.routerService.navigate([ '/dashboard' ]);
  }
}
