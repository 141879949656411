import { AgRendererComponent } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
  moduleId: module.id + '',
  selector: 'row-expand',
  template: `
    <button *ngIf="!(params?.node?.group) && isExpandable()"
            mat-button
            mat-icon-button
            type="button"
            (click)="toggle($event)"
            [matTooltip]="(params?.node?.expanded) ? params?.colDef?.collapseTooltip : params?.colDef?.expandTooltip"
            [matTooltipPosition]="'right'"
            [disabled]="!isExpandable()">
      <mat-icon>{{(params?.node?.expanded) ? params?.colDef?.collapseIcon : params?.colDef?.expandIcon}}</mat-icon>
    </button>
  `,
  styleUrls: [ '../cell-icon-buttons.less' ]
})
export class RowShowExpandComponent implements AgRendererComponent {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  toggle(event: any): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.isExpandable()) {
      this.params.node.setExpanded(!this.params.node.expanded);
    }
  }

  refresh (params: any): boolean {
    this.params = params;
    return true;
  }

  public isExpandable(): boolean {
    return (this.params && this.params.node && this.params.node.isExpandable());
  }
}
