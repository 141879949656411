import { Pipe, PipeTransform } from '@angular/core';

class PhoneParser {
  // TODO:  convert to ES module import, if possible (then remove from allowedCommonJsDependencies in angular.json)
  public static parse: (input: string, format: string) => string = require('phone-parser');
}

@Pipe({ name: 'phoneNumber' })
export class PhoneNumberPipe implements PipeTransform {
  transform(phoneString: string, format: string = '(xxx) xxx-xxxx'): any {
    let re = /(\d{10})(\s){0,1}(ext\#){0,1}(\d*){0,20}/;
    let m = re.exec(phoneString);
    if (m !== null) {
      if (m.index === re.lastIndex) {
        re.lastIndex++;
      }
      let phoneNumber = '';
      if (m[ 1 ]) {
        phoneNumber = PhoneParser.parse(m[ 1 ], format);
      }
      if (m[ 4 ]) {
        phoneNumber += ' ext. ' + m[ 4 ];
      }
      return phoneNumber;
    } else {
      return phoneString;
    }
  }
}
