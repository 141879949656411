import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class PortfolioValuationDailyViewApi {

  public endpoint: string = 'portfolio/valuation/daily/';

  constructor(
    protected apiHelper: ApiHelper,
  ) {}

  // ========================================
  // implementation
  // ========================================

  public findAll(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint, { body: params });
  }

  public findTypeDetail(row: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + 'type-detail', { body: row });
  }

  public findTypeDetailAll(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + 'type-detail/all', { body: params });
  }

  public findDetail(row: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + 'detail', { body: row });
  }

  public findType(row: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + 'type', { body: row });
  }

  public findTypeAll(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + 'type/all', { body: params });
  }

  public findDetailAll(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + 'detail/all', { body: params });
  }

  public getDailySummaryAdjustment(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + 'adjustment', { body: params });
  }

  public getDailyDetailAdjustment(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + 'detail/adjustment', { body: params });
  }
}
