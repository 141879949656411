<ui-fullscreen-footer fxLayout="row">
  <div [fxFlex]="selectedTabIdx == 0?'25':'70'"
       [fxLayoutAlign]="selectedTabIdx == 0?'center center':'flex-end center'">
    <div *ngIf="selectedTabIdx == 0 && selectedContentIndex == 0">
      <button id="search-clear"
              mat-button
              mat-raised-button
              (click)="clear()">
        CLEAR
      </button>
      <button id="search-search"
              mat-button
              mat-raised-button
              color="primary">
        SEARCH
      </button>
    </div>
    <div *ngIf="selectedTabIdx == 1 && selectedContentIndex == 0">
      <button id="search-cancel"
              mat-button
              mat-raised-button
              type="button"
              (click)="cancel()">
        CANCEL
      </button>
      <button id="search-submit"
              mat-button
              mat-raised-button
              color="primary"
              type="button"
              [disabled]="disableSubmit()"
              (click)="submitNewItem()">
        SUBMIT
      </button>
    </div>
  </div>
  <div [fxFlex]="selectedTabIdx == 0?'75':'30'"
       fxLayout="row"
       class="wrapper">
    <div class="selected-items-container"
         fxLayout="column"
         fxFlex>
      <div><b>{{associationController.selectedItems.length}} Selected
        {{associationController.model.toModel.name}}(s)</b></div>
      <div class="selected-items-content"
           *ngIf="associationController.model.modalMultiSelectType == 'chips' && associationController.view.modalMultiSelectChip">
        <mat-chip-list>
          <mat-chip *ngFor="let item of associationController.selectedItems; let i=index">
            <div class="chipWrapper">
              <ng-container
                *ngComponentOutlet="associationController.view.modalMultiSelectChip; injector: associationController.chipInjectors[i]">
              </ng-container>
              <mat-icon (click)="removeSelectedItem(item)">close</mat-icon>
            </div>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="selected-items-content"
           *ngIf="!associationController.view.modalMultiSelectChip">
        <mat-chip-list>
          <mat-chip *ngFor="let item of associationController.selectedItems; let i=index">
            <div class="dialog-component">
              <ng-container [framingComponentOutlet]="associationController.view.modalSelectChipComponent"
                            [framingComponentInputs]="{ associationController: associationController }"
                            [framingComponentInjector]="associationController.chipInjectors[i]"
                            (onComponent)="onChipReady($event, i)">
              </ng-container>
            </div>
            <button mat-button
                    class="dialog-chip-remove"
                    (click)="removeSelectedItem(item)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>
        <!--  <mat-chip-list>
           <mat-chips maxChips="1" [(ngModel)]="selectedItems">
             <mat-chip-template [uiRender]="info.config.modalChipTemplate"></mat-chip-template>

             <button mat-chip-remove type="button" class="mat-chip-remove" (click)="onRemoveChip($chip)">
               <mat-icon>close</mat-icon>
             </button>
           </mat-chips>
         </mat-chip-list> -->
      </div>
    </div>
    <div class="footer-nav"
         fxLayout="column"
         fxLayoutAlign="center center">
      <button id="search-next"
              mat-button
              mat-raised-button
              color="primary"
              type="button"
              *ngIf="selectedContentIndex == 0"
              [disabled]="!associationController.selectedItems || associationController.selectedItems.length < 1 || selectedTabIdx == 1"
              (click)="nextPage()">
        NEXT
      </button>
      <button id="search-submit2"
              mat-button
              mat-raised-button
              color="primary"
              type="submit"
              *ngIf="selectedContentIndex == 1"
              [disabled]="!associationController.selectedItems || associationController.selectedItems.length < 1"
              (click)="submit($event)">
        SUBMIT
      </button>
    </div>
  </div>
</ui-fullscreen-footer>
