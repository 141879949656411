<ui-card headline="Personal Information" [uiDisable]="disabled">
  <div fxFlex fxLayoutGap="18px">
    <div fxLayout="column" fxFlex="33">
      <mat-form-field>
        <input matInput [(ngModel)]="item.lastName" placeholder="Last Name" name="lastName" disabled>
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="item.firstName" placeholder="First Name" name="firstName" disabled>
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="item.middleInit" placeholder="Middle Initial" name="middleInit" disabled>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxFlex="33">
      <mat-form-field>
        <input matInput [(ngModel)]="item.nickname" placeholder="Nickname" name="nickname" disabled>
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="item.namePrefix" placeholder="Prefix" name="namePrefix" disabled>
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="item.nameSuffix" placeholder="Suffix" name="nameSuffix" disabled>
      </mat-form-field>

    </div>

    <div fxLayout="column" fxFlex="33">
      <mat-form-field>
        <input matInput [(ngModel)]="item.loginName" placeholder="Login ID" name="loginName" disabled>
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="item.contactId" placeholder="Contact ID" name="contactId" disabled>
      </mat-form-field>
    </div>
  </div>
</ui-card>


<contact-information></contact-information>


<email-notification></email-notification>

