import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ComponentPointData } from 'src/cad/structured-products/shared/interfaces/component-point-data';

@Injectable()
export class ComponentPointLookupApi {
  
  private readonly endpoint: string = 'product/matrix';
  
  constructor(
    private apiHelper: ApiHelper
  ) {}
  
  public filter(filterData: ComponentPointData): Observable<ComponentPointData[]> {
    return this.apiHelper.request(`${this.endpoint}/component-point-lookup`, { body: filterData })
    .pipe(
      map((componentPointDataList: ComponentPointData[]) => [ { code: '*', name: '*' } as ComponentPointData ].concat(componentPointDataList || []))
    );
  }
}
