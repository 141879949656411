<mat-card uiExpandable
          #uiExpandableRef="uiExpandable"
          [enableMax]="enableMax"
          [(isFullHeight)]="isFullHeight"
          [enableExpand]="enableExpand"
          [(isExpanded)]="isExpanded"
          [uiDisable]="disabled"
          [class.ui-card-expanded]="isExpanded"
          [class.ui-card-full-height]="isFullHeight"
          [context]="context">
  <mat-card-header>
    <mat-card-title fxFlex>
      <span class="mat-title">{{headline}}</span>
    </mat-card-title>
    <mat-card-subtitle *ngIf="subhead">
      <span class="mat-subhead">{{subhead}}</span>
    </mat-card-subtitle>
    <div *ngIf="isExpanded"
         class="card-toolbar"
         [ngStyle]="{ 'padding-right.px': cardToolbarPadding }"
         fxLayoutAlign="end center">
      <div *ngFor="let item of cardToolBarItems"
           class="tooltip"
           matTooltip="{{item.name}}">
        <button *ngIf=getStatus(item)
                mat-button
                class="toolbar-items"
                type="button"
                disableRipple="true"
                [disabled]="item.isDisabled()"
                [attr.aria-label]="item.action"
                (click)="executeAction($event, item.method)">
          <mat-icon>{{item.icon}}</mat-icon>
        </button>
      </div>
    </div>
    <div class="header-events"
         #headerSelector>
    </div>
    <expandable-fullheight-button *ngIf="enableMax">
    </expandable-fullheight-button>
    <button *ngIf="allowFullscreen"
            mat-button
            class="toolbar-items"
            type="button"
            disableRipple="true"
            (click)="makeFullscreen($event)">
      <mat-icon>{{ isFullscreen ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content #contentSelector>
    <ng-content></ng-content>
  </mat-card-content>
  <div *ngIf="showFoooter">
    <mat-card-footer>
      <ng-content select=".ui-card-footer"></ng-content>
    </mat-card-footer>
  </div>
</mat-card>
