import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ApiHelper} from 'cad/common/services/api/api-helper';
import {Workflow} from 'cad/administration/table-maintenance/workflow-associations/shared/interfaces/workflow';
import {
  WorkflowSecurityRole
} from 'cad/administration/table-maintenance/workflow-associations/shared/interfaces/workflow-security-role';
import {
  WorkflowAssetAssociation
} from 'cad/administration/table-maintenance/workflow-associations/shared/interfaces/workflow-asset-association';
import {ContractLock} from 'cad/contracts/shared/interfaces/contract-lock';
import ValidationResult = cad.ValidationResult;

@Injectable()
export class WorkflowAssetAssociationApi {

  private readonly endpoint: string = 'administration/table-maintenance/workflow';

  constructor(
    private apiHelper: ApiHelper) {
  }

  public getAll(): Observable<Workflow[]> {
    return this.apiHelper.request(this.endpoint +'/dashboard');
  }

  public getSecurityRoleData(id: number): Observable<WorkflowSecurityRole[]> {
    return this.apiHelper.request(this.endpoint + '/security-roles/'+ id);
  }

  public getAssetAssociationData(id: number): Observable<WorkflowAssetAssociation[]> {
    return this.apiHelper.request(this.endpoint + '/asset-associations/'+id);
  }

  public saveWorkflowAssetAssociation(params: WorkflowAssetAssociation[]): Observable<ValidationResult<WorkflowAssetAssociation[]>> {
    return this.apiHelper.request(this.endpoint + '/asset-associations', {body: params});
  }

  public saveWorkflowSecurityRole(params: WorkflowSecurityRole[]): Observable<ValidationResult<WorkflowSecurityRole[]>> {
    return this.apiHelper.request(this.endpoint + '/security-roles', {body: params});
  }

}
