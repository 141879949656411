import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class AssociateContactProfileApi {

  private readonly endpoint: string = 'associate/contact/';
  private readonly suffix: string = '/profiles';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public findAll = (contactId: string | number): Observable<any> => {
    return this.apiHelper.request(this.endpoint + contactId + this.suffix);
  }

  public save = (profiles: any[]): Observable<any> => {
    return this.apiHelper.request(this.endpoint + profiles[0].contactId + this.suffix, { body: profiles });
  }
}
