import { NgModule } from '@angular/core';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { InternalServerErrorComponent } from './internal-server-error/internal-server-error.component';

@NgModule({
  exports: [ 
    ForbiddenComponent, 
    NotFoundComponent, 
    InternalServerErrorComponent
  ],
  declarations: [ 
    ForbiddenComponent, 
    NotFoundComponent, 
    InternalServerErrorComponent
  ],
})
export class StatusModule {}
