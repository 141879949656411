
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ItemApi } from 'src/features/common/item/item-api';

@Injectable()
export class AgreementAssociateToNominationsApi extends ItemApi {

  private readonly endpoint: string = 'agreement/transaction/associate-to-nominations';

  constructor(
    public apiHelper: ApiHelper
  ) {
    super();
  }

  public find(params: any): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }`, { body: params });
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/filter`, { body: params });
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/dashboard`);
  }

  public save(data: any): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/save`, { body: data });
  }

  public deleteItem(id: number): Observable<any> {
    // Delete does not apply to Associate To Nominations
    return observableOf({});
  }

  public validateTransactionValidForNomDay(nomTransactionDataList: any[]): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/validate-for-nom-day`, { body: nomTransactionDataList });
  }

  public validateTransactionLookup(validationData: any): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/validate-transaction-lookup`, { body: validationData });
  }
}
