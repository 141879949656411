export const environment = {
  isReleaseBuild: true,
  envName:'Production',
  isDev:false,
  enableDebug: false,
  consoleDebugging: false,
  hideMenus:  [],
  useCache:false,
  useProxy:true,
  baseApiUrl:'/Services/Messenger/',
  host:'https://webservices.energytransfer.com',
  port:':443',
  secondaryUrl:'https://webservices.energytransfer.com',
  infoPostBaseUrl: 'http://peplmessenger.energytransfer.com/ipost',
  extSecurityUrl: 'https://security.energytransfer.net/'
};
