import { Injectable } from '@angular/core';
import { EasingPattern, EASING_PATTERN, SmoothScrollOptions } from 'src/common/types/easing-pattern.constants';

@Injectable()
export class UiSmoothScrollService {

  /**
   * @description Smooth scrolls to the provided scrollTop value
   * @param scrollContainer
   * @param toPosition
   * @param options
   */
  public scrollTo(scrollContainer: HTMLElement, toPosition: number, options: SmoothScrollOptions = {}): void {
    // if no scrollContainer then do not do anything
    if (!_.isElement(scrollContainer)) { return; }
    // set default values for the options
    this.assignOptionDefaults(options);
    // Initialize the whole thing
    setTimeout(() => {
      let elapsed: number = 0;
      let percentage: number;
      let position: number;
      let scrollHeight: number;
      let internalHeight: number;
      let start: number = scrollContainer.scrollTop;
      let end: number = (toPosition - options.offset);

      options.onBeforeScroll(scrollContainer);
      let intervalId: any = setInterval(() => {
        elapsed += 16;
        percentage = (elapsed / options.duration);
        percentage = (percentage > 1) ? 1 : percentage;
        position = start + ((end - start) * this.getEasingPattern(options.easing, percentage));
        scrollContainer.scrollTop = position;
        scrollHeight = scrollContainer.scrollHeight;
        internalHeight = scrollContainer.clientHeight + scrollContainer.scrollTop;
        if (position === end
          || scrollContainer.scrollTop === end
          || internalHeight > scrollHeight) {
          clearInterval(intervalId);
          options.onAfterScroll(scrollContainer);
        }
      }, 16);
    }, 0);
  }

  /**
   * @description Smooth scrolls to a particular element's top
   * @param scrollContainer
   * @param element
   * @param options
   */
  public scrollToElement(scrollContainer: HTMLElement, element: HTMLElement, options: SmoothScrollOptions): void {
    if (_.isElement(element)) {
      this.scrollTo(scrollContainer, element.offsetTop, options);
    }
  }

  /**
   * @description Assigns default values to the smooth scroll options
   * @param options
   */
  private assignOptionDefaults(options: SmoothScrollOptions): void {
    if (options) {
      options.duration = options.duration || 800;
      options.offset = options.offset || 0;
      options.easing = options.easing || EASING_PATTERN.EASE_IN_OUT_QUART as EasingPattern;
      if (!_.isFunction(options.onBeforeScroll)) {
        options.onBeforeScroll = (el: any) => {};
      }
      if (!_.isFunction(options.onAfterScroll)) {
        options.onAfterScroll = (el: any) => {};
      }
    }
  }

  /**
   * Calculate easing pattern.
   * @see http://archive.oreilly.com/pub/a/server-administration/excerpts/even-faster-websites/writing-efficient-javascript.html
   */
  private getEasingPattern(type: EasingPattern, time: number): number {
    switch (type) {
      case 'easeInQuad': {
        // accelerating from zero velocity
        return time * time;
      }
      case 'easeOutQuad': {
        // decelerating to zero velocity
        return time * (2 - time);
      }
      case 'easeInOutQuad': {
        // acceleration until halfway, then deceleration
        return time < 0.5 ? 2 * time * time : -1 + (4 - 2 * time) * time;
      }
      case 'easeInCubic': {
        // accelerating from zero velocity
        return time * time * time;
      }
      case 'easeOutCubic': {
        // decelerating to zero velocity
        let copyOfTime: number = time;
        return (--copyOfTime) * copyOfTime * copyOfTime + 1;
      }
      case 'easeInOutCubic': {
        // acceleration until halfway, then deceleration
        return time < 0.5 ? 4 * time * time * time : (time - 1) * (2 * time - 2) * (2 * time - 2) + 1;
      }
      case 'easeInQuart': {
        // accelerating from zero velocity
        return time * time * time * time;
      }
      case 'easeOutQuart': {
        // decelerating to zero velocity
        let copyOfTime: number = time;
        return 1 - (--copyOfTime) * copyOfTime * copyOfTime * copyOfTime;
      }
      case 'easeInOutQuart': {
        // acceleration until halfway, then deceleration
        let copyOfTime: number = time;
        return copyOfTime < 0.5
          ? (8 * copyOfTime * copyOfTime * copyOfTime * copyOfTime)
          : (1 - 8 * (--copyOfTime) * copyOfTime * copyOfTime * copyOfTime);
      }
      case 'easeInQuint': {
        // accelerating from zero velocity
        return time * time * time * time * time;
      }
      case 'easeOutQuint': {
        // decelerating to zero velocity
        let copyOfTime: number = time;
        return 1 + (--copyOfTime) * copyOfTime * copyOfTime * copyOfTime * copyOfTime;
      }
      case 'easeInOutQuint': {
        // acceleration until halfway, then deceleration
        let copyOfTime: number = time;
        return copyOfTime < 0.5
          ? (16 * copyOfTime * copyOfTime * copyOfTime * copyOfTime * copyOfTime)
          : (1 + 16 * (--copyOfTime) * copyOfTime * copyOfTime * copyOfTime * copyOfTime);
      }
      default: {
        return time;
      }
    }
  }
}
