
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiCache } from 'cad/common/services/api/api-cache';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import * as _ from 'lodash';

@Injectable()
export class ContractSummarySeasonalApi {

  public readonly seasonalEndpoint: string = 'agreement/contract-summary';

  constructor(
    public apiHelper: ApiHelper,
    public apiCache: ApiCache
  ) {}
  
  public getById(id: number | string): Observable<any> {
    if (_.isNil(id)) {
      return observableOf({});
    }
    return this.apiHelper.request(`${ this.seasonalEndpoint }/${ id }`);
  }
  
  public getSummaryQuantityList(params: any): Observable<any> {
    if (params && !_.isNil(params.contractId)) {
      return this.apiHelper.request(
        `${ this.seasonalEndpoint }/${ params.contractId }/contract-summary-quantity`,
        { body: params }
      );
    }
    return observableOf([]);
  }

  public getSummaryPointList(params: any): Observable<any> {
    if (params && !_.isNil(params.contractId)) {
      return this.apiHelper.request(
        `${ this.seasonalEndpoint }/${ params.contractId }/contract-summary-point`,
        { body: params }
      );
    }
    return observableOf([]);
  }
  
  public getSummaryOffsetPointList(params: any): Observable<any> {
    if (params && !_.isNil(params.contractId)) {
      return this.apiHelper.request(
        `${ this.seasonalEndpoint }/${ params.contractId }/contract-summary-offset-point`,
        { body: params }
      );
    }
    return observableOf([]);
  }
}
