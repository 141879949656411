import { Component, ViewChild, OnInit, Inject, Optional, AfterViewInit } from '@angular/core';
import { isFunction } from 'lodash';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgComponentOutlet } from '@angular/common';

@Component({
  selector: 'table-lookup-dialog',
  templateUrl: './lookup-dialog.component.tpl.html',
  styleUrls: [ './lookup-dialog.component.less' ],
})
export class TableColumnLookupDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(NgComponentOutlet) public ngComponentOutlet: NgComponentOutlet;
  private componentRef: any;
  title: string;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
              public dialogRef: MatDialogRef<TableColumnLookupDialogComponent>) {}

  ngOnInit(): void {
    this.title = this.dialogData.colDef.lookupModalTitle;
  }

  ngAfterViewInit(): void {
    // TODO: Fix this hack in Angular13: https://stackoverflow.com/questions/77283090/how-do-i-access-the-component-instance-from-code
    this.componentRef = (this.ngComponentOutlet as any)._componentRef.instance;
  }

  public isSubmitDisabled(): boolean {
    return (this.componentRef && isFunction(this.componentRef.isSubmitDisabled))
      ? this.componentRef.isSubmitDisabled()
      : false;
  }

  ok(): void {
    if (!_.isFunction(this.componentRef.getLookupResult)) {
      console.error('Lookup component does not implement getLookupResult', this.componentRef);
      this.dialogRef.close();
    } else {
      this.dialogRef.close(this.componentRef.getLookupResult());
    }
  }
}
