
import {of as observableOf,  Subscription ,  Observable } from 'rxjs';
import {
  Input, Output, Directive, OnInit, OnDestroy, DoCheck, EventEmitter, KeyValueDiffers,
  KeyValueChanges, KeyValueChangeRecord, KeyValueDiffer
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { SearchService, SearchResult } from 'common/services/search/search-service';
import { ParamKey } from 'src/common/components/query/param-key';
import * as _ from 'lodash';

@Directive({
  selector: 'ui-query',
})
export class UiQueryDirective implements OnInit, DoCheck, OnDestroy {
  
  @Input() public api: any;
  @Input() public results: any[];
  @Input() public constantParams: any;
  @Input() public allowEmptyParams: boolean;
  @Input() public autoFireSearch: boolean;
  @Input() public context: any;
  @Input() public cache: boolean;
  @Input() public debounce: boolean = false;
  @Input() public debounceTime: number = 1000;
  @Input() public items: any[] = [];
  @Input() public params: any;
  @Input() public paramKeys: ParamKey[] = [];
  @Input() public formCtrl: NgForm; // bound by require
  @Output() public loading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public paramKeysChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public itemsChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public paramsChange: EventEmitter<any> = new EventEmitter<any>();
  private queryTimeout: any;
  private queryTimeoutWait: number = 200;
  private debounceTimeout: any;
  private filters: any[] = [];
  private querySubscription: Subscription;
  private paramsDiffer: KeyValueDiffer<string, any>;
  public cacheLoaded: boolean = false;

  constructor(
    private searchService: SearchService,
    private differs: KeyValueDiffers,
  ) {
    this.paramsDiffer = this.differs.find({}).create();
  }
  
  public ngOnInit(): void {
    _.defaults(this, { // bindings defaults
      allowEmptyParams: true,
      cache: true,
      constantParams: {},
      params: {},
    });
    this.setParamKeys();
    if (this.cache) {
      this.retrieveCache().then((results: any[]) => {
        this.processSuccess(results);
        this.cacheLoaded = true;
      });
    }
  }

  public ngDoCheck(): void {
    let changes: KeyValueChanges<string, any>;
    
    if (this.autoFireSearch) {
      this.autoFireSearch = false;
      this.performQuery();
      return;
    }
    if (this.paramsDiffer) {
      changes = this.paramsDiffer.diff(this.params);
    }
    if (!_.isNil(changes)) {
      // If cache is enabled then ignore the initial change
      if (this.cache && !this.cacheLoaded) {
        return;
      }
      _.keys(this.params).forEach((key: string) => {
        let param: any = this.params[ key ];
        
        if (_.isNil(param) || param.length === 0 || param === '%' || param === '&&') {
          this.removeParam(key);
        }
      });
      if (this.checkKeyValueChanges(changes)) {
        if (this.debounce) {
          if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
          }
          this.debounceTimeout = setTimeout(() => this.refresh(), this.debounceTime);
        } else {
          this.refresh();
        }
      }
    }
  }
  
  public ngOnDestroy(): void {
    this.cancelQuery();
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    if (_.isArray(this.items)) {
      this.items.splice(0);
    }
    if (_.isArray(this.paramKeys)) {
      this.paramKeys.splice(0);
    }
    if (_.isArray(this.filters)) {
      this.filters.splice(0);
    }
    this.items = null;
    this.paramKeys = null;
    this.params = null;
    this.debounceTimeout = null;
    this.formCtrl = null;
    this.filters = null;
    this.paramsDiffer = null;
  }

  public setParamKeys(): void {
    this.paramKeys = _.keys(this.params).map((key: string) => {
      return { key, val: this.params[ key ] };
    });
    this.paramKeysChange.emit(this.paramKeys);
  }

  public clearParams(): void {
    if (_.isArray(this.paramKeys)) {
      this.paramKeys.splice(0);
    }
    this.paramKeys = [];
    this.paramKeysChange.emit(this.paramKeys);

    // this.params = {};
    // this.paramsChange.emit(this.params);
    
    if (_.isArray(this.items)) {
      this.items.splice(0);
    }
    this.items = [];
    this.itemsChange.emit(this.items);
    if (this.cache) {
      this.cacheResults(this.items);
    }
  }

  public removeParam(key: string): void {
    delete this.params[ key ];
    // this.paramsChange.emit(this.params);
    this.setParamKeys();
  }

  public refresh(): void {
    this.setParamKeys();
    if (!this.api) {
      this.loading.emit(false);
      return;
    }
    if (this.formCtrl && !this.formCtrl.valid) {
      this.loading.emit(false);
      return;
    }
    this.cancelQuery();
    if (this.params || this.allowEmptyParams) {
      if (!this.allowEmptyParams && !_.isEmpty(_.keys(this.params))) {
        this.performQuery();
      } else if (this.allowEmptyParams) {
        this.performQuery();
      }
    }
  }

  public performQuery(): void {
    this.cancelQuery(); // cancel any pending queries
    this.queryTimeout = setTimeout(() => { // wait until next frame in case other params are changing
      this.loading.emit(true);
      this.querySubscription = this.retrieveData().subscribe(this.processSuccess);
    }, this.queryTimeoutWait);
  }

  public cancelQuery(): void {
    if (this.queryTimeout) {
      clearTimeout(this.queryTimeout);
    }
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
    this.queryTimeout = null;
    this.querySubscription = null;
    this.loading.emit(false);
  }

  public addFilter(filter: any): void {
    if (_.isArray(this.filters)) {
      this.filters.push(filter);
    }
  }

  public removeFilter(filter: any): void {
    _.remove(this.filters, filter);
  }

  public retrieveCache(): Promise<any> {
    return new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
      let searchResult: SearchResult = this.searchService.getCachedSearch(this.context);
      if (searchResult && !_.isEmpty(searchResult.getResults())) {
        this.setParams(searchResult);
        return resolve(searchResult.getResults());
      }
      return resolve([]);
    });
  }

  public setParams(searchResult: SearchResult): void {
    if (searchResult) {
      this.params = _.cloneDeep(searchResult.getSearchParams());
    }
  }

  public retrieveData(): Observable<any> {
    return this.api
      ? this.api(_.assign(_.clone(this.params), this.constantParams))
      : observableOf([]);
  }

  public processSuccess = (results: any[]): void => {
    this.filter(results);
    if (this.cache) {
      this.cacheResults(results);
    }
    this.loading.emit(false);
  }

  public cacheResults(results: any): void {
    if (this.searchService) {
      this.searchService.cacheSearch(results, _.clone(this.params), this.context);
    }
  }

  public filter(results?: any): void {
    // remove empty filters
    _.forEach(this.params, (value: any, key: string) => {
      if (_.isNil(value)) {
        delete this.params[ key ];
      }
    });
    if (results) {
      this.items = results;
      this.itemsChange.emit(this.items);
    }
    this.setParamKeys();
    _.each(this.filters, (filter: any) => {
      this.items = _.filter(this.items, filter.test);
      this.itemsChange.emit(this.items);
    });
  }
  
  private checkKeyValueChanges(changes: KeyValueChanges<string, any>): boolean {
    let changed: boolean = false;
    
    changes.forEachAddedItem((record: KeyValueChangeRecord<string, any>) => {
      // record.currentValue is the newly added value and the record.previousValue is 'null'.
      if (this.isAddValueValid(record)) {
        changed = true;
        return;
      }
    });
    changes.forEachRemovedItem((record: KeyValueChangeRecord<string, any>) => {
      // record.currentValue is 'null' and the record.previousValue is the removed value.
      // If a search criteria is removed we want to restart the search with the updated criteria.
      if (this.isRemoveValueValid(record)) {
        changed = true;
        return;
      }
    });
    changes.forEachChangedItem((record: KeyValueChangeRecord<string, any>) => {
      if (record && !_.isEqual(record.currentValue, record.previousValue)) {
        changed = true;
        return;
      }
    });
    return changed;
  }
  
  private isAddValueValid(record: KeyValueChangeRecord<string, any>): boolean {
    return record
      && !_.isNil(record.currentValue)
      && record.currentValue !== ''
      && record.currentValue !== '%'
      && _.isNil(record.previousValue);
  }
  
  private isRemoveValueValid(record: KeyValueChangeRecord<string, any>): boolean {
    return record
      && !_.isNil(record.previousValue)
      && record.previousValue !== ''
      && record.previousValue !== '%'
      && _.isNil(record.currentValue);
  }
}
