import { CustomValidationObject } from './custom-validation-object';
import {
  Directive, ElementRef, AfterViewInit, Renderer2, OnDestroy, QueryList, ContentChildren, ContentChild, ViewChild, Optional, Host
} from '@angular/core';
import { NgModel, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Validatable } from './validatable';

const MESSAGES = {
  required: { message: 'You did not enter a field' },
  minlength: { message: 'Your field is too short' },
  maxlength: { message: 'Your field is too long' },
  pattern: { message: 'Your field is formatted incorrectly' },
  multiselectcheckbox: { message: 'You did not select anything' },
  uniqueName: { message: 'Name is not unique' },
  uniqueAbbr: { message: 'Abbreviation is not unique' },
  min: { message: 'Too small'},
  max: { message: 'Too large'}
};

/*
This Directive adds functionality to the mat-form-field.  Its purpose is to add validation error
messages to the component in the container.  Any of the errors defined in MESSAGES should result in the message
being added near the component to give feedback to the user.

Instead of using ui-form-group wrapping <mat-form-field> with ng-content, we are using this because you cannot do that
 https://github.com/angular/material2/issues/3042

Usage Example:
 <mat-form-field>
    <input md-input [(ngModel)]="itemData.firstName" placeholder="First Name" required maxlength="20"/>
 </mat-form-field>

 Runtime result (excluding material changes):
 <mat-form-field>
    <input md-input [(ngModel)]="itemData.firstName" placeholder="First Name" required maxlength="20"/>  <!-- material will change this line with divs labels and animation -->
    <md-error>
      <span>messages here if errors exist</span>
    </md-error>
 </mat-form-field>

 */

@Directive ({
  selector: 'mat-form-field:not([internalInput]),ui-lookup,ui-datepicker',
})
export class MatFormFieldDirective implements AfterViewInit, OnDestroy {
  @ContentChildren(NgModel) public models: QueryList<NgModel>;

  private inputElement: any;
  private childNodes: Node[] = [];
  private errorNodeParent: Node;
  private model: NgModel;
  private type: string = null;
  private oldErrors: any = null;

  private statusSubscription: Subscription;
  private listener: Function;

  private errorClasses: string[] = [
    'mat-input-invalid'
  ];

  private warningClasses: string[] = [
    'mat-input-warn'
  ];

  /**
   * IE11 has a bug that causes a change event to fire if a placeholder property
   * is present on an input.  Because of this extra change event, inputs are
   * being labeled as dirty when they aren't actually dirty.  This IE11 detection
   * is used as part of a workaround to determine when validation should occur.
   * see the method isReadyForValidation() below.
   */
  private isIE11: boolean = !!(window as any).MSInputMethodContext && !!(document as any).documentMode;

  constructor(el: ElementRef, private renderer: Renderer2, @Optional() @Host() private host: Validatable) {
    if (el.nativeElement) {
      this.inputElement = el.nativeElement;
    }
  }

  ngAfterViewInit(): void {
    // Stick the error element into the mat-form-field.
    let listenEl: any = this.inputElement;
    this.errorNodeParent = this.inputElement.querySelector('.mat-form-field-subscript-wrapper');

    //update the listener element and parent node depending on type of field
    if (this.inputElement.localName === 'mat-form-field') {
      listenEl = this.inputElement.querySelector('input')
        || this.inputElement.querySelector('textarea')
        || this.inputElement.querySelector('.mat-select');
    }

    this.listener = this.renderer.listen(listenEl, 'blur', (): void => {
      this.onChange();
    });

    if (this.models && this.models.first) {
      this.model = this.models.first;
    }

    if (this.model && this.model.control) {
      this.statusSubscription = this.model.control.statusChanges.subscribe(() => this.onChange());
    }
  }

  ngOnDestroy(): void {
    if (this.statusSubscription) { this.statusSubscription.unsubscribe(); }
    if (this.listener) { this.listener(); }
    this.inputElement = undefined;
    this.childNodes = [];
    this.errorNodeParent = undefined;
    this.model = undefined;
  }

  onChange(): void {
    this.clearErrors();
    this.removeWarningClassesOnCustom();
    if (!this.model) { return; }
    if (this.model.dirty && this.model.pending) { // <-- validation pending
      let msg = 'Validating...';
      if (this.host) {
        this.addMessageTextOnCustom(msg);
      } else {
        this.appendMessage(msg);
      }
    } else if (this.isReadyForValidation(this.model)) { // <-- look at the errors and see if we have messages to match
      this.oldErrors = this.model.errors;
      this.showProblems(this.model.errors, this.displayError);
    } else if (this.hasWarnings(this.model)) {
      this.showProblems((this.model.control as any).warnings, this.displayWarning);
    }
  }

  private showProblems(validationObj: { [key: string]: any }, action: Function): void {
    if(validationObj) {
      Object.keys(validationObj).forEach((key: string) => {
        let cannedValidation = MESSAGES[key];
        let customValidation: CustomValidationObject = validationObj[key];
        if (cannedValidation) {
          action.call(this, cannedValidation.message);
        } else if (customValidation && !customValidation.valid) {
          action.call(this, validationObj[key].message);
        }
      });
    }
  }

  /**
   * Workaround for IE11 bug which causes inputs to be dirty on initial load.
   */
  private isReadyForValidation(model: NgModel): boolean {
    let validationReady = this.isIE11 ? this.model.touched : (this.model.dirty || this.model.touched);
    return validationReady && !!model.errors;
  }

  private hasWarnings(model: NgModel): boolean {
    return (model.control as any).warnings;
  }

  private clearErrors(): void {
    this.clearOldChildren();
    if (this.oldErrors && this.oldErrors !== this.model.errors) {
      this.clearOldStyles();
    }
  }

  /* Clears all the child nodes (error messages)  */
  private clearOldChildren(): void {
    // remove node
    for (const child of this.childNodes) {
      this.renderer.removeChild(this.errorNodeParent, child);
    }
    this.childNodes = [];

    // for custom inputs
    this.hideErrorOnCustom();
  }

  private clearOldStyles(): void {
    let styleEl = this.inputElement.querySelector('input');
    if (styleEl) {
      this.renderer.removeStyle(styleEl, 'color');
    }
    // for custom controls
    this.removeErrorClassesOnCustom();
    this.removeWarningClassesOnCustom();
  }

  /* adds a message to the md-error tag */
  private appendMessage(message: string): void {
    const errNode = this.renderer.createElement('mat-error');
    this.renderer.addClass(errNode, 'mat-error');
    errNode.innerHTML = message;
    const node = this.renderer.appendChild(this.errorNodeParent, errNode);
    this.childNodes.push(errNode);
  }

  private displayError(message: string): void {
    this.host ? this.addMessageTextOnCustom(message) : this.appendMessage(message);
    this.addErrorClassesOnCustom();
  }

  /**
   * Methods for custom controls
   */

  private displayWarning(message: string): void {
    this.addMessageTextOnCustom(message);
    this.addWarningClassesOnCustom();
  }

  private addMessageTextOnCustom(message: string): void {
    this.host.errorNode.nativeElement.innerHTML = message;
  }

  private hideErrorOnCustom(): void {
    if (this.host && this.host.errorNode) {
      this.host.errorNode.nativeElement.innerHTML = '';
    }
  }

  private addErrorClassesOnCustom(): void {
    if (this.host && this.host.input) {
      this.addClasses(this.host.input.nativeElement, ...this.errorClasses);
    }
  }

  private removeErrorClassesOnCustom(): void {
    if (this.host && this.host.input) {
      this.removeClasses(this.host.input.nativeElement, ...this.errorClasses);
    }
  }

  private addWarningClassesOnCustom(): void {
    this.removeErrorClassesOnCustom();
    if (this.host && this.host.input) {
      this.addClasses(this.host.input.nativeElement, ...this.warningClasses);
      this.addClasses(this.host.errorNode.nativeElement, ...this.warningClasses);
    }
  }

  private removeWarningClassesOnCustom(): void {
    if (this.host && this.host.input) {
      this.removeClasses(this.host.input.nativeElement, ...this.warningClasses);
      this.removeClasses(this.host.errorNode.nativeElement, ...this.warningClasses);
    }
  }

  private addClasses(el: ElementRef, ...classes: string[]): void {
    classes.forEach((cls: string) => this.renderer.addClass(el, cls));
  }

  private removeClasses(el: ElementRef, ...classes: string[]): void {
    classes.forEach((cls: string) => this.renderer.removeClass(el, cls));
  }
}
