import { Component, InjectionToken, Injector, OnInit } from '@angular/core';

import { TableColumnBaseEditorComponent } from './../base/base-editor.component';

export let TABLE_EDITOR_DATA: InjectionToken<string> = new InjectionToken<string>('TableColumnParams');

@Component({
  moduleId: module.id + '',
  selector: 'table-column-custom-renderer',
  templateUrl: './custom-editor.component.html',
})
export class TableColumnCustomEditorComponent extends TableColumnBaseEditorComponent implements OnInit {
  public componentRef: any;
  public injector: Injector;
  constructor(parentInjector: Injector) { super(parentInjector); }

  ngOnInit(): void {
    this.injector = Injector.create([
      {
        provide: TABLE_EDITOR_DATA,
        useFactory: () => this.params,
        deps: [],
      },
    ], this.parentInjector);
  }
}
