/**
 * Created by jodell on 6/20/2017.
 */
import { Injectable, Injector } from '@angular/core';
import { PinnedItemApi } from 'cad/common/services/api/nomination/pinned-item/pinned-item';

@Injectable()
export class AllocationPinnedLocationApi extends PinnedItemApi {

  constructor(injector: Injector) {
    // removed
    super(injector);
    this.endpoint = 'flowing-gas/allocation/pinned-location';
  }
}
