/**
 * Created by jrayavarap on 3/12/2018..
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class ContractLocationQuantitiesApi {

  private readonly endpoint: string = 'flowing-gas/imbalance/shipper/contract-location-quantities';

  constructor(
    private apiHelper: ApiHelper) {}

  public getHeader = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/header', { body: params });
  }

  public getHeaderAndList = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/header-and-list/', { body: params });
  }

  public filter = (params?: any): Observable<any> => {
    params.toDate = dateUtil().getLastDateTSofMonthForGivenDate(params.flowBeginDate);
    return this.apiHelper.request(this.endpoint + '/lookup', { body: params });
  }
}
