import { Component, ViewChild, ViewContainerRef } from '@angular/core';

import { TableColumnBaseEditorComponent } from '../base/base-editor.component';

@Component({
  moduleId: module.id + '',
  selector: 'table-column-numeric-editor',
  templateUrl: './numeric-editor.component.tpl.html'
})
export class TableColumnNumericEditorComponent extends TableColumnBaseEditorComponent {
  public value: number;
  public params: any;
  public cancelBeforeStart: boolean = false;

  @ViewChild('input', { read: ViewContainerRef }) public input: any;

  ngAfterViewInit(): void {
    setTimeout((): void => {
      this.input.element.nativeElement.select();
    });
    super.ngAfterViewInit();
  }
}
