import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCache } from 'cad/common/services/api/api-cache';
import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class AgreementContractPriceApi {

  private readonly endpoint: string = 'agreement/contract';

  constructor(
    private apiHelper: ApiHelper,
    private apiCache: ApiCache,
  ) {}

  public getInputs = (itemData: any): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/price/${ itemData.contractId }/inputs`);
  }

  public getProductData = (contractId: string | number): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/price/${ contractId }`);
  }

  public save = (data: any): Observable<any> => {
    let rows = Object.getOwnPropertyNames(data).map((el) => data[el]);
    let contractId = rows[0].agreementId;
    return this.apiHelper.request(`${ this.endpoint }/price/${ contractId }/inputs`, { body: rows });
  }

  public runPriceConfigurator = (contractId: number): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/price/${ contractId }/configurator`);
  }
}
