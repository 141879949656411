import { TableColumnCurrencyRendererComponent } from './currency/currency-renderer.component';
import { TableColumnSelectDropdownEditorComponent } from './select-dropdown/select-dropdown-editor.component';
import { TableColumnSlideToggleRendererComponent } from './slide-toggle/slide-toggle-renderer.component';
import { TableColumnSlideToggleEditorComponent } from './slide-toggle/slide-toggle-editor.component';
import { TableColumnCustomEditorComponent } from './custom/custom-editor.component';
import { TableColumnCustomRendererComponent } from './custom/custom-renderer.component';
import { TableColumnIconRendererComponent } from './icon/icon-renderer.component';
import { TableColumnNumericEditorComponent } from './numeric/numeric-editor.component';
import { TableColumnDefaultEditorComponent } from './default/default-editor.component';
import { TableColumnClickActionRenderer } from './click-action/click-action-renderer.component';
import { TableColumnLookupModule } from './../column/lookup/module';
import { TableColumnComponent } from './column.component';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { TableColumnContextMenuModule } from './context-menu/module';
import { TableColumnCheckboxEditorComponent } from './checkbox/checkbox-editor.component';
import { TableColumnLabelboxEditorComponent } from 'src/ag-grid-wrapper/column/labelbox/labelbox-editor.component';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TableColumnBaseEditorComponent } from './base/base-editor.component';
import { TableColumnToggleBaseRenderer } from './base/toggle-base-renderer';
import { TableColumnLabelboxRenderer } from './labelbox/labelbox-renderer.component';

let components = [
  TableColumnComponent,
  TableColumnCheckboxEditorComponent,
  TableColumnDefaultEditorComponent,
  TableColumnNumericEditorComponent,
  TableColumnIconRendererComponent,
  TableColumnCustomRendererComponent,
  TableColumnCustomEditorComponent,
  TableColumnSlideToggleEditorComponent ,
  TableColumnSlideToggleRendererComponent,
  TableColumnSelectDropdownEditorComponent,
  TableColumnCurrencyRendererComponent,
  TableColumnLabelboxEditorComponent,
  TableColumnClickActionRenderer,
  TableColumnToggleBaseRenderer,
  TableColumnBaseEditorComponent,
  TableColumnLabelboxRenderer
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatSlideToggleModule,
    TableColumnContextMenuModule,
    TableColumnLookupModule,
  ],
  providers: [ CurrencyPipe ],
  declarations: [ components ],
  exports: [ components ],
  entryComponents: [ components ]
})
export class TableColumnModule {
}
