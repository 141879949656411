/**
 * Created by jrayavarap on 8/2/2017.
 */
import { Component, Input } from '@angular/core';

@Component({
  selector: 'contract-contacts-common',
  templateUrl: './contract-contacts-common.component.html',
  styles: [ `
    .card-margin {
      margin: 10px;
    }
    .title-padding {
      padding-bottom: 10px;
    }
  ` ],
})

export class ContractContactsCommonComponent {
  @Input() contactObj: any;
  @Input() headLine: string;
  @Input() contractId: number;
  @Input() baLabel: string;
}

