
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LookupApi } from 'src/features/common/modal/modal-feature-types';
import { ApiHelper } from 'src/cad/common/services/api/api-helper';
import { AlertsService } from 'src/cad/core/services/alerts/alerts.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
@Injectable()
export class GroupPointLookupApi implements LookupApi {
  private readonly endpoint: string = 'flowing-gas/alert-day';
  public pop: any;

  constructor(private apiHelper: ApiHelper, private cadAlerts: AlertsService,) {}

  public filter(params: any): Observable<any> {
    let returnVal: Observable<any>;
    let type = params.type;
    delete params.type;
    let lookupPop: string = _.get(params, 'populator');
    if (lookupPop) {
      this.pop = lookupPop;
    } else if (type) {
      this.pop = type;
    }
    if (this.pop) {
      switch (this.pop) {
        case 'POINT': {
          returnVal = this.apiHelper.request(`${ this.endpoint }/${this.pop}`, { body: params });
          this.pop = {};
          break;
        }
        case 'GROUP': {
          returnVal = this.apiHelper.request(`${ this.endpoint }/${this.pop}`, { body: params });
          this.pop = {};
          break;
        }
        default: {
          this.cadAlerts.info('Please Select a Location Type');
          break;
        }
      }
    }
    return !_.isNil(returnVal) ? returnVal : observableOf([]);
  }
}
