import { Injectable } from '@angular/core';
import { RowNode } from '@ag-grid-community/core';
import * as _ from 'lodash';
import { RowValidationService } from '../row/row-validation.service';
import { TableComponent } from '../table/table.component';
import { UiGridApi } from './ui-grid-api';
import { UiAutoSaveRowNode } from 'src/ag-grid-wrapper/interfaces/ui-auto-save-row-node';

@Injectable()
export class TableRowEditAutoSaveFactory {

  constructor(private validationService: RowValidationService) {
  }

  public unRegisterGridListener = (table: TableComponent): void => {
    this.destroyPublicApi(table.api);
  }

  public onGridApiRegistered = (table: TableComponent): void => {
    this.setupPublicApi(table.api);
    this.setGridSavePromise(table.api, table.onSaveRow);
  }

  /**
   * @name setSavePromise
   * @description Sets the promise associated with the row save, mandatory that
   * the saveRow event handler calls this method somewhere before returning.
   * @param {object} gridRow a data row from the grid for which a save has
   * been initiated
   * @param {promise} savePromise the promise that will be resolved when the
   * save is successful, or rejected if the save fails
   *
   */
  public setSavePromise = (gridRow: RowNode, savePromise: Promise<any>): void => {
    (gridRow as UiAutoSaveRowNode).rowEditSavePromise = savePromise;
  }

  /**
   * @name flushDirtyRows
   * @description Triggers a save event for all currently dirty rows, could
   * be used where user presses a save button or navigates away from the page
   * @param {object} grid the grid for which dirty rows should be flushed
   * @returns {promise} a promise that represents the aggregate of all
   * of the individual save promises - i.e. it will be resolved when all
   * the individual save promises have been resolved.
   *
   */
  public flushDirtyRows = (api: UiGridApi): Promise<any> => {
    const self = this;
    const promises: Promise<any>[] = [];
    api.rowEdit.getDirtyRows().forEach((gridRow: RowNode): void => {
      self.saveRow(api, gridRow)();
      promises.push((gridRow as UiAutoSaveRowNode).rowEditSavePromise);
    });
    return Promise.all(promises);
  }

  private setupPublicApi = (api: UiGridApi): void => {
    _.defaultsDeep(api, {
      rowEdit: {
        setSavePromise: this.setSavePromise,
        flushDirtyRows: this.flushDirtyRows,
      }
    });
  }

  private destroyPublicApi = (api: UiGridApi): void => {
    _.defaultsDeep(api, {
      rowEdit: {
        setSavePromise: null,
        flushDirtyRows: null,
        setRowsDirty: null,
        savePromise: null,
      }
    });
  }

  private setGridSavePromise = (api: UiGridApi, savePromise: Promise<any>) => {
    api.rowEdit.savePromise = savePromise;
  }

  /**
   * @name saveRow
   * @description  Returns a function that saves the specified row from the grid,
   * and returns a promise
   * @param {object} grid the grid for which dirty rows should be flushed
   * @param {GridRow} gridRow the row that should be saved
   * @returns {function} the saveRow function returns a function.  That function
   * in turn, when called, returns a promise relating to the save callback
   */
  private saveRow = (api: UiGridApi, gridRow: RowNode): any => {
    let self = this;
    if(this.validationService.validateRequiredColumns(api, gridRow)) {
      return (): Promise<any> => {
        if ((gridRow as UiAutoSaveRowNode).rowEditSavePromise) {
          return (gridRow as UiAutoSaveRowNode).rowEditSavePromise;
        }
        (gridRow as UiAutoSaveRowNode).isSaving = true;
        api.redrawRows({ rowNodes: [ gridRow ] });
        let promise: Promise<any> = api.rowEdit.savePromise(gridRow.data);
        this.setSavePromise(gridRow, promise);

        if (promise) {
          (gridRow as UiAutoSaveRowNode).rowEditSavePromise.then((result: any) => {
            if (result && result.successful) {
              self.processSuccessPromise(api, gridRow)();
            } else {
              let errMessages = result && result.errorMessages ? result.errorMessages : undefined;
              self.processErrorPromise(api, gridRow)(errMessages);
            }
          }, self.processErrorPromise(api, gridRow));
        } else {
          // console.error('A promise was not returned when saveRow event was raised, either nobody is listening to event or event did not return a promise');
        }

        return promise;
      };
    } else {
      // stop constant save attempts
      clearInterval((gridRow as UiAutoSaveRowNode).rowEditSaveTimer as NodeJS.Timer);
      // show error cells
      api.redrawRows();
      return (): Promise<any> => new Promise<any>((res, rej) => res(false));
    }
  }

  /**
   * @name processSuccessPromise
   * @description  Returns a function that processes the successful
   * resolution of a save promise
   * @param {object} grid the grid for which the promise should be processed
   * @param {GridRow} gridRow the row that has been saved
   * @returns {function} the success handling function
   */
  private processSuccessPromise = (api: UiGridApi, gridRow: RowNode): any => {
    return (): any => {
      api.rowEdit.setRowsClean( [ gridRow ]);
    };
  }

  /**
   * @name processErrorPromise
   * @description  Returns a function that processes the failed
   * resolution of a save promise
   * @param {object} grid the grid for which the promise should be processed
   * @param {GridRow} gridRow the row that is now in error
   * @returns {function} the error handling function
   */
  private processErrorPromise = (api: UiGridApi, gridRow: RowNode): any => {
    return (msg:any): any => {
      api.rowEdit.setRowsClean([ gridRow ]);
      api.rowEdit.setErrorRow(gridRow, msg);
    };
  }

  /**
   * @name considerSetTimer
   * @description Consider setting a timer on this row (if it is dirty).  if there is a timer running
   * on the row and the row isn't currently saving, cancel it, using cancelTimer, then if the row is
   * dirty and not currently saving then set a new timer
   * @param {object} grid the grid for which we are processing
   * @param {GridRow} gridRow the row for which the timer should be adjusted
   *
   */
  public considerSetTimer = (api: UiGridApi, gridRow: RowNode): void => {
    this.cancelTimer(api, gridRow);
    if ((gridRow as UiAutoSaveRowNode).isDirty && !(gridRow as UiAutoSaveRowNode).isSaving) {
      let waitTime = 2000;
      (gridRow as UiAutoSaveRowNode).rowEditSaveTimer = setInterval(this.saveRow(api, gridRow), waitTime);
    }
  }

  /**
   * @name cancelTimer
   * @description cancel the $interval for any timer running on this row
   * then delete the timer itself
   * @param {object} grid the grid for which we are processing
   * @param {GridRow} gridRow the row for which the timer should be adjusted
   *
   */
  public cancelTimer = (api: UiGridApi, gridRow: RowNode): void => {
    if (gridRow && (gridRow as UiAutoSaveRowNode).rowEditSaveTimer && !(gridRow as UiAutoSaveRowNode).isSaving) {
      clearInterval((gridRow as UiAutoSaveRowNode).rowEditSaveTimer as NodeJS.Timer);
      delete (gridRow as UiAutoSaveRowNode).rowEditSaveTimer;
    }
  }

  /**
   * @name beginEditCell
   * @description Receives a beginCellEdit event from the edit function,
   * and cancels any rowEditSaveTimer if present, as the user is still editing
   * this row.  Only the rowEntity parameter
   * is processed, although other params are available.  Grid
   * is automatically provided by the gridApi.
   * @param {object} grid the grid component that fired the beginCellEdit
   * event
   * @param {object} gridRow the grid row node for which the cell
   * was edited
   * @param {object} colDef the column definition that fired begin
   * edit
   */
  public beginCellEdit = (api: UiGridApi, gridRow: RowNode, colDef?: any): void => {
    if (!gridRow) {
      return;
    }
    this.cancelTimer(api, gridRow);
  }

  /**
   * @name endEditCell
   * @description Receives an afterCellEdit event from the edit function,
   * and sets flags as appropriate.  Only the rowEntity parameter
   * is processed, although other params are available.  Grid
   * is automatically provided by the gridApi.
   * @param {object} grid the grid component that fired the cellValueChanged
   * event
   * @param {object} gridRow the grid row node for which the cell
   * was edited
   * @param {object} colDef the column definition for which the cell
   * was edited
   * @param {object} newValue the newValue of the cell that was edited
   * @param {object} oldValue the oldValue of the cell that was edited
   */
  public endCellEdit = (api: UiGridApi, gridRow: RowNode, colDef: any, newValue: any, oldValue: any): void => {

    if (!gridRow) {
      // console.debug('Unable to find rowEntity in grid data, dirty flag cannot be set');
      return;
    }
    if (newValue !== oldValue || (gridRow as UiAutoSaveRowNode).isDirty) {

      if (!(gridRow as UiAutoSaveRowNode).isDirty) {
        (gridRow as UiAutoSaveRowNode).isDirty = true;
        api.rowEdit.dirtyRows.push(gridRow);
      }
      delete (gridRow as UiAutoSaveRowNode).isError;
      this.considerSetTimer(api, gridRow);
    }
  }
}
