import { Component, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import { UiFormSelectComponent } from '../select/select.component';
import * as _ from 'lodash';

@Component({
  selector: 'ui-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: [ '../select/select.component.less', './multi-select.component.less' ]
})
export class UiFormMultiSelectComponent extends UiFormSelectComponent {

  @Input('visibleItemProperties') visibleItemProperties: any[] = null;
  @HostListener('window:resize')
  onWindowResize(): void {
    this._throttledCalculateVisibleItemsFn();
  }
  @HostListener('window:orientationchange')
  onWindowOrientationChange(): void {
    this._throttledCalculateVisibleItemsFn();
  }
  @ViewChild('selectionCountTemplateRef', { read: ElementRef }) selectionCountEl: ElementRef;
  
  protected _addEmptyRow: boolean = false;
  public get isOverflowing(): boolean {
    return this._isOverflowing;
  }
  public set isOverflowing(value: boolean) {
    this._isOverflowing = value;
    this.onVisibleItemsChanged();
  }
  private overflowPaddingClass: string = 'ui-multi-select-selection-padding';
  public visibleItems: any[] = [];
  private valContainerEl: any;
  private _throttledCalculateVisibleItemsFn: Function & _.Cancelable;
  private _isOverflowing: boolean = false;

  ngOnInit(): void {
    super.ngOnInit(); // mandatory
    this._throttledCalculateVisibleItemsFn = _.throttle(() => {
      this.ngZone.run(() => {
        this.calculateVisibleItems();
      });
    }, 500, { leading: false, trailing: true });
    if (_.isNil(this.enableHeaderFilter)) {
      // If enableHeaderFilter is not specified then default it to true/enabled
      // for ui-multi-select (this) component only
      this.enableHeaderFilter = true;
      this.showHeaderSelectionRow = true;
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    setTimeout(() => {
      this.updateSelectionElements();
      this._throttledCalculateVisibleItemsFn();
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._throttledCalculateVisibleItemsFn.cancel();
    this._throttledCalculateVisibleItemsFn = null;
  }

  onExpansionChange(): void {
    if (this.matSelectRef && (this.matSelectRef as any).uiVirtualRepeat) {
      (this.matSelectRef as any).uiVirtualRepeat.refresh(false);
      (this.matSelectRef as any)._changeDetectorRef.markForCheck();
    }
  }

  onPanelClose(): void {
    super.onPanelClose();
    setTimeout(() => {
      this.updateSelectionElements();
      this._throttledCalculateVisibleItemsFn();
    });
  }

  public getSelectedObjects(): any[] {
    return _.filter(this.optionList, (optionItem: any) => {
      return this.model && this.model.indexOf(optionItem[ this.valueProperty ]) > -1;
    });
  }

  private updateSelectionElements(): void {
    let valueEl: any = (this.matSelectRef && this.matSelectRef.trigger)
      ? this.matSelectRef.trigger.nativeElement.querySelector('.mat-select-value')
      : null;
    if (valueEl) {
      if (this.selectionCountEl && this.selectionCountEl.nativeElement) {
        this.renderer.appendChild(valueEl, this.selectionCountEl.nativeElement);
      }
      if (this.valContainerEl) {
        this.valContainerEl = null;
      }
      this.valContainerEl = valueEl.querySelector('.mat-select-value-text');
    }
  }

  private calculateVisibleItems = (): void => {
    if (this.optionList && this.optionList.length
      && this.model && this.model.length) {
      let selectionArr: any[] = this.getSelectedObjects();
      if (this.valContainerEl && selectionArr.length) {
        let valContainerWidth: number;
        let clientFontSizePx: number;
        let valContainerVisibleText: string;
        let visibleItems: any[] = [];
        valContainerWidth = this.valContainerEl.getBoundingClientRect().width;
        this.isOverflowing = this.valContainerEl.clientWidth < this.valContainerEl.scrollWidth;
        clientFontSizePx = this.valContainerEl.scrollWidth / this.valContainerEl.textContent.length;
        valContainerVisibleText = this.valContainerEl.textContent.substring(0, Math.ceil(valContainerWidth / clientFontSizePx)
          - (this.isOverflowing ? 2 : 0));
        _.forEach(selectionArr, (item: any) => {
          if (_.includes(valContainerVisibleText, item[ this.viewValueProperty ])) {
            visibleItems.push(item);
            valContainerVisibleText = valContainerVisibleText.replace(item[ this.viewValueProperty ], '').replace(/,/,'').trim();
          } else if (valContainerVisibleText.length > 0
            && valContainerVisibleText.trim().length < item[ this.viewValueProperty ].length) {
            visibleItems.push(item);
            valContainerVisibleText = '';
          }
        });
        if (!_.isEqual(this.visibleItems, visibleItems)) {
          this.visibleItems.splice(0);
          this.visibleItems = this.visibleItems.concat(visibleItems);
        }
        if ((selectionArr.length - visibleItems.length) < 1) {
          this.isOverflowing = false;
        }
      }
    } else {
      this.isOverflowing = false;
    }
  }

  private onVisibleItemsChanged(): void {
    if (this.visibleItems && this.model && this.valContainerEl) {
      if (this.isOverflowing) {
        this.renderer.addClass(this.valContainerEl, this.overflowPaddingClass);
      } else {
        this.renderer.removeClass(this.valContainerEl, this.overflowPaddingClass);
      }
      this.changeDetectorRef.markForCheck();
    }
  }
}
