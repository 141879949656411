import { Injectable } from '@angular/core';
import { RowNode, RowNodeTransaction } from '@ag-grid-community/core';
import { TableComponent } from '../table/table.component';
import * as _ from 'lodash';

@Injectable()
export class TableRowEditDeleteFactory {

  constructor() {}

  public unRegisterGridListener(table: TableComponent): void {
    this.removeDeleteRowApi(table);
  }

  public onGridApiRegistered(table: TableComponent): void {
    this.setDeleteRowApi(table);
  }

  private setDeleteRowApi(grid: TableComponent): void {
    _.defaultsDeep(grid.api, {
      rowEdit: {
        deleteRowByData: (deleteRowData: any): void => {
          if (grid
            && grid.api
            && deleteRowData) {
            let rowNodes: RowNode[];

            if (grid.api.rowEdit) {
              rowNodes = grid.api.rowEdit.getAllRowNodes();
            }
            if (_.isArray(rowNodes)) {
              const rowIndex: number = _.findIndex(rowNodes, { data: deleteRowData });
              if (rowIndex > -1) {
                const rowNode: RowNode = rowNodes[ rowIndex ];
                let rowNodeTrans: RowNodeTransaction = grid.api.updateRowData({
                  add: null,
                  addIndex: null,
                  update: null,
                  remove: [ rowNode.data ]
                });

                if (rowNodeTrans
                  && _.isArray(rowNodeTrans.remove)
                  && rowNodeTrans.remove[ 0 ]) {
                  grid.rowRemoved.emit(rowNodeTrans.remove[ 0 ]);
                }
              }
            }
          }
        },
        deleteRowByIndex: (rowIndex: number): void => {
          const rowNode: any = grid.api.getModel().getRow(rowIndex);
          grid.api.updateRowData({ remove: [ rowNode ] });
          grid.rowRemoved.emit(rowNode);
        }
      }
    });
  }

  private removeDeleteRowApi(grid: TableComponent): void {
    delete (grid.api as any).rowEdit.deleteRowByData;
    delete (grid.api as any).rowEdit.deleteRowByIndex;
  }
}
