import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { DocumentsApi } from 'cad/common/services/api/shared/documents/documents';
import { UserPreferenceService } from 'cad/common/services/preferences/user-preferences';
import { RouterService } from 'cad/core/services/router/router.service';
import { LayoutService } from 'common/services/layout/layout';
import { saveAs } from 'file-saver-es';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { concatMap, filter, map, shareReplay, take, tap } from 'rxjs/operators';
import { AvailableAsset } from 'src/cad/common/models/user/user-details-model';
import { MessagesApi } from 'src/cad/common/services/api/administration/messages/messages';
import { DashboardService } from 'src/cad/common/services/dashboard/dashboard-service';
import { UserStoreService } from 'src/cad/common/store/core/services/user-store.service';
import { DocumentFileTypeEnum, DocumentTypeEnum } from 'src/cad/dashboard/enums/dashboard-enums';
import { DocumentData } from 'src/cad/dashboard/interfaces/document-data';
import { BaseDashComponent } from 'src/cad/dashboard/views/content/components/base-dash.component';
import { AutoUnsubscribables, AutoUnsubscriber } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import { ANNOUNCEMENT_ITEM_LAYOUT } from './announcement-item-layout';
import RBSServerMessageData = mplus.RBSServerMessageData;

@Component({
  selector: 'announcements-dash',
  templateUrl: './announcements-dash.component.html',
})
export class AnnouncementsDashComponent extends BaseDashComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('pdfViewer') public pdfViewer: PdfJsViewerComponent;
  public showUsefulLinks$: Observable<boolean>;
  public documentDataList$: Observable<DocumentData[]>;
  private itemDataSubject: ReplaySubject<AvailableAsset[]> = new ReplaySubject<AvailableAsset[]>(1);
  public layout: any = {};
  public announcements: RBSServerMessageData[] = [];

  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  private readonly typeFilter: DocumentData = {
    type: DocumentTypeEnum.USEFUL_LINK,
    documentId: null,
    assetNbr: null,
    name: null,
    description: null,
    fileName: null,
    fileType: null,
  };

  constructor(
    public apiHelper: ApiHelper,
    public router: RouterService,
    public route: ActivatedRoute,
    private ups: UserPreferenceService,
    private documentsApi: DocumentsApi,
    private dashboardService: DashboardService,
    private userStateStore: UserStoreService,
    private layoutService: LayoutService,
    private messageApi: MessagesApi,
  ) {
    super(apiHelper, router, route, ups);
    this.itemData$ = this.itemDataSubject.asObservable();
  }

  public ngOnInit(): void {
    this.documentDataList$ = this.userStateStore.stateChanged
    .pipe(
      filter((data) => data.stateChanges),
      concatMap((userState) => {
        return userState.stateChanges.user
          ? this.documentsApi.filter(this.typeFilter).pipe(tap((_) => {
            if (this.pdfViewer) {
              this.pdfViewer.pdfSrc = null;
              this.pdfViewer.downloadFileName = null;
            }
          }))
          : of(null);
      }),
      shareReplay()
    );

    this.showUsefulLinks$ =
      combineLatest(
        this.itemData$.pipe(map((itemData) => !!itemData)),
        this.documentDataList$.pipe(map((d) => d && d.length > 0)))
      .pipe(map(      ([ itemsPresent, documentsPresent ]) => itemsPresent && documentsPresent));

    this.subs.newSub = this.layoutService.layoutStream(ANNOUNCEMENT_ITEM_LAYOUT).subscribe((layout) => {
      this.layout = layout;
    });
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes
        && changes.userDetails
        && changes.userDetails.currentValue !== changes.userDetails.previousValue) {
        this.itemDataSubject.next(changes.userDetails.currentValue ? changes.userDetails.currentValue.availableassets : null);

        this.getAnnouncements(changes);
      }

    }
  }

  public ngOnDestroy(): void {
    this.itemDataSubject.complete();
    if (this.pdfViewer) {
      this.pdfViewer.pdfSrc = null;
    }
  }

  public getAnnouncements(changes: SimpleChanges): void {
    //call if no prev value OR if the user id has changed - trying to reduce calls
    if (!changes.userDetails.previousValue
      || (changes.userDetails.previousValue.userId
      && changes.userDetails.currentValue.userId !== changes.userDetails.previousValue.userId)) {

      this.subs.newSub = this.messageApi.getAnnouncements().subscribe((announcements: RBSServerMessageData) => {
        //during run as, depending on context this can throw an error until the user is all the way switched
        if (Array.isArray(announcements)) {
          this.announcements = announcements;
        }
      });
    }
  }

  public getEmptyHeadline(): string {
    return 'No Announcements';
  }
  public getDefaultHeadline(): string {
    return 'Announcements';
  }
  public getName(): string {
    return 'announcements';
  }

  public openDocument(documentData: DocumentData): void {
    if (documentData && documentData.documentId && documentData.fileType) {
      this.documentsApi.getById(documentData.documentId, documentData.fileType)
      .pipe(take(1))
      .subscribe((data: Blob) => {
        switch (documentData.fileType) {
          case DocumentFileTypeEnum.PDF: {
            this.openPdf(data, documentData.fileName);
            break;
          }
          case DocumentFileTypeEnum.XLS: {
            this.openSaveAs(data, DocumentFileTypeEnum.XLS, documentData.fileName);
            break;
          }
          case DocumentFileTypeEnum.PS: {
            this.openSaveAs(data, DocumentFileTypeEnum.PS, documentData.fileName);
            break;
          }
        }
      });
    }
  }

  public openPdf(documentSrcData: string | Blob | Uint8Array, fileName: string): void {
    if (this.pdfViewer) {
      this.pdfViewer.pdfSrc = documentSrcData; // pdfSrc can be Blob or Uint8Array
      this.pdfViewer.downloadFileName = fileName;
      this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
    }
  }

  public openSaveAs(documentSrcData: string | Blob | Uint8Array, fileType: DocumentFileTypeEnum, fileName: string): void {
    let fileBlob: Blob = new Blob([ documentSrcData ], { type: 'application/' + fileType });

    saveAs(fileBlob, fileName);
  }
}
