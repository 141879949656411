
import {of as observableOf,  Observable ,  Subscription ,  Subject } from 'rxjs';

import {switchMap} from 'rxjs/operators';


import { Directive, ElementRef, EventEmitter, Input, Output, OnInit, OnChanges, OnDestroy,
  Renderer2, RendererStyleFlags2, SimpleChanges
} from '@angular/core';
import { Cancelable } from 'lodash';

export interface ChangeEvent {
  start?: number;
  end?: number;
}
@Directive({
  selector: '[uiVirtualRepeatDir]',
  exportAs: 'uiVirtualRepeatDir'
})
export class UiVirtualRepeatDirective implements OnInit, OnDestroy, OnChanges {

  @Input('uiVirtualRepeatDir') originalCollection: any[] = [];
  @Input('vrEnabled')
  get enabled(): boolean {
    return this._enabled;
  }
  set enabled(value: boolean) {
    this._enabled = value;
    if (value === true) {
      this.initScrollContainer();
    } else {
      this.revertScrollContainer();
      this._scrollContainerRef = this.element.nativeElement;
    }
  }
  @Input('vrScrollContainer')
  get scrollContainer(): any {
    return this._scrollContainerRef;
  }
  set scrollContainer(value: any) {
    let containerEl: any;
    if (_.isString(value) && !_.isEmpty(value)) {
      containerEl = this.element.nativeElement.querySelector(value);
    } else {
      containerEl = value;
    }
    if (containerEl && !_.isEqual(this._scrollContainerRef, containerEl)) {
      this.revertScrollContainer();
      this._scrollContainerRef = containerEl;
      this.initScrollContainer();
    }
  }
  @Input('vrScrollbarWidth') scrollbarWidth: number;
  @Input('vrScrollbarHeight') scrollbarHeight: number;
  @Input('vrChildWidth') childWidth: number;
  @Input('vrChildHeight') childHeight: number;
  @Output('vrUpdate') virtualCollectionUpdate: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output('vrIndexChange') virtualIndexChange: EventEmitter<ChangeEvent> = new EventEmitter<ChangeEvent>();

  contentElementRef: any;
  offsetBeforeEl: any;
  scroll$: Subject<Event> = new Subject<Event>();
  scrollHeight: number;
  previousStart: number;
  previousEnd: number;
  startupLoop: boolean = true;

  private subscriptions: Subscription[] = [];
  private _enabled: boolean = true;
  private _scrollContainerRef: any = this.element.nativeElement;
  private scrollContainerElOrigStyle: any;
  private contentElOrigStyle: any;
  private _scrollListener: any;
  private _debouncedOnScrollFn: Function & Cancelable;


  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
  ) {
    this._debouncedOnScrollFn = _.debounce(this.onScroll, 100);
    this.offsetBeforeEl = this.renderer.createElement('div');
    this.renderer.setStyle(this.offsetBeforeEl, 'width', '1px');
    this.renderer.setStyle(this.offsetBeforeEl, 'opacity', '0');
  }

  ngOnInit(): void {
    this.subscriptions.push(this.scroll$.pipe(switchMap(() => {
      this.refresh();
      return observableOf();
    })).subscribe());
    this.scrollbarWidth = 0; // this.element.nativeElement.offsetWidth - this.element.nativeElement.clientWidth;
    this.scrollbarHeight = 0; // this.element.nativeElement.offsetHeight - this.element.nativeElement.clientHeight;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const items = changes.originalCollection;
    if (items && (items.previousValue === undefined || items.previousValue.length === 0)) {
      this.startupLoop = true;
    }
    this.previousStart = undefined;
    this.previousEnd = undefined;
    this.refresh(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this._debouncedOnScrollFn.cancel();
    this.revertScrollContainer();
    if (this.renderer && _.isFunction(this.renderer.destroyNode)) {
      this.renderer.destroyNode(this.offsetBeforeEl);
    }
    this._scrollContainerRef = null;
    this.offsetBeforeEl = null;
  }

  initScrollContainer(): void {
    if (!this.enabled) { return; }
    this.scrollContainerElOrigStyle = _.pick(this.scrollContainer.style, [ 'overflow', 'overflowY', 'position', '-webkit-overflow-scrolling' ]);
    this.renderer.setStyle(this.scrollContainer, 'overflow', 'hidden');
    this.renderer.setStyle(this.scrollContainer, 'overflow-y', 'auto');
    this.renderer.setStyle(this.scrollContainer, 'position', 'relative');
    this.renderer.setStyle(this.scrollContainer, '-webkit-overflow-scrolling', 'touch');
    if (this.scrollContainer && this.scrollContainer.children && this.scrollContainer.children.length > 0) {
      this.contentElementRef = this.scrollContainer.children[ 0 ];
      this.contentElOrigStyle = _.pick(this.contentElementRef.style, [ 'top', 'left', 'width', 'height', 'position' ]);
      this.renderer.setStyle(this.contentElementRef, 'top', '0');
      this.renderer.setStyle(this.contentElementRef, 'left', '0');
      this.renderer.setStyle(this.contentElementRef, 'width', '100%');
      this.renderer.setStyle(this.contentElementRef, 'height', '100%');
      this.renderer.setStyle(this.contentElementRef, 'position', 'absolute', RendererStyleFlags2.Important);
      this.renderer.insertBefore(this.scrollContainer, this.offsetBeforeEl, this.contentElementRef);
    }
    this._scrollListener = this.renderer.listen(this.scrollContainer, 'scroll', this.onScroll);
    this.refresh(false);
  }

  revertScrollContainer(): void {
    if (this.scrollContainer) {
      if (_.isFunction(this._scrollListener)) {
        this._debouncedOnScrollFn.cancel();
        this._scrollListener();
        this._scrollListener = null;
      }
      if (this.scrollContainerElOrigStyle) {
        _.keys(this.scrollContainerElOrigStyle).forEach((styleProp: string) => {
          this.renderer.removeStyle(this.scrollContainer, _.kebabCase(styleProp));
        });
        _.assign(this.scrollContainer.style, this.scrollContainerElOrigStyle.style);
      }
      if (this.contentElementRef) {
        _.keys(this.contentElOrigStyle).forEach((styleProp: string) => {
          this.renderer.removeStyle(this.contentElementRef, _.kebabCase(styleProp));
        });
        this.renderer.removeStyle(this.contentElementRef, 'transform', RendererStyleFlags2.Important);
        if (this.contentElOrigStyle) {
          _.assign(this.contentElementRef.style, this.contentElOrigStyle.style);
        }
      }
      this.renderer.removeStyle(this.offsetBeforeEl, 'height');
      if (this.scrollContainer === this.renderer.parentNode(this.offsetBeforeEl)) {
        this.renderer.removeChild(this.scrollContainer, this.offsetBeforeEl);
      }
      this.contentElementRef = null;
    }
  }

  refresh(animationFrame: boolean = true): void {
    if (this.enabled) {
      if (animationFrame) {
        requestAnimationFrame(() => this.calculateItems());
      } else {
        this.calculateItems();
      }
    }
  }

  scrollToIndex(index: number): void {
    if (index < 0 || index >= (this.originalCollection || []).length) { return; }
    let dimensions: any = this.calculateDimensions();
    this.scrollContainer.scrollTop = Math.floor(index / dimensions.itemsPerRow) *
      dimensions.childHeight - Math.max(0, (dimensions.itemsPerCol - 1)) * dimensions.childHeight;
    this.refresh();
  }

  scrollToItem(item: any): void {
    let index: number = (this.originalCollection || []).indexOf(item);
    this.scrollToIndex(index);
  }

  private countItemsPerRow(): number {
    let contentEl: any = this.contentElementRef;
    let offsetTop: number;
    let itemsPerRow: number = 0;
    if (contentEl && contentEl.children) {
      for (itemsPerRow = 0; itemsPerRow < contentEl.children.length; itemsPerRow++) {
        if (offsetTop != undefined && offsetTop !== contentEl.children[itemsPerRow].offsetTop) { break; }
        offsetTop = contentEl.children[itemsPerRow].offsetTop;
      }
    }
    return itemsPerRow;
  }

  private calculateDimensions(): any {
    let scrollEl: any = this.scrollContainer;
    let contentEl: any = this.contentElementRef;
    let items = this.originalCollection || [];
    let itemCount = items.length;
    let viewWidth = scrollEl.clientWidth - this.scrollbarWidth;
    let viewHeight = scrollEl.clientHeight - this.scrollbarHeight;
    let contentDimensions;
    if (this.childWidth == undefined || this.childHeight == undefined) {
      contentDimensions = (contentEl && contentEl.children && contentEl.children[ 0 ])
        ? contentEl.children[ 0 ].getBoundingClientRect()
        : { width: viewWidth, height: viewHeight };
    }
    let childWidth: number = this.childWidth || contentDimensions.width;
    let childHeight: number = this.childHeight || contentDimensions.height;
    let itemsPerRow: number = Math.max(1, this.countItemsPerRow());
    let itemsPerRowByCalc: number = Math.max(1, Math.floor(viewWidth / childWidth));
    let itemsPerCol: number = Math.max(1, Math.floor(viewHeight / childHeight));
    let scrollTop: number = Math.max(0, scrollEl.scrollTop);
    if (itemsPerCol === 1 && Math.floor(scrollTop / this.scrollHeight * itemCount) + itemsPerRowByCalc >= itemCount) {
      itemsPerRow = itemsPerRowByCalc;
    }
    return {
      itemCount,
      viewWidth,
      viewHeight,
      childWidth,
      childHeight,
      itemsPerRow,
      itemsPerCol,
      itemsPerRowByCalc
    };
  }

  private calculateItems(): void {
    let scrollEl: any = this.scrollContainer;
    let contentEl: any = this.contentElementRef;
    let dimensions: any = this.calculateDimensions();
    let items: any[] = this.originalCollection || [];
    this.scrollHeight = dimensions.childHeight * dimensions.itemCount / dimensions.itemsPerRow;
    this.renderer.setStyle(this.offsetBeforeEl, 'height', this.scrollHeight + 'px');
    if (scrollEl.scrollTop > this.scrollHeight) {
      scrollEl.scrollTop = this.scrollHeight;
    }
    let scrollTop: number = Math.max(0, scrollEl.scrollTop);
    let indexByScrollTop: number = scrollTop / this.scrollHeight * dimensions.itemCount / dimensions.itemsPerRow;
    let end: number = Math.min(dimensions.itemCount, Math.ceil(indexByScrollTop)
      * dimensions.itemsPerRow + dimensions.itemsPerRow
      * (dimensions.itemsPerCol + 1));
    let maxStartEnd: number = end;
    const modEnd: number = end % dimensions.itemsPerRow;
    if (modEnd) {
      maxStartEnd = end + dimensions.itemsPerRow - modEnd;
    }
    let maxStart: number = Math.max(0, maxStartEnd - dimensions.itemsPerCol
      * dimensions.itemsPerRow - dimensions.itemsPerRow);
    let start: number = Math.min(maxStart, Math.floor(indexByScrollTop) * dimensions.itemsPerRow);
    let contentYOffset: number = dimensions.childHeight * Math.ceil(start / dimensions.itemsPerRow);
    if (contentEl) {
      this.renderer.setStyle(contentEl, 'transform', 'translateY(' + contentYOffset + 'px)', RendererStyleFlags2.Important);
    }
    start = !isNaN(start) ? start : -1;
    end = !isNaN(end) ? end : -1;
    if (start !== this.previousStart || end !== this.previousEnd) {
      // emit the virtual collection
      this.virtualCollectionUpdate.emit(items.slice(start, end));
      this.previousStart = start;
      this.previousEnd = end;
      if (this.startupLoop === true) {
        this.refresh();
      } else {
        this.virtualIndexChange.emit({ start, end });
      }
    } else if (this.startupLoop === true) {
      this.startupLoop = false;
      this.refresh();
    }
  }

  private onScroll = (): void => {
    this.scroll$.next();
  }
}
