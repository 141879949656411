import { Component, ComponentRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TableComponent } from 'src/ag-grid-wrapper/table/table.component';
import { ItemController } from 'src/features/common/item/item.controller';
import { AssociationController } from '../../association.controller';
import { FullscreenDialogComponent } from '../fullscreen-dialog/fullscreen-dialog.component';

@Component({
  selector: 'ui-body-association-dialog',
  templateUrl: './fullscreen-dialog-body.component.html',
  styleUrls: [ './fullscreen-dialog-body.component.less' ],
})
export class FullscreenDialogBodyComponent implements OnInit {

  @Input() selectedContentIndex: number;
  @Input() selectedTabIdx: number;
  @Input() associationController: AssociationController;
  @Input() fromController: ItemController;
  @Input() data: any;
  @Input() createInjector: any;
  @Input() get searchResults(): any[] {
    return this._searchResults;
  }
  set searchResults(val: any[]) {
    this._searchResults = val;
    this.searchResultsChange.emit(this._searchResults);
  }

  @Output() tabSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() newItemChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() tabIndex: EventEmitter<number> = new EventEmitter<number>();
  @Output() searchResultsChange: EventEmitter<any> = new EventEmitter<any>();

  public results: any;
  public isUpdating: boolean;
  // public searchResults: any[] = [];
  public searchReference: ComponentRef<any>;
  public itemNewFormReference: ComponentRef<any>;
  public formReference: ComponentRef<any>;
  public listInjector: Injector;
  @ViewChild('listTable', { static: true }) private listTable: TableComponent;

  protected item: any = {};

  private _searchResults: any[] = [];

  constructor(
    public associationDialogComponent: FullscreenDialogComponent,
    public injector: Injector,
  ) { }

  public ngOnInit(): void {
    //
    // Bootstrap a special injector for the list partial that
    // contains the TableComponent ViewChild instance (which the partial needs)
    //
    this.listInjector = Injector.create(
      [
        {
          provide: TableComponent,
          useFactory: () => { return this.listTable; },
          deps: []
        },
      ],
      this.injector);

  }

  public autoRetrieve(): boolean {
    return !!this.results;
  }

  public onSearchReady(comp: any): void {
    this.searchReference = comp;
    _.assign(
      this.searchReference.instance.params,
      this.associationController.model.toModel.defaultSearchParams
    );
    if (this.associationDialogComponent) {
      this.associationDialogComponent.searchReference = this.searchReference;
    }
  }

  public onTabChange(event: any): void {
    if (event === 1 && this.associationController) {
      let newDataItem: any = this.associationController.newDataItem;
      if (newDataItem
        && newDataItem.itemController
        && newDataItem.itemController.model
        && !_.isEqual(newDataItem.itemController.model, this.associationController.model.toModel)) {
        if (!this.associationController.newDataItem.itemData) {
          this.associationController.newDataItem.itemData = {};
        }
        if (newDataItem.itemLayout && newDataItem.layoutService) {
          this.associationController.newDataItem.itemLayout = newDataItem.layoutService.buildLayout(this.associationController.model.toModel.layout);
        }
        // Re-evaluate new item defaults since baseComponent overwrites the itemData on ngOnInit
        _.assign(this.associationController.newDataItem.itemData, this.associationController.model.toModel.defaults());
      }
    }
    this.tabIndex.emit(event);
  }

  public onSelectedIndexChange(event: any): void {
    this.tabSelected.emit(event);
  }

  public onItemNewReady(comp: any): void {
    this.itemNewFormReference = comp;
    this.associationController.newDataItem = comp.instance;
    _.assign(this.associationController.newDataItem.itemData, this.associationController.model.toModel.defaults());
    if (this.associationDialogComponent) {
      this.associationDialogComponent.itemNewFormReference = this.itemNewFormReference;
    }
  }

  public select = (item: any): void => {
    if (item.node.isSelected()) {
      this.associationController.addSelectedItem(item.node.data);
    } else {
      this.associationController.removeSelectedItem(item.node.data);
    }
  }

  public onFormReady(comp: any): void {
    this.formReference = comp;

    this.setupAssociation(comp);
    if (this.associationDialogComponent) {
      this.associationDialogComponent.formReference = this.formReference;
    }
  }

  public setupAssociation = (comp: ComponentRef<any>): void => {
    this.associationController.newAssociation = comp.instance.association || {};
    if (!comp.instance.association) {
      console.error('Association form partial must have public association property.');
    }

    _.defaults<object, object>(this.associationController.newAssociation, this.associationController.model.defaults);
    let fromPk = this.fromController.model.primaryKey;
    let toPk = this.associationController.model.toModel.primaryKey;

    this.associationController.newAssociation[ fromPk ] = this.data.itemData[ fromPk ];
    this.associationController.newAssociation[ toPk ] = this.data.itemData[ toPk ];
  }
}
