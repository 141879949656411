import {Component, OnInit} from '@angular/core';

import { BaseDashComponent } from 'src/cad/dashboard/views/content/components/base-dash.component';

@Component({
  selector: 'ba-dash',
  templateUrl: './ba-dash.component.html',
})
export class BADashComponent extends BaseDashComponent implements OnInit {
  public name: string = 'ba';
  public endPoint: string = 'associate/business-associate/dashboard';

  ngOnInit(): void {
    super.ngOnInit();
  }

  public getEmptyHeadline(): string {
    return 'No Recent BAs';
  }
  public getDefaultHeadline(): string {
    return 'Recent Business Associates';
  }
  public getName(): string {
    return this.name;
  }

  public onRowClicked = (event: any): void => {
    super.navigate([ 'data-administration','business-associate', event.data.baId  ]);
  }
}
