
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ApiHelper } from 'src/cad/common/services/api/api-helper';
import { CapRelCommentTemplate } from 'src/cad/capacity-release/shared/interfaces/caprel-comment-template';
import { CRBidData } from 'src/cad/capacity-release/shared/interfaces/cr-bid-data';
import ValidationResult = cad.ValidationResult;
import * as _ from 'lodash';

@Injectable()
export class CapacityReleaseOfferBidsApi {
  private readonly endpoint: string = 'capacity-release/offer/';
  private readonly suffix: string = '/bids';

  constructor(private apiHelper: ApiHelper) {}

  public getById = (auctionId: number | string, bidId: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/' + bidId);
  }

  public find = (params: any): Observable<any> => {
    let auctionId: number = -1;
    let bidId: number = -1;

    if (params) {
      if (!_.isNil(params.relatedAuctionID)) {
        auctionId = params.relatedAuctionID;
      }
      if (!_.isNil(params.bidID)) {
        bidId = params.bidID;
      }
    }
    return this.getById(auctionId, bidId);
  }

  public getBids = (auctionId: number | string, bidData: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix, { body: bidData });
  }

  public recent = (auctionId: number | string = -1, offerData: any = {}): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/dashboard', { body: offerData });
  }

  public filter(filterData: CRBidData): Observable<CRBidData[]> {
    let auctionId: number = -1;
    if (filterData && !_.isNil(filterData.relatedAuctionID)) {
      auctionId = filterData.relatedAuctionID;
    }
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/filter', { body: filterData });
  }

  public save = (auctionId: number | string = -1, bidData: any = {}): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/save', { body: bidData });
  }

  public deleteItem = (item: any): Observable<any> => {
    return observableOf({msg:'*** DELETE NOT IMPLEMENTED, BID CANNOT BE DELETED ***'});
  }

  public postBid = (auctionId: number | string, bidData: any, quiet?: boolean): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/post-bid', { body: bidData, quiet });
  }

  public withdrawBid = (auctionId: number | string, bidData: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/withdraw-bid', { body: bidData });
  }

  public getUmbrellaBidData = (auctionId: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/umbrella-data');
  }
  
  public getBADesigneeList(auctionId: number | string, designeeBAId?: number | string): Observable<any> {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/ba-designee-list/' + designeeBAId || '-1');
  }
  
  public printBid(bidId: number): Observable<ValidationResult<void>> {
    return this.apiHelper.request(`${ this.endpoint }-1${this.suffix}/${ bidId }/print`);
  }
  
  public getCommentTemplates(bidId: number): Observable<CapRelCommentTemplate[]> {
    return this.apiHelper.request(`${ this.endpoint }-1${this.suffix}/${ bidId }/comments`);
  }
}
