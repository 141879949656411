import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class AssociateContactBusinessAssociateApi {

  private readonly endpoint: string = 'associate/contact/';
  private readonly suffix: string = '/ba';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public findAll = (contactId: string | number): Observable<any> => {
    return this.apiHelper.request(this.endpoint + contactId + this.suffix );
  }

  public save = (items: any, contact: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + contact.contactId + this.suffix , { body: contact.list });
  }
}
