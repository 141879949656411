
import {map} from 'rxjs/operators';
import { Injector, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AssociationController } from 'src/features/common/association/association.controller';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AssociationApi } from 'src/features/common/association/association-api';

@Injectable()
export class AgreementContractInternalContactsApi implements AssociationApi {

  static createAssociationApi(
    apiHelper: ApiHelper,
    associationController: AssociationController,
    injector: Injector,
  ): AssociationApi {
    return new AgreementContractInternalContactsApi(apiHelper);
  }

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public filter = (params: any): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint(params.contractId) }/internal/filter`, { body: params }).pipe(map((list) => {
      return list.map((el) => {
        el.contactName = `${el.firstName} ${el.lastName}`;
        return el;
      });
    }));
  }

  public findAll = (contractId: number): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint(contractId) }/internal`);
  }

  public save = (data: any): Observable<any> => {
    /* tslint:disable:no-param-reassign */
    if (_.isArray(data) && data.length) { data = data[0]; }
    /* tslint:enable:no-param-reassign */
    return this.apiHelper.request(`${ this.endpoint(data.contractId) }/internal/save`, { body: data.contactList });
  }

  private endpoint = (id: number | string): string => {
    return `agreement/contract/${ id }/contact`;
  }
}
