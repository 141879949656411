import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';

import {ApiHelper} from 'cad/common/services/api/api-helper';
import {NominationConfirmationLocationApi} from 'cad/common/services/api/nomination/confirmation/location/location';
import {
  NominationConfirmationPinnedLocationApi
} from 'cad/common/services/api/nomination/confirmation/pinned-location/pinned-location';
import {
  ConfirmationHistoryListData
} from 'src/cad/nominations/confirmation/confirmation-matrix/confirmation-history-matrix/shared/confirmation-history-list-data.model';
import {ConfirmationActions} from 'cad/nominations/confirmation/shared/enums';
import {
  ConfirmationListData
} from 'cad/nominations/confirmation/confirmation-matrix/shared/confirmation-list-data.model';
import {MatrixHeaderAndList} from 'cad/common/components/matrix/model/matrix-header-and-list.model';
import {LockConfirmationHeader} from 'cad/nominations/common/models/lock-confirmation-header';
import {LockConfirmationCycle} from 'cad/nominations/common/models/lock-confirmation-cycle';
import {LockAllConfirmationHeader} from 'cad/nominations/common/models/lock-all-confirmation-header';
import ValidationResult = cad.ValidationResult;
import {ConfirmationHeaderData} from 'cad/nominations/confirmation/shared/confirmation-header-data.model';
import {ShutInConfirmationHeader} from 'cad/nominations/common/models/shut-in-confirmation-header';
import {ShutInConfirmation} from 'cad/nominations/common/models/shut-in-confirmation';


@Injectable()
export class NominationConfirmationApi {

  private readonly endpoint: string = 'nomination/confirmation';
  private readonly savePath: string = '/saveconfirmations';
  private readonly deletePath: string = '/deleteconfirmation';

  constructor(
    public location: NominationConfirmationLocationApi,
    public pinnedLocation: NominationConfirmationPinnedLocationApi,
    private apiHelper: ApiHelper) {}

  public getHeader = (params: any, type: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/header/' + type, { body: params });
  }

  public getHeaderAndList = (params: any, type?: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/header-and-list/' + type, { body: params });
  }

  public getHistory = (id: string | number): Observable<ConfirmationHistoryListData[]> => {
    return this.apiHelper.request(this.endpoint + '/history/' + id);
  }

  public getConfirmationType = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/getConfirmationType', { body: params });
  }

  public getDateRanges = (params: any, type: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/confirmation-date-ranges/' + type, { body: params });
  }

  public save = (params: any, type: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/save/' + type, { body: params });
  }

  public saveEDI(params: ConfirmationHeaderData): Observable<ValidationResult> {
    return this.apiHelper.request(this.endpoint + '/sendEDI', { body: params });
  }

  public validateEPSQLimits = (params: any, type: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/validate-epsq-limits/' + type, { body: params, quiet: true });
  }

  public getList = (params: any, type?: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/'+type, { body: params });
  }

  public getDateRange = (params: any, type?: any): Observable<any> => {
    let path = 'pointdates';
    return this.apiHelper.request(this.endpoint + '/'+type+'/'+path, { body: params });
  }

  public getCurrentCycle = (params: any): Observable<any> => {
    let path = 'currentcycle';
    return this.apiHelper.request(this.endpoint + '/'+path, { body: params });
  }

  public action = (params: any, action: any): Observable<any> => {
    let path = action === ConfirmationActions.Delete ? this.deletePath : this.savePath;
    return this.apiHelper.request(this.endpoint + path, { body: params });
  }

  public resetPreConfirmation(params: ConfirmationListData, type: string): Observable<ValidationResult> {
    let path = '/deletePreConfirmation/' + type;
    return this.apiHelper.request(this.endpoint + path, { body: params });
  }

  public getLockConfirmation(params: ConfirmationListData): Observable<MatrixHeaderAndList<LockConfirmationHeader,LockConfirmationCycle>> {
    return this.apiHelper.request(this.endpoint+'/get-lock', { body: params });
  }

  public getLockAllConfirmation(params: ConfirmationListData[]): Observable<MatrixHeaderAndList<LockAllConfirmationHeader,ConfirmationListData>> {
    return this.apiHelper.request(this.endpoint+'/get-point-lock', { body: params });
  }

  public saveLockConfirmation(params: MatrixHeaderAndList<LockConfirmationHeader, LockConfirmationCycle>):
      Observable<ValidationResult<MatrixHeaderAndList<LockConfirmationHeader, LockConfirmationCycle>>> {
    return this.apiHelper.request(this.endpoint + '/lock/save', {body: params});
  }

  public saveLockAllConfirmation(params: MatrixHeaderAndList<LockAllConfirmationHeader, ConfirmationListData>):
      Observable<ValidationResult<MatrixHeaderAndList<LockAllConfirmationHeader, ConfirmationListData>>> {
    return this.apiHelper.request(this.endpoint + '/lock-all', {body: params});
  }
  public getShutInConfirmation(params: ShutInConfirmationHeader): Observable<ShutInConfirmation> {
    return this.apiHelper.request(this.endpoint+'/get-point-shut-in', { body: params });
  }

  public saveShutInConfirmation(params: ShutInConfirmation): Observable<ValidationResult<ShutInConfirmation>> {
    return this.apiHelper.request(this.endpoint+'/point-shut-in/save', { body: params });
  }

}
