/**
 * @author Amar Tsolmon
 * @description Constants retrieved from MPlus's 'WorkflowConstants' interface
 * omitting the exception mappings.
 */
export let WORKFLOW_CONSTANTS = {
  /** From MPlus's 'WorkflowConstants' interface **/
  STATUS_SUCCESS: 'SUCCESS',
  STATUS_SCHEDULED: 'SCHEDULED',
  STATUS_FAILED: 'FAILED',
  STATUS_FAILED_VALIDATION: 'FAILED VALIDATION',
  STATUS_CANCELLED: 'CANCELLED',
  STATUS_EXECUTING: 'EXECUTING',
  STATUS_DUPLICATE: 'DUPLICATE',
  STATUS_SHUTDOWN: 'SHUTDOWN',
  // the workflow is in the message queue...waiting to be executed
  STATUS_PENDING: 'PENDING',
  // the workflow is about to be sent to the message queue
  STATUS_EXECUTING_ASYNCHRONOUS_ACTION: 'Executing asynchronous action',
  // a workflow is stopped when a next step can not be executed because the
  // action of the current step is not completed.
  STATUS_STOPPED_BECAUSE_VALID_CONNECTOR_NOT_FOUND: 'STOPPED BECAUSE VALID CONNECTOR IS NOT FOUND.'
};
