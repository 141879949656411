import { Controller } from './controller';

/* tslint:disable:only-arrow-functions ter-prefer-arrow-callback no-param-reassign no-invalid-this */
export function Action(): Function {
  return function (target: Function, propertyKey: string, descriptor: PropertyDescriptor): PropertyDescriptor {
    if (descriptor === undefined) {
      descriptor = Object.getOwnPropertyDescriptor(target, propertyKey);
    }

    const originalMethod = descriptor.value;

    descriptor.value = function(): void {
      const args = [];
      const controller: Controller<any, any> = this as Controller<any, any>;

      for (let _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
      }

      originalMethod.apply(controller, args);

      controller.markForCheck();
    };

    return descriptor;
  };
}
/* tslint:enable:only-arrow-functions ter-prefer-arrow-callback no-param-reassign no-invalid-this */
