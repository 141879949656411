
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Directive, Input, forwardRef } from '@angular/core';
import { AbstractControl, NG_ASYNC_VALIDATORS, Validator } from '@angular/forms';
import { CustomValidationObject } from 'src/common/components/form/mat-form-field/custom-validation-object';
import { AgreementContractAssignmentApi } from 'cad/common/services/api/agreement/contract/assignment/assignment';

export interface AbstractControlWarn extends AbstractControl {
  warnings: any;
}

export interface CreditWorthinessValidatorConfig {
  showAsWarning: boolean;
}

@Directive({
  selector: '[isCreditWorthy]',
  providers: [ {provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => CreditWorthinessValidatorDirective), multi: true} ],
})
export class CreditWorthinessValidatorDirective implements Validator {
  
  @Input('isCreditWorthy') config: CreditWorthinessValidatorConfig;
  
  constructor(public api: AgreementContractAssignmentApi) {}
  
  validate(c: AbstractControlWarn): Observable<CustomValidationObject | null> {
    if (typeof c.value === 'string') {
      return this.api.isCreditWorthy(c.value).pipe(map((isCreditWorthy: boolean) => {
        if (isCreditWorthy) {
          delete c.warnings;
          return null;
        } else {
          if (this.config.showAsWarning) {
            c.warnings = {
              creditWorthiness: {
                valid: false,
                message: 'Service requester is not credit worthy',
              }
            };
            return null;
          }
          return {
            creditWorthiness: {
              valid: false,
              message: 'Service requester is not credit worthy',
            }
          };
        }
      }),
      catchError((err: any) => {
        console.error('Error received while validating Credit Worthiness: ', err);
        return observableOf(null);
      }),);
    } else {
      return observableOf(null);
    }
  }
}
