import { ControlValueAccessor } from '@angular/forms';

const noop = () => {};

export class UIControlValueAccessor implements ControlValueAccessor {

  // Placeholders for the callbacks which are later provided by the Control Value Accessor
  protected onTouchedCallback: () => void = noop;
  protected onChangeCallback: (_: any) => void = noop;

  protected _model: any  = '';
  protected disabled: boolean;

  // get accessor
  get model(): any {
    return this._model;
  }

  // set accessor including call the onchange callback
  set model(v: any) {
    if (v !== this._model) {
      this._model = v;
      this.fireOnCallBack(v);
    }
  }

  // Set touched on blur
  onBlur(): void {
    this.onTouchedCallback();
  }

  //method created just to allow override in child.
  fireOnCallBack(value: any): void {
    this.onChangeCallback(value);
  }

  // From ControlValueAccessor interface
  writeValue(value: any): void {
    if (value !== this._model) {
      this._model = value;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  // From ControlValueAccessor interface
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
