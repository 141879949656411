import { Type, Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { ItemApi } from './item-api';

@Injectable()
export class ItemApiConfig {
  /**
   * @see ItemModel.endpoint
   */
  endpoint?: string;

  /**
   * @see ItemModel.primaryKey
   */
  primaryKey?: string;

  /**
   * @see ItemModel.compositeKey
   */
  compositeKey?: string[];

  /**
   * @see ItemModel.primaryKeyAsObject
   */
  primaryKeyAsObject?: boolean;

  /**
   * @see ItemModel.excludePKProperties
   */
  excludePKProperties?: string[];
}

export interface ItemModel {
  /**
   * Humanized name of item
   */
  name?: string;

  /**
   * String name of API service
   */
  api?: Type<ItemApi>;

  /**
   *
   */
  apiMixin?: Function;

  /**
   * URL of endpoint
   */
  endpoint?: string;

  detailRoot?: string;

  detailPath?: string;

  /**
   * Primary key field name of item data
   */
  /**
   * @description Primary key field name of item data.
   * Used when the primary key of the item data consists of a single value.
   * @example primaryKey: 'pointCd'
   */
  primaryKey?: string;

  /**
   * @description Array of field names that make up composite key.
   * Used when the primary key of the item data consists of 2 to 4 small text based entries
   * but anything more than that, ie dates, will pollute the URL use {@link ItemModel.primaryKeyAsObject}
   * in that case.
   * When using compositeKey the URL will be populated with route parameters that matches the supplied
   * compositeKey items.
   * @example compositeKey: [ 'assetNbr', 'id' ]
   * Make sure to update your module route path to match the compositeKey entries:
   * path: ':assetNbr/:id'
   */
  compositeKey?: string[];

  /**
   * @description Sets a flag that instructs services to use an object as item data's primary key.
   * Used when item data's primary key consists of the whole or part of item data. Use this if
   * the compositeKey gets too big or has dates in it.
   * Unlike compositeKey, when using primary key as object the URL will be populated with only 1 route parameter
   * which is the {@link ItemModel.primaryKey}
   * @example primaryKeyAsObject: true
   */
  primaryKeyAsObject?: boolean;

  /**
   * @description List of item data property names to exclude when using the primary key as an object.
   * @default [ 'dirtyStatus', 'modifiedFields' ]
   * @example excludePKProperties: [ 'historyDataList', 'transactionDataList' ]
   */
  excludePKProperties?: string[];

  /**
   * Default properties used when creating a new item
   */
  defaults?: Function;

  /**
   *
   */
  defaultSearchParams?: any;

  /**
   * Begin searching immediately when defaults are populated
   *
   */
  autoSearch?: boolean;

  allowEmptySearchParams?: boolean;
  
  suppressHorizontalScroll?: boolean;

  /**
   *
   */
  layout?: any;

  /**
   *
   */
  dashboardFilter?: any;

  /**
   *
   */
  searchConstants?: any;

  /**
   *
   */
  detailAsNew?: boolean;

  /**
   *
   */
  itemNewMixins?: any[];

  /**
   *
   */
  itemMixins?: any[];

  /**
   *
   */
  searchChipTemplate?: string;

  /**
   *
   */
  showSearchByDefault?: boolean;

  /**
   *
   */
  searchRowDetailApi?: any;

  /**
   *
   */
  searchRowDetailKey?: string;

  /**
   * Set this to true if you don't want your
   * modals to be placed in declarations and
   * entry components by framing.  This means
   * you will need to do this yourself.
   */
  preCompiledModals?: boolean;

  /**
   * New item modal config
   */
  newModalConfig?: MatDialogConfig;

  /**
   * Set this to true if you want to turn on the expandable
   * feature on the recent items card
   */
  enableContentExpand?: boolean;

  /**
   *  only enable save when form is dirty
   */
  enableSaveOnFormDirty?: boolean;

  /**
   *  Show Info, Warning messages on successful save
   */
  showAllMessagesOnSave?: boolean;
}
