import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { map } from 'rxjs/operators';
import { NomPointData } from 'cad/common/services/api/nomination/nomination-matrix/contract/point/nom-point-data';

@Injectable()
export class NominationMatrixContractPointApi {

  private readonly endpoint: string = 'nomination/nomination-matrix/contract/point';

  constructor(private apiHelper: ApiHelper) {
  }

  public filter(params: Partial<NomPointData>): Observable<NomPointData[]> {

    const pointTypeCdFilter = (queryResults: NomPointData[]): NomPointData[] =>
      queryResults && queryResults.length > 0 && params && params.pointTypeCd ?
        queryResults.filter((r) => r.pointTypeCd === params.pointTypeCd): queryResults;

    const countyNameFilter = (queryResults: NomPointData[]): NomPointData[] =>
      queryResults && queryResults.length > 0 && params && params['countyCV.value'] ?
        queryResults.filter((r) => r.countyCV.value === params['countyCV.value'].replace('%','').toUpperCase()): queryResults;

    const stateFilter = (queryResults: NomPointData[]): NomPointData[] =>
      queryResults && queryResults.length > 0 && params && params['stateCV.value'] ?
        queryResults.filter((r) =>  [ r.stateCV.value.toUpperCase(), r.stateCV.cd.toUpperCase() ].includes(params['stateCV.value'].replace('%','').toUpperCase())): queryResults;

    return this.apiHelper.request(this.endpoint + '/filter/' + params.contractId, { body: params })
      .pipe(map((results) => stateFilter(pointTypeCdFilter(countyNameFilter(results)))));
  }
}


