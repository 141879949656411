import { NgForm, NgModel } from '@angular/forms';
import { UiFormComponent } from 'common/components/form/form.component';

/**
 * This inteface is implemented by the UiForm component and allows the [uiInput] directive to register itself
 * with the form at runtime (see src/common/components/form/input/input.directive.ts for more info).  The implementation
 * of this method should add a given NgModel to an NgForm.
 */
export interface UiForm {
  addControl(model: NgModel): void;
  removeControl(model: NgModel): void;
}

/**
 * This abstract class is the second half of a solution to the problem of form controls not being added to parent forms
 * when those form controls are in projected content (<ng-content></ng-content>) or added dynamically (ngComponentOutlet or ngFramingOutlet).
 * see the comments in src/common/components/form/input/input.directive.ts for more a more detailed explanation of this problem
 *
 * If a new component is added which has a form whose controls are projected or added dynamically, that component will need to
 * extend UiFormParent and implement a method that returns an object implementing the UiForm interface in order to allow the children
 * form controls to be added to the UiFormComponent.
 *
 * In order for our new form component to make itself available to the [uiInput] directives that are trying to inject it, it must provide
 * itself on their injector.  This can be done with ReflectiveInjector.resolveAndCreate()
 *
 *  We should be using StaticInjector 5/9/2018
 *
 * Example:
 *    ReflectiveInjector.resolveAndCreate(..Provider Here..., ...injector to add it to...);
 *
 * Framing assigns an injector to each controller, so you will have access to the injector in order to provide the component.  In the below
 * example, you can see a how you can create an form component whose partial belongs to a framing "item".
 *
 * Example:
 *
 *    @Component({
 *      selector: 'test-form',
 *      templateUrl: `
 *        <ui-form>
 *          <ng-container
 *            [framingComponentOutlet]="itemController.view.form"
 *            [framingComponentInjector]="itemController.injector">
 *          </ng-container>
 *        </ui-form>
 *      `,
 *    })
 *    export class TestFormComponent extends UiFormParent {
 *
 *      // get a reference to the UiFormComponent
 *      @ViewChild(UiFormComponent, { static: false }) public uiFormComponent: UiFormComponent;
 *      public createInjector: Injector;
 *
 *      constructor(
 *        private injector: Injector,
 *        private itemController: ItemController,
 *      ) {
 *        super();
 *
 *        // this is how you provide your UiForm implementing component to the relevant injector
 *        // here we are adding TestFormComponent to the 'item' injector
 *        // see https://angular.io/docs/ts/latest/api/core/index/ReflectiveInjector-class.html
 *        this.createInjector = ReflectiveInjector.resolveAndCreate(
 *          // set up provider for TestFormComponent in itemController.injector
 *          [
 *            {
 *              provide: UiFormParent,
 *              useFactory: () => injector.get(TestFormComponent),
 *            },
 *          ],
 *          // injector for the owner of the partial
 *          this.itemController.injector
 *        );
 *      }
 *
 *      pulbic get uiForm(): UiForm {
 *        return this.uiFormComponent;
 *      }
 *    }
 *
 * Now that our TestFormComponent has added itself to itemController's injector itemController.view.form will have
 * access to TestFormComponent and it's constituent form controls will be able to use the addControl method on the
 * uiFormComponent in order to register themselves with the form.
 *
 *    Actual partial:
 *
 *    @Component({
 *      selector: 'item-form-partial',
 *      template: `
 *        <input uiInput name="test" [(ngModel)]="test" />
 *      `,
 *    })
 *    export class ItemFormComponent extends BaseComponent {
 *      public constructor(injector: Injector) { super(injector); }
 *    }
 *
 */
export abstract class UiFormParent {
  public canSubmit: boolean = true;

  public abstract get uiForm(): UiForm;
}
