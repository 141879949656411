import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import {
  SummaryImbalanceSearchFilterData
} from 'cad/flowing-gas/imbalance/ship-imbal/summary-imbalances/shared/interfaces/summary-imbalance-search-filter-data';
import {
  SummaryImbalanceData
} from 'cad/flowing-gas/imbalance/ship-imbal/summary-imbalances/shared/interfaces/summary-imbalance-data';
import {
  DailyImbalance
} from 'cad/flowing-gas/imbalance/ship-imbal/summary-imbalances/shared/interfaces/daily-imbalance';
import {
  ImbalanceDailyDetail
} from 'cad/flowing-gas/imbalance/ship-imbal/summary-imbalances/shared/interfaces/imbalance-daily-detail';
import {
  DailyAllocationBalanceDetail
} from 'cad/flowing-gas/imbalance/ship-imbal/summary-imbalances/shared/interfaces/daily-allocation-balance-detail';

@Injectable()
export class ShipperImbalanceSummaryApi {

  private readonly endpoint: string = 'flowing-gas/imbalance/shipper-imbalance-summary';

  constructor(
    private apiHelper: ApiHelper) {}

  public getAll(params: SummaryImbalanceSearchFilterData): Observable<SummaryImbalanceData[]> {
    return this.apiHelper.request(this.endpoint + '/all', { body: params });
  }

  public getByBA (params: SummaryImbalanceSearchFilterData): Observable<SummaryImbalanceData[]>  {
    return this.apiHelper.request(this.endpoint + '/ba', { body: params });
  }

  public getByContract (params: SummaryImbalanceSearchFilterData): Observable<SummaryImbalanceData[]>  {
    return this.apiHelper.request(this.endpoint + '/noms', { body: params });
  }
  
  public getDailyImbalance(params:SummaryImbalanceData): Observable<DailyImbalance[]> {
    return this.apiHelper.request(this.endpoint + '/contract/daily-imbalances', { body: params });
  }

  public getImbalanceDailyDetails(params:SummaryImbalanceData): Observable<ImbalanceDailyDetail[]> {
    return this.apiHelper.request(this.endpoint + '/contract/daily-imbalances-detail', { body: params });
  }

  public getImbalancesSummary(params:SummaryImbalanceData): Observable<DailyAllocationBalanceDetail[]> {
    return this.apiHelper.request(this.endpoint + '/contract/imbalances-summary', { body: params });
  }

}
