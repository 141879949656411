import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { FeatureComponent } from 'src/framing/feature.component';

import { CadAppController } from '../../cad-app.controller';
import { CadAppModel } from '../../cad-app.model';
import { CadAppView } from '../../cad-app.view';

@Component({
  templateUrl: './app.component.html',
  styleUrls: [ './app.less' ],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent extends FeatureComponent<CadAppModel, CadAppView, CadAppController> {
  constructor(controller: CadAppController, injector: Injector) { super(controller, injector); }
}
