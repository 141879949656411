
import {map} from 'rxjs/operators';
import { ItemApi } from './../../../../../../features/common/item/item-api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AssociateContactApi } from 'cad/common/services/api/associate/contact/contact';

@Injectable()
export class GlobalContactUpdateApi extends ItemApi {

  public endpoint: string = 'associate/contact-combine';

  constructor(
    private associateContactApi: AssociateContactApi,
    private apiHelper: ApiHelper,
  ) {
    super();
  }

  // ========================================
  // implementation
  // ========================================

  public getById = (id: string | number): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/' + id).pipe(map((resp) => {
      resp.contactCombineDetailData = this.mergeDefaultStruct(resp.contactCombineDetailData);
      return resp;
    }));
  }

  public find(params: any): Observable<any> {
    return this.getById(params.combineContactId);
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/dashboard');
  }

  public save(contact: any): Observable<any> {
    let cfg = { body: contact };
    if (contact.combineContactId) {
      return this.apiHelper.request(this.endpoint + '/' + contact.combineContactId, cfg);
    }
    return this.apiHelper.request(this.endpoint , cfg);
  }

  public cancel = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/cancel', { body: params });
  }

  public lookup = (params: any): Observable<any> => {
    return this.associateContactApi.filter(params);
  }

  public deleteItem(item: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/delete', { body: item });
  }

  private mergeDefaultStruct (data: any): any {
    return _.merge(data, {
      ccaList: [],
      ccBAList: [],
      ccCtList: [],
      ccPtList: [],
      ccTrList: [],
      ccCirList: [],
      ccCerList: [],
      ccPirList: [],
      ccPerList: [],
    });
  }
}
