import { Directive, OnInit } from '@angular/core';
import { TableColumnComponent } from 'src/ag-grid-wrapper/column/column.component';
import * as _ from 'lodash';
import { IntegerNumberEditorComponent } from './integer-number-editor.component';

@Directive({
  selector: 'ui-table-column[integerNumber]'
})
export class IntegerNumberDirective implements OnInit {

  constructor(public tableColumn: TableColumnComponent) {}

  ngOnInit(): void {
    if (this.tableColumn.type !== 'number' && this.tableColumn.type !== 'numberStr') {
      this.tableColumn.type = 'number';
    }
    this.tableColumn.customEditor = IntegerNumberEditorComponent;
    this.tableColumn.valueGetter = (params: any): string => {
      let val: any;
      if (params && params.data) {
        val = _.get(params.data, params.colDef.field);
      } else if (params && !_.isNil(params.value)) {
        val = params.value;
      }
      return val;
    };
  }
}
