
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DashboardConfig } from 'src/cad/common/services/dashboard/dashboard-config.model';
import { DASHBOARD_CONFIG } from 'src/cad/dashboard/views/content/dashboard-config';
import { DashboardModel } from '../../models/dashboard/dashboard-model';
import { UserDetail } from '../../models/user/user-details-model';
import { UserStoreService } from '../../store/core/services/user-store.service';
import { AuthService } from '../auth/auth';

@Injectable()
export class DashboardService {
  private subscriptions: Subscription[] = [];
  private dashboardItems: DashboardConfig[] = DASHBOARD_CONFIG;

  constructor(
    private userStateStore: UserStoreService,
    private dashboardModel: DashboardModel,
    private authService: AuthService,
  ) {
    this.subscriptions.push(this.userStateStore.stateChanged.pipe(
        filter((userDetail) => userDetail.state.user))
        .subscribe((userDetail) => {
          this.onUserContext(userDetail.state.user);
        }));
    this.dashboardModel.dashboardItemsSubject.next(this.dashboardItems);
  }

  private onUserContext(userDetail: UserDetail): void {
    this.updateDashboard(userDetail);
    this.dashboardModel.dashboardItemsSubject.next(this.dashboardItems);
  }

  private updateDashboard = (userDetail: UserDetail): void => {
    this.updateDashboardItems(this.dashboardItems, userDetail);
  }

  private updateDashboardItems = (dashboardItems: any, userDetail: UserDetail): void => {
    dashboardItems.forEach((dashboardItem) => {
      if (dashboardItem.auth && userDetail) {
        dashboardItem.authInfo = {
          auth: this.authService.getAuthorizationForAction(userDetail.currentContext, dashboardItem.auth),
        };
        dashboardItem.enabled = dashboardItem.authInfo.auth.enabled;
        dashboardItem.visible = dashboardItem.authInfo.auth.visible;
      } else {
        dashboardItem.enabled = false;
        dashboardItem.visible = false;
      }
    });
  }
}

