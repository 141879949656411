import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { RouterService } from 'src/cad/core/services/router/router.service';

@Component({
  selector: 'ui-float-button',
  templateUrl: './float-button.component.html',
  styleUrls: [ './float-button.component.less' ],
})
export class UiFloatButtonComponent implements OnInit, OnDestroy {
  @Input() public iconName: string = 'add';
  @Input() public mainTooltip: string;
  @Input() public btnClick: Function;
  @Input() public stateGo: any;
  @Input() public actionItems: any;
  @Input() public direction: string = 'left'; // left, right, up, down
  @Input() public enableHover: boolean = true;
  @Input() public enableFullHover: boolean = true;
  @Input() public toolTipPosition: string = 'above'; //above, below, left, right
  @Input() public tooltipsVisible: boolean = false;
  @Input() public alwaysOpen: boolean = false;

  @Input()
  set isDisabled(isDisabled: boolean) {
    this._isDisabled = isDisabled;
    this.isDisabledChanged(this._isDisabled);
  }
  get isDisabled(): boolean { return this._isDisabled; }

  @Input()
  set isOpen(isOpen: boolean) {
    this._isOpen = isOpen;
    this.isOpenChanged(this._isOpen);
  }
  get isOpen(): boolean { return this._isOpen; }

  @Output() public onFunc: EventEmitter<Function> = new EventEmitter();
  public tooltipVisible: boolean;
  private _isOpen: boolean = false;
  private _isDisabled: boolean = false;
  private isOpenTimer: any;
  private initTimer: any;
  private openActionItemsTimer: any;
  private tooltipVisibleTimer: any;

  public constructor(
    private router: RouterService,
  ) {}

  // ====================================
  // implementation
  // ====================================

  public ngOnInit(): void {
    if (!this.mainTooltip) {
      this.mainTooltip = _.startCase(this.iconName);
    }

    if (this.actionItems) {
      this.isOpen = !this.isDisabled && this.alwaysOpen;
    }
  }

  public emitFuncCall(func: any): void {
    this.onFunc.emit(func);
  }

  public openActionItems(): void {
    if (this.isOpenTimer) {
      clearTimeout(this.isOpenTimer);
    }
    this.isOpenTimer = setTimeout(() => {
      this.isOpen = true;
    }, 800);
  }

  public isDisabledChanged(isDisabled: boolean): void {
    if (!this.isDisabled) {
      if (this.alwaysOpen) {
        this.openActionItems();
      }
    }
  }

  public areAnyActionsEnabled(): boolean {
    if (!this.actionItems) {
      return true;
    }
    for ( let item of this.actionItems) {
      if (item.enabled !== false) {
        return true;
      }
    }
    return false;
  }

  public isOpenChanged(isOpen: boolean): void {
    if (isOpen) {
      if (this.tooltipVisibleTimer) {
        clearTimeout(this.tooltipVisibleTimer);
      }
      this.tooltipVisibleTimer = setTimeout(() => {
        this.tooltipVisible = this.isOpen && this.tooltipsVisible;
      }, 800);
    } else if (this.alwaysOpen && !this.isOpen && !this.isDisabled) {
      if (this.openActionItemsTimer) {
        clearTimeout(this.openActionItemsTimer);
      }
      this.isOpen = false;
      this.openActionItemsTimer = _.defer(this.openActionItems);
    } else {
      this.tooltipVisible = this.isOpen;
    }
  }

  public mouseEnter(): void {
    if (this.enableHover && !this.isOpen) {
      this.isOpen = true;
    }
  }

  public mouseExit(): void {
    if (this.enableHover && !this.alwaysOpen) {
      this.isOpen = false;
    }
  }

  public clicked(): void {
    if (_.isEmpty(this.actionItems)) {
      if (this.stateGo) {
        let params = this.stateGo[1] || {};
        this.router.navigate([ this.stateGo[0] ], params); // ?????
      } else if (typeof this.btnClick === 'function') {
        this.btnClick();
      }
    }
  }

  public ngOnDestroy(): void {
    if (this.initTimer) {
      clearTimeout(this.initTimer);
    }
    if (this.isOpenTimer) {
      clearTimeout(this.isOpenTimer);
    }
    if (this.tooltipVisibleTimer) {
      clearTimeout(this.tooltipVisibleTimer);
    }
    if (this.openActionItemsTimer) {
      clearTimeout(this.openActionItemsTimer);
    }
    if (this.btnClick) {
      this.btnClick = null;
    }
    if (this.actionItems) {
      this.actionItems = null;
    }
    if (this.isDisabled) {
      this.isDisabled = null;
    }
    if (this.stateGo) {
      this.stateGo = null;
    }
    if (this.enableHover) {
      this.enableHover = null;
    }
    if (this.enableFullHover) {
      this.enableFullHover = null;
    }
    if (this.tooltipsVisible) {
      this.tooltipsVisible = null;
    }
    if (this.toolTipPosition) {
      this.toolTipPosition = null;
    }
    if (this.alwaysOpen) {
      this.alwaysOpen = null;
    }
  }
}
