/**
 * Created by jrayavarap on 5/2/2018.
 */

import {Injectable} from '@angular/core';
import {ApiHelper} from 'src/cad/common/services/api/api-helper';
import {Observable} from 'rxjs';
@Injectable()
export class StorageBalancesApi {

  private readonly endpoint: string = 'flowing-gas/storage-balances';

  constructor(
    private apiHelper: ApiHelper) { }

  public getHeader = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/header', { body: params});
  }

  public getHeaderAndList = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/header-and-list/', { body: params });
  }

}
