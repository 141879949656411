import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ui-fullscreen-footer',
  template: '<div class="ui-fullscreen-footer"><ng-content></ng-content></div>',
  styleUrls: [ './fullscreen-footer.component.less' ],
})
export class UiFullscreenFooterComponent {
  @Output() public onClose: EventEmitter<any> = new EventEmitter<any>();

  public close(): void {
    this.onClose.emit();
  }
}
