<div>
  <button *ngIf="uiProcessStatusService && uiProcessStatusService.isOpen"
          mat-button
          matTooltip="Active Process Status"
          (click)="toggleProcessStatusPopup()"
          aria-label="Active Process Status">
    <mat-icon [ngClass]="{
    'success-status-color': isSuccessStatus,
    'warning-status-color': isWarningStatus,
    'failed-status-color': isFailedStatus
    }">{{statusIcon}}
    </mat-icon>
  </button>
</div>
