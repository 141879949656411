import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: [ './modal-header.component.less' ],
})
export class UiModalHeaderComponent {
  @Input() public onClose: any;
  @Input() public allowDrag: boolean;
}
