import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class AssociateContactAssetBaApi {

  public endpoint: string = 'associate/contact/';
  public suffix: string = '/asset/ba';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  // TODO: Update endpoint to use findALL method on the server-side
  public findAll = (contactId: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + contactId + this.suffix + '/filter');
  }

  public findAssetBaPurposeCodes = (
    contactId: string | number,
    baId: string | number,
    assetId: string | number,
  ): Observable<any> => {
    return this.apiHelper.request(this.endpoint + contactId + '/asset/' + assetId + '/ba/' + baId + '/purposeCodes');
  }

  public savePurposeCodes = (
    contactId: string | number,
    baId: string | number,
    assetId: string | number,
    purposeCodes: any,
  ): Observable<any> => {
    return this.apiHelper.request(
      this.endpoint + contactId +
      '/asset/' + assetId +
      '/ba/' + baId +
      '/purposeCodes',
      { body: purposeCodes },
    );
  }

  public save = (assetBaContacts: any[]): Observable<any> => {
    return this.apiHelper.request(this.endpoint + assetBaContacts[0].contactId + this.suffix, { body: assetBaContacts });
  }
}
