import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkflowBaseApi } from 'cad/common/services/api/workflow/workflow-base/workflow-base';
import WorkflowExecutionRequestData = mplus.WorkflowExecutionRequestData;

@Injectable()
export class ScheduleAndViewReportApi extends WorkflowBaseApi {

  public filter (params: any): Observable<WorkflowExecutionRequestData[]>  {
    return super.filter(params, 'reporting');
  }

  public recent (): Observable<WorkflowExecutionRequestData[]>  {
    return super.recent('reporting');
  }
}
