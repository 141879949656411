import { Injectable } from '@angular/core';
import { ApiHelper } from '../../api-helper';
import { Observable } from 'rxjs';
import { ContractAllocation } from 'src/cad/invoicing/contract-allocation/contract-allocation';

@Injectable()
export class ContractAllocationApi {
  private readonly endpoint: string = 'settlement/contractallocations';

  constructor (
    private apiHelper : ApiHelper ) {
  }

  public monthlyFilter(flowPeriod: string, accountingPeriod:string): Observable<ContractAllocation[]> {
    const reqObj = {
      flowPeriod, accountingPeriod
    };
    return this.apiHelper.request(this.endpoint + '/monthly', { body: reqObj });
  }

  public dailyFilter(contractId: number, flowPeriod: string, deliveryPointCd: string, receiptPointCd: string, accountingPeriod: string): Observable<ContractAllocation[]> {
    const reqObj = {
      contractId, flowPeriod, deliveryPointCd, receiptPointCd, accountingPeriod
    };
    return this.apiHelper.request(this.endpoint + '/daily', { body:reqObj });
  }

}
