<div class="flex-container" fxLayout="row" fxFill fxFlexFill>
  <cad-search
    fxFlex
    fxLayoutColumn
    searchLayout="table-lookup"
    [itemModel]="lookupController.model.targetModel"
    [itemView]="lookupController.model.targetView"
    [itemApi]="lookupController.targetApiService"
    [itemSearchForm]="lookupController.model.targetView.search"
    [itemSearchList]="lookupController.model.targetView.list"
    [lookupInjector]="lookupInjector"
    [showSelect]="false"
    (resultSelected)="onSelect($event)">
  </cad-search>
</div>
