
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import * as _ from 'lodash';

@Injectable()
export class RolesApi {

  private readonly endpoint: string = 'administration/roles';

  constructor(
    private apiHelper: ApiHelper
  ) {}

  public getById(id: number | string): Observable<any> {
    if (_.isNil(id)) {
      return observableOf({});
    }
    return this.apiHelper.request(this.endpoint + '/' + id);
  }

  public find(params: any): Observable<any> {
    return (params && !_.isNil(params)) ? this.getById(params.roleId) : observableOf({});
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public recent(): Observable<any> {
    // Dashboard/Recent does not apply to Roles
    return observableOf([]);
  }

  public save(params: any): Observable<any> {
    // Save does not apply to Roles
    return observableOf([]);
  }

  public deleteItem(params: any): Observable<any> {
    // Delete does not apply to Roles
    return observableOf([]);
  }

  public getRoleTreeNodeData(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/role-tree', { body: params });
  }

  public getUsersNode(roleId: number | string): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/${ roleId }/users-node`);
  }

  public getObjectsNode(roleId: number | string): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/${ roleId }/objects-node`);
  }

  public getGUINode(roleId: number | string): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/${ roleId }/gui-node`);
  }
}
