import { Directive, Input, OnInit } from '@angular/core';
import { TableColumnComponent } from 'src/ag-grid-wrapper/column/column.component';
import { TimeEditorComponent } from 'cad/shared/table-renderers/time/time-editor.component';
import * as _ from 'lodash';

@Directive({
  selector: 'ui-table-column[timeEditor]',
  providers: [ ]
})
export class TimeEditorDirective implements OnInit {

  @Input() public maskType: string = 'time';

  constructor(public tableColumn: TableColumnComponent) {}

  ngOnInit(): void {
    this.tableColumn.type = 'custom';
    this.tableColumn.customEditor = TimeEditorComponent;
    if (this.tableColumn && _.isNil(this.tableColumn.customCellConfig)) {
      this.tableColumn.customCellConfig = {
        maskType: this.maskType
      };
    }
    this.tableColumn.valueGetter = (params: any): string => {
      let val = _.get(params.data, params.colDef.field);
      return val;
    };
  }
}
