import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './button-alert.component.html',
  styleUrls: [ './button-alert.component.less' ],
})
export class ButtonAlertComponent implements OnInit {
  public resolvedData: any;
  constructor(public dialogRef: MatDialogRef<ButtonAlertComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  public ngOnInit (): void {
    this.resolvedData = this.data;
    _.defaults(this.resolvedData, {
      title: 'Alert',
      message: 'Specify a dialog-message in your ui-button',
      label: 'Alert',
      btnText: 'Ok',
    });
  }
}
