
import {finalize} from 'rxjs/operators';
import { Injectable, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable ,  Subscription ,  ReplaySubject } from 'rxjs';
import { AlertsService } from 'src/cad/core/services/alerts/alerts.service';
import { IPollModel, UiDataPollingService } from 'src/common/services/data-polling/data-polling.service';
import { UiFloatingContentService } from 'src/common/services/floating-content/floating-content.service';
import { UiProcessStatusComponent } from 'src/common/components/process-status/process-status.component';
import ValidationResult = cad.ValidationResult;
import ValidationResultMessage = cad.ValidationResultMessage;
import * as _ from 'lodash';

export interface IProcessStatusUpdateModel {
  statusName: string;
  statusDescription?: string;
  statusIconName?: string;
  statusIconClass?: string;
  statusTooltip?: string;
}

export interface IProcessStatusModel extends IProcessStatusUpdateModel {
  processName: string;
  processCall?: Observable<any>;
  onComplete?: (processResult: IProcessResultModel) => IProcessResultModel;
}

export interface IProcessResultModel extends ValidationResult {
  statusUpdateModel?: IProcessStatusUpdateModel;
}

export class ProcessStatusModel implements IProcessStatusModel {

  public processSubscription: Subscription;
  public pollingSubscription: Subscription;

  constructor(
    public processName: string,
    public statusName: string = 'EXECUTING',
    public processCall?: Observable<any>,
    public onComplete?: (processResult: IProcessResultModel) => IProcessResultModel,
    public statusDescription?: string,
    public statusIconName?: string,
    public statusIconClass?: string,
    public statusTooltip?: string
  ) {
    this.updateStatusMaps();
  }

  public updateStatusMaps(): void {
    if (this.statusName && _.isArray(cad.statusMap[ this.statusName ])) {
      if (_.isNil(this.statusIconName)) {
        this.statusIconName = _.first(cad.statusMap[ this.statusName ]);
      }
      if (_.isNil(this.statusIconClass)) {
        this.statusIconClass = _.last(cad.statusMap[ this.statusName ]);
      }
    }
    if (_.isNil(this.statusDescription)) {
      this.statusDescription = this.statusName;
    }
    if (_.isNil(this.statusTooltip)) {
      this.statusTooltip = this.statusDescription;
    }
  }

  public destroy(): void {
    if (this.processSubscription) {
      this.processSubscription.unsubscribe();
    }
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
  }
}

@Injectable()
export class UiProcessStatusService implements OnDestroy {

  public get processList$(): Observable<ProcessStatusModel[]> {
    return this._processListSubject.asObservable();
  }
  public matDialogRef: MatDialogRef<UiProcessStatusComponent>;
  public processList: ProcessStatusModel[] = [];
  public floatingContentPane: Element;
  public dialogContainerEl: Element;
  public isShowing: boolean = false;
  public isOpen: boolean = false;
  private renderer: Renderer2;
  private _processListSubject: ReplaySubject<ProcessStatusModel[]> = new ReplaySubject<ProcessStatusModel[]>(1);

  constructor(
    public alertsService: AlertsService,
    public uiDataPollingService: UiDataPollingService,
    public uiFloatingContentService: UiFloatingContentService,
    @Inject(DOCUMENT) private documentRef: Document
  ) {}

  ngOnDestroy(): void {
    this.clearAllProcesses();
    this._processListSubject.complete();
  }

  public toggle(): void {
    if (!this.isShowing) {
      this.show();
    } else {
      this.hide();
    }
  }

  public show(): void {
    if (!this.isShowing) {
      if (this.renderer && this.floatingContentPane) {
        this.renderer.removeClass(this.floatingContentPane, 'hidden');
      }
      this.isShowing = true;
    }
  }

  public hide(): void {
    if (this.isShowing) {
      if (this.renderer && this.floatingContentPane) {
        this.renderer.addClass(this.floatingContentPane, 'hidden');
      }
      this.isShowing = false;
    }
  }

  public dismiss(): void {
    this.isOpen = false;
    this.hide();
    this.floatingContentPane = null;
    this.dialogContainerEl = null;
    this.matDialogRef = null;
    this.renderer = null;
    if (this.uiFloatingContentService) {
      this.uiFloatingContentService.close();
    }
  }

  public addProcess(processStatusModel: IProcessStatusModel, pollingModel?: IPollModel): void {
    let createdProcess: ProcessStatusModel;

    if (!_.isNil(processStatusModel) && _.isArray(this.processList)) {
      if (!_.isNil(_.find(this.processList, { processName: processStatusModel.processName }))) {
        this.removeProcess(processStatusModel.processName);
      }
      this.processList.push(new ProcessStatusModel(
        processStatusModel.processName,
        processStatusModel.statusName,
        processStatusModel.processCall,
        processStatusModel.onComplete,
        processStatusModel.statusDescription,
        processStatusModel.statusIconName,
        processStatusModel.statusIconClass,
        processStatusModel.statusTooltip
      ));
      createdProcess = _.find(this.processList, { processName: processStatusModel.processName });
      this.openDialog();
      if (createdProcess) {
        if (!_.isNil(pollingModel) && this.uiDataPollingService) {
          pollingModel.pollId = processStatusModel.processName;
          createdProcess.pollingSubscription = this.uiDataPollingService.startPolling(pollingModel).pipe(
          finalize(() => {
            createdProcess.pollingSubscription.unsubscribe();
          }))
          .subscribe(
            (response: any) => {
              let processResult: IProcessResultModel = { successful: true, validatedObject: response };

              if (createdProcess.onComplete) {
                processResult = createdProcess.onComplete(processResult);
              }
              this.handleComplete(processResult, createdProcess.processName);
            },
            (err: any) => {
              this.handleError(err, createdProcess.processName);
            }
          );
        }
        createdProcess.processSubscription = createdProcess.processCall.pipe(
        finalize(() => {
          createdProcess.processSubscription.unsubscribe();
        }))
        .subscribe((result: any) => {
          // Do not end the polling when Api timeout exceeds, this will cause the long running process
          // to fail when 2 min timeout reaches.
          if (!(result
            && result.errorMessages
            && result.errorMessages.find((errorMsg: ValidationResultMessage) => errorMsg.message === 'Timeout exceeded'))) {
            let processResult: IProcessResultModel = createdProcess.onComplete
              ? createdProcess.onComplete(result)
              : result;
            this.handleComplete(processResult, createdProcess.processName);
            createdProcess.pollingSubscription.unsubscribe();
          }
        });
      }
    }
  }

  public removeProcess(processName: string): void {
    if (_.isArray(this.processList)) {
      this.processList.filter((processStatusModel: ProcessStatusModel) => {
        return processStatusModel.processName === processName;
      }).forEach((processStatusModel: ProcessStatusModel) => {
        if (this.uiDataPollingService) {
          this.uiDataPollingService.removePoll(processStatusModel.processName);
        }
        processStatusModel.destroy();
      });
      _.remove(this.processList, { processName });
      this.emitProcessList();
      if (this.processList.length <= 0) {
        this.dismiss();
      }
    }
  }

  public updateProcessStatus(processName: string, updateModel: IProcessStatusUpdateModel): void {
    if (_.isArray(this.processList)
      && !_.isNil(processName)
      && !_.isNil(updateModel)) {
      let processToUpdateIdx: number
        = _.findIndex(this.processList, { processName });

      if (processToUpdateIdx > -1) {
        _.assign(this.processList[ processToUpdateIdx ], updateModel);
        this.processList[ processToUpdateIdx ].updateStatusMaps();
        this.openDialog();
      }
    }
  }

  public clearAllProcesses(): void {
    if (_.isArray(this.processList)) {
      this.processList.forEach((processStatusModel: ProcessStatusModel) => {
        if (this.uiDataPollingService) {
          this.uiDataPollingService.removePoll(processStatusModel.processName);
        }
        processStatusModel.destroy();
      });
      this.processList.splice(0);
    }
    this.emitProcessList();
    this.dismiss();
  }

  public getSuccessProcesses(): ProcessStatusModel[] {
    return _.filter(this.processList, (processModel: ProcessStatusModel) => {
      return processModel.statusName === 'SUCCESS';
    });
  }

  public getWarningProcesses(): ProcessStatusModel[] {
    return _.filter(this.processList, (processModel: ProcessStatusModel) => {
      return processModel.statusName === 'WARNING' || processModel.statusName === 'CANCELLED';
    });
  }

  public getFailedProcesses(): ProcessStatusModel[] {
    return _.filter(this.processList, (processModel: ProcessStatusModel) => {
      return processModel.statusName !== 'SUCCESS'
        && processModel.statusName !== 'EXECUTING'
        && processModel.statusName !== 'CANCELLED'
        && processModel.statusName !== 'PENDING'
        && processModel.statusName !== 'WARNING'
        && processModel.statusName !== 'SCHEDULED';
    });
  }

  private openDialog(): void {
    if (!this.isOpen) {
      if (this.uiFloatingContentService) {
        this.isOpen = true;
        this.matDialogRef = this.uiFloatingContentService.open(UiProcessStatusComponent);
        if (this.matDialogRef) {
          let afterOpenSubscription: Subscription = this.matDialogRef.afterOpened().subscribe(() => {
            if (this.matDialogRef.componentInstance) {
              this.renderer = this.matDialogRef.componentInstance.renderer;
            }
            if (this.documentRef) {
              this.floatingContentPane = this.documentRef.querySelector('.ui-floating-content');
            }
            if (this.floatingContentPane) {
              this.dialogContainerEl = this.floatingContentPane.querySelector('.mat-dialog-container');
              if (this.renderer && this.dialogContainerEl) {
                this.renderer.setStyle(this.dialogContainerEl, 'height', 'auto');
                this.renderer.setStyle(this.dialogContainerEl, 'width', 'auto');
              }
            }
            afterOpenSubscription.unsubscribe();
          });
        }
      }
    }
    this.emitProcessList();
    this.show();
  }

  private emitProcessList(): void {
    this._processListSubject.next(_.cloneDeep(this.processList));
  }

  private handleComplete(resultModel: IProcessResultModel, processName: string): void {
    let isSuccessful: boolean = (resultModel && resultModel.successful);
    let updateModel: IProcessStatusUpdateModel;

    if (!_.isNil(processName)) {
      if (resultModel) {
        updateModel = resultModel.statusUpdateModel;
        if (!updateModel) {
          let hasWarningMessages: boolean =
            _.isArray(resultModel.warningMessages) && !_.isEmpty(resultModel.warningMessages);

          updateModel = {
            statusName: (isSuccessful
              ? (hasWarningMessages ? 'WARNING' : 'SUCCESS')
              : 'FAILED'),
            statusDescription: null,
            statusIconName: null,
            statusIconClass: null,
            statusTooltip: null
          };
        }
        this.updateProcessStatus(processName, updateModel);
      }
      if (this.alertsService) {
        this.alertsService.showCombinedResultAlerts(
          resultModel,
          `Execution has ${ isSuccessful ? 'completed' : 'failed'} for Process <b>${processName}</b>`
        );
      }
    }
  }

  private handleError(err: any, processName: string): void {
    let updateModel: IProcessStatusUpdateModel = {
      statusName: 'FAILED',
      statusDescription: null,
      statusIconName: null,
      statusIconClass: null,
      statusTooltip: null
    };

    this.updateProcessStatus(processName, updateModel);
    if (this.alertsService) {
      this.alertsService.showCombinedResultAlerts(
        { errorMessages: _.isArray(err)
          ? _.map(err, (error: any) => {
            return { message: error };
          })
          : [ { message: err } ]
        },
        `Error received during the execution of the Process <b>${processName}</b>`
      );
    }
  }
}
