import { Pipe, PipeTransform } from '@angular/core';
/*
ngFor does not allow you to iterate over a map.  This is to help you with such an operation.
THIS MAY GET FIXED IN A FUTURE RELEASE OF ANGULAR

Example usage:
 <ul>
   <li *ngFor="let keyValuePair of messages | mapToIterable">
     key={{keyValuePair.key}} value={{keyValuePair.value}}
   </li>
 </ul>

 */

@Pipe({ name: 'mapToIterable' })
export class MapToIterable implements PipeTransform {
  transform(map: Object): any[] {
    let outArray = [];
    for (let theKey in map) {
      if (map.hasOwnProperty(theKey)) {
        outArray.push({ key: theKey, value: map[theKey] });
      }
    }
    return outArray;
  }
}
