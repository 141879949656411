import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { NominationMatrixContractActivityCodeApi } from 'cad/common/services/api/nomination/nomination-matrix/contract/activity-code/activity-code';
import { NominationMatrixContractPointApi } from 'cad/common/services/api/nomination/nomination-matrix/contract/point/nomination-matrix-contract-point.api';

@Injectable()
export class NominationMatrixContractApi {

  private readonly endpoint: string = 'nomination/nomination-matrix/contract';

  constructor(
    public activityCode: NominationMatrixContractActivityCodeApi,
    public point: NominationMatrixContractPointApi,
    private apiHelper: ApiHelper,
  ) {}

  // search by serviceName or serviceId doesn't work with this endpoint
  public filter = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public getById = (id: number | string): Observable<any> => {
    let params = {};
    _.defaults(params, {
      contractId: id,
    });
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }
}
