
import {throwError as observableThrowError, timer as observableTimer, of as observableOf,  Observable ,  Subject } from 'rxjs';

import {finalize, timeout, first, takeUntil, tap, switchMap} from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import * as _ from 'lodash';

export interface IPollModel {
  pollId?: string;
  pollingCall?: Observable<any>;
  isComplete?: (polledData: any) => boolean;
  pollInterval?: number;
  pollTimeout?: number;
}

export class PollModel implements IPollModel {
  
  public pollCounter: number = 0;
  public get pollDestructor$(): Observable<any> {
    return this._pollDestructorSubject.asObservable();
  }
  private _pollDestructorSubject: Subject<any> = new Subject<any>();
  
  constructor(
    public pollId?: string,
    public pollingCall: Observable<any> = observableOf(null),
    public isComplete: (polledData: any) => boolean = (polledData: any): boolean => false,
    public pollInterval: number = 5000, // Defaults to 5 seconds
    public pollTimeout: number = 600000 // Defaults to 10 minutes
  ) {}
  
  public emitDestructor(emitValue?: any): void {
    this._pollDestructorSubject.next(emitValue);
  }
  
  public destroy(): void {
    this._pollDestructorSubject.complete();
  }
}

@Injectable()
export class UiDataPollingService implements OnDestroy {

  public pollModelList: PollModel[] = [];
  
  public ngOnDestroy(): void {
    this.stopAllPolls();
    this.removeAllPolls();
  }
  
  public getPollModel(pollId: string): PollModel {
    return _.find(this.pollModelList, { pollId }) || null;
  }
  
  public createPollingModel(pollingModel: IPollModel): PollModel {
    if (!_.isNil(pollingModel) && !_.isNil(pollingModel.pollId)) {
      this.removePoll(pollingModel.pollId);
      this.pollModelList.push(new PollModel(
        pollingModel.pollId,
        pollingModel.pollingCall,
        pollingModel.isComplete,
        pollingModel.pollInterval,
        pollingModel.pollTimeout
      ));
      return this.getPollModel(pollingModel.pollId);
    } else {
      return null;
    }
  }
  
  public removePoll(pollId: string): void {
    this.stopPolling(pollId);
    _.remove(this.pollModelList, { pollId });
  }
  
  public stopAllPolls(): void {
    this.pollModelList.forEach((pollModel: PollModel) => this.stopPolling(pollModel.pollId));
  }
  
  public removeAllPolls(): void {
    this.pollModelList.forEach((pollModel: PollModel) => this.removePoll(pollModel.pollId));
  }
  
  public startPolling(pollingModel: IPollModel): Observable<any> {
    let pollModel: PollModel = this.createPollingModel(pollingModel);
    
    if (!_.isNil(pollModel) && !_.isNil(pollModel.pollId)) {
      return pollModel.pollingCall.pipe(
      switchMap(() => observableTimer(pollModel.pollInterval, pollModel.pollInterval)), // Start the poll timer
      tap(() => pollModel.pollCounter++), // Increment poll counter
      switchMap(() => pollModel.pollingCall), // Do the polling call
      takeUntil(pollModel.pollDestructor$), // until pollDestructor$ is emitted
      first((response: any): boolean => pollModel.isComplete(response)), // Grab the first matched data that is completed
      timeout(pollModel.pollTimeout), // Emit error when the max wait time reaches
      finalize(() => pollModel.emitDestructor()),); // Destroy the polling regardless the outcome
    } else {
      return observableThrowError('Poll not created.');
    }
  }
  
  public stopPolling(pollId: string): void {
    let pollModel: PollModel = this.getPollModel(pollId);
  
    if (!_.isNil(pollModel)) {
      pollModel.destroy();
    }
  }
}
