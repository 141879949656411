import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { CommunicationApi } from 'cad/common/services/api/administration/communication/communication';
import { MessagesApi } from 'cad/common/services/api/administration/messages/messages';
import { UsersApi } from 'cad/common/services/api/administration/users/users';
import { AdminUtilitiesApi } from 'cad/common/services/api/administration/admin-utilities/admin-utilities';
import { TableMaintenanceCodesApi } from 'src/cad/common/services/api/administration/table-maintenance/codes/codes';
import { DeadlineExtensionApi } from 'src/cad/common/services/api/administration/deadline-extension/deadline-extension';

@Injectable()
export class AdministrationApi {
  constructor(
    public adminUtilitiesApi: AdminUtilitiesApi,
    public communication: CommunicationApi,
    public deadlineExtensionApi: DeadlineExtensionApi,
    public messages: MessagesApi,
    public tableMaintenanceCodesApi: TableMaintenanceCodesApi,
    public users: UsersApi,
    private apiHelper: ApiHelper,
  ) {}

  public queryCodes = (codeValue: any): Observable<any> => {
    return this.apiHelper.request('administration/maintenance/codes/filter', { body: { codeValue } });
  }

  public findCode = (codeValue: any): Observable<any> => {
    return this.apiHelper.request('administration/maintenance/codes/find', { body: { codeValue } });
  }

  public queryCodeTypes = (params: any): Observable<any> => {
    return this.apiHelper.request('administration/maintenance/codes/types', { body: params });
  }
}
