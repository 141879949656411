import { NgModule } from '@angular/core';
import { ContractContactsCommonComponent } from 'cad/common/components/contract-contacts-common/contract-contacts-common.component';
import { AppMaterialModule } from 'cad/app-material.module';
import { PipesModule } from 'cad/common/filters/pipes.module';
import { SharedModule } from 'cad/shared/shared.module';
import {ContractContactsComponent} from 'src/cad/common/components/contract-contacts/contract-contacts.component';

@NgModule({
  imports: [ AppMaterialModule, PipesModule, SharedModule ],
  exports: [ ContractContactsComponent ],
  declarations: [ ContractContactsComponent, ContractContactsCommonComponent ],
  providers: [],
})
export class ContractContactsCommonModule {
}
