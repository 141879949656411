
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ItemApi } from 'src/features/common/item/item-api';
import { ServiceComponentData } from 'src/cad/structured-products/shared/interfaces/service-component-data';
import { ParameterMappingTierNode } from 'src/cad/structured-products/shared/interfaces/parameter-mapping-tier-node';
import { RightInputColumnData } from 'src/cad/contracts/shared/components/dynamic-input-tables/types/dynamic-input-tables-types';
import ValidationResult = cad.ValidationResult;

@Injectable()
export class ProductServiceApi {

  public readonly endpoint: string = 'product/product-service';

  constructor(
    private apiHelper: ApiHelper
  ) {}

  public find(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/find`, { body: params });
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/filter`, { body: params });
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/recent`);
  }

  public save(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/save`, { body: params });
  }

  public deleteItem(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/delete`, { body: params });
  }

  public getById = ( id: number | string ): Observable<any> => {
    return this.apiHelper.request( this.endpoint + '/' + id ).pipe(map( ( point ) => {
      point.pointCd = point.code;
      return point;
    } ));
  }

  public getRateSchedules(): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/rateschedules`);
  }

  public findRights(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/findrights`, { body: params });
  }

  public findPrices(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/findprices`, { body: params });
  }


  public rightslookup(): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/rightslookup`);
  }

  public priceslookup(): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/priceslookup`);
  }


  public findUtilizationPercentage(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/utilizationpercentage`, { body: params });
  }

  public saveUtilizationPercentage(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/save-utilization-percentage`, { body: params });
  }
  public findInputs(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/findinputs`, { body: params });
  }

  public saveRights(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/saverights`, { body: params });
  }

  public savePrices(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/saveprices`, { body: params });
  }
  public saveInputs(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/saveinputs`, { body: params });
  }
  
  public getRightParametersForMapping(serviceTemplId: number, serviceComponent: ServiceComponentData): Observable<ParameterMappingTierNode> {
    return this.apiHelper.request(`${this.endpoint}/${serviceTemplId}/get-right-param-mappings`, { body: serviceComponent });
  }
  
  public getPriceParametersForMapping(serviceTemplId: number, serviceComponent: ServiceComponentData): Observable<ParameterMappingTierNode> {
    return this.apiHelper.request(`${this.endpoint}/${serviceTemplId}/get-price-param-mappings`, { body: serviceComponent });
  }
  
  public getInputColumnList(serviceComponent: ServiceComponentData): Observable<RightInputColumnData[]> {
    return this.apiHelper.request(`${this.endpoint}/get-input-column-list`, { body: serviceComponent });
  }
  
  public saveRightParamMappings(parameterMappingTierNode: ParameterMappingTierNode): Observable<ValidationResult<ParameterMappingTierNode>> {
    return this.apiHelper.request(`${this.endpoint}/save-right-param-mappings`, { body: parameterMappingTierNode });
  }
  
  public savePriceParamMappings(parameterMappingTierNode: ParameterMappingTierNode): Observable<ValidationResult<ParameterMappingTierNode>> {
    return this.apiHelper.request(`${this.endpoint}/save-price-param-mappings`, { body: parameterMappingTierNode });
  }
}
