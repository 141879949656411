import {AlertsService} from 'cad/core/services/alerts/alerts.service';
import {AutoUnsubscriber, AutoUnsubscribables} from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import {UsersApi} from 'src/cad/common/services/api/administration/users/users';
import {AssociateContactProfileApi} from 'cad/common/services/api/associate/contact/profile/profile';
import {UserModelService} from 'src/cad/common/services/user/user-model-service';
import {Component, OnInit} from '@angular/core';
import {NewContactProfileModalComponent} from 'src/cad/user-profile/components/new-contact-profile-modal/new-contact-profile-modal.component';
import {UiGridApi} from 'src/ag-grid-wrapper';
import { ProfileTypeListService } from 'cad/administration/shared/profile-type-list.service';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'contact-information',
  templateUrl: './contact-information.component.html',
})
export class ContactInformationComponent implements OnInit {

  public items: any[];
  public item: any;
  public userId: any;
  public gridApi: UiGridApi;
  public activeList: any[];
  public notificationList: any[];
  public rowActionMenu: any;
  @AutoUnsubscriber() private subs: AutoUnsubscribables;


  constructor(private api: UsersApi,
              public mdDialog: MatDialog,
              private cadAlerts: AlertsService,
              private userModelService: UserModelService,
              private associateContactProfileApi: AssociateContactProfileApi,
              public alerts: AlertsService,
              public profileTypeListService: ProfileTypeListService) {
  }

  ngOnInit(): void {
    this.getContactInformation();

    this.rowActionMenu = {
      title: 'Email Notification',
      items: [
        {
          name: 'Delete',
          icon: 'delete',
          method: this.deleteRow,
          isDisabled: false,
        },
      ],
    };
  }

  public deleteRow = (rowData: any): void => {
    if (rowData && (this.notificationList.includes(rowData.profileTypeCd))) {
      this.cadAlerts.danger('Profile Type used in Email Notifications.');
    } else {
      rowData.dirtyStatus = cad.DirtyStatus.DELETE;
      this.saveContactInformation();
    }
  }

  getContactInformation(): void {
    this.userId = this.userModelService.getUser().userId;
    this.subs.newSub = this.associateContactProfileApi.findAll(this.userId).subscribe((data) => {
      this.items = data;
      this.activeProfileTypes();
    });

    this.subs.newSub = this.profileTypeListService.currentNotificationTypeList.subscribe((data) => {
      this.notificationList = data;
    });
  }

  public activeProfileTypes = (): void => {
    this.activeList = this.items.map((el): any => {
      return el.profileTypeCd;
    });
    this.profileTypeListService.setProfileTypeList(this.activeList);
  }

  saveContactInformation(): void {
    let errorStatus: boolean = false;
    _.forEach(this.items, (item: any) => {
      if (moment(item.effBeginDt).isAfter(item.effEndDt) && !errorStatus) {
        errorStatus = true;
      }
    });
    if (errorStatus) {
      this.cadAlerts.danger('End date must be greater than the start date');
    } else {
      this.subs.newSub = this.associateContactProfileApi.save(this.items).subscribe((resp) => {
        if (resp.successful) {
          this.getContactInformation();
          this.cadAlerts.success('Items successfully updated.');
        } else {
          this.showErrors(resp);
        }
      });
    }
  }

  openAddModal(): void {
    let dialogRef: MatDialogRef<NewContactProfileModalComponent> = this.mdDialog.open(NewContactProfileModalComponent, {
      data: {
        items: this.items,
        contactId: this.userId,
      },
      width: '60%',
    });

    let sub = dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.saveContactInformation();
      }
      sub.unsubscribe();
    });
  }

  showErrors(resp: any): void {
    if (resp.errorMessages && resp.errorMessages.length) {
      resp.errorMessages.forEach((err) => {
        this.cadAlerts.danger(err.message);
      });
    }
  }

  public onGridReady = (event: any): void => {
    if (event && event.api) {
      this.gridApi = event.api;
    }
  }

}


