
import {of as observableOf, forkJoin as observableForkJoin,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FeatureFlagsApi } from 'src/cad/common/services/api/physical/asset/feature-flag';
import * as _ from 'lodash';

@Injectable()
export class FeatureFlagsService {
  
  constructor(
    private featureFlagsApi: FeatureFlagsApi
  ) {}
  
  public isFeatureActive(featureFlags: string | string[]): Observable<boolean> {
    let returnObs: Observable<any>[] = [];
    let featureFlagList: string[] = _.isArray(featureFlags) ? featureFlags : [ featureFlags ];
    
    _.forEach(featureFlagList, (featureFlag: string) => {
      switch (featureFlag) {
        case 'asset-live': {
          returnObs.push(this.isAssetLive());
          break;
        }
        case 'caprel-segmentation': {
          returnObs.push(this.isCapRelSegmentationActive());
          break;
        }
        case 'caprel-seasonality': {
          returnObs.push(this.isCapRelSeasonalityActive());
          break;
        }
        default: {
          break;
        }
      }
    });
    return returnObs.length > 0
      ? observableForkJoin(returnObs).pipe(map((dataList: any) => _.every(dataList)))
      : observableOf(false);
  }
  
  public isAssetLive(): Observable<any> {
    return this.featureFlagsApi ? this.featureFlagsApi.isAssetLive() : observableOf(false);
  }
  
  public isCapRelSegmentationActive(): Observable<any> {
    return this.isAssetLive();
  }
  
  public isCapRelSeasonalityActive(): Observable<any> {
    return observableOf(true);
  }
}
