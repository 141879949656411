import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCache } from 'cad/common/services/api/api-cache';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { map } from 'rxjs/operators';

@Injectable()
export class ServiceRequesterApi {

  private readonly endpoint: string = 'associate/service-requester';

  constructor(
    private apiHelper: ApiHelper,
    private apiCache: ApiCache,
  ) {}

  public getById = (id: string | number): Observable<any> => {
    return this.filter().pipe(map((requesters) => {
      return _.find(requesters, { baid: id });
    }));
  }

  public filter = (params?: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params || {}, cache: this.apiCache.inMemory });
  }

  public search = (param: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint, { body: param, cache: this.apiCache.inMemory });
  }
}
