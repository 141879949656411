import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, CanDeactivate, Route, RouterStateSnapshot } from '@angular/router';


import { SecurityAuthorizer } from './security-authorizer';
import { SecurityModel } from './security.model';

import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivateModal } from 'src/features/common/security/modal/can-deactivate-modal';
import { DeactivateAuthorizer } from 'src/features/common/security/deactivate-authorizer';

@Injectable()
export class SecurityService implements CanActivate, CanActivateChild, CanLoad, CanDeactivate<any> {
  private deactivateAuthorizers: DeactivateAuthorizer[] = [];

  constructor(
    public authService: SecurityAuthorizer,
    public dialog: MatDialog,
  ) {
  }

  /**
   * CanActivate hook.
   */
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const model = route.data ? (route.data as any).SecurityModel as SecurityModel : undefined;
    if (model) {
      return this.authService.canActivate(model, route, state);
    } else {
      console.warn(`Expecting 'SecurityModel' in route data`, { route });
      return false;
    }
  }

  /**
   * CanActivateChild hook.
   */
  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const model = route.data ? (route.data as any).SecurityModel as SecurityModel : undefined;
    if (model) {
      return this.authService.canActivate(model, route, state);
    } else {
      console.warn(`Expecting 'SecurityModel' in route data`, { route });
      return false;
    }
  }

  /**
   * CanActivate hook.
   */
  public canLoad(
    route: Route,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const model = route.data ? (route.data as any).SecurityModel as SecurityModel : undefined;
    if (model) {
      return this.authService.canLoad(model, route);
    } else {
      console.warn(`Expecting 'SecurityModel' in route data`, { route });
      return false;
    }
  }

  public canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const model = currentRoute.data ? (currentRoute.data as any).SecurityModel as SecurityModel : undefined;
    if (model) {
      let canDeactivate = true;
      if (component && _.isFunction(component.canDeactivate)) {
        if (!(component as DeactivateAuthorizer).canDeactivate(currentRoute, currentState, nextState)) {
          canDeactivate = false;
        }
      }
      for (let authorizer of this.deactivateAuthorizers) {
        if (!authorizer.canDeactivate(currentRoute, currentState, nextState)) {
          canDeactivate = false;
        }
      }
      if (!canDeactivate) {
        let dialogRef = this.dialog.open(CanDeactivateModal, {
          width: '25%',
        });

        return dialogRef.afterClosed();
      } else {
        return true;
      }
    } else {
      console.warn(`Expecting 'SecurityModel' in route data`, { currentRoute });
      return true;
    }
  }

  public registerDeactivateAuthorizer(authorizer: DeactivateAuthorizer): void {
    if (authorizer) {
      this.deactivateAuthorizers = _.union(this.deactivateAuthorizers, [ authorizer ]);
    }
  }

  public unregisterDeactivateAuthorizer(authorizer: DeactivateAuthorizer): void {
    this.deactivateAuthorizers = _.without(this.deactivateAuthorizers, authorizer);
  }
}
