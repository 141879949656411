
export class BooleanUtils {
  private constructor() {}

  private static readonly map: any = { Y: true, N: false, YES: true, NO: false, };

  public static toBool (val: string | number | boolean): boolean {
    return _.isString(val) ? BooleanUtils.map[val.toUpperCase()] : !!val;
  }

  public static toYN (val: boolean): 'Y' | 'N' {
    return val ? 'Y' : 'N';
  } 

  public static toBinary (val: boolean): 0 | 1 {
    return val ? 1 : 0;
  }

  public static toggleValue(val: string | number | boolean): string | number | boolean {
    let valType;
    let toggled;

    if (_.isString(val)) { valType = 'yesno'; }
    if (_.isNumber(val)) { valType = 'onezero'; }

    switch (valType) {
      case 'yesno': {
        toggled = BooleanUtils.toYN(!BooleanUtils.toBool(val));
        break;
      }
      case 'onezero': {
        toggled = BooleanUtils.toBinary(!BooleanUtils.toBool(val));
        break;
      }
      default: {
        toggled = !val;
      }
    }
    return toggled;
  }
}
