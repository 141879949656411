import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';


@Injectable()
export class NominationConfirmationApprovalApi {

  private readonly endpoint: string = 'flowing-gas/scheduling/nomination-confirmation-approval';

  constructor(
    private apiHelper: ApiHelper,

  ) {}


  public save(data: any): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/save`, { body: data });
  }

}
