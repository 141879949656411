import { Input, Component, ChangeDetectorRef, Renderer2, OnInit, OnDestroy } from '@angular/core';
import { AutoUnsubscriber, AutoUnsubscribables } from 'cad/shared/mixins/auto-unsubscriber.mixin';
import { ProcessStatusModel, UiProcessStatusService } from 'src/common/services/process-status/process-status.service';

@Component({
  selector: 'ui-process-status',
  templateUrl: './process-status.component.html',
  styleUrls: [ './process-status.component.less' ]
})
export class UiProcessStatusComponent implements OnInit, OnDestroy {

  @Input() public processList: ProcessStatusModel[];

  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  constructor(
    public renderer: Renderer2,
    public changeDetectorRef: ChangeDetectorRef,
    public uiProcessStatusService: UiProcessStatusService
  ) {}

  ngOnInit(): void {
    if (this.uiProcessStatusService) {
      this.subs.newSub = this.uiProcessStatusService.processList$.subscribe((processList: ProcessStatusModel[]) => {
        this.processList = processList;
      });
    }
  }

  ngOnDestroy(): void {}

  removeProcess(processName: string): void {
    if (this.uiProcessStatusService && processName) {
      this.uiProcessStatusService.removeProcess(processName);
    }
  }

  hide(): void {
    if (this.uiProcessStatusService) {
      this.uiProcessStatusService.hide();
    }
  }
}
