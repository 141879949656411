/* 
TODO:  DELETE ENTIRE FILE once this app is on Angular 10 
(diff with Angular's version to ensure no custom functionality is lost) 
*/
import {of as observableOf,  Observable ,  Subscription } from 'rxjs';
import { NgZone, QueryList } from '@angular/core';
import { ActiveDescendantKeyManager, Highlightable } from '@angular/cdk/a11y';
import { take ,  debounceTime ,  filter ,  map ,  tap } from 'rxjs/operators';
import * as _ from 'lodash';

/**
 * This interface is for items that have 'value' and 'viewValue' properties (used by VirtualKeyManager).
 */
export interface VirtualKeyManagerOption extends Highlightable {
  value: any;
  viewValue: string;
}

export class VirtualKeyManager<T> extends ActiveDescendantKeyManager<VirtualKeyManagerOption & T> {

  private _letterKeyStreamSubscription: Subscription = Subscription.EMPTY;
  private _itemsChangesSubscription: Subscription = Subscription.EMPTY;

  constructor(
    _items: QueryList<VirtualKeyManagerOption & T>,
    public originalItems: any[] = [],
    public ngZone: NgZone,
    public scrollItemIntoView: (index: number) => Observable<any> = (index: number) => observableOf(undefined),
  ) {
    super(_items);
    this._itemsChangesSubscription = _items.changes.subscribe((items: QueryList<VirtualKeyManagerOption & T>) => {
      if (items) {
        if (this.activeItem) {
          let oldIndex: number = this.activeItemIndex;
          let newIndex: number = items.toArray().findIndex((item) => item.value === this.activeItem.value);
          if (newIndex !== oldIndex) {
            this.updateActiveItem(newIndex);
          }
        }
        if (items.toArray()[ this.activeItemIndex ] !== undefined) {
          this.setActiveItem(this.activeItemIndex);
        }
      }
    });
  }

  /**
   * @description Cleans up the class instance.
   */
  destroy(): void {
    this._itemsChangesSubscription.unsubscribe();
    this._letterKeyStreamSubscription.unsubscribe();
    this._itemsChangesSubscription = null;
    this._letterKeyStreamSubscription = null;
  }

  /**
   * @description Turns on typeahead mode which allows users to set the active item by typing.
   * @param debounceInterval Time to wait after the last keystroke before setting the active item.
   * @override Overrides parent class's method to handle Virtual Options.
   */
  withTypeAhead(debounceInterval: number = 500): this {
    this._letterKeyStreamSubscription.unsubscribe();
    // Debounce the presses of non-navigational keys, collect the ones that correspond to letters
    // and convert those letters back into a string. Afterwards find the first item that starts
    // with that string and select it.
    this._letterKeyStreamSubscription = (this as any)._letterKeyStream
    .pipe(
      tap((keyCode: string) => (this as any)._pressedLetters.push(keyCode)),
      debounceTime(debounceInterval),
      filter(() => (this as any)._pressedLetters.length > 0),
      map(() => (this as any)._pressedLetters.join(''))
    ).subscribe((inputString: string) => {
      for (let i = 0; i < this.originalItems.length; i++) {
        let labelValue: string = this.originalItems[ i ].viewValue;
        if (!_.isNil(labelValue) && labelValue!.toUpperCase().trim().indexOf(inputString) === 0) {
          this.scrollItemIntoView(i).subscribe(() => {
            let matchedIndex: number = this.getItemIndexByValue(this.originalItems[ i ].value);
            if (matchedIndex > -1) {
              this.setActiveItem(matchedIndex);
            }
          });
          break;
        }
      }
      (this as any)._pressedLetters = [];
    });
    return this;
  }

  /**
   * @description Sets the active item to the first enabled item in the list.
   */
  setFirstItemActive(): void {
    this.scrollItemIntoView(0).pipe(take(1)).subscribe(() => {
      super.setFirstItemActive();
    });
  }

  /**
   * @description Sets the active item to the last enabled item in the list.
   */
  setLastItemActive(): void {
    this.scrollItemIntoView(this.originalItems.length - 1).pipe(take(1)).subscribe(() => {
      super.setLastItemActive();
    });
  }

  /**
   * @description Sets the active item to the next enabled item in the list.
   */
  setNextItemActive(): void {
    if (this.activeItem) {
      const activeItemIndex: number = this.getOriginalItemIndex(this.activeItem.value);
      const nextActiveItemIndex: number = activeItemIndex + 1;
      if (activeItemIndex > -1 && nextActiveItemIndex < this.originalItems.length) {
        this.scrollItemIntoView(nextActiveItemIndex).pipe(take(1)).subscribe(() => {
          super.setNextItemActive();
        });
      }
    } else {
      super.setNextItemActive();
    }
  }

  /**
   * @description Sets the active item to a previous enabled item in the list.
   */
  setPreviousItemActive(): void {
    if (this.activeItem) {
      const activeItemIndex: number = this.getOriginalItemIndex(this.activeItem.value);
      const prevActiveItemIndex: number = activeItemIndex - 1;
      if (activeItemIndex > -1 && prevActiveItemIndex > -1) {
        this.scrollItemIntoView(prevActiveItemIndex).pipe(take(1)).subscribe(() => {
          super.setPreviousItemActive();
        });
      }
    } else {
      super.setPreviousItemActive();
    }
  }

  /**
   * @description Searches and returns the index of the passed 'itemValue' based on the 'value' property
   * of the '_items' QueryList items.
   * @param itemValue
   * @returns {number}
   */
  getItemIndexByValue(itemValue: any): number {
    return _.findIndex((this as any)._items.toArray(), (item: VirtualKeyManagerOption & T) => {
      return item.value === itemValue;
    });
  }

  /**
   * @description Searches and returns the index of the passed 'itemValue' based on the 'value' property
   * of the 'originalItems' array items.
   * @param itemValue
   * @returns {number}
   */
  getOriginalItemIndex(itemValue: any): number {
    return _.findIndex(this.originalItems, { value: itemValue });
  }

  /**
   * @description Sets the active item to the item at the index specified.
   * @override Overrides parent class's (ActiveDescendantKeyManager) method
   * @param index
   */
  setActiveItemIndex(index: number): void {
    this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
      let previousValue: any;
      if (this.activeItem) {
        previousValue = this.activeItem;
        this.activeItem.setInactiveStyles();
      }
      (this as any)._activeItemIndex = index;
      (this as any)._activeItem = (this as any)._items.toArray()[ index ];
      if (this.activeItem) {
        this.ngZone.run(() => {
          if (this.activeItem !== previousValue) {
            previousValue = null;
            this.change.next(index);
          }
          this.activeItem.setActiveStyles();
        });
      }
    });
  }

  /**
   * @description Sets the 'originalItems' array items to the 'items' specified.
   * @param items
   */
  setOriginalItems(items: any[]): void {
    this.originalItems.splice(0);
    this.originalItems = items;
  }
}
