import { RowNode } from '@ag-grid-enterprise/all-modules';
import { Component } from '@angular/core';
import { OfferReviewData } from 'src/cad/dashboard/interfaces/offer-review-data';
import { BaseDashComponent } from 'src/cad/dashboard/views/content/components/base-dash.component';

@Component({
  selector: 'offer-review-dashboard',
  templateUrl: './offer-review-dashboard.component.html',
})
export class OfferReviewDashboardComponent extends BaseDashComponent<OfferReviewData> {
  public name: string = 'offerreview';
  public endPoint: string = 'capacity-release/offer/bids-pending-action';
  
  public ngOnInit(): void {
    this.useDefaultLength = false;
    super.ngOnInit();
  }
  
  public getEmptyHeadline(): string {
    return 'No Offers Pending Review';
  }
  public getDefaultHeadline(): string {
    return 'Available Offers in Review';
  }
  public getName(): string {
    return this.name;
  }

  public onRowClicked(event: RowNode): void {
    if (event.data.description === 'Match Bid') {
      super.navigate([ 'capacity-release','offers', event.data.relatedAuctionID, 'bid-info' ]);
    } else {
      super.navigate([ 'capacity-release','offers', event.data.relatedAuctionID, 'offer-info' ]);
    }
  }
}
