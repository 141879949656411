<h1 fxLayout="row"
    fxLayoutAlign="start center"
    matDialogTitle>
  <div fxFlex>REDUCTION REASON</div>
  <div>
    <button type="button"
            mat-button
            mat-icon-button
            matDialogClose
            (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</h1>
<div matDialogContent>
  <reduction-reason-form #form
                         [codeType]="codeType"
                         [reductionCd]="reductionCd"
                         [editable]="editable"
                         [showComment]="showComment"
                         [comment]="comment"
                         [filterData]="data?.filterData">
  </reduction-reason-form>
</div>
<div matDialogActions
     fxLayout="row"
     fxLayoutGap="18px"
     fxLayoutAlign="end center">
  <button type="button"
          mat-raised-button
          skip-disable
          (click)="close()">{{editable ? 'CANCEL' : 'CLOSE'}}
  </button>
  <button *ngIf="editable"
          type="button"
          mat-raised-button
          color="primary"
          (click)="save()"
          [disabled]="isSubmitDisabled()">SUBMIT
  </button>
</div>
