import { Component, Injector } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { TableColumnLookupBaseComponent } from './lookup-base.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  moduleId: module.id + '',
  selector: 'table-column-lookup-render',
  templateUrl: './lookup-renderer.component.tpl.html'
})
export class TableColumnLookupRendererComponent extends TableColumnLookupBaseComponent implements AgRendererComponent {

  constructor(dialog: MatDialog, injector: Injector) {
    super(dialog, injector);
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
