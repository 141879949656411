import { RowNode } from '@ag-grid-community/core';
import { Observable ,  Subscription } from 'rxjs';
import { Component, Inject, Injector, ComponentRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { LayoutService } from 'common/services/layout/layout';
import { AlertsService } from 'cad/core/services/alerts/alerts.service';
import { UserModelService } from 'cad/common/services/user/user-model-service';

import { DirtyStatus } from 'common/types/cad.constants';
import { ItemController } from 'src/features/common/item/item.controller';
import { AssociationController } from 'src/features/common/association/association.controller';
import { UiFormComponent } from 'src/common/components/form/form.component';
import { UiFormParent } from 'src/common/components/form/form';
import { FullscreenDialogComponent } from '../fullscreen-dialog/fullscreen-dialog.component';

@Component({
  templateUrl: './form-only-dialog.component.html',
})
export class FormOnlyDialogComponent extends FullscreenDialogComponent  implements OnInit, OnDestroy {

  @ViewChild(UiFormComponent) uiFormComponent: UiFormComponent;

  public formOnlyInjector: Injector;
  public isDirty: boolean = false;

  public ngOnInit(): void {
    this.formOnlyInjector = Injector.create([
      {
        provide: UiFormParent,
        useFactory: () => this.injector.get(FormOnlyDialogComponent),
        deps: [],
      },
      {
        provide: MAT_DIALOG_DATA,
        useValue: this.data,
      }
    ], this.associationController.injector);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.associationController.cleanupAssociationModal();
  }

  public isSubmitDisabled(): boolean {
    if (this.uiFormComponent) {
      return !((this.uiFormComponent.form.valid && this.isDirty) || this.canSubmit);
    } else {
      return !this.canSubmit;
    }
  }
}
