
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'src/cad/common/services/api/api-helper';
import * as _ from 'lodash';

@Injectable()
export class CapacityReleaseStorageRelationshipApi {
  private readonly endpoint: string = 'capacity-release/storage-relationship';

  constructor(private apiHelper: ApiHelper) {}

  public getById(crRelatedId: number | string): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/' + crRelatedId);
  }

  public find = (params: any): Observable<any> => {
    return this.getById(params.crRelatedId);
  }

  public recent(params: any = {}): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/dashboard', { body: params });
  }

  public filter(params: any): Observable<any> {
    let returnVal: Observable<any>;

    if (params && _.has(params, 'populator')) {
      let paramsObj: any = _.omit(params, 'populator');
      let lookupActionName: string = _.get(params, 'populator');

      switch (lookupActionName) {
        case 'releasingShipperLookup': {
          returnVal = this.getReleasingShipperLookupData(paramsObj);
          break;
        }
        case 'replacementShipperLookup': {
          returnVal = this.getReplacementShipperLookupData(paramsObj);
          break;
        }
        default: {
          break;
        }
      }
    } else {
      returnVal = this.apiHelper.request(this.endpoint + '/filter', { body: params });
    }
    return !_.isNil(returnVal) ? returnVal : observableOf([]);
  }

  public save(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save', { body: params });
  }

  public deleteItem(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/delete', { body: params });
  }

  public getCrStgListForFilter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/crstglist-for-filter', { body: params });
  }

  public getReleasingShipperLookupData(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/releasing-shipper-lookup', { body: params });
  }
  
  public getReplacementShipperLookupData(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/replacement-shipper-lookup', { body: params });
  }
}
