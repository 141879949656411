<div fxLayout="row" [uiDraggableModal]="allowDrag"
     fxLayoutAlign="start center">
  <h2 class="title">
    <ng-content></ng-content>
  </h2>
  <div fxFlex></div>
  <ui-alerts-button></ui-alerts-button>
  <div class="close">
    <button type="button"
            mat-button
            mat-icon-button
            matDialogClose>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
