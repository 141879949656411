
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ApiHelper } from 'src/cad/common/services/api/api-helper';
import * as _ from 'lodash';

@Injectable()
export class CapacityReleaseOfferAwardsRecallsApi {
  private readonly endpoint: string = 'capacity-release/offer/';
  private readonly suffix: string = '/awards';
  private readonly suffix2: string = '/recalls';

  constructor(private apiHelper: ApiHelper) {}

  public getRecalls = (auctionId: number | string, awardId: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/' + awardId + this.suffix2);
  }

  public save(recallData: any): Observable<any> {
    let saveData: any = (_.isArray(recallData) && recallData.length > 0)
      ? recallData[ 0 ]
      : recallData;

    if (saveData
      && !_.isNil(saveData.auctionID)
      && !_.isNil(saveData.awardID)) {
      return this.apiHelper.request(
        this.endpoint + saveData.auctionID + this.suffix + '/' + saveData.awardID + this.suffix2 + '/save',
        { body: _.isArray(recallData) ? recallData : [ recallData ] }
      );
    }
    return observableOf({});
  }

  public getRecallLocations(recallData: any): Observable<any> {
    if (recallData
      && !_.isNil(recallData.auctionID)
      && !_.isNil(recallData.awardID)
      && !_.isNil(recallData.recallID)) {
      return this.apiHelper.request(
        this.endpoint
        + recallData.auctionID
        + this.suffix
        + '/'
        + recallData.awardID
        + this.suffix2
        + '/' + recallData.recallID
        + '/locations'
      );
    }
    return observableOf([]);
  }

  public saveRecallLocations(recallData: any, recallLocationData: any): Observable<any> {
    if (recallData
      && recallLocationData
      && !_.isNil(recallData.auctionID)
      && !_.isNil(recallData.awardID)
      && !_.isNil(recallData.recallID)) {
      return this.apiHelper.request(
        this.endpoint
        + recallData.auctionID
        + this.suffix
        + '/'
        + recallData.awardID
        + this.suffix2
        + '/' + recallData.recallID
        + '/locations/save',
        { body: recallLocationData }
      );
    }
    return observableOf({});
  }
}
