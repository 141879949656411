<span>
  <button
    [disabled]="controller.model.disableActionMenu"
    mat-button
    matTooltip="Options"
    aria-label="Open tool menu"
    [matMenuTriggerFor]="actionMenu">
    <mat-icon>apps</mat-icon>
  </button>
  <mat-menu id="test"
            #actionMenu
            positionX="after"
            positionY="below"
            [overlapTrigger]="false"
            [classList]="'ui-action-menu'">
    <div *ngFor="let item of actionItems">
      <button mat-menu-item
              [disabled]="!item.enabled"
              (click)="item.action()">
        <mat-icon>{{item.icon}}</mat-icon>
        {{item.name}}
      </button>
    </div>
  </mat-menu>
</span>
