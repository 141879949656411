import { Component, Input, OnInit } from '@angular/core';
import { BaseDashComponent } from 'src/cad/dashboard/views/content/components/base-dash.component';
import UserPreferences = cad.UserPreferences;
import { ActionMenu } from 'src/ag-grid-wrapper/table/action-menu';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { RouterService } from 'cad/core/services/router/router.service';
import { ActivatedRoute } from '@angular/router';
import { UserPreferenceService } from 'cad/common/services/preferences/user-preferences';
import { WorkflowFavService } from 'cad/common/services/workflow/workflow-fav-service';
import { VerticalEnum } from 'cad/dashboard/views/content/components/vertical.enum';
import { map, shareReplay } from 'rxjs/operators';
import ReportFavorite = cad.ReportFavorite;

@Component({
  selector: 'report-fav-dash',
  templateUrl: './report-fav-dash.component.html',
})
export class ReportFavDashComponent extends BaseDashComponent<ReportFavorite> implements OnInit {
  @Input() vertical: VerticalEnum;
  public actionMenu: ActionMenu;
  public name: string;
  private navigationPathPrefix: string[];

  constructor(
    public apiHelper: ApiHelper,
    public routerService: RouterService,
    public activatedRoute: ActivatedRoute,
    public userPreferenceService: UserPreferenceService,
    public workflowService: WorkflowFavService) {
    super(apiHelper, routerService, activatedRoute, userPreferenceService);
  }

  public ngOnInit(): void {
    this.options = { body: { workflowVertical: this.vertical } };

    this.itemData$ = this.userPreferenceService.getPreferences()
      .pipe(shareReplay(), map((data: UserPreferences) =>
        this.workflowService.abbreviateParametersOnFavs(_.sortBy(_.clone(this.userPreferenceService.getReportFavoritesFromPrefs(data)), 'name'))
    ));

    super.ngOnInit();
    switch(this.vertical) {
      case VerticalEnum.REPORTING: {
        this.name = 'report-fav';
        this.navigationPathPrefix = [ 'reports','schedule-and-view-reports' ];
        break;
      }
      case VerticalEnum.WORKFLOW: {
        this.name = 'workflow-fav';
        this.navigationPathPrefix = [ 'workflows','schedule-and-view-reports' ];
        this.options = null;
        break;
      }
      default: {
        this.name = 'invalid component vertical';
        break;
      }
    }

    this.actionMenu = {
      title: 'Manage Favorites',
      location: 'right',
      items: [
        {
          name: 'Remove',
          icon: 'delete',
          method: this.removeFavorite.bind(this),
        },
        {
          name: `Goto ${this.name}`,
          icon: 'arrow_right_alt',

          // TODO: GENERALIZE
          method: this.goToReport.bind(this),
        },
        {
          name: 'Run',
          icon: 'play_arrow',

          // TODO: GENERALIZE
          method: this.runReport.bind(this),
        },
        {
          name: 'Run in background',
          icon: 'schedule',
          method: this.runInBackground.bind(this),
        }
      ],
    };
  }

  public getEmptyHeadline(): string {
    return `No Favorite Reports`;
  }
  public getDefaultHeadline(): string {
    return `Favorite Reports for Current Asset`;
  }

  public getName(): string {
    return this.name;
  }

  public onRowClicked (event: any): void {
    this.workflowService.goToReport(event.data);
  }

  public removeFavorite(rowEntity: any): void {
    this.userPreferenceService.removeReportFavorite(rowEntity);
  }

  public goToReport(rowEntity: any): void {
    this.workflowService.goToReport(rowEntity);
  }

  public runReport(rowEntity: any): void {
    this.workflowService.runReportAndGo(rowEntity);
  }

  public runInBackground(rowEntity: any): void {
    this.workflowService.runReport(rowEntity);
  }
}
