
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ApiHelper } from 'src/cad/common/services/api/api-helper';
import { CapacityReleaseReputsApi } from 'src/cad/common/services/api/capacity-release/reputs/reputs';
import * as _ from 'lodash';

@Injectable()
export class CapacityReleaseRecallReputsApi {
  private readonly endpoint: string = 'capacity-release/recalls/';
  private readonly suffix: string = '/reputs-for-recall';

  constructor(
    public capacityReleaseReputsApi: CapacityReleaseReputsApi,
    private apiHelper: ApiHelper
  ) {}

  public getReputs(recallId: number | string): Observable<any> {
    if (!_.isNil(recallId)) {
      return this.apiHelper.request(this.endpoint + recallId + this.suffix );
    }
    return observableOf([]);
  }

  public save(reputData: any): Observable<any> {
    let saveData: any = (_.isArray(reputData) && reputData.length > 0)
      ? reputData[ 0 ]
      : reputData;

    if (saveData
      && !_.isNil(saveData.recallID)) {
      return this.apiHelper.request(
        this.endpoint + saveData.recallID + this.suffix + '/save',
        { body: _.isArray(reputData) ? reputData : [ reputData ] }
      );
    }
    return observableOf({});
  }

  public getReputLocations(reputId: number | string): Observable<any> {
    if (!_.isNil(reputId) && this.capacityReleaseReputsApi) {
      return this.capacityReleaseReputsApi.getReputLocations(reputId);
    }
    return observableOf([]);
  }

  public saveReputLocations(reputId: number | string, reputLocationData: any): Observable<any> {
    if (!_.isNil(reputId)
      && reputLocationData
      && this.capacityReleaseReputsApi) {
      return this.capacityReleaseReputsApi.saveReputLocations(reputId, reputLocationData);
    }
    return observableOf({});
  }
}
