import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LookupApi } from 'src/features/common/modal/modal-feature-types';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import ValidationResult = cad.ValidationResult;
import InternalContactLookupData = mplus.InternalContactLookupData;

@Injectable()
export class ContactInternalContactApi implements LookupApi {

  private readonly endpoint: string = 'associate/contact/';
  private readonly suffix: string = '/internal-contacts';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public filter(params: any): Observable<InternalContactLookupData> {
    return this.apiHelper.request(this.endpoint + params.userId + this.suffix + '/lookup', {body: params});
  }

  public findAll(contactId: number): Observable<mplus.ContactInternalContactData[]> {
    return this.apiHelper.request(this.endpoint + contactId + this.suffix);
  }

  public save(data: any): Observable<ValidationResult> {
    return this.apiHelper.request(this.endpoint + data.userId + this.suffix + '/save', {body: data});
  }

}
