import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { SecurityAuthApi } from 'cad/common/services/api/security/auth/auth';
import { EmitterService } from 'cad/core/services/emitter/emitter.service';
import { UserStoreService } from 'cad/common/store/core/services/user-store.service';
import { tap } from 'rxjs/operators';
import ValidationResult = cad.ValidationResult;
import { RootObject } from 'cad/common/store/user/user-model';


@Injectable()
export class SecurityLoginApi {
  private loginURI: string = 'security/login';

  constructor(
    private securityAuthApi: SecurityAuthApi,
    private apiHelper: ApiHelper,
    private emitterService: EmitterService,
    private userStateStore: UserStoreService,
  ) {}

  isRootObject(user: any): user is RootObject {
    return (user as RootObject).availablecontextsMap !== undefined;
  }

  private loginRequest(): Observable<RootObject[]> {
    return this.apiHelper.request(this.loginURI).pipe(tap((userData) => {
      if (('successful' in userData) && userData.successful === false) {
        throw new Error((userData as ValidationResult).errorMessages.join());
      }
      let user = userData.length;
      while (user--) {
        if (!this.isRootObject(userData[ user ])) {
          console.error(`${userData[ user ].userName} is not a RootObject, removing list`);
          userData.splice(user, 1);
        }
      }

      this.userStateStore.login(userData);

      console.log(`Auth OK`);

      this.securityAuthApi.clearAuthToken();
    }));
  }

  public loginUser(userName: string, password: string): Observable<RootObject[]> {
    console.log('Request Basic Auth: ' + userName);
    this.securityAuthApi.storeAuthToken(userName, password);
    return this.loginRequest();
  }

  public loginUserNTLM(): Observable<RootObject[]> {
    console.log('Request NTLM Auth');
    return this.loginRequest();
  }

}
