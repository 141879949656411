import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'can-deactivate-modal',
  templateUrl: 'can-deactivate-modal.html',
})
export class CanDeactivateModal {
  constructor(
    public dialogRef: MatDialogRef<CanDeactivateModal>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  public onCancelClick(): void {
    this.dialogRef.close(false);
  }
  public onOkClick(): void {
    this.dialogRef.close(true);
  }
}
