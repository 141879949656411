import { Component, OnChanges } from '@angular/core';
import { BaseDashComponent } from 'src/cad/dashboard/views/content/components/base-dash.component';

@Component({
  selector: 'caprel-dash',
  templateUrl: './caprel-dash.component.html',
})
export class CapRelDashComponent extends BaseDashComponent {
  public name: string = 'caprel';
  public endPoint: string = 'capacity-release/offer/pending-credit-bids';
  
  public ngOnInit(): void {
    this.useDefaultLength = false;
    super.ngOnInit();
  }
  
  public getEmptyHeadline(): string {
    return 'No Capacity Release Bids';
  }
  public getDefaultHeadline(): string {
    return 'My Capacity Release Bids';
  }
  public getName(): string {
    return this.name;
  }

  public onRowClicked = (event: any): void => {
    super.navigate([ 'capacity-release','offers', event.data.relatedAuctionID, 'bid-info' ]);
  }
}
