import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableHeaderFilterRendererComponent } from 'src/ag-grid-wrapper/header/filter/filter-header.component';
import { TableHeaderFilterCriteriaComponent } from 'src/ag-grid-wrapper/header/filter/table-header-filter-criteria.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';

const subModules = [
  TableHeaderFilterCriteriaComponent,
  TableHeaderFilterRendererComponent,
];

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatTabsModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatDatepickerModule,
    FormsModule,
    FlexLayoutModule
  ],
  exports: [ subModules ],
  declarations: [ subModules ],
  entryComponents: [ subModules ],
})
export class TableHeaderFilterModule {}
