import { ICellRendererParams, ICellRendererComp } from '@ag-grid-community/core';

export class TableColumnRadioRenderer implements ICellRendererComp {
  public value: any;
  public eGui: HTMLElement;
  public checked: boolean;

  protected params: ICellRendererParams;
  protected modelType: any;

  init(params: any): void {
    this.params = params;
    this.eGui = document.createElement('div');
    this.eGui.classList.add('md-radio');
    this.eGui.classList.add('md-radio-inline');
    if (!params.value) {
      this.eGui.innerHTML = '';
    }
    this.setTemplate();
    this.params.node.addEventListener('rowSelected', this.onSelection);
  }

  destroy(): void {
    this.params.node.removeEventListener('rowSelected', this.onSelection);
    this.eGui = null;
    this.params = null;
  }

  getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(params: any): boolean {
    this.params = params;
    this.setTemplate();
    return true;
  }

  public onSelection = (p: any): void => {
    this.eGui.querySelector('input').checked = p.node.selected;
  }

  private getTemplate(): string {
    let uuid = (this.params.api as any).gridOptionsWrapper.domDataKey + this.params.node.id;
    this.checked = this.params.node.isSelected();
    return `
      <input type="radio" id="${uuid}" name="singleSelect_${uuid}" ${this.checked ? 'checked' : ''}>
      <label for="${uuid}"></label>
    `;
  }

  private setTemplate(): void {
    this.eGui.innerHTML = this.getTemplate();
  }
}
