
  <ui-card headline="Email Notification">
    <ui-float-button (click)="openAddModal()" [hidden]="editableStatus"></ui-float-button>
    <ui-table [data]="items" [showDelete]="true" (itemsRemoved)="deleteRow($event)">
    <ui-table-column name="notificationTypeCd"
                     etCodeRenderer
                     codeType="NOTIFICATIONTYPE"
                     headerName="Notification Type"></ui-table-column>

    <ui-table-column name="profileTypeCd"
                     etCodeRenderer
                     codeType="CONTACTPROFILE"
                     headerName="Profile Type"></ui-table-column>
      </ui-table>
  </ui-card>

