
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiHelper } from 'src/cad/common/services/api/api-helper';
import { CRReputData } from 'src/cad/capacity-release/shared/interfaces/cr-reput-data';
import { CRReputLocationData } from 'src/cad/capacity-release/shared/interfaces/cr-reput-location-data';
import { CRReputAvailQtyData } from 'src/cad/capacity-release/shared/interfaces/cr-reput-avail-qty-data';
import ValidationResult = cad.ValidationResult;
import * as _ from 'lodash';

@Injectable()
export class CapacityReleaseReputsApi {
  private readonly endpoint: string = 'capacity-release/reputs';

  constructor(private apiHelper: ApiHelper) {}

  public getById(reputId: number | string): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/' + reputId);
  }

  public find = (params: any): Observable<any> => {
    return this.getById(params.reputID);
  }

  public recent(reputData: any = {}): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/dashboard', { body: reputData });
  }

  public filter(params: any): Observable<any> {
    let returnVal: Observable<any>;

    if (params && _.has(params, 'populator')) {
      let paramsObj: any = _.omit(params, 'populator');
      let lookupActionName: string = _.get(params, 'populator');

      switch (lookupActionName) {
        case 'reputtableRecallLookup': {
          returnVal = this.getReputtableRecallData(paramsObj);
          break;
        }
        default: {
          break;
        }
      }
    } else {
      returnVal = this.apiHelper.request(this.endpoint + '/filter', { body: params });
    }
    return !_.isNil(returnVal) ? returnVal : observableOf([]);
  }

  public save(reputData: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save', { body: reputData });
  }

  public deleteItem(reputData: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/delete', { body: reputData });
  }

  public getReputLocations(reputId: number | string): Observable<CRReputLocationData[]> {
    return this.apiHelper.request(this.endpoint + '/' + reputId + '/locations');
  }

  public saveReputLocations(reputId: number | string, reputLocationData: CRReputLocationData[]): Observable<ValidationResult> {
    return this.apiHelper.request(
      this.endpoint + '/' + reputId + '/locations/save',
      { body: reputLocationData }
    );
  }

  public getReputtableRecallData(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/reputtable-recall-lookup', { body: params });
  }
  
  public getReputableVolumesForRecall(crReputData: CRReputData): Observable<CRReputAvailQtyData[]> {
    return this.apiHelper.request(this.endpoint + '/reputable-volumes-for-recall', { body: crReputData })
    .pipe(
      map((dataList: CRReputAvailQtyData[]) => dataList.map((data: CRReputAvailQtyData) => {
        data.flowDirectionInd = data.flowDirInd;
        data.assocGroupCd = data.assocGroup;
        data.locationRecallQty = data.recalledQty;
        data.availQty = data.reputableQty;
        return data;
      }))
    );
  }
}
