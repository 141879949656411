import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { NominationMatrixContractApi } from 'cad/common/services/api/nomination/nomination-matrix/contract/contract';
import { NominationMatrixPinnedContractApi } from 'cad/common/services/api/nomination/nomination-matrix/pinned-contract/pinned-contract';
import {ApiCache} from 'cad/common/services/api/api-cache';
import {ProducerApi} from 'cad/common/services/api/nomination/nomination-matrix/producer/producer-api';
import { ContractForNomLookup } from 'src/cad/nominations/common/models/contract-for-nom-lookup';

@Injectable()
export class NominationMatrixApi {
  private readonly endpoint: string = 'nomination/nomination-matrix';

  constructor(
    public contract: NominationMatrixContractApi,
    public pinnedContracts: NominationMatrixPinnedContractApi,
    public producer: ProducerApi,
    private apiHelper: ApiHelper,
    private apiCache: ApiCache,
  ) {}

  public copy = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/copy', { body: params });
  }

  public copyRow(params?: any): Observable<any> {
    if(params) {
      return this.apiHelper.request(this.endpoint + '/copy-row', { body: params });
    } else {
      return this.apiHelper.request(this.endpoint + '/copy-row');
    }
  }

  public getNomHeader = (params: any, type: string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/nom-header/' + type, { body: params });
  }

  public getHeaderAndList = (params: any, type: string): Observable<any> => {
    return this.getNomHeaderAndList(params, type);
  }

  public getNomDateRange = (params: any, type: string): Observable<any> => {
    return this.apiHelper.request('nomination/nomination-date-range/filter' , { body: params, method: 'POST' });
  }

  public getNomHeaderAndList = (params: any, type: string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/nom-header-and-list/' + type, { body: params });
  }

  public getSubnomination(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/sub-nom/', {body: params});
  }

  public getLastNomDateForContract = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/last-nom-date-for-contract/' + params.contractId);
  }

  public getHeader = (params: any, type: string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/nom-header/' + type, { body: params });
  }

  public comment = (data: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/comment', { body: data });
  }

  public save = (params: any, type: string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/save/' + type, { body: params });
  }

  public validate = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/validate-nominations', { body: params });
  }

  public isFinPoint(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/finPoint', {body: params});
  }
  public getProducerPoints(asset: number): Observable<string[]> {
    return this.apiHelper.request(this.endpoint + '/getProducerPoints',
      { cache: this.apiCache.forSession, cacheTag: 'producerPoints_' + asset });
  }
  
  public getPointContract(filterData: Partial<ContractForNomLookup>): Observable<ContractForNomLookup> {
    return this.apiHelper.request(this.endpoint + '/pointContract', { body: filterData });
  }
}
