
import {of as observableOf,  Observable } from 'rxjs';
import { AutoUnsubscribables } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import { AutoUnsubscriber } from 'cad/shared/mixins/auto-unsubscriber.mixin';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Controller } from 'src/framing/controller';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { CadAppController } from 'src/features/common/cad-app/cad-app.controller';

import { ItemModel } from './item.model';
import { ItemView } from './item.view';
import { RouterService } from 'src/cad/core/services/router/router.service';
import { ItemApiService } from 'src/features/common/item/item-api.service';
import { BreadcrumbService } from 'src/features/common/breadcrumb/breadcrumb.service';
import * as _ from 'lodash';

@Component({
  selector: 'controller',
  template: '<router-outlet></router-outlet>',
})
export class ItemController extends Controller<ItemModel, ItemView> implements OnInit, OnDestroy {
  @AutoUnsubscriber() public subs: AutoUnsubscribables;
  public routeUrl: string;
  public router: RouterService;
  public apiHelper: ApiHelper;
  public itemApiService: ItemApiService;
  public breadcrumbService: BreadcrumbService;
  public cadAppController: CadAppController;

  private _disableActionMenu: boolean = false;

  constructor(
    injector: Injector,
  ) {
    super(injector);

    const route: ActivatedRoute = this.injector.get(ActivatedRoute);
    this.routeUrl = Controller.buildUrlLink(route.snapshot);

    this.router = this.injector.get(RouterService);
    this.apiHelper = this.injector.get(ApiHelper);
    this.cadAppController = this.injector.get(CadAppController);
    this.itemApiService = this.injector.get(ItemApiService);
    this.breadcrumbService = this.injector.get(BreadcrumbService);
  }

  ngOnInit(): void {
    this.cadAppController.updateSearchView(this.view.searchView, this.injector);
    if (this._disableActionMenu) {
      this.cadAppController.disableActionMenu();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy(); // mandatory
    if (this.cadAppController.view.searchInjector === this.injector) {
      this.cadAppController.updateSearchView(null, null);
      this.cadAppController.disableSearchState();
    }
    if (this._disableActionMenu) {
      this.cadAppController.enableActionMenu();
    }
  }

  public disableActionMenu(): void {
    this._disableActionMenu = true;
  }
  /**
   *
   */
  public initNewItem(ctrl: any): void {}

  /**
   *
   */
  public initCopyItem(ctrl: any): void {}

  /**
   *
   */
  public preSaveNewItem(ctrl: any): void {}

  /**
   *
   */
  public postSaveNewItem(ctrl: any, result: any): void {}

  /**
   * Called before the delete api is run
   * @param ctrl
   */
  public preDeleteItem(ctrl: any): Observable<boolean> { return observableOf(true); }

  /**
   * Called after the delete api returns
   * @param ctrl
   * @param result
   */
  public postDeleteItem(ctrl: any, result: any): void {}

  public navigateToItem(itemData: any, params?: any): Promise<boolean> {
    let detailPath: string;
    let navArr: any[];
    let isSameUrlNavigation: boolean = false;
    let lastNavigationData: any;

    if (this.apiHelper) {
      detailPath = this.apiHelper.url('/' + this.getDetailPath(), itemData).substr(1);
      if (this.router) {
        isSameUrlNavigation = this.apiHelper.url(this.routeUrl + '/' + this.getDetailPath(), itemData) === this.router.url;
      }
    }
    if (!detailPath || detailPath === '') {
      console.warn('No detail Path generated - this may be a problem');
    }
    navArr = this.model.compositeKey ? [ this.routeUrl, ...detailPath.split('/') ] : [ this.routeUrl, detailPath ];
    // Decoding the nav array items as encoding is added in apiHelper url function
    Object.keys(navArr).forEach((key: string) => navArr[ key ] = decodeURIComponent(navArr[ key ]));
    if (this.router) {
      if (this.model.primaryKeyAsObject
        && this.router.ngRouter
        && this.itemApiService) {
        lastNavigationData = this.itemApiService.getActiveItemData();
        if (isSameUrlNavigation
          && this.router.ngRouter.navigated
          && !_.isEqual(lastNavigationData, this.itemApiService.getProcessedData(itemData))) {
          this.router.ngRouter.navigated = false;
        }
        // Save activeItemData
        this.itemApiService.setActiveItemData(itemData);
        return (params
          ? this.router.navigateWithData(params, navArr)
          : this.router.navigate(navArr))
        .then((result: boolean) => {
          if (result) {
            if (this.breadcrumbService && (this.breadcrumbService as any).breadcrumbs) {
              (this.breadcrumbService as any).breadcrumbs =
                _.uniqWith((this.breadcrumbService as any).breadcrumbs, (value: any, other: any) => {
                  return _.isEqual(value.breadcrumb, other.breadcrumb);
                });
              (this.breadcrumbService as any).emitBreadcrumbs();
            }
          }
          return result;
        });
      } else {
        // Remove activeItemData since we are navigating to a non primaryKeyAsObject item
        this.itemApiService.setActiveItemData(null);
        return params
          ? this.router.navigateWithData(params, navArr)
          : this.router.navigate(navArr);
      }
    }

    return Promise.resolve(false);
  }

  public navigateRecent(): void {
    this.router.navigate([ this.routeUrl ]);
  }

  public getDetailPath(): string {
    if (this.model.compositeKey) {
      if (this.model.primaryKey) {
        throw new Error('Model can contain primary key or composite key, but not both.');
      }
      return ':' + this.model.compositeKey.join('/:');
    } else {
      return ':' + this.model.primaryKey;
    }
  }
}
