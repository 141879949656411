import { Injectable } from '@angular/core';


import { TableComponent } from '../table/table.component';
import { UiGridApi } from 'src/ag-grid-wrapper';

@Injectable()
export class ColumnFilterFactory {

  constructor() {
  }

  public unRegisterGridListener (table: TableComponent): void {
    this.destroyColumnApi(table.api);
  }

  public onGridApiRegistered (table: TableComponent): void  {
    this.setColumnApi(table.api);
  }

  private destroyColumnApi (api: UiGridApi): void {
    _.defaultsDeep(api, {
      column: {
        clearAllFilters: null
      }
    });
  }
  private setColumnApi(api: UiGridApi): void {
    _.defaultsDeep(api, {
      column: {
        clearAllFilters: this.clearAllFilters(api),
      }
    });
  }

  public clearAllFilters(api: UiGridApi): any {
    return (): void => {
      let filterModel = api.getFilterModel();

      let keys: string[] = _.keys(filterModel);
      _.forEach(keys, (key)=> {
        let filterComp = api.getFilterInstance(key);
        filterComp.setModel(null);
      });
      api.onFilterChanged();
    };

  }

}
