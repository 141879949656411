import { Directive, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { dateValueFormatter, TableColumnComponent } from 'src/ag-grid-wrapper';
import { DatepickerEditorComponent } from './datepicker-editor.component';

@Directive({
  selector: 'ui-table-column[datepickerEditor]',
})
export class DatepickerEditorDirective implements OnInit, OnChanges {
  @Input() minDate: string;
  @Input() maxDate: string;

  constructor(public tableColumn: TableColumnComponent) {}

  ngOnInit(): void {
    this.tableColumn.type = 'date';
    this.tableColumn.customEditor = DatepickerEditorComponent;
    this.tableColumn.valueFormatter = dateValueFormatter;
    this.setCustomCellConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('minDate') || changes.hasOwnProperty('maxDate')) {
      this.setCustomCellConfig();
    }
  }

  setCustomCellConfig(): void {
    if(this.minDate || this.maxDate) {
      this.tableColumn.customCellConfig = {
        minDate: this.minDate,
        maxDate: this.maxDate
      };
    }
  }
}
