import { Component, Inject } from '@angular/core';
import { TABLE_RENDERER_DATA } from 'src/ag-grid-wrapper';

/**
 *
 **/

@Component({
  selector: 'table-column-duns-number-renderer',
  template: `
    <div class="inner-cell">
      <div class="inner-cell-dropdown-value">{{params.value | dunsNumber}}</div>
    </div>
  `,
})
export class DunsNumberRendererComponent {
  constructor(@Inject(TABLE_RENDERER_DATA) public params: any) {}
}
