/**
 * @name uiLoginButton
 * @module common.components.toolbar.uiAlertsButton
 *
 * @description
 * `<ui-alerts-button>`
 *
 * @param {string} heading
 * @param {string} class
 * @param {string} top
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertsService } from 'cad/core/services/alerts/alerts.service';
import * as _ from 'lodash';

@Component({
  selector: 'ui-alerts-button',
  templateUrl: './alerts-button.component.html',
  styleUrls: [ './alerts-button.component.less' ],
})
export class UiAlertsButtonComponent implements OnInit, OnDestroy {

  public statusIcon: string = 'notifications_none';
  public statusClass: string = 'status.white';

  private subscriptions: Subscription[] = [];

  constructor(
    private alertsService: AlertsService,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(this.alertsService.alerts$.subscribe(
      (alerts: cad.Alert[]) => {
        if (alerts && alerts.length) {
          const highestSeverityAlert: cad.Alert = _.head(_.sortBy(alerts, 'severity'));
          this.statusIcon = 'notifications_active';
          this.statusClass = highestSeverityAlert.alertClass;
        } else {
          this.statusIcon = 'notifications_none';
          this.statusClass = 'status.white';
        }
      },
    ));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public toggleAlertsSidenav(): void {
    this.alertsService.toggleAlertsSidenav();
  }
}
