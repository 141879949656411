import { ApiHelper } from '../api-helper';
import { Injector } from '@angular/core';
import { Observable } from 'rxjs';

export class UploadApiBase {
  apiHelper: ApiHelper;
  endpoint: string;
  constructor(private injector: Injector, endpoint: string) {
    this.apiHelper = this.injector.get(ApiHelper);
    this.endpoint = endpoint;
  }
    
  load(param: number | any) : Observable<any> {
    const isGetRequest: boolean = typeof param === 'number'; 
    const query = isGetRequest ? `?uploadId=${param}` : '';
    return this.apiHelper.request(`${this.endpoint}${query}`, !isGetRequest && { body: param});
  }

  save(params: any[]): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/save`, {body: params});
  }
}
