import {Injectable} from '@angular/core';
import {ApiCache} from 'cad/common/services/api/api-cache';
import {StoreState} from 'cad/common/store/core/store-state';

@Injectable()
export class UserCacheService {

  constructor(private cache: ApiCache) {
  }

  public getUserState(): StoreState {
    return this.cache.forSession.get('userState');
  }

  public setUserState(userState: StoreState): void {
    this.cache.forSession.put('userState', userState);
  }


  public removeUserState(): void {
    this.cache.forSession.remove('userState');
  }

}
