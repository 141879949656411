import { Injectable } from '@angular/core';
import { UserCacheService } from 'cad/common/store/user/services/user-cache.service';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { version } from 'package.json';

@Injectable()
export class ConfigService {
  cadConfig: any = environment;
  version: string = version;

  constructor(public userCacheService: UserCacheService) {}

  public getInfoPostBaseUrl(): string {
    if (this.userCacheService
      && this.userCacheService.getUserState()
      && this.userCacheService.getUserState().user
      && this.userCacheService.getUserState().user.currentContext) {
      let assetAbbr: string = this.userCacheService.getUserState().user.currentContext.assetAbbr;

      // TODO: Remove the following IF statement once FGT Messenger site is up
      if (_.toLower(assetAbbr) === 'fgt') {
        return this.cadConfig.infoPostBaseUrl + '/' + assetAbbr;
      }
      return _.toLower(this.cadConfig.infoPostBaseUrl).replace(
        'peplmessenger',
        _.toLower(assetAbbr) + 'messenger'
      ) + '/' + assetAbbr;
    }
    return this.cadConfig.infoPostBaseUrl;
  }
}
