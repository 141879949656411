import * as moment from 'moment';
import { ICellRendererParams } from '@ag-grid-community/core';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';

function transformDateValue(value: any, format: string): string {
  let pipe: DatePipe = new DatePipe('en-US');
  let parsedValue: string = _.defaultTo(value, null);
  let parsedFormat: string = format || 'MM-dd-yyyy';

  if (moment(parsedValue).isValid()) {
    let isUTC: boolean = parsedFormat.replace(/'/g, '').includes(':UTC');
    
    if (isUTC) {
      parsedValue = dateUtil().getNonMomentParsedDate(parsedValue).utc().toISOString();
      parsedFormat = parsedFormat.replace(/:'UTC'/, '');
    }
    return pipe.transform(
      // Fix for Moment Epoch DST calculation not being available past year 2037
      (isPast2037(parsedValue) ? dateUtil().getNonMomentParsedDate(parsedValue).utc().format() : dateUtil().getNonMomentParsedDate(parsedValue)),
      parsedFormat.replace(/date:/g, '').replace(/'/g, ''),
      !isUTC ?
        // Fix for Moment Epoch DST calculation not being available past year 2037
        (isPast2037(parsedValue)
          ? dateUtil().getNonMomentParsedDate(parsedValue).utc().format('Z')
          : dateUtil().getNonMomentParsedDate(parsedValue).format('Z'))
        : null
    );
  }
  return null;
}

export function dateValueFormatter(params: ICellRendererParams): string {
  let formattedValue: string = params ? params.value : null;

  if (params && params.colDef) {
    formattedValue = transformDateValue(
      _.get(params.data, params.colDef.field),
      ((params.colDef as any).cellFilter || 'MM-dd-yyyy')
    );
  }
  return formattedValue;
}

export function dateTimeValueFormatter(params: ICellRendererParams): string {
  let formattedValue: string = params ? params.value : null;

  if (params && params.colDef) {
    formattedValue = transformDateValue(
      _.get(params.data, params.colDef.field),
      ((params.colDef as any).cellFilter || 'MM-dd-yyyy hh:mm a')
    );
  }
  return formattedValue;
}

function isPast2037(dateValue: string | moment.Moment): boolean {
  return dateValue && dateUtil().getNonMomentParsedDate(dateValue).year() > 2037;
}
