import { Component, ViewContainerRef, ViewChild, AfterViewInit, Injector } from '@angular/core';
import { AgEditorComponent } from '@ag-grid-community/angular';
import { MatDialog } from '@angular/material/dialog';
import { TableColumnLookupBaseComponent } from './lookup-base.component';

@Component({
  moduleId: module.id + '',
  selector: 'table-column-lookup-editor',
  templateUrl: './lookup-editor.component.tpl.html',
  styles: [
    `
      button.mat-icon-button {
        right: 30px;
        height: 29px;
        line-height: 29px;
        top: 4px;
      }
      input {
        width: 100%;
      }
    `
  ]
})
export class TableColumnLookupEditorComponent extends TableColumnLookupBaseComponent implements AgEditorComponent, AfterViewInit {
  @ViewChild('input', { read: ViewContainerRef }) public input: any;

  private cancelBeforeStart: boolean = false;

  constructor(dialog: MatDialog, injector: Injector) {
    super(dialog, injector);
  }

  get lookupForceDialog(): boolean {
    return this.params.column.colDef.lookupForceDialog;
  }

  agInit(params: any): void {
    this.params = params;

    //this.cancelBeforeStart = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);

    // if the row is floating a.k.a footer row do not allow editing.
    if (this.params.node.floating) {
      this.cancelBeforeStart = true;
    }

    if (this.lookupForceDialog) {
      this.openDialog();
    }
  }

  getValue(): any {
    return this.params.value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  onKeyDown(event: any): void {
    if (!this.isKeyPressedNumeric(event)) {
      if (event.preventDefault) { event.preventDefault(); }
    }
  }

  onBlur(event: any): void {
    // this.params.api.stopEditing();
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit(): void {
    setTimeout((): void => {
      this.input.element.nativeElement.select();
    });
  }

  private isKeyPressedNumeric(event: any): boolean {
    let charCode: number = (event.which) ? event.which : event.keyCode;
    return !(charCode !== 46 && charCode > 31
      && (charCode < 48 || charCode > 57));
  }
}
