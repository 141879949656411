import { Injectable } from '@angular/core';
import { Availableasset } from 'cad/common/store/user/user-model';
import { UserStoreService } from 'cad/common/store/core/services/user-store.service';
import { UserService2 } from 'cad/common/store/user/services/user.service';
import { ProfileService } from 'cad/common/models/user/profile-service';
import { take, map } from 'rxjs/operators';
import { AlertsService } from 'cad/core/services/alerts/alerts.service';

@Injectable()
export class AssetService {
  constructor(private alertsService: AlertsService,
              private userStateStore: UserStoreService,
              private userService: UserService2,
              private userProfile: ProfileService) {
  }

  changeAsset(asset: Availableasset): void {
    this.userStateStore.stateChanged
      .pipe(
        take(1),
        map((userDetail) => userDetail.state.availableUsers.find((u) => !!u.availablecontexts[asset.name])))
      .subscribe((user) => {
        let baList = user && user.availablecontexts ? user.availablecontexts[asset.name] : [];
        if(baList.length === 0) {
          this.alertsService.danger('List of Business Associates was empty for asset:' + asset);
        } else {
          let ba = baList.find((b) => b.toLowerCase() === asset.name.toLowerCase());
          ba = ba ? ba : baList[0];
          this.userService.setUserContext(asset, ba);
        }
      });
  }

  changeBA(ba: string): void {
    this.userStateStore.stateChanged
      .pipe(
        take(1),
        map((userDetail) => this.userProfile.retrieveProfile(userDetail.state.user.userName).getAsset()))
      .subscribe((asset) => this.userService.setUserContext(asset, ba));
  }

}
