import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { PinnedSearchCacheService } from 'src/common/services/search/pinned-search-cache.service';

@Component({ template: '' })
export abstract class AllocationScheduleQuantityBase implements OnInit {
  search: any;
  headerData: any[] = [];
  listData: any[] = [];
  pinnedSearchCacheService: PinnedSearchCacheService;
  route: ActivatedRoute;
  isRetrieving$: Observable<boolean>;
  private _isRetrievingSubj: Subject<boolean> = new Subject<boolean>();
  constructor(injector: Injector) {
    this.pinnedSearchCacheService = injector.get(PinnedSearchCacheService);
    this.route = injector.get(ActivatedRoute);
  }

  abstract setApi(item: any): Observable<any>;

  abstract updateHeader(item: any): void;

  ngOnInit(): void {
    this.isRetrieving$ = this._isRetrievingSubj.asObservable();
    const { id: pointCd } = this.route.snapshot.params;
    this.search = {...this.pinnedSearchCacheService.getCachedItem(pointCd), pointCd};
    this.loadData();
  }


  loadData(): void {
    this._isRetrievingSubj.next(true);
    this.setApi(this.search)
      .pipe(take(1), finalize(() => this._isRetrievingSubj.next(false)))
      .subscribe((resp) => {
        const {headerData, listData} = resp;
        this.headerData = headerData;
        this.listData = listData;
        this.pinnedSearchCacheService.setCached({...this.search, ...resp.headerData});
      }, (err) => {
        if(err) {
          this.listData = [];
        }
      });
    this.updateHeader(this.search);
  }

  footerConfiguration: any[] = [
    {
      label: 'Total', order: 2, type: 'custom', aggFunction: (rowData: any[], field: string) => {
        if (field !== 'schemeValue') {
          const deliveryTotal: number = rowData.filter((item) => item.nomDirectionOfFlow === 'D').reduce((acc, cur) => {
            return cur[field] ? acc + cur[field] : acc;
          }, 0);
          const receivedTotal: number = rowData.filter((item) => item.nomDirectionOfFlow === 'R').reduce((acc, cur) => {
            return cur[field] ? acc + cur[field] : acc;
          }, 0);
          return isNaN(receivedTotal - deliveryTotal) ? 0 : `${receivedTotal - deliveryTotal}`;
        } else {
          return `${rowData.reduce((acc, cur) => {
            return cur[field] ? acc + (+cur[field]) : acc;
          }, 0)}`;
        }
      }
    }
  ];
}
