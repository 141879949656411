import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LocationContactsModalComponent } from './location-contacts-modal/location-contacts-modal.component';
import { ContractContactsComponent } from 'src/cad/common/components/contract-contacts/contract-contacts.component';

@Injectable()
export class ContactsService {

  constructor(
    private mdDialog: MatDialog,
  ) {

  }

  public showLocationContacts(locationId: string, title: string, assetNbr?: number, confirmationInfo?: boolean) : Observable<boolean> {
    const dialogRef: MatDialogRef<LocationContactsModalComponent> = this.mdDialog.open(LocationContactsModalComponent, {
      data: {
        locationId,
        title,
        confirmationInfo,
        assetNbr
      }
    });

    return dialogRef.afterClosed();
  }

  // uncomment the following lines if you want to use the service for the contracts contact modal  this has not been tested at all

  // public showContractContacts(contractId: string) : Observable<boolean> {
  //   const dialogRef: MatDialogRef<ContractContactsComponent> = this.mdDialog.open(ContractContactsComponent, {
  //     data: {
  //       contractId
  //     }
  //   });

  //   return dialogRef.afterClosed();
  // }
}
