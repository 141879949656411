import { Input, Output, EventEmitter, Component, OnInit } from '@angular/core';
import { UiBottomSheetService } from './bottom-sheet.service';
import { MatDialogRef } from '@angular/material/dialog';

interface SheetPosition {
  top?: string;
  bottom?: string ;
  left?: string;
  right?: string;
}

const TOP: SheetPosition = { top: '0px' };
const BOTTOM: SheetPosition = { bottom: '0px' };
const LEFT: SheetPosition = { top: '0px', bottom: '0px', left: '0px' };
const RIGHT: SheetPosition = { top: '0px', bottom: '0px', right: '0px' };

@Component({
  selector: 'ui-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: [ './bottom-sheet.component.less' ],
})
export class UiBottomSheetComponent implements OnInit {
  @Input() public headline: string;
  @Output() public closed: EventEmitter<any> = new EventEmitter<any>();

  public minimized: boolean = false;
  public animationFinished: boolean = false;

  constructor(public dialogRef: MatDialogRef<any>, public bottomSheetService: UiBottomSheetService) {
  }

  ngOnInit(): void {
  }

  closeSheet(): void {
    this.closed.emit();
    this.bottomSheetService.hide();
    this.minimized = false;
  }

  toggleMinimized(): void {
    this.bottomSheetService.toggleMinimized();
  }
}
