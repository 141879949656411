import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';


@Injectable()
export class GDSContractLookupApi {

  private readonly endpoint: string = 'flowing-gas/scheduling/gds-contract-lookup';

  constructor(
    private apiHelper: ApiHelper,

  ) {}

  public findAll = (): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/find');
  }
}
