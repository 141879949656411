<mat-card class="card-margin">
  <div mat-dialog-content>
      <div class="md-title header" class="title-padding">{{headLine}}</div>
        <mat-form-field>
          <input matInput
                 [value]="contactObj?.firstName + ' ' + contactObj?.lastName"
                 placeholder="Contact Name"
                 disabled
                 name="external_contact_detail_contactName"/>
        </mat-form-field>
        <div fxLayout="row">
          <mat-form-field>
            <input matInput
                   value="{{contactObj?.phoneNumber | phoneNumber}}"
                   placeholder="Phone"
                   disabled
                   name="phoneNumber"/>
          </mat-form-field>
          <mat-form-field fxFlexOffset="5">
            <input matInput
                   value="{{contactObj?.faxNumber | phoneNumber}}"
                   placeholder="Fax"
                   disabled
                   name="faxNumber"/>
          </mat-form-field>
        </div>
      <mat-form-field>
        <input matInput
               [value]="contactObj && contactObj.email ? contactObj.email : ''"
               placeholder="Email"
               uiInputHref="mailto:{{contactObj?.email}}"
               readonly
               name="email"/>
      </mat-form-field>
      <div fxLayout="row">
        <mat-form-field>
          <input matInput
                 [value]="contractId"
                 placeholder="Svc Req K"
                 name="contractId"
                 disabled/>
        </mat-form-field>
        <mat-form-field fxFlexOffset="5">
          <input matInput
                 [value]="contactObj?.dunsNbr"
                 placeholder="{{baLabel}}"
                 disabled
                 name="dunsNbr"/>
        </mat-form-field>
      </div>
      <mat-form-field>
        <input matInput
               [value]="contactObj?.baName"
               placeholder="{{baLabel}} Name"
               disabled
               name="baName"/>
      </mat-form-field>
  </div>
</mat-card>
