import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { NominationConfirmationLocationApi } from 'cad/common/services/api/nomination/confirmation/location/location';
import { NominationEdiConfirmationPinnedLocationApi } from 'cad/common/services/api/nomination/edi-confirmation/pinned-location/pinned-location';

@Injectable()
export class NominationEdiConfirmationApi {

  private readonly endpoint: string = 'nomination/edi-confirmation';

  constructor(
    public location: NominationConfirmationLocationApi,
    public pinnedLocation: NominationEdiConfirmationPinnedLocationApi,
    private apiHelper: ApiHelper,
  ) {}

  public getHeader = (params: any, type?: any): Observable<any> => {
    return this.apiHelper.request(`${this.endpoint}/header/${type || 'CONF'}`, { body: params });
  }

  public getHeaderAndList = (params: any, type?: any): Observable<any> => {
    return this.apiHelper.request(`${this.endpoint}/header-and-list/${type || 'CONF'}`, { body: params });
  }

  // public getHistory = (id: string | number): Observable<any> => {
  //   return this.apiHelper.request(this.endpoint + '/history/' + id);
  // }

  // public getDateRanges = (params: any, type: any): Observable<any> => {
  //   return this.apiHelper.request(this.endpoint + '/confirmation-date-ranges/' + type, { body: params });
  // }

  // public save = (params: any, type: any): Observable<any> => {
  //   return this.apiHelper.request(this.endpoint + '/save/' + type, { body: params });
  // }
}
