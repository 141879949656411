<button *ngIf="!params.node.floating && menuItems.length > 1 &&
isValidRow && isMenuItemsHidden(menuItems)"
        mat-icon-button
        type="button"
        (click)="open($event)"
        [matMenuTriggerFor]="menu">
  <mat-icon>more_vert</mat-icon>
  <mat-menu class="row-action-menu-content"
            #menu="matMenu">
    <span *ngFor="let item of menuItems">
    <button *ngIf="!isHidden(item)"
            type="button"
            mat-menu-item
            (click)="onClick(item)"
            [disabled]="(item | disabledPipe:params)
            || (item.securityAction | securityDisabled:item.securityType:item.securityContext)">
      <mat-icon>{{item.icon}}</mat-icon>
      <span>{{item.name}}</span>
    </button>
      </span>
  </mat-menu>
</button>

<button *ngIf="!params.node.floating && menuItems.length === 1 &&
!isHidden(menuItems[0]) && isValidRow"
        type="button"
        [disabled]="(menuItems[0] | disabledPipe:params)
        || (menuItems[0].securityAction | securityDisabled:menuItems[0].securityType:menuItems[0].securityContext)"
        mat-icon-button
        (click)="onClick(menuItems[0])"
        [matTooltip]="menuItems[0].name">
  <mat-icon>{{menuItems[0].icon}}</mat-icon>
</button>
