
import { Injectable } from '@angular/core';
import {ParametersApi} from 'src/cad/common/services/api/structured-products/parameters/parameters';
import {ComponentApi} from 'src/cad/common/services/api/structured-products/component/component';
import {PTPMatrixUploadApi} from 'src/cad/common/services/api/structured-products/ptp-matrix-upload/ptp-matrix-upload';
import {ProductServiceApi} from 'cad/common/services/api/structured-products/product-service/product-service';
import {RightPriceServiceApi} from 'cad/common/services/api/structured-products/right-price-service/right-price-service';
import { StaticParameterLookupApi } from 'cad/common/services/api/structured-products/lookup/static-parameter-lookup.api';

@Injectable()
export class StructuredProductsApi {
  constructor(
    public parametersApi: ParametersApi,
    public productServiceApi: ProductServiceApi,
    public rightPriceServiceApi:RightPriceServiceApi,
    public staticParameterLookupApi: StaticParameterLookupApi,
    public componentApi: ComponentApi

  ) {}
}
