
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ItemApi } from 'src/features/common/item/item-api';

@Injectable()
export class AgreementCapacityReviewApi extends ItemApi {

  private readonly endpoint: string = 'agreement/contract';
  
  constructor(
    public apiHelper: ApiHelper
  ) {
    super();
  }

  public getById(id: number) : Observable<any> {
    // Get by id does not apply to Capacity Review
    return observableOf({});
  }

  public find(params: any): Observable<any> {
    // Find does not apply to Capacity Review
    return observableOf({});
  }

  public deleteItem(id: number): Observable<any> {
    // Delete does not apply to Capacity Review
    return observableOf({});
  }

  public filter(params: any): Observable<any> {
    // Search does not apply to Capacity Review
    return observableOf([]);
  }

  public recent(): Observable<any> {
    // Dashboard does not apply to Capacity Review
    return observableOf([]);
  }

  public save(data: any): Observable<any> {
    // Save does not apply to Capacity Review
    return observableOf({});
  }

  public getCapacityReviewContracts(): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/capacity-review-contracts`);
  }
  
  public submitCapacityReviewForContract(data: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/submit-capacity-review', { body: data });
  }
  
  public getSeasonalPointInputs(contractId: any): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/${contractId}/seasonal-point-inputs`);
  }
  
  public getAmendmentChangeDetails(contractId: any): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/${contractId}/amendment-change-details`);
  }
}
