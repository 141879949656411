import { Component, ChangeDetectionStrategy, Injector, HostBinding } from '@angular/core';

import { FeatureComponent } from 'src/framing/feature.component';
import { CadAppController } from '../../cad-app.controller';
import { CadAppModel } from '../../cad-app.model';
import { CadAppView } from '../../cad-app.view';

@Component({
  selector: 'ui-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: [ './search-dropdown.component.less' ],
})
export class SearchDropdownComponent extends FeatureComponent<CadAppModel, CadAppView, CadAppController> {
  
  @HostBinding('class.search-open') get isSearchOpen(): boolean {
    return this.controller && this.controller.model && this.controller.model.isSearching;
  }
  
  constructor(controller: CadAppController, injector: Injector) { super(controller, injector); }
}
