import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import * as _ from 'lodash';

@Injectable()
export class AssetConfigParamApi {

  public readonly endpoint: string = 'administration/table-maintenance/asset-config-param';

  constructor(
    private apiHelper: ApiHelper
  ) {}

  public find(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint, { body: params });
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/dashboard');
  }

  public save(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save', { body: !_.isArray(params) ? [ params ] : params });
  }

  public deleteItem(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/delete', { body: !_.isArray(params) ? [ params ] : params });
  }

  public getProcessNames(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/process-names', { body: params });
  }
}
