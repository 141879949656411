import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class NominationHistoryApi {
  private readonly endpoint: string = 'nomination/nomination-history';

  constructor(private apiHelper: ApiHelper) {
  }

  public filter = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

}
