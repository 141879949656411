import { ICellRendererComp, RowNode } from '@ag-grid-community/core';

import { KeyCode } from '../../table.constants';
import { BooleanUtils } from './../../utils/boolean-utils';
import * as _ from 'lodash';

export class TableColumnToggleRenderer implements ICellRendererComp {
  public value: any;
  public eGui: HTMLElement;
  public mode: string;
  public rowNode: RowNode;
  protected params: any;
  protected modelType: any;
  protected appEl: Element;

  init(params: any): void {
    this.params = params;
    this.mode = 'render';
    this.eGui = document.createElement('div');
    if (!params.value) {
      this.eGui.innerHTML = '';
    }
    this.setTemplate();
    this.eGui.addEventListener('click', this.valueChanged);
    this.eGui.addEventListener('keydown', this.onKeyDown);
    this.modelType = this.params.column.colDef.toggleModelType;
    this.rowNode = (params.node as RowNode);
    this.rowNode.addEventListener('cellChanged', (event) => {
      this.cellChanged(event, params);
    });
  }

  documentListener = (e: MouseEvent): void => {
    if (!this.eGui) { return; }
    if (!(this.eGui as any).contains(e.target) && this.appEl.contains((e.target) as Element)) {
      this.params.api.stopEditing();
    }
  }

  destroy(): void {
    if (this.eGui) {
      this.eGui.removeEventListener('click', this.valueChanged);
      this.eGui.removeEventListener('keydown', this.onKeyDown);
      this.eGui = null;
    }
    this.params = null;
    this.rowNode.removeEventListener('cellChanged', this.cellChanged);
  }

  get model(): any {
    return this.params.node.data[ this.params.column.colDef.field ];
  }

  set model(val: any) {
    this.params.node.data[ this.params.column.colDef.field ] = val;
  }

  getGui(): HTMLElement {
    return this.eGui;
  }

  cellChanged(event: any, params: any) : void {
    if(event.column.colId === params.column.colId) {
      params.oldValue = params.node.data[params.column.colDef.field];
      params.node.data[params.column.colDef.field] = event.newValue;
      if (this.mode === 'render' && this.eGui ) {
        this.eGui.innerHTML = this.getTemplate(event.newValue);
      }
    }
  }

  onKeyDown(e: KeyboardEvent): void {
    if (e.keyCode === KeyCode.Tab) {
      this.stopAndPrevent(e);
      if (e.shiftKey) {
        setTimeout(() => this.previousCell());
      } else {
        setTimeout(() => this.nextCell());
      }
    }

    if (e.keyCode === KeyCode.Spacebar) {
      this.stopAndPrevent(e);
      this.valueChanged(e);
    }
  }

  stopAndPrevent(e: any): void {
    e.stopPropagation();
    e.stopImmediatePropagation();
    e.preventDefault();
  }

  previousCell(): void {
    if (this.params) {
      if (!this.params.api.tabToPreviousCell()) {
        this.params.stopEditing();
      }
    }
  }

  nextCell(): void {
    if (this.params) {
      if (!this.params.api.tabToNextCell()) {
        this.params.api.stopEditing();
      }
    }
  }

  public toggleClicked = ($event: any): void => {
    $event.stopPropagation();
  }

  public valueChanged = ($event: any): void => {
    this.toggleClicked($event);
    if (!this.isEditable()) { return; }
    this.toggleValue();
    const gridRow: any = this.params.node;
    this.params.api.rowEdit.setGridRowDirty(this.params.api, gridRow);
    this.params.api.refreshCells({ rowNodes: [ gridRow ] });
    this.params.newValue = this.model;
    this.params.api.eventService.dispatchEvent({
      api: this.params.api,
      type: 'cellValueChanged',
      node: this.params.node,
      column: this.params.column }, this.params);
    if (this.mode === 'render') {
      this.setTemplate();
    }
  }

  public isChecked = (): boolean => {
    const modelValue: any = this.params.node.data[ this.params.column.colDef.field ];
    return BooleanUtils.toBool(modelValue);
  }

  public toggleValue(): void {
    this.params.oldValue = this.model;
    this.model =  BooleanUtils.toggleValue(this.model);
  }

  public isEditable(): boolean {
    if (this.params) {
      if (!this.params.colDef) {
        return true;
      } else if (_.isFunction(this.params.colDef.editable)) {
        return this.params.colDef.editable(this.params);
      } else {
        return this.params.colDef.editable;
      }
    } else {
      return false;
    }
  }

  public refresh(params: any): boolean {
    this.params = params;
    if (this.mode === 'edit') {
      this.setTemplate();
    }
    return true;
  }

  public getValue(): any {
    return this.model;
  }

  private getTemplate(state: boolean): string {
    return `
    <span class="ag-selection-checkbox" ${!this.isEditable() ? 'disabled' : ''}>
      <span class="ag-icon ag-icon-checkbox-${state ? '' : 'un'}checked"></span>
    </span>
    `;
  }

  private setTemplate(): void {
    this.eGui.innerHTML = this.getTemplate(this.isChecked());
  }

  afterGuiAttached(): void {
    if (this.mode === 'render') {
      this.eGui.focus();
    }
  }
}
