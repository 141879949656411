import {ApiHelper} from 'cad/common/services/api/api-helper';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import ValidationResult = cad.ValidationResult;
import {PowerPlantBalancingConfiguration} from 'cad/flowing-gas/common/models/power-plant-balancing-configuration';

@Injectable()
export class PowerPlantBalancingConfigurationApi {

  private readonly endpoint: string = 'flowManager/power-plant-balancing-config';

  constructor(
        private apiHelper: ApiHelper) {
  }

  public find(): Observable<PowerPlantBalancingConfiguration[]> {
    return this.apiHelper.request(this.endpoint + '/dashboard');
  }

  public save(params: PowerPlantBalancingConfiguration[]): Observable<ValidationResult<PowerPlantBalancingConfiguration[]>> {
    return this.apiHelper.request(`${this.endpoint}/save`, { body: params });
  }

}
