<mat-menu #childMenu="matMenu" [class]="'child-menu-panel'">
  <span *ngFor="let child of item">
    <span *ngIf="child.children && child.children.length > 0">
      <button mat-menu-item
              class = "main-menu-item"
              [matMenuTriggerFor]="menu.childMenu"
              *ngIf="(child.enabled && child.visible)">
        {{child.label}}
      </button>
      <ui-menu-item #menu [item]="child.children"></ui-menu-item>
    </span>
    <span *ngIf="!child.children || child.children.length === 0">
      <button mat-menu-item
              class = "main-menu-item"
              (click)="navigate(child)"
              *ngIf="((child.enabled && child.visible))">
        {{child.label}}
      </button>
    </span>
  </span>
</mat-menu>
