import { Directive, Input, OnInit } from '@angular/core';
import { TableColumnComponent } from 'src/ag-grid-wrapper/column/column.component';
import { PhoneNumberEditorComponent } from './phone-number-editor.component';
import { PhoneNumberRendererComponent } from './phone-number-renderer.component';
import { PhoneNumberPipe } from 'src/cad/common/filters/phone-number/phone-number.pipe';
import * as _ from 'lodash';

@Directive({
  selector: 'ui-table-column[phoneNumber]',
  providers: [ PhoneNumberPipe ]
})
export class PhoneNumberDirective implements OnInit {
  constructor(public tableColumn: TableColumnComponent, public pipe: PhoneNumberPipe) {}
  ngOnInit(): void {
    this.tableColumn.type = 'custom';
    this.tableColumn.customEditor = PhoneNumberEditorComponent;
    // this.tableColumn.customRenderer = PhoneNumberRendererComponent;
    this.tableColumn.valueGetter = (params: any): string => {
      let val: any;

      if (params && params.data) {
        val = _.get(params.data, params.colDef.field);
      } else if (params && !_.isNil(params.value)) {
        val = params.value;
      }
      return this.pipe.transform(val);
    };
  }
}
