import { Injector, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AssociationController } from 'src/features/common/association/association.controller';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AssociationApi } from 'src/features/common/association/association-api';

@Injectable()
export class PhysicalLocationGroupLocationApi implements AssociationApi {
  private readonly endpoint: string = 'physical/location-groups/locations';

  static createAssociationApi(
    apiHelper: ApiHelper,
    associationController: AssociationController,
    injector: Injector,
  ): AssociationApi {
    return new PhysicalLocationGroupLocationApi(apiHelper);
  }

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public findAll = (code: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/' + code);
  }

  public save = (locations: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/' + locations[0].code, { body: locations });
  }
}
