import { Component, Inject, Input } from '@angular/core';
import { TABLE_RENDERER_DATA } from 'src/ag-grid-wrapper';

/**
 *
 * This is an example of a custom renderer for the ag-grid-wrapper.  In order for this to work we need to inject the TABLE_RENDERER_DATA
 * provider which gives us access to the params object.  This object contains everything we need to work with the table column, including
 * column definition, grid api, etc...  The value of your renderer should be bound to params.value.  This renderer can be used directly by the
 * table column or it can be wrapped in a shared directive and placed in src/cad/shared/table-renderers to remove boilerplate for users which
 * is preferable if you are creating a renderer that should be reusable
 *
 * Example of Direct Usage:
 *
 *    ```@Component({
 *         selector: 'association-list-partial',
 *         templateUrl: './the-template.component.html',
 *       })
 *       export class AssociationListPartial {
 *         // don't forget to import SelectEditorComponent
 *         public etCodeRendererComponent: Type<ETCodeRendererComponent>;
 *         constructor() {
 *            this.etCodeRendererComponent = ETCodeRendererComponent;
 *         }
 *       }
 *
 *    later in component template...
 *
 *    ```<ui-table-column
 *           name="test"
 *           display-name="test"
 *           [customRenderer]="etCodeRendererComponent"
 *           type="custom">
 *       </ui-table-column>```
 *
 * Example of Directive Wrapper:
 *    see src/cad/shared/table-renderers/et-code/et-code-renderer.directive.ts
 *
 **/

@Component({
  selector: 'table-column-et-code-renderer',
  template: `
    <div class="inner-cell">
      <div class="inner-cell-dropdown-value">{{params.value}}</div>
    </div>
  `,
})
export class ETCodeRendererCDComponent {
  constructor(@Inject(TABLE_RENDERER_DATA) public params: any) {

  }
}
