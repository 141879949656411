import { Component, ChangeDetectionStrategy, Injector } from '@angular/core';

import { FeatureComponent } from 'src/framing/feature.component';
import { CadAppController } from '../../cad-app.controller';
import { CadAppModel } from '../../cad-app.model';
import { CadAppView } from '../../cad-app.view';

@Component({
  selector: 'ui-search-menu',
  templateUrl: './header-toolbar-search-menu.component.html',
  styleUrls: [ './header-toolbar-search-menu.component.less' ],
})
export class HeaderToolbarSearchMenuComponent extends FeatureComponent<CadAppModel, CadAppView, CadAppController> {
  constructor(controller: CadAppController, injector: Injector) { super(controller, injector); }
}
