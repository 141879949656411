import { Component, ElementRef, Inject, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { TABLE_EDITOR_DATA } from 'src/ag-grid-wrapper';
import { TableColumnCustomEditorComponent } from 'src/ag-grid-wrapper/column/custom/custom-editor.component';
import { UiLookupComponent } from 'src/common/components/form/lookup/lookup.component';
import * as _ from 'lodash';

@Component({
  selector: 'cad-lookup-editor',
  templateUrl: './lookup-editor.component.html',
  styleUrls: [ './lookup-editor.component.less' ]
})
export class LookupEditorComponent implements AfterViewInit, OnDestroy {

  @ViewChild('uiLookupRef') public uiLookupRef: UiLookupComponent;
  public inputElementRef: HTMLInputElement;
  public customCellConfig: any;
  public defaultSearchParams: any;

  constructor (
    @Inject(TABLE_EDITOR_DATA) public params: any,
    public elementRef: ElementRef,
    @Inject(TableColumnCustomEditorComponent) public tableColumnCustomEditorComponent: TableColumnCustomEditorComponent
  ) {
    if (this.tableColumnCustomEditorComponent) {
      // Override the cellEditor's getValue method to fix lookup input value change
      // since the model is not being updated on time
      this.tableColumnCustomEditorComponent.getValue = this.getValue.bind(this);
    }
    if (this.params
      && this.params.column
      && this.params.column.getColDef()) {
      this.customCellConfig = (_.isFunction(this.params.column.getColDef().customCellConfig)
        ? this.params.column.getColDef().customCellConfig(this.params)
        : this.params.column.getColDef().customCellConfig);
    }
    if (this.params
      && this.params.node
      && this.params.node.data
      && this.customCellConfig
      && this.customCellConfig.searchOnColumns) {
      Object.keys(this.customCellConfig.searchOnColumns).forEach((col: any) => {
        this.customCellConfig.defaultSearchParams = this.customCellConfig.defaultSearchParams || {};
        _.assign(this.customCellConfig.defaultSearchParams, {
          [ col ]: this.params.node.data[ this.customCellConfig.searchOnColumns[ col ] ]
        });
      });
    }
    if (this.customCellConfig) {
      if (this.customCellConfig.autocompleteModel) {
        this.customCellConfig.autocompleteModel.autoWidth = true;
      }
      if (this.params.column
        && this.params.column.getColDef()
        && this.params.column.getColDef().type === 'custom'
        && !this.customCellConfig.useAutocomplete) {
        // Change the colDef type to 'customLookup' to fix up and down arrow cell navigation
        // for custom column type for the lookup if autocomplete is not used
        this.params.column.getColDef().type = 'customLookup';
      }
    }
  }

  public ngAfterViewInit(): void {
    if (this.elementRef && this.elementRef.nativeElement) {
      this.inputElementRef = this.elementRef.nativeElement.querySelector('input');
    }
    if (this.uiLookupRef && this.customCellConfig && this.customCellConfig.autoOpen) {
      this.uiLookupRef.openModal();
    } else {
      setTimeout(() => {
        if (this.inputElementRef) {
          this.inputElementRef.focus();
          this.inputElementRef.select();
        }
      });
    }
  }

  public ngOnDestroy(): void {
    this.inputElementRef = null;
    this.params = null;
  }

  public getValue(): any {
    if (!this.params) {
      return null;
    }
    // Fix clearing the autocomplete input value or look up keyboard entered input value since
    // the model change is not triggering on time
    if (this.inputElementRef
      && ((this.customCellConfig && !this.customCellConfig.useAutocomplete && !this.isLookupModalOpen())
      || (this.isAutocompleteOpen() && (_.isNil(this.inputElementRef.value) || this.inputElementRef.value === '')))) {
      this.params.value = this.inputElementRef.value;
    }
    return this.params.value;
  }

  public onModelChange(event: any): void {
    if (this.params
      && this.params.node
      && this.params.node.data
      && this.params.column
      && this.params.column.getColDef()) {
      if (_.isObject(event)) {
        let colField: string = this.params.column.getColDef().field;

        this.params.value = event[ colField ];
        _.assign(this.params.node.data, _.omit(event, [ 'dirtyStatus', 'modifiedFields', colField ]));
      } else {
        this.params.value = event;
      }
      if (this.customCellConfig && this.customCellConfig.updateOnSelection) {
        if (_.isFunction(this.params.stopEditing)) {
          this.params.stopEditing();
        } else if (this.params.api && _.isFunction(this.params.api.stopEditing)) {
          this.params.api.stopEditing();
        }
      }
    }
  }

  public isLookupModalOpen(): boolean {
    return (this.uiLookupRef && this.uiLookupRef.lookupModalOpen);
  }

  public isAutocompleteOpen(): boolean {
    return (this.customCellConfig
    && this.customCellConfig.useAutocomplete
    && this.uiLookupRef
    && this.uiLookupRef.autocompleteOpen);
  }
}
