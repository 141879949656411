
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpResponseTypeEnum } from 'cad/common/services/api/api-enums';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ConfigService } from 'cad/core/services/config/config.service';
import { ItemApi } from 'src/features/common/item/item-api';

@Injectable()
export class InvoicingApi extends ItemApi {
  public endpoint: string = 'settlement/invoicing/';
  public urlContacts: string = '/contacts/';

  constructor(
    private apiHelper: ApiHelper,
    private configService: ConfigService
  ) {
    super();
  }

  // ========================================
  // implementation
  // ========================================
  public getAccountingPeriod = (): Observable<any> => {
    return this.apiHelper.request(this.endpoint + 'accounting-period' );
  }

  public find(params: mplus.InvoiceData): Observable<any> {
    return this.apiHelper.request(this.endpoint + params.invoiceId + this.urlContacts + params.contactId).pipe(map((resp: any) => {
      if (resp && resp.address) {
        resp.rawAddress = resp.address;
        resp.address = resp.address.replace(/\n/g, ' ');
      }
      return resp;
    }));
  }

  public findAll = (params: mplus.InvoiceData): Observable<any> => {
    return this.apiHelper.request(this.endpoint, { body: params });
  }

  public filter(params: mplus.InvoiceData): Observable<any> {
    if (params.accountingPeriod) {
      params.accountingPeriod = moment(params.accountingPeriod).startOf('day').format();
    }
    return this.apiHelper.request(this.endpoint + 'filter', { body: params });
  }

  public save(item: any): Observable<any> {
    if (item[this.configService.cadConfig.primaryKey[0]]) {
      return this.apiHelper.request(this.endpoint, { body: item });
    } else {
      return this.apiHelper.request(this.endpoint, { method: 'PUT', body: item });
    }
  }

  // looks like mplus.InvoiceData but that interface doesn't have .emailAddress
  public sendInvoice = (item: any): Observable<any> => {
    let data: any = { // mplus.InvoiceEmailFaxData
      accountingPeriod: item.accountingPeriod,
      emailAddress: item.emailAddress,
      faxNumber: item.faxNumber,
    };

    return this.apiHelper.request(this.endpoint + 'send/' + item.instanceId, { body: data });
  }

  public recent(): Observable<any> {
    // invoicing has no dashboard
    return observableOf([]);
  }

  public deleteItem(item: any): Observable<any> {
    return observableOf({msg:'*** DELTE NOT IMPLEMENTED HERE ***'});
  }

  public holdInvoice(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + 'hold-invoice', { body: params });
  }

  public releaseInvoice(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + 'release-invoice', { body: params });
  }

  public saveInvoiceDates(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + 'save-invoice-dates', { body: params, method: 'POST' });
  }

  public reviseInvoice(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + 'revise-invoice', { body: params });
  }
  
  public viewInvoices(idList: number[]): Observable<Blob> {
    return this.apiHelper.request(`${this.endpoint}invoices/view`, { body: idList, responseType: HttpResponseTypeEnum.BLOB });
  }
}
