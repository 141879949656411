import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ApiHelper} from 'cad/common/services/api/api-helper';
import {Workflow} from 'cad/administration/table-maintenance/shared/interfaces/workflow';
import {ObaMonitoring} from 'cad/flowing-gas/oba-monitoring/shared/interfaces/oba-monitoring';
import {OBAMonitoringSearch} from 'cad/flowing-gas/oba-monitoring/shared/interfaces/oba-monitiroing-search';
import {PriorPeriodAdjustment} from 'cad/flowing-gas/oba-monitoring/shared/interfaces/prior-period-adjustment';
import {DailyBalanceDetails} from 'cad/flowing-gas/oba-monitoring/shared/interfaces/daily-balance-details';
import {MeterPointDetails} from 'cad/flowing-gas/oba-monitoring/shared/interfaces/meter-point-details';

@Injectable()
export class OBAMonitoringApi {

  private readonly endpoint: string = 'flowing-gas/oba-imbalance';

  constructor(
    private apiHelper: ApiHelper) {
  }

  public getAll(params: OBAMonitoringSearch): Observable<ObaMonitoring[]> {
    return this.apiHelper.request(this.endpoint +'/dashboard',{body: params});
  }

  public getPriorPeriodAdjustment(params: ObaMonitoring): Observable<PriorPeriodAdjustment[]> {
    return this.apiHelper.request(this.endpoint + '/oba-ppa', {body: params});
  }

  public getDailyBalanceDetails(params: ObaMonitoring): Observable<DailyBalanceDetails[]> {
    return this.apiHelper.request(this.endpoint +'/daily',{ body: params });
  }

  public getReceiptMeterPointDetails(params: ObaMonitoring): Observable<MeterPointDetails[]> {
    return this.apiHelper.request(this.endpoint +'/meter-point/receipt',{ body: params });
  }

  public getDeliveryMeterPointDetails(params: ObaMonitoring): Observable<MeterPointDetails[]> {
    return this.apiHelper.request(this.endpoint +'/meter-point/delivery',{ body: params });
  }

}
