import { UiForm } from './../../../../../common/components/form/form';
import { UiFormComponent } from 'src/common/components/form/form.component';
import { UiFormParent } from 'src/common/components/form/form';
import { DynamicLookupComponent } from './../dynamic-lookup/dynamic-lookup.component';
import { FeatureComponent } from 'src/framing/feature.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AfterViewInit, Component, ComponentRef, Inject, Injector, ViewChild } from '@angular/core';
import { ModalFeatureBody } from './../../modal-feature-types';

import { ModalView } from './../../modal.view';
import { ModalModel } from './../../modal.model';
import { ModalController } from '../../modal.controller';
import { Observable } from 'rxjs';

@Component({
  selector: 'modal-skeleton',
  templateUrl: './modal-skeleton.component.html'
})
export class ModalSkeletonComponent extends FeatureComponent<ModalModel, ModalView, ModalController> implements AfterViewInit, UiFormParent {
  @ViewChild(DynamicLookupComponent) private lookupComp: DynamicLookupComponent;
  @ViewChild(UiFormComponent) public uiFormComponent: UiFormComponent;
  public modalBody: ModalFeatureBody;
  public controller: ModalController;
  public modalType: string;
  public modalInjector: Injector;
  public instanceType: string;
  public defaultSearchParams: string;
  public constSearchParams: string;

  constructor(private dialogRef: MatDialogRef<ModalSkeletonComponent>, @Inject(MAT_DIALOG_DATA) public data: any, injector: Injector) {
    super(data.controller, injector);
    this.controller = data.controller;
    this.instanceType = data.instanceType;
    this.defaultSearchParams = data.defaultSearchParams;
    this.constSearchParams = data.constSearchParams;
    this.modalInjector = Injector.create([
      {
        provide: ModalController,
        useValue: this.controller,
      },
      {
        provide: UiFormParent,
        useFactory: () => this.injector.get(ModalSkeletonComponent),
        deps: [],
      },
    ], injector);
  }

  public get uiForm(): UiForm { return this.uiFormComponent; }
  public get canSubmit(): boolean { return true; }

  ngAfterViewInit(): void {
    if (this.controller.model.type === 'lookup') {
      this.modalBody = this.lookupComp;
    }
  }

  onComponent(component: ComponentRef<ModalFeatureBody>): void {
    this.modalBody = component.instance;
  }

  submit(): void {
    /**
     * Basic modals are for information only.  When the user is
     * closing it, no action is needed.  Just return true and do
     * nothing
     */
    if (this.controller.model.type === 'basic') {
      this.dialogRef.close(true);
      return;
    }

    /**
     * if the model returns an observable, subscribe to the returned observable
     * to check if save was successful.  If it was, close the dialog and pass along
     * the result.  If not, do nothing to allow showing error messages to the user.
     */
    let obs = this.modalBody.onClose();
    if (obs instanceof Observable) {
      this.subs.newSub = obs.subscribe((result) => {
        if (result && result.successful) {
          this.dialogRef.close(result);
        }
      });
    } else {
      /**
       * If the return value isn't an observable, we can assume that it's ok to send
       * the data back immediately.
       */
      let returnData: any = this.modalBody.onClose();
      this.dialogRef.close(returnData);
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
