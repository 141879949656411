import { ChangeDetectionStrategy, Component, HostBinding, ViewChild } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { RowActionItem } from './action-item';
import * as _ from 'lodash';
import { ActionService } from 'cad/common/services/security/action.service';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  moduleId: module.id + '',
  selector: 'action-menu',
  templateUrl: 'action-menu.component.html',
  styleUrls: [ '../cell-icon-buttons.less' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RowActionMenuComponent implements AgRendererComponent {
  @ViewChild(MatMenuTrigger) public trigger: MatMenuTrigger;
  @HostBinding('attr.tabindex') public tabindex: number = -1;

  public menuItems: RowActionItem[] = [];
  isValidRow: boolean = true;

  constructor( private actionService: ActionService ) {
  }

  params: any = {};

  agInit(params: any): void {
    this.params = params;
    this.menuItems = params.colDef.data.items;
    if (_.isNil(params.data)) {
      this.isValidRow = false;
    }
  }

  public open = ($event: any): void => {
    $event.preventDefault();
    $event.stopPropagation();
    this.params.node.setSelected(true);
    this.trigger.openMenu();
  }

  public onClick = (item: RowActionItem): void => {
    if (item.method) {
      item.method(this.params.node.data, this.params);
    } else {
      console.error(`No method for row action item ${item.name}`);
    }
  }

  public isHidden(item: RowActionItem): boolean {
    // hidden status from menu item
    let menuItemHiddenStatus = false;
    if ( item.isHidden instanceof Function ) {
      menuItemHiddenStatus = item.isHidden(item.name, this.params);
    } else {
      menuItemHiddenStatus = item.isHidden ? true: false;
    }

    // hidden status from security context
    let securityHiddenStatus =  false;
    if ( item.securityAction ) {
      securityHiddenStatus = !this.actionService.isVisible(
        _.isNil(item.securityType) ? cad.uiProtection.PROTECTION_GROUP_SCREEN_FUNC: item.securityType,
        item.securityAction,
        item.securityContext
      );
    }

    return menuItemHiddenStatus || securityHiddenStatus;
  }

  /*
  RowMenu button will not be shown anymore if all the items in the menu are hidden
  */
  public isMenuItemsHidden(menuItems: RowActionItem[]): boolean {
    return menuItems.some((value) => !this.isHidden(value));
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

}
