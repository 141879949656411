import { ApiHelper } from './../../api-helper';
import { LookupApi } from 'src/features/common/modal/modal-feature-types';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SubNominationsApi implements LookupApi {

  public endpoint: string = 'nomination/sub-nom-config';

  constructor(private apiHelper: ApiHelper) {}
  
  filter (params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/location/filter`, { body: params });
  }

  dashboard (): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/dashboard`);
  }

  save (subNom: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}`, { body: subNom });
  }

  delete (subNomConfig: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}`, { method: 'DELETE', body: subNomConfig });
  }
}
