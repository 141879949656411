import {Component, OnInit} from '@angular/core';
import { BaseDashComponent } from 'src/cad/dashboard/views/content/components/base-dash.component';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { RouterService } from 'cad/core/services/router/router.service';
import { ActivatedRoute } from '@angular/router';
import { UserPreferenceService } from 'cad/common/services/preferences/user-preferences';
import { NominationMatrixPinnedContractApi } from 'cad/common/services/api/nomination/nomination-matrix/pinned-contract/pinned-contract';
import {map, shareReplay} from 'rxjs/operators';
import {of} from 'rxjs';

@Component({
  selector: 'nom-dash',
  templateUrl: './nom-dash.component.html',
})
export class NomDashComponent extends BaseDashComponent implements OnInit {
  public name: string = 'nom';

  constructor(public apiHelper: ApiHelper,
              public routerService: RouterService,
              public activatedRoute: ActivatedRoute,
              public userPreferenceService: UserPreferenceService,
              public nominationMatrixPinnedContractApi: NominationMatrixPinnedContractApi
              ) {
    super(apiHelper, routerService, activatedRoute, userPreferenceService);
  }

  ngOnInit(): void {
    if (this.userDetails && this.userDetails.userId) {
      this.itemData$ = this.nominationMatrixPinnedContractApi.filter()
        .pipe(
          shareReplay(),
          map((data) => this.trimData(data)));
    } else {
      this.itemData$ = of(null);
    }
    super.ngOnInit();
  }

  public getEmptyHeadline(): string {
    return 'No Pinned Nominations';
  }
  public getDefaultHeadline(): string {
    return 'Pinned Nominations';
  }
  public getName(): string {
    return this.name;
  }
}
