<form #form="ngForm" (ngSubmit)="close()">
  <ui-modal-header (close)="close()">Profile Type</ui-modal-header>
  <ui-modal-body>

    <div fxLayout="row" fxLayout.sm="column" class="col-padding add-profile-type" fxLayoutGap.gt-sm="12px">
      <div fxFlex="40">
        <ui-select
          [multiple]="true"
          name="profileTypeCd"
          placeholder="Profile Type"
          [(ngModel)]="newProfileType.profileTypeCd"
          codeType="CONTACTPROFILE"
          [disabledOptions]="disableList">
        </ui-select>
      </div>
      <div fxFlex>
        <ui-datepicker [(ngModel)]="newProfileType.effBeginDt" required placeholder="Effective Begin Date"
                       name="effBeginDt"></ui-datepicker>
      </div>
      <div fxFlex>
        <ui-datepicker [(ngModel)]="newProfileType.effEndDt" required placeholder="Effective End Date"
                       name="effEndDt"></ui-datepicker>
      </div>
      <div fxFlex="10" class="profile-type-action">
        <button mat-button mat-raised-button type="button"
                (click)="addProfileType(newProfileType)"
                [disabled]="!newProfileType?.profileTypeCd"
                title="Add Profile Type">Add
        </button>
      </div>
    </div>
    <div fxLayout="row">
      <h3><span>Active Profile Types</span></h3>
    </div>
    <div fxLayout="row" fxLayout.sm="column" class="col-padding" *ngFor="let item of items; let i=index">
      <form-group-observer (groupValueChange)="setDirtyStatus(i)" fxFlex>
        <div *ngIf="item.dirtyStatus !== 3"
             fxFlex=""
             fxLayout="row"
             fxLayoutGap.gt-sm="12px"
             fxLayout.lt-md="column">
          <div fxFlex>
            <ui-select
              uiInput
              [name]="'profileTypeCd' +i"
              placeholder="Profile Types"
              [(ngModel)]="items[i].profileTypeCd"
              codeType="CONTACTPROFILE"
              required
              [disabledOptions]="disableList"
              [attr.tabindex]="0">
            </ui-select>
          </div>
          <div fxFlex>
            <!--[uiInputMask]="columnMask(item.profileTypeCd)" -->
            <mat-form-field>
              <input matInput uiInput
                     [(ngModel)]="items[i].profileValue"
                     placeholder="Value"
                     required
                     *ngIf="!setMaskInput(item)"
                     [name]="'profileValue' +i"/>
              <input matInput uiInput
                     [(ngModel)]="items[i].profileValue"
                     placeholder="Value"
                     required
                     [uiInputMask]="setMaskInput(item)"
                     *ngIf="setMaskInput(item)"
                     [uiInputMaskOptions]="{autoUnmask:true, removeMaskOnSubmit:true}"
                     [name]="'profileValue' +i"/>
            </mat-form-field>
          </div>
          <div fxFlex>
            <ui-datepicker
              uiInput
              [name]="'effBeginDt' +i"
              placeholder="Effective Begin Date"
              [(ngModel)]="items[i].effBeginDt"
              required>
            </ui-datepicker>
          </div>
          <div fxFlex>
            <ui-datepicker
              uiInput
              [name]="'effEndDt' +i"
              placeholder="Effective End Date"
              [(ngModel)]="items[i].effEndDt"
              required>
            </ui-datepicker>
          </div>
          <div fxFlex="26px" class="profile-type-action" fxLayoutAlign="space-around center">
            <button type="button"
                    mat-button
                    mat-icon-button
                    color="primary"
                    (click)="removeProfileType(i)">
              <mat-icon md-font-set="material-icons"
                        title="Remove Profile Type"
                        [attr.tabindex]="0">delete</mat-icon>
            </button>
          </div>
        </div>
      </form-group-observer>
    </div>

  </ui-modal-body>

  <ui-modal-footer>
    <button mat-button mat-raised-button (click)="cancel()" type="button">CANCEL</button>
    <button mat-button mat-raised-button color="primary" type="submit" [disabled]="!form.valid || this.items.length===0">SUBMIT</button>
  </ui-modal-footer>
</form>

