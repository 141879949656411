/**
 * Created by jodell on 9/25/2017.
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { BusinessAssociateAddressApi } from 'cad/common/services/api/associate/business-associate/address/address';
import { AssociateBusinessAssociateAssetApi } from 'cad/common/services/api/associate/business-associate/asset/asset';
import { BaCombineHistory } from 'cad/common/services/api/associate/business-associate/combine-history/combine-history';
import { BaContact } from 'cad/common/services/api/associate/business-associate/contact/ba-contact';
import { DesigneeApi } from 'cad/common/services/api/associate/business-associate/designee/designee';
import { PrimaryContactApi } from 'cad/common/services/api/associate/business-associate/primary-contact/primary-contact';
import { BaRelatedBackward } from 'cad/common/services/api/associate/business-associate/related-backward/related';
import { BaRelated } from 'cad/common/services/api/associate/business-associate/related/related';

import { FilterByName } from 'cad/common/services/api/filter-by-name';
import { FilterByAbbr } from 'cad/common/services/api/filter-by-abbr';

@Injectable()
export class BusinessAssociateCombineApi  {
  private readonly endpoint: string = 'associate/ba-combine';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  // ========================================
  // Common methods - Implemented in Scaffold ItemApi
  // ========================================


  public cancel = (params?: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/cancel', { body: params });
  }


}
