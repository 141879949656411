<ui-action-card #profileContactInfoRef="uiActionCard"
                (saveClicked)="saveContactInformation()"
                (addClicked)="openAddModal()"
                [cardTitle]="'Contact Information'"
                [cardId]="'profileContactInfo'"
                [rowActionMenu]="{}"
                [showAdd]="true"
                [isAddEnabled]="true"
                [enableMax]="true"
                [gridApi]="gridApi">

<ui-table [data]="items"
          [actionMenu]="rowActionMenu"
          [enableRowAutoSave]="false"
          (gridReady)="onGridReady($event)">
  <ui-table-column name="profileTypeCd"
                   headerName="Profile Type"
                   etCodeRenderer
                   codeType="CONTACTPROFILE"
                   [sortObj]="{direction: 'asc', priority: 1}"></ui-table-column>

  <ui-table-column name="profileValue"
                   mask="ctrl.config.columnMask"
                   [editable]="true"
                   phoneOrText
                   headerName="Value *" cellFilter="phoneNumber"></ui-table-column>

  <ui-table-column name="effBeginDt"
                   datepickerEditor
                   headerName="Effective Begin Date *"
                   type="date"></ui-table-column>

  <ui-table-column name="effEndDt"
                   headerName="Effective End Date *"
                   datepickerEditor
                   [editable]="true"
                   [sortObj]="{direction: 'desc', priority: 0}"
                   type="date"></ui-table-column>
  </ui-table>
</ui-action-card>



