import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertsService } from 'cad/core/services/alerts/alerts.service';
import { AlertsSnackbarComponent } from '../alerts-snackbar/alerts-snackbar.component';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'ui-alerts-sidenav',
  templateUrl: './alerts-sidenav.component.html',
  styleUrls: [ './alerts-sidenav.component.less' ],
})
export class AlertsSidenavComponent implements OnInit, OnDestroy {

  public alerts: cad.Alert[] = [];

  private subscriptions: Subscription[] = [];

  private sbRef: MatSnackBarRef<AlertsSnackbarComponent>;
  private sbSubscription: Subscription;

  @ViewChild('alertsSidenav') private alertSidenav: MatSidenav;

  constructor(
    private snackBar: MatSnackBar,
    private alertsService: AlertsService,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(this.alertsService.alerts$.subscribe((alerts: cad.Alert[]) => {
      this.alerts = alerts;
    }));
    this.subscriptions.push(this.alertsService.snackbarAlerts$.subscribe((alerts: cad.Alert[]) => this.onSnackbarAlerts(alerts)));
    this.subscriptions.push(this.alertsService.alertsSidenavToggle$.subscribe(() => this.alertSidenav.toggle()));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    if (this.sbSubscription) { this.sbSubscription.unsubscribe(); }
  }

  public dismissAlert = (alert: cad.Alert): void => {
    this.alertsService.dismissAlert(alert);
  }

  public dismissAllAlerts(): void {
    if (this.alertsService) {
      this.alertsService.dismissAllAlerts();
    }
  }

  public toggle(): void {
    this.alertSidenav.toggle();
  }

  private onSnackbarAlerts(alerts: cad.Alert[]): void {
    if (alerts.length) {
      if (!this.sbRef) {
        // open the snackbar
        let config = { panelClass: [ 'snackbar-party' ] };
        this.sbRef = this.snackBar.openFromComponent(AlertsSnackbarComponent, config);
        this.sbRef.instance.sbRef = this.sbRef;
        this.sbSubscription = this.sbRef.afterDismissed().subscribe(() => {
          this.sbRef = undefined;
          if (this.sbSubscription) {
            this.sbSubscription.unsubscribe();
            this.sbSubscription = undefined;
          }
        });
      }
      this.sbRef.instance.alerts = alerts;
    } else if (this.sbRef) {
      // close the snackbar
      this.sbRef.instance.alerts = [];
      this.sbRef.dismiss();
      this.sbRef = undefined;
    }
  }
}
