import { Component, Inject, Injector, Input, OnInit, OnDestroy } from '@angular/core';
import { GridReadyEvent, RowNode } from '@ag-grid-community/core';
import { UiGridApi } from 'src/ag-grid-wrapper';
import { LookupApi, LookupInputDefinition, ModalFeatureBody } from './../../modal-feature-types';
import { ModalController } from './../../modal.controller';
import { LookupInputList } from '../../modal-feature-types';
import { PhysicalStatesApi } from 'src/cad/common/services/api/physical/states/states';
import { Observable ,  Subscription } from 'rxjs';
import { AutoUnsubscribables, AutoUnsubscriber } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import { AgreementRateScheduleApi } from 'src/cad/common/services/api/agreement/rate-schedule/rate-schedule';
import { PhysicalAssetApi } from 'cad/common/services/api/physical/asset/asset';
import { ModalModel } from 'src/features/common/modal/modal.model';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { CodeValueData } from 'cad/shared/interfaces/code-value-data';
import { AgreementContractServiceApi } from 'src/cad/common/services/api/agreement/contract/service/agreement-contract-service.api';
import { ServiceLookupData } from 'cad/common/services/api/agreement/contract/service/service-lookup-data';
import { UserModelService } from 'cad/common/services/user/user-model-service';
import { FilterModel } from 'common/interfaces/filter.model';
import { CountyData } from 'cad/shared/interfaces/county-data';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

declare type returnObjMap = {[key: string]: string};
declare type transformFn = (data: any, instanceType?: string) => returnObjMap;

@Component({
  selector: 'dynamic-lookup',
  templateUrl: './dynamic-lookup.component.html',
  styleUrls: [ './dynamic-lookup.component.less' ]
})
export class DynamicLookupComponent implements OnInit, OnDestroy, ModalFeatureBody {
  @Input() public inputs: LookupInputList;
  @Input() public controller: ModalController;
  @Input() public instanceType: string;
  @Input() public defaultSearchParams: { [key: string]: string | number };
  @Input() public constSearchParams: { [key: string]: string | number };

  @AutoUnsubscriber() public subs: AutoUnsubscribables;

  public title: string;
  public params: any = {};
  public items: any[] = [];
  public api: LookupApi;
  public gridApi: UiGridApi;
  public stateList: any[];
  public countyList$ :Observable<CountyData> ;
  public assetList: any[];
  public isAutoSearchEnabled: boolean;
  public isSearchButtonEnabled: boolean;
  public isMultiSelection: boolean;
  public isFilterEnabled: boolean;
  public selectionsOnly: boolean;
  public getServiceNamesForTransactionFn: () => Observable<CodeValueData[]> = this.getServiceNamesForTransaction.bind(this);
  private _performLookupSubscription: Subscription = Subscription.EMPTY;
  public isDisableCounty: boolean = true;

  constructor(private inj: Injector,
              @Inject(MAT_DIALOG_DATA) private data: any,
              public physicalStatesApi: PhysicalStatesApi,
              public physicalAssetApi: PhysicalAssetApi,
              public agreementRateScheduleApi: AgreementRateScheduleApi,
              public agreementContractServiceApi: AgreementContractServiceApi,
              private userModelService: UserModelService) {}

  ngOnInit(): void {
    this.api = this.inj.get(this.controller.model.api);
    this.isAutoSearchEnabled = (this.getModel() && this.getModel().autoSearch);
    this.isSearchButtonEnabled = (this.getModel() && this.getModel().showSearchButton);

    if((this.getModel() && this.getModel().autoForExternal) && this.userModelService.isUserInternal() === 'N') {
      this.hideInputs();
      this.isAutoSearchEnabled = true;
      this.isSearchButtonEnabled = false;
    }

    this.isMultiSelection = (this.getModel() && this.getModel().multiSelection);
    this.isFilterEnabled = (this.getModel() && this.getModel().enableFilter);

    /**
     * Populate states if a state input is included
     */
    this.populateStates();
    this.populateAssets();
    /**
     * Defaults should be prepopulated
     */
    this.populateDefaultSearchParams();
    if (this.isAutoSearchEnabled) {
      this.performLookup(true);
    }
  }

  private hideInputs(): void {
    if(this.controller && this.controller.model && this.controller.model.lookupInputs && this.controller.model.lookupInputs.inputs) {
      for (const input of this.controller.model.lookupInputs.inputs) {
        input.hideInForm = true;
      }
    }
  }

  public ngOnDestroy(): void {
    this.debouncedLookup.cancel();
    if (this._performLookupSubscription) {
      this._performLookupSubscription.unsubscribe();
      this._performLookupSubscription = null;
    }

  }

  public onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;

    let defaultFilters: FilterModel[] = [];
    if(this.inputs && this.inputs.inputs) {
      defaultFilters = this.buildFilters(this.inputs.inputs);
    }

    if (defaultFilters.length > 0) {
      for (const filterModel of defaultFilters) {
        let columnFilter = this.gridApi.getFilterInstance(filterModel.propertyName);
        if (columnFilter) {
          columnFilter.setModel({
            filterType: filterModel.filterType,
            type: filterModel.type,
            filter: filterModel.filter
          });
        }
      }
      this.gridApi.onFilterChanged();
    }

    setTimeout(() => {
      if (this.isMultiSelection) {
        (this.gridApi as any).gridOptionsWrapper.gridOptions.isExternalFilterPresent = this.isExternalFilterPresent.bind(this);
        (this.gridApi as any).gridOptionsWrapper.gridOptions.doesExternalFilterPass = this.doesExternalFilterPass.bind(this);
        // This was necessary to fix IE not rendering rows
        this.gridApi.onFilterChanged();
      }
    });
  }

  buildFilters(lookupInputs: LookupInputDefinition[]): FilterModel[] {
    let filters: FilterModel[] = [];
    for (const input of lookupInputs) {
      if(!input.suppressFilter && input.defaultFilterOperator && input.defaultFilter && input.defaultFilterType) {
        filters.push({
          propertyName: input.propertyName,
          type: input.defaultFilterOperator,
          filter: input.defaultFilter,
          filterType: input.defaultFilterType
        });
      }
    }
    return filters;
  }

  debouncedLookup: _.Cancelable = _.debounce(this.performLookup, 1000);

  performLookup(allowEmptyParams?: boolean): void {
    let itemDataPrimaryKey: string = this.getModel() ? this.getModel().itemDataPrimaryKey : null;
    /**
     * if allowEmptyParams is false and params are empty, or only contain defaults,
     * don't perform the lookup
     */
    let params: any = this.getObjWithoutEmpty(this.params);
    let nonDefaultParams: any = this.getObjWithoutDefaults(this.getObjWithoutEmpty(this.params));
    if (!Object.keys(params).length
      && !Object.keys(nonDefaultParams).length
      && !allowEmptyParams) {
      return;
    }
    _.assign(params, this.constSearchParams);
    if (itemDataPrimaryKey) {
      params[ itemDataPrimaryKey ] = this.data.itemData[ itemDataPrimaryKey ];
    }
    this._performLookupSubscription.unsubscribe();
    this._performLookupSubscription = this.api.filter(params).subscribe((items) => {
      this.setData(items);
    });
  }

  populateDefaultSearchParams(): void {
    if (!this.defaultSearchParams) {
      return;
    }
    let defaults = this.getObjWithoutEmpty(this.defaultSearchParams);
    _.assign(this.params, defaults);
  }

  getRateSchedules = (): Observable<CodeValueData[]> => {
    return this.agreementRateScheduleApi
      .findAll()
      .pipe(map((list) => list.map((s) => ({ cd: s.rateScheduleCd, value: s.rateScheduleCd }))));
  }

  getServiceNames = (serviceFilter: (serviceData: ServiceLookupData) => boolean = () => true): Observable<CodeValueData[]> => {
    return this.agreementContractServiceApi.getAll()
    .pipe(
      map((serviceLookupDataList: ServiceLookupData[]) =>
        serviceLookupDataList.filter(serviceFilter).map((serviceLookupData) => ({
          cd: serviceLookupData.serviceName,
          value: serviceLookupData.serviceName
        } as CodeValueData))
      )
    );
  }

  getServiceNamesForTransaction(): Observable<CodeValueData[]> {
    return this.agreementContractServiceApi.getAllForTransaction()
    .pipe(
      map((serviceLookupDataList: ServiceLookupData[]) =>
        serviceLookupDataList.map((serviceLookupData) => ({
          cd: serviceLookupData.serviceName,
          value: serviceLookupData.serviceName
        } as CodeValueData))
      )
    );
  }

  populateStates(): void {
    let hasStates = false;
    this.controller.model.lookupInputs.inputs.forEach((input: any) => {
      if (input.type === 'states') {
        hasStates = true;
      }
    });
    if (hasStates) {
      this.subs.newSub = this.getStates().subscribe((states) => this.stateList = states);
    }
  }

  public onStateChange(changeObj: MatSelectChange): void {
    let hasCounty: boolean = false;
    this.controller.model.lookupInputs.inputs.forEach((input: any) => {
      if (input.type === 'county') {
        hasCounty = true;
      }
    });
    if (hasCounty && changeObj && changeObj.value) {
      this.countyList$ = this.physicalStatesApi.getCounties(changeObj.value);
    }
    this.isDisableCounty = !(changeObj && changeObj.value);
    delete this.params.countyCd;
  }

  populateAssets(): void {
    let hasAssets = false;
    let hasAllAssets = false;
    this.controller.model.lookupInputs.inputs.forEach((input: any) => {
      if (input.type === 'assets') {
        hasAssets = true;
      } else if (input.type === 'all-assets') {
        hasAllAssets = true;
      }
    });
    if (hasAssets || hasAllAssets) {
      this.subs.newSub = this.getAssets(hasAllAssets).subscribe((assets) => {
        this.assetList = assets;
      });
    }
  }

  private getAssets(all: boolean): Observable<any> {
    if (all) {
      return this.physicalAssetApi.findAllAssets();
    }
    return this.physicalAssetApi.findAll();
  }

  public getStates(stateCd?: any): Observable<any> {
    return stateCd ?
      this.physicalStatesApi.filter().pipe(map((states) => {
        return _.filter(states, { stateCd });
      })) :
      this.physicalStatesApi.filter();
  }

  setData(items: any): any {
    if ((items.code === 500 && items.errors) || items.errors) {
      return;
    } else if (items.searchDataList) {
      this.items = items.searchDataList;
    } else {
      this.items = items;
    }
  }

  transform(data: any): any {
    let returnObj = {};
    let transformObj = {} ;
    if (typeof this.controller.model.returnObjMap === 'function') {
      transformObj = (this.controller.model.returnObjMap as transformFn)(data, this.instanceType);
    } else {
      transformObj = this.controller.model.returnObjMap;
    }
    Object.keys(transformObj).forEach((key, i) => returnObj[ key ] = data[ transformObj[ key ] ]);

    if (this.controller.model.transformObjFn && _.isFunction(this.controller.model.transformObjFn)) {
      returnObj = this.controller.model.transformObjFn(returnObj, this.instanceType);
    }
    return returnObj;
  }

  onClose(): any {
    let selectedRows: any[] = _.cloneDeep(this.getSelectedRows());

    if (this.controller.model.returnObjMap) {
      selectedRows = selectedRows.map((rowData: any) => {
        return this.transform(rowData);
      });
    }

    return this.isMultiSelection
      ? (_.isFunction(this.controller.model.multiSelectionTransformFn)
        ? this.controller.model.multiSelectionTransformFn(selectedRows, this.instanceType)
        : selectedRows)
      : _.first(selectedRows);
  }

  public isSelectionsOnlyDisabled(): boolean {
    return (!this.selectionsOnly && this.getSelectedRows().length < 1);
  }

  public onSelectionsOnlyChange(): void {
    if (this.gridApi) {
      this.gridApi.onFilterChanged();
    }
  }

  private getObjWithoutEmpty(obj: any): any {
    let newObj = _.cloneDeep(obj);
    Object.keys(newObj).forEach((key) => {
      if (!newObj[ key ]) {
        delete newObj[ key ];
      }
    });
    return newObj;
  }

  private getObjWithoutDefaults(obj: any): any {
    let newObj = _.cloneDeep(obj);
    if (this.constSearchParams) {
      Object.keys(this.constSearchParams).forEach((key) => {
        delete newObj[ key ];
      });
    }
    return newObj;
  }

  private getModel(): ModalModel {
    return (this.controller && this.controller.model) ? this.controller.model : null;
  }

  private isExternalFilterPresent(): boolean {
    return (this.isMultiSelection && this.selectionsOnly);
  }

  private doesExternalFilterPass(rowNode: RowNode): boolean {
    return rowNode && rowNode.isSelected();
  }

  private getSelectedRows(): any[] {
    if (this.gridApi) {
      return this.gridApi.getSelectedRows();
    }
    return [];
  }
}
