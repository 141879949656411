import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export abstract class ItemApi {
  abstract find(params: any): Observable<any>;

  abstract filter(params: any): Observable<any>;

  abstract recent(): Observable<any>;

  abstract save(item: any): Observable<any>;

  abstract deleteItem(item: any): Observable<any>;
}
