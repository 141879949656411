import { Injectable } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { UserDetail } from 'src/cad/common/models/user/user-details-model';

@Injectable()
export class EmitterService {

  // ===========================================================================
  // Action Menu
  // ===========================================================================

  public actionMenuNew: Subject<void> = new Subject<void>();
  
  public enableNewActionItem: Subject<boolean> = new Subject<boolean>();
  public enableCopyActionItem: Subject<boolean> = new Subject<boolean>();
  public enableDeleteActionItem: Subject<boolean> = new Subject<boolean>();

  // ===========================================================================
  // Search Menu
  // ===========================================================================

  public toggleSearch: Subject<boolean> = new Subject<boolean>();

  // ===========================================================================
  // Login / Logout
  // ===========================================================================

  public userLogin: ReplaySubject<UserDetail[]> = new ReplaySubject(1);


  // ===========================================================================
  // Form Actions
  // ===========================================================================

  public uiFormSubmitterClicked: Subject<string> = new Subject<string>();

  public formDirtyEvent: Subject<any> = new Subject<any>();

  // ===========================================================================
  // Security
  // ===========================================================================

  public actionContextResolved: Subject<boolean> = new ReplaySubject<boolean>(1);
  public actionMenuSecurityUpdated: Subject<void> = new Subject<void>();

  // ==========================================================================
  // Data Administration
  // ==========================================================================

  public contactBaAssociationRowSelection: Subject<any[]> = new ReplaySubject(1);

  // ==========================================================================
  // Physical
  // ==========================================================================

  public locationForecastMetersAssociationRowSelection: Subject<any[]> = new ReplaySubject(1);

  // ============================================================================
  // Dashboard Item
  // ============================================================================

  public itemSelection: Subject<string> = new ReplaySubject(1);



  public subjectByKey(key: string): Subject<any> {
    if (this.hasOwnProperty(key)) {
      return this[key] as Subject<any>;
    } else {
      console.warn(`No emitter found for '${key}'`);
      return new Subject<any>();
    }
  }
}
