import {Injectable, Renderer2} from '@angular/core';
import {RowNode, Constants} from '@ag-grid-community/core';
import {RowActionMenuComponent} from '../row/action-menu/action-menu.component';
import {DetailRowComponent} from '../row/detail-row/detail-row.component';
import {RowShowDeleteComponent} from '../row/row-delete/row-delete.component';
import {RowShowExpandComponent} from '../row/row-expand/row-expand.component';
import {RowNavigationComponent} from '../row/row-navigation/row-navigation.component';
import {RowVisualValidationComponent} from '../row/validation-icon/validation-icon.component';
import {RowMessageIconComponent} from '../row/message-icon/message-icon.component';
import {DirtyStatus, KeyCode} from '../table.constants';
import {TableColumnRadioRenderer} from './../column/radio/radio-renderer';
import {TableComponent} from './table.component';
import * as _ from 'lodash';


@Injectable()
export class CadTableConfigurer {
  constructor(public renderer: Renderer2) {
  }

  public configure(table: TableComponent): void {
    this.registerCadComponents(table);
    this.setCadTableDefaults(table);
  }

  public setGridTheme(table: TableComponent, el: any): void {
    this.renderer.addClass(el, 'ag-theme-material');
    this.renderer.setAttribute(el, 'tabindex', '0');
  }

  private registerCadComponents(table: TableComponent): void {
    let addColumn: boolean = false;
    addColumn = this.setSingleSelect(table)? true: addColumn;
    addColumn = this.setMultiSelect(table)? true: addColumn;
    addColumn = this.setActionMenu(table)? true: addColumn;
    addColumn = this.setNavigationMenu(table)? true: addColumn;
    addColumn = this.setShowDelete(table)? true: addColumn;
    addColumn = this.setValidationVisual(table)? true: addColumn;
    addColumn = this.setMessageIcon(table)? true: addColumn;
    addColumn = this.setRowChildComponent(table)? true: addColumn;
    if(addColumn) { table.refreshColDefs(); }
  }

  private setCadTableDefaults(table: TableComponent): void {
    this.setupDirtyStatusFiltering(table);
    this.setTableDefaults(table);
    this.setTableRowHeight(table);
    this.setGridRowClass(table);
  }

  private setupDirtyStatusFiltering(table: TableComponent): void {
    if (table.gridOptions) {
      let providedDoesExternalFilterPass: (node: RowNode) => boolean = table.gridOptions.doesExternalFilterPass;
      
      table.gridOptions.isExternalFilterPresent = () => true;
      // Do not replace the provided settings, instead execute the provided functions on top of the dirty status filtering
      table.gridOptions.doesExternalFilterPass = (node: RowNode): boolean => {
        let returnValue: boolean = providedDoesExternalFilterPass ? providedDoesExternalFilterPass(node) : true;
        
        if (!node.data || node.data.dirtyStatus === DirtyStatus.DELETE) {
          returnValue = false;
        }
        return returnValue;
      };
    }
  }

  private setTableRowHeight(table: TableComponent): void {
    if (table.gridOptions && !table.gridOptions.getRowHeight) {
      table.gridOptions.getRowHeight = (params: any): any => {
        if (params && params.node) {
          return this.isFooterHeaderRow(table, params.node)
            ? table.gridOptions.headerHeight
            : table.gridOptions.rowHeight;
        }
        return 30;
      };
    }
  }

  private setGridRowClass(table: TableComponent): void {
    if (table.gridOptions) {
      table.gridOptions.rowClassRules = {
        'row-saving': (params: any) => params.node.isSaving,
        'row-error': (params: any) => params.node.isError,
        'row-dirty': (params: any) => params.node.isDirty,
        'footer-row': (params: any) => (table && table.showFooter && params.node.rowPinned === Constants.PINNED_BOTTOM),
        'footer-header-row': (params: any) => this.isFooterHeaderRow(table, params.node)
      };
    }
  }

  private isFooterHeaderRow(table: TableComponent, rowNode: RowNode): boolean {
    const footerOrderingField: string = '**footerOrderingField**';
    const isHeader: boolean = rowNode && rowNode.data && rowNode.data[ footerOrderingField ] === -10000000;

    return (table && table.showFooter && rowNode.rowPinned === Constants.PINNED_BOTTOM && isHeader);
  }

  private setTableDefaults(table: TableComponent): void {
    _.defaults(table.gridOptions, {
      accentedSort: true,
      singleClickEdit: true,
      enableMenu: true,
      suppressScrollLag: false,
      suppressRowHoverClass: true,
      suppressParentsInRowNodes: true,
      suppressContextMenu: false,
      suppressCopyRowsToClipboard: true,
      suppressMovableColumns: false,
      suppressColumnMoveAnimation: false,
      stopEditingWhenGridLosesFocus: false,
      rowBuffer: 15,
      rowHeight: 30,
      headerHeight: 56,
      enableSorting: true,
      enableCellChangeFlash: true,
    });
  }

  public setNavigationMenu(table: TableComponent): boolean {
    let rv: boolean = false;
    if (table.navigationMenu) {
      const navigationMenuCell = {
        headerName: '',
        field: 'navigationMenu',
        data: table.navigationMenu,
        cellRendererFramework: RowNavigationComponent,
        width: 30,
        minWidth: 30,
        maxWidth: 30,
        cellClass: 'ag-grid-navigation-menu-cell ag-grid-action-menu-cell',
        headerClass: 'ag-grid-navigation-menu-header-cell',
        suppressColumnsToolPanel : true,
        pinnedRowCellRenderer: () => ''
      };
      table.prependColumn(navigationMenuCell);
      rv = true;
    }
    return rv;
  }

  public setSingleSelect(table: TableComponent): boolean {
    let rv: boolean = false;
    if (table.showSingleSelect) {
      table.gridOptions.deltaColumnMode = true;
      const singleSelectCell = {
        headerName: '',
        field: 'singleSelect',
        suppressMenu: true,
        suppressMovable: true,
        suppressCellFlash: true,
        sortable: false,
        filter: false,
        lockPosition: true,
        lockVisible: true,
        cellRenderer: TableColumnRadioRenderer,
        width: 30,
        minWidth: 30,
        maxWidth: 30,
        cellClass: 'ag-grid-single-select-cell',
        headerClass: 'ag-grid-single-select-header-cell',
        suppressColumnsToolPanel : true,
        pinnedRowCellRenderer: () => ''
      };
      table.gridOptions.rowSelection = 'single';
      table.prependColumn(singleSelectCell);
      rv = true;
    }
    return rv;
  }

  public setMultiSelect(table: TableComponent): boolean {
    let rv: boolean = false;
    if (table.showMultiSelect) {
      table.gridOptions.deltaColumnMode = true;
      const multiSelectCell = {
        headerName: '',
        field: 'multiSelect',
        suppressMenu: true,
        suppressMovable: true,
        suppressCellFlash: true,
        sortable: false,
        filter: false,
        lockPosition: true,
        lockVisible: true,
        checkboxSelection: true,
        headerCheckboxSelection: !!table.headerCheckboxSelection,
        // cellRendererFramework: RowMultipleSelectComponent,
        width: 30,
        minWidth: 30,
        maxWidth: 30,
        cellClass: 'ag-grid-multi-select-cell',
        headerClass: 'ag-grid-multi-select-header-cell',
        suppressColumnsToolPanel : true,
        pinnedRowCellRenderer: () => ''
      };
      table.gridOptions.rowSelection = 'multiple';
      table.prependColumn(multiSelectCell);
      rv = true;
    }
    return rv;
  }

  public setValidationVisual(table: TableComponent): boolean {
    let rv: boolean = false;
    if (table.validationVisual) {
      table.gridOptions.deltaColumnMode = true;
      const validationVisualCell = {
        headerName: '',
        field: 'validationVisual',
        suppressMenu: true,
        suppressMovable: true,
        suppressCellFlash: true,
        sortable: false,
        filter: false,
        lockPosition: true,
        lockVisible: true,
        // data: this.rowEditFactory.getErrorRows,
        cellRendererFramework: RowVisualValidationComponent,
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        cellClass: 'ag-grid-validation-visual-cell ag-grid-action-menu-cell',
        headerClass: 'ag-grid-validation-visual-header-cell',
        suppressColumnsToolPanel : true,
        pinnedRowCellRenderer: () => ''
      };
      table.prependColumn(validationVisualCell);
      rv = true;
    }
    return rv;
  }

  public setActionMenu(table: TableComponent): boolean {
    let rv: boolean = false;
    if (table.actionMenu) {
      const actionMenuCell = {
        headerName: '',
        field: 'actionMenu',
        suppressMenu: true,
        suppressMovable: true,
        suppressCellFlash: true,
        sortable: false,
        filter: false,
        data: table.actionMenu,
        cellRendererFramework: RowActionMenuComponent,
        width: 30,
        minWidth: 30,
        maxWidth: 30,
        cellClass: 'ag-grid-action-menu-cell',
        pinned: 'right',
        lockPinned: true,
        headerClass: 'ag-grid-action-menu-header-cell ag-grid-pinned-header-cell',
        suppressColumnsToolPanel : true,
        pinnedRowCellRenderer: () => ''
      };
      table.appendColumn(actionMenuCell);
      rv = true;
    }
    return rv;
  }

  public setShowDelete(table: TableComponent): boolean {
    let rv: boolean = false;
    if (table.showDelete) {
      const deleteActionCell = {
        headerName: '',
        field: 'showDelete',
        suppressMenu: true,
        suppressMovable: true,
        suppressCellFlash: true,
        sortable: false,
        filter: false,
        data: {
          itemsRemoved: table.itemsRemoved,
          confirmDelete: table.confirmDelete,
        },
        cellRendererFramework: RowShowDeleteComponent,
        width: 30,
        minWidth: 30,
        maxWidth: 30,
        cellClass: [ 'ag-grid-show-delete-cell', 'ag-grid-action-menu-cell' ],
        pinned: 'right',
        lockPinned: true,
        headerClass: 'ag-grid-show-delete-header-cell ag-grid-pinned-header-cell',
        suppressColumnsToolPanel : true,
        pinnedRowCellRenderer: () => ''
      };
      table.appendColumn(deleteActionCell);
      rv = true;
    }
    return rv;
  }

  public setRowChildComponent(table: TableComponent): boolean {
    let rv: boolean = false;
    if (table && table.rowDetailComponent) {
      const groupExpandCell = {
        headerName: '',
        field: 'showExpand',
        cellRendererFramework: RowShowExpandComponent,
        expandIcon: table.expandIcon,
        collapseIcon: table.collapseIcon,
        expandTooltip: table.expandTooltip,
        collapseTooltip: table.collapseTooltip,
        suppressMenu: true,
        suppressMovable: true,
        suppressCellFlash: true,
        sortable: false,
        filter: false,
        width: 36,
        minWidth: 36,
        maxWidth: 36,
        cellClass: [ 'row-expand-cell' ],
        pinned: 'right',
        lockPinned: true,
        headerClass: 'ag-grid-row-expand-header-cell ag-grid-pinned-header-cell',
        suppressColumnsToolPanel : true,
        pinnedRowCellRenderer: () => ''
      };
      if (table.gridOptions) {
        table.gridOptions.masterDetail = true;
        table.gridOptions.detailCellRendererFramework = DetailRowComponent;
        table.gridOptions.detailCellRendererParams = _.defaults(table.gridOptions.detailCellRendererParams, {
          rowDetailComponent: table.rowDetailComponent
        });
        // table.gridOptions.getRowHeight = (params: any): any => {
        //   return (params && params.node && params.node.flower)
        //     ? null
        //     : table.gridOptions.rowHeight;
        // };
        table.gridOptions.isFullWidthCell = (rowNode: RowNode) => {
          return rowNode && rowNode.detail;
        };
      }
      if (table.showExpand) {
        table.appendColumn(groupExpandCell);
        rv = true;
      }
    }
    return rv;
  }

  public setMessageIcon(table: TableComponent): boolean {
    let rv: boolean = false;
    if (!_.isNil(table.rowMessage) && !table.validationVisual) {
      table.gridOptions.deltaColumnMode = true;
      const messageIconCell = {
        headerName: '',
        field: 'rowMessageIcon',
        cellRendererFramework: RowMessageIconComponent,
        rowMessage: table.rowMessage,
        suppressMenu: true,
        suppressMovable: true,
        suppressCellFlash: true,
        sortable: false,
        filter: false,
        lockPosition: true,
        lockVisible: true,
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        cellClass: 'ag-grid-row-message-icon-cell ag-grid-action-menu-cell',
        headerClass: 'ag-grid-row-message-icon-header-cell',
        suppressColumnsToolPanel : true,
        pinnedRowCellRenderer: () => ''
      };
      table.prependColumn(messageIconCell);
      rv = true;
    }
    return rv;
  }

  setNextCellNavigation(table: TableComponent): void {
    if(table.enableNavigationHighlights) {
      table.gridOptions.navigateToNextCell = this.navigateToNextCell;
    }
  }

  setTabCellNavigation(table: TableComponent): void {
    if(table.enableNavigationHighlights) {
      table.gridOptions.tabToNextCell = this.tabToNextCell;
    }

  }

  tabToNextCell(params: any): any {
    let previousCell = params.previousCellDef;
    let suggestedNextCell = params.nextCellDef;
    if(previousCell.rowIndex !== suggestedNextCell.rowIndex) {
      suggestedNextCell.column.gridApi.forEachNode( (node) => {
        if (suggestedNextCell.rowIndex === node.rowIndex &&
          !(suggestedNextCell && suggestedNextCell.floating === 'bottom')) {
          node.setSelected(true);
          suggestedNextCell.data = node;
        }
      });
    }
    return suggestedNextCell;
  }

  navigateToNextCell(params: any): any {
    let previousCell = params.previousCellDef;
    let suggestedNextCell = params.nextCellDef;

    let KEY_UP = KeyCode.Up;
    let KEY_DOWN = KeyCode.Down;
    let KEY_LEFT = KeyCode.Left;
    let KEY_RIGHT = KeyCode.Right;

    switch (params.key) {

      case KEY_DOWN: {
        previousCell = params.previousCellDef;
       // set selected cell on current cell + 1
        params.nextCellDef.column.gridApi.forEachNode( (node) => {
          if (previousCell.rowIndex + 1 === node.rowIndex &&
            !(suggestedNextCell && suggestedNextCell.floating === 'bottom')) {
            node.setSelected(true);
            suggestedNextCell.data = node;
          }
        });
        return suggestedNextCell;
      }
      case KEY_UP: {
        previousCell = params.previousCellDef;
        //set selected cell on current cell - 1
        params.nextCellDef.column.gridApi.forEachNode( (node) => {
          if (previousCell.rowIndex - 1 === node.rowIndex &&
            !(previousCell && previousCell.floating === 'bottom')) {
            node.setSelected(true);
            suggestedNextCell.data = node;
          }
        });
        return suggestedNextCell;
      }
      case KEY_LEFT: {
        return suggestedNextCell;
      }
      case KEY_RIGHT: {
        return suggestedNextCell;
      }

      default: {
        throw 'this will never happen, navigation is always one of the 4 keys above';
      }
    }
  }

}
