import { UserDetail } from 'src/cad/common/models/user/user-details-model';
import { Injectable, Input, OnInit, Directive } from '@angular/core';
import { ApiHelper } from 'src/cad/common/services/api/api-helper';
import { RouterService } from 'src/cad/core/services/router/router.service';
import { UserPreferenceService } from 'cad/common/services/preferences/user-preferences';
import { ActivatedRoute } from '@angular/router';
import { RowActionItem } from 'src/ag-grid-wrapper/row/action-menu/action-item';
import { ApiRequestOptionsArgs } from 'cad/common/services/api/interfaces/api-request-options-args';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { isNullOrEmpty } from 'cad/shared/util/object';

@Directive()
@Injectable()
export abstract class BaseDashComponent<T = any> implements OnInit {
  @Input() userDetails: UserDetail;
  public headline$: Observable<string>;
  public itemData$: Observable<T[]>;
  public options?: ApiRequestOptionsArgs;
  public endPoint: string;
  public menuItems$: Observable<RowActionItem[]>;
  public useDefaultLength: boolean = true;
  private menuItems: RowActionItem[] = [
    {
      name: 'Delete',
      action: 'delete',
      icon: 'delete',
      method: this.delete.bind(this),
      isDisabled: () => false
    },
    {
      name: 'Move Up',
      action: 'up',
      icon: 'arrow_upward',
      method: this.up.bind(this),
      isDisabled: () => false
    },
    {
      name: 'Move Down',
      action: 'down',
      icon: 'arrow_downward',
      method: this.down.bind(this),
      isDisabled: () => false
    },
    {
      name: 'Move Left',
      action: 'left',
      icon: 'arrow_back',
      method: this.left.bind(this),
      isDisabled: () => false
    },
    {
      name: 'Move Right',
      action: 'right',
      icon: 'arrow_forward',
      method: this.right.bind(this),
      isDisabled: () => false
    }
  ];

  protected name: string;

  constructor (
    public apiHelper: ApiHelper,
    public routerService: RouterService,
    public activatedRoute: ActivatedRoute,
    public userPreferenceService: UserPreferenceService ) {
  }

  public abstract getEmptyHeadline(): string;
  public abstract getDefaultHeadline(): string;
  public abstract getName(): string;

  public trimData(data: T[]): T[] {
    if (data && data.length > 3) {
      return data.slice(0, 3);
    }
    return data;
  }

  public ngOnInit(): void  {
    if(!this.itemData$ && !isNullOrEmpty(this.endPoint)) {
      this.itemData$ = this.apiHelper.request(this.endPoint, this.options)
        .pipe(shareReplay(),map((data) =>
          this.useDefaultLength ? this.trimData(data) : data));
    }

    if(!this.headline$) {
      this.headline$ = this.itemData$
        .pipe(map((data) =>
          (data && data.length) ? this.getDefaultHeadline() : this.getEmptyHeadline()));
    }

    if(!this.menuItems$) {
      this.menuItems$ = this.activatedRoute.queryParams
        .pipe(map((param) =>
          param.edit ? this.menuItems : [])); // This is set for the user context menu to "Edit the dashboard"
    }
  }

  public navigate(navigationPath: (string|number)[]): void {
    this.routerService.navigate(navigationPath);
  }

  private delete(): void {
    this.userPreferenceService.removeDashboard(this.getName());
  }

  private up(): void {
    this.userPreferenceService.moveUpDashboard(this.getName());
    this.userPreferenceService.moveUpDashboard(this.getName());
  }

  private down(): void {
    this.userPreferenceService.moveDownDashboard(this.getName());
    this.userPreferenceService.moveDownDashboard(this.getName());
  }

  private left():void {
    this.userPreferenceService.moveUpDashboard(this.getName());
  }

  private right():void {
    this.userPreferenceService.moveDownDashboard(this.getName());
  }
}
