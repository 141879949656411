import { Injectable, ElementRef } from '@angular/core';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';


import { ReplaySubject ,  Observable } from 'rxjs';
import {CurrentContext} from 'cad/common/store/user/user-model';

@Injectable()
export class SidenavService {
  public get sidenavSections$(): Observable<any> { return this.sidenavSectionSubject; }

  private sidenavSectionSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
  private sections : any;
  private cards: any[] = [];

  constructor(
    private userModelService: UserModelService,
  ) {}

  protected evaluateSection(section: any, pipelineGroupKey: any, pipelineKey: any, baKey: any): boolean {
    let removeCurrentSection = true;

    if (section.hasOwnProperty('pipelineGroups')) {
      if (section.pipelineGroups.indexOf(pipelineGroupKey) > -1) {
        removeCurrentSection = false;
      }
    }
    if (section.hasOwnProperty('pipelines')) {
      if (section.pipelines.indexOf(pipelineKey) > -1) {
        removeCurrentSection = false;
      }
    }
    if (section.hasOwnProperty('businessAssociates')) {
      if (section.businessAssociates.indexOf(baKey) > -1) {
        removeCurrentSection = false;
      }
    }
    return removeCurrentSection;
  }

  private recursiveSetState(state: string, items: any[]): void {
    if(items) {
      items.forEach((item: any) => {
        item.isActive = state === item.state;
        if(item.pages && item.pages.length > 0) {
          this.recursiveSetState(state, item.pages);
        }
      });
    }

  }

  private recursiveSetStateById(id: string, items: any[]): void {
    if(items) {
      items.forEach((item: any) => {
        item.isActive = id === item.scrollTarget;
        if(item.pages && item.pages.length > 0) {
          this.recursiveSetStateById(id, item.pages);
        }
      });
    }
  }

  public setState( state: string ): void {
    this.recursiveSetState(state, this.sections);
    this.sidenavSectionSubject.next(this.sections);
  }

  public setStateById( id: string ): void {
    this.recursiveSetStateById(id, this.sections);
    this.sidenavSectionSubject.next(this.sections);
  }

  public registerCard(card: ElementRef  ) : void {
    this.cards.push(card);
  }

  public clearCards(): void {
    this.cards.splice(0, this.cards.length);
  }

  public setStateOnScroll(scrollParams: any): void {
    for (let index = 0; index < this.cards.length; index++) {
      const element = this.cards[index].nativeElement;
      if(Math.abs(scrollParams.srcElement.scrollTop - element.offsetTop) < 50) {
        this.setStateById(element.id);
        break;
      }
    }
  }


  public clearSideNav(): any[] {
    return this.buildSideNav([]);
  }

  public buildSideNav(sections: any[], currentContext?: CurrentContext): any[] {
    let currentCtx = currentContext ? currentContext : this.userModelService.getCurrentContext();
    return this.updateSections(sections, currentCtx);
  }

  private updateSections(sections: any[], currentContext: CurrentContext): any[] {
    if (_.isNil(currentContext)) { return []; }
    let pipelineGroupKey = currentContext.assetGroupCd;
    let pipelineKey = currentContext.assetAbbr;
    let baKey = currentContext.businessAssociateId;

    _.forEach(sections, (sectionItem: any) => {
      if (sectionItem.hasOwnProperty('pipelineGroups') || sectionItem.hasOwnProperty('pipelines') || sectionItem.hasOwnProperty('businessAssociates')) {
        sectionItem.hide = this.evaluateSection(sectionItem, pipelineGroupKey, pipelineKey, baKey);
      }
    });
    this.sidenavSectionSubject.next(sections);
    this.sections = sections;

    return sections;
  }
}
