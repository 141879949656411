import { Component, Inject } from '@angular/core';
import { TABLE_RENDERER_DATA } from 'src/ag-grid-wrapper';

@Component({
  selector: 'table-time-renderer',
  template: `
    <div class="inner-cell">
      <div>{{params.value}}</div>
    </div>
  `,
})
export class TimeRendererComponent {
  constructor(@Inject(TABLE_RENDERER_DATA) public params: any) { }
}
