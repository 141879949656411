import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class AllocateDailyApi {

  public endpoint: string = 'allocation/allocate-daily';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public filter = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public allocate = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/allocate', { body: params });
  }

  public currentAccountingPeriod(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/period', { body: params });
  }
}
