import { Component, Input, OnInit } from '@angular/core';
import { BaseDashComponent } from 'src/cad/dashboard/views/content/components/base-dash.component';
import { VerticalEnum } from 'cad/dashboard/views/content/components/vertical.enum';
import { map } from 'rxjs/operators';
import WorkflowConfigData = mplus.WorkflowConfigData;

@Component({
  selector: 'report-dash',
  templateUrl: './report-dash.component.html',
})
export class ReportDashComponent extends BaseDashComponent<WorkflowConfigData> implements OnInit {
  @Input() vertical: VerticalEnum;
  public statusMap: any = cad.statusMap;
  public endPoint: string = 'workflow/schedule-and-view-workflow/dashboard';
  private navigationPathPrefix: string[] = [];

  ngOnInit(): void {
    switch(this.vertical) {
      case VerticalEnum.REPORTING: {
        this.name = 'Report';
        this.navigationPathPrefix = [ 'reports','schedule-and-view-reports' ];
        this.options = { body: { workflowVertical: this.vertical } };
        break;
      }
      case VerticalEnum.WORKFLOW: {
        this.name = 'Workflow';
        this.navigationPathPrefix = [ 'workflow','schedule-and-view-workflow' ];
        this.options = null;
        this.itemData$ = this.apiHelper.request(this.endPoint)
          .pipe(map((data) => this.trimData(data.filter((item) => item.workFlowVertical !== VerticalEnum.REPORTING))));
        break;
      }
      default: {
        this.name = 'invalid component vertical';
        break;
      }
    }
    super.ngOnInit();
  }

  public getEmptyHeadline(): string {
    return `No Recent ${this.name}s`;
  }
  public getDefaultHeadline(): string {
    return `${this.name}s for ${this.userDetails ? this.userDetails.firstName + ' ' + this.userDetails.lastName : 'Unknown User'}`;
  }
  public getName(): string {
    return this.name;
  }

  public onRowClicked(event: any): void {
    super.navigate([ ...this.navigationPathPrefix, event.data.workflowExecutionRequestId ]);
  }
}


