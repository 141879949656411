import { Injector, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AssociationController } from 'src/features/common/association/association.controller';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AssociationApi } from 'src/features/common/association/association-api';

@Injectable()
export class PhysicalLocationMeterSchedulingSplitApi implements AssociationApi {
  private readonly endpoint: string = 'physical/locations/';
  private readonly endpointSuffix: string = '/meter-scheduling-split';

  static createAssociationApi(
    apiHelper: ApiHelper,
    associationController: AssociationController,
    injector: Injector,
  ): AssociationApi {
    return new PhysicalLocationMeterSchedulingSplitApi(apiHelper);
  }

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public findAll = (id: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + id + this.endpointSuffix );
  }

  public find = (id: number | string, params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + id + this.endpointSuffix + '/find', { body: params});
  }

  public save = (associations: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + associations[0].pointCd + this.endpointSuffix + '/save', { body: associations[0] });
  }
}
