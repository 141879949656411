import { UserStoreService } from 'cad/common/store/core/services/user-store.service';
import { UserService2 } from 'cad/common/store/user/services/user.service';
import { Component, OnInit } from '@angular/core';
import { RouterService } from 'src/cad/core/services/router/router.service';
import { RootObject } from 'cad/common/store/user/user-model';
import { take, filter } from 'rxjs/operators';
import { SecurityLoginApi } from 'cad/common/services/api/security/login/login';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: [ './login-dialog.component.less' ],
})
export class LoginDialogComponent implements  OnInit {
  public username: string;
  public password: string;
  public alerts: cad.Alert[] = [];
  public isReleaseBuild: boolean;
  public busy: boolean = false;

  constructor(
    private router: RouterService,
    private securityLoginApi: SecurityLoginApi,
    private userService: UserService2,
    private userStoreService: UserStoreService
  ) {}

  ngOnInit(): void {
    this.isReleaseBuild = environment.isReleaseBuild;
  }

  loginSubmit(): void {
    this.busy = true;
    this.securityLoginApi.loginUser(this.username, this.password)
      .pipe(take(1))
      .subscribe(
        (userData) => this.onLogin(userData),
        (error) => this.loginFailed(error),
        () => this.busy = false
      );
  }

  cancel(): void {
    this.router.navigate([ '/dashboard' ], { queryParams: { nl: 1 } });
  }

  private onLogin(userData: RootObject[]): void {
    if (userData && userData.length) {
      const userState = this.userService.getUserStoreState();
      if(userState) {
        if (userState.redirectUrl) {
          this.userStoreService.stateChanged
            .pipe(
              filter((stateChanged) => stateChanged && stateChanged.stateChanges),
              take(1))
            .subscribe((stateChanged) => {
            // need to wait for the user state to change before we can navigate to the page otherwise it will say you are still unauthroized
              if (stateChanged.stateChanges.user) {
                this.router.navigate([ userState.redirectUrl ])
                  .then(() => this.userStoreService.clearPostLoginRedirect());
              }
            });
        } else {
          this.router.navigate([ '/dashboard' ], {queryParams: {li: 1}});
        }
      }
    } else {
      console.error('Invalid user data return from login call');
    }
  }

  private loginFailed(error: any): void {
    console.error(error);
    this.alerts.push({ type: 'danger', msg: 'Your login attempt failed. Please enter a valid user name and password.' });
  }

}
