import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { DirtyStatus } from '../../table.constants';
import { RowConfirmActionComponent } from '../confirm-action/confirm-action.component';
import { AutoUnsubscribables, AutoUnsubscriber } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';


@Component({
  moduleId: module.id + '',
  selector: 'row-show-delete',
  templateUrl: 'row-delete.component.html',
  styleUrls: [ '../cell-icon-buttons.less' ],
})
export class RowShowDeleteComponent implements AgRendererComponent {

  @AutoUnsubscriber() private subs: AutoUnsubscribables;
  params: any;

  constructor(private mdDialog: MatDialog) {}

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public delete(): void {
    if (this.params
      && this.params.colDef
      && this.params.colDef.data
      && this.params.colDef.data.confirmDelete) {
      const dialogRef: MatDialogRef<RowConfirmActionComponent> = this.mdDialog.open(
        RowConfirmActionComponent,
        {
          data: {
            action: 'delete'
          }
        }
      );
      this.subs.newSub = dialogRef.afterClosed().subscribe((shouldDelete: any) => {
        if (shouldDelete) {
          this.deleteItem();
        }
      });
    } else {
      this.deleteItem();
    }
  }

  private deleteItem(): void {
    const ds = this.params.node.data.dirtyStatus;
    const savedRow = ds === DirtyStatus.NO_CHANGE || ds === DirtyStatus.UPDATE;
    if (savedRow) {
      this.params.node.data.dirtyStatus = DirtyStatus.DELETE;
      this.params.api.eventService.dispatchEvent({
        api: this.params.api,
        type: 'cellValueChanged',
        node: this.params.node,
        column: this.params.column,
      });
      this.params.api.onFilterChanged();
    } else {
      this.params.api.updateRowData({ remove: [ this.params.node.data ] });
    }

    const onDeleteRow = this.params.colDef.data.itemsRemoved;
    onDeleteRow.emit(this.params.node);
  }
}
