
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { AlertsService } from 'cad/core/services/alerts/alerts.service';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ContractAssignmentData } from 'src/cad/contracts/shared/interfaces/contract-assignment-data';
import { BusinessAssociateName } from 'src/cad/data-administration/shared/interfaces/ba-name';
import ValidationResult = cad.ValidationResult;

@Injectable()
export class AgreementContractAssignmentApi {

  private readonly endpoint: string = 'agreement/contract/assignment';

  constructor(
    private apiHelper: ApiHelper,
    private cadAlerts: AlertsService,
  ) {}

  public save(data: any): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/${ data.contractId }`, { body: data }).pipe(map((resp) => {
      if (resp.errorMessages) {
        // let  errors = _.uniq(resp.errorMessages, 'id');
        let errors = resp.errorMessages;
        errors.forEach((e) => this.cadAlerts.danger(e.message));
      }

      if (resp.warningMessages) {
        // let warnings = _.uniq(resp.warningMessages, 'id');
        let warnings = resp.warningMessages;
        warnings.forEach((w) => this.cadAlerts.warning(w.message));
      }

      if (!resp.errorMessages) {
        this.cadAlerts.success('Contract successfully assigned.');
      }

      return resp;
    }),
    catchError((err) => {
      console.log(err);
      return observableOf({});
    }),);
  }

  public isCreditWorthy(businessAssociateName: string): Observable<boolean> {
    let filterData: Partial<BusinessAssociateName> = { bAName: businessAssociateName };
    return this.apiHelper.request(`${ this.endpoint }/credit-worthy`, { body: filterData });
  }
  
  public retrieveDefaults(contractAssignmentData: ContractAssignmentData): Observable<ValidationResult> {
    return this.apiHelper.request(`${ this.endpoint }/retrieve-defaults`, { body: contractAssignmentData });
  }
}
