import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'src/cad/common/services/api/api-helper';

@Injectable()
export class GlArUploadDataApi {
  
  private readonly endpoint: string = 'settlement/gl-ar-upload-data';

  constructor(
    private apiHelper: ApiHelper
  ) {}
  
  public filter(params: any): Observable<any[]> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }
  
  public getFinancialFileNames(params: any): Observable<any[]> {
    return this.apiHelper.request(`${ this.endpoint }/financial-file-names`, { body: params });
  }
}
