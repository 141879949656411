import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'ui-accordion-panel',
  templateUrl: './accordion-panel.component.html',
  styleUrls: [ '../accordion.less' ],
})
export class UiAccordionPanelComponent {
  @Input() public header: any;
  @Input() public disabled: boolean = false;
  @Input()
  public get isOpen(): boolean {
    return this._isOpen;
  }
  public set isOpen(value: boolean) {
    this._isOpen = value;
    Promise.resolve().then(() => {
      this.isOpenChange.emit(this._isOpen);
    });
  }
  @Input() public isExpandable: boolean = true;
  @Input() public panelId: number;
  @Output() public isOpenChange: EventEmitter<boolean> = new EventEmitter();
  @Output() public panelSelected: EventEmitter<any> = new EventEmitter();

  public toggleEvent: Subject<number> = new Subject();
  private _isOpen: boolean = false;

  constructor() {}

  public toggle = () => {
    if(this.isExpandable) {
      this.isOpen = !this.isOpen;
      this.toggleEvent.next(this.panelId);
      this.panelSelected.emit(this.panelId);
    }
  }
}
