<ui-card [headline]="headline$|async" [cardToolBarItems]="menuItems$|async" [isExpanded]="true">
  <div *ngIf="itemData$|async;let itemData;">
    <ui-table *ngIf="itemData.length > 0" (rowClicked)="onRowClicked($event)" [data]="itemData" fxLayout="column">
      <ui-table-column name="lastName" headerName="Last Name"></ui-table-column>
      <ui-table-column name="firstName" headerName="First Name"></ui-table-column>
      <ui-table-column name="loginName" headerName="Login ID"></ui-table-column>
    </ui-table>
  </div>
</ui-card>

