<main>
  <header>
    <ui-global-header></ui-global-header>

    <ui-ribbon></ui-ribbon>

    <ui-loading-bar></ui-loading-bar>

    <ng-container [ngComponentOutlet]="controller.view.searchDropdown"></ng-container>
  </header>

  <div class="core ui-content">
    <router-outlet></router-outlet>
  </div>

  <ui-global-footer></ui-global-footer>
</main>

<ui-alerts-sidenav></ui-alerts-sidenav>
