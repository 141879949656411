import { NgModule } from '@angular/core';
import { AgGridModule } from '@ag-grid-community/angular';
import { ModuleRegistry, AllModules } from '@ag-grid-enterprise/all-modules';

import { TableColumnModule } from './column/column.module';
import { TableHeaderModule } from './header/header.module';
import { RowModule } from './row/row.module';
import { TableComponent } from './table/table.component';
import { TableRowEditAddFactory } from './ui-grid-api/row-edit-add.factory';
import { TableRowEditAutoSaveFactory } from './ui-grid-api/row-edit-auto-save.factory';
import { TableRowEditDeleteFactory } from './ui-grid-api/row-edit-delete.factory';
import { TableRowEditFactory } from './ui-grid-api/row-edit.factory';
import { TableRowFooterAggregationFactory } from './ui-grid-api/row-footer-aggregation.factory';
import { ColumnFilterFactory } from 'src/ag-grid-wrapper/ui-grid-api/column-filter.factory';

@NgModule({
  declarations: [
    TableComponent,
  ],
  imports: [
    RowModule,
    TableColumnModule,
    AgGridModule.withComponents([]),
    TableHeaderModule,
  ],
  exports: [
    AgGridModule,
    TableColumnModule,
    RowModule,
    TableComponent,
  ],
  providers: [
    ColumnFilterFactory,
    TableRowEditAddFactory,
    TableRowEditAutoSaveFactory,
    TableRowEditDeleteFactory,
    TableRowEditFactory,
    TableRowFooterAggregationFactory,
  ],
})
export class GridModule {
  constructor() {
    ModuleRegistry.registerModules(AllModules);
  }
}
