<button *ngIf="section.scroll === 'true'"
        mat-button
        disableRipple="true"
        (click)="select()"
        [ngClass]="{'active': section.isActive}">
  {{section.name}}
</button>
<button *ngIf="section.scroll === 'false'"
        mat-button
        (click)="select()"
        [ngClass]="{'active': section.isActive}">
  {{section.name}}
</button>
