<ng-container [ngSwitch]="enableVirtualOptions">
  <mat-form-field *ngSwitchCase="true"
                  [floatLabel]="floatLabel">
    <ui-mat-virtual-select [(ngModel)]="model"
                           #matSelectRef="uiMatVirtualSelect"
                           updateModelOn="default blur"
                           name="{{name}}"
                           [tabIndex]="tabIndex"
                           [originalOptionList]="optionList"
                           [(virtualOptionList)]="renderedItems"
                           [valueProperty]="valueProperty"
                           [viewValueProperty]="viewValueProperty"
                           [placeholder]="placeholder"
                           (closed)="onPanelClose()"
                           (opened)="onPanelOpen()"
                           [attr.readonly]="readonly"
                           [multiple]="true"
                           [showCloseButton]="true"
                           [disabled]="disabled"
                           [required]="required">
      <ui-select-header-filter [name]="name"
                               *ngIf="enableHeaderFilter"
                               uiMatVirtualSelectHeader
                               [(optionItems)]="optionList"
                               [(selectionModel)]="model"
                               [multiple]="true"
                               [showSelectionRow]="showHeaderSelectionRow"
                               [caseSensitive]="useCaseSensitiveFilter"
                               [startsWith]="useStartsWithFilter"
                               [valueProperty]="valueProperty"
                               [viewValueProperty]="viewValueProperty">
      </ui-select-header-filter>
      <ui-option-detail *ngFor="let option of renderedItems"
                        [optionItem]="option"
                        (onExpansionChange)="onExpansionChange()"
                        [optionItemProperties]="visibleItemProperties">
        <mat-option [disabled]="isOptionDisabled(option)"
                    title="{{option[viewValueProperty]}}"
                    [value]="option[valueProperty]">
          <!--<span md-highlight-text="headerFilterSearchText"-->
          <!--md-highlight-flags="gi">{{option[viewValueProperty]}}</span>-->
          {{option[viewValueProperty]}}
        </mat-option>
      </ui-option-detail>
      <div *ngIf="!renderedItems || renderedItems.length === 0"
           class="ui-multi-select-no-match"
           fxLayout="column"
           fxLayoutAlign="center center">
        <i>No matches found.</i>
      </div>
    </ui-mat-virtual-select>
    <mat-hint *ngIf="!!hintText"
              align="{{hintAlign}}">{{hintText}}</mat-hint>
  </mat-form-field>
  <mat-form-field *ngSwitchDefault
                  [floatLabel]="floatLabel">
    <mat-select [(ngModel)]="model"
                #matSelectRef="matSelect"
                updateModelOn="default blur"
                name="{{name}}"
                [tabIndex]="tabIndex"
                [placeholder]="placeholder"
                (closed)="onPanelClose()"
                [attr.readonly]="readonly"
                [multiple]="true"
                [disabled]="disabled"
                [required]="required">
      <mat-option *ngFor="let option of optionList"
                  title="{{option[viewValueProperty]}}"
                  [disabled]="isOptionDisabled(option)"
                  [value]="option[valueProperty]">{{option[viewValueProperty]}}
      </mat-option>
    </mat-select>
    <mat-hint *ngIf="!!hintText"
              align="{{hintAlign}}">{{hintText}}</mat-hint>
  </mat-form-field>
</ng-container>
<ng-container>
  <button class="ui-multi-select-more-button"
          #selectionCountTemplateRef
          type="button"
          mat-button
          [disabled]="disabled"
          [class.hidden]="!isOverflowing">
    +{{getSelectedObjects().length - visibleItems.length}} MORE
  </button>
</ng-container>
