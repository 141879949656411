import { Component, Input, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { NominationMatrixApi } from 'src/cad/common/services/api/nomination/nomination-matrix/nomination-matrix';
import { NominationMatrixListData } from 'src/cad/nominations/nomination/shared/nomination-matrix-list-data.model';
import { UiFormComponent } from 'src/common/components/form/form.component';
import { ReductionReason } from 'src/cad/shared/interfaces/reduction-reason';
import { clone, cloneDeep, isNil } from 'lodash';

@Component({
  selector: 'reduction-reason-form',
  templateUrl: './reduction-reason-form.component.html',
  styleUrls: [ './reduction-reason-form.component.less' ]
})
export class ReductionReasonFormComponent implements OnInit {

  @ViewChild(UiFormComponent) public uiForm: UiFormComponent;
  @Input() public comment: string;
  @Input() public reductionCd: string;
  @Input() public editable: boolean = false;
  @Input() public showComment: boolean;
  @Input() public codeType: string = 'CONFREDREASON';
  @Input() public filterData: NominationMatrixListData;
  public commentText: string;
  public reductionCdValue: string;
  public isDirty: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public nominationMatrixApi: NominationMatrixApi
  ) {}

  public ngOnInit(): void {
    this.commentText = clone(this.comment);
    this.reductionCdValue = clone(this.reductionCd) || '';
    this.showComment = isNil(this.showComment) ? true : this.showComment;
    if (this.data) {
      this.editable = (this.data.colDef && this.data.colDef.editable) || this.editable;
      this.filterData = this.filterData || this.data.itemData;
      if (this.data.lookupParams) {
        this.codeType = this.data.lookupParams.codeType ? this.data.lookupParams.codeType : 'CONFREDREASON' ;
        if (this.data.itemData) {
          this.reductionCdValue = this.data.itemData[ this.data.lookupParams.reductionCdColumn ];
          this.commentText = this.data.itemData[ this.data.lookupParams.commentColumn ];
        }
      }
    }
    if (this.showComment && !this.commentText && this.filterData && this.filterData.commentXrefNbr) {
      this.nominationMatrixApi.comment(cloneDeep(this.filterData))
      .pipe(take(1))
      .subscribe((data: NominationMatrixListData) => {
        this.commentText = data ? data.comment : null;
      });
    }
  }

  getLookupResult(): Partial<ReductionReason> {
    let returnValue: Partial<ReductionReason> = {};

    if (this.reductionCdValue) {
      returnValue = { reductionCd: this.reductionCdValue };
      if (this.showComment && this.commentText) {
        returnValue.comment = this.commentText;
      }
    }
    return returnValue;
  }

  isSubmitDisabled(): boolean {
    if (this.uiForm) {
      return !(this.uiForm.form.valid && this.isDirty);
    }
    return false;
  }
}
