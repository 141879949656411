import { ApiHelper } from 'cad/common/services/api/api-helper';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AlertDayMaintenanceData } from 'src/cad/flowing-gas/alert-day-maintenance/interfaces/alert-day-maintenance-data';
import { AlertDaySearchFilterData } from 'src/cad/flowing-gas/alert-day-maintenance/interfaces/alert-day-search-filter-data';

@Injectable()
export class AlertDayMaintenanceApi {
  public readonly endpoint: string = 'flowing-gas/alert-day';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public retrieveItems(filterData: AlertDaySearchFilterData): Observable<AlertDayMaintenanceData[]> {
    return this.apiHelper.request(`${this.endpoint}/filter`, { body: filterData });
  }

  public deleteItems(items: AlertDayMaintenanceData[]): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/delete-items`, { body: items });
  }

  public saveItems(items: AlertDayMaintenanceData[]): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/save-all`, { body: items });
  }
}
