import { FullscreenDialogComponent } from '../fullscreen-dialog/fullscreen-dialog.component';
import {
  Component,
  ComponentFactoryResolver,
  Injector,
  Input,
  OnInit,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DirtyStatus } from 'common/types/cad.constants';
import { Observable ,  Subscription } from 'rxjs';
import { AlertsService } from 'src/cad/core/services/alerts/alerts.service';
import { EmitterService } from 'src/cad/core/services/emitter/emitter.service';
import { RouterService } from 'src/cad/core/services/router/router.service';
import { AssociationApi } from 'src/features/common/association/association-api';
import { AssociationController } from 'src/features/common/association/association.controller';
import { ItemDetailController } from 'src/features/common/item-detail/item-detail.controller';
import { ItemController } from 'src/features/common/item/item.controller';
import { ItemModel } from 'src/features/common/item/item.model';

import { FormOnlyDialogComponent } from '../form-only-dialog/form-only-dialog.component';
import * as _ from 'lodash';

@Component({
  selector: 'ui-association',
  templateUrl: './association.component.html',
  styleUrls: [ './association.component.less' ]
})
export class AssociationComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() public associationController: AssociationController;

  public associationApiService: AssociationApi;
  public fromModel: ItemModel;
  public toModel: ItemModel;

  public itemData: any = {};
  public association: any;
  public selectedRows: any;
  public itemCtrl: any;
  public saveArray: any[];
  public cardToolbarItems: any[];
  public isDirtyTable: boolean = false;
  public isFullHeight: boolean;

  private subscriptions: Subscription[] = [];

  public constructor(
    private router: RouterService,
    public itemController: ItemController,
    private itemDetailController: ItemDetailController,
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    // private cadAlerts,
    private mdDialog: MatDialog,
    private emitterService: EmitterService,
    protected cadAlerts: AlertsService,
  ) {}

  public ngOnInit(): void {
    this.associationApiService = this.associationController.associationApiService;
    this.fromModel = this.associationController.fromController.model;
    this.toModel = this.associationController.model.toModel;
    if (!this.associationController.model.listEnableAutosave) {
      this.associationController.listSaveRowFn = this.gridRowSaveAssociation;
    }

    // TODO: Validate scaffold config and throw errors
    this.initItems();
    this.initListActionMenu();
    this.initCardToolbar();
    this.initHeading();

    this.associationController.model.listOnRowSelectionChangedCopy = _.clone(this.associationController.model.listOnRowSelectionChanged);
    // clear old selectsion from emitterService
    this.emitterService.subjectByKey(this.associationController.model.rowSelectionEventEmitter).next([]);
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.isFullHeight = this.associationController.model.listIsFullHeight;
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  // ========================================
  // initialization
  // ========================================

  public initItems(): void {
    this.subscriptions.push(this.itemDetailController.itemData$.subscribe((itemData) => {
      if (itemData) {
        this.itemData = itemData;
        this.associationController.refreshData(this.itemData[this.fromModel.primaryKey]);
      } else {
        this.itemData = {};
        this.associationController.itemsChanged([]);
      }
    }));
  }

  public initCardToolbar(): void {
    if (this.associationController.model.listSaveAllRows &&
      !this.associationController.model.listEnableAutosave) {
      this.cardToolbarItems = [
        {
          name: 'Undo',
          action: 'undo',
          icon: 'undo',
          method: this.revertTableChanges.bind(this),
          isDisabled: this.isIconEnabled.bind(this),
        },
        {
          name: 'Save',
          action: 'save',
          icon: 'save',
          method:  this.saveAssociation.bind(this),
          isDisabled: this.isIconEnabled.bind(this),
        },
      ];
    }
  }

  public initListActionMenu(): void {
    if (this.associationController.model.listActionMenu) {
      for (let item of this.associationController.model.listActionMenu.items) {
        switch (item.action) {
          case 'delete': {
            item.icon = 'delete';
            item.method = this.deleteAssociation.bind(this);
            break;
          }
          case 'edit': {
            item.icon = 'mode_edit';
            item.method = this.editAssociation.bind(this);
            break;
          }
          case 'view': {
            item.icon = 'remove_red_eye';
            item.method = this.viewAssociation.bind(this);
            break;
          }
          case 'expand': {
            item.icon = 'swap_vert';
            item.method = this.expandAssociation.bind(this);
            break;
          }
        }
      }
    }
  }

  public initHeading(): void {
    if (!this.associationController.model.heading) {
      this.associationController.model.heading = this.toModel.name + ' Association';
    }
  }

  public hasDirtyRows = (event:any): void => {
    let dirtyRow: any = _.find(this.associationController.items, (item): any => { return item.dirtyStatus > 0; });
    this.isDirtyTable =  dirtyRow ?  true : false;
  }

  public isIconEnabled = (event:any): boolean => {
    return !this.isDirtyTable;
  }

  public revertTableChanges = (event:any): void => {
    this.associationController.itemsChanged( this.associationController.originalItems );
    this.isDirtyTable =  false;
  }

  public saveAssociation = (event: any): void => {
    this.gridRowSaveAssociation(null);
  }

  public gridRowSaveAssociation = (rowEntity: any): Promise<any> => {

    if(rowEntity) {
      rowEntity.dirtyStatus = DirtyStatus.UPDATE;
      rowEntity[this.fromModel.primaryKey] = this.itemData[this.fromModel.primaryKey];
    }

    let saveArray = this.associationController.model.listSaveAllRows ? this.associationController.items : [ rowEntity ];
    this.deleteErrorMsgs(saveArray);
    let item = this.itemData;
    let preSaved = this.associationController.preSave(saveArray);

    let valid = this.checkValidationErrors(preSaved);

    if (valid && valid.errorMessages && valid.errorMessages.length > 0) {
      this.processError(valid);
      /**
       * in an error case, we need to return a rejected promise or the table will continue
       * to attempt saving over and over until it receives a rejected promise.  Also, the
       * parameter passed to the rejection function will be used as the tooltip for the row
       * visual validation component
       */
      let msg = valid.errorMessages.reduce((acc, err) => `${err.message} ${acc}`, '');
      return new Promise((res, rej) => rej(msg));
    }

    // Regardless if valid exist, if items is empty a save should not happen. There is nothing to save.
    if (this.associationController.items.length < 1) {
      return new Promise((res, rej) => rej('Array contained no associations.'));
    }

    this.saveArray = preSaved;

    let saveSubscription: Observable<any> = this.associationApiService.save(preSaved/*, item*/);

    return saveSubscription.toPromise().then((val: any) => {
      this.processSaveResult(this.associationController.model)(val);
      return val;
    });
  }

  // To clear previous validation errors on each save
  private deleteErrorMsgs(saveArray: any[]): any {
    _.forEach(saveArray, (row) => {
      return delete row.errorMessages;
    });
  }

  public checkValidationErrors(saveArray: any[]): any {
    let result: any = {};

    result.errorMessages = [];

    _.forEach(saveArray, (item) => {
      if (item.errorMessages) {
        _.forEach(item.errorMessages, (error) => {
          result.errorMessages.push({ message: error });
        });
      }
    });

    return result;
  }

  public processSaveResult(config: any): Function {
    return (result) => {
      if (result.successful) {
        this.processSuccess(result);
      } else {
        this.processError(result);
      }
    };
  }

  public processSuccess(result: any): void {
    this.cadAlerts.success('Association successfully updated.');
    this.associationController.refreshData(this.itemData[this.fromModel.primaryKey]);
    this.associationController.postSave(this.saveArray);
    this.saveArray = [];
    this.isDirtyTable =  false;
  }

  public processError(result: any): void {
    _.each(result.errorMessages, (errorMessage) => {
      this.cadAlerts.danger(errorMessage.message);
    });
  }

  public editAssociation(rowEntity: any): void {
    this.openModal(rowEntity);
  }

  public viewAssociation(rowEntity: any): void {
    // association usually assumes that the primary key for the toModel is in your rowEntity.
    // But if the name is different, you can use this property to map it
    if (this.associationController.model.toConfigPrimaryKeyMapping) {
      rowEntity[this.toModel.primaryKey] = rowEntity[this.associationController.model.toConfigPrimaryKeyMapping];
    }

    if (this.associationController.model.toRouteUrl.indexOf(':') > 0) {
      this.router.navigate([ this.getURLWithRouteParameters(rowEntity) ]);
    } else {
      this.router.navigate([ this.associationController.model.toRouteUrl, rowEntity[ this.toModel.primaryKey ] ]);
    }
  }

  /**
   take a routeURL like /blah/:key1/blah/:key2 with toModel.primaryKey of key1,key2 with data like
   {prop1:'val1', key1: 'keyVal1', key2: 'keyVal2', ...} and produce a URL like
   /blah/keyVal1/blah/keyVal2
   */
  private getURLWithRouteParameters(rowEntity: any): string {
    let url = this.associationController.model.toRouteUrl;
    let keys: string[] = this.toModel.primaryKey.split(',');
    _.each(keys, (key) => {
      url = url.replace(':' + key, rowEntity[ key ]);
    });
    return url;
  }

  public expandAssociation(rowEntity: any, params: any): void {
    params.node.canFlower = true;
    params.node.expanded = !params.node.expanded;
    params.api.onGroupExpandedOrCollapsed();
  }

  public deleteRow = (rowNode: any):void => {
    if((rowNode.data && rowNode.data.dirtyStatus) !== cad.DirtyStatus.NEW) {
      this.deleteAssociation(rowNode.data);
    }
  }

  public deleteAssociation= (rowEntity: any): void => {
    rowEntity.dirtyStatus = DirtyStatus.DELETE;
    rowEntity[this.fromModel.primaryKey] = this.itemData[this.fromModel.primaryKey];
    let association = [ rowEntity ];

    association = this.associationController.preDelete(association);

    this.subscriptions.push(this.associationApiService.save(association).subscribe((result) => {
      if (result.successful) {
        this.associationController.refreshData(this.associationController.itemDetailController.itemData[this.associationController.fromController.model.primaryKey]);
        let deleteMessage = this.associationController.model.deleteMessage || 'Association successfully deleted.';
        this.cadAlerts.success(deleteMessage);
      } else {
        //our results are getting moved to the errors, leaving in the errorMessages for now.
        _.each(result.errors, (errorMessage) => {
          this.cadAlerts.danger(errorMessage);
        });
        _.each(result.errorMessages, (errorMessage) => {
          this.cadAlerts.danger(errorMessage.message);
        });
      }
    }));
  }

  public resetCheckBox(): void {
    this.itemData.checkedItems = [];
    // loop through all items and check only those present in ctrl.selectedRows
    _.forEach(this.associationController.items, (item: any ) => {
      let searchQuery = {};

      searchQuery[this.associationController.model.primaryKey] = item[this.associationController.model.primaryKey];

      if (_.find(this.selectedRows, searchQuery)) {
        setTimeout(() => {
          this.associationController.model.listGridScope.gridApi.selection.selectRow(item);
        }, 100);
      }
    });
  }

  public onSelectedRows = (event: any): void => {
    this.emitterService.subjectByKey(this.associationController.model.rowSelectionEventEmitter).next(event);
    this.selectedRows = event.data;
    this.associationController.listSelections = event;
  }
  /**
   * This is the old select row.  I am leaving it until I can determine we have safely updated with the new method selectedRows
   * 3/2/2017 I am not sure yet where the modal open is needed.  Once determined we will see if we want to use onSelected rows
   * or an event that just uses the single row.  The onSelectedRows will pass all the selected rows.
   * @param row
   */
  public selectRow(row: any): void {
    this.selectedRows = this.associationController.model.listGridScope.gridApi.selection.getSelectedRows();

    if (this.associationController.model.listOnRowSelectionChangedCopy) {
      this.associationController.model.listOnRowSelectionChangedCopy({ row });
    } else {
      this.openModal(row.entity);
    }
  }

  public openModal(params?: any): void {
    if (this.associationController.model.readOnly) { return; }

    let dialogComponent;
    let width: string;
    let height: string;
    let maxWidth: string;

    if (this.associationController.model.modalTemplateName === 'form-only') {
      dialogComponent = FormOnlyDialogComponent;
      height = 'auto';
      width = `${this.associationController.model.modalSize}%` || '80%';
      maxWidth = 'none';
    } else {
      dialogComponent = FullscreenDialogComponent;
      width = '100%';
      height = '100%';
      maxWidth = '100vw';
    }

    this.mdDialog.open(dialogComponent, {
      width,
      height,
      maxWidth,
      data: {
        items: _.cloneDeep(this.associationController.items),
        controller: this.associationController,
        itemData: this.itemData,
        params: params || {},
        injector: this.injector,
      },
    });

    // this.router.navigate([ '.' + this.associationController.model.name, params ]);
  }
  
  callFunc(event: any): void {
    // TODO: this method exists in the template but wasn't implemented.  Needs fixing
  }
}
