import { Injectable } from '@angular/core';
import {
  CellComp, ColDef, Column, ColumnApi, ICellRendererParams, RowNode, RowRenderer,
  ValueFormatterParams
} from '@ag-grid-community/core';
import { GroupCellRendererParams } from '@ag-grid-community/core/dist/es6/rendering/cellRenderers/groupCellRenderer';
import { RowValidationService } from '../row/row-validation.service';
import { TableComponent } from '../table/table.component';
import { UiGridApi } from './ui-grid-api';
import { UiEditRowNode } from 'src/ag-grid-wrapper/interfaces/ui-edit-row-node';
import { UiAutoSaveRowNode } from 'src/ag-grid-wrapper/interfaces/ui-auto-save-row-node';
import * as _ from 'lodash';

@Injectable()
export class TableRowEditFactory {

  constructor( private rowValidationService: RowValidationService) { }

  public unRegisterGridListener(table: TableComponent): void {
    this.destroyPublicApi(table.api);
  }

  public onGridApiRegistered(table: TableComponent): void {
    this.setupPublicApi(table.api, table.columnApi);
    table.subs.newSub = table.cellValueChanged.asObservable().subscribe(this.onCellValueChanged.bind(this));
  }

  public clearAllRows(api: UiGridApi): any {
    return (): void => {
      api.setRowData([]);
      api.rowEdit.dirtyRows.splice(0);
      api.rowEdit.errorRows.splice(0);
    };
  }

  public getAllLeafRowNodes(api: UiGridApi): any {
    return (): RowNode[] => {
      let rowNodes: RowNode[] = [];
      api.forEachLeafNode((rowNode: RowNode) => {
        rowNodes.push(rowNode);
      });
      return rowNodes;
    };
  }

  public getAllRowNodes(api: UiGridApi): any {
    return (): RowNode[] => {
      let rowNodes: RowNode[] = [];
      api.forEachNode((rowNode: RowNode) => {
        rowNodes.push(rowNode);
      });
      return rowNodes;
    };
  }

  public getAllRowNodesAfterFilter(api: UiGridApi): any {
    return (): RowNode[] => {
      let rowNodes: RowNode[] = [];
      api.forEachNodeAfterFilter((rowNode: RowNode) => {
        rowNodes.push(rowNode);
      });
      return rowNodes;
    };
  }

  public getAllRowNodesAfterFilterAndSort(api: UiGridApi): any {
    return (): RowNode[] => {
      let rowNodes: RowNode[] = [];
      api.forEachNodeAfterFilterAndSort((rowNode: RowNode) => {
        rowNodes.push(rowNode);
      });
      return rowNodes;
    };
  }

  public getAllRowsData(api: UiGridApi): any {
    return (): any[] => {
      let dataRows: RowNode[] = this.getValidDataRowNodes(api.rowEdit.getAllRowNodes());
      return _.map(dataRows, 'data');
    };
  }

  public getAllRowsDataAfterFilter(api: UiGridApi): any {
    return (): any[] => {
      let dataRows: RowNode[] = this.getValidDataRowNodes(api.rowEdit.getAllRowNodesAfterFilter());
      return _.map(dataRows, 'data');
    };
  }

  public getAllRowsDataAfterFilterAndSort(api: UiGridApi): any {
    return (): any[] => {
      let dataRows: RowNode[] = this.getValidDataRowNodes(api.rowEdit.getAllRowNodesAfterFilterAndSort());
      return _.map(dataRows, 'data');
    };
  }

  /**
   * @name getDirtyRows
   * @description Returns all currently dirty rows
   * <pre>
   *      gridApi.rowEdit.getDirtyRows()
   * </pre>
   * @returns {array} An array of gridRows that are currently dirty
   *
   */
  public getDirtyRows(api: UiGridApi): any {
    return (): RowNode[] => {
      return (api && api.rowEdit && api.rowEdit.dirtyRows)
        ? api.rowEdit.dirtyRows
        : [];
    };
  }

  public getDirtyRowsData(api: UiGridApi): any {
    return (): any[] => {
      let dataRows: RowNode[] = this.getValidDataRowNodes(api.rowEdit.getDirtyRows());
      return _.map(dataRows, 'data');
    };
  }

  /**
   * @name hasDirtyRows
   * @description Returns true if any of the Grid's rows are dirty
   *              otherwise returns false
   * <pre>
   *      gridApi.rowEdit.hasDirtyRows()
   * </pre>
   * @returns {boolean}
   *
   */
  public hasDirtyRows(api: UiGridApi): () => boolean {
    return (): boolean => {
      if (api && api.rowEdit) {
        return api.rowEdit.getDirtyRows().length > 0;
      }

      return false;
    };
  }

  /**
   * @name getEditingCells
   * @description Returns all currently editing cells
   * <pre>
   *      gridApi.rowEdit.getEditingCells()
   * </pre>
   * @returns {array} An array of CellComp that are currently being edited
   *
   */
  public getEditingCells(api: UiGridApi): () => CellComp[] {
    return (): CellComp[] => {
      let rowRenderer: RowRenderer;
      let cellComponents: CellComp[] = [];

      if (api && (api as any).rowRenderer) {
        rowRenderer = (api as any).rowRenderer;
        rowRenderer.forEachCellComp((cellComp: CellComp) => {
          if (cellComp && cellComp.isEditing()) {
            cellComponents.push(cellComp);
          }
        });
      }

      return cellComponents;
    };
  }

  /**
   * @name hasEditingCells
   * @description Returns true if any of the Grid's cells are currently being edited
   *              otherwise returns false
   * <pre>
   *      gridApi.rowEdit.hasEditingCells()
   * </pre>
   * @returns {boolean}
   *
   */
  public hasEditingCells(api: UiGridApi): () => boolean {
    return (): boolean => {
      if (api && api.rowEdit) {
        return api.rowEdit.getEditingCells().length > 0;
      }

      return false;
    };
  }

  /**
   * @name getErrorRows
   * @description Returns all rows that are marked as error.
   * <pre>
   *      gridApi.rowEdit.getErrorRows()
   * </pre>
   * @returns {array} An array of gridRows that are currently in error
   *
   */
  public getErrorRows(api: UiGridApi): any {
    return (): RowNode[] => {
      return (api && api.rowEdit && api.rowEdit.errorRows)
        ? api.rowEdit.errorRows
        : [];
    };
  }

  public getErrorRowsData(api: UiGridApi): any {
    return (): any[] => {
      let dataRows: RowNode[] = this.getValidDataRowNodes(api.rowEdit.getErrorRows());
      return _.map(dataRows, 'data');
    };
  }

  /**
   * @name hasErrorRows
   * @description Returns true if any of the Grid's rows are marked as error
   *              otherwise returns false
   * <pre>
   *      gridApi.rowEdit.hasErrorRows()
   * </pre>
   * @returns {boolean}
   *
   */
  public hasErrorRows(api: UiGridApi): () => boolean {
    return (): boolean => {
      if (api && api.rowEdit) {
        return api.rowEdit.getErrorRows().length > 0;
      }

      return false;
    };
  }

  /**
   * @name setRowsClean
   * @description Sets each of the rows passed in dataRows
   * to be clean, clearing the dirty flag and the error flag, and removing
   * the rows from the dirty and error caches.
   * @param {object} api the gridApi for which rows should be set clean
   * Callback param: {array} rowNodes the row nodes for which the gridRows
   * should be set clean.
   */
  public setRowsClean(api: UiGridApi): any {
    return (rowNodes: RowNode[]): void => {
      rowNodes.forEach((gridRow: RowNode): void => {
        if (gridRow) {
          delete (gridRow as UiAutoSaveRowNode).isSaving;
          if ((gridRow as UiAutoSaveRowNode).rowEditSaveTimer && !(gridRow as UiAutoSaveRowNode).isSaving) {
            clearInterval((gridRow as UiAutoSaveRowNode).rowEditSaveTimer as NodeJS.Timer);
            delete (gridRow as UiAutoSaveRowNode).rowEditSaveTimer;
          }
          delete (gridRow as UiAutoSaveRowNode).rowEditSavePromise;
          api.rowEdit.removeDirtyRow(gridRow);
          api.rowEdit.removeErrorRow(gridRow);
        }
      });
      api.redrawRows({ rowNodes });
    };
  }

  public setGridClean(api: UiGridApi): any {
    return (): void => {
      if (api.rowEdit && api.rowEdit) {
        api.rowEdit.setRowsClean(api.rowEdit.getAllRowNodes());
      }
    };
  }

  public setDirtyRow(api: UiGridApi): any {
    return (dirtyRow: RowNode): void => {
      if (dirtyRow && api && api.rowEdit) {
        if (!api.rowEdit.dirtyRows) {
          api.rowEdit.dirtyRows = [];
        }
        let existingDirtyRowIndex: number = api.rowEdit.getDirtyRowIndex(dirtyRow);
        (dirtyRow as UiEditRowNode).isDirty = true;
        if (existingDirtyRowIndex > -1) {
          api.rowEdit.dirtyRows[ existingDirtyRowIndex ] = dirtyRow;
        } else {
          api.rowEdit.dirtyRows.push(dirtyRow);
        }
      }
    };
  }

  public removeDirtyRow(api: UiGridApi): any {
    return (removeGridRow: RowNode): void => {
      let dirtyRowIndex: number = api.rowEdit.getDirtyRowIndex(removeGridRow);
      if (dirtyRowIndex > -1) {
        api.rowEdit.dirtyRows.splice(dirtyRowIndex, 1);
        delete (removeGridRow as UiEditRowNode).isDirty;
      }
    };
  }

  public getDirtyRowIndex(api: UiGridApi): any {
    return (dirtyRow: RowNode): number => {
      return _.findIndex(api.rowEdit.getDirtyRows(), { id: dirtyRow.id });
    };
  }

  public setErrorRow(api: UiGridApi): any {
    return (errorRow: RowNode, msg?: any): void => {
      if (errorRow && api && api.rowEdit) {
        if (!api.rowEdit.errorRows) {
          api.rowEdit.errorRows = [];
        }
        let existingErrorRowIndex: number = api.rowEdit.getErrorRowIndex(errorRow);
        (errorRow as UiEditRowNode).isError = true;
        if (msg) {
          this.rowValidationService.setMessage(errorRow, msg);
        }
        if (existingErrorRowIndex > -1) {
          api.rowEdit.errorRows[ existingErrorRowIndex ] = errorRow;
        } else {
          api.rowEdit.errorRows.push(errorRow);
        }
      }
    };
  }

  public removeErrorRow(api: UiGridApi): any {
    return (removeGridRow: RowNode): void => {
      let errorRowIndex: number = api.rowEdit.getErrorRowIndex(removeGridRow);
      if (errorRowIndex > -1) {
        api.rowEdit.errorRows.splice(errorRowIndex, 1);
        delete (removeGridRow as UiEditRowNode).isError;
      }
    };
  }

  public getErrorRowIndex(api: UiGridApi): any {
    return (errorRow: RowNode): number => {
      return _.findIndex(api.rowEdit.getErrorRows(), { id: errorRow.id });
    };
  }

  public getUserColumns(columnApi: ColumnApi): any {
    return (columnList?: Column[]): Column[] => {
      let sourceColumns: Column[];

      if (_.isArray(columnList) && !_.isEmpty(columnList)) {
        sourceColumns = columnList;
      } else if (columnApi) {
        sourceColumns = columnApi.getAllDisplayedColumns();
      }
      return _.filter(sourceColumns, (column: Column) => !_.includes([
        'navigationMenu',
        'singleSelect',
        'multiSelect',
        'validationVisual',
        'actionMenu',
        'showDelete',
        'showExpand',
        'rowMessageIcon'
      ], column.getColId()));
    };
  }

  public getFormattedValue(api: UiGridApi): any {
    return (colKey: string, rowNode: RowNode): any => {
      let returnValue: any;
      let column: Column;
      let colDef: ColDef;
      let rowData: any;
      let columnApi: ColumnApi;

      if (api) {
        columnApi = (api as any).gridOptionsWrapper
          ? (api as any).gridOptionsWrapper.getColumnApi()
          : null;
        if (columnApi) {
          column = columnApi.getColumn(colKey);
        }
        if (!_.isNil(column) && !_.isNil(rowNode)) {
          /*
          The code line below accesses the private property 'params' by casting the ICellRendererComp
          {@see https://github.com/ag-grid/ag-grid/blob/v22.1.1/community-modules/core/src/ts/rendering/cellRenderers/iCellRenderer.ts#L35}
          to 'any' since ag-grid does not provide the 'params' property as part of the interface.
           */
          let cellRendererParams: ICellRendererParams = api.getCellRendererInstances({ rowNodes: [ rowNode ], columns: [ column ] })[ 0 ]
            ? (api.getCellRendererInstances({ rowNodes: [ rowNode ], columns: [ column ] })[ 0 ] as any).params
            : null;

          returnValue = cellRendererParams
            ? ((rowNode.footer && _.isFunction((cellRendererParams as unknown as GroupCellRendererParams).footerValueGetter))
              ? (cellRendererParams as unknown as GroupCellRendererParams).footerValueGetter(cellRendererParams)
              : cellRendererParams.getValue())
            : api.getValue(colKey, rowNode);
          colDef = column.getColDef();
          if (rowNode.groupData || rowNode.data) {
            rowData = _.cloneDeep(rowNode.group ? rowNode.groupData : rowNode.data);
          }
          if (colDef && rowData) {
            if (_.isFunction(rowData[ colDef.field ])) {
              rowData[ colDef.field ] = rowData[ colDef.field ](colDef.field);
            }
            if (_.isFunction(colDef.valueFormatter)) {
              let valueFormatterParams: ValueFormatterParams = {
                value: returnValue,
                node: rowNode,
                [ rowNode.group ? 'groupData' : 'data' ]: rowData,
                colDef,
                column,
                api,
                columnApi,
                context: (rowNode as any).context
              } as ValueFormatterParams;
              returnValue = colDef.valueFormatter(valueFormatterParams);
            }
          }
        }
      }
      return returnValue;
    };
  }

  private scrollToFocus(api: UiGridApi): any {
    return (gridRowData: any, scrollToFocusCol?: string): number => {
      if (gridRowData && api) {
        let rowIdx: number = _.findIndex(api.rowEdit.getAllRowsDataAfterFilterAndSort(), (row) => row === gridRowData);
        if (scrollToFocusCol) {
          api.ensureColumnVisible(scrollToFocusCol);
          api.setFocusedCell(rowIdx, scrollToFocusCol, null);
          api.startEditingCell({
            rowIndex: rowIdx,
            colKey: scrollToFocusCol,
          });
        }
        return rowIdx;
      }
      return null;
    };
  }

  private stopEditing(api: UiGridApi): any {
    return (): void => {
      api.stopEditing();
    };
  }

  private setupPublicApi(api: UiGridApi, columnApi: ColumnApi): void {
    _.defaultsDeep(api, {
      rowEdit: {
        scrollToFocus: this.scrollToFocus(api),
        stopEditing: this.stopEditing(api),
        clearAllRows: this.clearAllRows(api),
        setRowsClean: this.setRowsClean(api),
        setGridClean: this.setGridClean(api),
        /**
         * @description Send in an invalid row and validation message.
         * @param errorRow: any => pass in the grid row that has the validation error
         * @param msg?: any: This is the validation message.  Can be in form of string, array or ValidationObject
         */
        setErrorRow: this.setErrorRow(api),
        setDirtyRow: this.setDirtyRow(api),
        removeDirtyRow: this.removeDirtyRow(api),
        removeErrorRow: this.removeErrorRow(api),
        getAllLeafRowNodes: this.getAllLeafRowNodes(api),
        getAllRowNodes: this.getAllRowNodes(api),
        getAllRowNodesAfterFilter: this.getAllRowNodesAfterFilter(api),
        getAllRowNodesAfterFilterAndSort: this.getAllRowNodesAfterFilterAndSort(api),
        getAllRowsData: this.getAllRowsData(api),
        getAllRowsDataAfterFilter: this.getAllRowsDataAfterFilter(api),
        getAllRowsDataAfterFilterAndSort: this.getAllRowsDataAfterFilterAndSort(api),
        getDirtyRows: this.getDirtyRows(api),
        getDirtyRowsData: this.getDirtyRowsData(api),
        hasDirtyRows: this.hasDirtyRows(api),
        getEditingCells: this.getEditingCells(api),
        hasEditingCells: this.hasEditingCells(api),
        getDirtyRowIndex: this.getDirtyRowIndex(api),
        getErrorRows: this.getErrorRows(api),
        getErrorRowsData: this.getErrorRowsData(api),
        hasErrorRows: this.hasErrorRows(api),
        getErrorRowIndex: this.getErrorRowIndex(api),
        getUserColumns: this.getUserColumns(columnApi),
        getFormattedValue: this.getFormattedValue(api),
        setGridRowDirty: this.setGridRowDirty,
        dirtyRows: [],
        errorRows: [],
      }
    });
  }

  private destroyPublicApi(api: UiGridApi): void {
    if (api && api.rowEdit) {
      delete api.rowEdit.scrollToFocus;
      delete api.rowEdit.stopEditing;
      delete api.rowEdit.clearAllRows;
      delete api.rowEdit.setRowsClean;
      delete api.rowEdit.setGridClean;
      delete api.rowEdit.setErrorRow;
      delete api.rowEdit.setDirtyRow;
      delete api.rowEdit.removeDirtyRow;
      delete api.rowEdit.removeErrorRow;
      delete api.rowEdit.getAllLeafRowNodes;
      delete api.rowEdit.getAllRowNodes;
      delete api.rowEdit.getAllRowNodesAfterFilter;
      delete api.rowEdit.getAllRowNodesAfterFilterAndSort;
      delete api.rowEdit.getAllRowsData;
      delete api.rowEdit.getAllRowsDataAfterFilter;
      delete api.rowEdit.getAllRowsDataAfterFilterAndSort;
      delete api.rowEdit.getDirtyRows;
      delete api.rowEdit.getDirtyRowsData;
      delete api.rowEdit.hasDirtyRows;
      delete api.rowEdit.getEditingCells;
      delete api.rowEdit.hasEditingCells;
      delete api.rowEdit.getDirtyRowIndex;
      delete api.rowEdit.getErrorRows;
      delete api.rowEdit.getErrorRowsData;
      delete api.rowEdit.hasErrorRows;
      delete api.rowEdit.getErrorRowIndex;
      delete api.rowEdit.getUserColumns;
      delete api.rowEdit.getFormattedValue;
      delete api.rowEdit.setGridRowDirty;
      if (_.isArray(api.rowEdit.dirtyRows)) {
        api.rowEdit.dirtyRows.splice(0);
      }
      if (_.isArray(api.rowEdit.errorRows)) {
        api.rowEdit.errorRows.splice(0);
      }
      delete api.rowEdit.dirtyRows;
      delete api.rowEdit.errorRows;
    }
  }

  private onCellValueChanged(event: any): void {
    // fires on cell editing stopped regardless of whether value changed.
    this.cellValueChanged(event.api, event.node, event.newValue, event.oldValue);
  }

  private cellValueChanged(grid: any, gridRow: any, newValue: any, oldValue: any): void {
    if (gridRow && newValue !== oldValue) {
      this.setGridRowDirty(grid, gridRow);
    }
  }

  private setGridRowDirty(api: UiGridApi, gridRow: RowNode): void {
    if (gridRow && gridRow.data) {
      if (_.isUndefined(gridRow.data.dirtyStatus)) {
        gridRow.data.dirtyStatus = 2;
      } else if (gridRow.data.dirtyStatus !== 1) {
        gridRow.data.dirtyStatus = 2;
      }
    }
    if (api && api.rowEdit) {
      api.rowEdit.setDirtyRow(gridRow);
    }
  }

  private getValidDataRowNodes(rowNodes: RowNode[]): RowNode[] {
    return _.filter(rowNodes, (rowNode: RowNode) => (rowNode && !_.isNil(rowNode.data)));
  }
}
