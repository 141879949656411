import { ApiHelper } from 'cad/common/services/api/api-helper';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ImbalanceAdjustmentData } from 'src/cad/flowing-gas/imbalance-adjustment/interfaces/imbalance-adjustment-data';
import { ImbalanceAdjustmentSearchFilterData } from 'src/cad/flowing-gas/imbalance-adjustment/interfaces/imbalance-adjustment-search-filter-data';

@Injectable()
export class ImbalanceAdjustmentApi {
  public readonly endpoint: string = 'flowing-gas/imbalance-adjustment';

  constructor(
    private apiHelper: ApiHelper,
  ) {}
  
  public retrieveItems(filterData: ImbalanceAdjustmentSearchFilterData): Observable<ImbalanceAdjustmentData[]> {
    return this.apiHelper.request(`${this.endpoint}/filter`, { body: filterData });
  }
  
  public deleteItems(items: ImbalanceAdjustmentData[]): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/delete-items`, { body: items });
  }
  
  public saveItems(items: ImbalanceAdjustmentData[]): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/save-all`, { body: items });
  }

  public getCurrentAccountingPeriod(): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/current-period`, { method: 'GET'});
  }
}
