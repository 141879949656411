import { ActivatedRouteSnapshot, Route, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { SecurityModel } from './security.model';

export abstract class SecurityAuthorizer {
  public abstract canActivate(
    config: SecurityModel,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean;

  public abstract canActivateChild(
    config: SecurityModel,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean;

  public abstract canLoad(
    config: SecurityModel,
    route: Route,
  ): Observable<boolean> | Promise<boolean> | boolean;
}
