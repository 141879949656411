import { ICellRendererParams } from '@ag-grid-community/core';

export function linkFormatter(params: ICellRendererParams): string {
  let val = _.get(params.data, params.colDef.field);
  let template = `
    <div class="inner-cell">
      <a href="${(params.colDef as any).href}${params.value}">${params.value}</a>
    </div>
  `;
  return val ? template : '';
}
