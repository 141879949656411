import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'humanizeDoc' })
export class HumanizeDocPipe implements PipeTransform {
  transform(doc: any, args: string[]): any {
    if (!doc) { return ''; }

    // not sure what this was ever intended to do.  It was converted from Angular1
    //  I do not see any calls that have the type as a directive.  They are all links or toggles...
    if (doc.type === 'directive') {
      return doc.name.replace(/([A-Z])/g, ($1) => {
        return '-' + $1.toLowerCase();
      });
    }

    return doc.label || doc.name;
  }
}
