import { Component, Inject, OnInit } from '@angular/core';
import { AutoUnsubscriber, AutoUnsubscribables } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NominationConfirmationLocationContactApi } from 'src/cad/common/services/api/nomination/confirmation/location/contact/contact';
import { ConfigService } from 'src/cad/core/services/config/config.service';

@Component({
  selector: 'location-contacts-modal',
  templateUrl: './location-contacts-modal.component.html',
})
export class LocationContactsModalComponent implements OnInit {

  public noticeContactPageUrl: string;
  private locationId: string;
  public contactData: any;
  public title: string;
  private confirmationInfo: boolean;
  @AutoUnsubscriber() protected subs: AutoUnsubscribables;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public configSvc: ConfigService,
    private nominationConfirmationLocationContactApi: NominationConfirmationLocationContactApi,
    public dialogRef: MatDialogRef<LocationContactsModalComponent>,
  ) {
    this.locationId = this.data.locationId;
    this.title = this.data.title;
    this.confirmationInfo = this.data.confirmationInfo;
    this.subs.newSub  = this.nominationConfirmationLocationContactApi.getById(this.locationId,this.data.assetNbr).subscribe((result: any) => {
      this.contactData = result;
    });
  }

  public ngOnInit(): void {
    if (this.configSvc) {
      this.noticeContactPageUrl = this.configSvc.getInfoPostBaseUrl() + '/notice/notice-contact-page';
    }
  }

  public cancel = (): void => {
    this.dialogRef.close();
  }
}
