import { ApiHelper } from 'cad/common/services/api/api-helper';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AutomaticSchedulingParamsApi {

  public endpoint: string = 'flow/auto-scheduling/automatic-scheduling-params';

  constructor(
    private apiHelper: ApiHelper
  ) {}

  public findPointAutoScheduleOverview (effBeginDate: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/point/filter', { body: { effBeginDate } });
  }

  public findSegmentAutoScheduleOverview (effFromDate: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/segment/filter', { body: { effFromDate } });
  }

  public savePointAutoScheduleParams( pointParams: any[]) : Observable<any> {
    return this.apiHelper.request(this.endpoint + '/point/save', { body:  pointParams  });
  }

  public saveSegmentAutoScheduleParams( segmentParams: any[]) : Observable<any> {
    return this.apiHelper.request(this.endpoint + '/segment/save', { body: segmentParams });
  }
}

