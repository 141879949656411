
import {filter} from 'rxjs/operators';
import {AutoUnsubscriber, AutoUnsubscribables} from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import {Component, ViewChild, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {SidenavController} from 'src/features/common/sidenav/sidenav.controller';
import {SidenavService} from 'common/services/sidenav/sidenav';
import {RootObject} from 'cad/common/store/user/user-model';
import {UserStoreService} from 'cad/common/store/core/services/user-store.service';


@Component({
  selector: 'ui-sidenav-view',
  templateUrl: './sidenav.component.html',
  styleUrls: [ './sidenav.component.less' ],
})
export class SidenavComponent implements OnDestroy, OnInit {

  @ViewChild('sidenavMenu') sidenav: any;
  public sections: any[] = [];

  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  public constructor(
    private currentContextService: UserStoreService,
    private sidenavService: SidenavService,
    public sidenavController: SidenavController,
    public elementRef: ElementRef,
  ) {
  }

  public setMargin(): void {
    let content = this.elementRef.nativeElement.getElementsByClassName('mat-sidenav-content')[0];
    let marginLeft = this.sidenav._elementRef.nativeElement.offsetWidth * .25;

    setTimeout(() => {
      content.setAttribute('style', 'margin-left: ' + marginLeft + 'px');
    });
  }

  public ngOnInit(): void {
    if (this.sidenavController.model &&
      this.sidenavController.model.sidenavSections &&
      this.sidenavController.model.sidenavSections.sections) {
      this.subs.newSub = this.currentContextService.stateChanged.pipe(
        filter((userDetail) => userDetail.state && userDetail.state.user))
        .subscribe((userDetail) => {
          this.sidenavService.buildSideNav(this.sidenavController.model.sidenavSections.sections, userDetail.state.user);
          this.subs.newSub = this.sidenavService.sidenavSections$.subscribe((sections) => this.sections = sections);
        });
    }
    this.sidenavService.clearCards();
  }

  public onScrollFn(scrollParams: any): void {
    this.sidenavService.setStateOnScroll(scrollParams);
  }

  public ngOnDestroy(): void {
    this.sidenavService.clearSideNav();
    this.sidenavService.clearCards();
    this.sidenav = null;
  }
}
