import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Breadcrumb } from 'src/features/common/breadcrumb/breadcrumb';
import { BreadcrumbService } from 'src/features/common/breadcrumb/breadcrumb.service';
import { RouterService } from 'src/cad/core/services/router/router.service';

@Component({
  selector: 'ui-breadcrumb',
  templateUrl: './ribbon-breadcrumb.component.html',
  styleUrls: [ './ribbon-breadcrumb.component.less' ],
})
export class RibbonBreadcrumbComponent implements OnInit, OnDestroy {

  public breadcrumbs: Breadcrumb[];

  private subscriptions: Subscription[] = [];

  constructor(
    private router: RouterService,
    private breadcrumbService: BreadcrumbService,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(this.breadcrumbService.breadcrumbs$.subscribe((breadcrumbs: Breadcrumb[]) => this.onBreadcrumbs(breadcrumbs)));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public goHome(): void {
    this.router.navigate([ '/dashboard' ]);
  }

  private onBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
    this.breadcrumbs = breadcrumbs;
  }
}
