<div class="results">
  <div fxLayout="row"
       fxLayoutAlign="start center"
       fxLayoutGap="18px">
      <strong>{{(tableComponent?.api?.rowEdit?.getAllRowNodesAfterFilterAndSort().length || results?.length)}} Results</strong>
    <div fxFlex="auto"
         fxLayout="column"
         fxLayoutAlign="center start">
      <ng-content select=".ui-search-results-right-content, [uiSearchResultsRightContent]"></ng-content>
    </div>
  </div>

  <!-- <div fxLayout="row">
    <div fxFlex>
      <div class="search-filters" fxLayout="row" fxLayoutAlign="start center">
        <em style="padding-right: 10px">Filters:</em>

        <mat-chip-list fxFlex>
          <mat-chip *ngFor="let param of paramKeys">
            <strong>{{param}}</strong>

            <em>{{params[param]}}</em>

            <a (click)="removeParam(param)">
              <mat-icon md-font-set="material-icons">close</mat-icon>
            </a>
          </mat-chip>
        </mat-chip-list>

        <ui-button (click)="clearParams()">Clear All Filters</ui-button>
        <ui-button option="primary">Search</ui-button>
      </div>
    </div>
  </div> -->

  <ng-content></ng-content>
</div>
