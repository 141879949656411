
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AsyncValidator, AbstractControl, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidatorFn, Validator, Validators, ValidationErrors } from '@angular/forms';

import { FilterByAbbr } from 'cad/common/services/api/filter-by-abbr';
import { ObservableValidator, ObservableValidatorConfig, ObservableValidatorSource, ObservableValidatorResult } from '../observable-validator/observable-validator';

export interface UniqueAbbrValidatorConfig extends ObservableValidatorConfig {
  api: FilterByAbbr;
  key: string;
  ignore?: any;
  caseSensitive?: boolean;
}

@Directive({
  selector: '[uniqueAbbr]',
  providers: [ {provide: NG_ASYNC_VALIDATORS, useExisting: UniqueAbbrValidatorDirective, multi: true} ],
})
export class UniqueAbbrValidatorDirective extends ObservableValidator implements OnChanges {
  @Input('uniqueAbbr') config: UniqueAbbrValidatorConfig;

  ngOnChanges(changes: SimpleChanges): void {
    super.onChanges(changes, this.config);
  }

  asyncValidate(next: ObservableValidatorSource): Observable<ObservableValidatorResult> {
    if (!this.config.api && !this.config.key) {
      console.error('UniqueAbbrValidatorDirective not configured properly', this);
      return observableOf({ errors: { uniqueAbbr: true }, count: next.count });
    }
    if (next.value) {
      return this.config.api.filterByAbbr(next.value).pipe(map(
        (results) => {
          const conflicts = results.filter((result) => {
            if (this.config.ignore && this.config.ignore === result[this.config.key]) {
              return false;
            }
            if (this.config.caseSensitive) {
              return result[this.config.key] === next.value;
            } else {
              return result[this.config.key].toLowerCase() === next.value.toLowerCase();
            }
          });
          if (conflicts.length) {
            console.error('Abbr is not unique', { value: next.value, config: this.config });
            return { errors: { uniqueAbbr: true }, count: next.count };
          } else {
            return { errors: null, count: next.count };
          }
        },
        // tslint:disable:handle-callback-err
        (error) => {
          return { errors: null, count: next.count };
        }));
        // tslint:enable:handle-callback-err
    } else {
      return observableOf({ errors: null, count: next.count });
    }
  }
}
