import { AgreementContractApi } from 'cad/common/services/api/agreement/contract/contract';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiCache } from 'cad/common/services/api/api-cache';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AlertsService } from 'cad/core/services/alerts/alerts.service';
import { ContractData } from 'src/cad/contracts/shared/interfaces/contract';
import { AgreementLoadFactor } from 'src/cad/contracts/shared/interfaces/agreement-load-factor';
import { LoadFactorData } from 'src/cad/structured-products/define-service/interfaces/load-factor-data';
import { ContractFilterData } from 'src/cad/contracts/shared/interfaces/contract-filter';
import ValidationResult = cad.ValidationResult;

@Injectable()
export class ForecastContractApi extends AgreementContractApi {

  private readonly forecastContractEndpoint: string = 'portfolio/forecast/forecast-contract';
  
  constructor(
    public apiHelper: ApiHelper,
    public apiCache: ApiCache,
    public alertService: AlertsService,
  ) {
    super(apiHelper, apiCache, alertService);
  }
  
  public filter(params: Partial<ContractFilterData>): Observable<ContractFilterData[]> {
    return this.apiHelper.request(`${ this.forecastContractEndpoint }/filter`, { body: { ...params, isContract: this.contractType } });
  }

  setStatusAvailable(id: number): Observable<ContractFilterData[]> {
    return this.apiHelper.request(`${ this.forecastContractEndpoint }/${id}/available`);
  }

  setStatusProposed(id: number): Observable<ContractFilterData[]> {
    return this.apiHelper.request(`${ this.forecastContractEndpoint }/${id}/proposed`);
  }
  
  getServiceUtilizationPercentage(contractData: ContractData): Observable<LoadFactorData[]> {
    return this.apiHelper.request(`${ this.forecastContractEndpoint }/service-utilization-percentage`, { body: contractData });
  }
  
  getContractUtilizationPercentage(contractData: ContractData): Observable<AgreementLoadFactor[]> {
    return this.apiHelper.request(`${ this.forecastContractEndpoint }/contract-utilization-percentage`, { body: contractData });
  }
  
  saveContractUtilization(contractId: number, agreementLoadFactorList: AgreementLoadFactor[]): Observable<ValidationResult<AgreementLoadFactor[]>> {
    return this.apiHelper.request(`${ this.forecastContractEndpoint }/${contractId}/save-contract-utilization`, { body: agreementLoadFactorList });
  }
}
