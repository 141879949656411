<div [fxLayout]="itemLayout" class="ui-multi-checkbox">
  <div class="label" *ngIf="label">{{ label }}</div>
  <div *ngFor="let item of items; let i=index" fxFlex="{{flexAmount}}" class="ui-multi-checkbox-item">
    <mat-checkbox
      [ngModel]="items[i][checkedSym]"
      (ngModelChange)="setIndividualElement($event, i)"
      [disabled]="item[disabledSym]">
      {{item[itemLabel]}}
    </mat-checkbox>
  </div>
</div>
