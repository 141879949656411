import { Injectable } from '@angular/core';
import { CapacityReleaseOffersApi } from 'src/cad/common/services/api/capacity-release/offers/offers';
import { CapacityReleaseOfferBidsApi } from 'src/cad/common/services/api/capacity-release/bids/bids';
import { CapacityReleaseOfferAwardsApi } from 'src/cad/common/services/api/capacity-release/awards/awards';
import { CapacityReleaseRecallsApi } from 'src/cad/common/services/api/capacity-release/recalls/recalls';
import { CapacityReleaseReputsApi } from 'src/cad/common/services/api/capacity-release/reputs/reputs';
import { CapacityReleaseStorageRelationshipApi } from 'src/cad/common/services/api/capacity-release/storage-relationship/storage-relationship';

@Injectable()
export class CapacityReleaseApi {
  constructor(
    public offers: CapacityReleaseOffersApi,
    public bids: CapacityReleaseOfferBidsApi,
    public awards: CapacityReleaseOfferAwardsApi,
    public recalls: CapacityReleaseRecallsApi,
    public reputs: CapacityReleaseReputsApi,
    public storageRelationship: CapacityReleaseStorageRelationshipApi
  ) {}
}
