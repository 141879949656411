import { Component, Inject, AfterViewInit, ViewChild, HostBinding } from '@angular/core';
import { TABLE_EDITOR_DATA } from 'src/ag-grid-wrapper';
import { UiFormSelectComponent } from 'cad/shared/forms/select/select/select.component';
import * as _ from 'lodash';

/**
 *
 * This is an example of a custom editor for the ag-grid-wrapper.  In order for this to work we need to inject the TABLE_EDITOR_DATA
 * provider which gives us access to the params object.  This object contains everything we need to work with the table column, including
 * column definition, grid api, etc...  The value of your editor should be bound to params.value.  This editor can be used directly by the
 * table column or it can be wrapped in a shared directive and placed in src/cad/shared/table-renderers to remove boilerplate for users which
 * is preferable if you are creating a renderer that should be reusable
 *
 * Example of Direct Usage:
 *
 *    ```@Component({
 *         selector: 'association-list-partial',
 *         templateUrl: './the-template.component.html',
 *       })
 *       export class AssociationListPartial {
 *         // don't forget to import SelectEditorComponent
 *         public selectEditorComponent: Type<SelectEditorComponent>;
 *         constructor() {
 *            this.selectEditorComponent = SelectEditorComponent;
 *         }
 *       }
 *
 *    later in component template...
 *
 *    ```<ui-table-column
 *           name="test"
 *           display-name="test"
 *           [customEditor]="selectEditorComponent"
 *           type="custom">
 *       </ui-table-column>```
 *
 * Example of Directive Wrapper:
 *    see src/cad/shared/table-renderers/select-code/select-code-editor.directive.ts
 *
 **/
@Component({
  selector: 'cad-select-code-editor',
  template: `
    <div class="select-editor" tabindex="-1">
      <ui-select #select
                 [codeTypeAsset]="customCellConfig?.codeTypeAssetCol"
                 [codeType]="params?.column?.colDef?.cellFilter"
                 [(ngModel)]="params.value"
                 [disabledOptions]="customCellConfig?.disabledOptions"
                 (change)="onSelectClose($event)">
      </ui-select>
    </div>
  `,
  styleUrls: [ './select-code-editor.component.less' ],
})
export class SelectCodeEditorComponent implements AfterViewInit {

  @ViewChild('select', { read: UiFormSelectComponent }) public select: UiFormSelectComponent;
  @HostBinding('attr.tabindex') public tabindex: number = -1;
  public get customCellConfig(): any {
    if (this.params
      && this.params.column
      && this.params.column.colDef) {
      return (_.isFunction(this.params.column.colDef.customCellConfig)
        ? this.params.column.colDef.customCellConfig(this.params)
        : this.params.column.colDef.customCellConfig);
    }
    return null;
  }

  constructor (@Inject(TABLE_EDITOR_DATA) public params: any) {
  }

  ngAfterViewInit(): void {
    if (this.select) {
      setTimeout(() => this.select.matSelectRef.focus());
    }
  }

  onSelectClose(event: any): void {
    if(this.params && this.params.column && this.params.column.colDef && this.params.column.colDef.fireChangeOnSelect) {
      if(_.isFunction(this.params.stopEditing)) {
        this.params.stopEditing();
      } else if(this.params.api && _.isFunction(this.params.api.stopEditing)) {
        this.params.api.stopEditing();
      }
    }
  }
}
