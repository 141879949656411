import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, ReplaySubject ,  Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/features/common/breadcrumb/breadcrumb.service';
import { Breadcrumb } from 'src//features/common/breadcrumb/breadcrumb';
import * as _ from 'lodash';

@Injectable()
export class AlertsService implements OnDestroy {

  public alerts$: ReplaySubject<cad.Alert[]> = new ReplaySubject<cad.Alert[]>(1);
  public snackbarAlerts$: ReplaySubject<cad.Alert[]> = new ReplaySubject<cad.Alert[]>(1);
  public alertsSidenavToggle$: Subject<void> = new Subject<void>();

  private alertsStore: cad.Alert[] = [];
  private snackbarAlertsStore: cad.Alert[] = [];
  private dismissOnTimeoutDefault: number = 6000;
  private breadcrumbSubscription: Subscription = Subscription.EMPTY;
  private breadcrumbs: Breadcrumb[];

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
  ) {
    if (this.breadcrumbService) {
      this.breadcrumbSubscription = this.breadcrumbService.breadcrumbs$.subscribe((items: Breadcrumb[]) => {
        this.breadcrumbs = items;
      });
    }
  }

  ngOnDestroy(): void {
    this.breadcrumbSubscription.unsubscribe();
    this.breadcrumbSubscription = null;
  }

  public toggleAlertsSidenav(): void {
    this.alertsSidenavToggle$.next();
  }

  public success(msg: string, dismissOnTimeout?: number, noSnackbarAlert?: boolean): void {
    console.info('Alert (success): ' + msg);
    this.addAlert({
      alertClass: 'alert-color-green',
      dismissOnTimeout,
      noSnackbarAlert,
      icon: 'done',
      msg,
      severity: 4,
      type: 'success',
    });
  }

  public info(
    msg: string,
    dismissOnTimeout: number = 3000,
    noSnackbarAlert?: boolean,
    href?: string,
    linkDesc?: string
  ): void {
    console.info('Alert (info): ' + msg);
    this.addAlert({
      alertClass: 'alert-color-blue',
      dismissOnTimeout,
      noSnackbarAlert,
      href,
      linkDesc,
      msg,
      severity: 3,
      type: 'info',
    });
  }

  public warning(msg: string, dismissOnTimeout: number = 3000, noSnackbarAlert?: boolean): void {
    console.warn('Alert (warning): ' + msg);
    this.addAlert({
      alertClass: 'alert-color-yellow',
      dismissOnTimeout,
      noSnackbarAlert,
      msg,
      severity: 2,
      type: 'warning',
    });
  }

  public danger(msg: string, dismissOnTimeout: number = 3000, noSnackbarAlert?: boolean): void {
    console.error('Alert (danger): ' + msg);
    this.addAlert({
      alertClass: 'alert-color-red',
      dismissOnTimeout,
      noSnackbarAlert,
      icon: 'error',
      msg,
      severity: 1,
      type: 'danger',
    });
  }

  public addAlert(alert: cad.Alert): void {
    _.defaults(alert, {
      date: new Date(),
      dismissOnTimeout: this.dismissOnTimeoutDefault,
      icon: alert.type,
      // id: this.$state.params, // TODO(ng2)
      screen: this.getBreadcrumbLabel(),
      url: this.router.url
    });

    this.alertsStore.unshift(alert);
    this.alerts$.next(_.clone(this.alertsStore));

    // Only show snack bar alerts if noSnackbarAlert is true
    if (_.isNumber(alert.dismissOnTimeout) && !alert.noSnackbarAlert) {
      this.snackbarAlertsStore.unshift(alert);
      this.snackbarAlerts$.next(_.clone(this.snackbarAlertsStore));
      setTimeout(() => {
        this.dismissSnackbarAlert(alert);
      }, alert.dismissOnTimeout);
    }
    // Force change detection & full render of the DOM -- when there are simultaneous in-flight HTTP requests,
    // Angular can fail to render after the response
    this.ngZone.run(() => {});
  }

  public dismissAlert(alert: cad.Alert): void {
    const beforePull = this.alertsStore.length;
    _.pull(this.alertsStore, alert);
    if (this.alertsStore.length !== beforePull) {
      this.alerts$.next(_.clone(this.alertsStore));
    }
    // Also dismiss it for the snack bar/toast
    this.dismissSnackbarAlert(alert);
  }

  public dismissSnackbarAlert(alert: cad.Alert): void {
    const beforePull = this.snackbarAlertsStore.length;
    _.pull(this.snackbarAlertsStore, alert);
    if (this.snackbarAlertsStore.length !== beforePull) {
      this.snackbarAlerts$.next(_.clone(this.snackbarAlertsStore));
    }
    // Force change detection & full render of the DOM -- when there are simultaneous in-flight HTTP requests,
    // Angular can fail to render after the response
    this.ngZone.run(() => {});
  }

  public dismissAllAlerts(): void {
    if (_.isArray(this.alertsStore)) {
      this.alertsStore.splice(0);
      this.alerts$.next(_.clone(this.alertsStore));
    }
    if (_.isArray(this.snackbarAlertsStore)) {
      this.snackbarAlertsStore.splice(0);
      this.snackbarAlerts$.next(_.clone(this.snackbarAlertsStore));
    }
    // Force change detection & full render of the DOM -- when there are simultaneous in-flight HTTP requests,
    // Angular can fail to render after the response
    this.ngZone.run(() => {});
  }

  public getBreadcrumbLabel(): string {
    if (_.isArray(this.breadcrumbs) && this.breadcrumbs.length > 0) {
      return _.last(this.breadcrumbs).label;
    }
    return null;
  }

  public getErrorMessage(msg: any): string {
    let message = msg.message;
    if (msg.params && msg.params[0] && msg.params[0].msg) {
      message = `${message} -- because of -- ${this.getErrorMessage(msg.params[0])}`;
    }
    return message;
  }



  public showResultAlerts(resultObj: any, successMsg?: string, showWarningSnackbar: boolean = true, showInfoSnackbar: boolean = false): void {
    if (!_.isNil(resultObj)) {
      if (!_.isEmpty(resultObj.infoMessages)) {
        resultObj.infoMessages.forEach((msg: any) => this.info(msg.message, showInfoSnackbar ? 3000 : null, !showInfoSnackbar));
      }
      if (resultObj.successful && successMsg) {
        this.success(successMsg);
      }
      if (!_.isEmpty(resultObj.warningMessages)) {
        resultObj.warningMessages.forEach((msg: any) => this.warning(msg.message, 3000, !showWarningSnackbar));
      }
      if (!_.isEmpty(resultObj.errorMessages)) {
        resultObj.errorMessages.forEach((msg: any) => this.danger(this.getErrorMessage(msg)));
      }
    }
  }

  public showCombinedResultAlerts(resultObj: any, headerMsg: string = 'Result'): void {
    let msg: string = '';
    let hasInfoMessages: boolean;
    let hasWarningMessages: boolean;
    let hasErrorMessages: boolean;

    if (!_.isNil(resultObj)) {
      hasInfoMessages = _.isArray(resultObj.infoMessages) && !_.isEmpty(resultObj.infoMessages);
      hasWarningMessages = _.isArray(resultObj.warningMessages) && !_.isEmpty(resultObj.warningMessages);
      hasErrorMessages = _.isArray(resultObj.errorMessages) && !_.isEmpty(resultObj.errorMessages);
      if (hasInfoMessages) {
        msg += `<p>Info:</p><ul>`;
        resultObj.infoMessages.forEach((messageData: any) => msg += `<li>${messageData.message}</li>`);
        msg += `</ul>`;
      }
      if (hasWarningMessages) {
        msg += `<p>Warnings:</p><ul>`;
        resultObj.warningMessages.forEach((messageData: any) => msg += `<li>${messageData.message}</li>`);
        msg += `</ul>`;
      }
      if (hasErrorMessages) {
        msg += `<p>Errors:</p><ul>`;
        resultObj.errorMessages.forEach((messageData: any) => msg += `<li>${messageData.message}</li>`);
        msg += `</ul>`;
      }
      msg = (!_.isEmpty(msg) ? `${headerMsg}, message(s):` : headerMsg) + msg;
      if (resultObj.successful) {
        this.success(msg);
      } else {
        this.danger(msg);
      }
    }
  }
}
