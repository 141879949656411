<section fxLayout="row"
         fxFlex="auto"
         fxLayoutAlign="start center"
         class="breadcrumbs">
  <button mat-button class="home" (click)="goHome()"><mat-icon>home</mat-icon></button>
  <ol>
    <li *ngFor="let breadcrumb of breadcrumbs">
      <span [ngSwitch]="!!breadcrumb.link">
        <button mat-button *ngSwitchCase='true' [routerLink]="breadcrumb.link" [routerLinkActive]="'active'">{{breadcrumb.label}}</button>
        <div *ngSwitchCase='false'>{{breadcrumb.label}}</div>
      </span>
    </li>
  </ol>
</section>
