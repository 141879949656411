
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import {ItemApi} from 'src/features/common/item/item-api';

@Injectable()
export class CashoutAdjustmentsApi extends ItemApi {

  public endpoint: string = 'flowing-gas/cashout/adj';

  constructor( private apiHelper: ApiHelper ) {
    super();
  }

  // ========================================
  // implementation
  // ========================================

  public filter (params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public byQtyId (id: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + `/byQtyId/${ id }`);
  }

  public save (cashoutAdj: any): Observable<any> {
    return this.apiHelper.request(this.endpoint, { method: 'POST', body: cashoutAdj });
  }

  public find(params: any): Observable<any> {
    return this.byQtyId(params.id);
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/dashboard');
  }

  public deleteItem(item: any): Observable<any> {
    return observableOf('Not implemented');
  }

  public getCurrentAccountingPeriod(): Observable<any> {
    return this.apiHelper.request('settlement/settlement/current-accounting-period');
  }

}
