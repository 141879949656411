import { Injectable } from '@angular/core';
import { AddressApi } from 'cad/common/services/api/associate/address/address';
import { BusinessAssociateApi } from 'cad/common/services/api/associate/business-associate/business-associate';
import { AssociateContactApi } from 'cad/common/services/api/associate/contact/contact';
import { ServiceRequesterApi } from 'cad/common/services/api/associate/service-requester/service-requester';
import { BusinessAssociateCombineApi } from 'src/cad/common/services/api/associate/business-associate-combine/business-associate-combine-api';

@Injectable()
export class AssociateApi {
  constructor(
    public address: AddressApi,
    public businessAssociate: BusinessAssociateApi,
    public contact: AssociateContactApi,
    public serviceRequester: ServiceRequesterApi,
    public baCombine: BusinessAssociateCombineApi
  ) {}
}
