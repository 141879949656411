import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'yesno' })
export class YesNoPipe implements PipeTransform {
  transform(value: any, args: string[]): any {
    if (value == undefined) { return ''; }

    /* tslint:disable:no-param-reassign */
    if (typeof(value) === 'boolean') { value = value.toString(); }
    /* tslint:enable:no-param-reassign */

    let yesValues = [ 'yes' , 'y' , 'true' , 't' ];
    let noValues = [ 'no', 'n' , 'false' , 'f' ];

    if (_.includes(yesValues, value.toLocaleLowerCase())) { return 'Yes'; }
    if (_.includes(noValues, value.toLocaleLowerCase())) { return 'No'; }
    return '';
  }
}

