<h1 matDialogTitle>LOGIN</h1>
<form (ngSubmit)="loginSubmit()" #loginForm="ngForm" fxLayout="column">
  <mat-dialog-content fxLayout="column">
    <div class="alert" *ngFor="let alert of alerts">{{alert.msg}}</div>

    <mat-form-field>
      <input
        matInput
        id="login-username"
        name="username"
        placeholder="User Name"
        #usernameModel="ngModel"
        [(ngModel)]="username"
        [disabled]="busy"
        required
      />
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        id="login-password"
        type="password"
        name="password"
        placeholder="Password"
        #passwordModel="ngModel"
        [disabled]="busy"
        [(ngModel)]="password"
        required
      />
    </mat-form-field>

  </mat-dialog-content>

  <mat-dialog-actions fxLayoutGap="10px" fxLayoutAlign="end">
    <button type="button" mat-raised-button (click)="cancel()" [disabled]="busy">Cancel</button>
    <button type="submit" mat-raised-button color="primary" [disabled]="!loginForm.form.valid || busy">Login</button>
  </mat-dialog-actions>

</form>

<div *ngIf="!isReleaseBuild" class="test-alert">Your password for accessing the Messenger+ test environment may be different from your Production system password.</div>
<div class="test-alert">For login assistance or to request access, please contact Customer Support.</div>
