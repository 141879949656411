import { OnInit, Injector, Component } from '@angular/core';
import { RowNode } from '@ag-grid-community/core';
import { ModalController } from 'src/features/common/modal/modal.controller';
import { PinnedSearchCacheService } from 'src/common/services/search/pinned-search-cache.service';
import { ModalControllerFactoryService } from 'cad/core/services/modal-registry/modal-controller-factory.service';

export const getDefaultSearch = (): any => {
  return {
    flowBeginDate: dateUtil().getToday(),
    flowEndDate: dateUtil().getToday(),
  };
};

@Component({ template: '' })
export abstract class Recent implements OnInit {
  modalController: ModalController;
  modalControllerFactory: ModalControllerFactoryService;
  pinnedSearchCacheService: PinnedSearchCacheService;
  isScheduleQuantityForOperator: boolean;
  locations: any;
  search: any;

  protected constructor(injector: Injector) {
    this.pinnedSearchCacheService = injector.get(PinnedSearchCacheService);
    this.modalControllerFactory = injector.get(ModalControllerFactoryService);

    if(!(this.pinnedSearchCacheService && this.modalControllerFactory)) {
      throw Error;
    }

  }

  abstract findItem(): void;
  abstract getDefaultSearch(): any;

  ngOnInit(): void {
    this.search = this.getDefaultSearch();
    this.locations = this.pinnedSearchCacheService.getCachedKeys();
    this.modalController = this.modalControllerFactory.getConfirmationPointLookupModalController();
  }


  navigate(url: string): void {
    window.location.href += `/`+encodeURIComponent(url);
  }

  onRemoveItems(node: RowNode): void {
    this.pinnedSearchCacheService.deleteCachedItem(node.data.pointCd);
    this.locations = this.pinnedSearchCacheService.getCachedKeys();
  }

  onSelectRow(rows: RowNode[]): void {
    const { pointCd } = rows[0].data;
    this.pinnedSearchCacheService.setCached({...this.search, pointCd});
    this.navigate(pointCd);
  }

  onFindItem(resp: any): void {
    this.pinnedSearchCacheService.setCached({...this.search, ...resp});
    this.navigate(resp.pointCd);
  }
}
