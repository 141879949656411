import { Injector, Type } from '@angular/core';
import { Route } from '@angular/router';

import { FramingNgModule } from './framing-ng-module';
import { FeatureData } from './feature-data';
import { ControllerFactoryComponent } from './controller-factory.component';
import { Controller } from './controller';
import { RoutePath } from './types';

import * as _ from 'lodash';

/**
 */
export abstract class Feature<Model, View> {
  static featureCount: number = 1;

  // ========================================
  // public properties
  // ========================================

  /**
   * The name of this feature.
   */
  public abstract get featureName(): string;

  /**
   * The default model.
   */
  public get defaultModel(): Model { return undefined; }

  /**
   * The default view.
   */
  public get defaultView(): View { return undefined; }

  /**
   * The default controller.
   */
  public get defaultController(): Type<Controller<Model, View>> { return undefined; }

  /**
   * When true, framing will add the model to the route data.
   */
  public get addModelToRouteData(): boolean { return false; }

  /**
   * When true, framing will add the view to the route data.
   */
  public get addViewToRouteData(): boolean { return false; }

  /**
   * Model accessor.
   */
  public get theModel(): Model { return this._model; }

  /**
   * View accessor.
   */
  public get theView(): View { return this._view; }

  /**
   * Controller accessor.
   */
  public get theController(): Type<Controller<Model, View>> { return this._controller; }

  /**
   * True if framing() has been called.
   */
  public get framed(): boolean { return this._built; }

  /**
   * 'require': framing will attach the default route, creating it if it doesn't yet exist, if not route is attached to this feature (default behavior)
   * 'auto': framing will attach the default route if available if not route is attached to this feature
   * 'none': framing will not attach a route to this feature
   */
  public get routeRule(): ('auto' | 'none') { return 'auto'; }

  /**
   * The feature's route, if attached to a route, otherwise undefined
   * Valid ONLY during the buildFeature() and framing() functions
   */
  public get route(): Route { return this._route; }

  /**
   * 
   */
  public routePath: RoutePath;

  // ========================================
  // private properties
  // ========================================

  /**
   * True if framing() has been called.
   */
  private _built: boolean = false;

  /**
   * The model.
   */
  private _model: Model;

  /**
   * The view.
   */
  private _view: View;

  /**
   * The controller.
   */
  private _controller: Type<Controller<Model, View>>;

  /**
   * The feature's route, if attached to a route, otherwise undefined
   * Valid ONLY during the buildFeature() and framing() functions
   */
  private _route: Route;

  // ========================================
  // public methods
  // ========================================

  /**
   * Model chaining function.
   */
  public model(model?: Model): Feature<Model, View> {
    _.merge(this._model, model);
    return this;
  }

  /**
   * View chaining function.
   */
  public view(view?: View): Feature<Model, View> {
    _.merge(this._view, view);
    return this;
  }

  /**
   * Controller chaining function.
   */
  public controller(controller?: Type<Controller<Model, View>>): Feature<Model, View> {
    if (controller) { this._controller = controller; }
    return this;
  }

  /**
   * The build function.
   */
  public abstract build(framing: FramingNgModule): void;

  /**
   * Calls derived frame()
   */
  public buildFeature(framing: FramingNgModule): void {
    if (this._built) {
      console.warn(`buildFeature() called multiple times on feature '${this.featureName}'`);
      return;
    }

    // console.log(`Building ${this.featureName} feature at route path ${this.routePathString(this.routePath)}`);

    this._built = true; // mark this feature to framed

    if (this.routeRule === 'auto') {
      this._route = framing.getOrAddRoute(this.routePath);
    }

    try {
      this.build(framing);

      if (this._controller) {
        if (!this._route) {
          console.error(`Controller for feature ${this.featureName} cannot be configured with a route`);
        } else {
          if (!this._route.component) {
            this._route.component = ControllerFactoryComponent;
          }

          if (this._route.component !== ControllerFactoryComponent) {
            console.error(`Controller for feature ${this.featureName} cannot be configured with existing route component`, this._route);
          } else {
            const featureId: number = Feature.featureCount++;
            const featureData: FeatureData<Model, View> = {
              name: this.featureName,
              controller: this._controller,
              model: this._model,
              view: this._view,
              activated: false,
              created: false,
              failed: false,
            };
            // console.log(`Adding ${this.featureName} feature to route data (feature${featureId})`);
            this.addRouteData(framing, `feature${featureId}`, featureData);
          }
        }
      }

      if (this.addModelToRouteData) {
        // console.log(`Adding ${this.featureName} model to route data`);
        this.addRouteData(framing, this.featureName + 'Model', this._model);
      }
      if (this.addViewToRouteData) {
        // console.log(`Adding ${this.featureName} view to route data`);
        this.addRouteData(framing, this.featureName + 'View', this._view);
      }
    } catch (e) {
      console.error(`Exception when framing ${this.featureName} :`, e);
    }

    this._route = undefined; // clear the route so we're not holding any references to its properties

    // console.log(`Done framing ${this.featureName} feature`);
  }

  // ========================================
  // constructor()
  // ========================================

  /**
   * Contructor.
   */
  public constructor(model?: Model, view?: View, controller?: Type<Controller<Model, View>>) {
    this.construct(model, view, controller);
  }

  /**
   * Protected construct function for derived construction help.
   */
  protected construct(model?: Model, view?: View, controller?: Type<Controller<Model, View>>): void {
    const defaultModel: Model = this.defaultModel;
    this._model = defaultModel ? _.merge(defaultModel, model) : model;
    const defaultView: View = this.defaultView;
    this._view = defaultView ? _.merge(defaultView, view) : view;
    this._controller = controller || this.defaultController;
  }

  /**
   */
  private addRouteData(framing: FramingNgModule, name: string, value: any): void {
    if (value) {
      if (!this._route) {
        console.warn(`Failed to add ${name} route data for feature ${this.featureName}. No route.`);
      } else if (this._route.data && this._route.data[name]) {
        console.warn(`Failed to add ${name} route data for feature ${this.featureName}. Data item already exists.`);
      } else {
        framing.datum(name, value, this.routePath);
      }
    }
  }

  /**
   */
  private routePathString(routePath: RoutePath): string {
    if (!routePath) {
      return '""';
    } else if (_.isArray(routePath)) {
      return '"' + _.map(routePath, (route) => route.path).join('/') + '"';
    } else {
      return '"' + routePath.path + '"';
    }
  }
}
