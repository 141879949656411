import { Injectable, Injector } from '@angular/core';
import { PinnedItemApi } from 'src/cad/common/services/api/nomination/pinned-item/pinned-item';

@Injectable()
export class ContractLocationQtyPinnedContractApi extends PinnedItemApi {
  constructor(injector: Injector) {
    super(injector);
    this.endpoint = 'operProducer/contract-location-quantities/matrix/pinned-contracts';
  }
}
