<mat-sidenav-container >
  <mat-sidenav #sidenavMenu
               [ngClass]="(sidenavMenu?.opened) ? 'mat-sidenav-opened' : 'mat-sidenav-closed'"
               (closed)="setMargin()"
               mode="side"
               opened="true">
    <ui-sidenav (onToggle)="sidenavMenu.toggle()" [sections]="sections"></ui-sidenav>
  </mat-sidenav>

  <div fxFlex fxLayout="column" id="section-content" (scroll)="onScrollFn($event)" >
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>
