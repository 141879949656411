import { Inject, Injectable } from '@angular/core';
import { AlertsService } from 'cad/core/services/alerts/alerts.service';
import { UiFormComponent } from 'src/common/components/form/form.component';


@Injectable()
export class SaveFormService {
  constructor(
    private alerts: AlertsService,
  ) {}

  public success = (uiForm: UiFormComponent): () => void => {
    return () => {
      uiForm.markPristine();
      this.alerts.success('All changes saved.');
    };
  }

  public fail = (errors: string[] | string): () => void => {
    return () => {
      if (_.isArray(errors)) {
        _.each(_.flattenDeep(errors), (error: string) => {
          this.alerts.danger(error);
        });
      } else {
        this.alerts.danger(errors.toString());
      }
    };
  }
}
