import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from 'src/cad/app-material.module';

import { RowActionMenuComponent } from './action-menu/action-menu.component';
import { RowConfirmActionComponent } from './confirm-action/confirm-action.component';
import { DetailRowComponent } from './detail-row/detail-row.component';
import { RowMultipleSelectComponent } from './multiple-select/multiple-select.component';
import { RowShowDeleteComponent } from './row-delete/row-delete.component';
import { RowShowExpandComponent } from './row-expand/row-expand.component';
import { RowNavigationComponent } from './row-navigation/row-navigation.component';
import { RowValidationService } from './row-validation.service';
import { RowVisualValidationComponent } from './validation-icon/validation-icon.component';
import { RowMessageIconComponent } from './message-icon/message-icon.component';
import { ActionMenuItemDisabledPipe } from 'src/ag-grid-wrapper/row/action-menu/action-menu-item-disabled.pipe';
import { PipesModule } from 'src/cad/common/filters/pipes.module';

const subModules = [
  RowMultipleSelectComponent,
  RowActionMenuComponent,
  RowNavigationComponent,
  RowShowDeleteComponent,
  RowConfirmActionComponent,
  RowVisualValidationComponent,
  RowMessageIconComponent,
  RowShowExpandComponent,
  DetailRowComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AppMaterialModule,
    FlexLayoutModule,
    PipesModule
  ],
  exports: [ subModules ],
  declarations: [ subModules,   ActionMenuItemDisabledPipe ],
  entryComponents: [ subModules ],
  providers: [
    RowValidationService,
    // TableRowFooterAggregationFactory,
  ]
})
export class RowModule {
}
