import { Directive, OnInit } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { TableColumnComponent } from 'src/ag-grid-wrapper/column/column.component';
import * as _ from 'lodash';

/**
 * Example Usage:
 *
 *    ```<ui-table-column
 *        name="invoiceTypeCd"
 *        display-name="Invoice Type"
 *        etDropdownRenderer
 *        [editDropdownOptionsArray]="array"
 *        editDropdownValueId="cd"
          editDropdownValueLabel="value">
 *      </ui-table-column>```
 *
 * array should have codes and label values array = [ {cd: 123, value: 'string'} ];
 *
 * The above example will render an etDropdown value based on the code value in the array.
 **/

@Directive({
  selector: 'ui-table-column[etDropdownRenderer]',
})
export class ETDropdownRendererDirective implements OnInit {
  private dropdownArray: any[];
  private dropdownCode: string;
  private dropdownLabel: string;
  constructor(public tableColumn: TableColumnComponent) {}

  ngOnInit(): void {
    this.dropdownArray = this.tableColumn.editDropdownOptionsArray;
    this.dropdownCode = this.tableColumn.editDropdownValueId;
    this.dropdownLabel = this.tableColumn.editDropdownValueLabel;
    this.tableColumn.valueFormatter = (params: ICellRendererParams): string => {
      let val: any;
      let codeMatch: any;

      if (params && params.data) {
        val = _.get(params.data, params.colDef.field);
      } else if (params && !_.isNil(params.value)) {
        val = params.value;
      }
      codeMatch = _.find(this.dropdownArray, { [this.dropdownCode]: val } );
      if (codeMatch) {
        return codeMatch[this.dropdownLabel];
      }
      return val;
    };
  }
}
