import { Component, Type, ComponentRef, OnDestroy, OnInit, Renderer2, Injector, ValueProvider } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeatureData } from './feature-data';
import { Controller } from './controller';

@Component({
  selector: 'controller-factory',
  templateUrl: './controller-factory.component.html'
})
export class ControllerFactoryComponent implements OnDestroy {
  features: FeatureData<any, any>[] = [];
  controllers: Controller<any, any>[] = [];
  factoryInjector: Injector;

  constructor(
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private injector: Injector,
  ) {
    _.forOwn(this.route.snapshot.data, (value, key) => {
      if (key.startsWith('feature')) {
        const feature: FeatureData<any, any> = value as FeatureData<any, any>;
        if (feature.activated) {
          // console.log(`Controller ${feature.name} (${key}) already activated`);
        } else {
          // console.log(`Creating instance of controller ${feature.name} (${key})`);
          this.features.push(feature);
          feature.activated = true;
        }
      }
    });
  }

  ngOnDestroy(): void {
    _.forEach(this.features, (feature) => {
      // console.log(`Destroying instance of controller ${feature.name}`);
      feature.activated = false;
      feature.created = false;
      feature.failed = false;
    });
    this.features = [];
    this.controllers = [];
    this.factoryInjector = undefined;
  }

  onController(ref: ComponentRef<Controller<any, any>>, feature: FeatureData<any, any>): void {
    feature.created = true;
    this.controllers.push(ref.instance);
    // Important to deep clone the model & view before passing it to the controller in initializeController as we don't want to
    // modify and add memory & references to the model & view objects in the route data (these are kept around by angular)
    ref.instance.initializeController(feature.name, _.cloneDeep(feature.model), _.cloneDeep(feature.view));
    this.renderer.addClass(ref.location.nativeElement, `feature-${feature.name}`);
    this.initializeFactoryInjector();
  }

  onError(err: string, feature: FeatureData<any, any>): void {
    feature.failed = true;
    this.initializeFactoryInjector();
  }

  private initializeFactoryInjector(): void {
    let ready = true;
    _.forEach(this.features, (feature) => {
      if (feature.activated && !feature.created && !feature.failed) {
        ready = false;
      }
    });

    if (ready) {
      // setup controller providers (by type and by string for arrays)
      const providers: ValueProvider[] = [];
      const controllersByName: { [name:string]: Controller<any, any>[] } = {};
      _.forEach(this.controllers, (controller) => {
        providers.push(...controller.getFactoryProviders());
        if (!controllersByName[controller.name]) {
          controllersByName[controller.name] = [];
        }
        controllersByName[controller.name].push(controller);
      });
      const summary: { [name:string]: number } = {};
      _.forOwn(controllersByName, (value, key) => {
        providers.push({ provide: `${key}Controllers`, useValue: value });
        summary[key] = value.length;
      });

      // create the factory injector and pass it to all controllers
      // console.log(`Initializing factory injector with ${this.controllers.length} controller(s) provided of ${_.keys(summary).length} type(s)`, summary);
      this.factoryInjector = Injector.create(providers, this.injector);
      _.forEach(this.controllers, (controller) => {
        controller.initializeFactoryInjector(this.factoryInjector);
      });
    }
  }
}
