export const EASING_PATTERN = {
  EASE_IN_QUAD: 'easeInQuad',
  EASE_OUT_QUAD: 'easeOutQuad',
  EASE_IN_OUT_QUAD: 'easeInOutQuad',
  EASE_IN_CUBIC: 'easeInCubic',
  EASE_OUT_CUBIC: 'easeOutCubic',
  EASE_IN_OUT_CUBIC: 'easeInOutCubic',
  EASE_IN_QUART: 'easeInQuart',
  EASE_OUT_QUART: 'easeOutQuart',
  EASE_IN_OUT_QUART: 'easeInOutQuart',
  EASE_IN_QUINT: 'easeInQuint',
  EASE_OUT_QUINT: 'easeOutQuint',
  EASE_IN_OUT_QUINT: 'easeInOutQuint',
};

export type EasingPattern =
  'easeInQuad'
  | 'easeOutQuad'
  | 'easeInOutQuad'
  | 'easeInCubic'
  | 'easeOutCubic'
  | 'easeInOutCubic'
  | 'easeInQuart'
  | 'easeOutQuart'
  | 'easeInOutQuart'
  | 'easeInQuint'
  | 'easeOutQuint'
  | 'easeInOutQuint';

export interface SmoothScrollOptions {
  duration?: number;
  offset?: number;
  easing?: EasingPattern;
  onBeforeScroll?: (element: any) => void;
  onAfterScroll?: (element: any) => void;
}
