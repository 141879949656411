<div class="ui-float-group-{{direction}}"
      [ngClass]="{'ui-hover-full': enableHover}"
      (mouseenter)="mouseEnter()"
      (mouseleave)="mouseExit()">
  <button mat-mini-fab
          type="button"
          disableRipple="true"
          [attr.aria-label]="mainTooltip"
          [disabled]="isDisabled"
          class="mat-mini-fab"
          (click)="clicked()">
    <mat-icon>{{iconName}}</mat-icon>
  </button>
  <div *ngIf="!isDisabled && isOpen" class="action-items-{{direction}}">
    <div *ngFor="let item of actionItems" [matTooltip]="item.tooltip" [matTooltipPosition]="toolTipPosition">
      <button mat-mini-fab
              class="mat-mini-fab sub-fab-{{direction}}"
              type="button"
              disableRipple="true"
              *ngIf="item.enabled && item.func"
              (click)="emitFuncCall(item.func)"
              [attr.ariaLabel]="item.action">
        <mat-icon>{{item.iconName}}</mat-icon>
      </button>
      <button mat-mini-fab
              class="mat-mini-fab sub-fab-{{direction}}"
              type="button"
              disableRipple="true"
              *ngIf="item.enabled && item.click"
              (click)="item.click()"
              [attr.ariaLabel]="item.action">
        <mat-icon>{{item.iconName}}</mat-icon>
      </button>
    </div>
  </div>
</div>
