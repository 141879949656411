import { BaseValidatorService } from 'src/cad/common/services/validation/base-validator.service';
import { IValidator } from './ivalidator';
import { of, Observable, forkJoin } from 'rxjs';
import { ValidationResultModel } from './../validation-result/validation-result-model';
import { catchError, map } from 'rxjs/operators';
import { ɵisObservable as isObservable } from '@angular/core';
import { UiGridApi } from 'src/ag-grid-wrapper';

export class ValidationPipeline {

  constructor(validators: IValidator[], private baseValidatorService: BaseValidatorService) {
    this.validators = validators;
  }

  private validators: IValidator[];

  validate(entities: any[], gridApi?: UiGridApi): Observable<ValidationResultModel[]> {
    const resultObjects = entities.map((x) => new ValidationResultModel());
    // since the validator can be return either an Observable<ValidationResultModel[]> or ValidationResultModel[] we need to
    // standardize the array for passing into forkJoin
    const observables: Observable<ValidationResultModel>[] = [];
    for (let index = 0; index < entities.length; index++) {
      const tempArray = this.validators.map((v) => {
        if (typeof v.properties === 'string') {
          const z = v.validate(v.properties, entities[ index ], resultObjects[ index ], '', gridApi);
          return [ isObservable(z) ? z : of(z) ];
        } else {
          const arrayOfObservablesForEachProperty: Observable<ValidationResultModel>[] = [];
          v.properties.forEach((prop) => {
            const z = v.validate(prop, entities[ index ], resultObjects[ index ]);
            arrayOfObservablesForEachProperty.push(isObservable(z) ? z : of(z));
          });
          return arrayOfObservablesForEachProperty;
        }
      });
      tempArray.forEach((arrayOfObservableArrays) => arrayOfObservableArrays.forEach((propertyObservables) => observables.push(propertyObservables)));
    }

    return forkJoin(observables.length > 0 ? observables : [ of(true) ])
      .pipe(
        map((foo) => {
          return resultObjects;
        }, catchError(() => {
          return of(resultObjects);
        }))
      );
  }

}
