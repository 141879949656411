import { Component, Injector, OnInit } from '@angular/core';
import { ButtonContentComponent } from './button-content.component';

@Component({
  selector: 'ui-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent extends ButtonContentComponent implements OnInit {

  constructor (injector: Injector) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }
}
