<ui-modal-header>
  <h2>{{resolvedData.title}}</h2>
</ui-modal-header>
<ui-modal-body>
  <p class="message"> {{resolvedData.message}} </p>
</ui-modal-body>
<ui-modal-footer>
  <span>
    <button mat-button mat-raised-button color="primary" matDialogClose>{{resolvedData.btnText}}</button>
  </span>
</ui-modal-footer>