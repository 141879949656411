import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductServiceApi } from './product-service';
import { ServiceTemplateData } from 'src/cad/structured-products/shared/interfaces/service-template-data';
import { AgreementInputData } from 'src/cad/contracts/shared/components/dynamic-input-tables/types/dynamic-input-tables-types';

@Injectable()
export class ProductServiceInputsApi {

  constructor(
    private productServiceApi: ProductServiceApi
  ) {}
  
  public save(saveData: ServiceTemplateData): Observable<any> {
    return this.productServiceApi.saveInputs(saveData).pipe(map((result) => {
      if (result && result.validatedObject && result.validatedObject.agreementInputData) {
        result.validatedObject = result.validatedObject.agreementInputData;
      }
      return result;
    }));
  }
  
  public getInputs(filterData: ServiceTemplateData): Observable<ServiceTemplateData | AgreementInputData> {
    return this.productServiceApi.findInputs(filterData).pipe(map((result: ServiceTemplateData) => {
      return (result && result.agreementInputData)
        ? result.agreementInputData
        : result;
    }));
  }
}
