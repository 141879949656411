
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { LookupApi } from 'src/features/common/modal/modal-feature-types';
import * as _ from 'lodash';

@Injectable()
export class AgreementTransactionLookupsApi implements LookupApi {

  private readonly endpoint: string = 'agreement/transaction';

  constructor(public apiHelper: ApiHelper) {}

  public filter(params: any): Observable<any> {
    let returnVal: Observable<any>;
    if (params) {
      let paramsObj: any = _.omit(params, 'populator');
      let lookupActionName: string = _.get(params, 'populator') === 'Point'
        ? 'pointLookupData'
        : _.get(params, 'populator');

      switch (lookupActionName) {
        case 'PointPRK':
        case 'pointLookupData':
        case 'PointGroup': {
          returnVal = this.getPointLookupData(lookupActionName, paramsObj);
          break;
        }
        case 'transactionContracts': {
          returnVal = this.getTransactionContracts(paramsObj);
          break;
        }
        case 'internalContactLookup': {
          returnVal = this.getInternalContacts(paramsObj);
          break;
        }
        case 'externalContactLookup': {
          returnVal = this.getExternalContacts(paramsObj.transactionId, paramsObj.contractId, paramsObj);
          break;
        }
        case 'transactionLookup': {
          returnVal = this.getTransactionLookupData(paramsObj);
          break;
        }
        case 'serviceLookup': {
          returnVal = this.getServiceLookupData(paramsObj);
          break;
        }
        default: {
          break;
        }
      }
    }
    return !_.isNil(returnVal) ? returnVal : observableOf([]);
  }

  public getPointLookupData = (lookupType: string, params: any): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/point-lookup/${ lookupType }`, { body: params });
  }

  public getTransactionContracts = (params: any): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/transaction-contracts`, { body: params });
  }

  public getInternalContacts = (params: any): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/internal-contacts`, { body: params });
  }

  public getExternalContacts = (
    transactionId: number | string,
    contractId: number | string,
    params: any
  ): Observable<any> => {
    let transactionContractId: number | string = !_.isNil(contractId) ? contractId : -1;
    let transactionIdValue: number | string = !_.isNil(transactionId) ? transactionId : -1;

    return this.apiHelper.request(
      `${ this.endpoint }/${ transactionIdValue }/external-contacts/${ transactionContractId }`,
      { body: params }
    );
  }

  public getTransactionLookupData = (params: any): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/transaction-lookup`, { body: params });
  }

  public getServiceLookupData = (params: any): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/service-lookup`, { body: params });
  }
}
