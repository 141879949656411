import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { HttpResponseTypeEnum } from 'cad/common/services/api/api-enums';
import { DocumentFileTypeEnum } from 'src/cad/dashboard/enums/dashboard-enums';
import { DocumentData } from 'src/cad/dashboard/interfaces/document-data';
import {map} from 'rxjs/operators';
import {isArray} from 'util';

@Injectable()
export class DocumentsApi {
  
  private readonly endpoint: string = 'bedrock/documents';

  constructor(
    private apiHelper: ApiHelper
  ) {}
  
  public getById(documentId: number | string, fileType: DocumentFileTypeEnum): Observable<Blob> {
    return this.apiHelper.request(`${this.endpoint}/${fileType}/${documentId}`, { responseType: HttpResponseTypeEnum.BLOB });
  }

  public filter(filterData: DocumentData): Observable<DocumentData[]> {
    return this.apiHelper.request(`${ this.endpoint }/filter`, { body: filterData }).pipe(map((result) => {
      if(!isArray(result)) {
        console.error('Expected DocumentData[], got: ' + JSON.stringify(result));
        result = [];
      }
      return result;
    }));
  }
}
