
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { LookupApi } from 'src/features/common/modal/modal-feature-types';
import { CallLogContact } from 'src/cad/administration/call-log/shared/interfaces/call-log-contact';
import { CallLogCustomer } from 'src/cad/administration/call-log/shared/interfaces/call-log-customer';
import { isNil, omit, get } from 'lodash';

@Injectable()
export class CallLogLookupsApi implements LookupApi {
  
  private readonly endpoint: string = 'administration/calllog';

  constructor(
    public apiHelper: ApiHelper
  ) {}

  public filter(params: any): Observable<any> {
    let returnVal: Observable<any>;
    if (params) {
      let paramsObj: any = omit(params, 'populator');
      let lookupActionName: string = get(params, 'populator');

      switch (lookupActionName) {
        case 'contactLookup': {
          returnVal = this.getContactLookupData(paramsObj);
          break;
        }
        case 'customerLookup': {
          returnVal = this.getCustomerLookupData(paramsObj);
          break;
        }
        default: {
          break;
        }
      }
    }
    return !isNil(returnVal) ? returnVal : observableOf([]);
  }
  
  public getContactLookupData(filterData: Partial<CallLogContact>): Observable<CallLogContact[]> {
    return this.apiHelper.request(`${ this.endpoint }/look-up-contact`, { body: filterData });
  }
  
  public getCustomerLookupData(filterData: Partial<CallLogCustomer>): Observable<CallLogCustomer[]> {
    return this.apiHelper.request(`${ this.endpoint }/look-up-customer`, { body: filterData });
  }
}
