import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { ConfirmNavigationModalComponent } from './confirm-navigation-modal/confirm-navigation-modal.component';

@Injectable()
export class ConfirmNavigationService {

  public isConfirming: boolean;

  constructor(
    private mdDialog: MatDialog,
  ) {

  }

  public confirmNavigation(confirmationText?: string, widthInput?: string) : Observable<boolean> {
    let dialogWidth = widthInput || '40%';
    const dialogRef: MatDialogRef<ConfirmNavigationModalComponent> = this.mdDialog.open(ConfirmNavigationModalComponent, {
      data: {
        confirmationText
      },
      width: dialogWidth,
    });

    this.isConfirming = true;
    return dialogRef.afterClosed()
    .pipe(
      tap(() => this.isConfirming = false)
    );
  }
}
