<span>
  <button mat-button
          matTooltip="Search"
          aria-label="Open search menu"
          (click)="controller.toggleSearchState()"
          [disabled]="!view.search"
          [ngClass]="{'is-active': controller.model.isSearching}">
    <mat-icon>search</mat-icon>
  </button>
  <mat-menu [ngClass]="{'mat-open': controller.model.isSearching}">
  </mat-menu>
</span>

