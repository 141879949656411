import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UIControlValueAccessor } from '../control-value-accessor';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

// Allow this component access to ngModel so that it behaves like any other base component in Angular.
// 1. Extend UIControlValueAccessor
// 2. include provider on your component that has a forwardRef to yourself.
// 3. declare a public variable model - this sucks.
// 4. now you have the ngModel available as the variable "model" to use in your template.

@Component({
  selector: 'ui-form-yesno',
  templateUrl: './yesno.component.html',
  styleUrls: [ './yesno.component.less' ],
  providers: [ { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UiFormYesNoComponent), multi: true } ],
})

export class UiFormYesNoComponent extends UIControlValueAccessor {
  // Label above the component
  @Input() label: string;

  // pass throughs to the underlying component
  @Input() disabled: boolean;

  /**
   * Coerce required into boolean.  This allows the required property
   * to behave like other form controls.  User can add required like this:
   *
   *  <ui-form-yesno required></ui-form-yesno>
   *
   * or the user can use it with a variable like this:
   *
   *  <ui-form-yesno [required]="shouldBeRequired"></ui-form-yesno>
   */
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(val: boolean) {
    this._required = coerceBooleanProperty(val);
  }

  private _required: boolean;
}
