import { Injectable, Injector, OnDestroy } from '@angular/core';
import { UserCacheService } from 'cad/common/store/user/services/user-cache.service';
import { Availableasset, CurrentContext, RootObject } from 'cad/common/store/user/user-model';
import { Observable ,  Subject } from 'rxjs';
import { map, take ,  filter ,  takeUntil } from 'rxjs/operators';
import { UserProfile } from 'cad/common/models/user/user-profile';
import { ProfileService } from 'cad/common/models/user/profile-service';
import { SecurityUserApi } from 'cad/common/services/api/security/user/user';
import { UserStoreService } from 'cad/common/store/core/services/user-store.service';
import { StoreState} from 'cad/common/store/core/store-state';
import { CacheService } from 'ng2-cache';
import { CacheStoragesEnum } from 'ng2-cache/src/enums/cache-storages.enum';

@Injectable()
export class UserService2 implements OnDestroy {
  private unSub: Subject<void> = new Subject<void>();
  private userState: StoreState = undefined;
  private securityUserApi: SecurityUserApi;
  private userCache: UserCacheService;
  private cacheService: CacheService;
  private userStateStore: UserStoreService;
  private profileService: ProfileService;

  constructor(private injector: Injector) {
    this.userCache = this.injector.get(UserCacheService);
    this.cacheService = this.injector.get(CacheService);
    this.userStateStore = this.injector.get(UserStoreService);
    this.profileService = this.injector.get(ProfileService);
    this.securityUserApi = this.injector.get(SecurityUserApi);

    this.cacheService = this.cacheService.useStorage(CacheStoragesEnum.LOCAL_STORAGE);
    this.userStateStore.stateChanged
      .pipe(
        takeUntil(this.unSub),
        filter((userState) => userState.stateChanges))
      .subscribe((userState) => {
        this.userState = userState.state;
        if(this.userState) {
          this.userCache.setUserState(userState.state);
        } else {
          this.userCache.removeUserState();
        }

        if(userState.stateChanges.availableUsers) {
          let availableAssets = [];
          if (!_.isEmpty(this.userState.availableUsers)) {
            this.userState.availableUsers.forEach((root) => {
              availableAssets = [ ...availableAssets, ...root.availableassets ];
            });
            this.userStateStore.setAvailableAssets(availableAssets);
          }

          if (this.userState.availableUsers && this.userState.availableUsers.length > 0) {
            let profile = this.retrieveProfile();
            let asset: Availableasset;
            let ba: string;

            try {
              if (profile) {
                asset = profile.getAsset();
                ba = profile.getBa();
              } else if (this.validateCurrentContext(this.userState.availableUsers[0].currentContext)) {
                asset = this.userState.availableUsers[0].availableassets.find((a) => a.number === this.userState.availableUsers[0].currentContext.assetNbr);
                ba = this.userState.availableUsers[0].currentContext.businessAssociateName;
              } else if (this.cacheService.exists('assetName') && this.cacheService.exists('ba')) {
                let assetName = this.cacheService.get('assetName');
                let baName = this.cacheService.get('ba');

                asset = this.userState.availableAssets.find((a) => !!a.name && a.name === assetName);
                if(!assetName || !baName) {
                  throw new Error(`failed to retrieve cached asset: ${assetName} and ba: ${baName}`);
                }
                if (!asset) {
                  asset = UserService2.getDefaultAsset(availableAssets);
                  ba = this.getDefaultBusinessAssociate(asset);
                } else {
                  let baList: string[] = this.getBAList(asset);
                  ba = baList.find((baValue: string) => baValue === baName);
                  if (!ba) {
                    ba = this.getDefaultBusinessAssociate(asset);
                  }
                }
              } else {
                asset = UserService2.getDefaultAsset(availableAssets);
                ba = this.getDefaultBusinessAssociate(asset);
              }
            } catch(e) {
              console.error(e);
              asset = UserService2.getDefaultAsset(availableAssets);
              ba = this.getDefaultBusinessAssociate(asset);
            }

            profile = profile ? profile : new UserProfile(asset, ba, this.userState.availableUsers[0].userName);

            this.setUserContext(asset, ba, profile);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.unSub.next();
    this.unSub.complete();
  }

  private validateCurrentContext(currentContext: CurrentContext): boolean {
    return currentContext &&
      !_.isNil(currentContext.businessAssociateId) &&
      !_.isNil(currentContext.assetNbr) &&
      currentContext.businessAssociateId !== -1 &&
      currentContext.assetNbr !== -1;
  }

  setRunAsUser(loginId: string): void {
    this.userStateStore.setRunAsUser(loginId);
  }

  getUserStoreState(): StoreState {
    return this.userState;
  }

  getAvailableuUsers(): RootObject[] {
    return this.userState ? this.userState.availableUsers : undefined;
  }

  getCurrentContext(): RootObject {
    return this.userState ? this.userState.user : undefined;
  }

  logout(): void {
    this.userStateStore.logout();
    this.userStateStore.clearAvailableAssets();
    this.userStateStore.clearCurrentContext();
  }

  setUserContext(asset: Availableasset, ba: string, profile?: UserProfile): void {
    let newProfile = profile ? profile : new UserProfile(asset, ba, this.userState.availableUsers[0].userName);
    this.setUserContextObs(asset.name, ba, newProfile).pipe(take(1)).subscribe(() => {
      this.cacheService.set('assetName', asset.name);
      this.cacheService.set('ba', ba);
    });
  }

  setUserContextObs(assetName: string, baName: string, newProfile?: UserProfile): Observable<RootObject> {
    return this.securityUserApi.setUserContext(assetName, baName).pipe(
      map((currentUser: RootObject) => {
        this.userStateStore.setCurrentContext(currentUser);
        if (newProfile) {
          this.profileService.storeProfile(currentUser.userName, newProfile);
        }
        if (dateUtil() && dateUtil().assetModel && currentUser && currentUser.currentContext) {
          dateUtil().assetModel.assetGroupCd = currentUser.currentContext.assetGroupCd;
        }
        return currentUser;
      })
    );
  }

  private retrieveProfile(): UserProfile {
    let userId: string = this.userState.availableUsers[0].userName;
    return this.profileService.retrieveProfile(userId);
  }

  private static getDefaultAsset(availableAssets: Availableasset[]): Availableasset {
    return availableAssets[0];
  }

  private getDefaultBusinessAssociate(asset: Availableasset): string {
    let baList = this.getBAList(asset);

    if (_.isEmpty(baList)) {
      console.error('List of Business Associates was empty for asset:', asset);
      return undefined;
    }
    if (!_.isEmpty(baList)) {
      for (let ba of baList) {
        if (ba.toLocaleLowerCase() === asset.name.toLowerCase()) {
          return ba;
        }
      }
    }
    return baList[0];
  }

  private getBAList(asset: Availableasset): string [] {
    let baList = [];
    this.userState.availableUsers.map((rootObject) => {
      let ac = rootObject.availablecontexts[asset.name] ? rootObject.availablecontexts[asset.name] : undefined;
      if (ac) {
        baList = ac;
      }
    });
    return baList;
  }
}
