import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { UserModelService } from 'cad/common/services/user/user-model-service';
import { ApiCache } from 'cad/common/services/api/api-cache';
import { ServiceLookupData } from 'cad/common/services/api/agreement/contract/service/service-lookup-data';

@Injectable()
export class AgreementContractServiceApi {

  private readonly endpoint: string = 'agreement/contract/service/template';

  constructor(
    private apiHelper: ApiHelper,
    private userModelService: UserModelService,
    private apiCache: ApiCache
  ) {}

  public filter = (params?: any): Observable<ServiceLookupData[]> => {
    return this.apiHelper.request(`${ this.endpoint }/filter`, { body: params });
  }

  public getAll = (): Observable<ServiceLookupData[]> => {
    const params = { assetNbr: this.userModelService.getCurrentAssetNbr() };
    const options = { body: params, cache: this.apiCache.forSession };
    return this.apiHelper.request(`${ this.endpoint }/filter`, options);
  }
  
  public filterServiceTemplatesForTransaction(): Observable<ServiceLookupData[]> {
    return this.apiHelper.request(`${ this.endpoint }/getTransactionServices`);
  }
  
  public getAllForTransaction(): Observable<ServiceLookupData[]> {
    const params = { assetNbr: this.userModelService.getCurrentAssetNbr() };
    const options = { body: params, cache: this.apiCache.forSession };
    return this.apiHelper.request(`${ this.endpoint }/getTransactionServices`, options);
  }
}
