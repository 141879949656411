<ui-modal-header (onClose)="closeSheet()">
  {{headline}}
    <button mat-button mat-icon-button class="minimize-button" (click)="bottomSheetService.toggleMinimized()" [ngClass]="{ 'rotated': bottomSheetService.minimized }">
      <mat-icon>expand_less</mat-icon>
    </button>
</ui-modal-header>
<ui-modal-body>
  <div class="bs-container" fxLayout="row">
    <div fxFlex>
      <ng-content></ng-content>
    </div>
  </div>
</ui-modal-body>
