<span fxLayout="row"
      fxLayoutGap="16px">
  <ui-select name="selectedAssetName"
             fxFlex
             class="user-context-asset-dropdown"
             placeholder="SELECT AN ASSET"
             [(ngModel)]="selectedAssetName"
             [floatLabel]="'never'"
             [sourceFn]="availableAssets"
             (change)="selectedAssetChanged($event)"
             valueProperty="number"
             viewValueProperty="name"></ui-select>
  <ui-select name="selectedBAName"
             fxFlex
             class="user-context-ba-dropdown"
             placeholder="SELECT A BUSINESS ASSOCIATE"
             [(ngModel)]="selectedBAName"
             [floatLabel]="'never'"
             [sourceFn]="availableBusinessAssociates"
             (change)="selectedBANameChanged($event)"></ui-select>
</span>
