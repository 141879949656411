
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { LookupApi } from 'src/features/common/modal/modal-feature-types';
import { PhysicalAssetApi } from 'src/cad/common/services/api/physical/asset/asset';
import { TableMaintenanceCodesApi } from 'src/cad/common/services/api/administration/table-maintenance/codes/codes';
import { AssetConfigParamApi } from 'src/cad/common/services/api/administration/table-maintenance/asset-config-param/asset-config-param';
import { ArchiveTableNameApi } from 'src/cad/common/services/api/administration/table-maintenance/archive-table-name/archive-table-name';
import * as _ from 'lodash';

@Injectable()
export class TableMaintenanceLookupsApi implements LookupApi {

  constructor(
    public apiHelper: ApiHelper,
    public physicalAssetApi: PhysicalAssetApi,
    public tableMaintenanceCodesApi: TableMaintenanceCodesApi,
    public assetConfigParamApi: AssetConfigParamApi,
    public archiveTableNameApi: ArchiveTableNameApi
  ) {}

  public filter(params: any): Observable<any> {
    let returnVal: Observable<any>;
    if (params) {
      let paramsObj: any = _.omit(params, 'populator');
      let lookupActionName: string = _.get(params, 'populator');

      switch (lookupActionName) {
        case 'assetLookup': {
          returnVal = this.getAssetLookupData(paramsObj);
          break;
        }
        case 'codeTypeLookup': {
          returnVal = this.getCodeTypeLookupData(paramsObj);
          break;
        }
        case 'processNameLookup': {
          returnVal = this.getProcessNameLookupData(paramsObj);
          break;
        }
        case 'tableNameLookup': {
          returnVal = this.getTableNameLookupData(paramsObj);
          break;
        }
        default: {
          break;
        }
      }
    }
    return !_.isNil(returnVal) ? returnVal : observableOf([]);
  }

  public getAssetLookupData(params: any): Observable<any> {
    return this.physicalAssetApi.findAll().pipe(map((items: any[]) => {
      return _.filter(items, (item: any) => {
        return _.every(params, (paramValue: any, paramKey: string): boolean => {
          let paramValueStr: string = _.toLower(paramValue.toString());

          if (paramValueStr.endsWith('%')) {
            paramValueStr = paramValueStr.slice(0, -1);
          }
          return !_.isNil(item[ paramKey ])
            && (_.toLower(item[ paramKey ].toString()) as string).startsWith(paramValueStr);
        });
      });
    }));
  }

  public getCodeTypeLookupData(params: any): Observable<any> {
    return this.tableMaintenanceCodesApi.getCodeTypes(params);
  }

  public getProcessNameLookupData(params: any): Observable<any> {
    return this.assetConfigParamApi.getProcessNames(params);
  }

  public getTableNameLookupData(params: any): Observable<any> {
    return this.archiveTableNameApi
      ? this.archiveTableNameApi.getDBTableNames(params)
      : observableOf([]);
  }
}
