<div [ngClass]="{'ribbon':!envMessage, 'ribbon2':envMessage}" fxLayout="row">
  <button mat-button class="back" (click)="goBack()" *ngIf="ribbon.model.hasBack"><mat-icon>arrow_back</mat-icon>Back</button>
  <ui-breadcrumb></ui-breadcrumb>
  <div fxFlex><h6>
    <ng-container [framingComponentOutlet]="ribbon.view.content" [framingComponentInjector]="ribbon.injector"></ng-container>
  </h6></div>
  <button mat-button class="mat-warn env-message-button" *ngIf="envMessage">{{envMessage | uppercase}}</button>
  <ui-save-button *ngIf="showSave" [disabled]="controller.model.disableSaveButton" [label]="saveButtonLabel" ></ui-save-button>
  <ng-container [framingComponentOutlet]="ribbon.view.saveButton" [framingComponentInjector]="ribbon.injector"></ng-container>
</div>
