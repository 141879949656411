import { Injectable, Injector } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { CacheFactory } from 'common/services/cache-factory/cache-factory';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import { RootObject } from 'cad/common/store/user/user-model';

@Injectable()
export class PinnedItemApi {
  protected endpoint: string;
  private cache: CacheFactory;
  private userModelService: UserModelService;

  constructor(injector: Injector) {
    this.userModelService = injector.get(UserModelService);
  }

  public filter(key?: any): Observable<any> {
    return of(this.filterSync(key));
  }

  public filterSync(key?: any): any {
    let localEndpoint: string = this.getEndPoint();
    if (!this.cache) {
      this.setCacheStore(localEndpoint);
    }
    return this.cache.get(localEndpoint) || [];
  }

  public getAll(): any {
    const localEndpoint = this.getEndPoint();
    if(!this.cache) {
      this.setCacheStore(localEndpoint);
    }
    return this.cache.get(localEndpoint) || [];
  }

  public add(value: any, key: string): Observable<any> {
    return of(this.addSync(value, key));
  }

  public addSync(value: any, key: string): boolean {
    let localEndpoint: string = this.getEndPoint();
    this.setCacheStore(localEndpoint);
    let data = this.cache.get(localEndpoint) ? this.cache.get(localEndpoint) : [];
    if (_.isArray(data) && value) {
      let index = _.findIndex(data, [ key, value[ key ] ]);
      if (index === -1) {
        data.push(value);
        this.cache.set(localEndpoint, data);
        return true;
      }
    }
    return false;
  }

  public remove(value: any, key: string): Observable<any> {
    return of(this.removeSync(value, key));
  }

  public removeSync(value: any, key: string): any {
    let localEndpoint: string = this.getEndPoint();
    this.setCacheStore(localEndpoint);
    /* tslint:disable:no-param-reassign */
    value = value ? value : {};
    /* tslint:enable:no-param-reassign */
    let data = this.cache.get(localEndpoint) ? this.cache.get(localEndpoint) : [];
    if (_.isArray(data)) {
      let index = _.findIndex(data, [ key, value[ key ] ]);
      if (index > -1) {
        data.splice(index, 1);
      }
      this.cache.set(localEndpoint, data);
    }
    return this.cache.get(localEndpoint);
  }

  private setCacheStore(localEndpoint: string): void {
    if (!this.cache) {
      this.cache = CacheFactory.createCache(localEndpoint, {
        maxAge: Number.MAX_VALUE,
        storageMode: 'localStorage',
      });
    }
  }

  private getEndPoint(): string {
    let localEndpoint;
    let userDetails: RootObject = this.userModelService.getUser();
    if (userDetails &&
      userDetails.internal &&
      userDetails.internal === 'Y') {
      localEndpoint = this.endpoint + '/' +
        userDetails.currentContext.assetNbr + '/' +
        userDetails.userId;
    } else if (userDetails) {
      localEndpoint = this.endpoint + '/' +
        userDetails.currentContext.assetNbr + '/' +
        userDetails.currentContext.businessAssociateId + '/' +
        userDetails.userId;
    }
    return localEndpoint;
  }
}
