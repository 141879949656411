import { ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { Component, Injector, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { Controller } from 'src/framing/controller';
import { fromEvent ,  Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { EmitterService } from 'cad/core/services/emitter/emitter.service';
import { ActionGroup, ActionGroupState, ActionSecurityService } from 'src/cad/common/services/security/action-security.service';
import { RouterService } from 'src/cad/core/services/router/router.service';
import { FeatureComponent } from 'src/framing/feature.component';
import { CadAppModel } from 'src/features/common/cad-app/cad-app.model';
import { CadAppView } from 'src/features/common/cad-app/cad-app.view';
import { CadAppController } from 'src/features/common/cad-app/cad-app.controller';
import { TableRegistryService } from 'src/ag-grid-wrapper/services/table-registry.service';
import { PrintScreenService } from 'src/common/services/print-screen/print-screen.service';
import { TableComponent } from 'src/ag-grid-wrapper/table/table.component';
import { HeaderToolbarActionMenu } from 'src/features/common/cad-app/interfaces/header-toolbar-action-menu';
import { isNil } from 'lodash';

@Component({
  selector: 'ui-action-menu',
  templateUrl: './header-toolbar-action-menu.component.html',
})
export class HeaderToolbarActionMenuComponent extends FeatureComponent<CadAppModel, CadAppView, CadAppController> {

  public onDashboard: boolean = false;
  public actionItems: HeaderToolbarActionMenu[];

  private actionGroup: ActionGroup;
  private subscriptions: Subscription[] = [];

  constructor(public injector: Injector,
              public controller: CadAppController,
              private router: RouterService,
              private renderer2: Renderer2,
              private securityService: ActionSecurityService,
              private emitterService: EmitterService,
              private tableRegistryService: TableRegistryService,
              private printScreenService: PrintScreenService
  ) {
    super(controller, injector);
  }

  public createNew(): void {
    let itemUrl = this.getItemUrl(this.router.routerState.snapshot.root);
    if (itemUrl) {
      this.router.navigateByUrl(itemUrl + '/new');
    }
  }

  public copyItem(): void {
    let itemDetailUrl = this.getItemDetailUrl(this.router.routerState.snapshot.root);
    if (itemDetailUrl) {
      this.router.navigateByUrl(itemDetailUrl + '/copy');
    }
  }

  public deleteItem(): void {
    let itemDetailUrl = this.getItemDetailUrl(this.router.routerState.snapshot.root);
    if (itemDetailUrl) {
      this.router.navigateByUrl(itemDetailUrl + '/delete');
    }
  }

  public printScreen(): void {
    let tableDisplayValues: { [ key: string ]: { element: HTMLElement, display: string } } = {};
    let parentCardHeights: { [ key: string ]: { element: HTMLElement, height: string } } = {};
    let parentCardContentHeights: { [ key: string ]: { element: HTMLElement, height: string } } = {};
    let searchElement: Element = document.querySelector('ui-search-dropdown cad-search');
    
    Object.keys(this.tableRegistryService.tableComponentList).forEach((key: string) => {
      let tableComponent: TableComponent = this.tableRegistryService.getTable(key);
    
      if (tableComponent
        && tableComponent.element
        && tableComponent.element.nativeElement
        && tableComponent.element.nativeElement.clientHeight > 0) {
        if (!tableComponent.noPrint) {
          if (tableComponent.element.nativeElement.closest('.mat-card')) {
            parentCardHeights[ key ] = {
              element: tableComponent.element.nativeElement.closest('.mat-card'),
              height: tableComponent.element.nativeElement.closest('.mat-card').style.height
            };
          }
          if (tableComponent.element.nativeElement.closest('.mat-card-content')) {
            parentCardContentHeights[ key ] = {
              element: tableComponent.element.nativeElement.closest('.mat-card-content'),
              height: tableComponent.element.nativeElement.closest('.mat-card-content').style.height
            };
          }
          tableComponent.element.nativeElement.insertAdjacentHTML(
            'afterend',
            `<div id="cadPrintScreen_${key}">${this.printScreenService.convertGridToHTML(tableComponent.api)}</div>`
          );
        }
        tableDisplayValues[ key ] = {
          element: tableComponent.element.nativeElement,
          display: tableComponent.element.nativeElement.style.display
        };
        tableComponent.element.nativeElement.style.setProperty('display', 'none', 'important');
      }
    });
    Object.keys(parentCardHeights).forEach((key: string) => {
      parentCardHeights[ key ].element.style.setProperty('height', 'auto', 'important');
    });
    Object.keys(parentCardContentHeights).forEach((key: string) => {
      parentCardContentHeights[ key ].element.style.setProperty('height', 'auto', 'important');
    });
    if (searchElement) {
      this.renderer2.setStyle(searchElement, 'position', 'relative', RendererStyleFlags2.Important);
    }
    fromEvent(window, 'afterprint')
    .pipe(take(1))
    .subscribe(() => {
      Object.keys(tableDisplayValues).forEach((key: string) => {
        if (document.querySelector('#cadPrintScreen_' + key)) {
          document.querySelector('#cadPrintScreen_' + key).remove();
        }
        if (searchElement) {
          this.renderer2.removeStyle(searchElement, 'position', RendererStyleFlags2.Important);
        }
        if (tableDisplayValues[ key ] && tableDisplayValues[ key ].element) {
          if (parentCardHeights[ key ] && !isNil(parentCardHeights[ key ].height)) {
            parentCardHeights[ key ].element.style.removeProperty('height');
            this.renderer2.setStyle(parentCardHeights[ key ].element, 'height', parentCardHeights[ key ].height);
          }
          if (parentCardContentHeights[ key ] && !isNil(parentCardContentHeights[ key ].height)) {
            parentCardContentHeights[ key ].element.style.removeProperty('height');
            this.renderer2.setStyle(parentCardContentHeights[ key ].element, 'height', parentCardContentHeights[ key ].height);
          }
          tableDisplayValues[ key ].element.style.removeProperty('display');
          if (!isNil(tableDisplayValues[ key ].display)) {
            this.renderer2.setStyle(tableDisplayValues[ key ].element, 'display', tableDisplayValues[ key ].display);
          }
        }
      });
    });
    window.print();
  }

  public getReports(): void {
    this.router.navigateByUrl('/reports/schedule-and-view-reports');
  }

  public ngOnInit(): void {
    this.actionItems = [
      {
        name: 'New',
        protectionMapName: 'new',
        action: () => this.createNew(),
        type: 'link',
        state: 'details',
        icon: 'add_circle_outline',
        hideOnDash: false,
        hideNoRoute: true,
        enabled: false,
        visible: false,
      },
      {
        name: 'Copy',
        protectionMapName: 'copy',
        action: () => this.copyItem(),
        type: 'link',
        state: 'details',
        icon: 'content_copy',
        hideOnDash: true,
        hideNoRoute: true,
        enabled: false,
        visible: false,
      },
      {
        name: 'Delete',
        protectionMapName: 'delete',
        action: () => this.deleteItem(),
        type: 'link',
        state: 'details',
        icon: 'delete',
        hideOnDash: true,
        hideNoRoute: true,
        enabled: false,
        visible: false,
      },
      {
        name: 'Print',
        protectionMapName: 'print',
        action: () => this.printScreen(),
        type: 'link',
        state: 'details',
        icon: 'group_add',
        hideOnDash: false,
        enabled: false,
        visible: false,
      },
      {
        name: 'Reports',
        protectionMapName: 'print',
        action: () => this.getReports(),
        type: 'link',
        state: 'details',
        icon: 'assignment',
        hideOnDash: false,
        enabled: false,
        visible: false,
      },
    ];

    this.subscriptions.push(this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.onDashboard = (event.url === '/' || event.url === '/dashboard');
        this.menuListener();
      }
    }));

    this.subscriptions.push(this.emitterService.actionContextResolved.subscribe(() => {
      this.menuListener();
    }));
    this.subscriptions.push(this.emitterService.enableDeleteActionItem.subscribe((enable: boolean) => this.enableDisableActionItem('Delete', enable)));
    this.subscriptions.push(this.emitterService.enableNewActionItem.subscribe((enable: boolean) => this.enableDisableActionItem('New', enable)));
    this.subscriptions.push(this.emitterService.enableCopyActionItem.subscribe((enable: boolean) => this.enableDisableActionItem('Copy', enable)));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private updateMenu(): void {
    this.actionGroup = this.securityService.getContextGroup(cad.uiProtection.PROTECTION_GROUP_ACTIONBUTTON);

    if (!this.actionGroup) {
      this.actionGroup = this.securityService.getContextGroup(cad.uiProtection.PROTECTION_GROUP_ACTIONBUTTON_CAMELCASE);
    }
  }

  private updateMenuSecurity(): void {
    this.disableMenu();
    if (this.actionGroup) {
      let all = this.actionGroup.all;
      if (all) {
        this.updateActionItems(all, this.actionItems);
      }

      for (let key in this.actionGroup) {
        if (this.actionGroup.hasOwnProperty(key)) { // tslint: forin
          if (key !== 'all') {
            // override
            let itemArray = _.filter(this.actionItems, _.matches({ protectionMapName: key }));
            // This should only be one item - match from gui to actionItems defined above
            for (let item of itemArray) {
              this.applySecurity(item, this.actionGroup[ key ].visible, this.actionGroup[ key ].enabled);
            }
          }
        }
      }
    }
  }

  private disableMenu(): void {
    let disabledActionGroup = {
      visible: true,
      enabled: false,
    };
    this.updateActionItems(disabledActionGroup, this.actionItems);
  }
  
  public enableDisableActionItem(actionName: string, enable: boolean): void {
    let targetActionItem: HeaderToolbarActionMenu = this.actionItems.find((actionItem) => actionItem.name === actionName);
    if (targetActionItem) {
      targetActionItem.enabled = enable;
    }
  }

  private updateActionItems(actionGroup: ActionGroupState, items: any): void {
    for (let item in items) {
      if (items.hasOwnProperty(item)) { // tslint: forin
        this.applySecurity(items[ item ], actionGroup.visible, actionGroup.enabled);
      }
    }
  }

  private applySecurity(item: any, visible: boolean, enabled: boolean): void {
    item.visible = visible;

    if (item.hideNoRoute && !this.getItemUrl(this.router.routerState.snapshot.root)) {
      item.enabled = false;
    } else if (item.hideOnDash && this.onDashboard) {
      item.enabled = false;
    } else {
      item.enabled = enabled;
    }
  }

  private menuListener(): void {
    // Delay by one cycle to give the SecurityActionService time to determine the new action context
    this.updateMenu();
    this.updateMenuSecurity();
    this.emitterService.actionMenuSecurityUpdated.next();
  }

  private getItemUrl(route: ActivatedRouteSnapshot): any {
    return this.getDataUrl('ItemRoute', route);
  }

  private getItemDetailUrl(route: ActivatedRouteSnapshot): any {
    return this.getDataUrl('ItemDetailRoute', route);
  }

  private getDataUrl(key: string, route: ActivatedRouteSnapshot): any {
    if (route.data && route.data[ key ]) {
      return Controller.buildUrlLink(route);
    }

    if (route.children.length) {
      return this.getDataUrl(key, route.children[ 0 ]);
    } else {
      return null;
    }
  }
}
