import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteModalComponent } from 'cad/core/services/modal/delete-modal/delete-modal.component';

@Injectable()
export class ModalsService {

  constructor(
    private mdDialog: MatDialog,
  ) {

  }

  public showDelete(message: string, title: string) : Observable<boolean> {
    const dialogRef: MatDialogRef<DeleteModalComponent> = this.mdDialog.open(DeleteModalComponent, {
      data: {
        title,
        message
      },
      width: '60%',
    });

    return dialogRef.afterClosed();
  }
}
