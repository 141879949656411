import { NgModule } from '@angular/core';
import { SharedModule } from 'cad/shared/shared.module';
import { CadAppController } from './cad-app.controller';
import { AlertsSidenavComponent } from './view/alerts-sidenav/alerts-sidenav.component';
import { AlertsSnackbarComponent } from './view/alerts-snackbar/alerts-snackbar.component';
import { FooterComponent } from './view/footer/footer.component';
import { HeaderComponent } from './view/header/header.component';
import { HeaderToolbarComponent } from './view/header-toolbar/header-toolbar.component';
import { HeaderToolbarActionMenuComponent } from './view/header-toolbar-action-menu/header-toolbar-action-menu.component';
import { HeaderToolbarLoginMenuComponent } from './view/header-toolbar-login-menu/header-toolbar-login-menu.component';
import { HeaderToolbarMenuComponent } from './view/header-toolbar-menu/header-toolbar-menu.component';
import { HeaderToolbarMenuItemComponent } from './view/header-toolbar-menu-item/header-toolbar-menu-item.component';
import { HeaderToolbarSearchMenuComponent } from './view/header-toolbar-search-menu/header-toolbar-search-menu.component';
import { HeaderToolbarUserContextComponent } from './view/header-toolbar-user-context/header-toolbar-user-context.component';
import { HeaderToolbarProcessStatusComponent } from './view/header-toolbar-process-status/header-toolbar-process-status.component';
import { RibbonComponent } from './view/ribbon/ribbon.component';
import { RibbonBreadcrumbComponent } from './view/ribbon-breadcrumb/ribbon-breadcrumb.component';
import { SearchDropdownComponent } from './view/search-dropdown/search-dropdown.component';
import { NewVersionSnackbarComponent } from 'src/features/common/cad-app/view/new-version-snackbar/new-version-snackbar.component';

@NgModule({
  imports: [ SharedModule ],
  declarations: [
    AlertsSidenavComponent,
    AlertsSnackbarComponent,
    CadAppController,
    FooterComponent,
    HeaderComponent,
    HeaderToolbarActionMenuComponent,
    HeaderToolbarComponent,
    HeaderToolbarLoginMenuComponent,
    HeaderToolbarMenuComponent,
    HeaderToolbarMenuItemComponent,
    HeaderToolbarProcessStatusComponent,
    HeaderToolbarSearchMenuComponent,
    HeaderToolbarUserContextComponent,
    NewVersionSnackbarComponent,
    RibbonBreadcrumbComponent,
    RibbonComponent,
    SearchDropdownComponent,
  ],
  entryComponents: [
    AlertsSnackbarComponent,
    CadAppController,
    NewVersionSnackbarComponent,
    SearchDropdownComponent,
  ],
  exports: [
    AlertsSidenavComponent,
    FooterComponent,
    HeaderComponent,
    HeaderToolbarActionMenuComponent,
    HeaderToolbarComponent,
    HeaderToolbarLoginMenuComponent,
    HeaderToolbarMenuComponent,
    HeaderToolbarMenuItemComponent,
    HeaderToolbarProcessStatusComponent,
    HeaderToolbarSearchMenuComponent,
    HeaderToolbarUserContextComponent,
    RibbonBreadcrumbComponent,
    RibbonComponent,
    SearchDropdownComponent,
  ]
})
export class CadAppComponentsModule {}
