<h1 matDialogTitle>{{title}}</h1>
<div mat-dialog-content>
{{message}}
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-raised-button
          type="button"
          color="primary"
          (click)="submit()">YES
  </button>
  <button mat-raised-button
          type="button"
          color="primary"
          (click)="cancel()">NO
  </button>
</div>
