import { ICellRendererParams } from '@ag-grid-community/core';
import { CurrencyPipe } from '@angular/common';
import * as _ from 'lodash';

export function currencyValueFormatter(params: ICellRendererParams): string {
  let pipe: CurrencyPipe = new CurrencyPipe('en-US');
  let val: string;

  if (params && params.data) {
    val = _.get(params.data, params.colDef.field);
  } else if (params && !_.isNil(params.value)) {
    val = params.value;
  }
  return (!isNaN((val as any)) && isFinite((val as any))) ? pipe.transform(val, 'USD', 'symbol') : val;
}
