import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';

@Injectable()
export class DateInterceptor implements HttpInterceptor {

  constructor() {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let updatedReq = req.clone({body: this.standardizeDates(req)});
    return next.handle(updatedReq);
  }
  private standardizeDates = (req: HttpRequest<any>): any => {
    if (req.body) {
      for (let property in req.body) {
        if (req.body.hasOwnProperty(property)) { // tslint: forin
          if (req.body[ property ] instanceof Date) {
            req.body[ property ] = moment(req.body[ property ]).format();
          }
        }
      }
    }
    return req.body;
  }
}
