import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';

@Component({ template: '' })
export abstract class TableColumnToggleBaseRenderer implements AgRendererComponent, OnDestroy {
  public value: any;
  protected params: any;
  protected modelType: any;

  agInit(params: any): void {
    this.params = params;
    this.modelType = this.params.column.colDef.toggleModelType;
  }

  ngOnDestroy(): void {
    this.params = null;
    this.modelType = null;
  }

  public toggleClicked = ($event: any): void => {
    $event.stopPropagation();
  }

  public valueChanged = ($event: any): void => {
    this.params.oldValue = this.getModelValue();
    switch (this.modelType) {
      case 'yesno': {
        this.setYesNoModelValue($event.checked);
        break;
      }
      case 'onezero': {
        this.setOneZeroModelValue($event.checked);
        break;
      }
      default: {
        this.setTrueFalseModelValue($event.checked);
      }
    }
    const gridRow: any = this.params.node;
    this.params.api.rowEdit.setGridRowDirty(this.params.api, gridRow);
    this.params.api.refreshCells({ rowNodes: [ gridRow ] });
    this.params.newValue = this.getModelValue();
    this.params.api.eventService.dispatchEvent({
      api: this.params.api,
      type: 'cellValueChanged',
      node: this.params.node,
      column: this.params.column }, this.params);
  }

  public isChecked = (): boolean => {
    const modelValue: any = this.params.node.data[ this.params.column.colDef.field ];
    switch (this.modelType) {
      case 'yesno': { return this.getYesNoModelValue(); }
      case 'onezero': { return this.getOneZeroModelValue(); }
      default: { return this.getTrueFalseModelValue(); }
    }
  }

  public isEditable = (): boolean => {
    return !this.params.column.colDef.editable;
  }

  public refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  protected getModelValue = (): any => {
    return this.params.node.data[ this.params.column.colDef.field ];
  }

  protected getYesNoModelValue = (): boolean => {
    return this.params.node.data[ this.params.column.colDef.field ] === 'Y';
  }

  protected getTrueFalseModelValue = (): boolean => {
    return this.params.node.data[ this.params.column.colDef.field ];
  }

  protected getOneZeroModelValue = (): boolean => {
    return this.params.node.data[ this.params.column.colDef.field ] === 1;
  }

  protected setYesNoModelValue = (checked: boolean): void => {
    if (checked) {
      this.params.node.data[ this.params.column.colDef.field ] = 'Y';
    } else {
      this.params.node.data[ this.params.column.colDef.field ] = 'N';
    }
  }

  protected setTrueFalseModelValue = (checked: boolean): void => {
    this.params.node.data[ this.params.column.colDef.field ] = checked;
  }

  protected setOneZeroModelValue = (checked: boolean): void => {
    if (checked) {
      this.params.node.data[ this.params.column.colDef.field ] = 1;
    } else {
      this.params.node.data[ this.params.column.colDef.field ] = 0;
    }
  }

}
