
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'src/cad/common/services/api/api-helper';
import * as _ from 'lodash';

@Injectable()
export class AgreementContractFootnotesApi {
  private readonly endpoint: string = 'agreement/contract/';
  private readonly suffix: string = '/footnotes';

  constructor(
    private apiHelper: ApiHelper
  ) {}

  public getFootnotes(contractId: string | number): Observable<any> {
    if (!_.isNil(contractId)) {
      return this.apiHelper.request(this.endpoint + contractId + this.suffix);
    }
    return observableOf([]);
  }

  public save(contractId: string | number, data: any): Observable<any> {
    if (data && !_.isNil(contractId)) {
      return this.apiHelper.request(
        this.endpoint + contractId + this.suffix + '/save',
        { body: data }
      );
    }
    return observableOf({});
  }

  public deleteItem(contractId: string | number, data: any): Observable<any> {
    if (data && !_.isNil(contractId)) {
      return this.apiHelper.request(
        this.endpoint + contractId + this.suffix + '/delete',
        { body: data }
      );
    }
    return observableOf({});
  }

  public getFootnoteTypes(contractId: string | number): Observable<any> {
    if (!_.isNil(contractId)) {
      return this.apiHelper.request(this.endpoint + contractId + this.suffix + '/footnote-types');
    }
    return observableOf([]);
  }
}
