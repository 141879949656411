import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { PhysicalLocationApi } from 'src/cad/common/services/api/physical/location/location';
import { AgreementContractApi } from 'src/cad/common/services/api/agreement/contract/contract';
import * as _ from 'lodash';

@Injectable()
export class DesigneeApi {
  public endpoint: string = 'associate/business-associate/';
  public suffix: string = '/designee';

  constructor(
    private apiHelper: ApiHelper,
    private agreementContractApi: AgreementContractApi,
    private physicalLocationApi: PhysicalLocationApi
  ) {}

  public findAll(baId: string | number): Observable<any> {
    return this.apiHelper.request(this.endpoint + baId + this.suffix);
  }

  public saveDesignees(designees: any, baId: string | number): Observable<any> {
    return this.apiHelper.request(this.endpoint + baId + this.suffix + '/save', { body: designees });
  }

  public filter(params: any): Observable<any> {
    let returnVal: Observable<any>;
    if (params) {
      let paramsObj: any = _.omit(params, 'populator');
      let lookupActionName: string = _.get(params, 'populator');

      switch (lookupActionName) {
        case 'designeeBaLookup': {
          returnVal = this.getDesigneeBALookupData(paramsObj);
          break;
        }
        case 'designeePointLookup': {
          returnVal = this.getDesigneePointLookupData(paramsObj);
          break;
        }
        case 'designeeContractLookup': {
          returnVal = this.getDesigneeContractLookupData(paramsObj);
          break;
        }
        default: {
          break;
        }
      }
    }
    return !_.isNil(returnVal) ? returnVal : observableOf([]);
  }

  public getDesigneeBALookupData(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + params.baId + this.suffix + '/ba-lookup', { body: _.omit(params, 'baId') });
  }

  public getDesigneePointLookupData(params: any): Observable<any> {
    if (this.physicalLocationApi) {
      return this.physicalLocationApi.filter(params);
    }
    return observableOf([]);
  }

  public getDesigneeContractLookupData(params: any): Observable<any> {
    if (this.agreementContractApi) {
      return this.agreementContractApi.filter(params);
    }
    return observableOf([]);
  }
}
