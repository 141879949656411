import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class AssociateContactAddressApi {

  private readonly endpoint: string = 'associate/contact/';
  private readonly suffix: string = '/address';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public findAll = (contactId: string | number): Observable<any> => {
    return this.apiHelper.request(this.endpoint + contactId + this.suffix);
  }

  public save = (contactAddresses: any[]): Observable<any> => {
    return this.apiHelper.request(this.endpoint + contactAddresses[0].contactId + this.suffix, { body: contactAddresses });
  }
}
