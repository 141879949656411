<ui-modal-header (close)="onCancelClick()">Unsaved Data</ui-modal-header>
<ui-modal-body>
  <p>You have unsaved data. Do you wish to navigate away from this page?</p>
</ui-modal-body>
<ui-modal-footer>
  <button mat-button
          mat-raised-button
          type="button"
          (click)="onCancelClick()">CANCEL
  </button>
  <button mat-button
          mat-raised-button
          (click)="onOkClick()"
          type="button"
          color="primary">OK
  </button>
</ui-modal-footer>
