import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService2 } from 'src/cad/common/store/user/services/user.service';

@Injectable()
export class UserInterceptor implements HttpInterceptor {
  private userService: UserService2;
  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let userReq = req;
    if (!this.userService) {
      // newing up is necessary to prevent a cyclic dependency error
      this.userService = new UserService2(this.injector);
    }

    const userState = this.userService.getUserStoreState();
    if (userState && userState.user && userState.user.currentContext) {
      const { assetAbbr, assetNbr, businessAssociateDunsNbr: ba, businessAssociateId: baid } = userState.user.currentContext;
      userReq = req.clone({
        setHeaders: {
          asset: assetNbr ? assetAbbr : undefined,
          ba: ba || '',
          baid: baid ? baid.toString() : undefined,
          ...(userState.runAsUser && { 'X-On-Behalf-Of': userState.runAsUser })
        }
      });
    }
    return next.handle(userReq);
  }
}
