import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ServiceCompntMatrixData } from 'src/cad/structured-products/shared/interfaces/service-compnt-matrix-data';
import { ServiceTemplateData } from 'src/cad/structured-products/shared/interfaces/service-template-data';

@Injectable()
export class RateComponentLookupApi {
  
  private readonly endpoint: string = 'product/right-price-service';
  
  constructor(
    private apiHelper: ApiHelper
  ) {}
  
  public filter(filterData: ServiceTemplateData): Observable<ServiceCompntMatrixData[]> {
    return this.apiHelper.request(`${this.endpoint}/rate-component-lookup`, { body: filterData });
  }
}
