import {AlertsService} from 'cad/core/services/alerts/alerts.service';
import {AutoUnsubscriber, AutoUnsubscribables} from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import {UsersApi} from 'src/cad/common/services/api/administration/users/users';
import {AssociateContactNotificationApi} from 'cad/common/services/api/associate/contact/notification/notification';
import {UserModelService} from 'src/cad/common/services/user/user-model-service';
import {Component, OnInit} from '@angular/core';
import {ActionService} from 'src/cad/common/services/security/action.service';
import { AddEmailNotificationModalComponent } from '../../modals/add-email-notification.component';
import { ProfileTypeListService } from 'cad/administration/shared/profile-type-list.service';
import { cloneDeep } from 'lodash';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'email-notification',
  templateUrl: './email-notification.component.html',
})
export class EmailNotificationComponent implements OnInit {

  public items: any[];
  public userId: any;
  public disabled: boolean = true;
  public editableStatus: boolean = true;
  @AutoUnsubscriber() private subs: AutoUnsubscribables;


  constructor(private api: UsersApi,
              public mdDialog: MatDialog,
              private cadAlerts: AlertsService,
              private userModelService: UserModelService,
              private associateContactNotificationApi: AssociateContactNotificationApi,
              public profileTypeListService:ProfileTypeListService,
              private actionService: ActionService) {
  }

  ngOnInit(): void {
    this.getNotificationTypes();
  }

  ngAfterViewInit(): void {
    this.subs.newSub = this.profileTypeListService.currentProfileTypeList.subscribe((data) => {
      if (data && data.length > 0) {
        this.editableStatus = false;
      } else {
        this.editableStatus = true;
      }
    });
  }

  getNotificationTypes(): void {
    this.userId = this.userModelService.getUser().userId;
    this.subs.newSub = this.associateContactNotificationApi.findAll(this.userId).subscribe((data) => {
      this.items = data;
      this.profileTypeListService.setNotificationTypeList(data.map((el): any => {
        return el.profileTypeCd;
      }));
    });
  }

  saveNotification(items: any): void {
    this.subs.newSub = this.associateContactNotificationApi.save(items).subscribe((resp) => {
      if (resp.successful) {
        this.getNotificationTypes();
        this.cadAlerts.success('Items successfully updated.');
      } else {
        this.showErrors(resp);
      }
    });
  }

  deleteRow(row: any): void {
    let index = this.items.indexOf(row.data);
    if (index > -1) {
      this.items[index].dirtyStatus = cad.DirtyStatus.DELETE;
      this.saveNotification(this.items);
    }

  }


  openAddModal(): void {
    let dialogRef: MatDialogRef<AddEmailNotificationModalComponent> = this.mdDialog.open(AddEmailNotificationModalComponent, {
      data: {
        contactNotificationList: cloneDeep(this.items),
        contactId: this.userId,
      },
      width: '60%',
      disableClose: true
    });

    let sub = dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.saveNotification(data);
      }
      sub.unsubscribe();
    });
  }


  showErrors(resp: any): void {
    if (resp.errorMessages && resp.errorMessages.length) {
      resp.errorMessages.forEach((err) => {
        this.cadAlerts.danger(err.message);
      });
    }
  }

}
