import { Component, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { TableColumnLabelboxRenderer } from '../labelbox/labelbox-renderer.component';

@Component({
  moduleId: module.id + '',
  selector: 'table-column-labelbox-editor',
  template: `
    <div class="editor" (click)="onClick($event)" tabindex="0">
      <mat-icon *ngIf="!!this.model" class="labelbox-cell-active" matTooltip="{{ activeTooltip }}">label</mat-icon>
      <mat-icon *ngIf="!this.model" class="labelbox-cell-inactive" matTooltip="{{ inactiveTooltip }}">label_outline</mat-icon>
    </div>
  `
})
export class TableColumnLabelboxEditorComponent extends TableColumnLabelboxRenderer implements AfterViewInit {
  public mode: string;
  public icon: HTMLElement;
  public activeTooltip: string;
  public inactiveTooltip: string;

  constructor(public el: ElementRef, public renderer: Renderer2) {
    super();
  }

  agInit(params: any): void {
    this.params = params;
    this.activeTooltip = params.colDef.cellActiveTooltip;
    this.inactiveTooltip = params.colDef.cellInactiveTooltip;
    this.mode = 'edit';
  }

  ngAfterViewInit(): void {
    //setTimeout(() => this.el.nativeElement.querySelector('.editor').focus());
  }

  onClick(e: MouseEvent): void {
    this.valueChanged(e);
  }
}
