<mat-card class="card-margin">
  <div mat-dialog-content>
      <div class="md-title header" class="title-padding">{{headLine}}</div>
        <mat-form-field>
          <input matInput
                 [value]="fullName"
                 placeholder="Contact Name"
                 disabled
                 name="external_contact_detail_contactName"/>
        </mat-form-field>
        <div fxLayout="row">
          <mat-form-field>
            <input matInput
                   value="{{contactObj?.phoneNumber | phoneNumber}}"
                   placeholder="Phone"
                   disabled
                   name="phoneNumber"/>
          </mat-form-field>
          <mat-form-field fxFlexOffset="5">
            <input matInput
                   value="{{contactObj?.faxNumber | phoneNumber}}"
                   placeholder="Fax"
                   disabled
                   name="faxNumber"/>
          </mat-form-field>
        </div>
      <mat-form-field>
        <input matInput
               [value]="contactObj && contactObj.email ? contactObj.email : ''"
               readonly
               placeholder="Email"
               uiInputHref="mailto:{{contactObj?.email}}"
               name="email"/>
      </mat-form-field>
    <div fxLayout="row" *ngIf="(label === 'Location' && confirmationInfo)">
      <mat-form-field>
        <input matInput
               [value]="type ? contactObj && contactObj?.pointCd ? contactObj.pointCd : '' : contactObj?.dunsNbr"
               placeholder="{{label}}"
               disabled
               name="label"/>
      </mat-form-field>
      <mat-form-field fxFlexOffset="5">
        <input matInput
               value="{{contactObj?.meter}}"
               placeholder="Meter"
               disabled
               name="meter"/>
      </mat-form-field>
    </div>
      <mat-form-field *ngIf="(label !== 'Location' || !confirmationInfo)">
        <input matInput
               [value]="type ? contactObj && contactObj?.pointCd ? contactObj.pointCd : '' : contactObj?.dunsNbr"
               placeholder="{{label}}"
               disabled
               name="label"/>
      </mat-form-field>
    <mat-form-field>
      <input matInput
             [value]="type ? contactObj && contactObj?.pointName ? contactObj.pointName : '' : contactObj?.baName"
             placeholder="{{label}} Name"
             disabled
             name="label"/>
    </mat-form-field>
    <div fxLayout="row" *ngIf="(label === 'Location' && confirmationInfo && contactObj && (contactObj.area || contactObj.supplyBasin))">
      <mat-form-field>
        <input matInput
               value="{{contactObj?.area}}"
               placeholder="Area"
               disabled
               name="area"/>
      </mat-form-field>
      <mat-form-field fxFlexOffset="5">
        <input matInput
               value="{{contactObj?.supplyBasin}}"
               placeholder="Supply Basin"
               disabled
               name="supplyBasin"/>
      </mat-form-field>
    </div>
    <div fxLayout="row" *ngIf="(label !== 'Location' && confirmationInfo)" class="emptydiv">
    </div>
  </div>
</mat-card>
