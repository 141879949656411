import { Directive, ElementRef, OnInit, Input } from '@angular/core';

@Directive ({
  selector: '[uiInputHref]',
})
export class UiInputHrefDirective implements OnInit {
  // This will be the href string represented by the attribute on the input tag
  @Input('uiInputHref') href: any;

  // Actual reference to the input element
  inputElement: any;

  constructor(el: ElementRef) {
    if (el.nativeElement) {
      this.inputElement = el.nativeElement;
    }
  }

  ngOnInit(): void {
    // We have a less file, but I am not sure how to get it referenced.
    // this.inputElement.classList.add('ui-input-href');
    this.inputElement.setAttribute('style', 'cursor: pointer; color: blue!important; text-decoration: underline');

    this.inputElement.setAttribute('disabled', '');

    // Add click to parent element <div> as the input onclick is not working - not sure if this is because it is disabled.
    this.inputElement.parentElement.onclick = (evt: any) => {
      window.location.href = this.href;
    };
  }
}
