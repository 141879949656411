
import {filter,  take } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import UserPreferences = cad.UserPreferences;
import ReportFavorite = cad.ReportFavorite;
import AssetSpecificPreferences = cad.AssetSpecificPreferences;
import {ApiHelper} from 'cad/common/services/api/api-helper';
import {BehaviorSubject} from 'rxjs';
import {UserStoreService} from 'cad/common/store/core/services/user-store.service';
import { AlertsService } from 'src/cad/core/services/alerts/alerts.service';
import {AutoUnsubscribables, AutoUnsubscriber} from 'cad/shared/mixins/auto-unsubscriber.mixin';
import * as _ from 'lodash';
import ValidationResult = cad.ValidationResult;
import TablePreferences = cad.TablePreferences;
import { UserCacheService } from 'cad/common/store/user/services/user-cache.service';
import { RouterService } from 'cad/core/services/router/router.service';
import { TableComponent } from 'src/ag-grid-wrapper/table/table.component';

import {UserModelService} from 'cad/common/services/user/user-model-service';

@Injectable()
export class UserPreferenceService {
  @AutoUnsubscriber() public subs: AutoUnsubscribables;
  private prefs: BehaviorSubject<UserPreferences> = new BehaviorSubject<UserPreferences>(null);
  private routeUrl: string = '';

  constructor(private apiHelper: ApiHelper, userStateStore: UserStoreService,
              private userCacheService: UserCacheService,
              private router: RouterService,
              injector: Injector,
              private userModelService: UserModelService,
              private alertsService: AlertsService) {
    this.subs.newSub = userStateStore.stateChanged.pipe(
      filter((userState) => userState && userState.stateChanges))
      .subscribe((userState) => {
        if (userState.stateChanges.user) {
          this.retrieveUserPref();
        } else {
          this.prefs.next({ tablePreferences: [], dashboards: [], assetSpecific: []});
        }
      });
  }

  private retrieveUserPref(): void {
    this.apiHelper.request('user-preference/general', { method: 'GET' }).subscribe((data) => {
      if (data) {
        this.prefs.next(data);
      } else {
        this.prefs.next({ tablePreferences: [], dashboards: [], assetSpecific: []});
      }
    });
  }

  public getPreferences(): BehaviorSubject<UserPreferences> {
    return this.prefs;
  }

  public getCurrentPreferences(): UserPreferences {
    return this.prefs.value;
  }

  public removeDashboard(name: string): void {
    for (let i = this.prefs.value.dashboards.length - 1; i >= 0; i--) {
      if (this.prefs.value.dashboards[i].name.toLowerCase() === name.toLowerCase()) {
        this.prefs.value.dashboards.splice(i, 1);
        break;
      }
    }
    this.savePrefs();
  }

  public moveUpDashboard(theName: string): void {
    for (let i = 1; i < this.prefs.value.dashboards.length; i++) {
      if (this.prefs.value.dashboards[i].name.toLowerCase() === theName.toLowerCase()) {
        this.prefs.value.dashboards[i].index -= 1;
        this.prefs.value.dashboards[i - 1].index += 1;
        break;
      }
    }
    this.sortDashboards();
    this.savePrefs();
  }

  public moveDownDashboard(theName: string): void {
    for (let i = 0; i < this.prefs.value.dashboards.length - 1; i++) {
      if (this.prefs.value.dashboards[i].name.toLowerCase() === theName.toLowerCase()) {
        this.prefs.value.dashboards[i].index += 1;
        this.prefs.value.dashboards[i + 1].index -= 1;
        break;
      }
    }
    this.sortDashboards();
    this.savePrefs();
  }

  public addDashboard(theName: string): void {
    this.addDashboardAt(theName, this.prefs.value.dashboards.length + 1);
    this.savePrefs();
  }

  public addDashboardAt(theName: string, ndx: number): void {
    if (this.doesDashExist(theName)) {
      return;
    }
    //simply re-index higher indexes if we have a collision with the new entry
    if (this.dashIndexHasCollision(ndx)) {
      for (let i = this.prefs.value.dashboards.length - 1; i >= 0; i--) {
        if (this.prefs.value.dashboards[i].index >= ndx) {
          this.prefs.value.dashboards[i].index += 1;
        }
      }
    }
    this.prefs.value.dashboards.push({name: theName, index: ndx});
    this.sortDashboards();
    this.savePrefs();
  }

  public addTablePreference(tableComponent: TableComponent, colPreference: any): void {
    let _root = '_root';
    this.routeUrl = '';
    let assetGroupCd = this.userCacheService.getUserState().user.currentContext.assetGroupCd;
    let assetNbr: number = this.userCacheService.getUserState().user.currentContext.assetNbr;
    let currentRoute: string = this.processRouteTree(this.router.routerState.snapshot[ _root ].children[ 0 ]);
    let tableId = UserPreferenceService.generateTableId(tableComponent);
    if (!_.isNil(tableId && this.prefs.value.tablePreferences)) {
      let result: any = UserPreferenceService.findTargetTablePref(tableId, this.prefs.value.tablePreferences, assetGroupCd, assetNbr, currentRoute);
      _.forEach(result, (item: any) => {
        _.remove(this.prefs.value.tablePreferences, item);
      });
      this.prefs.value.tablePreferences.push({
        name: tableId,
        preference: colPreference,
        assetGroup: assetGroupCd,
        assetNbr,
        route: currentRoute
      });
    } else {
      this.prefs.value.tablePreferences = [];
      this.prefs.value.tablePreferences.push({
        name: tableId,
        preference: colPreference,
        assetGroup: assetGroupCd,
        assetNbr,
        route: currentRoute
      });
    }
    this.savePrefs();
  }

  public deleteTablePreference(tableComponent: TableComponent, columnApi:any, api:any): void {
    let _root = '_root';
    this.routeUrl = '';
    let assetGroupCd = this.userCacheService.getUserState().user.currentContext.assetGroupCd;
    let assetNbr: number = this.userCacheService.getUserState().user.currentContext.assetNbr;
    let currentRoute: string = this.processRouteTree(this.router.routerState.snapshot[_root].children[0]);
    let tableId = UserPreferenceService.generateTableId(tableComponent);
    if (!_.isNil(tableId && this.prefs.value.tablePreferences)) {
      let result: any = UserPreferenceService.findTargetTablePref(tableId, this.prefs.value.tablePreferences, assetGroupCd, assetNbr, currentRoute);
      _.forEach(result, (item: any) => {
        _.remove(this.prefs.value.tablePreferences, item);
      });
    }
    this.savePrefs();
    columnApi.resetColumnState();
    api.setSortModel([]);
  }

  private sortDashboards(): void {
    this.prefs.value.dashboards = _.sortBy(this.prefs.value.dashboards, 'index');
  }

  private doesDashExist(theName: string): boolean {
    for (let i = 0; i < this.prefs.value.dashboards.length; i++) {
      if (this.prefs.value.dashboards[i].name.toLowerCase() === theName.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  private dashIndexHasCollision(ndx: number): boolean {
    for (let i = this.prefs.value.dashboards.length - 1; i >= 0; i--) {
      if (this.prefs.value.dashboards[i].index === ndx) {
        return true;
      }
    }
    return false;
  }

  private initializeAssetSpecificPrefs(assetNumber: number): void {
    if (!this.prefs.value.assetSpecific) {
      this.prefs.value.assetSpecific = [];
    }
    if (this.findAssetSpecificPrefsIndex(assetNumber) < 0) {
      this.prefs.value.assetSpecific.push({assetNbr: assetNumber, favoriteReports: []});
    }
  }

  public getReportFavoritesFromPrefs(prefs: UserPreferences): ReportFavorite[] {
    if (!prefs) {
      return [];
    }
    let assetNbr = this.getCurrentAssetNbr();
    this.initializeAssetSpecificPrefs(assetNbr);
    return this.prefs.value.assetSpecific[this.findAssetSpecificPrefsIndex(assetNbr)].favoriteReports;
  }

  public saveReportFavorites(favs: ReportFavorite[]): void {
    let assetNbr = this.getCurrentAssetNbr();
    this.initializeAssetSpecificPrefs(assetNbr);
    this.prefs.value.assetSpecific[this.findAssetSpecificPrefsIndex(assetNbr)].favoriteReports = favs;
    this.savePrefs();
  }

  public addReportFavorite(fav: ReportFavorite): void {
    let assetNbr = this.getCurrentAssetNbr();
    this.initializeAssetSpecificPrefs(assetNbr);
    this.prefs.value.assetSpecific[this.findAssetSpecificPrefsIndex(assetNbr)].favoriteReports.push(fav);
    this.savePrefs();
  }

  public removeReportFavorite(fav: ReportFavorite): void {
    let assetNbr = this.getCurrentAssetNbr();
    _.remove(this.prefs.value.assetSpecific[this.findAssetSpecificPrefsIndex(assetNbr)].favoriteReports, fav);
    this.savePrefs();
  }

  public static generateTableId(tableComponent: TableComponent): string {
    let _view = '_hostView';
    let generatedId: string;

    if (!_.isNil(tableComponent.id)) {
      generatedId = tableComponent.id;
    } else {
      let tableEl: HTMLElement = tableComponent.element.nativeElement;

      generatedId = 'tableId_';
      if (tableEl.closest('ui-card') && tableEl.closest('ui-card').id) {
        generatedId += tableEl.closest('ui-card').id;
      } else if (tableEl.closest('.mat-card')
        && tableEl.closest('.mat-card').querySelector('.mat-card-header .mat-card-title .mat-title')
        && (tableEl.closest('.mat-card').querySelector('.mat-card-header .mat-card-title .mat-title') as HTMLElement).innerText) {
        generatedId += _.camelCase((tableEl.closest('.mat-card')
          .querySelector('.mat-card-header .mat-card-title .mat-title') as HTMLElement).innerText);
      } else {
        generatedId += tableComponent.viewContainer[ _view ].constructor.name;
      }
    }
    return generatedId;
  }

  public static findTargetTablePref(tableId: string, tablePreferences: TablePreferences[], assetGroupCd: any, assetNbr: number, currentRoute: any): any {
    return _.filter(tablePreferences, (tablePreference: TablePreferences) => {
      return (tablePreference.name === tableId
        && tablePreference.assetGroup === assetGroupCd
      && (!_.isNil(tablePreference.assetNbr) ? tablePreference.assetNbr === assetNbr : true)
        && _.trim(tablePreference.route) === _.trim(currentRoute));
    });
  }

  public processRouteTree(input: any): string {
    if (!_.isNil(input.value.routeConfig.path) && input.value.routeConfig.path !== '') {
      this.routeUrl = this.routeUrl + '/' + input.value.routeConfig.path;
    }
    if (input.children[ 0 ]) {
      this.processRouteTree(input.children[ 0 ]);
    }
    return this.routeUrl;
  }

  ngOnDestroy(): void {
    this.prefs.complete();
  }

  private findAssetSpecificPrefsIndex(assetNbr: number): number {
    let rv = -1;
    this.prefs.value.assetSpecific.forEach((item: AssetSpecificPreferences, index: number) => {
      if (item.assetNbr === assetNbr) {
        rv = index;
      }
    });
    return rv;

  }

  private getCurrentAssetNbr(): number {
    return (this.userModelService.getUser() && this.userModelService.getUser().currentContext)
      ? this.userModelService.getUser().currentContext.assetNbr
      : null;
  }

  private savePrefs(): void {
    this.clearOrConvertObsoletePrefs();
    this.apiHelper.request('user-preference/general', {body: this.prefs.value})
    .pipe(take(1)).subscribe((result: ValidationResult) => {
      this.alertsService.showResultAlerts(
        result,
        'User Preferences successfully saved.'
      );
      if (result && result.successful) {
        this.notifyOfChange();
      }
    });
  }

  private notifyOfChange(): void {
    this.prefs.next(this.prefs.value);
  }

  private clearOrConvertObsoletePrefs(): void {
    if ((this.prefs.value as any).favoriteReports) {
      (this.prefs.value as any).favoriteReports = null;
    }
  }

}
