import { ApiHelper } from 'cad/common/services/api/api-helper';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScheduleNominationData } from 'src/cad/flowing-gas/scheduling/autoscheduling/interfaces/schedule-nomination-data';
import { MilePostData } from 'src/cad/flowing-gas/scheduling/autoscheduling/interfaces/mile-post-data';
import { PointScheduleOverrunData } from 'src/cad/flowing-gas/scheduling/autoscheduling/interfaces/point-schedule-overrun-data';
import { ProrateScheduleNominationData } from 'src/cad/flowing-gas/scheduling/autoscheduling/interfaces/prorate-schedule-nomination-data';
import { SegmentMilePostSearchData } from 'src/cad/flowing-gas/scheduling/autoscheduling/automatic-scheduling/interfaces/segment-mile-post-search-data';
import { PointSchedulingSearchData } from 'src/cad/flowing-gas/scheduling/autoscheduling/automatic-scheduling/interfaces/point-scheduling-search-data';
import { SegmentMilePostNomSearchData } from 'src/cad/flowing-gas/scheduling/autoscheduling/automatic-scheduling/interfaces/segment-mile-post-nom-search-data';
import { PointSchedulingNomSearchData } from 'src/cad/flowing-gas/scheduling/autoscheduling/automatic-scheduling/interfaces/point-scheduling-nom-search-data';
import { UpdateScheduleNominationData } from 'src/cad/flowing-gas/scheduling/autoscheduling/automatic-scheduling/interfaces/update-schedule-nomination-data';
import ValidationResult = cad.ValidationResult;

@Injectable()
export class AutomaticSchedulingApi {

  public endpoint: string = 'flow/auto-scheduling/automatic-scheduling';

  constructor(
    private apiHelper: ApiHelper
  ) {}

  public findSegmentMilePostSchedulingOverview(filterData: SegmentMilePostSearchData): Observable<MilePostData[]> {
    return this.apiHelper.request(this.endpoint + '/mile-post/filter', { body: filterData });
  }

  public findScheduleNominationForMilePost(filterData: SegmentMilePostNomSearchData): Observable<ScheduleNominationData[]> {
    return this.apiHelper.request(this.endpoint + '/mile-post/noms/filter', { body: filterData });
  }

  public findScheduleNominationForPoint(filterData: PointSchedulingNomSearchData): Observable<ScheduleNominationData[]> {
    return this.apiHelper.request(this.endpoint + '/point/noms/filter', { body: filterData });
  }

  public findPointAutoScheduleOverview(filterData: PointSchedulingSearchData): Observable<PointScheduleOverrunData[]> {
    return this.apiHelper.request(this.endpoint + '/point/filter', { body: filterData });
  }
  
  public saveNominations(saveData: UpdateScheduleNominationData): Observable<ValidationResult> {
    return this.apiHelper.request(this.endpoint + '/noms/save', { body: saveData });
  }
  
  public prorateNoms(prorateData: ProrateScheduleNominationData): Observable<ScheduleNominationData[]> {
    return this.apiHelper.request(this.endpoint + '/noms/prorate', { body: prorateData });
  }
}
