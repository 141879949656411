import { Component, Inject, Input, OnDestroy } from '@angular/core';
import { TABLE_RENDERER_DATA } from 'src/ag-grid-wrapper';

import { Observable } from 'rxjs';

@Component({
  selector: 'table-column-datepicker-renderer',
  template: `
    <div class="inner-cell">
      <div class="inner-cell-dropdown-value">{{formatted | async}}</div>
    </div>
  `,
})
export class DatepickerRendererComponent {
  public format: string = 'MM-DD-YYYY';
  public formatted: Observable<string>;

  constructor(@Inject(TABLE_RENDERER_DATA) public params: any) {
    this.format = this.format || this.params.colDef.cellFilter;
    this.formatted = this.params.paramsChanged$.map((v) => {
      let newDate = moment(v).format(this.format);
      return v ? newDate : '';
    });
  }
}
