import { Injectable } from '@angular/core';
import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let httpHeaders: HttpHeaders = new HttpHeaders({
      'X-Requested-With': 'CAD',
      'Content-Type': 'application/json;charset=utf-8',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Accept: 'application/json, text/plain, */*',
    });
    const reqClone = req.clone({headers: httpHeaders});
    return next.handle(reqClone);
  }
}
