import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { SecurityAuthApi } from 'cad/common/services/api/security/auth/auth';
import { RouterService } from 'src/cad/core/services/router/router.service';
import {UserService2} from 'cad/common/store/user/services/user.service';

@Injectable()
export class SecurityLogoutApi {
  private logoutURI: string = 'security/logout';

  constructor(
    private apiHelper: ApiHelper,
    private securityAuthApi: SecurityAuthApi,
    private router: RouterService,
    private userService: UserService2,
  ) {}

  public logoutUser = (): Observable<any> => {
    let req = this.apiHelper.request(this.logoutURI, {});
    this.securityAuthApi.clear();
    this.userService.logout();
    this.router.navigate([ '/dashboard' ], { queryParams: { nl: 1 }} );
    console.log('The user has been logged out successfully.');
    return req;
  }
}
