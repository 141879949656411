import {ApiHelper} from 'src/cad/common/services/api/api-helper';
import {Observable} from 'rxjs';
import {ItemApi} from 'src/features/common/item/item-api';
import {Injectable} from '@angular/core';

@Injectable()
export class AutoParkServiceApi extends ItemApi {

  private readonly endpoint: string = 'flowing-gas/auto-park-service';

  constructor(private apiHelper: ApiHelper) {
    super();
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/dashboard', {body: {
      accountingPeriod: dateUtil().getFirstDayOfMonth()
    } });
  }

  public deleteItem(): Observable<any> {
    throw 'Not implemented';
  }

  public find(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/find', { body: params });
  }

  public save(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save', { body: params });
  }

}
