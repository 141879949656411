import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import {ProducerData} from 'cad/nominations/nomination/shared/data-model/producer-data';

@Injectable()
export class ProducerApi {
  private readonly endpoint: string = 'nomination/nomination-matrix/producer';

  constructor(
    private apiHelper: ApiHelper,
  ) {}


  public filter(params: { flowDate: string, pointCd: string }): Observable<ProducerData[]> {
    const date: string = moment(params.flowDate).format('MM-DD-YYYY');
    return this.apiHelper.request(this.endpoint + '/filter/'+ params.pointCd +'/'+ date );
  }
}
