import { MatOptgroup, MatOption } from '@angular/material/core';
import { QueryList } from '@angular/core';

export class MatOptionHelpers {

  /**
   * From @angular/material 5.0.0-rc.2
   * Counts the amount of option group labels that precede the specified option.
   * @param optionIndex Index of the option at which to start counting.
   * @param options Flat list of all of the options.
   * @param optionGroups Flat list of all of the option groups.
   */
  static countGroupLabelsBeforeOption(optionIndex: number, options: QueryList<MatOption>,
                                      optionGroups: QueryList<MatOptgroup>): number {

    if (optionGroups.length) {
      let optionsArray = options.toArray();
      let groups = optionGroups.toArray();
      let groupCounter = 0;

      for (let i = 0; i < optionIndex + 1; i++) {
        if (optionsArray[i].group && optionsArray[i].group === groups[groupCounter]) {
          groupCounter++;
        }
      }

      return groupCounter;
    }

    return 0;
  }
}
