import { NgModule } from '@angular/core';
import { SecurityService } from './security.service';
import { CanDeactivateModal } from 'src/features/common/security/modal/can-deactivate-modal';
import { SharedModule } from 'src/cad/shared/shared.module';

@NgModule({
  providers: [ SecurityService ],
  declarations: [ CanDeactivateModal ],
  entryComponents: [ CanDeactivateModal ],
  imports: [ SharedModule ]
})
export class SecurityModule {}
