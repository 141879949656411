<ui-card [headline]="headline$|async" [cardToolBarItems]="menuItems$|async" [isExpanded]="true">
  <div *ngIf="itemData$|async;let itemData;">
      <ui-table *ngIf="itemData.length > 0" (rowClicked)="onRowClicked($event)" [data]="itemData" fxLayout="column">
        <ui-table-column name="relatedOfferID" headerName="Offer Number" [width]="80" type="numberStr"></ui-table-column>
        <ui-table-column name="bidID" headerName="Bid Number" [width]="80" type="numberStr"></ui-table-column>
        <ui-table-column name="status" headerName="Bid Status" codeType="CROFFERSTATUS" etCodeRenderer></ui-table-column>
        <ui-table-column name="bidderName" headerName="Bidder Name" [width]="500"></ui-table-column>
      </ui-table>
    </div>
</ui-card>
