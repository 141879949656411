import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import { AlertsService } from 'cad/core/services/alerts/alerts.service';
import * as _ from 'lodash';


@Component({
  selector: 'new-contact-profile-modal',
  templateUrl: './new-contact-profile-modal.component.html',
})
export class NewContactProfileModalComponent {
  public newProfileType: any = {};
  public items: any[];
  public disableList: any[];
  public phoneMaskOpts: string[] = [ 'C', 'P', 'SP', 'PF', 'SF', 'HP', 'TV', 'TM', 'TA', 'TS' ];
  public phoneExtMaskOpts: string[] = [ 'PP' ];


  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
              public alerts: AlertsService,
              private dialogRef: MatDialogRef<NewContactProfileModalComponent>,) {
  }

  ngOnInit(): void {
    this.setCtrlDefaults();
  }

  close(): void {
    let status: boolean = true;
    _.forEach(this.items, (item) => {
      if (moment(item.effBeginDt).isAfter(moment(item.effEndDt)) && status) {
        status = false;
        this.alerts.danger('End Date can not be before Begin date');
      }
    });
    if (!_.isEmpty(this.items) && status) {
      this.dialogRef.close(this.items);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }


  public setDirtyStatus(idx: number): void {
    let item = this.items[idx];
    let ds = item.dirtyStatus;
    if (ds !== cad.DirtyStatus.NEW) {
      item.dirtyStatus = cad.DirtyStatus.UPDATE;
    }
  }

  public setCtrlDefaults = () => {

    this.items = this.data.items;
    this.newProfileType.effBeginDt = dateUtil().getToday();
    this.newProfileType.effEndDt = dateUtil().getDefaultEndDate();
    this.newProfileType.contactId = this.data.contactId;

    this.disableProfileTypes();
    if (_.isEmpty(this.items)) {
      // add primary email and phone if there are no types
      let primaryPhone = _.clone(this.newProfileType);
      let primaryEmail = _.clone(this.newProfileType);
      primaryPhone.profileTypeCd = 'PP';
      primaryEmail.profileTypeCd = 'PE';
      primaryPhone.dirtyStatus = primaryEmail.dirtyStatus = cad.DirtyStatus.NEW;
      this.items.push(primaryPhone, primaryEmail);
    }

  }

  public addProfileType = (row: any): void => {
    _.forEach(row.profileTypeCd, (item:any):void => {
      let profType = {
        contactId: row.contactId,
        effBeginDt: row.effBeginDt,
        effEndDt: row.effEndDt,
        profileTypeCd: item,
        dirtyStatus: cad.DirtyStatus.NEW
      };
      if (moment(row.effBeginDt).isAfter(moment(row.effEndDt))) {
        this.alerts.danger('End Date can not be before Begin date');
      } else {
        this.items.push(profType);
      }
    });
    this.disableProfileTypes();
  }

  public disableProfileTypes = (): void => {
    let activeTypes = this.items.map((el): any => {
      return el.profileTypeCd;
    });
    this.disableList = activeTypes;
  }


  public removeProfileType = (index: any): void => {
    let row = this.items[index];
    if (row.dirtyStatus === cad.DirtyStatus.NEW) {
      this.items.splice(index, 1);
      this.disableProfileTypes();
      return;
    }
    row.dirtyStatus = cad.DirtyStatus.DELETE;

  }

  public setMaskInput(item: any): string {
    let type = item.profileTypeCd;
    let usePhoneMask = this.phoneMaskOpts.indexOf(type) > -1;
    let usePhoneExtMask = this.phoneExtMaskOpts.indexOf(type) > -1;
    item.maskInput = (usePhoneMask || usePhoneExtMask);
    if(usePhoneExtMask) {
      return 'phoneExtension';
    } else if (usePhoneMask) {
      return 'phone';
    }
    return '';
  }
}
