<ui-fullscreen-body fxFlex fxLayout>
  <div
    class="ui-fullscreen-body-left"
    fxLayout="column"
    [ngClass]="{ 'slide-right': selectedTabIdx == 1 }"
    [ngStyle]="selectedContentIndex == 1 ? { zIndex: -1 } : {}">
    <mat-tab-group
      class="ui-mat-tabs"
      fxFlex
      [dynamicHeight]="false"
      [selectedIndex]="selectedTabIdx"
      (selectedIndexChange)="onTabChange($event)">

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon fontSet="material-icons">search</mat-icon>
        </ng-template>
        <div fxLayoutFill fxFlex class="tab1-content">
          <div fxLayoutFill class="ui-search search-form sidebar-content-inner">
            <div class="tab-title">Search</div>
            <div class="col-padding" uiAutoFocus>
              <ng-container
                [framingComponentOutlet]="associationController.view.itemSearch || associationController.model.toView.search"
                [framingComponentInputs]="{ associationController: associationController }"
                [framingComponentInjector]="associationController.injector"
                (onComponent)="onSearchReady($event)">
              </ng-container>
            </div>

          </div>
        </div>
      </mat-tab>

      <mat-tab *ngIf="associationController.model.modalShowForm">
        <ng-template mat-tab-label>
          <mat-icon fontSet="material-icons">add</mat-icon>
        </ng-template>
        <div fxLayoutFill fxLayout="column" fxFlex class="tab2-content">
          <div fxLayoutFill class="sidebar-content-inner">
            <div class="tab-title">Create</div>
            <ng-container
              [framingComponentOutlet]="associationController.model.toView.newForm || associationController.model.toView.form"
              [framingComponentInputs]="{ associationController: associationController }"
              [framingComponentInjector]='createInjector'
              (onComponent)="onItemNewReady($event)">
            </ng-container>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div fxLayout="column" class="ui-fullscreen-body-right">
    <div class="ui-fullscreen-body-right-float">

      <mat-tab-group
        class="ui-mat-tabs-no-label"
        [ngClass]="{ 'disable-content': selectedTabIdx == 1 }"
        fxLayout="column"
        fxLayoutFill
        fxFlex
        [dynamicHeight]="false"
        (selectedIndexChange)="onSelectedIndexChange($event)"
        [selectedIndex]="selectedContentIndex">

        <mat-tab>
          <div class="tab1-content" fxLayout="column">
            <div class="tab-content-header">
              Select {{associationController.model.toModel.name}}(s)
            </div>

            <div class="results-title">{{searchResults.length}} Results</div>

            <!-- MULTI SELECT -->

            <!-- [minTableWidth]="resultsTableMinWidth"
                  [gridApi]="gridApi"
                  [isMultiSelect="associationController.model.modalMultiSelect" -->
            <div class="_tableHeightWrapper">
              <ui-table
                #listTable
                renderFullHeight="true"
                class="tab-content-body"
                [ngClass]="['ui-table-fullscreen']"
                gridName="search"
                [data]="searchResults"
                [showMultiSelect]="associationController.model.modalMultiSelect"
                [showSingleSelect]="!associationController.model.modalMultiSelect"
                enableFullRowSelection="true"
                enableExpandable="false"
                (rowSelected)="select($event)"
                [actionMenu]="associationController.model.modalActionMenu">
                <ng-container
                  [framingComponentOutlet]="associationController.model.toView.list"
                  [framingComponentInputs]="{ associationController: associationController }"
                  [framingComponentInjector]="listInjector">
                </ng-container>
              </ui-table>
            </div>
          </div>
        </mat-tab>

        <mat-tab [disabled]="!associationController.selectedItems || associationController.selectedItems.length < 1">
          <div *ngIf="selectedContentIndex == 1" class="tab2-content" fxLayout="column">
            <div class="tab-content-header">
              Define Associations
            </div>
            <ng-container
              [framingComponentOutlet]="associationController.view.form"
              [framingComponentInputs]="{ associationController: associationController }"
              (onComponent)="onFormReady($event)">
            </ng-container>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</ui-fullscreen-body>

<!-- UI-QUERY HAS NO VIEW -->
<ui-query
  [api]="associationController.associationSearch"
  [(items)]="searchResults"
  [params]="searchReference ? searchReference.instance.params : {}"
  [allowEmptyParams]="associationController.model.searchEmptyParams"
  [autoFireSearch]="associationController.model.autoSearch"
  [cache]="false">
  <ui-query-filter-exclude-items
    *ngIf="associationController.model.filterSearchResult"
    [idField]="associationController.model.toModel.primaryKey"
    [items]="$any(associationController).model.items">
  </ui-query-filter-exclude-items>
</ui-query>
