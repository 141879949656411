import {
  Directive, ElementRef, AfterContentInit, Renderer2, OnDestroy, ContentChild
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { AutoUnsubscribables, AutoUnsubscriber } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';

/*
 This directive will append length information to the input assuming that maxLength or minLength are set on the input
 */

@Directive ({
  selector: 'mat-form-field',
})
export class LengthHintDirective implements AfterContentInit, OnDestroy {

  @ContentChild(NgModel) public ngModelRef: NgModel;
  @AutoUnsubscriber() private subs: AutoUnsubscribables;
  private hostElement: any;
  private lengthHint: any;
  private listener: Function;

  constructor(
    public elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    if (elementRef && elementRef.nativeElement) {
      this.hostElement = elementRef.nativeElement;
    }
  }

  public ngAfterContentInit(): void {
    if (this.ngModelRef) {
      this.subs.newSub = this.ngModelRef.valueChanges.subscribe(() => this.appendLengthInfo());
    }
    setTimeout(() => this.appendLengthInfo());
  }

  public ngOnDestroy(): void {
    if (this.listener) { this.listener(); }
  }

  public appendLengthInfo(): void {
    let input = this.hostElement.querySelectorAll('input, textarea')[0];
    if (input) {
      let maxLenAttr = input.attributes.maxlength;
      let minLenAttr = input.attributes.minlength;
      if (maxLenAttr || minLenAttr) {
        if (!this.lengthHint) {
          this.lengthHint = this.renderer.createElement('mat-hint');
          this.renderer.appendChild(this.hostElement, this.lengthHint);
          this.renderer.addClass(this.lengthHint, 'hint-length');
          this.listener = this.renderer.listen(input, 'keyup', () => this.appendLengthInfo());
        }
        let msg = input.inputmask
          ? (input.inputmask.format(input.value) && input.inputmask.format(input.value).length
            ? input.inputmask.format(input.value).length.toString()
            : '0')
          : (this.ngModelRef && this.ngModelRef.value ? this.ngModelRef.value.length.toString() : '0');
        if (maxLenAttr && minLenAttr) {
          msg += '/' +  minLenAttr.nodeValue + '-' + maxLenAttr.nodeValue;
        } else if (maxLenAttr) {
          msg += '/' + maxLenAttr.nodeValue;
        } else {
          msg += '/' + minLenAttr.nodeValue + '+';
        }
        this.lengthHint.innerHTML = msg;
      }
    }
  }
}
