import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrentContext } from 'cad/common/models/user/user-details-model';
import { AuthService } from 'cad/common/services/auth/auth';
import { UserPreferenceService } from 'cad/common/services/preferences/user-preferences';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DashboardConfig } from 'src/cad/common/services/dashboard/dashboard-config.model';
import { RouterService } from 'src/cad/core/services/router/router.service';
import { DASHBOARD_CONFIG } from './dashboard-config';

@Component({
  selector: 'select-dash-dialog',
  templateUrl: './select-dash-dialog.component.html',
  styleUrls: [ './select-dash-dialog.component.less' ],
})
export class SelectDashDialogComponent implements OnInit {

  private allDashboards: DashboardConfig[] = DASHBOARD_CONFIG;
  public dashboards$: Observable<DashboardConfig[]>;
  public authorizedDashboards$: Observable<DashboardConfig[]>;
  public selectedItems: DashboardConfig[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public currentContext$: Observable<CurrentContext>,
              private userPrefs: UserPreferenceService,
              private router: RouterService,
              private dialogRef: MatDialogRef<SelectDashDialogComponent>,
              private authService: AuthService) {
  }

  public ngOnInit(): void {
    this.authorizedDashboards$ = this.currentContext$.pipe(
      shareReplay(),
      map((c) =>
        this.allDashboards.filter((d) => {
          const authInfo = this.authService.getAuthorizationForAction(c, d.auth);
          return authInfo && authInfo.enabled;
        })));

    this.dashboards$ = combineLatest(this.authorizedDashboards$, this.userPrefs.getPreferences())
      .pipe(
        shareReplay(),
        map(([ authorizedDashboards, preferences ]) =>
          authorizedDashboards.filter((d) =>
            !preferences.dashboards.some((pd) => pd.name === d.code))));
  }

  public submit(): void {
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.userPrefs.addDashboard(this.selectedItems[i].code);
    }
    this.cancel();
  }

  public cancel(): void {
    this.router.navigate([ '/dashboard' ], {queryParams: {edit: 1}});
    this.dialogRef.close();
  }

}
