<div fxLayout="column" fxFlexFill>
    <mat-button-toggle-group [(ngModel)]="showRaw">
        <mat-button-toggle [value]="false">Pretty</mat-button-toggle>
        <mat-button-toggle [value]="true">HTML</mat-button-toggle>
    </mat-button-toggle-group>
    <div #host (dblclick)="showRaw = !showRaw" [fxHide]="showRaw" fxFlex>
    </div>
    <textarea fxFlex
              [fxHide]="!showRaw"
              (dblclick)="showRaw = !showRaw"
              (keyup)="handleChanges($event.currentTarget.value)"
              [(ngModel)]="html"
              [disabled]="disabled">
    </textarea>
</div>
