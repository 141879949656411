
import {filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import {MenuModel} from 'src/cad/common/models/menu/menu-model';
import {AuthService} from 'src/cad/common/services/auth/auth';
import {UserDetail} from 'src/cad/common/models/user/user-details-model';
import {UserStoreService} from 'cad/common/store/core/services/user-store.service';
import { FeatureFlagsService } from 'src/common/services/feature-flags/feature-flags.service';
import { menu } from 'cad/menu';


@Injectable()
export class MenuService {
  private subscriptions: Subscription[] = [];

  private menuItems: any = menu;

  constructor(
    private authService: AuthService,
    private userStateStore: UserStoreService,
    private menuModel: MenuModel,
    private featureFlagsService: FeatureFlagsService
  ) {
    this.subscriptions.push(this.userStateStore.stateChanged.pipe(
      filter((userDetail) => userDetail.state.user))
      .subscribe((userDetail) => {
        this.onUserContext(userDetail.state.user);
      }));
    this.menuModel.menuItemsSubject.next(this.menuItems);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private onUserContext(userDetail: UserDetail): void {
    this.updateMenu(userDetail);
    this.menuModel.menuItemsSubject.next(this.menuItems);
  }

  private evaluateMenuItem(menuItem: any, pipelineGroupKey: any, pipelineKey: any, baKey: any): boolean {
    let enableCurrentItem: boolean = false;

    if (menuItem.hasOwnProperty('pipelineGroups') && menuItem.pipelineGroups.indexOf(pipelineGroupKey) > -1) {
      enableCurrentItem = true;
    }
    if (menuItem.hasOwnProperty('pipelines') && menuItem.pipelines.indexOf(pipelineKey) > -1) {
      enableCurrentItem = true;
    }
    if (menuItem.hasOwnProperty('businessAssociates') && menuItem.businessAssociates.indexOf(baKey) > -1) {
      enableCurrentItem = true;
    }
    return enableCurrentItem;
  }

  private updateMenu(userDetail: UserDetail): void {
    this.updateMenuItems(this.menuItems, userDetail);
  }

  private updateMenuItems = (menuItems: any, userDetail: UserDetail): void => {
    menuItems.forEach((menuItem: any) => {
      if (menuItem.auth && userDetail) {
        menuItem.authInfo = {
          auth: this.authService.getAuthorizationForAction(userDetail.currentContext, menuItem.auth),
        };
        menuItem.enabled = menuItem.authInfo.auth.enabled;
        menuItem.visible = menuItem.authInfo.auth.visible;
      } else {
        menuItem.enabled = false;
        menuItem.visible = false;
      }
      if (menuItem.featureFlags && this.featureFlagsService) {
        this.subscriptions.push(
          this.featureFlagsService.isFeatureActive(menuItem.featureFlags).subscribe((isActive: any) => {
            if (!isActive) {
              menuItem.enabled = false;
              menuItem.visible = false;
            }
          })
        );
      }
      if (userDetail && userDetail.currentContext) {
        if (menuItem.hasOwnProperty('pipelineGroups')
          || menuItem.hasOwnProperty('pipelines')
          || menuItem.hasOwnProperty('businessAssociates')) {
          let isEnabled: boolean = this.evaluateMenuItem(
            menuItem,
            userDetail.currentContext.assetGroupCd,
            userDetail.currentContext.assetAbbr,
            userDetail.currentContext.businessAssociateId
          );

          menuItem.enabled = menuItem.enabled && isEnabled;
          menuItem.visible = menuItem.visible && isEnabled;
        }
      }
      if (menuItem.children) {
        this.updateMenuItems(menuItem.children, userDetail);
      }
    });
  }
}
