<h1 matDialogTitle>Contract Contacts</h1>
<div mat-dialog-content>
  <div fxLayout="row" class="col-padding">

  <contract-contacts-common headLine="Nomination Analyst Contact"
                            baLabel="TSP"
                            [contractId]="contractId"
                            [contactObj]="contactData?.internalAnalystContact">
  </contract-contacts-common>

  <contract-contacts-common headLine="Notification Contact"
                            baLabel="Svc Req"
                            [contractId]="contractId"
                            [contactObj]="contactData?.notificationContact">
  </contract-contacts-common>

  <contract-contacts-common headLine="Nomination Contact"
                            baLabel="Svc Req Agent"
                            [contractId]="contractId"
                            [contactObj]="contactData?.nominationContact">
  </contract-contacts-common>

  </div>
</div>

<a href="{{noticeContactPageUrl}}" target="_blank">Contact Customer Service</a>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-raised-button
          type="button"
          color="primary"
          (click)="dialogRef.close()">CLOSE
  </button>
</div>
