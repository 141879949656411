import {Component, OnInit} from '@angular/core';
import { BaseDashComponent } from 'src/cad/dashboard/views/content/components/base-dash.component';
import { ContractPendingApprovalData } from 'src/cad/dashboard/interfaces/contract-pending-approval';

@Component({
  selector: 'contract-dash',
  templateUrl: './contract-dash.component.html',
})
export class ContractDashComponent extends BaseDashComponent<ContractPendingApprovalData> implements OnInit {
  public name: string = 'contract';
  public endPoint: string = 'agreement/contract/pending-approval-contract';

  public ngOnInit(): void {
    this.useDefaultLength = false;
    super.ngOnInit();
  }

  public getEmptyHeadline(): string {
    return 'No Pending Contracts';
  }
  public getDefaultHeadline(): string {
    return 'Contract Approvals';
  }
  public getName(): string {
    return this.name;
  }

  public onRowClicked = (event: any): void => {
    super.navigate([ 'contracts', (event.data.isContract === 'AMENDMENT') ? 'amendment' : 'contract', event.data.contractId, 'approval' ]);
  }
}
