import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class CommunicationApi {

  public readonly endpoint: string = 'administration/communication';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  // ========================================
  // Common methods
  // ========================================

  public getById = (server: any, msgId: any): Observable<any> => {
    return this.find({ msgId: Number(msgId), server });
  }

  public find = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/find', { body: params });
  }

  public filter = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public save = (msg: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/save', { body: msg });
  }

  public resendEmail = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/resend-email', {body: params});
  }

  public resendFax = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/resend-fax', {body: params});
  }
}
