import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AssociateContactApi } from 'cad/common/services/api/associate/contact/contact';
import { RouterService } from 'src/cad/core/services/router/router.service';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'ui-updater-field',
  templateUrl: './updater-field.component.html',
  styleUrls: [ './updater-field.component.less' ],
})
export class UpdaterFieldComponent implements OnInit, OnChanges, OnDestroy {

  @Input() userName: string;
  @Input() updateDate: any;
  @Input() label: string = 'Last updated by';
  @Input() noLink: boolean = false;

  public userData: any = {};
  public isUpdaterContact: boolean = false;
  updateDateStr: string;
  private contactPrimaryKey: string = 'contactId';
  private userPrimaryKey: string = 'contactId';
  // TODO Find a better way to navigate to a different item, Ex: from Physical Location Group to Data Admin Contact Info
  private contactDetailPage: string = '/data-administration/contact';
  private dateFormat: string = 'dddd, MMMM Do, YYYY hh:mm:ss A zz';
  private getUserByLoginSubsciption: Subscription;

  constructor(
    private router: RouterService,
    private contactApi: AssociateContactApi,
  ) {}

  public ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        let changeObj: SimpleChange = changes[ propName ];
        switch (propName) {
          case 'userName': {
            if (changeObj.currentValue !== changeObj.previousValue) {
              this.userData.contactPageId = undefined;
            }
            this.setUserData(changeObj.currentValue);
            break;
          }
          case 'updateDate': {
            if (!_.isNil(changeObj.currentValue)) {
              this.updateDateStr = moment(new Date(changeObj.currentValue.replace(/-\d\d\d\d/, ''))).isValid()
                ? moment(changeObj.currentValue).format(this.dateFormat).trim()
                : moment([ 1900, 0, 1 ]).format(this.dateFormat).trim();
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.getUserByLoginSubsciption) {
      this.getUserByLoginSubsciption.unsubscribe();
    }
  }

  public setUserData(username: string): void {
    if (username) {
      if (this.getUserByLoginSubsciption) {
        this.getUserByLoginSubsciption.unsubscribe();
      }
      this.getUserByLoginSubsciption = this.contactApi.getUserByLogin(username).subscribe((userDetails) => {
        // check for one unique user
        if (userDetails && userDetails.contactId) {
          let userObj = userDetails;
          _.assign(this.userData, userObj);
          if (!userObj.firstName && !userObj.lastName) {
            this.userData.fullName = _.clone(username);
          } else {
            this.userData.fullName = userObj.firstName + ' ' + userObj.lastName;
          }
          this.userData.contactPageId = this.contactDetailPage
            + '({ ' + this.contactPrimaryKey + ': ' + this.userData[this.userPrimaryKey] + ' })';
          this.isUpdaterContact = true;
        } else {
          this.isUpdaterContact = false;
        }
      });
    }
  }

  public navigateToContact = (): void => {
    this.router.navigate([ this.contactDetailPage, this.userData[this.userPrimaryKey] ]);
  }
}
