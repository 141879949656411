
import {debounceTime} from 'rxjs/operators';
import { AutoUnsubscriber, AutoUnsubscribables } from 'cad/shared/mixins/auto-unsubscriber.mixin';
import { Component, Input, Output, AfterContentInit, ContentChildren, QueryList, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';

import { Subscription } from 'rxjs';

@Component({
  selector: 'form-group-observer',
  template: '<ng-content></ng-content>',
})
export class FormGroupObserverComponent implements AfterContentInit {
  @Input() public groupValue: any;
  @Output() public groupValueChange: EventEmitter<any> = new EventEmitter<any>();

  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  @ContentChildren(NgModel, { descendants: true }) private models: QueryList<NgModel>;

  constructor() {}

  ngAfterContentInit(): void {
    this.models.forEach((model: NgModel) => {
      this.subs.newSub = model.control.valueChanges.pipe(
      debounceTime(500))
      .subscribe((newValue: any) => {
        if (model.control.dirty) {
          this.emitGroupModel();
        }
      });
    });
  }

  private emitGroupModel(): void {
    let isRowDirty: boolean = false;

    let formData = this.models.reduce((acc, model) => {
      acc[model.name] = model.value;
      if (model.touched || model.dirty) {
        isRowDirty = true;
      }
      return acc;
    }, {});

    if (isRowDirty) {
      this.groupValueChange.emit(formData);
    }
  }
}
