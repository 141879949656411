<ng-container *ngIf="mode === 'sort'; else filterTemplateRef">
  <div class="ag-cell-label-container sort-mode"
       role="presentation"
       (click)="onSortRequested($event)">
    <div ref="eLabel"
         class="ag-header-cell-label"
         role="presentation">
    <span ref="eText"
          class="ag-header-cell-text {{colDef?.headerClass}}"
          role="columnheader">
          {{params?.displayName  }}
          <mat-checkbox *ngIf="allowSelectAll" class="smaller-checkbox" [(ngModel)]="checkboxSelectAllState"
                (change)="updateCheckBoxRows($event)"
                (click)="$event.stopPropagation()"
                >
                </mat-checkbox>
      <span *ngIf="$any(colDef)?.required"
                                    class="required-col">*</span>
      <mat-icon *ngIf="$any(colDef)?.headerIcon"
                [class.header-icon-active]="this.headerIconIsActive"
                [class.header-icon-inactive]="!this.headerIconIsActive"
                matTooltip="{{ this.headerIconIsActive ? $any(colDef)?.headerActiveTooltip : $any(colDef)?.headerInactiveTooltip }}"
                (click)="iconClick(); $any(colDef)?.headerClick($event)">{{ $any(colDef)?.headerIcon }}</mat-icon>
      <span [class.ag-hidden]="!(params?.enableSorting)"
            class="sort-container">
        <mat-icon class="sort-indicator"
                  [ngClass]="sorted">arrow_drop_up
        </mat-icon>
        <span *ngIf="params?.showSortOrder"
              class="ag-sort-order"
              [class.ag-hidden]="!showSortIndex"
              aria-hidden="true">
          {{sortOrderValue}}
        </span>
      </span>
    </span>
    </div>
  </div>
</ng-container>
<ng-template #filterTemplateRef>
  <div class="ag-cell-label-container filter-mode"
       role="presentation"
       [matMenuTriggerFor]="menu"
       (menuOpened)="onMenuOpened()">
    <div ref="eLabel"
         class="ag-header-cell-label"
         role="presentation">
    <span ref="eText"
          class="ag-header-cell-text"
          role="columnheader"
          [ngClass]="colDef?.headerClass"
          [class.filtered-on]="isFilteredOn">
                          {{params?.displayName }}
                <mat-checkbox *ngIf="allowSelectAll" class="smaller-checkbox" [(ngModel)]="checkboxSelectAllState"
                (change)="updateCheckBoxRows($event)"
                (click)="$event.stopPropagation()"
                >
                </mat-checkbox>

      <span *ngIf="$any(colDef)?.required"
                                    class="required-col">*</span>
      <span [class.ag-hidden]="!(params?.enableSorting)"
            class="sort-container">
        <mat-icon class="sort-indicator"
                  [ngClass]="sorted">arrow_drop_up
        </mat-icon>
        <span *ngIf="params?.showSortOrder"
              class="ag-sort-order"
              [class.ag-hidden]="!showSortIndex"
              aria-hidden="true">
          {{sortOrderValue}}
        </span>
      </span>
    </span>
    </div>
  </div>
  <mat-menu #menu="matMenu"
            [overlapTrigger]="false"
            class="header-menu-parent">
    <div fxLayout="row"
         class="header-menu"
         (click)="$event.stopPropagation()">
      <div fxFlex="100">
        <div fxLayout="column"
             fxLayoutGap="14px">
          <div fxLayout="row"
               fxLayoutGap="8px"
               fxLayoutAlign="start center"
               class="sort-menu-container">
            <div fxFlex
                 fxLayout="row"
                 class="sort-row-content">
              <span fxFlex>Sort</span>
              <span *ngIf="params?.showSortOrder"
                    class="ag-sort-order"
                    [class.ag-hidden]="!showSortIndex"
                    aria-hidden="true">
                {{sortOrderValue}}
              </span>
              <mat-icon class="sort-indicator"
                        [ngClass]="sorted">arrow_drop_up
              </mat-icon>
            </div>
            <button mat-button
                    mat-icon-button
                    class="menu-action-button"
                    (click)="onSortRequested($event)"
                    [disabled]="!(params?.enableSorting)">
              <mat-icon>sort</mat-icon>
            </button>
          </div>
          <table-header-filter-criteria #filterContainerRef
                                        [colDef]="colDef"
                                        [columnType]="columnType"
                                        [(dateRangeTo)]="dateRangeTo1"
                                        [(filterType)]="filterType1"
                                        [(filterCriteria)]="filterCriteria1"
                                        [filterTypeList]="filterTypeList"
                                        (onFilterChange)="onFilter($event)"
                                        (onClearFilter)="onClearFilter(true)">
          </table-header-filter-criteria>
          <ng-container *ngIf="showSecondCondition">
            <mat-radio-group fxLayout="row"
                             fxLayoutGap="20px"
                             [(ngModel)]="filterOperator"
                             (change)="onFilter(filterCriteria1)"
                             name="filterOperator">
              <mat-radio-button [value]="'AND'"
                                aria-label="AND">AND
              </mat-radio-button>
              <mat-radio-button [value]="'OR'"
                                aria-label="OR">OR
              </mat-radio-button>
            </mat-radio-group>
            <table-header-filter-criteria [colDef]="colDef"
                                          [columnType]="columnType"
                                          [(dateRangeTo)]="dateRangeTo2"
                                          [(filterType)]="filterType2"
                                          [(filterCriteria)]="filterCriteria2"
                                          [filterTypeList]="filterTypeList"
                                          (onFilterChange)="onFilter($event)"
                                          (onClearFilter)="onClearFilter(false)">
            </table-header-filter-criteria>
          </ng-container>
        </div>
      </div>
    </div>
  </mat-menu>
</ng-template>
