import { Inject, Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

import { Breadcrumb } from 'src/features/common/breadcrumb/breadcrumb';
import { BreadcrumbFactory } from 'src/features/common/breadcrumb/breadcrumb.model';
import { ItemDetailController } from 'src/features/common/item-detail/item-detail.controller';

// TODO:  Fix CLI complaining that it can't resolve the arg for this class
@Injectable()
export class ItemDetailBreadcrumb implements BreadcrumbFactory {
  private breadcrumb$: Subject<Breadcrumb> = new Subject<Breadcrumb>();

  constructor(
    @Inject('itemData') private breadcrumbGenerator: ((itemData: any) => string),
  ) {
    this.breadcrumb$.next({ label: '...' });
  }

  public updateBreadcrumb(itemData: any, link: any): void {
    this.breadcrumb$.next({
      label: itemData ? this.breadcrumbGenerator(itemData) : '...',
      link,
    });
  }

  public breadcrumb(): Breadcrumb | Observable<Breadcrumb> | Promise<Breadcrumb> {
    return this.breadcrumb$.asObservable();
  }
}
