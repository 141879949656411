import { Injector, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AssociationController } from 'src/features/common/association/association.controller';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AssociationApi } from 'src/features/common/association/association-api';

@Injectable()
export class PhysicalMeterLocationApi implements AssociationApi {
  private readonly endpoint: string = 'physical/meters/';
  private readonly suffix: string = '/locations';

  static createAssociationApi(
    apiHelper: ApiHelper,
    associationController: AssociationController,
    injector: Injector,
  ): AssociationApi {
    return new PhysicalMeterLocationApi(apiHelper);
  }

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public findAll = (meterId: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + meterId + this.suffix);
  }

  public save = (associations: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + associations[0].pointCd + this.suffix + '/save', { body: associations });
  }
}
