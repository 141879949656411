import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {ActionMenu} from 'src/ag-grid-wrapper/table/action-menu';
import {ScheduledQuantityForOperatorActionService} from 'cad/nominations/scheduled-quantity/shared/scheduled-quantity-for-operator-action.service';

@Component({
  selector: 'allocation-schedule-quantity-header',
  templateUrl: 'header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AllocationScheduleQuantityHeaderComponent implements OnInit {
  @Input() data: any;
  actionMenu:ActionMenu;

  constructor(private actionService: ScheduledQuantityForOperatorActionService ) { }

  ngOnInit(): void {
    this.actionMenu = this.actionService.getScheduledQuantityHeaderActionMenu();
  }

}
