import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RouterService } from 'src/cad/core/services/router/router.service';
import { SecurityLoginApi } from 'src/cad/common/services/api/security/login/login';
import { UserService2 } from 'src/cad/common/store/user/services/user.service';
import { RootObject } from 'cad/common/store/user/user-model';
import { switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'run-as-user-dialog',
  templateUrl: './run-as-user.component.html',
})
export class RunAsUserDialogComponent implements OnInit, OnDestroy {
  public username: string;
  runAsUser: boolean = false;
  public alerts: cad.Alert[] = [];
  private subscriptions: Subscription[] = [];
  
  constructor(
    private router: RouterService,
    private userService: UserService2,
    private securityApi: SecurityLoginApi  ) {}

  ngOnInit(): void {
    const { runAsUser } = this.userService.getUserStoreState();
    this.runAsUser = !!runAsUser;
    this.username = runAsUser;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private onLogin = (userData: RootObject[]): void => {
    if (userData && userData.length) {
      this.router.navigate([ '/dashboard' ], {queryParams: {li: 1} });
    } else {
      console.error('Invalid user data return from login call');
    }
  }

  public runAsSubmit(): void {
    this.userService.setRunAsUser(this.username);
    this.subscriptions.push(this.securityApi.loginUser(this.username, null)
      .pipe(switchMap((userData : RootObject[]) => {
        try {
          const assetName = userData[0].availableassets[0].name;
          const baName = userData[0].availablecontexts[assetName][0];
          return this.userService.setUserContextObs(assetName, baName)
            .pipe(map(() => {
              return userData;
            }));
        } catch (e) {
          throw new Error(`Run as User Error: ${e}`);
        }
      }))
      .subscribe(
        (userData) => {
          this.onLogin(userData);
        },
        (err: Error) => {
          console.error(err.message ? err.message : err);
          this.onLogin(null);
        }
      ));
  }

  public clearRunAs = (): void => {
    this.userService.setRunAsUser(null);
    this.subscriptions.push(this.securityApi.loginUserNTLM().subscribe(this.onLogin));
  }

  public cancel = (): void => {
    this.router.navigate([ '/dashboard' ], { queryParams: { nl: 1 } });
  }

  private addAlert = (message: string, type?: string): void => {
    this.alerts.push({ type: type || 'danger', msg: message });
  }

}
