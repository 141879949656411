/**
 * Created by jodell on 5/31/2017.
 */
import { ViewEncapsulation, Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';

@Component({
  selector: 'row-visual-validation-component',
  templateUrl: './validation-icon.component.html',
  styleUrls: [ './validation-icon.component.less' ],
})
export class RowVisualValidationComponent implements AgRendererComponent {

  params: any;
  private isError: boolean = false;
  private validationToolTip: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}

