import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, Type, ComponentRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RowNode, Events, RowDataChangedEvent, DisplayedColumnsChangedEvent } from '@ag-grid-community/core';
import { UiGridApi } from 'src/ag-grid-wrapper';
import { ActionService } from 'src/cad/common/services/security/action.service';
import { RowConfirmActionComponent } from 'src/ag-grid-wrapper/row/confirm-action/confirm-action.component';
import { AutoUnsubscriber, AutoUnsubscribables } from 'cad/shared/mixins/auto-unsubscriber.mixin';
import * as _ from 'lodash';

@Component({
  selector: 'ui-action-card',
  templateUrl: './action-card.component.html',
  styleUrls: [ './action-card.component.less' ],
  exportAs: 'uiActionCard'
})
export class UiActionCardComponent implements OnInit, OnDestroy {

  @Input() public cardId: string;
  @Input() public cardTitle: string = 'Items';
  @Input() public itemName: string = 'Item';
  @Input() public contentComponent: Type<any>;
  @Input() public showAdd: boolean = true;
  @Input() public isAddEnabled: boolean;
  @Input() public enableMax: boolean = true;
  @Input() public isFullHeight: boolean = false;
  @Input() public readOnly: boolean = false;
  @Input() public allowEdit: boolean = true;
  @Input() public skipDisable: boolean;
  // This is to override the security context of the current route's SecurityFeature data, we may have some cases
  // where the card may need security from a different vertical, ie businessassociate in contracts.
  // 'securityContext' === null || undefined: The save and delete will NOT use security context and will be
  // enabled.
  // 'securityContext' === '': (Default) The save and delete will use the security context of the current
  // route's SecurityFeature to be enabled/disabled.
  @Input() public securityContext: string = '';
  @Input() public bypassErrors: boolean = false;
  @Input() public context: string = 'ui-content';
  @Input() public enableExpand: boolean = true;
  @Input()
  public get gridApi(): UiGridApi {
    return this._gridApi;
  }
  public set gridApi(value: UiGridApi) {
    if (this._gridApi) {
      this._gridApi.removeEventListener(Events.EVENT_ROW_DATA_CHANGED, this.onRowDataChangedFn);
    }
    this._gridApi = value;
    if (this._gridApi) {
      this._gridApi.addEventListener(Events.EVENT_ROW_DATA_CHANGED, this.onRowDataChangedFn);
      this._gridApi.addEventListener(Events.EVENT_TOOL_PANEL_VISIBLE_CHANGED, this.onDisplayedColumnsChangedFn);
    }
  }
  private openPanelStatus: boolean = false;
  @Input() public cardToolBarItems: any[] = [
    {
      name: 'tool panel',
      action: 'table',
      icon: 'table_chart',
      method: this.showToolPanel.bind(this),
      isDisabled: this.areToolPanelDisabled.bind(this),
      isShow: this.areToolPanelHidden.bind(this),
    },
    {
      name: 'Undo',
      action: 'undo',
      icon: 'undo',
      method: this.revertChanges.bind(this),
      isDisabled: this.areActionsDisabled.bind(this)
    },
    {
      name: 'Save',
      action: 'save',
      icon: 'save',
      method: this.saveCardData.bind(this),
      isDisabled: (): boolean => !this.canPerformAction(cad.actionConstants.ACTION_SAVE)
      || (this.disableOnError() || this.areActionsDisabled())
    }
  ];
  @Input() public rowActionMenu: any;
  @Output() public addClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() public undoClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() public saveClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() public rowDeleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() public gridToolPanelClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() public showToolPanelStatus: boolean = false;
  public gridDataClone: any[];
  public contentComponentRef: ComponentRef<any>;
  public onRowDataChangedFn: (event: RowDataChangedEvent) => void = this.onRowDataChanged.bind(this);
  /*ui table is reset after coloumn toolpanel is closed*/
  public onDisplayedColumnsChangedFn: (event: DisplayedColumnsChangedEvent) => void
    = this.onDisplayedColumnsToolPanelChanged.bind(this);
  private _gridApi: UiGridApi;
  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  constructor(
    public actionService: ActionService,
    public matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (!this.rowActionMenu) {
      this.rowActionMenu = {
        title: 'Manage ' + this.itemName,
        items: [
          {
            name: 'Delete ' + this.itemName,
            icon: 'delete',
            method: this.confirmDeleteRow.bind(this),
            isDisabled: this.deleteRowItemDisabled.bind(this)
          }
        ]
      };
    }
  }

  ngOnDestroy(): void {
    if (this.gridApi) {
      this.gridApi.removeEventListener(Events.EVENT_ROW_DATA_CHANGED, this.onRowDataChangedFn);
      this.gridApi = null;
    }
    this.addClicked.complete();
    this.undoClicked.complete();
    this.saveClicked.complete();
    this.rowDeleted.complete();
    this.gridToolPanelClicked.complete();
    if (this.contentComponentRef) {
      this.contentComponentRef.destroy();
      this.contentComponentRef = null;
    }
    this.addClicked = null;
    this.undoClicked = null;
    this.saveClicked = null;
    this.rowDeleted = null;
    this.gridToolPanelClicked = null;
  }

  public onContentReady(compRef: ComponentRef<any>): void {
    if (compRef) {
      this.contentComponentRef = compRef;
      if (this.contentComponentRef.instance.gridApi) {
        this.gridApi = this.contentComponentRef.instance.gridApi;
      }
      this.contentComponentRef.instance.uiGridCardRef = this;
      this.contentComponentRef.onDestroy(() => {
        if (this.gridApi) {
          this.gridApi.removeEventListener(Events.EVENT_ROW_DATA_CHANGED, this.onRowDataChangedFn);
          this.gridApi = null;
        }
        if (this.contentComponentRef.instance && this.contentComponentRef.instance.uiGridCardRef) {
          this.contentComponentRef.instance.uiGridCardRef = null;
        }
      });
    }
  }

  public onRowDataChanged(event: RowDataChangedEvent): void {
    if (_.isArray(this.gridDataClone)) {
      this.gridDataClone.splice(0);
    }
    if (event && event.api && (event.api as UiGridApi).rowEdit) {
      this.gridDataClone = _.cloneDeep((event.api as UiGridApi).rowEdit.getAllRowsData());
      this.resetFlags();
    }
  }

  public onDisplayedColumnsToolPanelChanged(event: DisplayedColumnsChangedEvent): void {
    this.evaluateColumnsToolPanelVisibility(event.api);
  }

  public evaluateColumnsToolPanelVisibility(api: any): void {
    if (api.isSideBarVisible()) {
      api.sizeColumnsToFit();
    }
  }

  public revertChanges(): void {
    if (this.gridApi) {
      if (this.gridApi.rowEdit && this.gridApi.rowEdit.hasEditingCells()) {
        this.gridApi.stopEditing();
      }
      if ((this.gridApi as any).gridOptionsWrapper.gridOptions.undoRedoCellEditing) {
        this.gridApi.undoCellEditing();
      } else if (this.gridApi && _.isArray(this.gridDataClone)) {
        if (this.gridApi.rowEdit && this.gridApi.rowEdit.hasEditingCells()) {
          this.gridApi.stopEditing();
        }
        this.resetFlags();
        (this.gridApi as any).gridOptionsWrapper.gridOptions.rowData.splice(0);
        if (_.isArray(this.gridDataClone)) {
          this.gridDataClone.forEach((gridData: any) => {
            (this.gridApi as any).gridOptionsWrapper.gridOptions.rowData.push(gridData);
          });
          this.gridApi.setRowData(this.gridDataClone);
        }
      }
    }
    this.undoClicked.emit(true);
  }

  public showToolPanel(): void {
    if (!this.openPanelStatus) {
      this.gridApi.openToolPanel('columns');
      this.openPanelStatus = true;
    } else {
      this.gridApi.closeToolPanel();
      this.openPanelStatus = false;
    }
  }

  public areToolPanelDisabled(): boolean {
    return false;
  }

  public areToolPanelHidden(): boolean {
    return this.showToolPanelStatus;
  }

  public resetFlags(): void {
    if (this.gridApi && this.gridApi.rowEdit) {
      this.gridApi.rowEdit.setGridClean();
      this.gridApi.rowEdit.dirtyRows.splice(0);
      this.gridApi.rowEdit.errorRows.splice(0);
    }
  }

  public disableOnError(): boolean {
    if(this.bypassErrors) {
      return false;
    }
    return this.hasErrorRows();
  }
  public areActionsDisabled(): boolean {
    if (this.allowEdit) {
      if (this.gridApi && this.gridApi.rowEdit) {
        return !(this.hasDirtyRows() || this.gridApi.rowEdit.hasEditingCells());
      }
    }
    return !this.allowEdit;
  }

  public canPerformAction(action: any): boolean {
    if (_.isNil(this.securityContext)) {
      return true;
    }
    return (this.actionService && !_.isNil(action))
      ? this.actionService.canPerformAction(action, this.securityContext)
      : false;
  }

  public hasDirtyRows(): boolean {
    return (this.gridApi
    && this.gridApi.rowEdit
    && this.gridApi.rowEdit.hasDirtyRows());
  }

  public hasErrorRows(): boolean {
    return (this.gridApi
    && this.gridApi.rowEdit
    && this.gridApi.rowEdit.hasErrorRows());
  }

  public onAddClicked(event: any): void {
    if (!this.readOnly && this.isAddEnabled) {
      this.addClicked.emit(event);
    }
  }

  public saveCardData(): void {
    if (this.readOnly || !this.canPerformAction(cad.actionConstants.ACTION_SAVE)) {
      return;
    }
    if (this.gridApi && this.gridApi.rowEdit) {
      if (this.gridApi.rowEdit.hasEditingCells()) {
        this.gridApi.stopEditing();
      }
      setTimeout(() => this.saveClicked.emit(
        _.unionWith(this.gridApi.rowEdit.getAllRowsData(), this.getDeleteRowsData(), _.isEqual)
      ));
    } else {
      setTimeout(() => this.saveClicked.emit(true));
    }
  }

  public confirmDeleteRow(rowData: any): void {
    if (this.readOnly || !this.canPerformAction(cad.actionConstants.ACTION_SAVE)) { return; }
    if (this.matDialog) {
      const dialogRef: MatDialogRef<RowConfirmActionComponent> = this.matDialog.open(RowConfirmActionComponent, {
        data: {
          action: 'delete'
        }
      });
      this.subs.newSub = dialogRef.afterClosed().subscribe((shouldDelete) => {
        if (shouldDelete) {
          this.deleteRowItem(rowData);
        }
      });
    }
  }

  public deleteRowItem(rowData: any): void {
    let gridDirtyRows: RowNode[];
    let existingDirtyRow: RowNode;

    if (this.readOnly || !this.canPerformAction(cad.actionConstants.ACTION_SAVE)) { return; }
    if (this.gridApi
      && this.gridApi.rowEdit
      && rowData) {
      gridDirtyRows = this.gridApi.rowEdit.getDirtyRows();
      if (rowData.dirtyStatus === cad.DirtyStatus.NEW) {
        existingDirtyRow = _.find<RowNode>(gridDirtyRows, { data: rowData });
        if (existingDirtyRow) {
          this.gridApi.rowEdit.setRowsClean([ existingDirtyRow ]);
        }
        this.gridApi.rowEdit.deleteRowByData(rowData);
      } else {
        let existingRow: RowNode = _.find<RowNode>(this.gridApi.rowEdit.getAllLeafRowNodes(), { data: rowData });
        if (existingRow) {
          existingRow.data.dirtyStatus = cad.DirtyStatus.DELETE;
          this.gridApi.rowEdit.setDirtyRow(existingRow);
          this.rowDeleted.emit(existingRow.data);
        }
      }
    }
  }

  public deleteRowItemDisabled(): boolean {
    return !this.canPerformAction(cad.actionConstants.ACTION_SAVE) || !this.allowEdit;
  }

  public getDeleteRowsData(): any[] {
    if (this.gridApi
      && this.gridApi.rowEdit
      && this.gridApi.rowEdit.hasDirtyRows()) {
      // return _.filter(this.gridApi.rowEdit.getDirtyRowsData(), { dirtyStatus: cad.DirtyStatus.DELETE });
      return this.gridApi.rowEdit.getDirtyRowsData().filter((item) => item.dirtyStatus === cad.DirtyStatus.DELETE);
    }
    return [];
  }
}
