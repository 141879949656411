import { Injectable } from '@angular/core';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import * as _ from 'lodash';
import { ColumnState, RowNode } from '@ag-grid-community/core';

export interface ISearchService {
  getCachedSearch(context: string): SearchResult;
  cacheSearch(results: any[], searchParms: any, context: string): void;
  removeFromCache(ctrl: any): void;
  clearSearchResult(): void;
  getGridState(context: string): GridState;
  setGridState(context: string,
               selected: RowNode,
               sortModel: {
                 colId: string;
                 sort: string;
               }[],
               filterModel: any,
               columnState: ColumnState[],
               columnGroupState: ({
                 groupId: string;
                 open: boolean;
               })[]): void;
}

export class GridState {

  constructor(
    private context: string,
    private assetNbr: any,
    private selected: RowNode,
    private sortModel: {
      colId: string;
      sort: string;
    }[],
    private filterModel: any,
    private columnState: ColumnState[],
    private columnGroupState: ({
      groupId: string;
      open: boolean;
    })[]
  ) {}

  public clear(): void {
    this.context = '';
    this.assetNbr = null;
    this.setSortModel([]);
    this.setFilterModel({});
    this.setColumnState(null);
    this.setColumnGroupState(null);
  }

  public getContext(): string {
    return this.context;
  }

  public getAssetNbr(): any {
    return this.assetNbr;
  }

  public getSortModel(): {
    colId: string;
    sort: string;
  }[] {
    return this.sortModel;
  }

  public setSortModel(value: {
    colId: string;
    sort: string;
  }[]): void {
    this.sortModel = value;
  }

  public getSelectedRowNode(): RowNode {
    return this.selected;
  }

  public setSelectedRowNode(selected: RowNode): void {
    this.selected = selected;
  }
  public getFilterModel(): any {
    return this.filterModel;
  }

  public setFilterModel(value: any): void {
    this.filterModel = value;
  }

  public getColumnState(): ColumnState[] {
    return this.columnState;
  }

  public setColumnState(value: ColumnState[]): void {
    this.columnState = value;
  }

  public getColumnGroupState(): ({
    groupId: string;
    open: boolean;
  })[] {
    return this.columnGroupState;
  }

  public setColumnGroupState(value: ({
    groupId: string;
    open: boolean;
  })[]): void {
    this.columnGroupState = value;
  }
}

export class SearchResult {

  constructor(
    private context: string,
    private assetNbr: any,
    private results: any[],
    private searchParams: any
  ) {}

  public clear(): void {
    this.context = '';
    this.assetNbr = null;
    this.results = [];
    this.searchParams = {};
  }

  public getSearchParams(): any {
    return this.searchParams;
  }

  public getResults(): any[] {
    return this.results;
  }

  public getContext(): string {
    return this.context;
  }

  public getAssetNbr(): any {
    return this.assetNbr;
  }
}

@Injectable()
export class SearchService implements ISearchService {

  private searchResult: SearchResult;
  private gridState: GridState;

  constructor(private userModelService: UserModelService) {}

  public getCachedSearch(context: string): SearchResult {
    if (this.searchResult
      && context === this.searchResult.getContext()
      && this.searchResult.getAssetNbr() === this.getAssetNumber()) {
      return this.searchResult;
    }
    this.clearSearchResult();
    return this.searchResult;
  }

  public cacheSearch(results: any[], searchParams: any, context: string): void {
    this.searchResult = new SearchResult(
      context,
      this.getAssetNumber(),
      _.clone(results),
      _.clone(searchParams)
    );
  }

  public removeFromCache(ctrl: any): void {
    let primKey: any;
    let searchResult: SearchResult;

    if (ctrl && ctrl.itemConfig) {
      if (!_.isNil(ctrl.itemConfig.primaryKey)) {
        primKey = !_.isNil(ctrl.itemConfig.primaryKey)
          ? ctrl.itemConfig.primaryKey
          : null;
        searchResult = this.getCachedSearch(ctrl.itemConfig.moduleName);
        _.remove(searchResult.getResults(), (item: any) => {
          return (!_.isNil(primKey)
          && ctrl.itemData
          && item[ primKey ] === ctrl.itemData[ primKey ]);
        });
      }
    }
  }

  public clearSearchResult(): void {
    if (this.searchResult) {
      this.searchResult.clear();
    }
  }

  public getGridState(context: string): GridState {
    if (this.gridState
      && context === this.gridState.getContext()
      && this.gridState.getAssetNbr() === this.getAssetNumber()) {
      return this.gridState;
    }
    this.setGridState(context);
    return this.gridState;
  }

  public setGridState(
    context: string,
    selected?: RowNode,
    sortModel?: {
      colId: string;
      sort: string;
    }[],
    filterModel?: any,
    columnState?: ColumnState[],
    columnGroupState?: ({
      groupId: string;
      open: boolean;
    })[]
  ): void {
    this.gridState = new GridState(
      context,
      this.getAssetNumber(),
      selected,
      sortModel ? _.cloneDeep(sortModel) : [],
      filterModel ? _.cloneDeep(filterModel) : {},
      columnState ? _.cloneDeep(columnState) : null,
      columnGroupState ? _.cloneDeep(columnGroupState) : null
    );
  }

  private getAssetNumber(): number {
    return this.userModelService ? this.userModelService.getCurrentAssetNbr() : null;
  }
}
