import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

import { AlertsService } from 'cad/core/services/alerts/alerts.service';


@Component({
  templateUrl: './alerts-snackbar.component.html',
  styleUrls: [ './alerts-snackbar.component.less' ],
})
export class AlertsSnackbarComponent {

  public alerts: cad.Alert[] = [];

  public sbRef: MatSnackBarRef<AlertsSnackbarComponent>; // set from AlertsSidenavComponent after AlertsSnackbarComponent constructed

  constructor(
    private alertsService: AlertsService,
  ) {}

  public dismiss(): void {
    const alerts = _.clone(this.alerts); // clone first as this.alerts is modified during loop
    alerts.forEach((alert: cad.Alert) => this.alertsService.dismissSnackbarAlert(alert));
    this.sbRef.dismiss();
  }
}
