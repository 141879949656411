import { UserModelService } from 'cad/common/services/user/user-model-service';
import { ModalController } from 'src/features/common/modal/modal.controller';
import { NominationMatrixContractPointApi } from 'cad/common/services/api/nomination/nomination-matrix/contract/point/nomination-matrix-contract-point.api';
import { ModalRegistryRegistrationService } from 'cad/core/services/modal-registry/modal-registry-registration.service';
import { LookupInputDefinition } from 'src/features/common/modal/modal-feature-types';
import { ModalModel } from 'src/features/common/modal/modal.model';
import { Injectable } from '@angular/core';
import { cloneDeep, assign } from 'lodash';
import { DynamicObject } from 'common/types/dynamic-object';
import { ModalConfigs } from 'cad/core/services/modal-registry/modal-configs';
import {ProducerApi} from 'cad/common/services/api/nomination/nomination-matrix/producer/producer-api';
import {CodeValueData} from 'cad/shared/interfaces/code-value-data';

@Injectable()
export class ModalControllerFactoryService {
  private readonly pointLookupModalHeaderLabels: DynamicObject = {
    FG : {
      tabsAreaCd: 'MKT/WD'
    },
    defaultGroup : {
      tabsAreaCd: 'TABS'
    }
  };

  private readonly contractLookupModalHeaderLabels: DynamicObject = {
    FG : {
      mdcq: 'MDTQ'
    },
    defaultGroup : {
      mdcq: 'MDCQ/MSQ'
    }
  };

  private readonly contractLookupModalColumnVisibility: DynamicObject = {
    FG : {
      mrpl: false,
      mdpl: false,
    },
    defaultGroup : {
      mrpl: true,
      mdpl: true,
    }
  };

  constructor(
    private userModelService: UserModelService,
    private modalService: ModalRegistryRegistrationService
    ) {
  }

  public isInternalUser(): boolean {
    return this.userModelService.isUserInternal() === 'Y';
  }

  public getReceiptLocationModalController(): ModalController {
    // Get default settings
    let config = cloneDeep(ModalConfigs.nomContractPointLookupModel);

    // Override some defaults
    assign(config, {
      title: 'Receipt Location Lookup',
      name: 'receiptPointCd',
      type: 'lookup',

      returnObjMap: {
        receiptPointCd: 'pointCd',
        receiptPtName: 'pointName'
      },
      api: NominationMatrixContractPointApi,
    });

    // Update column header labels based on asset group
    const assetGroupCd = this.userModelService.getCurrentContext().assetGroupCd;
    const settings = this.pointLookupModalHeaderLabels[assetGroupCd] ? this.pointLookupModalHeaderLabels[assetGroupCd] : this.pointLookupModalHeaderLabels.defaultGroup;
    config = this.updateColumnHeaderNames(config, settings);
    config = this.autoForOperator(config);


    return this.modalService.setModalController(config);
  }

  getModalController(model: ModalModel): ModalController {
    let config = cloneDeep(model);
    assign(config, { autoSearch: false });
    if(this.userModelService.isUserInternal() === 'N') {
      config = this.autoForOperator(config);
    }
    return this.modalService.setModalController(config);
  }

  public getDeliveryLocationModalController(): ModalController {
    // Get default settings
    let config = cloneDeep(ModalConfigs.nomContractPointLookupModel);

    // Override some defaults
    assign(config, {
      title: 'Delivery Location Lookup',
      name: 'deliveryPointCd',
      type: 'lookup',
      returnObjMap: {
        deliveryPointCd: 'pointCd',
        deliveryPtName: 'pointName'
      },
      api: NominationMatrixContractPointApi,
    });

    // Update column header labels based on asset group
    const assetGroupCd = this.userModelService.getCurrentContext().assetGroupCd;
    const settings = this.pointLookupModalHeaderLabels[assetGroupCd] ? this.pointLookupModalHeaderLabels[assetGroupCd] : this.pointLookupModalHeaderLabels.defaultGroup;
    config = this.updateColumnHeaderNames(config, settings);
    config = this.autoForOperator(config);

    return this.modalService.setModalController(config);
  }



  public getConfirmationPointLookupModalController(): ModalController {
    let config = cloneDeep(ModalConfigs.confirmationPointLookupModel);

    if(this.userModelService.isUserInternal() === 'N') {
      config = this.autoForOperator(config);
    } else {
      config.lookupInputs.inputs = config.lookupInputs.inputs.map(this.removeDefaultFilter);
    }
    return this.modalService.setModalController(config);
  }

  public getUpKModalController(): ModalController {
    // Get default settings
    let config = cloneDeep(ModalConfigs.contractLookupModel);

    // Override some defaults
    assign(config, {
      title: 'Up K Lookup',
      name: 'receiptRef2Cd',
      type: 'lookup',
      autoSearch: false,
      returnObjMap: {
        value: 'contractId',
      },
      transformObjFn: (data: any) => {
        return {
          receiptRef2Cd: data.value ? data.value.toString() : '',
        };
      }
    });

    config = this.updateContractLookupConfig(config);

    if(this.userModelService.isUserInternal() === 'N') {
      config = this.autoForOperator(config);
    }

    return this.modalService.setModalController(config);
  }

  public getDnKModalController(): ModalController {
    // Get default settings
    let config = cloneDeep(ModalConfigs.contractLookupModel);

    // Override some defaults
    assign(config, {
      title: 'Down K Lookup',
      name: 'deliveryRef2Cd',
      type: 'lookup',
      autoSearch: false,
      returnObjMap: {
        value: 'contractId',
      },
      transformObjFn: (data: any) => {
        return {
          deliveryRef2Cd: data.value ? data.value.toString() : '',
        };
      }
    });

    config = this.updateContractLookupConfig(config);

    if(this.userModelService.isUserInternal() === 'N') {
      config = this.autoForOperator(config);
    }

    return this.modalService.setModalController(config);
  }

  public getUpNameModalController(): ModalController {
    let config = cloneDeep(ModalConfigs.businessAssociateItemModel);
    assign(config, {
      title: 'Business Associate Lookup',
      name: 'receiptRef1Cv',
      type: 'lookup',
      autoSearch: false,
      returnObjMap: {
        value: 'baName',
        cd: 'baId'
      },
      transformObjFn: (data: any) => {
        return {
          receiptRef1Name: data.value,
          receiptRef1Cd: data.cd ? data.cd.toString(): '',
          receiptRef1Cv: data
        };
      }
    });

    if(this.userModelService.isUserInternal() === 'N') {
      config = this.autoForOperator(config);
    }

    return this.modalService.setModalController(config);
  }

  public getUpNameProducerModalController(): ModalController {
    let config = cloneDeep(ModalConfigs.businessAssociateItemModel);
    assign(config, {
      title: 'Producer Lookup',
      api: ProducerApi,
      name: 'receiptRef1Cv',
      type: 'lookup',
      autoSearch: true,
      lookupInputs: {
        inputs: [
          { type: 'text', propertyName: 'producerName', name: 'Name', hideInForm: true },
          { type: 'text', propertyName: 'producerAbbrName', name: 'Abbr.' , hideInForm: true},
          { type: 'numberStr', propertyName: 'producerBANbr', name: 'BA Nbr.', hideInForm: true},
          { type: 'text', propertyName: 'producerDUNSNbr', name: 'Duns Nbr', hideInForm: true },
          { type: 'text', propertyName: 'producerAgentName', name: 'Agent Name', hideInForm: true },
          { type: 'text', propertyName: 'producerAgentAbbrName', name: 'Agent Abr Name', hideInForm: true },
          { type: 'text', propertyName: 'producerAgentBANbr', name: 'Agent BA Nbr', hideInForm: true },
          { type: 'text', propertyName: 'producerAgentDUNSNbr', name: 'Agent Duns Nbr', hideInForm: true },
        ]
      },
      returnObjMap: {
        value: 'producerName',
        cd: 'producerBANbr'
      },
      transformObjFn: (data: CodeValueData) => {
        return {
          receiptRef1Name: data.value,
          receiptRef1Cd: data.cd ? data.cd.toString(): '',
          receiptRef1Cv: data
        };
      }
    });

    if(this.userModelService.isUserInternal() === 'N') {
      config = this.autoForOperator(config);
    }

    return this.modalService.setModalController(config);
  }

  public getDnNameModalController(): ModalController {
    let config = cloneDeep(ModalConfigs.businessAssociateItemModel);
    assign(config, {
      title: 'Business Associate Lookup',
      name: 'deliveryRef1Cv',
      type: 'lookup',
      autoSearch: false,
      returnObjMap: {
        value: 'baName',
        cd: 'baId'
      },
      transformObjFn: (data: any) => {
        return {
          deliveryRef1Cd: data.cd ? data.cd.toString() : '',
          deliveryRef1Name: data.value,
          deliveryRef1Cv: data
        };
      }
    });

    if(this.userModelService.isUserInternal() === 'N') {
      config = this.autoForOperator(config);
    }

    return this.modalService.setModalController(config);

  }

  public getReceiptNominationsContractLookupController(): ModalController {
    return this.modalService.setModalController(ModalConfigs.receiptNominationsContractLookupModel);
  }

  public getPinnedItemsContractLookupController(): ModalController {
    // Get default settings
    let config = cloneDeep(ModalConfigs.contractLookupModel);
    config.showSearchButton = true;
    config = this.updateContractLookupConfig(config);

    if(this.userModelService.isUserInternal() === 'N') {
      config = this.autoForOperator(config);
    }
    return this.modalService.setModalController(config);
  }

  private updateContractLookupConfig(config: ModalModel ): ModalModel {
    let updateConfig = cloneDeep(config);
    // Update column header labels based on asset group
    const assetGroupCd = this.userModelService.getCurrentContext().assetGroupCd;
    const settings = this.contractLookupModalHeaderLabels[assetGroupCd] ? this.contractLookupModalHeaderLabels[assetGroupCd] : this.contractLookupModalHeaderLabels.defaultGroup;
    updateConfig = this.updateColumnHeaderNames(updateConfig, settings);

    // Update column visibility based on asset group
    const visibilitySetting = this.contractLookupModalColumnVisibility[assetGroupCd] ? this.contractLookupModalColumnVisibility[assetGroupCd] : this.contractLookupModalColumnVisibility.defaultGroup;
    return this.updateColumnsVisibility(updateConfig, visibilitySetting);
  }

  private updateColumnHeaderNames( config:ModalModel, settings: any): ModalModel {
    const updateConfig = cloneDeep(config);
    const inputs: LookupInputDefinition[] = updateConfig.lookupInputs.inputs;

    Object.keys(settings).forEach((columnName)=> {
      const input:LookupInputDefinition = inputs.find( (x) => x.propertyName === columnName );
      if (input && settings[columnName]) {
        input.name = settings[columnName];
      }
    });
    return updateConfig;
  }

  private updateColumnsVisibility( config:ModalModel, settings: any): ModalModel {
    const updateConfig = cloneDeep(config);
    const inputs: LookupInputDefinition[] = updateConfig.lookupInputs.inputs;

    Object.keys(settings).forEach((columnName)=> {
      const input:LookupInputDefinition = inputs.find( (x) => x.propertyName === columnName );
      if (input && settings.hasOwnProperty(columnName)) {
        input.hideInTable = input.hideInForm = !settings[columnName];
      }
    });
    return updateConfig;
  }

  private autoForOperator(config: ModalModel): ModalModel {
    const updateConfig = cloneDeep(config);
    updateConfig.lookupInputs.inputs = updateConfig.lookupInputs.inputs.map(this.hideQueryInput);
    updateConfig.autoSearch = true;
    updateConfig.enableFilter = true;
    return updateConfig;
  }

  private hideQueryInput(input: LookupInputDefinition): LookupInputDefinition {
    const copy = cloneDeep(input);
    copy.hideInForm = true;
    copy.suppressFilter = false;
    return copy;
  }

  private removeDefaultFilter(input: LookupInputDefinition): LookupInputDefinition {
    const copy = cloneDeep(input);
    copy.defaultFilter = undefined;
    copy.defaultFilterType = undefined;
    copy.defaultFilterOperator = undefined;
    return copy;
  }

}
