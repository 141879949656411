import { Injectable } from '@angular/core';
import { ObservableStore } from 'cad/common/services/store';
import { StoreState } from 'cad/common/store/core/store-state';
import { UserCacheService } from 'cad/common/store/user/services/user-cache.service';
import { Availableasset, RootObject } from 'cad/common/store/user/user-model';

@Injectable()
export class UserStoreService extends ObservableStore<StoreState> {
  constructor(private cache: UserCacheService) {
    super({ logStateChanges: false, includeStateChangesOnSubscribe: true, trackStateHistory: false });
    const initialState = this.getInitialState();
    this.setState(initialState, 'init_state');
  }

  setRunAsUser(loginId: string): void {
    this.setState({runAsUser: loginId}, `setRunAsUser: ${loginId}`);
  }

  setCurrentContext(user: RootObject): void {
    this.setState({user}, 'setCurrentContext');
  }

  clearCurrentContext() : void {
    this.setState({user: undefined}, 'clearCurrentContext' );
  }

  login(availableUsers: RootObject[]): void {
    if (!Array.isArray(availableUsers)) {
      throw new TypeError();
    } else {
      for (const user of availableUsers) {
        if(('availablecontexts' in user) && ('availableassets' in user) === false) {
          throw new TypeError();
        }
      }
    }

    let filteredUsers = availableUsers.filter((user) => {
      return (user.availablecontexts && user.availableassets);
    });
    this.setState({availableUsers: filteredUsers}, 'login');
  }

  logout(): void {
    this.setState({availableUsers: undefined}, 'logout');
  }

  setAvailableAssets(availableAssets: Availableasset[]): void {
    this.setState({availableAssets}, 'assets-available');
  }

  setPostLoginRedirect(url: string): void {
    this.setState({ redirectUrl: url }, 'redirect-url');
  }

  clearPostLoginRedirect(): void {
    this.setState({ redirectUrl: undefined }, 'clear-redirect-url');
  }

  clearAvailableAssets(): void {
    this.setState({availableAssets: undefined}, 'clear-assets-available');
  }

  private getInitialState(): StoreState {
    const initialState = {
      user: undefined,
      availableUsers: undefined,
      availableAssets: undefined,
    };
    let cachedUser = this.cache.getUserState();
    return cachedUser? cachedUser : initialState;

  }
}
