import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightText'
})
export class HighlightTextPipe implements PipeTransform {
  
  /**
   * @param text Text being searched for matches.
   * @param search Match text to be highlighted.
   * @param flags A list of flags (loosely based on JavaScript RexExp flags).
   *              Supported flags:
   *              'g': Find all matches within the provided text
   *              'i': Ignore case when searching for matches
   * @param matchByWord Whether the search should search by word or by raw string.
   * @returns {string}
   */
  transform(text: string, search: any, flags?: string, matchByWord?: boolean): string {
    if (!search) {
      return text;
    }
    const pattern: string = search
    .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
    .split(matchByWord ? ' ' : null)
    .filter((splitWord: string) => splitWord.length > 0)
    .join('|');
    const regex: RegExp = new RegExp(pattern, flags);
    return search ? text.replace(regex, (match: string) => `<b>${match}</b>`) : text;
  }
}
