import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ApiCache } from 'src/cad/common/services/api/api-cache';
import {SegmentApiInterface} from 'cad/common/services/api/physical/segment/segment-api-interface';

@Injectable()
export class PhysicalSegmentApi implements SegmentApiInterface {
  private readonly endpoint: string = 'physical/segment';

  constructor(
    private apiHelper: ApiHelper,
    private apiCache: ApiCache,
  ) {}

  public filter = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public all = (): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/all');
  }

  public recent = (): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/dashboard');
  }

  public getById = (code: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/' + code);
  }

  public parentSegments = (segmentLevel: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/parentSegmentCVList/' + segmentLevel);
  }

  public save = (itemData: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/' + itemData.code , { body: itemData });
  }
}
