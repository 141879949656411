import {Injectable} from '@angular/core';
import {ActionMenu} from 'src/ag-grid-wrapper/table/action-menu';
import {ContactsService} from 'cad/core/services/contacts/contacts.service';
import {ModalsService} from 'cad/core/services/modal/modals.service';
import {
  AutoUnsubscribables,
  AutoUnsubscriber
} from 'cad/shared/mixins/auto-unsubscriber.mixin';
import {ReductionReasonModalComponent} from 'cad/nominations/common/modals/reduction-reason-modal/reduction-reason-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {RouterService} from 'cad/core/services/router/router.service';
import {UserModelService} from 'src/cad/common/services/user/user-model-service';
import {
  DirectionOfFlowEnum,
  ReductionReasonEnum
} from 'cad/nominations/confirmation/shared/store/confirmation-state-store';


@Injectable()
export class ScheduledQuantityForOperatorActionService {

  @AutoUnsubscriber() protected subs: AutoUnsubscribables;

  constructor(private contactsService: ContactsService,
              private deleteService: ModalsService,
              private mdDialog: MatDialog,
              private router: RouterService,
              private userModelService: UserModelService) {
  }

  readonly schQtyForOperatorActionMenu: ActionMenu = {
    title: 'Manage Schedule Quantity Operator',
    location: 'right',
    items: [
      {
        name: 'View Reduction Comments',
        icon: 'vertical_align_bottom',
        action: 'viewReductionComments',
        securityType: 'screenfunction',
      }
    ],
  };
  readonly schQtyForOperatorHeaderActionMenu: ActionMenu = {
    title: 'Manage Schedule Quantity Header Operator',
    location: 'right',
    items: [
      {
        name: 'View Location Operator Contacts',
        icon: 'people',
        action: 'viewHeaderLocationOperator',
      }
    ],
  };


  public viewLocationOperatorContacts(rowEntity: any): void {

    const id = rowEntity.entityId !== undefined ? rowEntity.entityId : rowEntity.pointCd;

    this.contactsService.showLocationContacts(id,
      'Location Operator Contacts', this.userModelService.getCurrentContext()
        ? this.userModelService.getCurrentContext().assetNbr
        : null
      , true);
  }

  public viewConfReductionComments(rowEntity: any): void {
    this.mdDialog.open(ReductionReasonModalComponent, {
      data: {
        reductionCd: rowEntity.reductionCd,
        comment: rowEntity.comment,
        filterData: rowEntity,
        codeType: this.getReductionReasonCodes(rowEntity.nomDirectionOfFlow, this.userModelService.isUserInternal() === 'Y'),
      },
      width: '60%',
    });
  }

  public disableViewConfReductionComments(rowEntity: any): boolean {
    return !rowEntity || !rowEntity.reductionCd;
  }

  public getScheduledQuantityHeaderActionMenu(): ActionMenu {
    return this.initConfActionMenu(this.schQtyForOperatorHeaderActionMenu);
  }

  public getScheduledQuantityOperatorActionMenu(): ActionMenu {
    return this.initConfActionMenu(this.schQtyForOperatorActionMenu);
  }


  private initConfActionMenu(listActionMenu: ActionMenu): ActionMenu {
    let changedListActionMenu = _.cloneDeep(listActionMenu);
    changedListActionMenu.items.forEach((item) => {
      switch (item.action) {
        case 'viewReductionComments': {
          item.method = this.viewConfReductionComments.bind(this);
          item.isDisabled = this.disableViewConfReductionComments.bind(this);
          break;
        }
        case 'viewHeaderLocationOperator': {
          item.method = this.viewLocationOperatorContacts.bind(this);
          break;
        }
      }
    });
    return changedListActionMenu;
  }

  getReductionReasonCodes(direction: string, isInternal: boolean): string {
    switch (direction) {
      case DirectionOfFlowEnum.Delivered: {
        return isInternal ? ReductionReasonEnum.IntDeliveryConfRR : ReductionReasonEnum.ExtDeliveryConfRR;
      }
      case DirectionOfFlowEnum.Received: {
        return isInternal ? ReductionReasonEnum.IntReceiptConfRR : ReductionReasonEnum.ExtReceiptConfRR;
      }
      default: {
        return 'CONFREDREASON';
      }
    }
  }
}

