import { Pipe, PipeTransform } from '@angular/core';
import { ActionService } from 'src/cad/common/services/security/action.service';
import * as _ from 'lodash';

/**
 * @description Determines if the security action is NOT permitted to the current user.
 * @param securityAction Ex: 'postOffering', 'SubmitContract', 'save'
 * @param securityType Optional. Defaults to 'actionbutton'. Ex: 'actionbutton', 'screenfunction'
 * @param securityContext Optional. Overrides the security context of the current route's SecurityFeature data,
 *                        useful in cases where the security context resides in a different vertical.
 *                        Ex: 'businessAssociate', 'capRelAuction'
 * @returns {boolean} True if the action is NOT allowed, otherwise false.
 * @example
 *   [disabled]="'save' | securityDisabled"
 *   [disabled]="isDisabled | 'delete' | securityDisabled:'actionButton'"
 *   [disabled]="'postOffering' | securityDisabled:'screenfunction'"
 *   [disabled]="'SubmitContract' | securityDisabled:'screenfunction':'businessAssociate'"
 */
@Pipe({ name: 'securityDisabled' })
export class SecurityDisabledPipe implements PipeTransform {

  constructor(
    public actionService: ActionService
  ) {}

  transform(securityAction: string, securityType?: string, securityContext?: string): boolean {
    return securityAction
      ? !(this.actionService && this.actionService.canPerform(
        !_.isEmpty(securityType) ? securityType : 'actionbutton',
        securityAction,
        securityContext
      ))
      : false;
  }
}
