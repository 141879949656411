<ng-container [ngSwitch]="enableVirtualOptions">
  <mat-form-field *ngSwitchCase="true"
                  [floatLabel]="floatLabel">
    <ui-mat-virtual-select [(ngModel)]="model"
                           #matSelectRef="uiMatVirtualSelect"
                           name="{{name}}"
                           [tabIndex]="tabIndex"
                           [originalOptionList]="optionList"
                           [(virtualOptionList)]="renderedItems"
                           [valueProperty]="valueProperty"
                           [viewValueProperty]="viewValueProperty"
                           [placeholder]="placeholder"
                           (closed)="onPanelClose()"
                           (opened)="onPanelOpen()"
                           [attr.readonly]="readonly"
                           [multiple]="multiple"
                           [disabled]="disabled"
                           [required]="required">
      <ui-select-header-filter [name]="name"
                               *ngIf="enableHeaderFilter"
                               uiMatVirtualSelectHeader
                               [(optionItems)]="optionList"
                               [(selectionModel)]="model"
                               [showSelectionRow]="showHeaderSelectionRow"
                               [multiple]="multiple"
                               [caseSensitive]="useCaseSensitiveFilter"
                               [startsWith]="useStartsWithFilter"
                               [valueProperty]="valueProperty"
                               [viewValueProperty]="viewValueProperty">
      </ui-select-header-filter>
      <mat-option *ngFor="let option of renderedItems"
                  [disabled]="isOptionDisabled(option)"
                  title="{{option[viewValueProperty]}}"
                  [value]="option[valueProperty]">{{option[viewValueProperty]}}
      </mat-option>
    </ui-mat-virtual-select>
    <mat-hint *ngIf="!!hintText"
              align="{{hintAlign}}">{{hintText}}</mat-hint>
  </mat-form-field>
  <mat-form-field *ngSwitchDefault
                  [floatLabel]="floatLabel">
    <mat-select [(ngModel)]="model"
                #matSelectRef="matSelect"
                name="{{name}}"
                [tabIndex]="tabIndex"
                [placeholder]="placeholder"
                [attr.readonly]="readonly"
                [multiple]="multiple"
                [disabled]="disabled"
                [required]="required">
      <mat-option *ngFor="let option of optionList"
                  [disabled]="isOptionDisabled(option)"
                  title="{{option[viewValueProperty]}}"
                  [value]="option[valueProperty]">{{option[viewValueProperty]}}
      </mat-option>
    </mat-select>
    <mat-hint *ngIf="!!hintText"
              align="{{hintAlign}}">{{hintText}}</mat-hint>
  </mat-form-field>
</ng-container>
