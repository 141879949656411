
import { AfterViewInit, Component, ComponentRef, Inject, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserModelService } from 'cad/common/services/user/user-model-service';
import { AlertsService } from 'cad/core/services/alerts/alerts.service';
import { AutoUnsubscribables, AutoUnsubscriber } from 'cad/shared/mixins/auto-unsubscriber.mixin';
import { LayoutService } from 'common/services/layout/layout';
import { DirtyStatus } from 'common/types/cad.constants';
import { CUSTOM_CHIP_DATA } from 'common/types/chip-data';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UiForm, UiFormParent } from 'src/common/components/form/form';
import { UiFormComponent } from 'src/common/components/form/form.component';
import { AssociationApiService } from 'src/features/common/association/association-api.service';
import { AssociationController } from 'src/features/common/association/association.controller';
import { ItemController } from 'src/features/common/item/item.controller';
import { NewItemInputService } from 'src/features/common/new-item/new-item-input.service';

@Component({
  selector: 'ui-association-dialog',
  templateUrl: './fullscreen-dialog.component.html',
  styleUrls: [ './fullscreen-dialog.component.less', ],
})
export class FullscreenDialogComponent extends UiFormParent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(UiFormComponent) uiFormComponent: UiFormComponent;

  public fromController: ItemController;
  public associationController: AssociationController;
  public associationApiService: AssociationApiService;
  public results: any;
  public selectedItems: any[] = [];
  public gridApi: any;
  public searchResults: any[] = [];
  public itemData: any;
  public toItemData: any;
  public selectedContentIndex: number = 0;
  public selectedTabIdx: number = 0;
  public createInjector: Injector;

  public formReference: ComponentRef<any>;
  public searchReference: ComponentRef<any>;
  public itemNewFormReference: ComponentRef<any>;

  public isUpdating: boolean;
  public itemIdField: string | number;
  public isDirty: boolean = false;

  protected item: any = {};
  protected association: any = {};
  protected firstDayofMonth: any = dateUtil().getFirstDayOfMonth();
  protected defaultEndDate: any = dateUtil().getDefaultEndDate();
  protected itemSave: Function;
  protected itemLayout: any;

  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected dialogRef: MatDialogRef<FullscreenDialogComponent>,
    protected cadAlerts: AlertsService,
    protected uiLayoutService: LayoutService,
    protected userModelService: UserModelService,
    protected injector: Injector,
    public newItemInputService: NewItemInputService
  ) {
    super();

    this.associationController = this.data.controller;

    this.associationApiService = this.data.controller.associationApiService;
    this.fromController = this.associationController.fromController;
    this.itemIdField = this.associationController.model.toModel.primaryKey;
    this.isUpdating = false; // this.item && this.item[this.itemIdField] != undefined || this.associationController.newAssociation && this.associationController.newAssociation[this.itemIdField];
    this.itemSave = this.associationController.toApiService.save;
    this.itemData = this.data.itemData;

    this.associationController.cleanupAssociationModal();

    if (this.associationController.injector) {
      this.createInjector = Injector.create([
        {
          provide: UiFormParent,
          useFactory: () => this.injector.get(FullscreenDialogComponent),
          deps: [],
        },
      ], this.associationController.injector);
    }

    _.defaults(this, {
      modalTemplateName: 'default',
    });
  }

  public get uiForm(): UiForm { return this.uiFormComponent; }

  public ngOnInit(): void {
    if (this.associationController.model.toModel) {
      if (this.associationController.model.toModel.layout) {
        this.subs.newSub = this.uiLayoutService.layoutStream(this.associationController.model.toModel.layout).subscribe((layout) => {
          this.itemLayout = layout;
        });
      }
    }
    this.associationController.init();
  }

  public ngAfterViewInit(): void {
    this.newItemInputService.getModels().forEach((ngModel: NgModel) => this.uiForm.addControl(ngModel));
  }

  public ngOnDestroy(): void {
    this.newItemInputService.destroy();
  }

  public getDirtyStatus = (item: any): DirtyStatus => {
    return item.hasOwnProperty('dirtyStatus') ? (item.dirtyStatus === cad.DirtyStatus.DELETE) ? cad.DirtyStatus.DELETE : cad.DirtyStatus.UPDATE : cad.DirtyStatus.NEW;
  }

  public saveAssociations = (): Subscription => {
    return this.associationController.saveNewAssociations().pipe(tap((result: any) => {
      if (result.successful) {
        this.associationController.postSave(result.validatedObject);
        if (this.associationController.model) {
          this.associationController.refreshData(this.itemData[ this.fromController.model.primaryKey ]);
          this.cadAlerts.success('Associations successfully saved.');
        }
        this.closeModal();
      } else {
        _.each(result.errorMessages, (errorMessage: any) => {
          this.cadAlerts.danger(errorMessage.message);
        });
      }
    }),
      catchError((err: any, caught: any): any => {
        if (err) {
          console.error('error saving association', err, caught);
        }
      })).subscribe();
  }

  public clear(): void {
    if (this.searchReference && this.searchReference.instance) {
      this.searchReference.instance.params = {};
    }
  }

  public cancel(): void {
    this.selectedTabIdx = 0;
  }

  public saveNewItem(): void {
    let newItemApi = this.associationController.toApiService;

    this.item = _.cloneDeep(this.associationController.newDataItem.itemData);
    this.item.dirtyStatus = DirtyStatus.NEW;
    this.item.updateDt = moment().format();
    this.subs.newSub = this.uiFormComponent.validate().subscribe((valid) => {
      if (valid) {
        if (this.associationController
          && this.associationController.model
          && this.associationController.model.modalSubmitOnSaveNew) {
          let preSaved = this.associationController.preSave([ this.item ]);
          this.subs.newSub = newItemApi.save(preSaved).subscribe((result) => {
            if (result.successful) {
              this.associationController.postSave(result.validatedObject);
              this.associationController.refreshData(this.itemData[ this.fromController.model.primaryKey ]);
              this.cadAlerts.success('New Association successfully saved.');
              this.closeModal();
              this.resetForm(this.uiFormComponent.form);
            } else {
              _.each(result.errorMessages, (errorMessage: any) => {
                this.cadAlerts.danger(errorMessage.message);
              });
            }
          });
        } else {
          this.subs.newSub = newItemApi.save(this.item).subscribe((result) => {
            if (result.successful) {
              let existingItem = _.find(this.associationController.selectedItems, (val) => {
                let pk = this.associationController.model.toModel.primaryKey;
                return val[ pk ] === result.validatedObject[ pk ];
              });

              if (!existingItem) {
                this.addChipForNewItem(result.validatedObject);
                this.tabIndexChange(0);
              }

              this.resetForm(this.uiFormComponent.form);
            } else {
              console.log('saveNewItem error, message: ', result.errorMessages);
            }
          });
        }
      }
    });
  }

  public resetForm(form: NgForm): void {
    Object.keys(form.controls).forEach((c) => {
      form.controls[ c ].reset();
    });
    setTimeout(() => {
      _.assign(this.associationController.newDataItem.itemData, this.associationController.model.toModel.defaults());
    });
  }

  public addChipForNewItem(itemData: any): void {
    let injector = Injector.create(
      [
        { provide: CUSTOM_CHIP_DATA, useValue: itemData }
      ],
      this.associationController.injector
    );
    this.associationController.chipInjectors.push(injector);
    this.associationController.selectedItems.push(itemData);
  }

  public onAddTabSelected(): void {
    if (_.isEqual(this.toItemData, this.associationController.model.toModel.defaults())) {
      if (this.searchReference) {
        _.assign(this.toItemData, _.mapValues(this.searchReference.instance.params, (val) => {
          if (_.isString(val)) {
            return val.replace(/%$/g, '');
          } else {
            return val;
          }
        }));
      }
    }
  }

  public onSearchReady(comp: any): void {
    this.searchReference = comp;
    _.assign(
      this.searchReference.instance.params,
      this.associationController.model.toModel.defaultSearchParams
    );
  }

  public onFormReady(comp: any): void {
    this.formReference = comp;
    this.formReference.instance.associationController = this.associationController;
    this.setupAssociation(comp);
  }

  public setupAssociation(comp: ComponentRef<any>): void {
    this.associationController.newAssociation = comp.instance.association || {};

    if (!comp.instance.association) {
      console.error('Association form partial must have public association property.');
    }

    _.defaults<object, object>(this.associationController.newAssociation, this.associationController.model.defaults);

    let fromPk = this.fromController.model.primaryKey;
    let toPk = this.associationController.model.toModel.primaryKey;

    this.associationController.newAssociation[ fromPk ] = this.data.itemData[ fromPk ];
    this.associationController.newAssociation[ toPk ] = this.data.itemData[ toPk ];
  }

  public onRemoveChip(item: any): void {
    let itemIndex = _.findIndex(this.searchResults, (val) => {
      return val[ this.associationController.model.toModel.primaryKey ] === item[ this.associationController.model.toModel.primaryKey ];
    });

    if (itemIndex !== -1 && this.gridApi.grid.getRow(this.searchResults[ itemIndex ]).isSelected) {
      this.gridApi.selection.unSelectRow(this.searchResults[ itemIndex ]);
    }
  }

  public isEqual(obj1: any, obj2: any): boolean {
    return _.isEqual(obj1, obj2);
  }

  public incrementContent(increment: boolean): void {
    if (increment) {
      this.selectedContentIndex = this.selectedContentIndex + 1;
    } else {
      this.selectedContentIndex = this.selectedContentIndex - 1;
    }
  }
  public tabIndexChange(tabIndex: number): void {
    this.selectedTabIdx = tabIndex;
  }

  public selectedTab(tabIndex: number): void {
    this.selectedContentIndex = tabIndex;
  }
  private cleanUp(): void {
    this.associationController.cleanupAssociationModal();
  }

  closeModal(): void {
    this.cleanUp();
    this.dialogRef.close();
  }

}
