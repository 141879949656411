import { OnDestroy, Injector, ValueProvider, Component, Injectable } from '@angular/core';
import { UrlSegment, ActivatedRouteSnapshot } from '@angular/router';
import { BehaviorSubject ,  ReplaySubject ,  Observable } from 'rxjs';
import { AutoUnsubscriber, AutoUnsubscribables } from 'cad/shared/mixins/auto-unsubscriber.mixin';
import * as _ from 'lodash';

@Component({ template: '' })
export abstract class Controller<M, V> implements OnDestroy {
  @AutoUnsubscriber() protected subs: AutoUnsubscribables;
  onFactoryInjector$: ReplaySubject<Injector> = new ReplaySubject<Injector>(1);
  factoryInjector: Injector;
  name: string;

  private _modelSubject: BehaviorSubject<M>;
  private _model$: Observable<M>;
  private _model: M;
  private _viewSubject: BehaviorSubject<V>;
  private _view$: Observable<V>;
  private _view: V;
  private _markForCheckSubject: BehaviorSubject<boolean>;
  private _markForCheck$: Observable<boolean>;

  constructor(public injector: Injector) {}

  public static buildURLLinkWithoutTab(route: ActivatedRouteSnapshot): string {
    if (!route) { return '/'; }
    let urls: UrlSegment[] = Controller.getUrlSegments(route);
    urls.splice(-1, 1);
    return '/' + urls.join('/');
  }

  /**
   * Helper function to build the URL of an ActivatedRouteSnapshot
   */
  public static buildUrlLink(route: ActivatedRouteSnapshot): string {
    if (!route) { return '/'; }
    let urls: UrlSegment[] = Controller.getUrlSegments(route);
    return '/' + urls.join('/');
  }

  public static getUrlSegments(route: ActivatedRouteSnapshot): UrlSegment[] {
    let urls: UrlSegment[] = [];
    if (!route) { return urls; }
    /* tslint:disable:no-param-reassign */
    for (; route.parent; route = route.parent) {
      /* tslint:enable:no-param-reassign */
      let tempUrls = _.clone(route.url).reverse();
      urls = urls.concat(tempUrls);
    }
    // decode the url segments to prevent multiple navigations
    // to the same url to continuously encode the url via the router
    urls = _.map(urls, this.decodeSegment);
    return urls.reverse();
  }

  /**
   * Function to decode the path portion of a Url Segment
   * @param urlSegment
   */
  public static decodeSegment(urlSegment: UrlSegment): UrlSegment {
    let segment: UrlSegment = _.cloneDeep(urlSegment);
    if(segment.path) {
      segment.path = decodeURI(segment.path);
    }
    return segment;
  }

  /**
   * Override for derived controllers to provide by base class as well
   */
  public getFactoryProviders(): ValueProvider[] {
    return [ { provide: this.constructor, useValue: this } ];
  }

  initializeController(name: string, model: M, view: V): void {
    this.name = name;
    this._model = model;
    this._view = view;
    this._modelSubject = new BehaviorSubject<M>(this._model);
    this._viewSubject = new BehaviorSubject<V>(this._view);
    this._model$ = this._modelSubject.asObservable();
    this._view$ = this._viewSubject.asObservable();
    this._markForCheckSubject = new BehaviorSubject<boolean>(true);
    this._markForCheck$ = this._markForCheckSubject.asObservable();
    this.onControllerInit();
  }

  initializeFactoryInjector(factoryInjector: Injector): void {
    this.factoryInjector = factoryInjector;
    setTimeout(() => {
      this.onFactoryInjector$.next(this.factoryInjector);
      this.onFactoryInjector();
    }); // delay this by a cycle to prevent a ExpressionChangedAfterItHasBeenCheckedError
  }

  /**
   * Called when model & view are initialized (before ngOnInit).
   * Can be overwritten as needed
   */
  public onControllerInit(): void {}

  /**
   * Called when model & view are initialized (before ngOnInit).
   * Can be overwritten as needed
   */
  public onFactoryInjector(): void {}

  /**
   * Necessary so that super.ngOnDestroy() can be called from derived classes
   * since we are using @AutoUnsubscriber() in this class.
   */
  public ngOnDestroy(): void {
    this.factoryInjector = undefined;
    this.onFactoryInjector$ = undefined;
    this._model = undefined;
    this._view = undefined;
  }

  /**
   * Model observable accessor.
   */
  public get model$(): Observable<M> { return this._model$; }

  /**
   * Model accessor.
   */
  public get model(): M { return this._model; }

  /**
   * View observable accessor.
   */
  public get view$(): Observable<V> { return this._view$; }

  /**
   * View accessor.
   */
  public get view(): V { return this._view; }

  /**
   * Mark for check observable accessor.
   */
  public get markForCheck$(): Observable<boolean> { return this._markForCheck$; }

  /**
   */
  public updateModel(model: M, replace: boolean = false): void {
    if (replace) {
      this._model = _.clone(model);
    } else {
      this._model = _.assign({}, this._model, model);
    }
    this._modelSubject.next(this._model);
  }

  public updateView(view: V, replace: boolean = false): void {
    if (replace) {
      this._view = _.clone(view);
    } else {
      this._view = _.assign({}, this._view, view);
    }
    this._viewSubject.next(this._view);
  }

  public markForCheck(): void {
    this._markForCheckSubject.next(true);
  }
}
