import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ParameterVOData } from 'src/cad/structured-products/shared/interfaces/parameter-vo-data';

@Injectable()
export class StaticParameterSearchApi {
  
  private readonly endpoint: string = 'product/static-parameter-lookup';
  
  constructor(
    private apiHelper: ApiHelper
  ) {}
  
  public filter(): Observable<ParameterVOData[]> {
    return this.apiHelper.request(this.endpoint + '/filter');
  }
}
