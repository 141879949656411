import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class UpdateBalanceDateApi {

  public endpoint: string = 'flow/update-balance-date';

  constructor(
    private apiHelper: ApiHelper
  ) {}

  public updateBalanceDate = (params: { contractId: number, balanceDate: Date }): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/save', { body: params });
  }

  public filter(params: { contractId: number, serviceRequesterName: string }): Observable<mplus.ContractData[]> {
    return this.apiHelper.request(`${ this.endpoint }/filter`, { body: params });
  }
}
