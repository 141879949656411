import { Component, Input } from '@angular/core';

/*
Allows you to wrap icon with tooltip

Usage Examples:
 <!-- simple usage -->
 <ui-md-icon tooltip="Home" mdIconName="home"></ui-md-icon>

 <!-- apply filter to iconName and class so that you can dynamically determine -->
 <ui-md-icon [tooltip]="testData.status" mdIconName="{{testData.status | workflowStatusIconName}}"
     mdIconClass="{{testData.status | workflowStatusIconColor}}"></ui-md-icon>
*/

@Component({
  selector: 'ui-md-icon',
  templateUrl: './icon.component.html',
  styleUrls: [ './icon.component.less' ],
})

export class UiMdIconComponent {
  @Input() tooltip: any;
  @Input() mdIconName: any;
  @Input() mdIconClass: any;
}
