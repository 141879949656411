<div class="ui-select-header-filter-container mat-elevation-z1"
     fxLayout="column">
  <mat-form-field [floatLabel]="'never'">
    <input [(ngModel)]="headerFilterSearchText"
           name="{{name}}_headerFilterSearchText"
           #input
           matInput
           updateModelOn="default"
           class="ui-select-header-filter-input"
           type="search"
           autocomplete="off"
           placeholder="Search ...">
    <button type="button"
            matSuffix
            mat-button
            mat-icon-button
            matTooltip="Clear Filter"
            tabindex="-1"
            *ngIf="headerFilterSearchText"
            (click)="clearHeaderFilterSearchText()">
      <mat-icon class="material-icons">close</mat-icon>
    </button>
  </mat-form-field>
  <ng-container *ngIf="showSelectionRow">
    <mat-divider></mat-divider>
    <div class="ui-select-header-filter-selection"
         fxLayoutAlign="start center"
         fxLayoutGap="10px"
         fxLayout="row">
      <button *ngIf="multiple"
              class="ui-select-header-filter-remove-all"
              type="button"
              mat-button
              mat-icon-button
              matTooltip="Clear Selections"
              (click)="clearSelectionModel()"
              [disabled]="!selectionModel || selectionModel?.length < 1"
              aria-label="Clear Selections">
        <mat-icon>delete_sweep</mat-icon>
      </button>
      <div [class.bold-font]="!multiple"
           fxLayout="column"
           fxLayoutAlign="center start">{{selectionText}}
      </div>
      <mat-checkbox [(ngModel)]="selectionsOnly"
                    [disabled]="!selectionModel || selectionModel?.length < 1">Show Selections Only</mat-checkbox>
      <span class="ui-select-header-filter-results-count"
            fxFlex
            fxLayout="column"
            fxLayoutAlign="center end">{{optionItems?.length}} Results</span>
    </div>
  </ng-container>
</div>
