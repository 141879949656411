import { ChangeDetectorRef, Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscriber, AutoUnsubscribables } from 'cad/shared/mixins/auto-unsubscriber.mixin';
import { Controller } from './controller';

@Component({ template: '' })
// TODO: Add Angular decorator.
export abstract class FeatureComponent<M, V, C extends Controller<M, V>> implements OnDestroy {
  public model: M;
  public view: V;
  @AutoUnsubscriber() protected subs: AutoUnsubscribables;
  private _changeDetectorRef: ChangeDetectorRef;

  public constructor(
    @Inject('controller') public controller: C,
    public injector: Injector,
  ) {
    this.controller = controller;
    this._changeDetectorRef = this.injector.get(ChangeDetectorRef);

    this.subs.newSub = controller.model$.subscribe((model) => this.updateModel(model));
    this.subs.newSub = controller.view$.subscribe((view) => this.updateView(view));
    this.subs.newSub = controller.markForCheck$.subscribe(() => this._changeDetectorRef.markForCheck());
  }

  /**
   * Necessary so that super.ngOnDestroy() can be called from derived classes
   * since we are using @AutoUnsubscriber() in this class.
   */
  public ngOnDestroy(): void {
    this._changeDetectorRef = null;
    this.controller = null;

    this.model = null;
    this.view = null;
  }

  private updateModel(model: M): void {
    this.model = model;
    this._changeDetectorRef.markForCheck();
  }

  private updateView(view: V): void {
    this.view = view;
    this._changeDetectorRef.markForCheck();
  }
}
