import { ETCodePipe } from 'src/cad/common/filters/et-codes/et-codes.pipe';
import { Directive, Input, OnInit } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { CodesApi } from 'cad/common/services/api/codes/codes';
import { Code } from 'src/cad/shared/interfaces/code-value-data';
import { UserModelService } from 'cad/common/services/user/user-model-service';
import { TableColumnComponent } from 'src/ag-grid-wrapper/column/column.component';
import { SelectCodeEditorComponent } from './select-code-editor.component';
import { ICellRendererParams, SetFilterValuesFuncParams } from '@ag-grid-community/core';
import * as _ from 'lodash';

/**
 *
 * This directive is used to configure a custom editor component see ./select-code-editor.component.ts for more information
 * on how to create a custom editor.
 *
 * The purpose of this directive is to configure the table column to use a custom editor and wrap up the boilerplace so the editor
 * can be used simply by adding a shared directive to your table column.  The ag-grid-wrapper's TableColumnComponent has a
 * few inputs that need to be configured in order to use a custom editor.  To do this, we will inject the TableColumnComponent into
 * our constructor.  In the ngOnInit lifecycle hook we can configure it to use the 'custom' type, assign our editor component to the
 * customEditor (Type<any>) input and assign our custom renderer to the customRenderer (Type<any>) property.  In this example I've
 * also added a codeType input to allow the user to specify the etCode type to use.
 *
 * Example Usage:
 *
 *    ```<ui-table-column
 *        name="invoiceTypeCd"
 *        display-name="Invoice Type"
 *        uiSelectEtCodeEditor
 *        codeType="INVOICETYPE"
          [enable-edit]="true">
 *      </ui-table-column>```
 *
 * The above will render an editable cell which, when clicked will show the SelectCodeEditorComponent.  The extra property [enable-edit]="true"
 * is needed to allow editing of the column.  It is also possible to set this.tableColumn.enableEdit = true; in the ngOnInit lifecycle hook.
 * Both edit and non-edit modes will render the et code value from the et code itself.
 *
 * Add [showValue]='false' if you want to see the code as opposed to the value.
 *
 **/

@Directive({
  selector: 'ui-table-column[uiSelectEtCodeEditor]',
  providers: [ ETCodePipe ]
})
export class SelectCodeEditorDirective implements OnInit {

  @Input() public codeType: string;
  @Input() public codeTypeAssetCol: number;
  @Input() public showValue: boolean = true;
  @Input() public disabledOptions: any[];

  @Input() public fireChangeOnSelect: boolean = false;
  constructor(
    public tableColumn: TableColumnComponent,
    public pipe: ETCodePipe,
    public userModelService: UserModelService,
    public codesApi: CodesApi
  ) {}

  ngOnInit(): void {
    this.tableColumn.type = 'custom';
    if (this.tableColumn.useCustomHeaderFilter) {
      this.tableColumn.filter = 'agSetColumnFilter';
      this.tableColumn.menuTabs = [ 'filterMenuTab' ];
      this.tableColumn.suppressMenu = false;
      this.tableColumn.headerComponentFramework = null;
      /* Adding useCustomFilterParams to the tableColumn allows us to use the custom filterParams object
         * to populate the filter with the values from the code table.  This is useful when we want to use
         * the code table values as the filter values. But also see them in filter is confusing the user
         * that some records in the filter are not showning in the grid.
      * */
      if (this.tableColumn.useCustomFilterParams) {
        this.tableColumn.filterParams = {
          values: (params: SetFilterValuesFuncParams) => {
            this.codesApi.getCodeType(this.userModelService.getCurrentAssetNbr(), this.codeType)
                .pipe(
                    map((codeType: Code) => (codeType && codeType.codes) ? codeType.codes.map((code) => code.cd) : []),
                    take(1)
                ).subscribe((codes) => params.success(codes));
          },
          textFormatter: (from: string) => this.pipe.transform(from, this.codeType, this.codeTypeAssetCol)
        };
      }
    }
    this.tableColumn.cellFilter = this.codeType;
    this.tableColumn.customEditor = SelectCodeEditorComponent;
    if (this.tableColumn && _.isNil(this.tableColumn.customCellConfig)) {
      this.tableColumn.customCellConfig = {
        codeTypeAssetCol: this.codeTypeAssetCol,
        disabledOptions: this.disabledOptions
      };
    }
    this.tableColumn.valueFormatter = (params: ICellRendererParams): string => {
      let val: any;

      if (params && params.data) {
        val = _.get(params.data, params.colDef.field);
      } else if (params && !_.isNil(params.value)) {
        val = params.value;
      } else {
        val = (params && _.isFunction((params as any).toUpperCase))
          ? (params as any).toUpperCase()
          : null;
      }

      return this.pipe.transform(val, this.codeType, this.codeTypeAssetCol);
    };
  }
}
