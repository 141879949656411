
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import * as _ from 'lodash';
import { AdjustmentApiInterface } from './adjustment-api-interface';

@Injectable()
export class AdjustmentApi implements AdjustmentApiInterface {

  private readonly endpoint: string = 'settlement/adjustment';

  constructor(
    private apiHelper: ApiHelper
  ) {}

  public getById(id: number | string): Observable<any> {
    if (_.isNil(id)) {
      return observableOf({});
    }
    return this.apiHelper.request(this.endpoint + '/' + id);
  }

  public find(params: any): Observable<any> {
    return (params && !_.isNil(params)) ? this.getById(params.adjustmentId) : observableOf({});
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/dashboard');
  }

  public save(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save', { body: params });
  }

  public deleteItem(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/delete', { body: params });
  }

  public getAdjustmentTypeList(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/adjustment-type-list', { body: params });
  }

  public getAdjustmentComponentList(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/adjustment-component-list', { body: params });
  }

  public recalcRateComponents(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/recalc-rate-components', { body: params });
  }

  public getDefaultAccountingPeriodByType(contractId: number | string): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/default-acct-period-by-type/' + (contractId || 0));
  }
}
