<div fxLayout="row">
  <form-group-observer (groupValueChange)="performLookup()"
                       fxFlex
                       fxLayoutGap="12px">
    <ng-container *ngFor="let input of controller.model.lookupInputs?.inputs">
      <mat-form-field fxFlex
                      *ngIf="input.type === 'numberStr' && !input.hideInForm">
        <input matInput
               [(ngModel)]="params[input.propertyName]"
               [disabled]="input.disableInForm"
               [placeholder]="input.name || input.propertyName"
               numbersOnly
               modelFormatter
               updateModelOn="blur"/>
      </mat-form-field>

      <mat-form-field fxFlex
                      *ngIf="input.type === 'text' && !input.hideInForm">
        <input matInput
               addWildCard="{{input.addWildCard || ''}}"
               [disabled]="input.disableInForm"
               [(ngModel)]="params[input.propertyName]"
               [type]="input.type"
               [placeholder]="input.name || input.propertyName"
               modelFormatter
               updateModelOn="blur"/>
      </mat-form-field>

      <mat-form-field fxFlex
                      *ngIf="input.type === 'number' && !input.hideInForm">
        <input matInput
               [(ngModel)]="params[input.propertyName]"
               [disabled]="input.disableInForm"
               [type]="input.type"
               [placeholder]="input.name || input.propertyName"
               modelFormatter
               updateModelOn="blur"/>
      </mat-form-field>

      <ui-select *ngIf="input.type === 'checkbox' && !input.hideInForm"
                 fxFlex
                 [sourceFn]="[ { cd: 'true', value: 'True' }, { cd: 'false', value: 'False' } ]"
                 [codeType]="input.codeType"
                 [placeholder]="input.name || input.propertyName"
                 [(ngModel)]="params[input.propertyName]"
                 [disabled]="input.disableInForm"></ui-select>

      <ui-select *ngIf="input.type === 'select' && !input.hideInForm"
                 fxFlex
                 [codeType]="input.codeType"
                 [placeholder]="input.name || input.propertyName"
                 [(ngModel)]="params[input.propertyName]"
                 [disabled]="input.disableInForm"></ui-select>

      <ui-datepicker *ngIf="input.type === 'date' && !input.hideInForm"
                     fxFlex
                     [(ngModel)]="params[input.propertyName]"
                     [disabled]="input.disableInForm"
                     [placeholder]="input.name || input.propertyName"></ui-datepicker>

      <ui-select *ngIf="input.type === 'states' && !input.hideInForm"
                 fxFlex
                 [name]="input.propertyName"
                 (change)="onStateChange($event)"
                 [placeholder]="input.name || input.propertyName"
                 [(ngModel)]="params[input.propertyName]"
                 [disabled]="input.disableInForm"
                 [sourceFn]="stateList"
                 valueProperty="stateCd"
                 viewValueProperty="stateName">
      </ui-select>
      <ui-select *ngIf="input.type === 'county' && !input.hideInForm"
                  fxFlex
                  [name]="input.propertyName"
                  [placeholder]="input.name || input.propertyName"
                  [(ngModel)]="params[input.propertyName]"
                  [disabled]="input.disableInForm || isDisableCounty"
                  [sourceFn]="countyList$ | async"
                  valueProperty="countyCd"
                  viewValueProperty="countyName">
      </ui-select>
      <ui-select *ngIf="(input.type === 'assets' || input.type === 'all-assets') && !input.hideInForm"
                 fxFlex
                 [name]="input.propertyName"
                 [placeholder]="input.name || input.propertyName"
                 [(ngModel)]="params[input.propertyName]"
                 [disabled]="input.disableInForm"
                 [sourceFn]="assetList"
                 valueProperty="number"
                 viewValueProperty="abbreviation">
      </ui-select>

      <ui-select *ngIf="input.type === 'rateschedules' && !input.hideInForm"
                 fxFlex
                 [name]="input.propertyName"
                 [placeholder]="input.name || input.propertyName"
                 [(ngModel)]="params[input.propertyName]"
                 [disabled]="input.disableInForm"
                 [sourceFn]="getRateSchedules">
      </ui-select>

      <ui-select *ngIf="input.type === 'serviceNames' && !input.hideInForm"
                 fxFlex
                 [name]="input.propertyName"
                 [placeholder]="input.name || input.propertyName"
                 [(ngModel)]="params[input.propertyName]"
                 [disabled]="input.disableInForm"
                 [sourceFn]="getServiceNames"
                 [sourceFnParams]="input.serviceNameFilter">
      </ui-select>
      <ui-select *ngIf="input.type === 'serviceNamesForTransaction' && !input.hideInForm"
                 fxFlex
                 [name]="input.propertyName"
                 [placeholder]="input.name || input.propertyName"
                 [(ngModel)]="params[input.propertyName]"
                 [disabled]="input.disableInForm"
                 [sourceFn]="getServiceNamesForTransactionFn">
      </ui-select>

    </ng-container>
    <div *ngIf="isSearchButtonEnabled"
         fxFlex="0 0 88px"
         fxLayoutAlign="start start">
      <button mat-button
              mat-raised-button
              class="search-button"
              type="button"
              color="primary"
              (click)="performLookup()">Search
      </button>
    </div>
  </form-group-observer>
</div>
<ui-search-results [params]="params"
                   [results]="items">
  <div *ngIf="isMultiSelection"
       class="ui-search-results-right-content">
    <mat-checkbox name="selectionsOnly"
                  (change)="onSelectionsOnlyChange()"
                  [(ngModel)]="selectionsOnly"
                  [disabled]="isSelectionsOnlyDisabled()">Selected items only
    </mat-checkbox>
  </div>
  <ui-table [data]="items"
            (gridReady)="onGridReady($event)"
            [id]="'DynamicLookup_' + controller.name"
            [rowSelection]="isMultiSelection ? 'multiple' : 'single'"
            [minHeight]="250"
            [enableFilter]="isFilterEnabled || isMultiSelection"
            [showMultiSelect]="isMultiSelection"
            [showSingleSelect]="!isMultiSelection">
    <div *ngFor="let col of controller.model.lookupInputs?.inputs">
      <ui-table-column *ngIf="col.type !== 'select' && !col.hideInTable"
                       [headerName]="col.name || col.propertyName"
                       [type]="col.type === 'date' ? 'date' : col.type"
                       [sortObj]="col.sortObj"
                       [suppressFilter]="col.suppressFilter"
                       [name]="col.type === 'county' ? col.displayPropertyName : col.propertyName">
      </ui-table-column>
      <ui-table-column *ngIf="col.type === 'select' && !col.hideInTable"
                       etCodeRenderer
                       [codeType]="col.codeType"
                       [sortObj]="col.sortObj"
                       [suppressFilter]="col.suppressFilter"
                       [headerName]="col.name || col.propertyName"
                       [name]="col.propertyName">
      </ui-table-column>
    </div>
  </ui-table>
</ui-search-results>
