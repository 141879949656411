import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableHeaderFilterModule } from 'src/ag-grid-wrapper/header/filter/module';

@NgModule({
  imports: [
    CommonModule,
    TableHeaderFilterModule,
  ],
})
export class TableHeaderModule {
}
