<h1 matDialogTitle>{{title}}</h1>
<div mat-dialog-content>
  <div fxLayout="row" class="col-padding">

    <location-contacts-common headLine="Internal Analyst"
                              type="internal"
                              label="Location"
                              confirmationInfo="confirmationInfo"
                              [contactObj]="contactData?.internalAnalystContact">
    </location-contacts-common>

    <location-contacts-common headLine="Operator"
                              label="Operator"
                              confirmationInfo="confirmationInfo"
                              [contactObj]="contactData?.operatorContact">
    </location-contacts-common>

    <location-contacts-common headLine="Operator Agent"
                              label="Operator Agent"
                              confirmationInfo="confirmationInfo"
                              [contactObj]="contactData?.operatorAgentContact">
    </location-contacts-common>

  </div>
</div>

<a href="{{noticeContactPageUrl}}" target="_blank">Click
  here for Customer Service Contact Information</a>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-raised-button
          type="button"
          color="primary"
          (click)="dialogRef.close()">CLOSE
  </button>
</div>
