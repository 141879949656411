import { Directive, HostBinding, Input,  OnInit } from '@angular/core';

@Directive({
  selector: '[collapse]',
})
export class CollapseDirective implements OnInit {

  // style
  @HostBinding('style.height')
  public height:string;
  // shown
  @HostBinding('class.in')
  @HostBinding('attr.aria-expanded')
  public isExpanded:boolean = true;
  // hidden
  @HostBinding('attr.aria-hidden')
  public isCollapsed:boolean = false;
  // stale state
  @HostBinding('class.collapse')
  public isCollapse:boolean = true;
  // animation state
  @HostBinding('class.collapsing')
  public isCollapsing:boolean = false;

  @Input()
  private set collapse(value:boolean) {
    this.isExpanded = value;
    this.toggle();
  }

  private get collapse():boolean {
    return this.isExpanded;
  }

  constructor() {
  }

  ngOnInit(): void {

  }

  toggle(): void {
    if (this.isExpanded) {
      this.hide();
    } else {
      this.show();
    }
  }

  hide(): void {
    this.isCollapse = false;
    this.isCollapsing = true;

    this.isExpanded = false;
    this.isCollapsed = true;
    setTimeout(() => {
      this.height = '0';
      this.isCollapse = true;
      this.isCollapsing = false;
    }, 4);
  }

  show(): void {
    this.isCollapse = false;
    this.isCollapsing = true;

    this.isExpanded = true;
    this.isCollapsed = false;
    setTimeout(() => {
      this.height = 'auto';

      this.isCollapse = true;
      this.isCollapsing = false;
    }, 4);
  }
}
