import { Injectable } from '@angular/core';
import { ApiCache } from 'cad/common/services/api/api-cache';

@Injectable()
export class SecurityAuthApi {
  private authToken: string;

  constructor(
    private apiCache: ApiCache,
  ) {
    this.authToken = this.getAuthToken();
  }

  /**
   * Construct a Basic auth Base64 encoded auth token from the username/passord combination
   */
  public buildAuthString = (username: string, password: string): string => {
    return 'Basic ' + btoa(username + ':' + password);
  }

  /**
   * Clear the local and session value for the authToken
   */
  public clearAuthToken = (): void => {
    this.apiCache.forSession.remove('auth');
    this.authToken = null;
  }

  /**
   * Return the current value of the authtoken
   */
  public getAuthToken = (): string => {
    if (!this.authToken) {
      this.authToken = this.apiCache.forSession.get('auth');
    }
    return this.authToken;
  }

  /**
   * Store the base64 encrypted auth token in the session.
   * @param username
   * @param password
   */
  public storeAuthToken = (username: string, password: string): void => {
    this.authToken = this.buildAuthString(username, password);
    this.apiCache.forSession.put('auth', this.authToken);
  }

  /**
   * Clear all user and auth information
   */
  public clear = (): void => {
    this.clearAuthToken();
  }
}
