import { AfterViewInit, Component, Injector, OnDestroy, OnInit, Type } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { Ribbon } from 'src/features/common/ribbon/ribbon';
import { RibbonService } from 'src/features/common/ribbon/ribbon.service';

import { ActionService } from 'cad/common/services/security/action.service';

import { ConfigService } from 'cad/core/services/config/config.service';

import { FeatureComponent } from 'src/framing/feature.component';
import { CadAppController } from '../../cad-app.controller';
import { CadAppModel } from '../../cad-app.model';
import { CadAppView } from '../../cad-app.view';

const defaultRibbon: Ribbon = {
  model: {
    hasSave: false,
    hasBack: false,
  },
  view: {},
};

@Component({
  selector: 'ui-ribbon',
  templateUrl: './ribbon.component.html',
  styleUrls: [ './ribbon.component.less' ],
})
export class RibbonComponent extends FeatureComponent<CadAppModel, CadAppView, CadAppController> {

  public showSave: boolean = false;

  public envMessage: string = '';

  public saveButtonLabel: string;

  public ribbon: Ribbon = defaultRibbon;

  constructor(
    private location: Location,
    private actionsService: ActionService,
    private configService: ConfigService,
    private ribbonService: RibbonService,
    controller: CadAppController,
    injector: Injector,
  ) {
    super(controller, injector);

    this.subs.newSub = this.ribbonService.ribbon$.subscribe((ribbon: Ribbon) => this.onRibbon(ribbon));

    if (this.configService.cadConfig.isDev) {
      this.envMessage = this.configService.cadConfig.envName;
    }
  }

  public goBack(): void {
    this.location.back();
  }

  private canPerformSave(): boolean {
    return true; // this.actionsService.canPerformAction(cad.actionConstants.ACTION_SAVE);
  }

  private onRibbon(ribbon: Ribbon): void {
    this.ribbon = ribbon || defaultRibbon;
    this.showSave = this.ribbon.model.hasSave && this.canPerformSave();
    this.saveButtonLabel = this.ribbon.model.saveButtonLabel;
  }
}
