/**
 * Created by jsunseri on 3/21/2019.
 */

import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { IFilterAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams, RowNode } from '@ag-grid-community/core';

@Component({
  selector: 'checkbox-boolean-filter-cell',
  template: ``,
})
export class CheckBoxBooleanFilter implements IFilterAngularComp {
  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;
  public model: any;

  @ViewChild('input', { read: ViewContainerRef }) public input: any;

  agInit(params: IFilterParams): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
  }

  isFilterActive(): boolean {
    return this.model !== null && this.model !== undefined && this.model !== '';
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    let value = this.valueGetter(params.node);
    if ( this.model.filter !== '' ) {
      return value === this.model.filter;
    }
    return true;
  }

  getModel(): any {
    return this.model;
  }

  setModel(model: any): void {
    this.model = model ? model : '';
  }

  ngAfterViewInit(params: IAfterGuiAttachedParams): void {
    setTimeout(() => {
      if (this.input && this.input.element && this.input.element.nativeElement) {
        this.input.element.nativeElement.focus();
      }
    });
  }

  // noinspection JSMethodCanBeStatic
  componentMethod(message: string): void {
    alert(`Alert from PartialMatchFilterComponent ${message}`);
  }

  onChange(newValue: any): void {
    if (this.model.value !== newValue) {
      this.model.value = newValue;
    }
  }
}
