import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllocationScheduleQuantityBase } from './abstract/allocation-schedule';
import { Recent } from './abstract/recent';
import { UiFormBaseSelectComponent } from 'src/cad/shared/forms/select/base/base-select.component';
import { Controller } from 'src/framing/controller';
import { FeatureComponent } from 'src/framing/feature.component';
import { MatDateSelectionModel } from 'src/common/components/form/mat-datepicker/date-selection-model';

let components = [ 
  AllocationScheduleQuantityBase,
  Recent,
  UiFormBaseSelectComponent,
  Controller,
  FeatureComponent,
  MatDateSelectionModel
];

@NgModule({
  imports: [ CommonModule, ],
  declarations: [ components ],
  exports: [ components ],
})
export class AbstractModelsModule {
}
