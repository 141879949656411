import {Injectable, Injector} from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import {RouterService} from 'src/cad/core/services/router/router.service';

@Injectable()
export class MaintainParkingLimitsApi {
  private readonly endpoint: string = 'flow/maintain-parking-limits';

  constructor(
    public apiHelper: ApiHelper,
    public routerService: RouterService
  ) {}

  public retrieveParkLimits(effDate: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/retrieve/park-limits', { body: { flowDate: effDate } });
  }

  public retrieveAssetConfigParams(effDate: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/retrieve/asset-config-param', { body: { flowDate: effDate } });
  }

  public saveAssetConfigParams(assetConfigParams: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save/asset-config-param', { body: assetConfigParams });
  }

  public retrieveProrateFactor(effDate: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/retrieve/prorate-factors', { body: { effBeginDt: effDate } });
  }

  public retrieveParkParameters(effDate: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/retrieve/park-parameters', { body: { effBeginDt: effDate } });
  }

  public retrieveParkParametersHistory(parkUnparkParameter: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/retrieve/park-parameters/history', { body: parkUnparkParameter });
  }

  public saveParkLimits(parkLimits: any[]): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save/park-limits', { body: parkLimits });
  }

  public saveProrateFactors(proRateFacotrs: any[]): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save/prorate-factors', { body: proRateFacotrs });
  }

  public saveParkParameters(parkParameters: any[]): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save/park-parameters', { body: parkParameters });
  }

}
