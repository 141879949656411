import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { TableColumnComponent } from 'src/ag-grid-wrapper';
import { BooleanUtils } from 'src/ag-grid-wrapper/utils/boolean-utils';
import { HeaderFilterType } from 'src/ag-grid-wrapper/interfaces/header-filter-type';
import { isNil } from 'lodash';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'table-header-filter-criteria',
  templateUrl: './table-header-filter-criteria.component.html',
  styleUrls: [ './table-header-filter-criteria.component.less' ]
})
export class TableHeaderFilterCriteriaComponent {

  @ViewChild('filterTrigger') public filterTrigger: MatMenuTrigger;
  @Input() public filterTypeList: HeaderFilterType[];
  @Input()
  public get filterCriteria(): string | number {
    return this._filterCriteria;
  }
  public set filterCriteria(value: string | number) {
    if (this.getParsedFilterValue(this._filterCriteria) !== this.getParsedFilterValue(value)) {
      this._filterCriteria = this.getParsedFilterValue(value);
      this.filterCriteriaChange.emit(this._filterCriteria);
      this.onFilterChange.emit(this._filterCriteria);
    }
  }
  @Input()
  public get filterType(): string {
    return this._filterType;
  }
  public set filterType(value: string) {
    if (this._filterType !== value) {
      this._filterType = value;
      this.filterTypeChange.emit(this._filterType);
    }
  }
  @Input()
  public get dateRangeTo(): string {
    return this._dateRangeTo;
  }
  public set dateRangeTo(value: string) {
    if (this._dateRangeTo !== value) {
      this._dateRangeTo = value;
      this.dateRangeToChange.emit(this._dateRangeTo);
      this.onFilterChange.emit(this._dateRangeTo);
    }
  }
  @Input() public columnType: string | string[];
  @Input() public colDef: TableColumnComponent;
  @Output() public filterCriteriaChange: EventEmitter<string | number> = new EventEmitter<string | number>();
  @Output() public filterTypeChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() public dateRangeToChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() public onFilterChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onClearFilter: EventEmitter<any> = new EventEmitter<any>();
  public indeterminate: boolean = true;
  public checkboxState: boolean = true;
  public checkClickCount: number = 0;
  private _filterCriteria: string | number;
  private _filterType: string;
  private _dateRangeTo: string;

  public setFilterType(event: MouseEvent, type: string): void {
    if (event) {
      event.stopPropagation();
    }
    this.filterType = type;
    this.onFilterChange.emit(this.filterCriteria);
    if (this.filterTrigger) {
      this.filterTrigger.closeMenu();
    }
  }

  public checkboxChange(event: MatCheckboxChange): void {
    let val: ('Y' | 'N') | (0 | 1) | boolean | string;
    let modelType: string;
    this.checkClickCount++;
    if (this.checkClickCount % 3) {
      if (this.colDef) {
        modelType = this.colDef.toggleModelType;
      }
      if (modelType === 'yesno') {
        val = BooleanUtils.toYN(event && event.checked);
      } else if (modelType === 'onezero') {
        val = BooleanUtils.toBinary(event && event.checked);
      } else if (modelType === 'truefalse') {
        val = event.checked;
      }
    } else {
      val = '';
      this.indeterminate = true;
    }
    this.onFilterChange.emit(val);
  }

  private getParsedFilterValue(filterValue: string | number): string | number {
    return isNil(filterValue)
      ? ''
      : filterValue;
  }
}
