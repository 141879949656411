import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCache } from 'cad/common/services/api/api-cache';
import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class PhysicalStatesApi {
  private readonly endpoint: string = 'physical/states/';
  private readonly suffix: string = '/counties';

  constructor(
    private apiHelper: ApiHelper,
    private apiCache: ApiCache,
  ) {}

  public filter = (params?: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + 'filter', { body: params, cache: this.apiCache.for30Days });
  }

  public getCounties = (params?: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + params + this.suffix, { cache: this.apiCache.for30Days });
  }
}
