import { Component, Inject } from '@angular/core';
import { TABLE_RENDERER_DATA } from 'src/ag-grid-wrapper';

@Component({
  selector: 'table-phone-renderer',
  template: `
    <div class="inner-cell">
      <div class="inner-cell-dropdown-value">{{params.value| phoneNumber}}</div>
    </div>
  `,
})
export class PhoneNumberRendererComponent {
  constructor(@Inject(TABLE_RENDERER_DATA) public params: any) { }
}
