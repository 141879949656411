import {Component, OnInit} from '@angular/core';
import { BaseDashComponent } from 'src/cad/dashboard/views/content/components/base-dash.component';

const emptyHeadline = 'No Recent Addresses';
const defaultHeadline = 'Recent Addresses';

@Component({
  selector: 'address-dash',
  templateUrl: './address-dash.component.html',
})
export class AddressDashComponent extends BaseDashComponent implements OnInit {
  public name: string = 'address';
  public endPoint: string = 'associate/address/dashboard';

  ngOnInit(): void {
    super.ngOnInit();
  }

  public getEmptyHeadline(): string {
    return emptyHeadline;
  }
  public getDefaultHeadline(): string {
    return defaultHeadline;
  }
  public getName(): string {
    return this.name;
  }

  public onRowClicked(event: any): void {
    super.navigate([ 'data-administration','address', event.data.addressId ]);
  }
}
