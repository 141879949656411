
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCache } from 'cad/common/services/api/api-cache';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import * as _ from 'lodash';

@Injectable()
export class AgreementRateScheduleApi {
  private readonly endpoint: string = 'agreement/rate-schedule';

  constructor(
    private apiHelper: ApiHelper,
    private apiCache: ApiCache,
    private userModel: UserModelService,
  ) {}

  public findAll = (): Observable<any> => {
    return this.apiHelper.request(
      this.endpoint + '/' + this.userModel.getCurrentAssetNbr(),
      { cache: this.apiCache.for30Days }
    ).pipe(map((rateScheduleList: any[]) => {
      if (_.isArray(rateScheduleList)) {
        rateScheduleList.map((rateSchedule: any) => {
          if (_.isArray(rateScheduleList)) {
            if (_.isNil(rateSchedule.rateScheduleCd)) {
              rateSchedule.rateScheduleCd = rateSchedule.code;
            }
            if (_.isNil(rateSchedule.rateScheduleDesc)) {
              rateSchedule.rateScheduleDesc = rateSchedule.description;
            }
            if (_.isNil(rateSchedule.assetNbr)) {
              rateSchedule.assetNbr = rateSchedule.assetNumber;
            }
            if (_.isNil(rateSchedule.firmIntIndCV)) {
              rateSchedule.firmIntIndCV = { cd: rateSchedule.type, value: rateSchedule.type };
            }
          }
          return rateSchedule;
        });
      }
      return rateScheduleList;
    }));
  }

  filter = (param: any): Observable<any> => {
    return this.apiHelper.request(`${this.endpoint}/filter`, { body: param });
  }
}
