import { Injectable } from '@angular/core';
import { CacheFactory } from 'common/services/cache-factory/cache-factory';

@Injectable()
export class ApiCache {

  public readonly second: number = 1000;
  public readonly minute: number = this.second * 60;
  public readonly hour: number = this.minute * 60;
  public readonly day: number = this.hour * 24;

  public inMemory: CacheFactory = CacheFactory.createCache(
    'forMemory');

  public for15Minutes: CacheFactory = CacheFactory.createCache(
    'for15Minutes', {
      maxAge: 15 * this.minute,
    });

  public forSession: CacheFactory = CacheFactory.createCache(
    'forSession', {
      storageMode: 'sessionStorage',
    });

  public for4Hours: CacheFactory = CacheFactory.createCache(
    'for4Hours', {
      maxAge: 4 * this.hour,
      storageMode: 'localStorage',
    });

  public for30Days: CacheFactory = CacheFactory.createCache(
    'for30Days', {
      maxAge: 30 * this.day,
      storageMode: 'localStorage',
    });
  public for1Day: CacheFactory = CacheFactory.createCache(
    'for1Days', {
      maxAge: 1 * this.day,
      storageMode: 'localStorage',
    });
}
