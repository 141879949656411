import { Injector, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AssociationController } from 'src/features/common/association/association.controller';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ApiCache } from 'cad/common/services/api/api-cache';
import { AssociationApi } from 'src/features/common/association/association-api';

@Injectable()
export class PhysicalLocationInternalContactsApi implements AssociationApi {
  private readonly endpoint: string = 'physical/location/';
  private readonly suffix: string = '/internal-contacts';

  static createAssociationApi(
    apiHelper: ApiHelper,
    associationController: AssociationController,
    injector: Injector,
  ): AssociationApi {
    return new PhysicalLocationInternalContactsApi(apiHelper, injector.get(ApiCache));
  }

  constructor(
    private apiHelper: ApiHelper,
    private apiCache: ApiCache,
  ) {}

  /* TODO: self.filter should be renamed to self.findAll */
  public findAll = (pointCd: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + pointCd + this.suffix);
  }

  public getAll = (pointCd: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + pointCd + this.suffix + '/all');
  }

  public save = (internalContacts: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + internalContacts[0].pointCd + this.suffix + '/save', { body: internalContacts });
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + params.pointCd + this.suffix + '/all' );
  }
}
