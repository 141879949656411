import { Injector, Input, OnInit, Directive } from '@angular/core';
import { EmitterService } from 'src/cad/core/services/emitter/emitter.service';
import { ActionService } from 'src/cad/common/services/security/action.service';
import { AutoUnsubscribables, AutoUnsubscriber } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';

@Directive()
export abstract class BaseButtonComponent implements OnInit {

  @Input() public securityAction: string;
  @Input() public securityType: string;
  @Input() public securityContext: string;
  public emitterService: EmitterService;
  public actionService: ActionService;
  securityDisabled: boolean = false;
  @AutoUnsubscriber() protected subs: AutoUnsubscribables;

  constructor(protected injector: Injector) {
    if (injector) {
      this.emitterService = injector.get(EmitterService);
      this.actionService = injector.get(ActionService);
    }
  }

  public ngOnInit (): void {
    if (this.emitterService) {
      this.subs.newSub = this.emitterService.actionContextResolved.subscribe(() => {
        if (this.securityType
          && this.securityAction
          && this.actionService) {
          this.securityDisabled =
            !this.actionService.canPerform(this.securityType, this.securityAction, this.securityContext);
        } else {
          this.securityDisabled = false;
        }
      });
    }
  }
}
