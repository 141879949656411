
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import * as _ from 'lodash';

@Injectable()
export class IndexPricesApi {

  private readonly endpoint: string = 'settlement/index-prices';

  constructor(
    private apiHelper: ApiHelper
  ) {}

  public getById(id: number | string): Observable<any> {
    if (_.isNil(id)) {
      return observableOf({});
    }
    return this.apiHelper.request(this.endpoint + '/' + id);
  }

  public find(params: any): Observable<any> {
    return (params && !_.isNil(params)) ? this.getById(params.indexCode) : observableOf({});
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public recent(): Observable<any> {
    // Dashboard/Recent does not apply to Index Prices
    return observableOf([]);
  }

  public save(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save', { body: params });
  }

  public deleteItem(params: any): Observable<any> {
    // Delete does not apply to Index Prices
    return observableOf({});
  }

  public createNewIndexPriceListDataList(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/create-new-index-price-list', { body: params });
  }

  public calculateIndexPrice(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/calculate-index-price', { body: params });
  }

  public getGasPriceIndexList(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/gas-price-index-list', { body: params });
  }

  public getIndexPriceList(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/index-price-list', { body: params });
  }

  public getSpotPriceSearchList(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/spot-price-search-list', { body: params });
  }

  public getSpotPriceList(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/spot-price-list', { body: params });
  }
}
