
import {of as observableOf,  Observable } from 'rxjs';
import { Injector, Injectable } from '@angular/core';

import { AssociationController } from 'src/features/common/association/association.controller';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AssociationApi } from 'src/features/common/association/association-api';
import { RelatedContractData } from 'src/cad/contracts/shared/interfaces/related-contract-data';
import { ContractPointData } from 'src/cad/contracts/shared/interfaces/contract-point-data';
import * as _ from 'lodash';

@Injectable()
export class AgreementContractForwardRelatedApi implements AssociationApi {
  private readonly endpoint: string = 'agreement/contract/related';

  static createAssociationApi(
    apiHelper: ApiHelper,
    associationController: AssociationController,
    injector: Injector,
  ): AssociationApi {
    return new AgreementContractForwardRelatedApi(apiHelper);
  }

  constructor(
    private apiHelper: ApiHelper,
  ) {}
  
  public filter(params: any): Observable<(ContractPointData | mplus.ContractData)[]> {
    let returnVal: Observable<any>;
    
    if (params && _.has(params, 'populator')) {
      let paramsObj: any = _.omit(params, 'populator');
      let lookupActionName: string = _.get(params, 'populator');
      
      switch (lookupActionName) {
        case 'relatedPointLookup': {
          returnVal = this.getRelatedPointList(paramsObj);
          break;
        }
        default: {
          break;
        }
      }
    } else {
      returnVal = this.apiHelper.request(`${ this.endpoint }/filter`, { body: params });
    }
    return returnVal ? returnVal : observableOf([]);
  }

  public findAll = (id: number): Observable<any> => {
    if (!id) {
      return observableOf([]);
    }
    return this.apiHelper.request(`${ this.endpoint }/${ id }/forward`);
  }

  public save = (relatedData: any): Observable<any> => {
    /* tslint:disable:no-param-reassign */
    /*if (related.length) { related = related[0]; }
     if (Object.prototype.toString.call(related) === '[object Object]') { related = [ related ]; }*/ // FIXME(ng2) -- that could get cleaner
    /* tslint:enable:no-param-reassign */
    let saveData: any = (_.isArray(relatedData) && relatedData.length > 0)
      ? relatedData[ 0 ]
      : relatedData;
  
    if (saveData && !_.isNil(saveData.id)) {
      return this.apiHelper.request(
        `${ this.endpoint }/${ saveData.id }`,
        { body: _.isArray(relatedData) ? relatedData : [ relatedData ] }
      );
    }
    return observableOf({});
  }
  
  public isNewEnabled(contractId: string | number): Observable<boolean> {
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }/is-new-enable`);
  }
  
  public getRelatedPointList(params: RelatedContractData): Observable<ContractPointData[]> {
    return this.apiHelper.request(`${ this.endpoint }/related-point-list`, { body: params });
  }
}
