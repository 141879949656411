<ui-modal-header (close)="onNoClick()">Session Timeout</ui-modal-header>

<ui-modal-body>
  <div fxLayout="row" fxLayout.sm="column" class="col-padding">
    Your session has been timed out.  You were logged out on:
  </div>
  <br>
  <div fxLayout="row" fxLayoutAlign="center center" class="col-padding">
    {{logoutTime}}
  </div>
</ui-modal-body>

<ui-modal-footer>
  <button mat-raised-button type="button" (click)="onNoClick()"  color="primary">OK</button>
</ui-modal-footer>
