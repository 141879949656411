
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AssetGroup } from 'src/common/types/cad.constants';
import { ApiCache } from 'cad/common/services/api/api-cache';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import { ItemApi } from 'src/features/common/item/item-api';
import { ContractSummarySeasonalApi } from './contract-summary-seasonal';
import { ContractFilterData } from 'src/cad/contracts/shared/interfaces/contract-filter';
import * as _ from 'lodash';

@Injectable()
export class ContractSummaryApi extends ItemApi {

  private readonly endpoint: string = 'agreement/contract';
  protected contractType: string = 'SUMMARY';

  constructor(
    public apiHelper: ApiHelper,
    public apiCache: ApiCache,
    public userModelService: UserModelService,
    public contractSummarySeasonalApi: ContractSummarySeasonalApi
  ) {
    super();
  }
  
  public getById(id: number | string): Observable<any> {
    if (_.isNil(id)) {
      return observableOf({});
    }
    if (this.isContextFG()) {
      return this.contractSummarySeasonalApi.getById(id);
    } else {
      return this.apiHelper.request(`${ this.endpoint }/${ id }/summary`);
    }
  }
  
  public find(params: any): Observable<any> {
    return params ? this.getById(params.contractId) : observableOf({});
  }

  public deleteItem(id: number): Observable<any> {
    // Delete does not apply to Contract Summary
    return observableOf({});
  }

  public findAll(): Observable<mplus.ContractData[]> {
    return this.apiHelper.request(`${ this.endpoint }`);
  }

  public filter(params: Partial<ContractFilterData>): Observable<ContractFilterData[]> {
    params.isContract = this.contractType;
    return this.apiHelper.request(`${ this.endpoint }/filter`, { body: params });
  }

  public recent(): Observable<ContractFilterData[]> {
    return this.apiHelper.request(`${ this.endpoint }/dashboard`, { body: { isContract: this.contractType } });
  }

  public save(data: any): Observable<mplus.ContractData[]> {
    // Save does not apply to Contract Summary
    return observableOf([]);
  }

  public getPendingAmendment(id: number): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/${ id }/pending-amendment`);
  }
  
  public isContextFG(): boolean {
    return (this.userModelService
      && this.contractSummarySeasonalApi
      && this.userModelService.getCurrentContext()
      && this.userModelService.getCurrentContext().assetGroupCd === AssetGroup.FG);
  }
}
