
import {throwError as observableThrowError,  Observable ,  ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, finalize, map } from 'rxjs/operators';


@Injectable()
export class PendingInterceptor implements HttpInterceptor {
  private _pendingRequests: number = 0;
  private _pendingRequestsStatus: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private _filteredUrlPatterns: RegExp[] = [ new RegExp('nomination\\/nomination-matrix\\/contract\\/activity-code\\/filter') ];

  get pendingRequestsStatus(): Observable<boolean> {
    return this._pendingRequestsStatus.asObservable();
  }

  get pendingRequests(): number {
    return this._pendingRequests;
  }

  get filteredUrlPatterns(): RegExp[] {
    return this._filteredUrlPatterns;
  }

  private shouldBypass(url: string): boolean {
    return this._filteredUrlPatterns.some((e) => {
      return e.test(url);
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const shouldBypass = this.shouldBypass(req.url);

    if (!shouldBypass) {
      this._pendingRequests++;

      if (1 === this._pendingRequests) {
        this._pendingRequestsStatus.next(true);
      }
    }

    return next.handle(req).pipe(
      map((event) => {
        return event;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
      finalize(() => {
        if (!shouldBypass) {
          this._pendingRequests--;
          if (0 === this._pendingRequests) {
            this._pendingRequestsStatus.next(false);
          }
        }
      })
    );
  }
}
export function PendingInterceptorServiceFactory(): PendingInterceptor {
  return new PendingInterceptor();
}

