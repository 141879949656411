import { Component, Input, Output, EventEmitter, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ui-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: [ './nav-link.component.less' ],
})
export class UiNavLinkComponent implements OnDestroy {


  @Input() public section: any; // bound by bindings
  @Output() public onSelection: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public ngOnDestroy(): void {
    this.section = null;
  }

  public select(): void {
    if( !this.section.hide && this.section.scroll && this.section.scrollTarget) {
      const scrollTargetElemt = this.document.getElementById(this.section.scrollTarget);
      if (scrollTargetElemt) {
        scrollTargetElemt.scrollIntoView();
      } else {
        console.warn(`unable to find scroll target with id: ${this.section.scrollTarget}`);
      }

    }
    this.onSelection.emit(true);
  }

}
