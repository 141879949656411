
import {of as observableOf,  Observable } from 'rxjs';
import { ItemApi } from './../../../../../../features/common/item/item-api';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { Injectable } from '@angular/core';
import { OffSystemPointGroup } from 'src/cad/physical/interfaces/off-system-point-group';
import { PointGroupPoint } from 'src/cad/physical/interfaces/point-group-point';

@Injectable()
export class PhysicalOffSystemPtGroupApi implements ItemApi {
  private endpoint: string = 'physical/location-groups/off-system-point-group';
  constructor(private apiHelper: ApiHelper) { }

  find(params: any): Observable<any> {
    return observableOf([]);
  }

  filter(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/location-group/filter`, { body: params });
  }

  recent(): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/dashboard`);
  }

  save(item: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}`, { body: item });
  }

  deleteItem(item: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}`, { method: 'DELETE', body: item });
  }
  
  getPointGroupAssociatedPoints(filter: Partial<OffSystemPointGroup>): Observable<PointGroupPoint[]> {
    return this.apiHelper.request(`${this.endpoint}/locations`, { body: filter });
  }
}
