import { NgModule } from '@angular/core';
import { SharedModule } from 'cad/shared/shared.module';
import { PipesModule } from 'cad/common/filters/pipes.module';
import { ReportDashComponent } from 'src/cad/dashboard/views/content/components/report-dash.component';
import { ReportFavDashComponent } from 'src/cad/dashboard/views/content/components/report-fav-dash.component';
import { BADashComponent } from 'src/cad/dashboard/views/content/components/ba-dash.component';
import { NomDashComponent } from 'src/cad/dashboard/views/content/components/nom-dash.component';
import { AnnouncementsDashComponent } from 'src/cad/dashboard/views/content/components/announcements-dash.component';
import { ContactDashComponent } from 'src/cad/dashboard/views/content/components/contact-dash.component';
import { AddressDashComponent } from 'src/cad/dashboard/views/content/components/address-dash.component';
import { ContractDashComponent } from 'src/cad/dashboard/views/content/components/contract-dash.component';
import { CapRelDashComponent } from 'src/cad/dashboard/views/content/components/caprel-dash.component';
import { PdfJsViewerModule }  from 'ng2-pdfjs-viewer';
import { OfferReviewDashboardComponent } from 'cad/dashboard/views/content/components/offer-review-dashboard.component';

@NgModule({
  imports: [
    SharedModule,
    PipesModule,
    PdfJsViewerModule
  ],
  declarations: [
    AddressDashComponent,
    ContactDashComponent,
    ReportDashComponent,
    ReportFavDashComponent,
    BADashComponent,
    NomDashComponent,
    AnnouncementsDashComponent,
    ContractDashComponent,
    CapRelDashComponent,
    OfferReviewDashboardComponent
  ],
  exports: [
    AddressDashComponent,
    ContactDashComponent,
    ReportDashComponent,
    ReportFavDashComponent,
    BADashComponent,
    NomDashComponent,
    AnnouncementsDashComponent,
    ContractDashComponent,
    CapRelDashComponent,
    OfferReviewDashboardComponent
  ]
})
export class DashboardComponentsModule {}
