import { ICellRendererComp, ICellRendererParams } from '@ag-grid-community/core';
import * as _ from 'lodash';

export class TableColumnDefaultRendererComponent implements ICellRendererComp {
  public params: any;
  public eGui: HTMLElement;

  init(params: any): void {
    this.params = params;
    this.eGui = document.createElement('div');
    let editable;
    if (_.isFunction(this.params.colDef.editable)) {
      editable = this.params.colDef.editable(params);
    } else {
      editable = this.params.colDef.editable;
    }
    this.eGui.classList.add(editable ? 'inner-cell' : 'inner-cell-not-editable');
    this.eGui.innerHTML = _.isNil(params.valueFormatted) ? params.value : params.valueFormatted;
    if (!params.value) {
      this.eGui.innerHTML = '';
    }
  }

  destroy(): void {
    this.eGui = null;
    this.params = null;
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }

  refresh(params: any): boolean {
    this.params = params;
    this.eGui.innerHTML = (params && !_.isNil(params.valueFormatted))
      ? params.valueFormatted
      : !_.isNil(params.value)
        ? params.value
        : '';
    return true;
  }
}

export function defaultCellRenderer(params: ICellRendererParams): string {
  let cellValue: any = (params && !_.isNil(params.valueFormatted))
    ? params.valueFormatted
    : !_.isNil(params.value)
      ? params.value
      : '';

  return `
    <div class="inner-cell">${cellValue}</div>
  `;
}
