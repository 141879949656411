
import {Availableasset} from 'cad/common/store/user/user-model';
/**
 * Created by jodell on 12/1/2016.
 */
export class UserProfile {

  constructor(private _asset: Availableasset, private _ba: string, private _userId: string) {

  }

  getAsset(): Availableasset {
    return this._asset;
  }

  getBa(): string {
    return this._ba;
  }

  getUserId(): string {
    return this._userId;
  }

  getAssetName(): string {
    if (this._asset instanceof Object) {
      return this._asset.name;
    } else {
      return this._asset;
    }
  }
}
