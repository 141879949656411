import { Injectable, Injector } from '@angular/core';
import { PinnedItemApi } from 'cad/common/services/api/nomination/pinned-item/pinned-item';

@Injectable()
export class NominationConfirmationPinnedLocationApi extends PinnedItemApi {

  constructor(injector: Injector) {
    super(injector);
    this.endpoint = 'nomination/confirmation/pinned-location';
  }
}
