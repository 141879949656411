import { Component, Inject, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { TABLE_EDITOR_DATA } from 'src/ag-grid-wrapper';

@Component({
  selector: 'table-integer-editor',
  template: `
    <div class="integer-number">
      <input #input matInput [(ngModel)]="params.value" type="number" step="1" (keypress)="onKeyPress($event)">
    </div>
  `,
  styleUrls: [ './integer-number-editor.component.less' ],
})

export class IntegerNumberEditorComponent implements AfterViewInit {

  @ViewChild('input', { read: ViewContainerRef }) public input: any;

  constructor (@Inject(TABLE_EDITOR_DATA) public params: any) {}

  ngAfterViewInit(): void {
    setTimeout((): void => {
      if (this.input && this.input.element && this.input.element.nativeElement) {
        this.input.element.nativeElement.focus();
        this.input.element.nativeElement.select();
      }
    });
  }

  public onKeyPress(event: any): any {
    return ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 45)? true : false;
  }
}
