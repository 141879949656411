export const ANNOUNCEMENT_ITEM_LAYOUT = {
  defaults: {
    emergency: {
      phone: '',
      media: ''
    },
    customerService: {
      phone: '',
      tollFree: '',
      email: ''
    },
    messenger: {
      phone: '',
      tollFree: '',
      email: ''
    },
    oncall: {
      hours: '',
      phone: '',
      email: ''
    }
  },
  pipelineGroups: {
    FG: {
      emergency: {
        phone: '(800) 238-5066',
        media: '(214) 840-5820'
      },
      customerService: {
        phone: '(713) 989-2500',
        email: 'FGTTeam.Mailbox@energytransfer.com'
      },
      messenger: {
        phone: '(713) 989-2500',
        email: 'FGTTeam.Mailbox@energytransfer.com'
      },
      oncall: {
        hours: '7AM - 10PM',
        phone: '(713) 989-2500',
        email: 'FGTTeam.Mailbox@energytransfer.com'
      }
    },
    PE: {
      emergency: {
        phone: '(800) 225-3913',
        media: '(214) 599-8785'
      },
      customerService: {
        phone: '(713) 989-4272',
        tollFree: '(800) 275-7375',
        email: 'pipelinecos@energytransfer.com'
      },
      messenger: {
        phone: '(713) 989-7800',
        tollFree: '(877) 689-7800',
        email: 'messenger@energytransfer.com'
      },
      oncall: {
        hours: '7AM - 10PM',
        phone: '(713) 962-9862',
        email: 'scheduleroncall@energytransfer.com'
      }
    },
    ET: {
      emergency: {
        phone: '',
        media: '(214) 840-5820'
      },
      customerService: {
        phone: '(713) 989-2440',
        email: 'MarketingServicesTWfax@energytransfer.com'
      },
      messenger: {
        phone: '(713) 989-2777',
        tollFree: '(866) 944-7290',
        email: 'MarketingServicesTWfax@energytransfer.com'
      },
      oncall: {
        hours: '7AM - 10PM',
        phone: '',
        email: ''
      }

    }
  },

  pipelines: {
    TW: {
      emergency: {
        phone: '(866) 999-8975',
        media: '(214) 840-5820'
      }
    },
    TGR: {
      emergency: {
        phone: '(866) 844-3735',
        media: '(214) 840-5820'
      }
    },
    FEP: {
      emergency: {
        phone: '(888) 844-8030',
        media: '(214) 840-5820'
      }
    }


  }
};
