<mat-toolbar class="table-lookup-dialog-toolbar">
  <div mat-toolbar-row
       fxFlex
       fxLayout="row">
    <h2 class="title">{{title}}</h2>
    <div fxFlex
         fxLayoutAlign="end center">
      <button type="button"
              mat-button
              mat-icon-button
              matDialogClose>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<ng-container *ngIf="dialogData?.component">
  <mat-dialog-content fxLayout="column"
                      fxFlex>
    <ng-container *ngComponentOutlet="dialogData.component.componentType"></ng-container>
  </mat-dialog-content>
</ng-container>
<div matDialogActions
     fxLayout="row"
     fxLayoutGap="18px"
     fxLayoutAlign="end center">
  <button type="button"
          mat-raised-button
          skip-disable
          matDialogClose>CANCEL
  </button>
  <button type="button"
          mat-raised-button
          color="primary"
          (click)="ok()"
          [disabled]="isSubmitDisabled()">SELECT
  </button>
</div>
