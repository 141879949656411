import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from 'src/cad/app-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TableColumnLookupDialogComponent } from './lookup-dialog.component';
import { TableColumnLookupEditorComponent } from './lookup-editor.component';
import { TableColumnLookupRendererComponent } from './lookup-renderer.component';
import { TableColumnContextMenuModule } from '../context-menu/module';

const subModules = [
  TableColumnLookupEditorComponent,
  TableColumnLookupDialogComponent,
  TableColumnLookupRendererComponent,
];

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    FlexLayoutModule,
    FormsModule,
    TableColumnContextMenuModule,
  ],
  exports: [ subModules ],
  declarations: [ subModules ],
  entryComponents: [ subModules ],
})
export class TableColumnLookupModule {
}
