
import { RowNode } from '@ag-grid-community/core';
import { Component, Injector, OnDestroy } from '@angular/core';
import { ApiCache } from 'cad/common/services/api/api-cache';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { DirtyStatus } from 'common/types/cad.constants';
import { CUSTOM_CHIP_DATA } from 'common/types/chip-data';
import * as _ from 'lodash';
import { Observable, of as observableOf, ReplaySubject } from 'rxjs';
import { ItemDetailController } from 'src/features/common/item-detail/item-detail.controller';
import { ItemApiService } from 'src/features/common/item/item-api.service';
import { ItemController } from 'src/features/common/item/item.controller';
import { ItemApiConfig } from 'src/features/common/item/item.model';
import { Controller } from 'src/framing/controller';
import { AssociationApi } from './association-api';
import { AssociationApiService } from './association-api.service';
import { AssociationModel } from './association.model';
import { AssociationView } from './association.view';

@Component({
  selector: 'controller',
  template: '',
})
export class AssociationController extends Controller<AssociationModel, AssociationView> implements OnDestroy {

  public associationApiService: AssociationApi;
  public fromController: ItemController;
  public toApiService: ItemApiService;
  public itemDetailController: ItemDetailController;
  public gridApi: any;
  /**
   * From Item Data
   */
  public itemData: any = {};

  /**
   * To Item Created
   */
  public newDataItem: any = {};

  /**
   * Items from the on-page grid list (current associations)
   */
  public items: any[] = [];

  /**
   * Original Items from the on-page grid list (current associations)
   */
  public originalItems: any[] = [];

  /**
   * An observable of the items.
   */
  public get items$(): Observable<any> { return this.itemsSubject; }
  /**
   * When adding a new association, it gets created on this object
   */
  public newAssociation: any = {};
  /**
   * Selected Items for fullscreen modal search results
   */
  public selectedItems: any[] = [];
  /**
   * Selected Items for on-page association grid
   */
  public listSelections: RowNode[] = [];

  /**
   * Save function reference of the association grid
   */
  public listSaveRowFn: (rowEntity: any) => Promise<any>;

  /**
   * Holds injectors used for custom chips in association modals
   */
  public chipInjectors: Injector[] = [];

  protected firstDayofMonth: any = dateUtil().getFirstDayOfMonth();
  protected defaultEndDate: any = dateUtil().getDefaultEndDate();

  /**
   * ReplaySubject for items observable.
   */
  protected itemsSubject: ReplaySubject<any> = new ReplaySubject<any>(1);

  // ==========================================================================

  constructor(injector: Injector) { super(injector); }

  /**
   *
   */
  init(): void { }

  /**
   *
   */
  preSave(items: any[]): any[] { return items; }

  /**
   *
   */
  postSave(savedItems: any = []): void { }

  /**
   * @description Accepts the association item(s) to delete and sets the return back to the association item(s).
   * This gives the opportunity to adjust or swap out the object(s) from the generic rowData.
   * @param items
   * @returns {any[]}
   */
  preDelete(items: any[]): any[] { return items; }

  // ==========================================================================

  /**
   *
   */
  onControllerInit(): void {
    /* 
    * Previous usage was to dynamically generate a new module and provide the service,
    * then grab that module's injection context to get the instance of the service.
    * This usage just creates the instance itself, which *shouldn't* cause breakage downstream
    * 
    * Ideally we'd just put `private toApiService: ItemApiService` and access this.toApiService, but because many classes
    * are extending this class then we'd have to update the super() calls in all those constructors/classes
    */
    const itemApiConfig: ItemApiConfig = {
      primaryKey: this.model.toModel.primaryKey,
      endpoint: this.model.toModel.endpoint,
      compositeKey: this.model.toModel.compositeKey,
    };
    this.toApiService = new ItemApiService(this.injector.get(ApiCache), this.injector.get(ApiHelper), this.model.toModel.api as any, itemApiConfig);

    this.fromController = this.injector.get(ItemController);
    this.itemDetailController = this.injector.get(ItemDetailController);

    // TODO --- somehow get TypeScript to ensure the expected constructor parameters of this.model.api are correct
    if (this.model.api) {
      this.associationApiService = this.model.api.createAssociationApi(this.injector.get(ApiHelper), this, this.injector);
    } else {
      this.associationApiService = AssociationApiService.createAssociationApi(this.injector.get(ApiHelper), this, this.injector);
    }

    this.subs.newSub = this.itemDetailController.itemData$.subscribe((data) => this.itemData = data);
  }

  /**
   * This method is given to the ui-query directive to perform the new association
   * search.  Override this method to do a custom association search
   */
  associationSearch = (params: any): Observable<any> => {
    let api = this.toApiService.associationFilter || this.toApiService.filter;
    return api.call(this.toApiService, params);
  }

  /**
   * Method used to refresh on the on-page association grid list.  Override this if you have unique requirements for refresh.
   */
  refreshData(primaryKey: number | string): void {
    this.subs.newSub = this.associationApiService.findAll(primaryKey).subscribe((items) => {
      this.items = items;
      this.itemsChanged(this.items);
    });
  }

  /**
   * Default save association method.  Override this method or use a more aggressive presave to change behavior.
   */
  saveNewAssociations(): Observable<any> {
    if (this.selectedItems.length) {
      let prepared = this.prepareNew(this.selectedItems);
      let preSaved = this.preSave(prepared);
      return (preSaved && preSaved.length !== 0) ? this.associationApiService.save(preSaved) : observableOf({});
    } else if (this.newAssociation[ this.model.toModel.primaryKey ]) {
      let preSaved = this.preSave(this.newAssociation);
      return this.associationApiService.save([ preSaved ]);
    } else {
      console.error(`Association is missing ${this.model.toModel.primaryKey}`, this.newAssociation);
      return observableOf({});
    }
  }

  addSelectedItem(item: any): void {
    this.selectedItems.push(item);
    this.chipInjectors.push(Injector.create([ { provide: CUSTOM_CHIP_DATA, useValue: item } ], this.injector));
  }

  removeSelectedItem(item: any): void {
    let index = this.selectedItems.indexOf(item);
    if (index > -1) {
      this.selectedItems.splice(index, 1);
      this.chipInjectors.splice(index, 1);
    }
  }

  /**
   *  This is the default association save preparation.
   */
  prepareNew(list: any): any {
    let associations = _.map(list, (item: any) => {
      let a: any = _.clone(this.newAssociation);

      a[ this.model.toModel.primaryKey ] = item[ this.model.toModel.primaryKey ];
      if (!a.effEndDt) {
        a.effEndDt = item.effEndDt;
      }

      return a;
    });

    _.each(associations, (a) => {
      _.defaults(a, {
        effBeginDt: this.firstDayofMonth,
        effEndDt: this.defaultEndDate,
      });

      a.dirtyStatus = this.getDirtyStatus(a);
    });
    return associations;
  }

  /**
   *  Helper method for association save to add the correct dirty status
   */
  getDirtyStatus = (item: any): DirtyStatus => {
    return item.hasOwnProperty('dirtyStatus') ? (item.dirtyStatus === cad.DirtyStatus.DELETE) ? cad.DirtyStatus.DELETE : cad.DirtyStatus.UPDATE : cad.DirtyStatus.NEW;
  }

  ngOnDestroy(): void {
    if (this.model.listActionMenu) {
      _.forEach(this.model.listActionMenu.items, (item) => {
        item.method = undefined;
        item.isDisabled = undefined;
      });
    }
    super.ngOnDestroy(); // mandatory
    this.itemsSubject = undefined;
    this.cleanupAssociationModal();
  }

  public cleanupAssociationModal(): void {
    this.selectedItems = [];
    this.chipInjectors = [];
    this.newAssociation = {};
  }

  public onGridReady = (event: any): void => {
  }

  public onCellValueChanged = (event: any): void => {
  }

  public onCellEditStarted = (event: any): void => {
  }

  public itemsChanged(items: any): void {
    this.items = items || [];
    if (this.itemData && !_.isEqual(this.itemData[ _.camelCase(this.model.toModel.name + 'ItemList') ], this.items)) {
      this.itemData[ _.camelCase(this.model.toModel.name + 'ItemList') ] = [];
      this.itemData[ _.camelCase(this.model.toModel.name + 'ItemList') ] = this.items;
    }
    this.itemsSubject.next(items);
    this.originalItems = _.cloneDeep(items);
  }
}
