import { Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams, RowNode } from '@ag-grid-community/core';
import * as _ from 'lodash';

export interface RowMessageIconConfig {
  isVisible?: ((rowNode: RowNode) => boolean) | boolean;
  message?: ((params: any) => string) | string;
  iconName?: ((params: any) => string) | string;
  iconClass?: ((params: any) => string) | string;
}

@Component({
  selector: 'row-message-icon-component',
  templateUrl: './message-icon.component.html',
  styleUrls: [ './message-icon.component.less' ]
})
export class RowMessageIconComponent implements AgRendererComponent {

  public params: ICellRendererParams;
  public rowMessage: RowMessageIconConfig;
  public isVisible: boolean;
  public message: string;
  public iconName: string;
  public iconClass: string;

  agInit(params: any): void {
    this.params = params;
    this.setMessageValues();
  }

  refresh(params: any): boolean {
    this.params = params;
    this.setMessageValues();
    return true;
  }

  private setMessageValues(): void {
    if (this.params
      && this.params.colDef
      && (this.params.colDef as any).rowMessage) {
      this.rowMessage = (this.params.colDef as any).rowMessage;
      this.isVisible = _.isFunction(this.rowMessage.isVisible)
        ? this.rowMessage.isVisible(this.params.node)
        : this.rowMessage.isVisible;
      this.message = _.isFunction(this.rowMessage.message)
        ? this.rowMessage.message(this.params)
        : this.rowMessage.message;
      this.iconName = (_.isFunction(this.rowMessage.iconName)
        ? this.rowMessage.iconName(this.params)
        : this.rowMessage.iconName) || 'feedback';
      this.iconClass = _.isFunction(this.rowMessage.iconClass)
        ? this.rowMessage.iconClass(this.params)
        : this.rowMessage.iconClass;
    }
  }
}
