
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ItemApi } from 'src/features/common/item/item-api';
import { LookupApi } from 'src/features/common/modal/modal-feature-types';
import { PhysicalCapacityData } from 'src/cad/physical/interfaces/physical-capacity';
import { PointData } from 'src/cad/contracts/shared/interfaces/point-data';

@Injectable()
export class PhysicalCapacityApi extends ItemApi implements LookupApi {
  private readonly endpoint: string = 'physical/capacity';

  constructor(
    private apiHelper: ApiHelper,
  ) {
    super();
  }

  public filter(params: any): Observable<any> {
    let returnVal: Observable<any>;

    if (params && _.has(params, 'populator')) {
      let paramsObj: any = _.omit(params, 'populator');
      let lookupActionName: string = _.get(params, 'populator');

      switch (lookupActionName) {
        case 'locationCodeLookup': {
          returnVal = this.getPointLookupData(paramsObj);
          break;
        }
        default: {
          break;
        }
      }
    } else {
      returnVal = this.apiHelper.request(this.endpoint + '/filter', { body: params });
    }
    return !_.isNil(returnVal) ? returnVal : observableOf([]);
  }

  public find(params: any): Observable<any> {
    return this.getById(params.physicalCapacityId);
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/dashboard');
  }

  public getById = (id: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/'+ id);
  }

  public save(itemData: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '' , { body: itemData });
  }

  public deleteItem(itemData: PhysicalCapacityData): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/delete', { body: itemData });
  }

  public getPointLookupData(filter: PointData): Observable<PointData[]> {
    return this.apiHelper.request(`${ this.endpoint }/point/lookup`, { body: filter });
  }
}
