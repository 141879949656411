import { Inject, Component } from '@angular/core';
import { CUSTOM_CHIP_DATA } from 'common/types/chip-data';

@Component({
  templateUrl: 'dialog-chip.component.html',
})
export class DialogChipComponent {

  public valueProperty: string = 'id';
  public viewValueProperty: string = 'name';

  constructor(
    @Inject(CUSTOM_CHIP_DATA) public item: any,
  ) {}
}
