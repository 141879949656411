import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'src/cad/common/services/api/api-helper';

@Injectable()
export class CapacityReleaseOfferBidsLocationsApi {
  private readonly endpoint: string = 'capacity-release/offer/';
  private readonly suffix: string = '/bids';
  private readonly suffix2: string = '/locations';

  constructor(private apiHelper: ApiHelper) {}

  public getById = (auctionId: number | string, bidId: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/' + bidId + this.suffix2);
  }

  public save = (auctionId: number | string, bidData: any): Observable<any> => {
    let bidId: number | string = bidData ? bidData.bidID : -1;

    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/' + bidId + this.suffix2 + '/save', { body: bidData });
  }
}
