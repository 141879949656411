<mat-horizontal-stepper [linear]="isLinear">
  <mat-step *ngFor="let stepConfig of stepConfigList; let isFirst=first; let isLast=last"
            #matStepRef="matStep">
    <ng-template matStepLabel>{{stepConfig?.label}}</ng-template>
    <div fxLayout="column"
         fxLayoutGap="18px">
      <div class="step-header-label">
        {{stepConfig?.label}}
      </div>
      <div class="step-content-container">
        <ng-container [framingComponentOutlet]="stepConfig?.componentType"
                      (onComponent)="onStepReady($event, stepConfig, matStepRef)">
        </ng-container>
      </div>
      <div class="step-footer-container">
        <ng-container *ngIf="stepConfig?.footerComponentType; else defaultFooterTemplate"
                      [framingComponentOutlet]="stepConfig?.footerComponentType"
                      (onComponent)="onFooterReady($event, stepConfig)">
        </ng-container>
        <ng-template #defaultFooterTemplate>
          <div fxLayout="row"
               fxLayoutAlign="end center"
               fxLayoutGap="10px">
            <button *ngIf="!isLast"
                    type="button"
                    color="primary"
                    (click)="submitData()"
                    mat-button>Submit and Skip Wizard
            </button>
            <button *ngIf="!isFirst"
                    type="button"
                    mat-button
                    mat-raised-button
                    matStepperPrevious>Back
            </button>
            <button *ngIf="!isLast"
                    type="button"
                    mat-button
                    mat-raised-button
                    color="primary"
                    (click)="updateData(stepConfig?.data)"
                    matStepperNext>Next
            </button>
            <button *ngIf="isLast"
                    type="button"
                    mat-button
                    mat-raised-button
                    color="primary"
                    (click)="submitData()">Submit and Review
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </mat-step>
</mat-horizontal-stepper>
