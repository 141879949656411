<ui-button-content [type]="type"
                   [label]="label"
                   [isInvalid]="isInvalid"
                   [securityAction]="securityAction"
                   [securityType]="securityType"
                   [securityContext]="securityContext"
                   [option]="option"
                   [dialogTitle]="dialogTitle"
                   [dialogMessage]="dialogMessage"
                   [dialogLabel]="dialogLabel"
                   [dialogBtnText]="dialogBtnText"
                   [skipDisable]="skipDisable">
  <ng-template #template>
    <ng-content></ng-content>
  </ng-template>
  <ng-template #dropDownTemplate>
    <ng-content select="ui-button-dropdown"></ng-content>
  </ng-template>
</ui-button-content>
