import { Injectable } from '@angular/core';
import { BusinessAssociate } from 'cad/common/services/api/associate/address/business-associate/business-associate';
import { Contact } from 'cad/common/services/api/associate/address/contact/contact';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class AddressApi {
  private readonly endpoint: string = 'associate/address';
  constructor(
    private apiHelper: ApiHelper,
    public businessAssociate: BusinessAssociate,
    public contact: Contact,
  ) {}

  public autoFilter = (contactId: number, params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/context-filter/'+ contactId + '/filter', {body: params});
  }}
