import { Directive, OnInit, OnDestroy, OnChanges, Input } from '@angular/core';

import { UiQueryDirective } from './query.directive';

@Directive({
  selector: 'ui-query-filter-exclude-items',
})
export class UiQueryFilterExcludeItemsDirective implements OnInit, OnDestroy, OnChanges {
  @Input() public idField: string;
  @Input() public items: any;

  private ids: any[] = [];

  constructor(private queryCtrl: UiQueryDirective) {}

  public ngOnInit = () => {
    _.defaults(this, {
      idField: '',
      items: {},
    });

    this.queryCtrl.addFilter(this);

  }

  public ngOnChanges(changes: any): void {
    if (changes.items) {
      this.refresh();
    }
  }

  public ngOnDestroy = () => {
    this.queryCtrl.removeFilter(this);
  }

  public test = (item: any) => {
    return _.indexOf(this.ids, item[this.idField]) === -1;
  }

  private refresh = () => {
    this.ids = _.map(this.items, this.idField);

    setTimeout(() => {
      this.queryCtrl.filter();
    }, 50);
  }
}
