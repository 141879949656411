import { ModalRegistry } from './modal-registry.service';
import { ModalOpenOptions } from './modal-feature-types';
import { AutoUnsubscriber, AutoUnsubscribables } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import { Component, Injector, Injectable, EventEmitter, OnInit, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Controller } from 'src/framing/controller';

import { Subscription ,  Observable ,  ReplaySubject } from 'rxjs';

import { ModalModel } from './modal.model';
import { ModalView } from './modal.view';

import { ItemController } from 'src/features/common/item/item.controller';
import { ItemApiService } from 'src/features/common/item/item-api.service';

import { ModalSkeletonComponent } from './view/modal-skeleton/modal-skeleton.component';

@Component({
  selector: 'modal-controller-component',
  template: '',
})
export class ModalController extends Controller<ModalModel, ModalView> implements OnInit {
  public mdDialog: MatDialog;
  public registry: ModalRegistry;

  constructor(injector: Injector, private zone: NgZone) {
    super(injector);
  }

  ngOnInit(): void {
    this.registry.emitModalCtrl(this.model.name, this);
  }

  onControllerInit(): void {
    this.mdDialog = this.injector.get(MatDialog);
    this.registry = this.injector.get(ModalRegistry);
    this.registry.registerModal(this.model.name);
  }

  open(opts?: ModalOpenOptions): Observable<any> {
    let cfg: any = this.createDialogData(opts ? opts : {});
    return this.zone.run(() => this.mdDialog.open(ModalSkeletonComponent, cfg).afterClosed());
  }

  private createDialogData(opts: ModalOpenOptions): any {
    let cfg: any = {};

    _.assign(cfg, this.model.dialogConfig);

    cfg.data = cfg.data || {};
    cfg.data.controller = this;
    cfg.data.allControllers = {};

    this.factoryInjector.get('ModalControllers').forEach((ctrl) => {
      cfg.data.allControllers[ctrl.model.name] = ctrl;
    });

    if (opts.data) {
      cfg.data.bodyData = opts.data;
    }
    if (this.model.type === 'lookup') {
      cfg.data.instanceType = opts.instanceType;
      cfg.data.defaultSearchParams = opts.defaultSearchParams;
      cfg.data.constSearchParams = opts.constSearchParams;
      cfg.data.itemData = opts.itemData;
      cfg.data.tableLookup = opts.tableLookup;
    }
    return cfg;
  }

  ngOnDestroy(): void {
    this.registry.destroy(this.model.name);
  }
}
