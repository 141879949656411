import { AfterViewInit, ElementRef, HostBinding, Injectable, Injector, OnDestroy, Renderer2, Directive, Component } from '@angular/core';
import { AgEditorComponent } from '@ag-grid-community/angular';
import { Column, RowNode } from '@ag-grid-community/core';
import { KeyCode } from '../../table.constants';
import { UiGridApi } from 'src/ag-grid-wrapper';
import * as _ from 'lodash';

@Component({
  template: ''
})
export abstract class TableColumnBaseEditorComponent implements AgEditorComponent, OnDestroy, AfterViewInit {
  @HostBinding('attr.tabindex') public tabindex: number = -1;
  public value: any;

  public params: any;
  public el: ElementRef;
  public renderer: Renderer2;

  protected cancelBeforeStart: boolean = false;
  protected timeoutCancel: any;
  protected clickListener: Function;
  protected appEl: any;
  protected keyListener: Function;

  constructor(public parentInjector: Injector) {
    this.el = parentInjector.get(ElementRef);
    this.renderer = parentInjector.get(Renderer2);
    this.keyListener = this.renderer.listen(this.el.nativeElement, 'keydown', (e: KeyboardEvent) => this.onKeyDown(e));
  }

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
    if (this.timeoutCancel) {
      clearTimeout(this.timeoutCancel);
    }
    // if the row is floating a.k.a footer row do not allow editing.
    if (this.params.node.floating) {
      this.cancelBeforeStart = true;
    }
  }

  getValue(): any {
    if (!_.isNil(this.params)) {
      return this.params.value;
    }
  }

  onKeyDown(e: KeyboardEvent): void {
    let gridApi: UiGridApi = this.params.api;
    let rowNode: RowNode = this.params.node;
    let column: Column = this.params.column;

    if (e.keyCode === KeyCode.Tab) {
      e.stopPropagation();
      e.preventDefault();
      if (e.shiftKey) {
        setTimeout(() => this.previousCell());
      } else {
        setTimeout(() => this.nextCell());
      }
    }
    if ([ KeyCode.Left, KeyCode.Right, KeyCode.Up, KeyCode.Down ].indexOf(e.keyCode) > -1) {
      e.stopPropagation();
      if ([ KeyCode.Up, KeyCode.Down ].indexOf(e.keyCode) > -1
        && gridApi
        && rowNode
        && column
        && column.getColDef()
        && !_.includes([ 'custom', 'select' ], column.getColDef().type)) {
        let nextRowIndex;

        e.preventDefault();
        if (e.keyCode === KeyCode.Down) {
          nextRowIndex = rowNode.rowIndex + 1;
          if (nextRowIndex >= gridApi.getModel().getRowCount()) {
            return;
          }
        } else if (e.keyCode === KeyCode.Up) {
          nextRowIndex = rowNode.rowIndex - 1;
          if (nextRowIndex < 0) {
            return;
          }
        }
        this.params.stopEditing();
        gridApi.clearRangeSelection();
        gridApi.setFocusedCell(nextRowIndex, column);
        if (rowNode.isSelected()
          && (gridApi as any).gridOptionsWrapper
          && (gridApi as any).gridOptionsWrapper.gridOptions
          && (gridApi as any).gridOptionsWrapper.gridOptions.rowSelection === 'single') {
          gridApi.selectIndex(nextRowIndex, null, null);
        }
        if (gridApi.getModel()
          && gridApi.getModel().getRow(nextRowIndex)
          && column.isCellEditable(gridApi.getModel().getRow(nextRowIndex))) {
          gridApi.startEditingCell({
            rowIndex: nextRowIndex,
            colKey: column,
            rowPinned: gridApi.getModel().getRow(nextRowIndex).rowPinned
          });
        }
      }
    }
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  onBlur(event: any): void {
    // this.params.api.stopEditing();
  }

  ngOnDestroy(): void {
    if (this.clickListener) {
      this.clickListener();
    }
    if (this.keyListener) {
      this.keyListener();
    }
    this.appEl = null;
    this.el = null;
  }

  previousCell(): void {
    if (this.params && this.params.api) {
      if (!this.params.api.tabToPreviousCell()) {
        this.params.stopEditing();
      }
    }
  }

  nextCell(): void {
    if (this.params && this.params.api) {
      if (!this.params.api.tabToNextCell()) {
        this.params.api.stopEditing();
      }
    }
  }

  /**
   * This causes the cell to stop editing when you click outside of the grid (modals not included).
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.appEl = document.querySelector('app');
      this.clickListener = this.renderer.listen('document', 'click', (e: MouseEvent) => {
        if (!this.el) { return; }
        if (this.params
          && this.params.api
          && !this.el.nativeElement.contains(e.target) && this.appEl.contains((e.target) as Element)) {
          this.params.api.stopEditing();
        }
      });
    });
  }

  public getCustomCellConfig(): any {
    if (this.params
      && this.params.column
      && this.params.column.getColDef()) {
      return (_.isFunction(this.params.column.getColDef().customCellConfig)
        ? this.params.column.getColDef().customCellConfig(this.params)
        : this.params.column.getColDef().customCellConfig);
    }
    return null;
  }
}
