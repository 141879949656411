import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ui-nav-href',
  templateUrl: './nav-href.component.html',
  styleUrls: [ './nav-href.component.less' ],
})
export class UiNavHrefComponent implements OnInit {
  @Input() section: any;
  activeHref: boolean = false;
  href: string;
  
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.href = `${window.location.href.split('/').slice(0,-1).join('/')}/${this.section.state}`;
    this.activeHref = this.router.url.indexOf(this.section.state) !== -1;
  }
}
