
import {of as observableOf, throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ItemApi } from 'src/features/common/item/item-api';
import * as _ from 'lodash';

@Injectable()
export class GDSImbalanceStorageSchemeApi extends ItemApi {

  private readonly endpoint: string = 'flowing-gas/scheduling/gds-imbalance-storage-scheme';
  private readonly compositeKeyProperties: string[] = [
    'gdsContractId',
    'transactionTypeInd',
    'fromDate',
    'toDate'
  ];

  constructor(
    private apiHelper: ApiHelper
  ) {
    super();
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/dashboard');
  }

  public findAll = (code: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/' + code);
  }

  public getById = (id: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/' + id);
  }

  public find(params: any): Observable<any> {
    let paramsObj: any = this.getProcessedFilterObj(params);

    _.defaults(paramsObj, {
      transactionTypeInd: 'W',
      fromDate: dateUtil().getFirstDayOfMonth(),
      toDate: dateUtil().getLastDayOfMonth()
    });
    return this.apiHelper.request(`${ this.endpoint }/find`, { body: paramsObj });
  }

  public findconfiginfo(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/findconfiginfo', { body: params });
  }

  public new(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/new', { body: params });
  }

  public save(data: any): Observable<any> {
    if (data) {
      return this.apiHelper.request(this.endpoint + '/save', { body: data });
    } else {
      return observableThrowError('Not enough info to save'); // Validation should have taken care of this
    }
  }

  public deleteItem(item: any): Observable<any> {
    return observableOf({msg:'*** DELTE NOT IMPLEMENTED HERE ***'});
  }

  private getProcessedFilterObj(filterObj: any): any {
    return _.pick(filterObj, this.compositeKeyProperties);
  }
}
