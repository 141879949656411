import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ItemApi } from 'src/features/common/item/item-api';
import { CallLog } from 'src/cad/administration/call-log/shared/interfaces/call-log';
import ValidationResult = cad.ValidationResult;
import { isNil } from 'lodash';

@Injectable()
export class CallLogApi extends ItemApi {

  private readonly endpoint: string = 'administration/calllog';
  
  constructor(
    public apiHelper: ApiHelper
  ) {
    super();
  }
  
  public getById(callLogId: number): Observable<CallLog> {
    return this.apiHelper.request(this.endpoint + '/' + callLogId);
  }
  
  public find(filterData: CallLog): Observable<CallLog> {
    if (filterData && !isNil(filterData.callLogId)) {
      return this.getById(filterData.callLogId);
    } else {
      throw new Error('callLogId is invalid.');
    }
  }
  
  public filter(filterData: Partial<CallLog>): Observable<CallLog[]> {
    return this.apiHelper.request(`${ this.endpoint }/filter`, { body: filterData });
  }
  
  public recent(): Observable<CallLog[]> {
    return this.apiHelper.request(`${ this.endpoint }/dashboard`);
  }
  
  public save(saveData: CallLog): Observable<ValidationResult<CallLog>> {
    return this.apiHelper.request(`${ this.endpoint }/save`, { body: saveData });
  }
  
  public deleteItem(deleteData: CallLog): Observable<ValidationResult> {
    throw new Error('Method not implemented.');
  }
}
