import { Component, Inject, OnInit, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CodesModel } from 'src/cad/common/models/codes/codes-model';
import { TABLE_EDITOR_DATA } from 'src/ag-grid-wrapper';

@Component({
  selector: 'table-phone-editor',
  template: `
    <div class="phone-number">
      <input #input matInput [(ngModel)]="params.value" uiInputMask="phoneExtension" [uiInputMaskOptions]="{autoUnmask:true,removeMaskOnSubmit:true}" placeholder="">
    </div>
  `,
  styleUrls: [ './phone-number-editor.component.less' ],
})
export class PhoneNumberEditorComponent implements AfterViewInit {
  @ViewChild('input', { read: ViewContainerRef }) public input: any;
  constructor (@Inject(TABLE_EDITOR_DATA) public params: any) {}

  ngAfterViewInit(): void {
    // Do a setTimeout to allow time for the value to render
    setTimeout((): void => {
      if (this.input && this.input.element && this.input.element.nativeElement) {
        this.input.element.nativeElement.focus();
        this.input.element.nativeElement.select();
      }
    });
  }
}
