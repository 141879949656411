import { NgModule, NgModuleRef, Type ,Compiler, Injector, Component, EventEmitter, OnDestroy } from '@angular/core';
import { Controller } from 'src/framing/controller';
import { Framing } from 'src/framing/framing';
import { Feature } from 'src/framing/feature';
import { FramingNgModule } from 'src/framing/framing-ng-module';

import { Subscription ,  Observable } from 'rxjs';

import { LookupModel } from './lookup.model';
import { LookupView } from './lookup.view';

import { ItemController } from 'src/features/common/item/item.controller';
import { ItemApiService } from 'src/features/common/item/item-api.service';
import { ItemApiConfig } from 'src/features/common/item/item.model';
import { ItemApi } from 'src/features/common/item/item-api';

// function makeTargetModule(injector: Injector, itemApiConfig: ItemApiConfig): NgModuleRef<any> {
//   /* tslint:disable:only-arrow-functions ter-prefer-arrow-callback */
//   @NgModule(Framing(function(framing: FramingNgModule): FramingNgModule {
//     framing
//       .provide(ItemApiService)
//       .provide({ provide: ItemApiConfig, useValue: itemApiConfig });
//     return framing;
//   }))
//   class TargetModule {}
//   /* tslint:enable:only-arrow-functions ter-prefer-arrow-callback */

//   return injector.get(Compiler).compileModuleSync(TargetModule).create(injector);
// }

@Component({
  selector: 'controller',
  template: '',
})
export class LookupController extends Controller<LookupModel, LookupView> {

  public targetApiService: ItemApiService;

  constructor(injector: Injector) { super(injector); }

  /**
   *
   */
  onControllerInit(): void {
    const itemApiConfig: ItemApiConfig = {
      primaryKey: this.model.targetModel.primaryKey,
      endpoint: this.model.targetModel.endpoint,
      compositeKey: this.model.targetModel.compositeKey,
    };

    // const toNgModuleRef = makeTargetModule(this.injector, itemApiConfig);

    // this.targetApiService = toNgModuleRef.injector.get(ItemApiService);
    this.targetApiService = this.injector.get(ItemApiService);
  }
}
