
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ItemApi } from 'src/features/common/item/item-api';
import { ComponentTariffRateData } from 'src/cad/contracts/transactions/interfaces/component-tariff-rate-data';
import { ParkingTransactionDetail } from 'src/cad/contracts/transactions/interfaces/parking-transaction-detail';

@Injectable()
export class AgreementTransactionApi extends ItemApi {

  private readonly endpoint: string = 'agreement/transaction';
  protected contractType: string;
  constructor(
    public apiHelper: ApiHelper,
  ) {
    super();
    this.contractType = 'transaction';
  }

  public getById = (id: number) : Observable<mplus.ContractData[]> => {
    return this.apiHelper.request(`${ this.endpoint }/${ id }`).pipe(tap((val) => val[0]));
  }

  public find(params: any): Observable<any> {
    return this.getById(params.contractTransactionId);
  }

  public deleteItem(item: any): Observable<any> {
    let validatePricesConfirmed: string = (item && item.validatePricesConfirmed) ? 'true' : 'false';

    return this.apiHelper.request(`${ this.endpoint }/delete/${ validatePricesConfirmed }`, { body: item });
  }

  public findAll = () : Observable<mplus.ContractData[]> => {
    return this.apiHelper.request(`${ this.endpoint }`);
  }

  public filter(params: any): Observable<mplus.ContractData[]> {
    return this.apiHelper.request(`${ this.endpoint }/filter`, { body: params });
  }

  public recent(): Observable<mplus.ContractData[]> {
    return this.apiHelper.request(`${ this.endpoint }/dashboard`);
  }

  public save(data: any): Observable<mplus.ContractData[]> {
    return this.apiHelper.request(this.endpoint, { body: data });
  }

  public getAssociatedNoms(transactionId: number): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/${ transactionId }/associated-noms`);
  }

  public completeTransaction(transactionId: number): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/${ transactionId }/complete`);
  }

  public submitTransaction(transactionId: number): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/${ transactionId }/submit`);
  }

  public unsubmitTransaction(transactionId: number): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/${ transactionId }/unsubmit`);
  }

  public copyToOtherTransaction(contractId: number): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }/copy-to-other`);
  }
  
  public printDiscountLetter(transactionId: number): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/${ transactionId }/print-discount-letter`);
  }
  
  public getReservationDiscountSummaryList(transactionId: number): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/${ transactionId }/reservation-discount-summary`);
  }
  
  public getTransactionComponentTariffRateList(transactionId: number, inputTemplId: number): Observable<ComponentTariffRateData[]> {
    return this.apiHelper.request(`${ this.endpoint }/${ transactionId }/${ inputTemplId }/transaction-component-tariff-rate`);
  }
  
  public getTransactionViewDetailList(transactionId: number): Observable<ParkingTransactionDetail[]> {
    return this.apiHelper.request(`${ this.endpoint }/${ transactionId }/transaction-view-details`);
  }
}
