import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReductionReasonFormComponent } from './reduction-reason-form/reduction-reason-form.component';
import { isNil } from 'lodash';

@Component({
  selector: 'reduction-reason-modal',
  templateUrl: './reduction-reason-modal.component.html',
  styleUrls: [ './reduction-reason-modal.component.less' ]
})
export class ReductionReasonModalComponent {

  public comment: string;
  public showComment: boolean;
  public reductionCd: string = '';
  public editable: boolean = false;
  public codeType: string = 'CONFREDREASON';
  @ViewChild('form') reductionForm: ReductionReasonFormComponent;


  constructor(
    public dialogRef: MatDialogRef<ReductionReasonModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.editable = data.editable;
    this.reductionCd = data.reductionCd;
    this.showComment = isNil(data.showComment) ? true : data.showComment;
    this.comment = data.comment;
    this.codeType = data.codeType ? data.codeType : 'CONFREDREASON' ;
  }

  public save(): void {
    this.dialogRef.close( this.reductionForm.getLookupResult());
  }

  public close(): void {
    this.dialogRef.close();
  }

  public isSubmitDisabled(): boolean {
    return (this.reductionForm && this.reductionForm.isSubmitDisabled)
      ? this.reductionForm.isSubmitDisabled()
      : false;
  }
}
