import { NgModule } from '@angular/core';
import { SharedModule } from 'cad/shared/shared.module';
import { SidenavComponent } from './sidenav.component';
import { SidenavController } from './sidenav.controller';

@NgModule({
  imports: [ SharedModule ],
  exports: [ SidenavComponent ],
  declarations: [ 
    SidenavController, 
    SidenavComponent
  ],
  entryComponents: [ SidenavController ],
})
export class SidenavComponentsModule {}
