
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ApiCache } from 'cad/common/services/api/api-cache';

@Injectable()
export class FeatureFlagsApi {
  private readonly endpoint: string = 'physical/asset-live';

  constructor(
    private apiHelper: ApiHelper,
    private apiCache: ApiCache,
  ) {}

  public isAssetLive = (): Observable<any> => {
    //return this.apiHelper.request(this.endpoint, { cache: this.apiCache.forSession, cacheTag: this.endpoint });
    // Temporary enabling Asset Live for Project One and Two
    return observableOf(true);
  }
}
