
import {of as observableOf,  Observable ,  ReplaySubject } from 'rxjs';

import {map} from 'rxjs/operators';
import { EmitterService } from './../../../cad/core/services/emitter/emitter.service';
import { CadAppController } from 'src/features/common/cad-app/cad-app.controller';
import { ItemController } from './../item/item.controller';
import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Controller } from 'src/framing/controller';
import { RouterService } from 'cad/core/services/router/router.service';

import { ValidationResultModel } from 'src/cad/common/models/validation-result/validation-result-model';
import { ItemDetailModel } from './item-detail.model';
import { ItemDetailView } from './item-detail.view';
import { ItemDetailBreadcrumb } from './item-detail.breadcrumb';
import * as _ from 'lodash';

@Component({
  selector: 'controller',
  template: '<router-outlet></router-outlet>',
})
export class ItemDetailController extends Controller<ItemDetailModel, ItemDetailView> {

  public router: RouterService;

  public routeUrl: string;

  /**
   * The item data (accessible directly without the observable).
   */
  public itemData: any = {};
  public cleanItemData: any = {};

  public itemController: ItemController;

  /**
   * ReplaySubject for item data observable.
   */
  private itemDataSubject: ReplaySubject<any> = new ReplaySubject<any>(1);

  private itemDetailBreadcrumb: ItemDetailBreadcrumb;

  private cadAppController: CadAppController;

  public emitterService: EmitterService;

  constructor(
    injector: Injector,
  ) {
    super(injector);

    const route: ActivatedRoute = this.injector.get(ActivatedRoute);
    this.routeUrl = Controller.buildUrlLink(route.snapshot);

    this.router = this.injector.get(RouterService);
    this.itemDetailBreadcrumb = this.injector.get(ItemDetailBreadcrumb, null); // optional

    this.itemController = this.injector.get(ItemController);
    this.cadAppController = this.injector.get(CadAppController);
    this.emitterService = this.injector.get(EmitterService);

    this.subs.newSub = route.data.pipe(
      map((data) => data.itemData))
      .subscribe(
        (itemData) => {
          if (this.itemController.model.enableSaveOnFormDirty) {
            this.cadAppController.disableSaveButton();
            this.subs.newSub = this.emitterService.formDirtyEvent.subscribe((data: any)  => {
              data.dirtyStatus ? this.cadAppController.enableSaveButton() : this.cadAppController.disableSaveButton();
            });
          }
          this.itemDataChanged(itemData);
        },
        (err) => {
          switch (err) {
            case 404: { this.router.navigate([ '/404' ]); break; }
            case 403: { this.router.navigate([ '/403' ]); break; }
            default: { this.router.navigate([ '/500' ]); break; }
          }
        });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy(); // mandatory
    this.itemDataSubject = undefined;
    this.itemData = {};
  }

  /**
   * An observable of the item data.
   */
  public get itemData$(): Observable<any> { return this.itemDataSubject; }

  // ==========================================================================

  /**
   *
   */
  public initFormItem(ctrl: any): void {}

  /**
   * Validates itemData before saving
   */
  public validateItemData(itemData: any): Observable<ValidationResultModel> {
    return observableOf(new ValidationResultModel());
  }

  /**
   *
   */
  public preSave(itemData: any): void {}

  /**
   *
   */
  public postSave(itemData: any): void {}

  /**
   *
   */
  public preDelete(itemData: any): void {}

  // ==========================================================================

  /**
   * Called by ItemDataResolveService when itemData changes.
   */
  public itemDataChanged(itemData: any): void {
    this.itemData = itemData || {};
    this.cleanItemData = _.cloneDeep(this.itemData);
    this.itemDataSubject.next(itemData);
    if (this.itemDetailBreadcrumb) {
      this.itemDetailBreadcrumb.updateBreadcrumb(itemData, this.routeUrl);
    }
  }
}
