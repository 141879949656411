import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ApiHelper} from 'cad/common/services/api/api-helper';
import {
  ParkingServiceSearch
} from 'cad/flowing-gas/imbalance/ship-imbal/parking-service-imbal/model/parking-service-search.model';
import {
  ParkingServiceHeaderData
} from 'cad/flowing-gas/imbalance/ship-imbal/parking-service-imbal/model/parking-service-header-data.model';
import {
  ParkingServiceDataModel
} from 'cad/flowing-gas/imbalance/ship-imbal/parking-service-imbal/model/parking-service-data.model';
import {
  PtpAllocationSearchGroupWSData
} from 'cad/flowing-gas/imbalance/ship-imbal/daily-k-bal/interfaces/ptp-allocation-search-group-ws-data';
import {
  ContractTransactionLookupData
} from 'cad/flowing-gas/imbalance/ship-imbal/daily-k-bal/interfaces/contract-transaction-lookup-data';

@Injectable()
export class ParkingServiceImbalanceApi {

  private readonly endpoint: string = 'flowing-gas/imbalance/shipper/parking-service-imbalance';

  constructor(
    private apiHelper: ApiHelper) {
  }

  public getHeader(params: ParkingServiceSearch): Observable<ParkingServiceHeaderData> {
    return this.apiHelper.request(this.endpoint + '/header', {body: params});
  }

  public getHeaderAndList(params: ParkingServiceSearch): Observable<ParkingServiceDataModel> {
    return this.apiHelper.request(this.endpoint + '/header-and-list/', {body: params});
  }

  public filter(params?: PtpAllocationSearchGroupWSData): Observable<ContractTransactionLookupData[]> {
    return this.apiHelper.request(this.endpoint + '/lookup', {body: params});
  }
}
