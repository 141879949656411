import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ui-fullscreen-header',
  templateUrl: 'fullscreen-header.component.html',
  styleUrls: [ './fullscreen-header.component.less' ],
})
export class UiFullscreenHeaderComponent {
  @Input() public title: string;
  @Output() public onClose: EventEmitter<any> = new EventEmitter<any>();

  public close(): void {
    this.onClose.emit();
  }
}

