import { Injectable } from '@angular/core';
import { Column, ProcessCellForExportParams, ProcessRowGroupForExportParams } from '@ag-grid-community/core';
import { UiGridApi } from 'src/ag-grid-wrapper';
import { ColumnFooterConfiguration } from 'src/ag-grid-wrapper/interfaces/footer-configuration';
import { ConfigService } from 'cad/core/services/config/config.service';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

@Injectable()
export class PrintScreenService {

  public csvDelim: string = ',';
  public headerHeight: number = 80;
  public footerHeight: number = 40;

  constructor(
    public userModelService: UserModelService,
    private configService: ConfigService
  ) {}

  public getFullHTML(bodyHtml: string, headerHtml?: string, footerHtml?: string): string {
    let bodyHeight: string = `calc(100% - (${this.headerHeight}px + ${this.footerHeight}px))`;
    let htmlText: string = '<!DOCTYPE html><HTML><HEAD>' +
      '<title>' + document.title + '</title>' +
      '<STYLE TYPE="text/css">body, td { font-family: SansSerif; font-size: 12px; } h3 { font-size: 18px; }</STYLE>' +
      '</HEAD><BODY><TABLE BORDER="0" CELLSPACING="0" CELLPADDING="0" WIDTH="100%" HEIGHT="100%">' +
      '<TR><TD HEIGHT="' + this.headerHeight + '" VALIGN="MIDDLE" ALIGN="CENTER">';

    // Header
    htmlText += !_.isEmpty(headerHtml) ? headerHtml : this.getDefaultHeader();
    htmlText += '<HR></TD></TR><TR><TD VALIGN="TOP" ALIGN="CENTER" STYLE="height: ' + bodyHeight + '">';
    // Body
    htmlText += bodyHtml;
    htmlText += '</TD></TR><TR><TD HEIGHT="' + this.footerHeight + '" ALIGN="CENTER"><HR>';
    // Footer
    htmlText += !_.isEmpty(footerHtml) ? footerHtml : this.getDefaultFooter();

    htmlText += '</TD></TR></TABLE></BODY></HTML>';
    return htmlText;
  }

  public getDefaultHeader(): string {
    let headerText: string = document.title || 'Messenger';
    
    if (this.configService.cadConfig && this.configService.cadConfig.isDev) {
      headerText += ' - ' + this.configService.cadConfig.envName;
    }
    return `<h1 ALIGN="LEFT">
              <img src="${document.baseURI}favicon.ico" style="vertical-align: middle;"/>
              <span style="vertical-align: middle;">${headerText}</span>
            </h1>`;
  }

  public getDefaultFooter(): string {
    let footerHtmlText: string = '<TABLE ALIGN="LEFT" WIDTH="100%" CELLSPACING="0" CELLPADDING="4"><TR>';

    if (this.userModelService) {
      footerHtmlText += '<TD WIDTH="20%"><b>User : </b>' +
        this.userModelService.getUser().firstName +
        '&nbsp;' + this.userModelService.getUser().lastName +
        '</TD><TD ALIGN="CENTER" WIDTH="50%"><b>Bus. Assoc: </b>' +
        this.userModelService.getUser().currentContext.businessAssociateDunsNbr +
        '-' + this.userModelService.getUser().currentContext.businessAssociateName +
        '</TD>';
    }
    footerHtmlText += '<TD ALIGN="RIGHT" WIDTH="30%"><b>' +
      moment().format('MM/DD/YYYY hh:mm:ss A z') + '&nbsp;&nbsp;&nbsp;</b></TD>';
    footerHtmlText += '</TR></TABLE>';

    return footerHtmlText;
  }

  public convertCardToHTML(cardElementOrSelector: Element | string, gridApiList: UiGridApi[] = []): string {
    let returnString: string = '';
    let cardEl: Element = _.isElement(cardElementOrSelector)
      ? cardElementOrSelector as Element
      : document.querySelector(cardElementOrSelector as string);

    if (cardEl && _.isElement(cardEl)) {
      let cardHeaderEl = cardEl.querySelector('.mat-card-header .mat-card-title');

      if (cardHeaderEl && _.isElement(cardHeaderEl)) {
        returnString += '<H3 ALIGN="LEFT">';
        returnString += (cardHeaderEl as HTMLElement).innerText;
        returnString += '</H3>';
      }
      // Process form fields
      returnString += this.convertFormToHTML(cardEl.querySelector('ui-form'));
      // Process grids
      gridApiList.forEach((gridApi: UiGridApi) => {
        returnString += this.convertGridToHTML(gridApi);
      });
    }
    return returnString;
  }

  public convertFormToHTML(formElementOrSelector: Element | string): string {
    let returnString: string = '';
    let formEl: Element = _.isElement(formElementOrSelector)
      ? formElementOrSelector as Element
      : document.querySelector(formElementOrSelector as string);
    let formFields: any;
    let itemIndex: number;

    if (formEl && _.isElement(formEl)) {
      returnString += '<TABLE BORDER=0 WIDTH="90%" CELLSPACING="0" CELLPADDING="4">';
      formFields = formEl.querySelectorAll('.mat-checkbox, .mat-form-field');

      for (itemIndex = 0; itemIndex < formFields.length; itemIndex++) {
        if (itemIndex % 2 === 0) {
          returnString += '<TR>';
        }
        returnString += '<TD>';
        returnString += this.convertFormFieldToHTML(formFields[ itemIndex ]);
        returnString += '</TD>';
        if (itemIndex % 2 === 1) {
          returnString += '</TR>';
        }
      }
      returnString += '</TABLE>';
    }

    return returnString;
  }

  public convertFormFieldToHTML(parentEl: Element): string {
    let returnString: string = '';
    let formFieldLabelEl: Element;
    let formFieldValueEl: Element;

    if (parentEl && _.isElement(parentEl)) {
      formFieldLabelEl = parentEl.querySelector('.mat-checkbox-label, .mat-form-field-placeholder');
      formFieldValueEl = parentEl.querySelector('.mat-input-element, .mat-select-value-text, .mat-checkbox-input');
      if (formFieldLabelEl && _.isElement(formFieldLabelEl)) {
        returnString += (formFieldLabelEl as HTMLElement).innerText + ':&nbsp;';
      }
      if (formFieldValueEl && _.isElement(formFieldValueEl)) {
        if (formFieldValueEl.nodeName === 'INPUT') {
          if ((formFieldValueEl as HTMLInputElement).type === 'checkbox'
            || (formFieldValueEl as HTMLInputElement).type === 'radio') {
            returnString += (formFieldValueEl as HTMLInputElement).checked ? 'Yes' : 'No';
          } else {
            returnString += (formFieldValueEl as HTMLInputElement).value;
          }
        } else if (formFieldValueEl.nodeName === 'TEXTAREA') {
          returnString += (formFieldValueEl as HTMLInputElement).value;
        } else {
          returnString += (formFieldValueEl as HTMLElement).innerText;
        }
      }
    }

    return returnString;
  }

  public convertGridToHTML(gridApi: UiGridApi): string {
    let columnSeparator: string = '@,@';
    let gridCSV: string = gridApi ? gridApi.getDataAsCsv({
      getCustomContentBelowRow: (params: ProcessRowGroupForExportParams) => {
        if ((params.api as UiGridApi).rowEdit
          && !params.node.group
          && params.node.lastChild
          && params.node.parent
          && params.node.parent.group
          && params.node.parent.sibling
          && params.node.parent.sibling.footer
          && params.node.parent.sibling.rowGroupColumn) {
          let returnVal: string[] = [];
          
          params.columnApi.getAllDisplayedColumns().forEach((displayedColumn: Column) => {
            if (displayedColumn.isValueActive()) {
              returnVal.push(params.node.parent.sibling.aggData[ displayedColumn.getColId() ]);
            } else {
              returnVal.push((params.api as UiGridApi).rowEdit.getFormattedValue(displayedColumn.getColId(), params.node.parent.sibling));
            }
          });
          return returnVal.join(columnSeparator);
        }
        return null;
      },
      processCellCallback: (params: ProcessCellForExportParams): string => {
        if (params
          && params.node
          && params.column) {
          if (params.node.rowPinned
            && params.column.getColDef()
            && (params.column.getColDef() as any).footerConfiguration) {
            const footerOrderingField: string = '**footerOrderingField**';
            const isHeader: boolean = (params.node.data && params.node.data[ footerOrderingField ] === -10000000);
            const footerConfig: ColumnFooterConfiguration = (params.column.getColDef() as any).footerConfiguration.find(
              (conf: ColumnFooterConfiguration) => conf.header && conf.header[ params.value ]
            );
            if (isHeader && footerConfig) {
              return footerConfig.header[ params.value ];
            }
          }
          if (params.api && (params.api as UiGridApi).rowEdit) {
            return (params.api as UiGridApi).rowEdit.getFormattedValue(params.column.getColId(), params.node);
          }
        }
        return '';
      },
      columnSeparator,
      columnKeys: (gridApi as any).columnController.getAllDisplayedColumns(),
      suppressQuotes: true
    }) : '';

    return !_.isEmpty(gridCSV) ? this.csvToHTMLTable(gridCSV, columnSeparator) : '';
  }

  public csvToHTMLTable(csvString: string, csvDeliminator?: string): string {
    let allRows: string[] = csvString.split(/\r?\n|\r/);
    let returnStr: string = '<table BORDER="0" WIDTH="100%" ALIGN="CENTER" CELLSPACING="0" CELLPADDING="4">';

    for (let rowIndex: number = 0; rowIndex < allRows.length; rowIndex++) {
      let rowCells: string[] = allRows[rowIndex].split(csvDeliminator || this.csvDelim);

      if (rowIndex === 0) {
        returnStr += '<thead>';
      }
      returnStr += '<tr>';
      for (let cellIndex: number = 0; cellIndex < rowCells.length; cellIndex++) {
        if (rowIndex === 0) {
          returnStr += '<th ALIGN="LEFT" BGCOLOR="#C0C0C0" VALIGN="MIDDLE">' + rowCells[cellIndex] + '</th>';
        } else {
          returnStr += '<td ALIGN="LEFT" VALIGN="MIDDLE">';
          returnStr += rowCells[cellIndex];
          returnStr += '</td>';
        }
      }
      returnStr += '</tr>';
      if (rowIndex === 0) {
        returnStr += '</thead>';
        returnStr += '<tbody>';
      }
    }
    returnStr += '</tbody>';
    returnStr += '</table>';
    return returnStr;
  }
}
