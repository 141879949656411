import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EventEmitter,
  Injector,
  Input,
  NgModuleRef,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  Type,
  ViewContainerRef,
} from '@angular/core';

interface FramingComponentOutlet {
  isActivated(): boolean;
}

@Directive({
  selector: '[framingComponentOutlet]',
})
export class FramingComponentOutletDirective implements OnChanges, OnDestroy, FramingComponentOutlet {
  @Input() framingComponentName: string;
  @Input() framingComponentOutlet: Type<any>;
  @Input() framingComponentInputs: { [key:string]: any };
  @Input() framingComponentInjector: Injector;
  @Input() content: any[][];

  @Output() onComponent: EventEmitter<ComponentRef<any>> = new EventEmitter<ComponentRef<any>>();
  @Output() onError: EventEmitter<string> = new EventEmitter<string>();

  private _componentRef: ComponentRef<any>;
  private _moduleRef: NgModuleRef<any>;

  constructor(
    private _view: ViewContainerRef,
  ) {}

  isActivated(): boolean { return !!this._componentRef; }

  ngOnChanges(changes: SimpleChanges): void {
    let activate = false;
    if ((changes as any).ngModuleFactory) {
      if (this._moduleRef) { this._moduleRef.destroy(); }
      activate = true;
    }

    if ((changes as any).framingComponentOutlet &&
      (changes as any).framingComponentOutlet.currentValue !== (changes as any).framingComponentOutlet.previousValue) {
      activate = true;
    }

    if (activate) {
      this.activate(this.framingComponentOutlet);
    }
  }

  ngOnDestroy(): void {
    this.deactivate();
    if (this._moduleRef) { this._moduleRef.destroy(); }
  }

  private activate(component: Type<any>): void {
    this.deactivate();

    if (!component) {
      return;
    }

    try {
      const injector = this.framingComponentInjector || this._view.parentInjector;
      const factory = injector.get(ComponentFactoryResolver).resolveComponentFactory(component);
      this._componentRef = this._view.createComponent(factory, this._view.length, injector, this.content);
      if (this.framingComponentInputs) {
        _.forOwn(this.framingComponentInputs, (value, key) => this._componentRef.instance[key] = value);
      }
      try {
        this.onComponent.emit(this._componentRef);
      } catch (e) {
        console.error(`onComponent failed on activated component in FramingComponentOutlet ${this.framingComponentName}`, e);
      }
      try {
        this._componentRef.changeDetectorRef.detectChanges();
      } catch (e) {
        console.error(`detectChanges failed on activated component in FramingComponentOutlet ${this.framingComponentName}`, e);
      }
    } catch (e) {
      console.error(`Failed to activate component in FramingComponentOutlet ${this.framingComponentName}`, e);
      try {
        this.onError.emit(e.toString());
      } catch (e) {
        console.error(`onError failed in FramingComponentOutlet ${this.framingComponentName}`, e);
      }
    }
  }

  private deactivate(): void {
    if (this._componentRef) {
      this._view.remove(this._view.indexOf(this._componentRef.hostView));
      this._componentRef.destroy();
    }
    this._view.clear();
    this._componentRef = undefined;
  }
}
