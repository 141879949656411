import { Directive, Input, Host, Inject, Injector, OnInit } from '@angular/core';
import { EmitterService } from 'src/cad/core/services/emitter/emitter.service';
import { AutoUnsubscribables, AutoUnsubscriber } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import { TableComponent } from 'src/ag-grid-wrapper/table/table.component';
import { ActionService } from 'src/cad/common/services/security/action.service';
/**
 * Created by jodell on 9/13/2017.
 */
@Directive({
  selector: 'ui-table',
})
export class TableDisableDirective implements OnInit {

  @Input() public skipDisable: boolean;
  @AutoUnsubscriber() private subs: AutoUnsubscribables;
  public table: TableComponent;

  constructor(private injector: Injector, private emitterService: EmitterService, private actionService: ActionService) {
    this.table = this.injector.get(TableComponent);
  }

  ngOnInit(): void {
    this.subs.newSub =
      this.emitterService.actionContextResolved.subscribe(() => {
        this.disableTable();
      });
  }

  ngAfterViewInit(): void {
  }

  disableTable(): void {
    if (this.isDisabled() && this.table) {
      this.table.disableEdit = true;
    }
  }

  public isDisabled(): boolean {
    if (this.skipDisable) { return false; }
    return !this.actionService.canPerformAction(cad.actionConstants.ACTION_SAVE);
  }
}
