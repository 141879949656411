import { Component, Injector, OnInit } from '@angular/core';
import { MenuModel } from 'cad/common/models/menu/menu-model';
import { ApiCache } from 'cad/common/services/api/api-cache';
import { EmitterService } from 'cad/core/services/emitter/emitter.service';
import { FeatureComponent } from 'src/framing/feature.component';
import { CadAppController } from '../../cad-app.controller';
import { CadAppModel } from '../../cad-app.model';
import { CadAppView } from '../../cad-app.view';
import * as _ from 'lodash';
import {RootObject} from 'cad/common/store/user/user-model';
import {UserStoreService} from 'cad/common/store/core/services/user-store.service';

@Component({
  selector: 'ui-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: [ './header-toolbar.component.less' ],
})
export class HeaderToolbarComponent extends FeatureComponent<CadAppModel, CadAppView, CadAppController> implements OnInit {

  public showMenuButton: boolean = false;
  public isLoggedIn: boolean = false;
  public menu: any[];

  constructor(
    private menuModel: MenuModel,
    private apiCache: ApiCache,
    private emitterService: EmitterService,
    private currentContextService: UserStoreService,
    controller: CadAppController,
    injector: Injector,
  ) {
    super(controller, injector);
  }

  public get showSearchButton(): boolean { return !!this.controller.view.search; }

  public retreiveIsLoggedInCache = (): boolean => {
    let user = this.apiCache.forSession.get('user') || false;
    return (user) ? user.loggedIn : false;
  }

  public ngOnInit(): void {
    this.isLoggedIn = this.retreiveIsLoggedInCache();

    this.subs.newSub = this.menuModel.menuItems$.subscribe((mi) => {
      const menuItems = _.cloneDeep(mi);
      this.menu = [ { label: '', menuItems } ];
      this.updateMenuVisibility();
    });

    this.subs.newSub = this.currentContextService.stateChanged
      .subscribe((userDetail) => this.onUserContext(userDetail.state.user));
  }

  private onUserContext(currentContext: RootObject): void {
    if (currentContext) {
      this.isLoggedIn = currentContext.loggedIn;
    } else {
      this.isLoggedIn = false;
      this.showMenuButton = false;
    }
  }

  private updateMenuVisibility(): void {
    this.showMenuButton = false;
    this.menu.forEach((m) => {
      this.showMenuButton = this.showMenuButton || this.isMenuVisible(m.menuItems);
    });
  }

  private isMenuVisible(menuItems: any): boolean {
    if (!menuItems) { return false; }
    let visible: boolean = false;
    menuItems.forEach((m) => {
      if (m.visible) { visible = true; }
      if (m.children) { visible = visible || this.isMenuVisible(m.children); }
    });
    return visible;
  }
}
