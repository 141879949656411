import { ICellRendererParams } from '@ag-grid-community/core';
import { DecimalPipe } from '@angular/common';
import * as _ from 'lodash';

export function numberValueFormatter(params: ICellRendererParams): string {
  let pipe = new DecimalPipe('en-US');
  let format = (params.colDef as any).cellFilter;
  let val: string;

  if (params && params.data) {
    val = _.get(params.data, params.colDef.field);
  } else if (params && !_.isNil(params.value)) {
    val = params.value;
  }

  val = _.isNumber(val) ? val : _.toString(val).trim();

  return (format !== 'ignore' && !isNaN((val as any)) && isFinite((val as any))) ? pipe.transform(val, format) : val;
}

export function numberStringValueFormatter(params: ICellRendererParams): string {
  let returnVal: string = numberValueFormatter(params);

  return !_.isNil(returnVal) ? returnVal.replace(/,/g, '') : returnVal;
}
