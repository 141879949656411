import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class NominationContactApi {
  private readonly endpoint: string = 'nomination/nomination-contact';

  constructor(private apiHelper: ApiHelper) {
  }

  public getById = (id: Number): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/findById/' + id);
  }
}
