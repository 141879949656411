<ui-form type="basic" (dirtyStatusChanged)="isDirty = $event.dirtyStatus">
  <ui-modal-header>
    Email Notification(s)
  </ui-modal-header>
  <ui-modal-body>
    <div fxLayout="column">
      <div fxLayout="column"
           cdkTrapFocus>
        <div fxLayout="row"
             fxLayoutGap.gt-sm="18px"
             fxLayout.lt-md="column">
          <div fxFlex="auto"
               fxFlex.gt-sm="35">
            <ui-select uiAutoFocus
                       placeholder="Notification Type"
                       [(ngModel)]="selectedNotificationTypes"
                       [multiple]="true"
                       name="selectedNotificationTypes"
                       codeType="NOTIFICATIONTYPE"
                       [disabledOptions]="disabledNotificationTypes">
            </ui-select>
          </div>
          <div fxFlex="auto"
               fxLayoutAlign="start center"
               fxFlex.gt-sm="65">
            <button mat-button
                    mat-raised-button
                    type="button"
                    (click)="selectedNotificationTypes ? addNotificationRows() : null"
                    title="Add Notification Types"
                    [disabled]="!(selectedNotificationTypes?.length)">Add
            </button>
          </div>
        </div>
        <h3><span>Active Notification Types</span></h3>
      </div>
      <div fxLayout="column">
        <div class="content-padding"
             fxFlex="auto">
          <div *ngFor="let contactNotification of contactNotificationList; let i = index">
            <div *ngIf="!isDelete(contactNotification)"
                 fxLayout="row"
                 fxLayoutGap.gt-sm="18px"
                 fxLayout.lt-md="column">
              <div fxFlex="auto"
                   fxFlex.gt-sm="45">
                <ui-select uiInput
                           name="{{contactNotification.notificationTypeCd + i + '_notificationTypeCd'}}"
                           placeholder="Notification Type"
                           [(ngModel)]="contactNotification.notificationTypeCd"
                           codeType="NOTIFICATIONTYPE"
                           [disabledOptions]="disabledNotificationTypes"
                           (change)="formFieldChanged(i)"
                           required>
                </ui-select>
              </div>
              <div fxLayout="row"
                   fxLayoutGap="18px"
                   fxFlex="auto"
                   fxFlex.gt-sm="55">
                <div fxFlex="auto"
                     fxFlex.gt-sm="100">
                  <ui-select uiInput
                             name="{{contactNotification.notificationTypeCd + i + '_profileTypeCd'}}"
                             placeholder="Profile Type"
                             [(ngModel)]="contactNotification.profileTypeCd"
                             [sourceFn]="contactNotificationProfileTypeList"
                             [disabledOptions]="disabledProfileTypes[contactNotification.notificationTypeCd]"
                             (change)="formFieldChanged(i)"
                             required>
                  </ui-select>
                </div>
                <div fxFlex="none"
                     fxFlexAlign="center">
                  <button type="button"
                          mat-button
                          mat-icon-button
                          color="primary"
                          title="Remove Notification Type"
                          (click)="removeNotificationRow(i)">
                    <mat-icon [attr.tabindex]="0">delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ui-modal-body>
  <ui-modal-footer>
    <button type="button"
            mat-raised-button
            matDialogClose>CANCEL
    </button>
    <button type="button"
            mat-raised-button
            color="primary"
            [disabled]="isSubmitDisabled()"
            (click)="onSubmit()">SUBMIT
    </button>
  </ui-modal-footer>
</ui-form>
