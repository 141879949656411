import { Directive, Input, OnInit } from '@angular/core';
import { TableColumnComponent } from 'src/ag-grid-wrapper/column/column.component';
import { PhoneOrTextEditorComponent } from './phone-or-text-editor.component';
import { PhoneOrTextRendererComponent } from './phone-or-text-renderer.component';

@Directive({
  selector: 'ui-table-column[phoneOrText]',
})
export class PhoneOrTextDirective implements OnInit {
  constructor(public tableColumn: TableColumnComponent) {}
  ngOnInit(): void {
    this.tableColumn.type = 'custom';
    this.tableColumn.customEditor = PhoneOrTextEditorComponent;
    this.tableColumn.customRenderer = PhoneOrTextRendererComponent;
  }
}
