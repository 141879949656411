import { DunsNumberPipe } from './../../../common/filters/duns-number/duns-number.pipe';
import { Directive, Input, ComponentRef, OnInit } from '@angular/core';
import { TableColumnComponent } from 'src/ag-grid-wrapper/column/column.component';
import { DunsNumberRendererComponent } from './duns-number-renderer.component';
import { ICellRendererParams } from '@ag-grid-community/core';
import * as _ from 'lodash';

/**
 *
 *
 **/

@Directive({
  selector: 'ui-table-column[dunsNumber]',
  providers: [ DunsNumberPipe ]
})
export class DunsNumberDirective implements OnInit {
  @Input() codeType: string;
  constructor(public tableColumn: TableColumnComponent, private pipe: DunsNumberPipe) {}

  ngOnInit(): void {
    (this.tableColumn as any).valueFormatter = (params: ICellRendererParams): string => {
      let val: any;

      if (params && params.data) {
        val = _.get(params.data, params.colDef.field);
      } else if (params && !_.isNil(params.value)) {
        val = params.value;
      }
      return this.pipe.transform(val);
    };
  }
}
