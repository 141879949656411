import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ItemApi } from 'src/features/common/item/item-api';
import { map } from 'rxjs/operators';
import { SystemWideNotice } from 'cad/common/services/api/infopost/notices/system-wide-notice';
import ValidationResult = cad.ValidationResult;

@Injectable()
export class NoticesApi extends ItemApi {
  endpoint: string = 'infopost/notices';

  constructor(private apiHelper: ApiHelper) {
    super();
  }

  filter(params: any): Observable<SystemWideNotice[]> {
    return this.apiHelper.request(`${ this.endpoint }/filter`, { body: params })
      .pipe(map((items: SystemWideNotice[]) =>
        items.map((item: SystemWideNotice) => this.convertIncomingToBoolFlags(item))));
  }

  getById(id: number) : Observable<SystemWideNotice> {
    return this.filter( {noticeId: id} )
      .pipe(map( (val) => this.convertIncomingToBoolFlags(val[0])));
  }

  find(params: {noticeId: number}): Observable<SystemWideNotice> {
    return this.getById(params.noticeId);
  }

  deleteItem(item: SystemWideNotice): Observable<ValidationResult> {
    const requestObject = this.convertOutgoingToStringFlags(item);
    return this.apiHelper.request(`${ this.endpoint }/delete`, { body: requestObject });
  }

  findAll(): Observable<SystemWideNotice[]> {
    return this.filter({});
  }

  recent(): Observable<SystemWideNotice[]> {
    return this.apiHelper.request(`${ this.endpoint }/recent`, { method: 'GET' });
  }

  save(item: SystemWideNotice): Observable<ValidationResult<SystemWideNotice>> {
    const requestObject = this.convertOutgoingToStringFlags(item);
    return this.apiHelper.request(`${ this.endpoint }/save`, { body: requestObject })
      .pipe(map((x: ValidationResult<SystemWideNotice>) => {
        return {
          successful: x.successful,
          validatedObject: this.convertIncomingToBoolFlags(x.validatedObject),
          errorMessages: x.errorMessages
        };
      }));
  }

  resend(item: SystemWideNotice): Observable<SystemWideNotice> {
    const requestObject = this.convertOutgoingToStringFlags(item);
    return this.apiHelper.request(`${ this.endpoint }/resend`, { body: requestObject })
      .pipe(map((x) => this.convertIncomingToBoolFlags(x)));
  }

  approveReg(item: SystemWideNotice): Observable<SystemWideNotice> {
    const requestObject = this.convertOutgoingToStringFlags(item);
    return this.apiHelper.request(`${ this.endpoint }/approve-regulatory`, { body: requestObject })
      .pipe(map((x) => this.convertIncomingToBoolFlags(x)));
  }

  approveOpt(item: SystemWideNotice): Observable<SystemWideNotice> {
    const requestObject = this.convertOutgoingToStringFlags(item);
    return this.apiHelper.request(`${ this.endpoint }/approve-optimization`, { body: requestObject })
      .pipe(map((x) => this.convertIncomingToBoolFlags(x)));
  }

  private convertIncomingToBoolFlags(item: SystemWideNotice): SystemWideNotice {
    const clone = _.cloneDeep(item);
    clone.criticalInd = [ 'Y', true ].includes(clone.criticalInd);
    clone.autoApprInd = [ 'Y', true ].includes(clone.autoApprInd);
    clone.emailSentInd = [ 'Y', true ].includes(clone.emailSentInd);
    clone.postInd = [ 'Y', true ].includes(clone.postInd);
    clone.displayOnEBB = [ 'Y', true ].includes(clone.displayOnEBB);
    return clone;
  }

  private convertOutgoingToStringFlags(item: SystemWideNotice): SystemWideNotice {
    const clone = _.cloneDeep(item);
    clone.criticalInd = [ 'Y', true ].includes(clone.criticalInd) ? 'Y' : 'N';
    clone.autoApprInd = [ 'Y', true ].includes(clone.autoApprInd) ? 'Y' : 'N';
    clone.emailSentInd = [ 'Y', true ].includes(clone.emailSentInd) ? 'Y' : 'N';
    clone.postInd = [ 'Y', true ].includes(clone.postInd) ? 'Y' : 'N';
    clone.displayOnEBB = [ 'Y', true ].includes(clone.displayOnEBB) ? 'Y' : 'N';
    return clone;
  }

}

