import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators, AbstractControl } from '@angular/forms';
import { CustomValidationObject } from 'common/components/form/mat-form-field/custom-validation-object';

/**
 * @see https://github.com/yuyang041060120/ng2-validation for more validators
 */
@Directive({
  selector: '[uiDigitValidator]',
  providers: [ {provide: NG_VALIDATORS, useExisting: forwardRef(() => UiDigitValidatorDirective), multi: true} ],
})
export class UiDigitValidatorDirective implements Validator {
  validate(control: AbstractControl): CustomValidationObject {
    let requiredValidator: any = Validators.required(control);
    if (requiredValidator !== undefined && requiredValidator !== null) {
      return null;
    }
    return /^\d+$/.test(control.value) ? null : {
      uiDigitValidator: {
        valid: false,
        message: 'Invalid digit/integer value',
      }
    };
  }
}
