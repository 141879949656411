<div fxLayout="column" class="lookup">
  <ng-container *ngIf="!useAutocomplete; else autocompleteTempRef">
    <mat-form-field fxFlex
                    internalInput
                    #inputWrapper
                    [hintLabel]="hintText">
      <input matInput
             #input
             #ngModel="ngModel"
             [(ngModel)]="model"
             [ngModelOptions]="{ updateOn: updateModelOn }"
             [name]="name"
             (blur)="onTextBlur()"
             [placeholder]="placeholder"
             [required]="required"
             [disabled]="disabled"
             [readonly]="inputLocked"
             type="ui-lookup"/>
      <button *ngIf="useModal" matSuffix
              mat-icon-button
              type="button"
              [disabled]="disabled"
              aria-label="Lookup"
              (click)="openModal($event)"
              fxLayoutAlign="space-around center">
        <mat-icon [ngClass]="{'disabled': disabled || inputLocked}">search</mat-icon>
      </button>
      <mat-error class="mat-error"
                 #errorNode></mat-error>
    </mat-form-field>
  </ng-container>
  <ng-template #autocompleteTempRef>
    <mat-form-field fxFlex
                    internalInput
                    #inputWrapper
                    [hintLabel]="hintText">
      <input matInput
             #input
             [ngModel]="model"
             [name]="name"
             (blur)="onTextBlur()"
             [placeholder]="placeholder"
             [required]="required"
             [disabled]="disabled"
             [readonly]="inputLocked"
             [matAutocomplete]="lookupAutocomplete"
             #ngModel="ngModel"
             type="ui-lookup"/>
      <button *ngIf="useModal" matSuffix
              mat-icon-button
              type="button"
              [disabled]="disabled"
              aria-label="Lookup"
              (click)="openModal($event)"
              fxLayoutAlign="space-around center">
        <mat-icon [ngClass]="{'disabled': disabled || inputLocked}">search</mat-icon>
      </button>
      <mat-error class="mat-error"
                 #errorNode></mat-error>
    </mat-form-field>
    <mat-autocomplete #lookupAutocomplete="matAutocomplete"
                      (optionSelected)="onOptionSelected($event)">
      <ui-virtual-repeat #virtualPanel
                         [vrActivated]="true"
                         [vrItems]="filteredOptions$ | async"
                         [vrChildHeight]="AUTOCOMPLETE_OPTION_HEIGHT"
                         [style.maxHeight.px]="AUTOCOMPLETE_PANEL_HEIGHT"
                         [style.width.px]="virtualPanelWidth"
                         [vrItemsToBuffer]="ITEMS_TO_BUFFER"
                         (vrVirtualItemsChange)="virtualItems = $event">
        <mat-option *ngFor="let option of virtualItems"
                    [disabled]="isOptionDisabled(option)"
                    [value]="option[ autocompleteModel?.valueProperty ]">
          <span *ngIf="autocompleteModel?.idProperty && !isNil(option[ autocompleteModel?.idProperty ])">
            (<i>{{option[ autocompleteModel.idProperty ]}}</i>)
          </span>
          <span [innerHTML]="(!isNil(option[ autocompleteModel?.valueProperty ]) ? option[ autocompleteModel?.valueProperty ] : '')
        | highlightText : ngModel?.value : (autocompleteModel?.caseSensitive) ? '' : 'i'"></span>
        </mat-option>
      </ui-virtual-repeat>
    </mat-autocomplete>
  </ng-template>
</div>
