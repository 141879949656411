export enum ConfirmationActions {
  Save,
  ConfirmAll,
  Undo,
  Delete,
  DateChanged
}

export enum ConfirmationAllTypes {
  Scheduled = 'SCHED',
  Nomination = 'NOM',
}

export enum ConfCycleEnum {
  TIM = '0',
  EVE = '1',
  ID1 = '2',
  ID2 = '3',
  ID3 = '7',
  FINALAM = '9'
}
