import { Injectable } from '@angular/core';

import { NominationConfirmationApi } from 'cad/common/services/api/nomination/confirmation/confirmation';
import { NominationEdiConfirmationApi } from 'cad/common/services/api/nomination/edi-confirmation/edi-confirmation';
import { NominationContactApi } from 'cad/common/services/api/nomination/nomination-contact/nomination-contact';
import { NominationHistoryApi } from 'cad/common/services/api/nomination/nomination-history/nomination-history';
import { NominationMatrixApi } from 'cad/common/services/api/nomination/nomination-matrix/nomination-matrix';
import { NominationTransactionTypeApi } from 'cad/common/services/api/nomination/transaction-type/transaction-type';

@Injectable()
export class NominationApi {
  constructor(public confirmation: NominationConfirmationApi,
              public ediConfirmation: NominationEdiConfirmationApi,
              public nominationContact: NominationContactApi,
              public nominationHistory: NominationHistoryApi,
              public nominationMatrix: NominationMatrixApi,
              public transactionType: NominationTransactionTypeApi) {
  }
}
