import { Directive, Input, OnInit } from '@angular/core';
import { ICellRendererParams, SetFilterValuesFuncParams } from '@ag-grid-community/core';
import { map, take } from 'rxjs/operators';
import { CodesApi } from 'cad/common/services/api/codes/codes';
import { UserModelService } from 'cad/common/services/user/user-model-service';
import { TableColumnComponent } from 'src/ag-grid-wrapper/column/column.component';
import { ETCodePipe } from 'src/cad/common/filters/et-codes/et-codes.pipe';
import { EtCodeFilter } from 'cad/shared/table-renderers/et-code/et-code-filter';
import { TableComponent } from 'src/ag-grid-wrapper/table/table.component';
import { Code } from 'src/cad/shared/interfaces/code-value-data';
import * as _ from 'lodash';

/**
 *
 * This directive is used to configure a custom renderer component see ./et-code-renderer.component.ts for more information
 * on how to create a custom renderer.
 *
 * The purpose of this directive is to configure the table column to use a custom renderer and wrap up the boilerplace so the renderer
 * can be used simply by adding a shared directive to your table column.  The ag-grid-wrapper's TableColumnComponent has a
 * few inputs that need to be configured in order to use a custom editor.  To do this, we will inject the TableColumnComponent into
 * our constructor.  In the ngOnInit lifecycle hook we can configure it to use the 'custom' column type and assign our custom renderer
 * to the customRenderer (Type<any>) property.  In this example I've also added a codeType input to allow the user to specify the etCode
 * type to use.
 *
 * Example Usage:
 *
 *    ```<ui-table-column
 *        name="invoiceTypeCd"
 *        display-name="Invoice Type"
 *        etCodeRenderer
 *        codeType="INVOICETYPE">
 *      </ui-table-column>```
 *
 * The above example will render an etCode value based on the code value in the cell.  All of the configuration needed to do that is here in
 * this directive.
 *
 **/

@Directive({
  selector: 'ui-table-column[etCodeRenderer]',
  providers: [ ETCodePipe ]
})
export class ETCodeRendererDirective implements OnInit {

  @Input() public codeType: string;
  @Input() public codeTypeAssetCol: number;
  @Input() public showValue: boolean = true;
  @Input() public sourceData: any[] = [];

  constructor(
    public table: TableComponent,
    public tableColumn: TableColumnComponent,
    public pipe: ETCodePipe,
    public userModelService: UserModelService,
    public codesApi: CodesApi
  ) {}

  ngOnInit(): void {
    this.table.frameworkComponents = { etCodeFilter: EtCodeFilter };
    this.tableColumn.filter = 'etCodeFilter';
    //this.tableColumn.headerComponentFramework = EtCodeFilter;
    this.tableColumn.cellFilter = this.codeType;
    if (this.tableColumn && _.isNil(this.tableColumn.customCellConfig)) {
      this.tableColumn.customCellConfig = this.codeTypeAssetCol;
    }
    this.tableColumn.valueGetter = this.tableColumn.valueFormatter = (params: ICellRendererParams): string => {
      let val: any;

      if (params && params.data) {
        val = _.get(params.data, params.colDef.field);
      } else if (params && !_.isNil(params.value)) {
        val = params.value;
      } else {
        val = (params && _.isFunction((params as any).toUpperCase))
          ? (params as any).toUpperCase()
          : '';
      }
      if (this.sourceData.length) {
        let pair: any = _.find(this.sourceData, { cd: val });

        return pair ? pair.value : '';
      } else {
        return this.pipe.transform(val, this.codeType, this.codeTypeAssetCol);
      }
    };
    if (this.tableColumn.useCustomHeaderFilter) {
      this.tableColumn.filter = 'agSetColumnFilter';
      this.tableColumn.menuTabs = [ 'filterMenuTab' ];
      this.tableColumn.suppressMenu = false;
      this.tableColumn.headerComponentFramework = null;
      this.tableColumn.valueGetter = null;
      this.tableColumn.filterParams = {
        values: (params: SetFilterValuesFuncParams) => {
          this.codesApi.getCodeType(this.userModelService.getCurrentAssetNbr(), this.codeType)
            .pipe(
              map((codeType: Code) => (codeType && codeType.codes) ? codeType.codes.map((code) => code.cd) : []),
              take(1)
            ).subscribe((codes) => params.success(codes));
        },
        textFormatter: (from: string) => this.pipe.transform(from, this.codeType, this.codeTypeAssetCol)
      };
    } else {
      this.tableColumn.filter = 'etCodeFilter';
    }
  }
}
