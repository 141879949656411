import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import { ItemApi } from 'src/features/common/item/item-api';
import WorkflowExecutionRequestData = mplus.WorkflowExecutionRequestData;
import ValidationResult = cad.ValidationResult;
import WorkflowExecutionRequestParamData = mplus.WorkflowExecutionRequestParamData;
import { CodeValueData } from 'cad/shared/interfaces/code-value-data';
import * as moment from 'moment-timezone';
import { map } from 'rxjs/operators';
import { CurrentContext } from 'cad/common/store/user/user-model';

@Injectable()
export class WorkflowBaseApi extends ItemApi {
  public readonly endpoint: string = 'workflow/schedule-and-view-workflow';

  constructor(
    private apiHelper: ApiHelper,
    private userModel: UserModelService,
  ) {
    super();
  }

  public isUserInternal(): boolean {
    return this.userModel.isUserInternal() === 'Y';
  }

  public getUsername(): string {
    return this.userModel.getUser().userName;
  }

  public getById(id: number | string): Observable<WorkflowExecutionRequestData> {
    return this.apiHelper.request(this.endpoint + '/' + id).pipe(map((workflow) => {
      // TODO - maybe iterate over the available download types.  This would be less hard coding
      if (workflow.result) {
        let currentContext: CurrentContext = this.userModel.getCurrentContext();
        let queryString = '?asset=' + currentContext.assetAbbr + '&ba=' + (currentContext.businessAssociateDunsNbr ? currentContext.businessAssociateDunsNbr : '');
        workflow.link = {
          pdf: this.apiHelper.getFullUrl(this.endpoint + '/pdf' + '/' + workflow.result + queryString),
          xls: this.apiHelper.getFullUrl(this.endpoint + '/xls' + '/' + workflow.result + queryString),
          ps: this.apiHelper.getFullUrl(this.endpoint + '/ps' + '/' + workflow.result + queryString),
          csv: this.apiHelper.getFullUrl(this.endpoint + '/csv' + '/' + workflow.result + queryString),
          tsv: this.apiHelper.getFullUrl(this.endpoint + '/tsv' + '/' + workflow.result + queryString)
        };
      }
      return workflow;
    }));
  }

  public find(params: any): Observable<WorkflowExecutionRequestData> {
    return this.getById(params.workflowExecutionRequestId);
  }

  public cancelWorkflow(workflowExecutionRequestId: number): Observable<ValidationResult> {
    return this.apiHelper.request(this.endpoint + '/' + workflowExecutionRequestId + '/cancel');
  }

  public filter (params: any, vertical?: string): Observable<WorkflowExecutionRequestData[]> {
    let _params = _.clone(params);
    if(_params && _params.scheduledDt && !_params.dateRangeStartDt && !_params.dateRangeEndDt) {
      _params.dateRangeEndDt = moment(_params.scheduledDt).add(1,'day').format('YYYY-MM-DD');
      _params.dateRangeStartDt = _params.scheduledDt;
      _params.scheduledDt = null;
    }
    if (vertical) {
      _params.workflowVertical = vertical; // ex vertical='reporting'
    }

    // There is a bug in the back end that will return hidden jobs if isRecurring isn't explicitly set by the client.
    if(!('isRecurring' in _params)) {
      _params.isRecurring = null;
    } else if (_params.isRecurring === 'Y' || _params.isRecurring === true) {
      _params.isRecurring = true;
    } else if (_params.isRecurring === 'N' || _params.isRecurring === false) {
      _params.isRecurring = false;
    } else {
      _params.isRecurring = null;
    }

    return this.apiHelper.request(this.endpoint + '/filter', { body: _params });
  }

  //  The bug with the filter endpoint is present in the dashboard endpoint as well, except that explicitly setting isRecurring does not work as a workaround for dashboard.
  //  So we are using the filter endpoint and just sending the parameters it would have set for us automatically.
  // public recent (vertical?: string): Observable<WorkflowExecutionRequestData[]> {
  //   if (vertical) {
  //     return this.apiHelper.request(this.endpoint + '/dashboard', { body: { workflowVertical: vertical, isRecurring: false } });
  //   } else {
  //     return this.apiHelper.request(this.endpoint + '/dashboard', { body: { isRecurring: false } });
  //   }
  // }

  public recent (vertical?: string): Observable<WorkflowExecutionRequestData[]> {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    return vertical ?
      this.apiHelper.request(this.endpoint + '/filter',{ body:
      {
        workflowVertical: vertical,
        isRecurring: false,
        archived: 'N',
        dateRangeStartDt: oneYearAgo,
        assetNbr: this.userModel.getCurrentAssetNbr(),
        maxRows2Retrieve: 10,
        requestorUsername: this.userModel.getUser().userName,
      }})
      :
      this.apiHelper.request(this.endpoint + '/filter',{ body:
      {
        isRecurring: false,
        archived: 'N',
        dateRangeStartDt: oneYearAgo,
        assetNbr: this.userModel.getCurrentAssetNbr(),
        maxRows2Retrieve: 10,
        requestorUsername: this.userModel.getUser().userName,
      }});
  }

  public save(workflow: any): Observable<ValidationResult> {
    if (workflow.workflowExecutionRequestId) {
      return this.apiHelper.request(this.endpoint + '/' + workflow.workflowExecutionRequestId, { body: workflow });
    } else {
      return this.apiHelper.request(this.endpoint + '/saveNew', { body: workflow });
    }
  }

  public createParams(id: number | string): Observable<WorkflowExecutionRequestData> {
    return this.apiHelper.request(this.endpoint + '/createParams/' + id);
  }

  public updateParams (itemData: any, paramName: any): Observable<WorkflowExecutionRequestParamData[]> {
    return this.apiHelper.request(this.endpoint + '/updateParams/' + paramName, { body: itemData });
  }

  public getAvailablePrinters(): Observable<string[]> {
    return this.apiHelper.request(this.endpoint + '/availablePrinters');
  }

  public getWorkflowRequestors(): Observable<Requestor[]> {
    return this.apiHelper.request(this.endpoint + '/requestors');
  }

  public getRecurrencePatterns(): Observable<CodeValueData[]> {
    return this.apiHelper.request(this.endpoint + '/recurrencePatterns');
  }

  public deleteItem(workflow: any): Observable<ValidationResult> {
    return this.apiHelper.request(this.endpoint + '/' + workflow.workflowExecutionRequestId, { method: 'DELETE' });
  }
}


export class Requestor {
  userId: number;
  userName: string;
  firstName: string;
  lastName: string;
  middleInitial: string;
  userTypeInd: string;
}
