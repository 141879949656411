import { Directive, Input, ElementRef, OnInit, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';
import { AutoUnsubscriber, AutoUnsubscribables } from 'cad/shared/mixins/auto-unsubscriber.mixin';
import * as _ from 'lodash';

/**
 * This directive allows input element to limit/restrict the user input to numbers with the
 * supplied decimal precision, default is 2.
 *
 * Example Usage:
 *
 *    <mat-form-field>
 *      <input matInput [uiInputDigit]="4" [(ngModel)]="test" name="test" />
 *    </mat-form-field>
 *
 * Adding [uiInputDigit]="4" to the above input element allows it to limit the user input
 * to a number with max precision of 4 digits.
 */
@Directive({
  selector: '[uiInputDigit]',
  exportAs: 'uiInputDigit'
})
export class UiInputDigitDirective implements OnInit {

  @Input('uiInputDigit') public maxPrecision: number = 2;

  // Allow decimal numbers and negative values
  private regex: RegExp;
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: string[] = [ 'Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Delete' ];
  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  constructor(
    public elementRef: ElementRef,
    public ngModel: NgModel
  ) {}

  @HostListener('keydown', [ '$event' ])
  public onKeyDown(event: KeyboardEvent): void {
    let current: string;
    let next: string;

    if (event) {
      // Allow Backspace, tab, end, and home keys
      if (this.specialKeys.indexOf(event.key) !== -1) {
        return;
      }
      current = this.elementRef.nativeElement.value;
      next = current.concat(event.key);
      if (next && !next.match(this.regex)) {
        event.preventDefault();
      }
    }
  }

  public ngOnInit(): void {
    this.regex = new RegExp(`^\\d*\\.?\\d{0,${this.maxPrecision}}$`, 'g');
    if (this.ngModel) {
      this.subs.newSub = this.ngModel.valueChanges.subscribe((newValue: any) => {
        if (this.isLengthGreaterThanMax(newValue)) {
          this.ngModel.control.setValue(this.getFormattedValue(newValue));
        }
      });
    }
  }

  public getFormattedValue(value: any): number {
    if (this.isNumeric(value)) {
      return parseFloat(parseFloat(value).toFixed(this.maxPrecision));
    }
    return null;
  }

  public isLengthGreaterThanMax(value: any): boolean {
    return !_.isNil(value)
      && value.toString().substr(value.toString().indexOf('.') + 1).length > this.maxPrecision;
  }

  private isNumeric(value: any): boolean {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
}
