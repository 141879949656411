import {
  Component, Input, Output, EventEmitter, ViewChildren, ElementRef, QueryList, DoCheck, OnDestroy
} from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'ui-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: [ './sidenav.component.less' ],
})
export class UiSidenavComponent implements DoCheck, OnDestroy {
  // public autoFocusContent: boolean = false; // modified by nav-link

  @Input() public sections: any;
  @Output() public onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChildren('sectionItemList', { read: ElementRef }) sectionItemElemList: QueryList<ElementRef>;
  public get isSideNavOpen(): boolean {
    return this._isSideNavOpen;
  }
  public set isSideNavOpen(value: boolean) {
    if (!_.isEqual(this._isSideNavOpen, value)) {
      this._isSideNavOpen = value;
      if (this.activeSection) {
        if (this._isSideNavOpen) {
          this.expandSection(this.activeSection);
        } else {
          this.collapseSection(this.activeSection);
        }
      }
    }
  }
  public activeSection: any;
  public activeSectionListElem: any;
  public activeSectionPage: any;
  private _isSideNavOpen: boolean = true;

  constructor() {}

  public ngDoCheck(): void {
    if (this.activeSection) {
      this.setActiveSectionPage(this.activeSection);
    }
  }

  public ngOnDestroy(): void {
    this.onToggle.complete();
    this.onToggle = null;
    this.sections = null;
    this.activeSection = null;
    this.sectionItemElemList = null;
    this.activeSectionListElem = null;
    this.activeSectionPage = null;
  }

  public toggleSideNav(): void {
    this.isSideNavOpen = !this.isSideNavOpen;
    this.onToggle.emit(this.isSideNavOpen);
  }

  public toggleMenu(element: ElementRef, sectionItem: any): void {
    if (sectionItem) {
      if (element) {
        this.activeSectionListElem = element.nativeElement;
      }
      if (sectionItem.isSubMenuOpen) {
        this.collapseSection(sectionItem);
      } else {
        this.expandSection(sectionItem);
      }
    }
  }

  public expandSection(section: any): void {
    let activePage: any;

    if (section) {
      if (this.activeSection) {
        if (!_.isEqual(section.state, this.activeSection.state)) {
          this.activeSectionPage = null;
        }
        if (this.activeSection.isSubMenuOpen) {
          this.collapseSection(this.activeSection);
        }
      }
      this.activeSection = section;
      this.setActiveSectionPage(this.activeSection);
      if (this.activeSectionPage && _.isArray(section.pages) && section.pages.length > 0) {
        activePage = _.find(section.pages, this.activeSectionPage);
        if (activePage) {
          activePage.isActive = true;
        }
      }
      section.isSubMenuOpen = true;
    }
  }

  public collapseSection(section: any): void {
    if (section) {
      if (_.isArray(section.pages) && section.pages.length > 0) {
        section.pages.forEach((pageItem: any) => {
          if (!_.isEqual(_.omit(pageItem, 'isActive'), this.activeSectionPage)) {
            pageItem.isActive = false;
          }
        });
      }
      section.isSubMenuOpen = false;
    }
  }

  public getActiveSectionPage(section: any): any {
    if (section
      && _.isArray(section.pages)
      && section.pages.length > 0) {
      return _.find(section.pages, { isActive: true }) || section.pages[ 0 ];
    }
    return null;
  }

  public setActiveSectionPage(section: any): void {
    let activePage: any = this.getActiveSectionPage(section);

    if (activePage && !_.isEqual(this.activeSectionPage, _.omit(activePage, 'isActive'))) {
      this.activeSectionPage = _.omit(activePage, 'isActive');
    }
  }
}
