<!-- <ui-form type="basic"
         (dirtyStatusChanged)="isDirty = $event.dirtyStatus"> -->
  <h1 fxLayout="row"
      fxLayoutAlign="start center"
      matDialogTitle>
    <div fxFlex>CONFIRM NAVIGATION</div>
    <div>
      <button type="button"
              mat-button
              mat-icon-button
              matDialogClose
              (click)="cancel()"
              >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </h1>
  <div matDialogContent>
    <p>{{confirmationText}}</p>
  </div>
  <div matDialogActions
       fxLayout="row"
       fxLayoutGap="18px"
       fxLayoutAlign="end center">
       <button type="button"
       mat-raised-button
       color="primary"
       (click)="continueNavigation()">CONTINUE
</button>

    <button type="button"
            mat-raised-button
            (click)="cancel()">CANCEL NAVIGATION
    </button>
  </div>
<!-- </ui-form> -->
