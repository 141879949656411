import './app.less';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { StatusModule } from './status/status.module';
import { LoginModule } from './login/login.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { RunAsUserModule } from 'src/cad/login/run-as-user.module';
import { NotFoundComponent } from './status/not-found/not-found.component';
import { ForbiddenComponent } from './status/forbidden/forbidden.component';
import { InternalServerErrorComponent } from './status/internal-server-error/internal-server-error.component';
import { AuthInterceptor } from 'common/services/http-interceptor/auth-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserInterceptor } from 'common/services/http-interceptor/user-interceptor';
import { TimingInterceptor } from 'common/services/http-interceptor/timing-interceptor';
import { DateInterceptor } from 'common/services/http-interceptor/date-interceptor';
import { HeaderInterceptor } from 'common/services/http-interceptor/header-interceptor';
import { PendingInterceptor } from 'common/services/http-interceptor/pending-interceptor';
import { IdleInterceptor } from 'common/services/http-interceptor/idle-interceptor';
import { EmptyStringInterceptor } from 'common/services/http-interceptor/empty-string-interceptor';
import { RedirectGuard } from './informational-postings/redirect-guard';
import { UserProfileModule } from 'cad/user-profile/user-profile.module';
import { SecurityService } from 'src/features/common/security/security.service';
import { RouterModule } from '@angular/router';
import { CadAppComponentsModule } from 'src/features/common/cad-app/cad-app-components.module';
import { FormsModule } from '@angular/forms';
import { FramingRootComponent } from 'src/framing/framing-root.component';
import { BrowserModule } from '@angular/platform-browser';
import { CadAppController } from 'src/features/common/cad-app/cad-app.controller';
import { ControllerFactoryComponent } from 'src/framing/controller-factory.component';
import { AppComponent } from 'src/features/common/cad-app/view/app/app.component';
import { SearchDropdownComponent } from 'src/features/common/cad-app/view/search-dropdown/search-dropdown.component';
import { APP_BASE_HREF } from '@angular/common';

@NgModule({
  declarations: [ AppComponent ],
  bootstrap: [ FramingRootComponent ],
  imports: [
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    StatusModule,
    CadAppComponentsModule,
    BrowserModule.withServerTransition({
      appId: 'app',
    }),
    FormsModule,
    RouterModule.forRoot(
      [
        {
          path: '',
          component: ControllerFactoryComponent,
          data: {
            featureCadApp: {
              name: 'CadApp',
              controller: CadAppController,
              model: {
                isSearching: false,
                footerLogo: 'resources/img/logo.svg',
                disableSearchButton: false,
                disableActionMenu: false
              },
              view: {
                app: AppComponent,
                search: null,
                searchDropdown: SearchDropdownComponent
              },
            }
          },
          children: [
            {
              path: '',
              pathMatch: 'full',
              loadChildren: () => DashboardModule,
              canActivate: [ SecurityService ],
              data: { SecurityModel: { authorization: 'anonymous' } }
            },
            { path: 'login', loadChildren: () => LoginModule },
            { path: 'run-as-user', loadChildren: () => RunAsUserModule },
            {
              path: 'administration',
              loadChildren: () => import('./administration/administration.module')
                .then((m) => { return m.AdministrationModule; })
                .catch((err) => {
                  console.log('Lazy Loading Error in AdministrationModule: ');
                  console.log(err);
                }),
              canActivate: [ SecurityService ],
              data: { SecurityModel: { authorization: 'CustomerActivities.menu.administration' } }
            },
            {
              path: 'contracts',
              loadChildren: () => import('./contracts/contracts.module')
                .then((m) => { return m.ContractsModule; })
                .catch((err) => {
                  console.log('Lazy Loading Error in ContractsModule: ');
                  console.log(err);
                }),
              canActivate: [ SecurityService ],
              data: { SecurityModel: { authorization: 'CustomerActivities.menu.contracts' } },
            },
            {
              path: 'data-administration',
              loadChildren: () => import('cad/data-administration/data-administration.module')
                .then((m) => { return m.DataAdministrationModule; })
                .catch((err) => {
                  console.log('Lazy Loading Error in DataAdministrationModule: ');
                  console.log(err);
                }),
              canActivate: [ SecurityService ],
              data: { SecurityModel: { authorization: 'CustomerActivities.menu.dataAdministration' } },
            },
            {
              path: 'structured-products',
              loadChildren: () => import('./structured-products/structured-products.module')
                .then((m) => { return m.StructuredProductsModule; })
                .catch((err) => {
                  console.log('Lazy Loading Error in StructuredProductsModule: ');
                  console.log(err);
                }),
              canActivate: [ SecurityService ],
              data: { SecurityModel: { authorization: 'CustomerActivities.menu.structuredProducts' } },
            },
            {
              path: 'physical',
              loadChildren: () => import('./physical/physical.module')
                .then((m) => { return m.PhysicalModule; })
                .catch((err) => {
                  console.log('Lazy Loading Error in PhysicalModule: ');
                  console.log(err);
                }),
              canActivate: [ SecurityService ],
              data: { SecurityModel: { authorization: 'CustomerActivities.menu.physical' } },
            },
            {
              path: 'portfolio-management',
              loadChildren: () => import('./portfolio-management/portfolio-management.module')
                .then((m) => { return m.PortfolioManagementModule; })
                .catch((err) => {
                  console.log('Lazy Loading Error in PortfolioManagementModule: ');
                  console.log(err);
                }),
              canActivate: [ SecurityService ],
              data: { SecurityModel: { authorization: 'CustomerActivities.menu.portfolioManagement' } },
            },
            {
              path: 'reports',
              loadChildren: () => import('./reports/reports.module')
                .then((m) => { return m.ReportsModule; })
                .catch((err) => {
                  console.log('Lazy Loading Error in ReportsModule: ');
                  console.log(err);
                })
            },
            {
              path: 'workflow',
              loadChildren: () => import('./workflow/workflow.module')
                .then((m) => { return m.WorkflowModule; })
                .catch((err) => {
                  console.log('Lazy Loading Error in WorkflowModule: ');
                  console.log(err);
                }),
              canActivate: [ SecurityService ],
              data: {
                SecurityModel: {
                  authorization: 'CustomerActivities.menu.workflow.workflowRunner',
                  actionContext: 'workflowRunner'
                }
              },
            },
            {
              path: 'user-profile',
              loadChildren: () => UserProfileModule,
              canActivate: [ SecurityService ],
              data: {
                SecurityModel: {
                  authorization: 'CustomerActivities.menu.maintainProfile',
                  actionContext: 'maintainProfile'
                }
              }
            },
            {
              path: 'invoicing',
              loadChildren: () => import('./invoicing/invoicing.module')
                .then((m) => { return m.InvoicingModule; })
                .catch((err) => {
                  console.log('Lazy Loading Error in InvoicingModule: ');
                  console.log(err);
                }),
              canActivate: [ SecurityService ],
              data: { SecurityModel: { authorization: 'CustomerActivities.menu.invoicing' } },
            },
            {
              path: 'nominations',
              loadChildren: () => import('./nominations/nominations.module')
                .then((m) => { return m.NominationsModule; })
                .catch((err) => {
                  console.log('Lazy Loading Error in NominationsModule: ');
                  console.log(err);
                }),
              canActivate: [ SecurityService ],
              data: { SecurityModel: { authorization: 'CustomerActivities.menu.nominations' } },
            },
            {
              path: 'flowing-gas',
              loadChildren: () => import('./flowing-gas/flowing-gas.module')
                .then((m) => { return m.FlowingGasModule; })
                .catch((err) => {
                  console.log('Lazy Loading Error in FlowingGasModule: ');
                  console.log(err);
                }),
              canActivate: [ SecurityService ],
              data: { SecurityModel: { authorization: 'CustomerActivities.menu.flowManagement' } },
            },
            {
              path: 'infopost',
              loadChildren: () => import('./infopost/infopost.module')
                .then((m) => { return m.InfoPostModule; })
                .catch((err) => {
                  console.log('Lazy Loading Error in InfoPostModule: ');
                  console.log(err);
                }),
              canActivate: [ SecurityService ],
              data: { SecurityModel: { authorization: 'CustomerActivities.menu.infopost' } },
            },
            {
              path: 'capacity-release',
              loadChildren: () => import('./capacity-release/capacity-release.module')
                .then((m) => { return m.CapacityReleaseModule; })
                .catch((err) => {
                  console.log('Lazy Loading Error in CapacityReleaseModule: ');
                  console.log(err);
                }),
              canActivate: [ SecurityService ],
              data: { SecurityModel: { authorization: 'CustomerActivities.menu.capacityRelease' } },
            },
            { path: 'infoPost', canActivate: [ RedirectGuard ], component: NotFoundComponent },
            {
              path: 'site-map',
              loadChildren: () => import('./site-map/site-map.module')
                .then((m) => { return m.SiteMapModule; })
                .catch((err) => {
                  console.log('Lazy Loading Error in SiteMapModule: ');
                  console.log(err);
                }),
            },
            { path: 'dashboard', pathMatch: 'full', redirectTo: '' },
            { path: '404', component: NotFoundComponent },
            { path: '403', component: ForbiddenComponent },
            { path: 'forbidden', component: ForbiddenComponent },
            { path: '500', component: InternalServerErrorComponent },
            { path: '**', component: NotFoundComponent }
          ]
        }
      ],
      {
        enableTracing: false,
        useHash: true,
      }),
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: PendingInterceptor, useClass: PendingInterceptor, deps: [] },
    // order matters, please do not change the order of interceptor without reason.
    { provide: HTTP_INTERCEPTORS, useExisting: PendingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true, },
    { provide: HTTP_INTERCEPTORS, useClass: UserInterceptor, multi: true, },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, },
    // { provide: HTTP_INTERCEPTORS, useClass: TimingInterceptor, multi: true, },
    { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true, },
    { provide: HTTP_INTERCEPTORS, useClass: EmptyStringInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: IdleInterceptor, multi: true, },
    RedirectGuard
  ],
})
export class AppModule {}
