import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'logged-out-warn',
  templateUrl: 'logged-out-warn.html',
})
export class LoggedOutWarn {
  public logoutTime: any;
  constructor(public dialogRef: MatDialogRef<LoggedOutWarn>,
              @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.logoutTime = moment().format('dddd, MMMM Do YYYY, h:mm:ss a');

  }
  public onNoClick(): void {
    this.dialogRef.close(true);
  }

  public login(): void {
    console.log('login');
  }
}
