import { NgModule } from '@angular/core';
import { ConfDateUtil } from 'cad/nominations/common/utils/conf-date-util/conf-date-util';
import { NomDateUtil } from 'cad/nominations/common/utils/nom-date-util/nom-date-util';
import { EdiConfDateUtil } from 'src/cad/nominations/common/utils/edi-conf-date-util/edi-conf-date-util';
import { FutureNomDateUtil } from 'src/cad/nominations/common/utils/future-nom-date-util/future-nom-date-util';
import { SchedQtyDateUtil } from 'src/cad/nominations/common/utils/sched-qty-date-util/sched-qty-date-util';

@NgModule({
  providers: [
    ConfDateUtil,
    EdiConfDateUtil,
    FutureNomDateUtil,
    NomDateUtil,
    SchedQtyDateUtil,
  ]
})
export class CoreUtilsModule {}
