
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { BusinessAssociateApi } from 'cad/common/services/api/associate/business-associate/business-associate';
import { CodesApi } from 'cad/common/services/api/codes/codes';
import { PhysicalLocationApi } from 'cad/common/services/api/physical/location/location';
import { PhysicalAssetApi } from 'cad/common/services/api/physical/asset/asset';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import { FilterByNamePipe } from 'src/cad/common/filters/filter-by-name/filter-by-name.pipe';

@Injectable()
export class AssociateGlobalContactContactDetailsApi {
  public detailMethods: any;
  public assets: any;

  constructor(
    private associateBusinessAssociateApi: BusinessAssociateApi,
    private apiHelper: ApiHelper,
    private codesApi: CodesApi,
    private physicalLocationApi: PhysicalLocationApi,
    private physicalAssetApi: PhysicalAssetApi,
    private userModelService: UserModelService,
    // @Inject('$filter') private $filter: any,
  ) {
    this.detailMethods = {
      ccaList: this.getAsset,
      ccBAList: this.getBA,
      ccCtList: this.getContract,
      ccPtList: this.getLocation,
      ccTrList: this.getTransaction,
      ccCirList: this.getLocationAndContract,
      ccCerList: this.getLocationAndContract,
      ccPirList: this.getLocationAndContract,
      ccPerList: this.getLocationAndContract,
    };
  }

  public getPointAndContractRoles = (codeType: any): Observable<any> => {
    let currentAsset = this.userModelService.getCurrentAssetNbr();
    return this.codesApi.getCodeType(currentAsset, codeType).pipe(map((resp) => {
      return resp.codes.map((element) => {
        return {
          roleCd: element.cd,
          roleDescription: element.value,
        };
      });
    }));
  }

  public getDetail = (displayName: any, terms: any): Observable<any> => {
    return this.detailMethods[displayName](terms);
  }

  private getAsset = (terms: any): Observable<any> => {
   // if (!terms) { return Observable.of([]); }

    let filter = new FilterByNamePipe();

   /*
    let physApiSub = this.physicalAssetApi.findAll().subscribe((assets: any) => {
      this.assets = assets;
      //assets.forEach((a) => this.disabledList[a.abbreviation] = false);
      //this.associationController.items.forEach((a) => this.disabledList[a.abbrAssetName] = true);
    });
    //this.subscriptions.push(physApiSub);
    */
    let gridItems = this.physicalAssetApi.findAll().pipe(map((resp: any) => {
      return resp.map((element) => {
        return {
          assetAbbrName: element.abbreviation,
          assetName: element.name,
          assetNbr: element.number,
        };
      });
    }));
    /*
    let gridItems = this.userModelService.getAvailableAssets().map((element) => {
      return {
        assetAbbrName: element.abbreviation,
        assetName: element.name,
        assetNbr: element.number,
      };
    });
    */
    // FIXME(ng2) impl
    // let filtered = this.$filter('filter')(gridItems, terms);
    // return this.asPromise(filtered);
    // return Observable.of([]);
    //return Observable.of(this.assets);
    return gridItems;
  }

  private getBA = (terms: any): Observable<any> => {
    if (!terms) { return observableOf([]); }

    return this.associateBusinessAssociateApi.filter(terms).pipe(map((resp) => {
      return resp.map((element) => {
        return {
          baAbbrName: element.baAbbrName,
          baId: element.baId,
          baName: element.baName,
        };
      });
    }));
  }

  private getContract = (terms: any): Observable<any> => {
    if (!terms) { return observableOf([]); }

    return this.apiHelper.request('associate/contact-combine/filterContract', { method: 'POST', body: terms });
  }

  private getLocation = (terms: any): Observable<any> => {
    if (!terms) { return observableOf([]); }

    return this.physicalLocationApi.filter(terms).pipe(map((resp) => {
      return resp.map((element) => {
        return {
          assetNumber: element.assetNumber,
          pointCd: element.pointCd,
          pointName: element.pointName,
          pointTypeCd: element.pointTypeCd,
        };
      });
    }));
  }

  private getTransaction = (terms: any): Observable<any> => {
    if (!terms) {
      return observableOf([]);
    }
    return this.apiHelper.request('associate/contact-combine/filterTransaction', { method: 'POST', body: terms });
  }

  private getLocationAndContract = (codeType: any): Observable<any> => {
    let currentAsset = this.userModelService.getCurrentAssetNbr();
    return this.codesApi.getCodeType(currentAsset, codeType).pipe(map((resp) => {
      return resp.codes.map((element) => {
        return {
          roleCd: element.cd,
          roleDescription: element.value,
        };
      });
    }));
  }
}
