<button disableRipple="true"
        mat-button
        class="menu-button"
        [matMenuTriggerFor]="rootMenu"
        *ngIf="menuData[0].baseMenu"
        #levelOneTrigger="matMenuTrigger">
        <mat-icon aria-label="menu" class="material-icons" *ngIf="!levelOneTrigger.menuOpen">menu</mat-icon>
        <mat-icon aria-label="menu" class="material-icons" *ngIf="levelOneTrigger.menuOpen">clear</mat-icon>
</button>


<mat-menu #rootMenu="matMenu" yPosition="below" [overlapTrigger]="false" [class]="'root-menu-panel'">
    <span *ngFor="let item of menuData">
      <!-- Handle branch node buttons here -->
      <span *ngIf="item.children && item.children.length > 0">
        <button mat-menu-item class="main-menu-item" [matMenuTriggerFor]="menu.childMenu" *ngIf="((item.enabled && item.visible))">
          {{item.label}}
        </button>
      </span>
      <ui-menu-item #menu [item]="item.children"></ui-menu-item>
      <span *ngIf="!item.children || item.children.length === 0">
        <button mat-button>
          {{item.label}}
        </button>
      </span>
    </span>
</mat-menu>


