import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'src/cad/common/services/api/api-helper';

@Injectable()
export class CapacityReleaseOfferRankBidsApi {
  private readonly endpoint: string = 'capacity-release/offer/';
  private readonly suffix: string = '/rank-bids';

  constructor(private apiHelper: ApiHelper) {}

  public getRankBids = (auctionId: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix);
  }

  public save = (auctionId: number | string, rankBidsDataList: any[]): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/save', { body: rankBidsDataList });
  }
}
