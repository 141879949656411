import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { isString } from 'lodash';

@Injectable()
export class SecurityUserApi {
  private readonly endpoint: string = 'security';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public getUserByLogin = (id: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/find-users/' + id);
  }

  /**
   * Set the ba and asset name for a logged in user.
   * This will be the asset and ba they are doing business for.
   * @param assetName
   * @param baName
   * @returns RBSUser
   */
  public setUserContext = (assetName: string, baName: string): Observable<any> => {
    return this.apiHelper.request(
      this.endpoint + '/set-user-context/' + (isString(assetName) && assetName.trim().endsWith('.') ? (assetName + '/') : assetName),
      { body: { baName } }
    );
  }

  /**
   * Get user details back for a given uesr id
   * @param id
   * @returns RBSUser
     */
  public getUser = (id: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/get-user/' + id);
  }
}
