<div cdkTrapFocus>
  <!-- Contains:
  Non-Material version 10.0.1 feature: Datepicker Actions, Datepicker Month Year
  If the datepicker uses action mode then do not close the calendar on user select.
-->
  <mat-calendar [id]="datepicker.id"
                [monthMode]="datepicker.monthMode"
                [ngClass]="datepicker.panelClass"
                [startAt]="datepicker.startAt"
                [startView]="datepicker.startView"
                [minDate]="datepicker._minDate"
                [maxDate]="datepicker._maxDate"
                [dateFilter]="datepicker._dateFilter"
                [headerComponent]="datepicker.calendarHeaderComponent"
                [selected]="_getSelected()"
                [dateClass]="datepicker.dateClass"
                [comparisonStart]="comparisonStart"
                [comparisonEnd]="comparisonEnd"
                [@fadeInCalendar]="'enter'"
                (yearSelected)="datepicker._selectYear($event)"
                (monthSelected)="datepicker._selectMonth($event)"
                (_userSelection)="_handleUserSelection($event)">
  </mat-calendar>

  <!-- Start of:
    Non-Material version 10.0.1 feature: Datepicker Time
  -->
  <ng-container *ngIf="showTimepicker">
    <span class="mat-divider"></span>
    <div class="mat-timepicker-container">
      <mat-form-field>
        <input matInput
               type="text"
               (input)="$any(datepicker).formatInputValue($event?.target)"
               maxlength="2"
               [ngModel]="$any(datepicker).hourValue"
               #hourModelRef="ngModel"
               (ngModelChange)="$any(datepicker).onTimeModelChange('hour', $event)"
               name="hourValue"
               (keydown)="$any(datepicker).onTimeInputKeydown($event, 'hour', hourModelRef)"
               (blur)="$any(datepicker).changeTimeModel('hour', hourModelRef)">
      </mat-form-field>
      <span class="mat-timepicker-separator">&#58;</span>
      <mat-form-field>
        <input matInput
               type="text"
               (input)="$any(datepicker).formatInputValue($event?.target)"
               maxlength="2"
               [ngModel]="$any(datepicker).minuteValue"
               #minuteModelRef="ngModel"
               (ngModelChange)="$any(datepicker).onTimeModelChange('minute', $event)"
               [disabled]="$any(datepicker)?.disableMinute"
               name="minuteValue"
               (keydown)="$any(datepicker).onTimeInputKeydown($event, 'minute', minuteModelRef)"
               (blur)="$any(datepicker).changeTimeModel('minute', minuteModelRef)">
      </mat-form-field>
      <ng-container *ngIf="($any(datepicker)?.includeSeconds)">
        <span class="mat-timepicker-separator">&#58;</span>
        <mat-form-field>
          <input matInput
                 type="text"
                 (input)="$any(datepicker).formatInputValue($event?.target)"
                 maxlength="2"
                 [ngModel]="$any(datepicker).secondValue"
                 #secondModelRef="ngModel"
                 (ngModelChange)="$any(datepicker).onTimeModelChange('second', $event)"
                 name="secondValue"
                 (keydown)="$any(datepicker).onTimeInputKeydown($event, 'second', secondModelRef)"
                 (blur)="$any(datepicker).changeTimeModel('second', secondModelRef)">
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="($any(datepicker)?.meridianMode)">
        <button mat-button
                class="mat-timepicker-meridian-button"
                type="button"
                (click)="$any(datepicker).toggleMeridian(hourModelRef)">
          {{$any(datepicker)?.meridianValue}}
        </button>
      </ng-container>
    </div>
  </ng-container>
  <!-- End of:
    Non-Material version 10.0.1 feature: Datepicker Time
  -->

  <!-- Start of:
    Non-Material version 10.0.1 feature: Datepicker Actions
    If the datepicker uses action mode then render the datepicker actions.
  -->
  <ng-container *ngIf="showActions">
    <span class="mat-divider"></span>
    <div class="mat-datepicker-actions">
      <button mat-button
              mat-raised-button
              type="button"
              (click)="datepicker.onCancel()">CANCEL
      </button>
      <button mat-button
              mat-raised-button
              type="button"
              color="primary"
              (click)="datepicker.onSubmit()">OK
      </button>
    </div>
  </ng-container>
  <!-- End of:
    Non-Material version 10.0.1 feature: Datepicker Actions
    If the datepicker uses a submit button mode then render the datepicker actions.
  -->
</div>
