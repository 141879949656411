<mat-toolbar class="mat-primary" fxLayout="row">
  <ui-menu *ngIf="showMenuButton" [menuData]="menu[0].menuItems"></ui-menu>
  <ui-user-context *ngIf="isLoggedIn" fxFlex=""></ui-user-context>
  <span *ngIf="!isLoggedIn" fxFlex=""></span>
  <ui-process-status-button></ui-process-status-button>
  <ui-search-menu *ngIf="isLoggedIn && showSearchButton"></ui-search-menu>
  <ui-action-menu *ngIf="isLoggedIn"></ui-action-menu>
  <ui-alerts-button></ui-alerts-button>
  <ui-login-menu></ui-login-menu>
</mat-toolbar>
