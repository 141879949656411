import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AssociateContactAddressApi } from 'cad/common/services/api/associate/contact/address/address';
import { AssociateContactAssetBaApi } from 'cad/common/services/api/associate/contact/asset-ba/asset-ba';
import { AssociateContactBusinessAssociateApi } from 'cad/common/services/api/associate/contact/business-associate/business-associate';
import { AssociateContactNotificationApi } from 'cad/common/services/api/associate/contact/notification/notification';
import { AssociateContactProfileApi } from 'cad/common/services/api/associate/contact/profile/profile';
import { AssociateContactSecurityApi } from 'cad/common/services/api/associate/contact/security/security';
import {ContactInternalContactApi} from 'cad/common/services/api/associate/contact/internal-contact/internal-contact';

@Injectable()
export class AssociateContactApi {

  public detail: Function;
  private readonly endpoint: string = 'associate/contact';

  constructor(
    public address: AssociateContactAddressApi,
    public assetBa: AssociateContactAssetBaApi,
    public businessAssociate: AssociateContactBusinessAssociateApi,
    public notification: AssociateContactNotificationApi,
    public profile: AssociateContactProfileApi,
    public security: AssociateContactSecurityApi,
    public internalContact: ContactInternalContactApi,
    private apiHelper: ApiHelper) {
    this.detail = this.businessAssociate.findAll;
  }
  // ========================================
  // Common methods - Implemented in Scaffold ItemApi
  // ========================================

  public filter = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public find = (id: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/' + id);
  }

  public getUserByLogin = (login: string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/filter/login-name/' + login);
  }

}
