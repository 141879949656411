import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'ui-button-dropdown',
  template: `
    <button mat-menu-item
            [disabled]="disabled || securityDisabled"
            (click)="btnClick.emit($event)">
      <ng-content></ng-content>
    </button>`
})
export class ButtonDropdownComponent extends BaseButtonComponent {
  
  @Input() public disabled: boolean;
  @Output() public btnClick: EventEmitter<any> = new EventEmitter<any>();
}
