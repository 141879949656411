export const DASHBOARD_CONFIG = [
  {
    code: 'ba',
    value: 'Business Associates',
    auth: 'CustomerActivities.menu.dataAdministration.businessAssociate',
    emptyHeader: 'No Recent BAs',
    DefaultHeaderLine: 'Recent Business Associates',
    tableConfig: [
      { colName: 'addrLine1', colTitle: 'Address Line 1' },
      { colName: 'addrLine2', colTitle: 'Address Line 2' },
      { colName: 'addrLine3', colTitle: 'Address Line 3' }
    ],
    endpoint: 'associate/business-associate/dashboard',
    navigationPath: [
      'data-administration',
      'business-associate'
    ],
    key: 'baId'
  },
  {
    code: 'report',
    value: 'Reports',
    auth: 'CustomerActivities.menu.reports',
    emptyHeader: 'No Recent Reports',
    DefaultHeaderLine: '`Reports for ${this.userDetails.firstName} ${this.userDetails.lastName}`',
    Component: 'ReportDashPartialComponent',
    tableConfig: [
      { colName: 'addrLine1', colTitle: 'Address Line 1' },
      { colName: 'addrLine2', colTitle: 'Address Line 2' },
      { colName: 'addrLine3', colTitle: 'Address Line 3' }
    ],
    endpoint: 'workflow/schedule-and-view-workflow/dashboard',
    navigationPath: [
      'reports',
      'schedule-and-view-reports'
    ],
    key: 'workflowExecutionRequestId'

  },
  {
    code: 'workflow',
    value: 'Workflows',
    auth: 'CustomerActivities.menu.workflow',
    emptyHeader: 'No Recent Workflows',
    DefaultHeaderLine: '`Workflows for ${this.userDetails.firstName} ${this.userDetails.lastName}`',
    Component: 'ReportDashPartialComponent',
    tableConfig: [
      { colName: 'addrLine1', colTitle: 'Address Line 1' },
      { colName: 'addrLine2', colTitle: 'Address Line 2' },
      { colName: 'addrLine3', colTitle: 'Address Line 3' }
    ],
    endpoint: 'workflow/schedule-and-view-workflow/dashboard',
    navigationPath: [
      'workflows',
      'schedule-and-view-reports'
    ],
    key: 'workflowExecutionRequestId'

  },
  {
    code: 'report-fav',
    value: 'Favorite Reports for Current Asset',
    auth: 'CustomerActivities.menu.reports',
    emptyHeader: 'No Favorite Reports',
    DefaultHeaderLine: '`Favorites for ${this.userDetails.firstName} ${this.userDetails.lastName}`',
    Component: 'ReportFavDashComponent',
    tableConfig: [
      { colName: 'workflowName', colTitle: 'Report' }
    ],
    endpoint: '',
    navigationPath: [
      'reports',
      'schedule-and-view-reports',
      'new'
    ],
    key: 'workflowId'
  },
  {
    code: 'address',
    value: 'Addresses',
    auth: 'CustomerActivities.menu.dataAdministration.address',
    emptyHeader: 'No Recent Addresses',
    DefaultHeaderLine: 'Recent Addresses',
    endpoint: 'associate/address/dashboard',
    navigationPath: [
      'data-administration',
      'address'
    ],
    key: 'addressId'
  },
  {
    code: 'contact',
    value: 'Contacts',
    auth: 'CustomerActivities.menu.dataAdministration.contact',
    emptyHeader: 'No Recent Contacts',
    DefaultHeaderLine: 'Recent Contacts',
    endpoint: 'associate/contact/dashboard',
    navigationPath: [
      'data-administration',
      'contact'
    ],
    key: 'contactId'
  },
  {
    code: 'caprel',
    value: 'Capacity Release',
    auth: 'CustomerActivities.menu.capacityRelease.pendingCreditBids',
    emptyHeader: 'No Capacity Release Bids',
    DefaultHeaderLine: 'My Capacity Release Bids',
    endpoint: 'capacity-release/offer/pending-credit-bids',
    navigationPath: [
      'capacity-release',
      'offers'
    ],
    key: 'relatedAuctionID'
  },
  {
    code: 'nom',
    value: 'Pinned Nominations',
    auth: 'CustomerActivities.menu.nominations.confirmations',
    emptyHeader: 'No Pinned Nominations',
    DefaultHeaderLine: 'Pinned Nominations',
    endpoint: 'unknown',
    navigationPath: [
      'unknown',
      'unknown'
    ],
    key: 'taskId'
  },
  {
    code: 'contract',
    value: 'Contracts',
    auth: 'CustomerActivities.menu.contracts.contract',
    emptyHeader: 'No Pending Contracts',
    DefaultHeaderLine: 'Contract Approvals',
    endpoint: 'agreement/contract/pending-approval-contract',
    navigationPath: [
      'contracts',
      'contract'
    ],
    key: 'contractId'
  },
  {
    code: 'offerreview',
    value: 'Offers in Review',
    auth: 'CustomerActivities.menu.capacityRelease.bidsPendingAction',
    emptyHeader: 'No Offers Pending Review',
    DefaultHeaderLine: 'Available Offers in Review',
    endpoint: 'capacity-release/offer/bids-pending-action',
    navigationPath: [
      'capacity-release',
      'offers'
    ],
    key: 'relatedAuctionID'
  }
];
