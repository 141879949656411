import { EmitterService } from 'cad/core/services/emitter/emitter.service';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import { CurrentContext } from 'src/cad/common/models/user/user-details-model';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { RouterService } from 'src/cad/core/services/router/router.service';
import { MatSelectChange } from '@angular/material/select';
import { AssetService } from 'cad/common/store/user/current-context/asset';
import { UserStoreService } from 'cad/common/store/core/services/user-store.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'ui-user-context',
  templateUrl: './header-toolbar-user-context.component.html',
  styleUrls: [ './header-toolbar-user-context.component.less' ],
})
export class HeaderToolbarUserContextComponent implements OnInit, OnDestroy {
  currentContext: CurrentContext;
  availableBusinessAssociates: any[] = [];
  selectedBAName: string = '';
  selectedAssetName: any;
  availableAssets: any[] = [];
  private unSub: Subject<void> = new Subject<void>();

  constructor(
    private userServiceModel: UserModelService,
    private emitterService: EmitterService,
    private router: RouterService,
    private assetService: AssetService,
    private currentContextService: UserStoreService,
    @Inject(DOCUMENT) private documentRef: Document
  ) {}

  ngOnInit(): void {
    this.currentContextService.stateChanged
      .pipe(takeUntil(this.unSub))
      .subscribe((userDetail) => {
        if (userDetail.state && userDetail.state.user) {
          this.currentContext = userDetail.state.user.currentContext;
          this.availableAssets = userDetail.state.availableAssets;
          this.availableBusinessAssociates = (userDetail.state.user.availablecontexts && userDetail.state.user.currentContext)
            ? userDetail.state.user.availablecontexts[ userDetail.state.user.currentContext.assetName ]
            : [];
          if (this.currentContext && this.currentContext.businessAssociateName === 'undefined') {
            this.currentContext.businessAssociateName = '';
          }
          this.selectedBAName = this.currentContext && this.currentContext.businessAssociateName
            ? this.currentContext.businessAssociateName
            : '';
          this.selectedAssetName = this.currentContext && this.currentContext.assetNbr
            ? this.currentContext.assetNbr
            : '';
          this.documentRef.title = this.currentContext && this.currentContext.assetAbbr
            ? (this.currentContext.assetAbbr + ' - Messenger+ by Energy Transfer')
            : 'Messenger+ by Energy Transfer';
        } else {
          console.log('logging out');
          this.currentContext = null;
          this.availableAssets = [];
          this.availableBusinessAssociates = [];
          this.selectedBAName = '';
          this.selectedAssetName = '';
        }
      });
  }

  ngOnDestroy(): void {
    this.unSub.next();
    this.unSub.complete();
  }

  selectedAssetChanged(changeObj: MatSelectChange): void {
    if (changeObj) {
      let newAssetValue = this.availableAssets.find((asset) => asset.number === changeObj.value);

      this.router.navigate([ '/dashboard' ]).then((value: boolean) => {
        if (value) {
          // Route navigation succeeded
          this.assetService.changeAsset(newAssetValue);
        } else if (this.currentContext && this.currentContext.assetNbr) {
          // Route navigation failed/cancelled
          this.selectedAssetName = this.currentContext.assetNbr;
        }
      });
    }
  }

  selectedBANameChanged(changeObj: MatSelectChange): void {
    if (changeObj) {
      if (!this.currentContext || this.currentContext.businessAssociateName !== changeObj.value) {
        this.router.navigate([ '/dashboard' ]).then((value: boolean) => {
          if (value) {
            // Route navigation succeeded
            this.assetService.changeBA(changeObj.value);
          } else if (this.currentContext && this.currentContext.businessAssociateName) {
            // Route navigation failed/cancelled
            this.selectedBAName = this.currentContext.businessAssociateName;
          }
        });
      }
    }
  }
}
