import { Injectable } from '@angular/core';
import { ActionGroup, ActionSecurityService } from './action-security.service';
import * as _ from 'lodash';

@Injectable()
export class ActionService {

  constructor(
    public securityService: ActionSecurityService,
  ) {}

  public canPerform(type: any, action: any, context?: any): boolean {
    let rv = false;
    if (type === cad.uiProtection.PROTECTION_GROUP_SCREEN_FUNC) {
      rv = this.canPerformScreenFunction(action, context);
    } else if (type === cad.uiProtection.PROTECTION_GROUP_ACTIONBUTTON
      || type === cad.uiProtection.PROTECTION_GROUP_ACTIONBUTTON_CAMELCASE) {
      rv = this.canPerformAction(action, context);
    }
    return rv;
  }

  public isVisible(type: any, action: any, context?: any): boolean {
    let actionGroup: ActionGroup;
    let actionList: any[] = [ action, 'all' ];

    if (type === cad.uiProtection.PROTECTION_GROUP_SCREEN_FUNC) {
      actionGroup = this.securityService.getContextGroup(cad.uiProtection.PROTECTION_GROUP_SCREEN_FUNC, context);
    } else if (type === cad.uiProtection.PROTECTION_GROUP_ACTIONBUTTON ) {
      actionGroup = this.securityService.getContextGroup(cad.uiProtection.PROTECTION_GROUP_ACTIONBUTTON, context);
    } else if (type === cad.uiProtection.PROTECTION_GROUP_ACTIONBUTTON_CAMELCASE ) {
      actionGroup = this.securityService.getContextGroup(cad.uiProtection.PROTECTION_GROUP_ACTIONBUTTON_CAMELCASE, context);
    }
    return this.isActionVisible(actionGroup, actionList);
  }

  public isEnabled(type: any, action: any, context?: any): boolean {
    let actionGroup: ActionGroup;
    let actionList: any[] = [ action, 'all' ];

    if (type === cad.uiProtection.PROTECTION_GROUP_SCREEN_FUNC) {
      actionGroup = this.securityService.getContextGroup(cad.uiProtection.PROTECTION_GROUP_SCREEN_FUNC, context);
    } else if (type === cad.uiProtection.PROTECTION_GROUP_ACTIONBUTTON ) {
      actionGroup = this.securityService.getContextGroup(cad.uiProtection.PROTECTION_GROUP_SCREEN_FUNC, context);
    } else if (type === cad.uiProtection.PROTECTION_GROUP_ACTIONBUTTON_CAMELCASE ) {
      actionGroup = this.securityService.getContextGroup(cad.uiProtection.PROTECTION_GROUP_ACTIONBUTTON_CAMELCASE, context);
    }
    return this.isActionEnabled(actionGroup, actionList);
  }

  public canPerformScreenFunction(action: any, context?: any): boolean {
    let actionGroup: ActionGroup;
    let actionArray: any[] = [ action, 'all' ];

    if (this.securityService) {
      actionGroup = this.securityService.getContextGroup(cad.uiProtection.PROTECTION_GROUP_SCREEN_FUNC, context);
    }
    return this.isActionEnabled(actionGroup, actionArray);
  }

  public canPerformAction(action: any, context?: any): boolean {
    let rv = false;
    let actionGroup: ActionGroup;

    if (this.securityService) {
      actionGroup = this.securityService.getContextGroup(cad.uiProtection.PROTECTION_GROUP_ACTIONBUTTON, context);
      if (!actionGroup) {
        actionGroup = this.securityService.getContextGroup(cad.uiProtection.PROTECTION_GROUP_ACTIONBUTTON_CAMELCASE, context);
      }
    }

    switch (_.toUpper(action)) {
      case cad.actionConstants.ACTION_SAVE: {
        let saveList = [ 'save', 'submit', 'all' ];
        rv = this.isActionEnabled(actionGroup, saveList);
        break;
      }
      case cad.actionConstants.ACTION_DELETE: {
        let actionList = [ 'delete', 'all' ];
        rv = this.isActionEnabled(actionGroup, actionList);
        break;
      }
      case cad.actionConstants.ACTION_COPY: {
        let actionList = [ 'copy', 'all' ];
        rv = this.isActionEnabled(actionGroup, actionList);
        break;
      }
      case cad.actionConstants.ACTION_PRINT: {
        let actionList = [ 'print', 'all' ];
        rv = this.isActionEnabled(actionGroup, actionList);
        break;
      }
      case cad.actionConstants.ACTION_NEW: {
        let actionList = [ 'new', 'all' ];
        rv = this.isActionEnabled(actionGroup, actionList);
        break;
      }
      case cad.actionConstants.ACTION_RETRIEVE: {
        let actionList = [ 'retrieve', 'all' ];
        rv = this.isActionEnabled(actionGroup, actionList);
        break;
      }
      default: {
        console.error(`The ${action} action is not implemented for ${actionGroup}`);
        break;
      }
    }
    return rv;
  }

  private isActionEnabled(actionGroup: any, actionKeyList: string[]): boolean {
    if(!actionGroup) {
      return false;
    }
    let rv = false;
    for (let entry of actionKeyList) {
      if (actionGroup.hasOwnProperty(entry)) { // tslint: forin
        return actionGroup[entry].enabled;
      }
    }
    return rv;
  }

  private isActionVisible(actionGroup: any, actionKeyList: string[]): boolean {
    if(!actionGroup) {
      return false;
    }
    let rv = false;
    for (let entry of actionKeyList) {
      if (actionGroup.hasOwnProperty(entry)) { // tslint: forin
        return actionGroup[entry].visible !== false;
      }
    }
    return rv;
  }
}
