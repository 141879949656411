<span class="yesno mat-form-field mat-form-field-can-float mat-form-field-should-float">
  <div class="mat-form-field-placeholder-wrapper">
    <label class="mat-form-field-placeholder mat-float">
      {{label}}
      <span class="mat-placeholder-required"
            *ngIf="required"> *</span>
    </label>
  </div>
  <mat-radio-group [(ngModel)]="model"
                   fxLayoutGap="20px"
                   fxLayout="row"
                   [disabled]="disabled"
                   [required]="required">
    <mat-radio-button value="Y"
                      aria-label="Yes">Yes</mat-radio-button>
    <mat-radio-button value="N"
                      aria-label="No">No</mat-radio-button>
  </mat-radio-group>
</span>
