
import {of as observableOf,  Observable ,  Subscription ,  Subject } from 'rxjs';
import {
  Component, ElementRef, EventEmitter, Input, Output, OnInit, OnChanges, OnDestroy,
  Renderer2, SimpleChanges, SimpleChange, ViewChild, NgZone, ChangeDetectorRef
} from '@angular/core';
import { take } from 'rxjs/operators';

export interface ChangeEvent {
  start?: number;
  end?: number;
}
@Component({
  selector: 'ui-virtual-repeat,[uiVirtualRepeat]',
  templateUrl: './virtual-repeat.component.html',
  styleUrls: [ './virtual-repeat.component.less' ],
  exportAs: 'uiVirtualRepeat'
})
export class UiVirtualRepeatComponent implements OnInit, OnChanges, OnDestroy {

  @Input('vrItems') originalCollection: any[] = [];
  @Input('vrActivated')
  get activated(): boolean {
    return this._activated;
  }
  set activated(value: boolean) {
    if (!_.isEqual(this._activated, value)) {
      this._activated = value;
      if (this._activated) {
        if (!this.scrollContainer) {
          this.scrollContainer = this.element.nativeElement;
        }
        this.initScrollContainer();
      } else {
        this.revertScrollContainer();
      }
    }
  }
  @Input('vrScrollContainer')
  get scrollContainer(): any {
    return this._scrollContainerRef;
  }
  set scrollContainer(value: any) {
    let containerEl: any;
    if (_.isString(value) && !_.isEmpty(value)) {
      containerEl = this.element.nativeElement.querySelector(value);
    } else {
      containerEl = value;
    }
    if (containerEl && !_.isEqual(this._scrollContainerRef, containerEl)) {
      this.revertScrollContainer();
      this._scrollContainerRef = containerEl;
    }
  }
  @Input('vrItemsToBuffer') itemsToBuffer: number = 0;
  @Input('vrScrollbarWidth') scrollbarWidth: number;
  @Input('vrScrollbarHeight') scrollbarHeight: number;
  @Input('vrChildWidth') childWidth: number;
  @Input('vrChildHeight') childHeight: number;
  @Output('vrVirtualItemsChange') virtualCollectionChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output('vrIndexChange') virtualIndexChange: EventEmitter<ChangeEvent> = new EventEmitter<ChangeEvent>();

  @ViewChild('content', { read: ElementRef }) contentElementRef: ElementRef;

  viewPortItems: any[];
  scrollHeight: number;
  contentYOffset: number;
  startIndex: number;
  startIndexWithoutBuffer: number;
  endIndex: number;
  endIndexWithoutBuffer: number;
  previousStart: number;
  previousEnd: number;
  startupLoop: boolean = true;
  isScrolling: boolean;
  onScrollDone: EventEmitter<any> = new EventEmitter<any>(true);

  private subscriptions: Subscription[] = [];
  private _activated: boolean;
  private _scrollContainerRef: any;
  private _scrollListener: any;
  private _debouncedOnScrollCallbackFn: Function & _.Cancelable;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private element: ElementRef,
    private renderer: Renderer2,
    private ngZone: NgZone,
  ) {
    this._debouncedOnScrollCallbackFn = _.debounce(this.onScrollCallback, 100);
  }

  ngOnInit(): void {
    this.scrollbarWidth = 0;
    this.scrollbarHeight = 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    let changeDetected: boolean = false;
    const changeObj: SimpleChange = changes.originalCollection;
    if (changeObj && !changeObj.isFirstChange() && !_.isEqual(changeObj.currentValue, changeObj.previousValue)) {
      changeDetected = true;
    }
    if (changeObj !== undefined
      && (_.isNil(changeObj.previousValue)
      || (!_.isNil(changeObj.previousValue) && changeObj.previousValue.length === 0))) {
      this.startupLoop = true;
    }
    if (changeDetected) {
      this.previousStart = undefined;
      this.previousEnd = undefined;
    }
    this.refresh();
  }

  ngOnDestroy(): void {
    this._debouncedOnScrollCallbackFn.cancel();
    this.onScrollDone.complete();
    this.virtualCollectionChange.complete();
    this.virtualIndexChange.complete();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.revertScrollContainer();
  }

  initScrollContainer(): void {
    if (!this.scrollContainer || !this.activated) { return; }
    this.renderer.addClass(this.scrollContainer, 'ui-virtual-repeat-scroll-container');
    this._scrollListener = this.renderer.listen(this.scrollContainer, 'scroll', (event: any) => {
      this.isScrolling = true;
      this._debouncedOnScrollCallbackFn(event);
      this.refresh();
    });
  }

  revertScrollContainer(): void {
    if (this.scrollContainer) {
      this._debouncedOnScrollCallbackFn.cancel();
      if (this.isScrolling) {
        this.isScrolling = false;
      }
      if (_.isFunction(this._scrollListener)) {
        this._scrollListener();
        this._scrollListener = null;
      }
      this.renderer.removeClass(this.scrollContainer, 'ui-virtual-repeat-scroll-container');
      this._scrollContainerRef = null;
    }
  }

  refresh(animationFrame: boolean = true): void {
    if (this.activated) {
      if (animationFrame) {
        requestAnimationFrame(() => this.calculateItems());
      } else {
        this.calculateItems();
      }
    }
  }

  onScrollCallback(event: any): void {
    this.isScrolling = false;
    if (event && event.target) {
      this.onScrollDone.emit(event.target.scrollTop);
    }
  }

  scrollToIndex(index: number): void {
    if (!this.activated
      || !this.scrollContainer
      || index < 0
      || index >= (this.originalCollection || []).length) {
      return;
    }
    const dimensions: any = this.calculateDimensions();
    let scrollTopValue: number = Math.floor(index / dimensions.itemsPerRow) * dimensions.childHeight;
    this.scrollTo(scrollTopValue).pipe(take(1)).subscribe();
  }

  scrollToItem(item: any): void {
    const index: number = (this.originalCollection || []).indexOf(item);
    this.scrollToIndex(index);
  }

  scrollTo(scrollTopValue: number): Observable<any> {
    let scrollSubject: Subject<any> = new Subject<any>();
    let validScrollTopValue: number = Math.max(0, this.toValidNumber(scrollTopValue));
    if (!this.scrollContainer || this.scrollContainer.scrollTop === validScrollTopValue) {
      return observableOf(undefined);
    }
    this.onScrollDone.asObservable().pipe(take(1)).subscribe((scrollTop: number) => {
      scrollSubject.next(scrollTop);
    });
    this.scrollContainer.scrollTop = validScrollTopValue;
    return scrollSubject.asObservable();
  }

  runOnStableZone(fn: Function): void {
    this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
      fn();
    });
  }

  private countItemsPerRow(): number {
    const contentEl: any = this.contentElementRef.nativeElement;
    let offsetTop: number;
    let itemsPerRow: number = 0;
    if (contentEl && contentEl.children) {
      for (itemsPerRow = 0; itemsPerRow < contentEl.children.length; itemsPerRow++) {
        if (offsetTop !== undefined && offsetTop !== contentEl.children[itemsPerRow].offsetTop) { break; }
        offsetTop = contentEl.children[itemsPerRow].offsetTop;
      }
    }
    return itemsPerRow;
  }

  private calculateDimensions(): any {
    const scrollEl: any = this.scrollContainer;
    if (!scrollEl) {
      return null;
    }
    const contentEl: any = this.contentElementRef.nativeElement;
    const items = this.originalCollection || [];
    const itemCount = items.length;
    const viewWidth = scrollEl.clientWidth - this.scrollbarWidth;
    const viewHeight = scrollEl.clientHeight - this.scrollbarHeight;
    let contentDimensions;
    if (this.childWidth == undefined || this.childHeight == undefined) {
      contentDimensions = (contentEl && contentEl.children && contentEl.children[ 0 ])
        ? contentEl.children[ 0 ].getBoundingClientRect()
        : { width: viewWidth, height: viewHeight };
    }
    const childWidth: number = this.childWidth || contentDimensions.width;
    const childHeight: number = this.childHeight || contentDimensions.height;
    let itemsPerRow: number = Math.max(1, this.toValidNumber(this.countItemsPerRow()));
    const itemsPerRowByCalc: number = Math.max(1, this.toValidNumber(Math.floor(viewWidth / childWidth)));
    const itemsPerCol: number = Math.max(1, this.toValidNumber(Math.floor(viewHeight / childHeight)));
    const scrollTop: number = Math.max(0, this.toValidNumber(scrollEl.scrollTop));
    if (itemsPerCol === 1 && Math.floor(scrollTop / this.scrollHeight * itemCount) + itemsPerRowByCalc >= itemCount) {
      itemsPerRow = itemsPerRowByCalc;
    }
    return {
      itemCount,
      viewWidth,
      viewHeight,
      childWidth,
      childHeight,
      itemsPerRow,
      itemsPerCol,
      itemsPerRowByCalc
    };
  }

  private calculateItems(): void {
    const scrollEl: any = this.scrollContainer;
    if (!scrollEl) {
      return null;
    }
    const contentEl: any = this.contentElementRef ? this.contentElementRef.nativeElement : null;
    const dimensions: any = this.calculateDimensions();
    const items: any[] = this.originalCollection || [];
    this.scrollHeight = this.toValidNumber(dimensions.childHeight * dimensions.itemCount / dimensions.itemsPerRow);
    if (contentEl && contentEl.getBoundingClientRect().height > this.scrollHeight) {
      this.scrollHeight = contentEl.getBoundingClientRect().height;
    }
    if (scrollEl.scrollTop > this.scrollHeight) {
      scrollEl.scrollTop = this.scrollHeight;
    }

    const scrollTop: number = Math.max(0, scrollEl.scrollTop);
    const indexByScrollTop: number = this.toValidNumber(scrollTop / this.scrollHeight * dimensions.itemCount / dimensions.itemsPerRow);

    this.endIndex = Math.min(dimensions.itemCount - 1, (Math.ceil(indexByScrollTop) * dimensions.itemsPerRow)
      + (dimensions.itemsPerRow * dimensions.itemsPerCol));
    let maxStartEnd: number = this.endIndex;
    const modEnd: number = this.endIndex % dimensions.itemsPerRow;
    if (modEnd) {
      maxStartEnd = this.endIndex + dimensions.itemsPerRow - modEnd;
    }
    const maxStart: number = Math.max(0, maxStartEnd - dimensions.itemsPerCol
      * dimensions.itemsPerRow - dimensions.itemsPerRow);
    this.startIndex = Math.min(maxStart, Math.floor(indexByScrollTop) * dimensions.itemsPerRow);
    this.startIndex = Math.max(0, this.toValidNumber(this.startIndex));
    this.startIndexWithoutBuffer = this.startIndex;
    this.startIndex = Math.max(0, this.startIndexWithoutBuffer - this.itemsToBuffer);

    this.endIndex = Math.min(dimensions.itemCount - 1, this.toValidNumber(this.endIndex));
    this.endIndexWithoutBuffer = this.endIndex;
    this.endIndex = Math.min(dimensions.itemCount - 1, this.endIndexWithoutBuffer + this.itemsToBuffer);

    this.contentYOffset = dimensions.childHeight * Math.ceil(this.startIndex / dimensions.itemsPerRow);

    let emitEvent = false;

    if (!_.isEqual(this.startIndex, this.previousStart) || !_.isEqual(this.endIndex, this.previousEnd)) {
      this.previousStart = this.startIndex;
      this.previousEnd = this.endIndex;
      if (this.startupLoop === true) {
        this.refresh();
      } else {
        emitEvent = true;
      }
    } else if (this.startupLoop === true) {
      this.startupLoop = false;
      emitEvent = true;
      this.refresh();
    }
    if (emitEvent === true) {
      this.viewPortItems = items.slice(this.startIndex, this.endIndex + 1);
      // emit the virtual collection
      this.virtualCollectionChange.emit(this.viewPortItems);
      this.virtualIndexChange.emit({ start: this.startIndex, end: this.endIndex });
      this.changeDetectorRef.markForCheck();
    }
  }

  private toValidNumber(value: any, fallbackNumber: number = 0): number {
    return _.isFinite(value) ? value : fallbackNumber;
  }
}
