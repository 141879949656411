import {
  Renderer2,
  Directive,
  ElementRef,
  Input,
} from '@angular/core';

@Directive({
  selector: '[ui-column]',
})
export class UiLayoutColumnDirective {

  private _className: string;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {}

  @Input('ui-column')
  set uiColumn(value: string) {
    this._className = 'col-md-' + value;
    this.renderer.addClass(this.elementRef, this._className);
  }

  get uiColumn(): string {
    return this._className;
  }
}
