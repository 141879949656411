
/**
 * Created by jsunseri on 4/30/2019.
 */
import { Injectable } from '@angular/core';
import { SegmentCapacityApi } from './physical/segment/capacity/capacity';
import { PortfolioSegmentLocationApi } from './physical/segment/location/location';
import { ForecastContractApi } from './forecast/forecast-contract/forecast-contract';
import { PortfolioSegmentApi } from './physical/segment/segment';
import { ForecastContractAmendmentSummaryApi } from './forecast/forecast-contract-amendment-summary/forecast-contract-amendment-summary';



@Injectable()
export class PortfolioManagementApi {
  constructor(
    public forecastContractApi: ForecastContractApi,
    public segmentCapacityApi: SegmentCapacityApi,
    public protfolioSegmentLocaitonApi: PortfolioSegmentLocationApi,
    public portfolioSegmentApi: PortfolioSegmentApi,
    public forecastContractAmendmentSummaryApi: ForecastContractAmendmentSummaryApi
  ) {}
}
