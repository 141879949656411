<ui-card headline="Announcements"
         [skipDisable]="true">
  <mat-list>
    <h3 *ngIf="announcements.length == 0" matSubheader>No Messages</h3>
    <h3 *ngIf="announcements.length > 0" matSubheader>Messages</h3>
    <ul> <li *ngFor="let item of announcements">
      {{item.message}}
    </li></ul>
    <mat-divider></mat-divider>
    <h3 matSubheader>No Informational Postings</h3>
    <mat-divider></mat-divider>
    <ul> <li *ngFor="let asset of itemData$ | async">
      {{asset.abbreviation}} {{asset.name }}
    </li></ul>
    <mat-divider></mat-divider>
    <h3 matSubheader>Emergency Contacts</h3>
    <mat-list-item>
      <div fxFlex="50" fxLayout="column">24hr Emergency:</div>
      <div fxFlex="50" fxLayout="column">{{layout.emergency?.phone}}</div>
    </mat-list-item>
    <mat-list-item>
      <div fxFlex="50" fxLayout="column">Call Before You Dig:</div>
      <div fxFlex="50" fxLayout="column">811(For National)</div>
    </mat-list-item>
    <mat-list-item>
      <div fxFlex="50" fxLayout="column">Media Contact:</div>
      <div fxFlex="50" fxLayout="column">{{layout.emergency?.media}}</div>
    </mat-list-item>

    <h3 matSubheader>Customer Service</h3>
    <mat-list-item>
      <div fxFlex="50" fxLayout="column">Phone:</div>
      <div fxFlex="50" fxLayout="column">{{layout.customerService?.phone}}</div>
    </mat-list-item>
    <mat-list-item>
      <div fxFlex="50" fxLayout="column">Toll Free:</div>
      <div fxFlex="50" fxLayout="column">{{layout.customerService?.tollFree}}</div>
    </mat-list-item>
    <mat-list-item>
      <div fxFlex="50" fxLayout="column">Direct Email:</div>
      <div fxFlex="50" fxLayout="column">
        <a mat-icon-button
           href="mailto:{{layout.customerService?.email}}">
          <mat-icon class="material-icons">email</mat-icon>
        </a>
      </div>
    </mat-list-item>

    <h3 matSubheader>Messenger Services</h3>
    <mat-list-item>
      <div fxFlex="50" fxLayout="column">Phone:</div>
      <div fxFlex="50" fxLayout="column">{{layout.messenger?.phone}}</div>
    </mat-list-item>
    <mat-list-item>
      <div fxFlex="50" fxLayout="column">Toll Free:</div>
      <div fxFlex="50" fxLayout="column">{{layout.messenger?.tollFree}}</div>
    </mat-list-item>
    <mat-list-item>
      <div fxFlex="50" fxLayout="column">Direct Email:</div>
      <div fxFlex="50" fxLayout="column">
        <a mat-icon-button
           href="mailto:{{layout.messenger?.email}}">
          <mat-icon class="material-icons">email</mat-icon>
        </a>
      </div>
    </mat-list-item>

    <h3 matSubheader>Nominations and Scheduling On-Call</h3>
    <mat-list-item>
      <div fxFlex="50" fxLayout="column">Hours:</div>
      <div fxFlex="50" fxLayout="column">{{layout.oncall?.hours}}</div>
    </mat-list-item>
    <mat-list-item>
      <div fxFlex="50" fxLayout="column">Phone:</div>
      <div fxFlex="50" fxLayout="column">{{layout.oncall?.phone}}</div>
    </mat-list-item>
    <mat-list-item>
      <div fxFlex="50" fxLayout="column">Direct Email:</div>
      <div fxFlex="50" fxLayout="column">
        <a mat-icon-button
           href="mailto:{{layout.oncall?.email}}">
          <mat-icon class="material-icons">email</mat-icon>
        </a>
      </div>
    </mat-list-item>

    
    <ng-container *ngIf="showUsefulLinks$|async">
      <h3 matSubheader>Useful Links</h3>
      <mat-list-item *ngFor="let documentData of documentDataList$ | async">
        <div>
          <a [matTooltip]="documentData?.description"
             [matTooltipPosition]="'right'"
             href="javascript:void(0);"
             (click)="openDocument(documentData)">
            {{documentData?.name}}
          </a>
        </div>
      </mat-list-item>
      <ng2-pdfjs-viewer #pdfViewer
                        [externalWindow]="true"
                        [openFile]="false"
                        [viewBookmark]="false">
      </ng2-pdfjs-viewer>
    </ng-container>
  </mat-list>
</ui-card>
