import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class BaCombineHistoryBackward {

  private readonly endpoint: string = 'associate/business-associate/';
  private readonly suffix: string = '/combineHist/backward';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public findAll = (baId: string | number): Observable<any> => {
    return this.apiHelper.request(this.endpoint + baId + this.suffix);
  }
}
