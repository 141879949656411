import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { IFilterAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams, RowNode, TextFilter } from '@ag-grid-community/core';
import { isNil, isEmpty, toUpper } from 'lodash';

@Component({
  selector: 'filter-cell',
  template: ``,
})
export class EtCodeFilter implements IFilterAngularComp {
  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;
  public model: any;

  @ViewChild('input', { read: ViewContainerRef }) public input: any;

  agInit(params: IFilterParams): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
  }

  isFilterActive(): boolean {
    return !isEmpty(this.model)
      && (this.model.filter || (this.model.condition1 && !isEmpty(this.model.condition1.filter)));
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    let value: any = this.valueGetter(params.node);
    let valueLowerCase = value ? value.toString().toLowerCase() : '';
    let filterOperator: string;
    let filterText1: string;
    let filterText2: string;
    let filterTextLowerCase1: string;
    let filterTextLowerCase2: string;
    let filterType1: string;
    let filterType2: string;

    if (this.model) {
      if (this.model.operator) {
        filterOperator = this.model.operator;
      }
      if (this.model.condition1 || this.model.condition2) {
        if (!isNil(this.model.condition1.filter)) {
          filterText1 = this.model.condition1.filter;
          filterTextLowerCase1 = filterText1 ? filterText1.toLowerCase() : '';
          filterType1 = this.model.condition1.type;
        }
        if (!isNil(this.model.condition2.filter)) {
          filterText2 = this.model.condition2.filter;
          filterTextLowerCase2 = filterText2 ? filterText2.toLowerCase() : '';
          filterType2 = this.model.condition2.type;
        }
      } else if (!isNil(this.model.filter)) {
        filterText1 = this.model.filter;
        filterTextLowerCase1 = filterText1 ? filterText1.toLowerCase() : '';
        filterType1 = this.model.type;
      }
    }
    if (filterOperator && filterTextLowerCase1 && filterTextLowerCase2) {
      let cond1: boolean = this.doesFilterConditionPass(filterType1, valueLowerCase, filterTextLowerCase1);
      let cond2: boolean = this.doesFilterConditionPass(filterType2, valueLowerCase, filterTextLowerCase2);

      return toUpper(filterOperator) === 'AND'
        ? (cond1 && cond2)
        : (cond1 || cond2);
    } else {
      return this.doesFilterConditionPass(filterType1, valueLowerCase, filterTextLowerCase1);
    }
  }

  getModel(): any {
    return this.model;
  }

  setModel(model: any): void {
    this.model = model ? model : '';
  }

  ngAfterViewInit(params: IAfterGuiAttachedParams): void {
    setTimeout(() => {
      if (this.input && this.input.element && this.input.element.nativeElement) {
        this.input.element.nativeElement.focus();
      }
    });
  }

  // noinspection JSMethodCanBeStatic
  componentMethod(message: string): void {
    alert(`Alert from PartialMatchFilterComponent ${message}`);
  }

  onChange(newValue: any): void {
    if (this.model.value !== newValue) {
      this.model.value = newValue;

      //this.params.filterChangedCallback();
    }
  }

  doesFilterConditionPass(filterType: string, value: string, filterValue: string): boolean {
    switch (filterType) {
      case TextFilter.CONTAINS: {
        return value.indexOf(filterValue) >= 0;
      }
      case TextFilter.NOT_CONTAINS: {
        return value.indexOf(filterValue) === -1;
      }
      case TextFilter.EQUALS: {
        return value === filterValue;
      }
      case TextFilter.NOT_EQUAL: {
        return value !== filterValue;
      }
      case TextFilter.STARTS_WITH: {
        return value.indexOf(filterValue) === 0;
      }
      case TextFilter.ENDS_WITH: {
        let index = value.lastIndexOf(filterValue);
        return index >= 0 && index === (value.length - filterValue.length);
      }
      default: {
        // should never happen
        console.warn('invalid filter type ' + filterValue);
        return false;
      }
    }
  }
}
