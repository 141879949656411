
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import * as _ from 'lodash';

@Injectable()
export class EmailTemplatesApi {

  public readonly endpoint: string = 'administration/table-maintenance/email-templates';

  constructor(
    private apiHelper: ApiHelper,
    private userModelService: UserModelService
  ) {}

  public getById(assetNbr: number | string, id: number | string): Observable<any> {
    let assetNumber: number | string = assetNbr;
    let emailTemplateId: number | string = id;

    if (_.isNil(assetNumber)) {
      assetNumber = this.userModelService.getCurrentAssetNbr();
    }
    if (_.isNil(assetNumber) || _.isNil(emailTemplateId)) {
      return observableOf({});
    }
    return this.apiHelper.request(this.endpoint + '/' + assetNumber + '/' + emailTemplateId);
  }

  public find(params: any): Observable<any> {
    let assetNumber: number | string;
    let emailTemplateId: number | string;

    if (params) {
      assetNumber = params.assetNbr;
      emailTemplateId = params.id;
    }
    return this.getById(assetNumber, emailTemplateId);
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/dashboard');
  }

  public save(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save', { body: params });
  }

  public deleteItem(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/delete', { body: params });
  }

  public exportEmailTemplate(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/export-email-template', { body: params });
  }
}
