export class NomBaseDatesUtil {

  protected ctrl: any;

  public setDefaultDates = (ctrl?: any): void => {
    let obj = this.ctrl ? this.ctrl : ctrl;
    if (obj.searchBeginDate === null || obj.searchEndDate === null) {
      _.assign(obj, {
        searchBeginDate: this.getDefaultStartDate(),
        searchEndDate: moment(this.getDefaultStartDate()).endOf('month').startOf('day').format(),
      });
    }
  }

  public getDefaultStartDate = (): string => {
    if (moment().hour() >= 13) {
      return moment().startOf('day').add(2, 'day').format();
    } else {
      return moment().startOf('day').add(1, 'day').format();
    }
  }

  public setRequestDates = (rowEntity: any, ctrl?: any): void => {
    let obj = this.ctrl ? this.ctrl : ctrl;
    _.assign(rowEntity, {
      searchBeginDate: moment(obj.searchBeginDate).startOf('day').format(),
      searchEndDate: moment(obj.searchEndDate).startOf('day').format(),
    });
  }

  public setStateParamDates = (ctrl?: any): void => {
    let obj = this.ctrl ? this.ctrl : ctrl;
    // _.assign(this.$stateParams, {
    //   searchBeginDate: obj.searchBeginDate,
    //   searchEndDate: obj.searchEndDate,
    // });
  }

}
