import { TableColumnBaseEditorComponent } from './../base/base-editor.component';
import { Component, ViewContainerRef, ViewChild, Injector } from '@angular/core';

@Component({
  moduleId: module.id + '',
  selector: 'table-column-default-editor',
  templateUrl: './default-editor.component.tpl.html'
})
export class TableColumnDefaultEditorComponent extends TableColumnBaseEditorComponent {
  @ViewChild('input', { read: ViewContainerRef }) public input: any;

  constructor(injector: Injector) {
    super(injector);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    // Do a setTimeout to allow time for the value to render
    setTimeout((): void => {
      this.input.element.nativeElement.select();
    });
  }
}
