import { Injectable } from '@angular/core';
import { ApiHelper } from '../../api-helper';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import {map} from 'rxjs/operators';
import ValidationResult = cad.ValidationResult;

@Injectable()
export class AllocationApi {

  private readonly endpoint: string = 'allocation';

  constructor(private apiHelper: ApiHelper) {}

  public getHeaderAndList = (params: any, type?: any): Observable<any> => {
    return this.apiHelper.request(`${this.endpoint}/header-and-list/${type || 'ALLOC'}`, { body: params });
  }

  public isPreviousAccountingPeriod = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/is-previous-accouting-period/', { body: params });
  }

  public getOperatorRankCutOffTime = (): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/operator-rank-cutoff-time');
  }

  public getHeader = (params: any, type?: any): Observable<any> => {
    return this.apiHelper.request(`${this.endpoint}/header/${type || 'ALLOC'}`, { body: params });
  }

  public allocate = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/allocate', { body: params });
  }

  public getDateRanges = (params: any, type?: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/date-ranges/' + type, { body: params });
  }

  public save = (params: any, type: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/save/' + type, { body: params });
  }

  /*changing from Post to Get*/
  public copy(pointCd: string, copyDate: string, fromDate: string, toDate: string): Observable<ValidationResult> {
    return this.apiHelper.request(
      `${this.endpoint}/copy/${pointCd}/${moment(copyDate).format('YYYY-MM-DD')}/${moment(fromDate).format('YYYY-MM-DD')}/${moment(toDate).format('YYYY-MM-DD')}`
    );
  }

  public getCurrentConfCycle(currentDate: string): Observable<string> {
    return this.apiHelper.request(this.endpoint + '/currentconfcycle', {body: `"${currentDate}"` })
      .pipe(map((r) => r.toString()));
  }

}
