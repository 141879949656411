import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { RightTemplateData } from 'src/cad/structured-products/shared/interfaces/right-template-data';

@Injectable()
export class RightTemplateLookupApi {

  public readonly endpoint: string = 'product/product-service';

  constructor(
    private apiHelper: ApiHelper
  ) {}
  
  public filter(): Observable<RightTemplateData[]> {
    return this.apiHelper.request(`${this.endpoint}/rightslookup`);
  }
}
