import { NgModule } from '@angular/core';
import { SharedModule } from 'cad/shared/shared.module';
import { LoginContentComponent } from 'cad/login/views/content/login-content.component';
import { LoginDialogComponent } from 'cad/login/dialog/login-dialog.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule.forChild([ { path: '', component: LoginContentComponent } ]),
  ],
  declarations: [
    LoginDialogComponent,
    LoginContentComponent
  ],
  entryComponents: [ LoginDialogComponent ]
})
export class LoginModule {}
