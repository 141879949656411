/**
 * @name uiMenu
 * @module common.components.uiMenu
 *
 * @description
 * `<ui-menu>`
 *
 * @param {object=} ui-menu
 * @param {string=} ng-class
 * @param {boolean=} sub
 */

import {
  Component, Input, OnInit, AfterViewInit, OnDestroy
} from '@angular/core';
import {ConfigService} from 'cad/core/services/config/config.service';

@Component({
  selector: 'ui-menu',
  templateUrl: './header-toolbar-menu.component.html',
  styleUrls: [ './header-toolbar-menu.component.less' ],
})
export class HeaderToolbarMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  private _menuData: any;
  @Input()
  set menuData(menuData: any) {
    if (this.configService.cadConfig.hideMenus) {
      for (let hideMenu of this.configService.cadConfig.hideMenus) {
        menuData.find((menuItem) => {
          if(menuItem.label === hideMenu) {
            menuItem.visible = false;
            menuItem.enabled = false;
          }
        });
      }
    }
    this._menuData = menuData;
  }

  get menuData(): any {
    return this._menuData;
  }

  public constructor( private configService: ConfigService,
  ) {}

  public ngOnInit(): void {

  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
  }

  public hasEnabledChildren(item: any): boolean {
    if (!item.children) {
      return false;
    }
    for (let child of item.children) {
      if (child.visible) {
        return true;
      }
    }
    return false;
  }
}
