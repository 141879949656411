import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ContractQuantity } from 'src/cad/contracts/shared/interfaces/contract-quantity';
import { ContractTransactionSearch } from 'src/cad/contracts/transactions/interfaces/contract-transaction-search';

@Injectable()
export class AgreementTransactionInputsApi {

  private readonly endpoint: string = 'agreement/transaction';

  constructor(public apiHelper: ApiHelper) {}
  
  public getContractQuantity(filterData: Partial<ContractTransactionSearch>): Observable<ContractQuantity> {
    return this.apiHelper.request(`${ this.endpoint }/contract-quantity`, { body: filterData });
  }

  public save = (data: any): Observable<any> => {
    return this.apiHelper.request(`${this.endpoint}/inputs`, { body: data });
  }

  public getInputs = (itemData: any): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/${ itemData.contractId }/${ itemData.contractTransactionId }/transaction-inputs`);
  }
}
