import { Directive, Renderer2, ElementRef, HostListener, forwardRef, Input, OnInit, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { isNumber } from 'util';

class BaseValueAccessor implements ControlValueAccessor {
  protected model: any;

  protected onChange: (_: any) => void;
  protected onTouched: () => void;

  constructor(protected _renderer: Renderer2, protected _elementRef: ElementRef) {}

  /**
   * Called by Angular when value is changed programmatically.
   */
  writeValue(value: any): void {
    const normalizedValue = (value === null || value === undefined) ? '' : value;
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', normalizedValue);
    this.model = normalizedValue;
  }

  registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }

  /**
   * Called below when value is changed from user input.
   */
  protected writeValueFromUser(value: any): void {
    const normalizedValue = (value === null || value === undefined) ? '' : value;
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', normalizedValue);
  }
}

class FormatterAndParser extends BaseValueAccessor {
  protected viewValuePipeline: ((val: any) => any)[] = [];
  protected modelValuePipeline: ((val: any) => any)[] = [];

  constructor(_renderer: Renderer2, _elementRef: ElementRef) {
    super(_renderer, _elementRef);
  }

  protected updateControl(value: any, inputType: string): void {
    let controlValue: any;
    switch (inputType) {
      case 'range':
      case 'number': {
        controlValue = (value === '') ? '' : parseFloat(value);
        break;
      }
      default: {
        controlValue = value;
        break;
      }
    }
    this.writeToView(controlValue);
    this.writeToModel(controlValue);
  }

  private writeToModel(value: any): void {
    if ((value || isNumber(value))  && value !== this.model) {
      this.model = value;
      this.onChange(this.applyPipeline(this.modelValuePipeline, value));
    } else if (value === '' && value !== this.model) {
      this.model = undefined;
      this.onChange(this.applyPipeline(this.modelValuePipeline, undefined));
    }
  }

  private writeToView(value: any): void {
    if ((value || isNumber(value))  && value !== this.model) {
      this.writeValueFromUser(this.applyPipeline(this.viewValuePipeline, value));
    }
  }

  private applyPipeline(pipeline: any = [], value: any): any {
    return pipeline.reduce((acc: any, fn: Function) => fn(acc), value);
  }
}

@Directive({
  selector: 'input[modelFormatter]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModelFormatterDirective),
      multi: true,
    },
  ],
})
export class ModelFormatterDirective extends FormatterAndParser implements OnInit, OnDestroy {
  @Input() updateModelOn: string = 'keyup';
  @HostListener('blur')
  public onHostBlur(): void {
    this.onTouched();
  }

  private stopListening: any;

  constructor(_renderer: Renderer2, _elementRef: ElementRef) {
    super(_renderer, _elementRef);
  }

  public addViewTransform(fn: any): void {
    this.viewValuePipeline.push(fn);
  }

  public addModelTransform(fn: any): void {
    this.modelValuePipeline.push(fn);
  }

  public clearViewTransforms(): void {
    this.viewValuePipeline.length = 0;
  }

  public clearModelTransforms(): void {
    this.modelValuePipeline.length = 0;
  }

  public ngOnInit(): void {
    this.setModelChangeEvent(this.updateModelOn);
  }

  public ngOnDestroy(): void {
    if (this.stopListening) {
      this.stopListening();
    }
  }

  public setModelChangeEvent(updateEvent: string): void {
    if (this.stopListening) {
      this.stopListening();
    }
    this.stopListening = this._renderer.listen(this._elementRef.nativeElement, updateEvent, (evt) => {
      this.updateControl(evt.target.value, evt.target.type);
    });
  }
}
