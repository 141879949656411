
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ItemApi } from 'src/features/common/item/item-api';

@Injectable()
export class BaseServiceTariffApi implements ItemApi {

  public readonly endpoint: string = 'product/base-service-tariff';

  constructor(
    private apiHelper: ApiHelper
  ) {}

  public find(params: any): Observable<any> {
    return observableOf(params);
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/filter`, { body: params });
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/recent`);
  }

  public save(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/save`, { body: params });
  }

  public deleteItem(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/delete`, { body: params });
  }

  public getById = ( id: number | string ): Observable<any> => {
    return this.apiHelper.request( this.endpoint + '/' + id ).pipe(map( ( point ) => {
      point.pointCd = point.code;
      return point;
    } ));
  }
  
  public settlementApproval(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/settlement-approval`, { body: params });
  }
  
  public regulatoryApproval(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/regulatory-approval`, { body: params });
  }
  
  public exceptionsApproval(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/exceptions-approval`, { body: params });
  }
  
  public rejectSelectedEntry(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/reject-selected-entry`, { body: params });
  }
  
  public rejectAll(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/reject-all`, { body: params });
  }
  
  public deleteSelectedEntry(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/delete-selected-entry`, { body: params });
  }
}
