import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators, AbstractControl } from '@angular/forms';
import { CustomValidationObject } from 'common/components/form/mat-form-field/custom-validation-object';

/**
 * @see https://github.com/yuyang041060120/ng2-validation for more validators
 */
@Directive({
  selector: '[uiNumberValidator]',
  providers: [ {provide: NG_VALIDATORS, useExisting: forwardRef(() => UiNumberValidatorDirective), multi: true} ],
})
export class UiNumberValidatorDirective implements Validator {
  validate(control: AbstractControl): CustomValidationObject {
    let requiredValidator: any = Validators.required(control);
    if (requiredValidator !== undefined && requiredValidator !== null) {
      return null;
    }
    return /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(control.value) ? null : {
      uiNumberValidator: {
        valid: false,
        message: 'Field is not a number',
      }
    };
  }
}
