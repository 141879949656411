<ui-modal-header>
    {{data.title}}
</ui-modal-header>
<ui-modal-body>
    {{data.body}}
</ui-modal-body>
<ui-modal-footer>
    <button mat-button mat-raised-button type="button" mat-dialog-close>Cancel</button>
    <button mat-button mat-raised-button color="primary" [mat-dialog-close]="true">Confirm</button>
</ui-modal-footer>
