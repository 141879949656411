/**
 * Constants and Enums used in the UiDatepicker Component {@see UiDatepickerComponent}
 */

export enum DatepickerDisplayModeEnum {
  MONTH = 'month',
  DATE = 'date',
  DATETIME = 'datetime',
  FULL = 'full',
  TIME = 'time'
}

export enum UpdateModelOnEnum {
  CHANGE = 'change',
  BLUR = 'blur',
  SUBMIT = 'submit'
}

export enum StartViewEnum {
  MONTH = 'month',
  YEAR = 'year',
  MULTI_YEAR = 'multi-year'
}
