import {
  Directive,
  Injector,
  Input,
  Output,
  ElementRef,
  ViewContainerRef,
  OnInit,
  AfterContentInit,
  OnChanges,
  OnDestroy,
  ContentChild,
  EventEmitter,
  SimpleChanges,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[uiExpandable]',
  exportAs: 'uiExpandable'
})
export class UiExpandableDirective implements OnInit, OnDestroy, AfterContentInit, OnChanges {

  @Input() public context: string = 'ui-content';
  @Input() public enableExpand: boolean = true;
  @Input() public isExpanded: boolean = true;
  @Input() public enableMax: boolean = false;
  @Input() public noButton: boolean = false;
  @Input() public maxHeight: number;
  @Input()
  public get isFullHeight(): boolean {
    return this._isFullHeight;
  }
  public set isFullHeight(val: boolean) {
    this._isFullHeight = val;
    Promise.resolve().then(() => {
      this.isFullHeightChange.emit(this._isFullHeight);
    });
  }
  @Output() public isFullHeightChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public isExpandedChange: EventEmitter<any> = new EventEmitter<any>();
  @ContentChild('headerSelector', { read: ViewContainerRef }) public headerSelector: ViewContainerRef;
  @ContentChild('contentSelector', { read: ViewContainerRef }) public contentSelector: ViewContainerRef;

  public viewportElement: any;
  public contentElement: any;
  public headerElement: any;
  public containerElement: any;
  public originalHeight: number;
  public originalContainerHeight: number;
  public containerHeight: number;
  public contentHeight: number;
  public isAnimating: boolean = false;
  private listener: Function;
  private _isFullHeight: boolean = false;

  constructor(
    private injector: Injector,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    this.contentElement = this.contentSelector.element.nativeElement;
    this.containerElement = this.elementRef.nativeElement;
    this.headerElement = this.headerSelector.element.nativeElement.parentElement;
    this.renderer.setStyle(this.contentElement, 'transition', 'height linear .3s, padding step-end .3s, opacity step-end .3s');
    this.findUiContentContainer();
    this.setHeaderClickable();

    setTimeout(() => {
      this.setOriginalHeights();
    });

    this.setHeights();

    if (!this.isExpanded) {
      this.collapse();
    }

    setTimeout(() => {
      if (this.isFullHeight) {
        this.expandFullHeight();
        // update the original heights
        this.setOriginalHeights();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.isExpanded
        && changes.isExpanded.firstChange === false
        && changes.isExpanded.currentValue !== changes.isExpanded.previousValue) {
        if (changes.isExpanded.currentValue) {
          this.expand();
        } else {
          this.collapse();
        }
      }
      if (changes.isFullHeight
        && changes.isFullHeight.firstChange === false
        && changes.isFullHeight.currentValue !== changes.isFullHeight.previousValue) {
        if (changes.isFullHeight.currentValue) {
          this.expandFullHeight();
        } else {
          this.collapseFullHeight();
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.listener) {
      this.listener();
      this.listener = undefined;
    }
    this.isFullHeightChange.complete();
    this.isFullHeightChange = null;
    this.isExpandedChange.complete();
    this.isExpandedChange = null;
    this.contentElement = null;
    this.headerElement = null;
    this.containerElement = null;
    this.viewportElement = null;
    this.elementRef = null;
    this.headerSelector = null;
    this.contentSelector = null;
    this.injector = undefined;
  }

  public setOriginalHeights(): void {
    if (this.contentElement) {
      this.originalHeight = this.contentElement.offsetHeight;
    }
    if (this.containerElement) {
      this.originalContainerHeight = this.containerElement.offsetHeight;
    }
  }

  public setHeaderClickable(): void {
    this.listener = this.renderer.listen(this.headerSelector.element.nativeElement, 'click', (event: Event) => {
      this.expandableToggle();
    });
  }

  public expandableToggle(): void {
    if(this.enableExpand) {
      this.isExpanded ? this.collapse() : this.expand();
    }
  }

  public toggleFullHeight(event?: Event): void {
    if (event) {
      event.stopPropagation();
    }
    this.isFullHeight ? this.collapseFullHeight() : this.expandFullHeight();
  }

  public setHeights(): void {
    if (this.viewportElement) {
      this.containerHeight = this.viewportElement.offsetHeight - this.calcOffset(this.containerElement);
      // TODO:  jbo come back and fix this.  this is for the footer.
      this.contentHeight = this.containerHeight - this.outerHeight(this.headerElement) - 0;
    }
  }

  /**
   * @description Ensures that viewportElement and heights are populated before evaluating sizes.
   * This ensures that it populates the above dependencies in case any of them is missed during ngAfterContentInit
   */
  public checkContainerDeps(): void {
    this.findUiContentContainer();
    this.setHeights();
  }

  private async expand (): Promise<any> {
    this.checkContainerDeps();
    /**
     * if its fullscreen, set it first to default,
     * then save those values as original values
     */
    if (this.isFullHeight) {
      await this.collapseFullHeight();
      this.setOriginalHeights();
    }
    this.isExpanded = true;
    this.isExpandedChange.emit(this.isExpanded);
    // we're coming from a collapsed state
    return this.setToOriginalHeight();
  }

  private async collapse (): Promise<TransitionEvent> {
    this.checkContainerDeps();
    if (this.isFullHeight) {
      /**
       * if its fullscreen, set it first to default,
       * then save those values as original values
       */
      await this.collapseFullHeight();
      this.setOriginalHeights();
    } else {
      // coming from expanded state
      this.setOriginalHeights();
    }
    this.isExpanded = false;
    this.isExpandedChange.emit(this.isExpanded);
    return this.setToMinHeight();
  }

  private async expandFullHeight (): Promise<TransitionEvent> {
    this.checkContainerDeps();
    if (!this.viewportElement) {
      return this.emptyPromise();
    }
    if (this.isExpanded) {
      // if were at original height, save
      this.setOriginalHeights();
    } else {
      // if not, go to original, then save
      await this.expand();
      this.setOriginalHeights();
    }
    this.isFullHeight = true;
    await this.setToOriginalHeight(false);
    return this.setToMaxHeight();
  }

  private async collapseFullHeight (): Promise<TransitionEvent> {
    this.checkContainerDeps();
    if (!this.viewportElement) {
      return this.emptyPromise();
    }
    this.isFullHeight = false;
    return this.setToOriginalHeight();
  }

  private async setToOriginalHeight(shouldNotify: boolean = true): Promise<TransitionEvent> {
    this.renderer.setStyle(this.contentElement, 'opacity', '1');
    this.renderer.setStyle(this.contentElement, 'height', this.originalHeight.toString() + 'px');
    this.renderer.setStyle(this.containerElement, 'height', this.originalContainerHeight.toString() + 'px');
    if (shouldNotify) {
      return this.transitionEndPromise();
    } else {
      return this.nextVmTurn();
    }
  }

  private async setToMaxHeight(): Promise<TransitionEvent> {
    this.renderer.setStyle(this.containerElement, 'height', this.containerHeight.toString() + 'px');
    this.renderer.setStyle(this.contentElement, 'height', this.contentHeight.toString() + 'px');
    let end = await this.transitionEndPromise();
    this.containerElement.scrollIntoView();
    return end;
  }

  private setToMinHeight(): Promise<TransitionEvent> {
    this.renderer.setStyle(this.containerElement, 'height', (this.headerElement.offsetHeight + this.contentElement.offsetHeight).toString() + 'px');
    this.renderer.setStyle(this.contentElement, 'opacity', '0');
    this.renderer.setStyle(this.containerElement, 'height', this.headerElement.offsetHeight.toString() + 'px' );
    return this.transitionEndPromise();
  }

  private transitionEndPromise(): Promise<TransitionEvent> {
    /**
     * this is a one time event listener that removes itself once completed
     */
    return new Promise<TransitionEvent>((res) => {
      this.contentElement.addEventListener('transitionend', (evt) => {
        res(evt);
      }, { once: true });
    });
  }

  private emptyPromise(): Promise<any> {
    return new Promise<any>((res) => res());
  }

  private pause(ms: number): Promise<any> {
    return new Promise<any>((res) => {
      setTimeout(() => res(), ms);
    });
  }

  private async nextVmTurn(): Promise<any> {
    return await this.pause(0);
  }

  private calcOffset(element: any): number {
    return (this.outerHeight(element) - element.offsetHeight); // + this.containerElement.parentElement.offsetTop;
  }

  private outerHeight(element: any): number {
    let height = element.offsetHeight;
    let style = getComputedStyle(element);

    height += parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
    return height;
  }

  private findUiContentContainer(element: Element = this.containerElement): void {
    if (!this.viewportElement && element) {
      //change the viewport to something I can pass in.  Should be matrix
      // matrix needs display:block
      if (element.classList.contains(this.context)) {
        this.viewportElement = element;
      } else {
        if (element.parentElement) {
          this.findUiContentContainer(element.parentElement);
        }
      }
    }
  }
}
