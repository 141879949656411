// Constant (and enum) definitions used throughout cad project

export enum DirtyStatus {
  NO_CHANGE = 0,
  NEW = 1,
  UPDATE = 2,
  DELETE = 3,
}

export const statusMap = {
  SUCCESS: [ 'done', 'status-green' ],
  WARNING: [ 'sync_problem', 'status-orange' ],
  FAILED: [ 'error', 'status-red' ],
  SCHEDULED: [ 'schedule', 'status-grey' ],
  'FAILED VALIDATION': [ 'error', 'status-red' ],
  CANCELLED: [ 'block', 'status-orange' ],
  EXECUTING: [ 'cached', 'status-blue rotateCounter' ],
  DUPLICATE: [ 'error', 'status-red' ],
  SHUTDOWN: [ 'error', 'status-red' ],
  PENDING: [ 'sync_problem', 'status-blue' ],
  'Executing asynchronous action': [ 'cached', 'status-blue' ],
  'STOPPED BECAUSE VALID CONNECTOR IS NOT FOUND.': [ 'error', 'status-red' ],
};

export const uiProtection = {
  PROTECTION_GROUP_ACTIONBUTTON: 'actionbutton',
  PROTECTION_GROUP_ACTIONBUTTON_CAMELCASE: 'actionButton',
  PROTECTION_GROUP_CUST_ACT: 'CustomerActivities',
  PROTECTION_GROUP_MENU: 'menu',
  PROTECTION_GROUP_SCREEN_FUNC: 'screenfunction',
  PROTECTION_GROUP_TAB: 'tab',
};

export const actionConstants = {
  ACTION_COPY: 'COPY',
  ACTION_CLEAR: 'CLEAR',
  ACTION_CANCEL: 'CANCEL',
  ACTION_DELETE: 'DELETE',
  ACTION_PRINT: 'PRINT',
  ACTION_SAVE: 'SAVE',
  ACTION_NEW: 'NEW',
  ACTION_RETRIEVE: 'RETRIEVE',
};

export const assets = {
  FEP: 40,
  TGR: 50,
  TW: 60,
  FGT: 62,
  GST: 70,
  SER: 271,
  PEPL: 301,
  TGC: 307,
  LCLNG: 322,
  SWG: 345,
  ROVER: 588,
  MRT: 2650,
  VectorUS: 9901,
  VectorCAN: 9902,
  GR: 2651,
};

export enum Asset  {
  FEP = 'FEP',
  TGR = 'TGR',
  TW = 'TW',
  FGT = 'FGT',
  GST = 'GST',
  SER = 'SER',
  PEPL = 'PEPL',
  TGC = 'TGC',
  LCLNG = 'LCLNG',
  SWG = 'SWG',
  ROVER = 'ROVER',
  MRT = 'MRT',
  VectorUS = 'VectorUS',
  VectorCAN = 'VectorCAN',
  GR = 'GR'
}

export enum AssetGroup {
  ET = 'ET',
  FG = 'FG',
  PE = 'PE',
  TP = 'TP',
  EN = 'EN'
}

export enum ApprovalStatusEnum {
  APPROVED = 'A',
  REJECTED = 'R',
  CONDITIONAL_APPROVED = 'C',
  NOT_YET_REVIEWED = 'N'
}

export enum ApprovalTypeEnum {
  CAPACITY_APPROVAL = 'SALE',
  OPTIMIZATION_APPROVAL = 'OPT',
  CREDIT_APPROVAL = 'CRD',
  EXTERNAL_EXECUTION = 'EXTE',
  INTERNAL_EXECUTION = 'INTE',
  ADMIN_LAST_APPROVAL = 'ADM2',
  LEGAL_APPROVAL = 'LEGAL',
  REGULATORY_APPROVAL = 'REGU',
  ADMIN_FIRST_APPROVAL = 'ADM1',
  CAPACITY_REVIEW = 'CTRV'
}

export enum ApprovalBusinessTypeEnum {
  CONTRACT = 'CT',
  BID = 'BID'
}

export const rbsResource = {
  VALUE_NOT_SET: 777
};

export const guiProtection = {
  USER_SECURITY_SAVE_ACTION: 'userSecurity.actionButton.save',
};

export const ACTIVE_ITEM = {
  CACHE_KEY: 'activeItemData'
};

export const WEEK_DAYS = {
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY'
};
