import { ModalRegistry } from './../../../features/common/modal/modal-registry.service';
import { ContractContactsCommonModule } from 'src/cad/common/components/contract-contacts-common/contract-contacts-common.module';
import { LocationContactsCommonComponent } from './../../common/components/location-contacts-common/location-contacts-common.component';
import { AllocationLocationApi } from '../../common/services/api/flowing-gas/allocation/allocation-location.service';
import { BaseValidatorService } from './../../common/services/validation/base-validator.service';
import { SubNominationsApi } from './../../common/services/api/nomination/sub-nominations/sub-nominations';
import { Injector, NgModule } from '@angular/core';
import { SharedModule } from 'src/cad/shared/shared.module';

// features
import { SecurityModule } from 'src/features/common/security/security.module';
import { SecurityAuthorizer } from 'src/features/common/security/security-authorizer';
import { BreadcrumbModule } from 'src/features/common/breadcrumb/breadcrumb.module';
import { RibbonModule } from 'src/features/common/ribbon/ribbon.module';

// external packages
import { CacheService } from 'ng2-cache';

// core
import { AlertsService } from 'cad/core/services/alerts/alerts.service';
import { ConfigService } from 'cad/core/services/config/config.service';
import { SecurityAuthorizerService } from 'cad/core/services/security/security-authorizer.service';
import { SecurityActionContextService } from 'cad/core/services/security/security-action-context.service';
import { EmitterService } from 'cad/core/services/emitter/emitter.service';
import { RouterService } from 'cad/core/services/router/router.service';
import { ConfirmNavigationService } from 'cad/core/services/confirm-navigation/confirm-navigation.service';
import { ConfirmNavigationModalComponent } from 'cad/core/services/confirm-navigation/confirm-navigation-modal/confirm-navigation-modal.component';
import { ModalRegistryRegistrationService } from './modal-registry/modal-registry-registration.service';
import { TableRegistryService } from 'src/ag-grid-wrapper/services/table-registry.service';

// common/services...
import { LayoutService } from 'common/services/layout/layout';
import { SidenavService } from 'common/services/sidenav/sidenav';
import { UiSmoothScrollService } from 'common/services/smooth-scroll/smooth-scroll.service';
import { PrintScreenService } from 'src/common/services/print-screen/print-screen.service';
import { UiFloatingContentService } from 'src/common/services/floating-content/floating-content.service';
import { UiDataPollingService } from 'src/common/services/data-polling/data-polling.service';
import { UiProcessStatusService } from 'src/common/services/process-status/process-status.service';
import { FeatureFlagsService } from 'src/common/services/feature-flags/feature-flags.service';

// Common Services
import { AuthService } from 'cad/common/services/auth/auth';
import { ActionService } from 'cad/common/services/security/action.service';
import { UserPreferenceService } from 'cad/common/services/preferences/user-preferences';

// Common Models
import { CodeType } from 'cad/common/models/codes/code-type';
import { CodesModel } from 'cad/common/models/codes/codes-model';
import { MenuModel } from 'cad/common/models/menu/menu-model';
import { SecurityModel } from 'cad/common/models/security/security-model';
import { StateModel } from 'cad/common/models/state/state-model';
import { DashboardModel } from 'src/cad/common/models/dashboard/dashboard-model';

// Save
import { CopyFormService } from 'cad/common/services/save/copy-form/copy-form';
import { DirtyFormService } from 'cad/common/services/save/dirty-form/dirty-form';
import { SaveFormService } from 'cad/common/services/save/save-form/save-form';

// Administration APIs
import { AdministrationApi } from 'cad/common/services/api/administration/administration';
import { CommunicationApi } from 'cad/common/services/api/administration/communication/communication';
import { MessagesApi } from 'cad/common/services/api/administration/messages/messages';
import { UsersApi } from 'cad/common/services/api/administration/users/users';
import { AdminUtilitiesApi } from 'cad/common/services/api/administration/admin-utilities/admin-utilities';
import { TableMaintenanceCodesApi } from 'cad/common/services/api/administration/table-maintenance/codes/codes';
import { AssetConfigParamApi } from 'cad/common/services/api/administration/table-maintenance/asset-config-param/asset-config-param';
import { TableMaintenanceLookupsApi } from 'cad/common/services/api/administration/table-maintenance/table-maintenance-lookups';
import { GroupManagementApi } from 'cad/common/services/api/administration/group-management/group-management';
import { ArchiveTableNameApi } from 'cad/common/services/api/administration/table-maintenance/archive-table-name/archive-table-name';
import { AssetParamApi } from 'cad/common/services/api/administration/table-maintenance/asset-param/asset-param';
import { DefineRecurPatternsApi } from 'cad/common/services/api/administration/table-maintenance/define-recur-patterns/define-recur-patterns';
import { EmailTemplatesApi } from 'cad/common/services/api/administration/table-maintenance/email-templates/email-templates';
import { EdiSecurityApi } from 'cad/common/services/api/administration/edi-security/edi-security';
import { RolesApi } from 'cad/common/services/api/administration/roles/roles';
import { DeadlineExtensionApi } from 'cad/common/services/api/administration/deadline-extension/deadline-extension';
import { CallLogApi } from 'cad/common/services/api/administration/call-log/call-log.api';
import { IssueSubtopicActionApi } from 'cad/common/services/api/administration/call-log/issue-subtopic-action/issue-subtopic-action.api';
import { CallLogLookupsApi } from 'cad/common/services/api/administration/call-log/call-log-lookups.api';
import { ContactMaintenanceApi } from 'cad/common/services/api/administration/call-log/contact-maintenance/contact-maintenance.api';
import { CustomerMaintenanceApi } from 'cad/common/services/api/administration/call-log/customer-maintenance/customer-maintenance.api';

// API Helpers
import { Api } from 'cad/common/services/api/api';
import { ApiCache } from 'cad/common/services/api/api-cache';
import { ApiHelper } from 'cad/common/services/api/api-helper';

// Allocation API
import { AllocationApi } from 'src/cad/common/services/api/flowing-gas/allocation/allocation.service';
import { AllocationTransferApi} from 'cad/common/services/api/allocation/transfer/transfer';

// Associate APIs
import { AddressApi } from 'cad/common/services/api/associate/address/address';
import { BusinessAssociate } from 'cad/common/services/api/associate/address/business-associate/business-associate';
import { Contact } from 'cad/common/services/api/associate/address/contact/contact';
import { AssociateApi } from 'cad/common/services/api/associate/associate';
import { BusinessAssociateAddressApi } from 'cad/common/services/api/associate/business-associate/address/address';
import { AssociateBusinessAssociateAssetApi } from 'cad/common/services/api/associate/business-associate/asset/asset';
import { BusinessAssociateAssetContactApi } from 'cad/common/services/api/associate/business-associate/asset/contact/contact';
import { BusinessAssociateApi } from 'cad/common/services/api/associate/business-associate/business-associate';
import { BaCombineHistoryBackward } from 'cad/common/services/api/associate/business-associate/combine-history-backward/combine-history';
import { BaCombineHistory } from 'cad/common/services/api/associate/business-associate/combine-history/combine-history';
import { BaContact } from 'cad/common/services/api/associate/business-associate/contact/ba-contact';
import { AssociateBusinessAssociateContactExternalApi } from 'cad/common/services/api/associate/business-associate/contact/external/associate-business-associate-contact-external.api';
import { AssociateBusinessAssociateContactInternalApi } from 'cad/common/services/api/associate/business-associate/contact/internal/associate-business-associate-contact-internal.api';
import { BaCredit } from 'cad/common/services/api/associate/business-associate/credit/credit';
import { DesigneeApi } from 'cad/common/services/api/associate/business-associate/designee/designee';
import { BaHistoryApi } from 'cad/common/services/api/associate/business-associate/history/history';
import { PrimaryContactApi } from 'cad/common/services/api/associate/business-associate/primary-contact/primary-contact';
import { BaRelatedBackward } from 'cad/common/services/api/associate/business-associate/related-backward/related';
import { BaRelated } from 'cad/common/services/api/associate/business-associate/related/related';
import { BaSecurityApi } from 'cad/common/services/api/associate/business-associate/security/security';
import { AssociateContactAddressApi } from 'cad/common/services/api/associate/contact/address/address';
import { AssociateContactAssetBaApi } from 'cad/common/services/api/associate/contact/asset-ba/asset-ba';
import { AssociateContactBusinessAssociateApi } from 'cad/common/services/api/associate/contact/business-associate/business-associate';
import { AssociateContactApi } from 'cad/common/services/api/associate/contact/contact';
import { AssociateContactNotificationApi } from 'cad/common/services/api/associate/contact/notification/notification';
import { AssociateContactProfileApi } from 'cad/common/services/api/associate/contact/profile/profile';
import { AssociateContactSecurityApi } from 'cad/common/services/api/associate/contact/security/security';
import { AssociateGlobalContactContactDetailsApi } from 'cad/common/services/api/associate/global-contact/contact-details/contact-details';
import { GlobalContactUpdateApi } from 'cad/common/services/api/associate/global-contact/global-contact';
import { ServiceRequesterApi } from 'cad/common/services/api/associate/service-requester/service-requester';

// Capacity Release APIs
import { CapacityReleaseOffersApi } from 'src/cad/common/services/api/capacity-release/offers/offers';
import { CapacityReleaseOfferRatesApi } from 'src/cad/common/services/api/capacity-release/offers/rates/rates';
import { CapacityReleaseOfferLocationsApi } from 'src/cad/common/services/api/capacity-release/offers/locations/locations';
import { CapacityReleaseOfferPreArrangedBidApi } from 'src/cad/common/services/api/capacity-release/offers/pre-arranged-bid/pre-arranged-bid';
import { CapacityReleaseOfferRankBidsApi } from 'src/cad/common/services/api/capacity-release/offers/rank-bids/rank-bids';
import { CapacityReleaseOfferIndexRateApi } from 'src/cad/common/services/api/capacity-release/offers/index-rate/index-rate';
import { CapacityReleaseOfferBidsApi } from 'src/cad/common/services/api/capacity-release/bids/bids';
import { CapacityReleaseOfferBidsLocationsApi } from 'src/cad/common/services/api/capacity-release/bids/locations/locations';
import { CapacityReleaseOfferBidsRatesApi } from 'src/cad/common/services/api/capacity-release/bids/rates/rates';
import { CapacityReleaseOfferBidsMatchBidApi } from 'src/cad/common/services/api/capacity-release/bids/match-bid/match-bid';
import { CapacityReleaseOfferAwardsApi } from 'src/cad/common/services/api/capacity-release/awards/awards';
import { CapacityReleaseOfferAwardsLocationsApi } from 'src/cad/common/services/api/capacity-release/awards/locations/locations';
import { CapacityReleaseOfferAwardsRatesApi } from 'src/cad/common/services/api/capacity-release/awards/rates/rates';
import { CapacityReleaseOfferAwardsRecallsApi } from 'src/cad/common/services/api/capacity-release/awards/recalls/recalls';
import { CapacityReleaseRecallReputsApi } from 'src/cad/common/services/api/capacity-release/recalls/reputs/reputs';
import { CapacityReleaseRecallsApi } from 'src/cad/common/services/api/capacity-release/recalls/recalls';
import { CapacityReleaseReputsApi } from 'src/cad/common/services/api/capacity-release/reputs/reputs';
import { CapacityReleaseStorageRelationshipApi } from 'src/cad/common/services/api/capacity-release/storage-relationship/storage-relationship';
import { CapacityReleaseApi } from '../../common/services/api/capacity-release/capacity-release';

// Codes API
import { CodesApi } from 'cad/common/services/api/codes/codes';

// Comment API
import { CommentApi } from 'cad/common/services/api/comment/comment';

// Invoicing API
import { InvoicingApi } from 'cad/common/services/api/invoicing/invoices/invoicing';
import { InvoicingLookupsApi } from 'cad/common/services/api/invoicing/invoicing-lookups';
import { ValuationComponentViewApi } from 'cad/common/services/api/invoicing/valuations/component-view/component-view';
import { ValuationDailyViewApi } from 'cad/common/services/api/invoicing/valuations/daily-view/daily-view';
import { ValuationMonthlyViewApi } from 'cad/common/services/api/invoicing/valuations/monthly-view/monthly-view';
import { ValuationApi } from 'cad/common/services/api/invoicing/valuations/valuation';
import { AdjustmentApi } from 'cad/common/services/api/invoicing/adjustment/adjustment';
import { IndexPricesApi } from 'cad/common/services/api/invoicing/index-prices/index-prices';
import { GlArUploadDataApi } from 'cad/common/services/api/invoicing/gl-ar-upload-data/gl-ar-upload-data';
import { ContractAllocationApi } from 'cad/common/services/api/invoicing/contract-allocation/contract-allocation.api';

// Nomindation APIs
import { NominationConfirmationApi } from 'cad/common/services/api/nomination/confirmation/confirmation';
import { NominationConfirmationLocationContactApi } from 'cad/common/services/api/nomination/confirmation/location/contact/contact';
import { NominationConfirmationLocationApi } from 'cad/common/services/api/nomination/confirmation/location/location';
import { NominationConfirmationPinnedLocationApi } from 'cad/common/services/api/nomination/confirmation/pinned-location/pinned-location';
import { NominationEdiConfirmationApi } from 'cad/common/services/api/nomination/edi-confirmation/edi-confirmation';
import { NominationEdiConfirmationPinnedLocationApi } from 'cad/common/services/api/nomination/edi-confirmation/pinned-location/pinned-location';
import { NominationApi } from 'cad/common/services/api/nomination/nomination';
import { NominationContactApi } from 'cad/common/services/api/nomination/nomination-contact/nomination-contact';
import { NominationHistoryApi } from 'cad/common/services/api/nomination/nomination-history/nomination-history';
import { NominationMatrixContractActivityCodeApi } from 'cad/common/services/api/nomination/nomination-matrix/contract/activity-code/activity-code';
import { NominationMatrixContractApi } from 'cad/common/services/api/nomination/nomination-matrix/contract/contract';
import { NominationMatrixContractPointApi } from 'cad/common/services/api/nomination/nomination-matrix/contract/point/nomination-matrix-contract-point.api';
import { NominationMatrixApi } from 'cad/common/services/api/nomination/nomination-matrix/nomination-matrix';
import { NominationMatrixPinnedContractApi } from 'cad/common/services/api/nomination/nomination-matrix/pinned-contract/pinned-contract';
import { PinnedItemApi } from 'cad/common/services/api/nomination/pinned-item/pinned-item';
import { NominationTransactionTypeApi } from 'cad/common/services/api/nomination/transaction-type/transaction-type';
import { ModalControllerFactoryService } from 'src/cad/core/services/modal-registry/modal-controller-factory.service';

// Agreement APIs
import { AgreementApi } from 'cad/common/services/api/agreement/agreement';
import { AgreementContractAssignmentApi } from 'cad/common/services/api/agreement/contract/assignment/assignment';
import { AgreementContractExternalContactsApi } from 'cad/common/services/api/agreement/contract/contacts/external-contacts/external-contacts';
import { AgreementContractInternalContactsApi } from 'cad/common/services/api/agreement/contract/contacts/internal-contacts/internal-contacts';
import { ContractAssignmentExternalContactsApi } from 'cad/common/services/api/agreement/contract/assignment/external-contacts/external-contacts';
import { ContractAssignmentInternalContactsApi } from 'cad/common/services/api/agreement/contract/assignment/internal-contacts/internal-contacts';
import { AgreementContractApi } from 'cad/common/services/api/agreement/contract/contract';
import { AgreementContractBackwardRelatedApi } from 'cad/common/services/api/agreement/contract/related/backward-related/backward-related';
import { AgreementContractForwardRelatedApi } from 'cad/common/services/api/agreement/contract/related/forward-related/forward-related';
import { AgreementContractRightsApi } from 'cad/common/services/api/agreement/contract/rights/rights';
import { AgreementContractPriceApi } from 'cad/common/services/api/agreement/contract/price/price';
import { AgreementContractServiceApi } from 'cad/common/services/api/agreement/contract/service/agreement-contract-service.api';
import { AgreementRateScheduleApi } from 'cad/common/services/api/agreement/rate-schedule/rate-schedule';
import { AgreementTransactionApi } from 'cad/common/services/api/agreement/transaction/transaction';
import { AgreementTransactionInputsApi } from 'cad/common/services/api/agreement/transaction/transaction-inputs';
import { AgreementPointLookupApi } from 'cad/common/services/api/agreement/contract/point/point';
import { AgreementTransactionLookupsApi } from 'cad/common/services/api/agreement/transaction/transaction-lookups';
import { AgreementAssociateToNominationsApi } from 'cad/common/services/api/agreement/transaction/associate-to-nominations/associate-to-nominations';
import { AgreementContractFootnotesApi } from 'cad/common/services/api/agreement/contract/footnotes/footnotes';
import { AgreementContractGlossaryApi } from 'cad/common/services/api/agreement/contract/glossary/glossary';
import { ContractSummaryApi } from 'src/cad/common/services/api/agreement/contract-summary/contract-summary';
import { ContractSummarySeasonalApi } from 'src/cad/common/services/api/agreement/contract-summary/contract-summary-seasonal';
import { AgreementCapacityReviewApi } from 'cad/common/services/api/agreement/capacity-review/capacity-review';
import { ContractLockApi } from 'src/cad/common/services/api/agreement/contract-lock/contract-lock.api';

// Physical APIs
import { PhysicalAssetApi } from 'cad/common/services/api/physical/asset/asset';
import { PhysicalLocationGroupApi } from 'cad/common/services/api/physical/location-group/location-group';
import { PhysicalLocationGroupLocationApi } from 'cad/common/services/api/physical/location-group/location/location-group-location';
import { PhysicalLocationAdjacentLocationsApi } from 'cad/common/services/api/physical/location/adjacent-locations/adjacent-locations';
import { PhysicalLocationContractsApi } from 'cad/common/services/api/physical/location/contracts/contracts';
import { PhysicalLocationExternalContactsApi } from 'cad/common/services/api/physical/location/external-contacts/external-contacts';
import { PhysicalLocationForecastMeterApi } from 'cad/common/services/api/physical/location/forecast-meters/forecast-meters';
import { PhysicalLocationForecastSegApi } from 'cad/common/services/api/physical/location/forecast-seg/forecast-seg';
import { PhysicalLocationForecastSegmentsApi } from 'cad/common/services/api/physical/location/forecast-segments/forecast-segments';
import { PhysicalLocationInternalContactsApi } from 'cad/common/services/api/physical/location/internal-contacts/internal-contacts';
import { PhysicalLocationApi } from 'cad/common/services/api/physical/location/location';
import { PhysicalLocationAssociatedGroupsApi } from 'cad/common/services/api/physical/location/location-location-groups/location-location-groups';
import { PhysicalLocationAssociatedMetersApi } from 'cad/common/services/api/physical/location/location-meters/location-meters';
import { PhysicalLocationPartyApi } from 'cad/common/services/api/physical/location/location-party/location-party';
import { PhysicalLocationOperatorChoiceApi } from 'cad/common/services/api/physical/location/location-operator-choice/location-operator-choice';
import { PhysicalLocationSurveyApi } from 'cad/common/services/api/physical/location/location-survey/location-survey';
import { PhysicalLocationMeterSchedulingSplitApi } from 'cad/common/services/api/physical/location/meter-scheduling-split/meter-scheduling-split';
import { PhysicalMeterApi } from 'cad/common/services/api/physical/meter/meter';
import { PhysicalCapacityApi } from 'cad/common/services/api/physical/capacity/capacity';
import { PhysicalMeterLocationApi } from 'cad/common/services/api/physical/meter/meter-locations/meter-locations';
import { PhysicalApi } from 'cad/common/services/api/physical/physical';
import { PhysicalSegmentCapacityApi } from 'cad/common/services/api/physical/segment/capacity/capacity';
import { PhysicalSegmentLocationApi } from 'cad/common/services/api/physical/segment/location/location';
import { PhysicalSegmentApi } from 'cad/common/services/api/physical/segment/segment';
import { PhysicalStatesApi } from 'cad/common/services/api/physical/states/states';
import { PhysicalOffSystemPtGroupApi } from 'src/cad/common/services/api/physical/off-system-point-group/off-system-point-group';

// Shared APIs
import { ApprovalsApi } from 'cad/common/services/api/shared/approvals/approvals';
import { DocumentsApi } from 'cad/common/services/api/shared/documents/documents';

// common/services/api/security
import { SecurityAuthApi } from 'cad/common/services/api/security/auth/auth';
import { SecurityLoginApi } from 'cad/common/services/api/security/login/login';
import { SecurityLogoutApi } from 'cad/common/services/api/security/logout/logout';
import { SecurityApi } from 'cad/common/services/api/security/security';
import { SecurityUserApi } from 'cad/common/services/api/security/user/user';

// common/services/security
import { ActionSecurityService } from 'src/cad/common/services/security/action-security.service';

// common/services/api/workflow
import { ScheduleAndViewReportApi } from 'cad/common/services/api/workflow/schedule-and-view-report/schedule-and-view-report';
import { ScheduleAndViewWorkflowApi } from 'cad/common/services/api/workflow/schedule-and-view-workflow/schedule-and-view-workflow';
import { WorkflowApi } from 'cad/common/services/api/workflow/workflow';
import { WorkflowBaseApi } from 'cad/common/services/api/workflow/workflow-base/workflow-base';
import { WorkflowConfigApi } from 'cad/common/services/api/workflow/workflow-config/workflow-config';
import { WorkflowFavService } from 'cad/common/services/workflow/workflow-fav-service';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import { MenuService } from 'src/cad/common/services/menu/menu-service';
import { UserModel } from 'src/cad/common/models/user/user-model';
import { ProfileService } from 'src/cad/common/models/user/profile-service';
import { DashboardService } from 'src/cad/common/services/dashboard/dashboard-service';

// Flowing Gas APIs
import { FlowingGasApi } from 'cad/common/services/api/flowing-gas/flowing-gas';
import { PostedImbalancesApi } from 'src/cad/common/services/api/flowing-gas/posted-imbalances/posted-imbalances';
import { AllocationPinnedLocationApi } from 'src/cad/common/services/api/flowing-gas/allocation/pinned-location/pinned-location';
import { ShipperImbalanceApi } from 'src/cad/common/services/api/flowing-gas/imbalance/shipper-imbalance/shipper-imbalance';
import { ApiExceptionHandlerService } from 'src/cad/common/services/api/api-exception-handler';
import { BusinessAssociateCombineApi } from 'src/cad/common/services/api/associate/business-associate-combine/business-associate-combine-api';
import { IdleSessionComponent } from 'src/cad/core/services/session/component/idle-session.component';
import { LoggedOutWarn } from 'cad/core/services/session/component/logged-out-warn';
import { CashoutAdjustmentsApi } from 'src/cad/common/services/api/flowing-gas/cashout/cashout-adjustments/cashout-adjustments.api';
import { AllocationExceptionApi } from 'src/cad/common/services/api/flowing-gas/allocation-exception/allocation-exception';
import { GLAccountsApi } from 'src/cad/common/services/api/invoicing/glaccounts/glaccounts';
import { ContractLocationQuantitiesApi } from 'src/cad/common/services/api/flowing-gas/imbalance/shipper-imbalance/contract-location-quantities';
import { DailyKBalApi } from 'src/cad/common/services/api/flowing-gas/imbalance/shipper-imbalance/daily-k-bal';
import { SchedulingChargeLocationViewApi } from 'src/cad/common/services/api/flowing-gas/scheduling-charge/location-view/location-view';
import { SchedulingChargeContractViewApi } from 'src/cad/common/services/api/flowing-gas/scheduling-charge/contract-view/contract-view';
import { SchedulingChargeWaiverApi } from 'src/cad/common/services/api/flowing-gas/scheduling-charge/scheduling-charge-waiver/scheduling-charge-waiver';
import { GDSImbalanceStorageSchemeApi } from './../../common/services/api/flowing-gas/scheduling/gds-imbalance-storage-scheme/gds-imbalance-storage-scheme';
import { GDSContractLookupApi } from 'src/cad/common/services/api/flowing-gas/scheduling/gds-contract-lookup/gds-contract-lookup';
import { StorageBalancesApi } from 'src/cad/common/services/api/flowing-gas/storage-balances/storage-balances';
import { GDSImbalanceApi } from 'src/cad/common/services/api/flowing-gas/scheduling/gds-imbalance/gds-imbalance';
import { MorningReportApi } from 'src/cad/common/services/api/flowing-gas/scheduling/morning-report/morning-report';
import { OBAContractLookupApi } from 'src/cad/common/services/api/flowing-gas/scheduling/oba-contract-lookup/oba-contract-lookup';
import { MorningReportOutageApi } from 'src/cad/common/services/api/flowing-gas/scheduling/morning-report/morning-report-outage';
import { MorningReportStorageApi } from 'src/cad/common/services/api/flowing-gas/scheduling/morning-report/morning-report-storage';
import { MorningReportShipScheduleApi } from 'src/cad/common/services/api/flowing-gas/scheduling/morning-report/morning-report-ship-schedule';
import { PointLookupApi } from 'src/cad/common/services/api/flowing-gas/scheduling/point-lookup/point-lookup';
import { SegmentLookupApi } from 'src/cad/common/services/api/flowing-gas/scheduling/segment-lookup/segment-lookup';
import { NominationConfirmationApprovalApi } from 'src/cad/common/services/api/flowing-gas/scheduling/nomination-confirmation-approval/nomination-confirmation-approval';
import { ViewPoolBalanceInfoApi } from 'src/cad/common/services/api/flowing-gas/scheduling/view-pool-balance-info/view-pool-balance-info.api';
import { AutomaticSchedulingApi } from 'src/cad/common/services/api/flowing-gas/scheduling/auto-scheduling/automatic-scheduling/automatic-scheduling';
import { AutoParkServiceApi } from 'src/cad/common/services/api/flowing-gas/auto-park-service/auto-park-service';
import { UpdateBalanceDateApi } from 'src/cad/common/services/api/flowing-gas/update-balance-date/update-balance-date';
import { MaintainParkingLimitsApi } from 'src/cad/common/services/api/flowing-gas/scheduling/maintain-parking-limits/maintain-parking-limits';
import { ForecastContractApi } from 'src/cad/common/services/api/portfolio-management/forecast/forecast-contract/forecast-contract';
import { ForecastAmendmentApi } from 'src/cad/common/services/api/portfolio-management/forecast/forecast-amendment/forecast-amendment.api';
import { SegmentCapacityApi } from 'src/cad/common/services/api/portfolio-management/physical/segment/capacity/capacity';
import { PortfolioSegmentLocationApi } from 'src/cad/common/services/api/portfolio-management/physical/segment/location/location';
import { PortfolioSegmentApi } from 'src/cad/common/services/api/portfolio-management/physical/segment/segment';
import { PortfolioManagementApi } from 'src/cad/common/services/api/portfolio-management/portfolio-management';
import { ForecastContractAmendmentSummaryApi } from 'src/cad/common/services/api/portfolio-management/forecast/forecast-contract-amendment-summary/forecast-contract-amendment-summary';
import { GroupPointLookupApi } from 'src/cad/common/services/api/flowing-gas/group-point/group-point';
import { ShipperImbalanceSummaryApi } from 'src/cad/common/services/api/flowing-gas/imbalance/shipper-imbalance-summary/shipper-imbalance-summary';
import { CashoutDetailsApi } from 'src/cad/common/services/api/flowing-gas/cashout/cashout-details/cashout-details.api';

//Structured Products
import { StructuredProductsApi } from 'src/cad/common/services/api/structured-products/structured-products';
import { ParametersApi } from 'src/cad/common/services/api/structured-products/parameters/parameters';
import { ComponentApi } from 'src/cad/common/services/api/structured-products/component/component';
import { ProductServiceApi } from 'cad/common/services/api/structured-products/product-service/product-service';
import { ProductServiceInputsApi } from 'cad/common/services/api/structured-products/product-service/product-service-inputs.api';
import { BaseServiceTariffApi } from 'cad/common/services/api/structured-products/base-service/base-service';
import { ZoneToZoneMatrixRateApi } from 'src/cad/common/services/api/upload/upload';
//InfoPost
import { InfoPostApi } from 'src/cad/common/services/api/infopost/infopost';
import { NoticesApi } from 'cad/common/services/api/infopost/notices/notices.api';
import { TransactionalPostingsApi } from 'src/cad/common/services/api/infopost/transactional-postings/transactional-postings';
import { TransactionalPostingsCommentApi } from 'src/cad/common/services/api/infopost/transactional-postings/transactional-postings-comment';

import { AssetService } from 'cad/common/store/user/current-context/asset';
import { UserCacheService } from 'cad/common/store/user/services/user-cache.service';
import { UserService2 } from 'cad/common/store/user/services/user.service';
import { UserStoreService } from 'cad/common/store/core/services/user-store.service';
import { AutomaticSchedulingParamsApi } from 'cad/common/services/api/flowing-gas/scheduling/auto-scheduling/automatic-scheduling-params/automatic-scheduling-params';
import { PortfolioValuationDailyViewApi } from 'cad/common/services/api/portfolio-management/forecast-valuation/valuation/daily-view/daily-view';
import { PortfolioValuationMonthlyViewApi } from 'cad/common/services/api/portfolio-management/forecast-valuation/valuation/montly-view/monthly-view';
import { PortfolioValuationApi } from 'cad/common/services/api/portfolio-management/forecast-valuation/valuation/valuation';
import { UnassignedUsersApi } from 'cad/common/services/api/associate/contact/security/unassigned-users';
import { FeatureFlagsApi } from 'cad/common/services/api/physical/asset/feature-flag';
import { FeatureFlagsCanLoadGuard } from 'src/common/guards/can-load/feature-flags-can-load.guard';
import { FeatureFlagsCanActivateGuard } from 'src/common/guards/can-activate/feature-flags-can-activate.guard';
import { ValidationPipelineFactoryService } from 'src/cad/common/services/validation/validation-pipeline-factory.service';
import { CycleProcessStatusApi } from 'cad/common/services/api/flowing-gas/scheduling/cycle-process-status/cycle-process-status-api';
import { RightPriceServiceApi } from 'cad/common/services/api/structured-products/right-price-service/right-price-service';
import { RightTemplateLookupApi } from 'cad/common/services/api/structured-products/lookup/right-template-lookup.api';
import { PriceTemplateLookupApi } from 'cad/common/services/api/structured-products/lookup/price-template-lookup.api';
import { StaticParameterLookupApi } from 'cad/common/services/api/structured-products/lookup/static-parameter-lookup.api';
import { RateComponentLookupApi } from 'cad/common/services/api/structured-products/lookup/rate-component-lookup.api';
import { VariableParameterLookupApi } from 'cad/common/services/api/structured-products/lookup/variable-parameter-lookup.api';
import { StaticParameterSearchApi } from 'cad/common/services/api/structured-products/lookup/static-parameter-search.api';
import { ParameterDomainLookupApi } from 'cad/common/services/api/structured-products/lookup/parameter-domain-lookup.api';
import { ComponentPointLookupApi } from 'cad/common/services/api/structured-products/lookup/component-point-lookup.api';
import { ContactsService } from './contacts/contacts.service';
import { LocationContactsModalComponent } from './contacts/location-contacts-modal/location-contacts-modal.component';
import { ReportBatchApi } from 'src/cad/common/services/api/report/report-batch';
import { ModalsService } from 'cad/core/services/modal/modals.service';
import { DeleteModalComponent } from 'cad/core/services/modal/delete-modal/delete-modal.component';
import { AllocateDailyApi } from 'cad/common/services/api/flowing-gas/allocate-daily/allocate-daily';
import { OperationalBusinessAssociate } from 'cad/common/services/api/lookup/operational-business-associate';
import { ActionStore } from 'cad/common/store/core/services/action-store.service';
import { ViewImbalanceApi } from 'src/cad/common/services/api/flowing-gas/view-imbalance/view-imbalance';
import { AlertDayMaintenanceApi } from 'src/cad/common/services/api/flowing-gas/alert-day-maintenance/alert-day-maintenance';
import { ImbalanceAdjustmentApi } from 'src/cad/common/services/api/flowing-gas/imbalance-adjustment/imbalance-adjustment';
import { InFieldTransferApi } from 'cad/common/services/api/allocation/transfer/in-field-transfer';
import { ProducerApi } from 'cad/common/services/api/nomination/nomination-matrix/producer/producer-api';
import { ContactInternalContactApi } from 'cad/common/services/api/associate/contact/internal-contact/internal-contact';
import { ContractLocationQtyPinnedContractApi } from 'src/cad/flowing-gas/imbalance/operator-producer/operator-k-loc-daily-qts/pinned-operator.api';
import { LocationImbalApi } from 'src/cad/common/services/api/flowing-gas/imbalance/oper-producer-imbal/location-imbal';
import { LocationImbalPinnedContractApi } from 'src/cad/flowing-gas/imbalance/operator-producer/location-imbal/pinned-operator.api';
import { ParkingServiceImbalanceApi } from 'cad/common/services/api/flowing-gas/imbalance/shipper-imbalance/parking-service-imbalance';
import { ShipImbalPinnedContractApi } from 'src/cad/flowing-gas/imbalance/ship-imbal/shipper-imbal/pinned-operator.api';
import { OBAImbalPinnedContractApi } from 'src/cad/flowing-gas/imbalance/ship-imbal/oba-imbal-history/oba-imbal-pinned-operator.api';
import { DailyKBalPinnedContractApi } from 'src/cad/flowing-gas/imbalance/ship-imbal/daily-k-bal/daily-k-bal-pinned-operator.api';
import { KLocDailyQtsPinnedContractApi } from 'src/cad/flowing-gas/imbalance/ship-imbal/k-loc-daily-qts/k-loc-daily-qts-pinned-operator.api';
import { WorkflowAssetAssociationApi } from 'cad/common/services/api/administration/table-maintenance/workflow-asset-association/workflow-asset-association-api.service';
import { CapacityReleaseIndexApi } from 'src/cad/common/services/api/administration/table-maintenance/cr-index/capacity-release-index-api.service';
import {
  EdiProcessScheduleApi
} from 'cad/common/services/api/administration/table-maintenance/edi-process-schedule/edi-process-schedule-api.service';
import {
  WorkflowApi as WorkflowLookupApi
} from 'cad/common/services/api/administration/table-maintenance/edi-process-schedule/workflow-api.service';
import {
  ConfirmationReportExecutionScheduleApi
} from 'cad/common/services/api/administration/table-maintenance/confirmation-report-execution-schedule/confirmation-report-execution-schedule-api.service';
import {OBAMonitoringApi} from 'cad/common/services/api/flowing-gas/oba-monitoring/oba-monitoring-api';
import {StorageMonitoringApi} from 'cad/common/services/api/flowing-gas/storage-monitoring/storage-monitoring';
import {
  PowerPlantBalancingConfigurationApi
} from 'cad/common/services/api/flowing-gas/power-plant-balancing-configuration/power-plant-balancing-configuration-api';
import {OptionPdaApi} from 'cad/common/services/api/flowing-gas/option-pda/option-pda-api';
import {
  ScheduledQuantityByCycleApi
} from 'cad/common/services/api/nomination/scheduled-quantity-by-cycle/scheduled-quantity-by-cycle-api';


const bootstrapServices = [
  // services to instantiate at bootstrap (these must be listed as providers in the app)
  FeatureFlagsService,
  MenuService,
  SecurityActionContextService,
  DashboardService,
];

@NgModule({
  imports: [
    SharedModule,
    BreadcrumbModule,
    RibbonModule,
    SecurityModule,
    ContractContactsCommonModule
  ],
  declarations: [
    LocationContactsCommonComponent,
    IdleSessionComponent,
    LoggedOutWarn,
    ConfirmNavigationModalComponent,
    LocationContactsModalComponent,
    DeleteModalComponent
  ],
  entryComponents: [
    IdleSessionComponent,
    LoggedOutWarn,
    ConfirmNavigationModalComponent,
    LocationContactsModalComponent,
    DeleteModalComponent
  ],
  providers: [
    // external packages
    CacheService,

    // global security feature authorizer service
    {provide: SecurityAuthorizer, useExisting: SecurityAuthorizerService},

    // core
    AlertsService,
    ConfigService,
    SecurityAuthorizerService,
    SecurityActionContextService,
    EmitterService,
    RouterService,
    ConfirmNavigationService,
    ModalRegistryRegistrationService,
    ModalControllerFactoryService,
    ModalRegistry,
    { provide: 'ModalControllers', useValue: [] },
    TableRegistryService,

    // common/services...
    LayoutService,
    SidenavService,
    UiSmoothScrollService,
    PrintScreenService,
    UiFloatingContentService,
    UiDataPollingService,
    UiProcessStatusService,
    FeatureFlagsService,

    // Common Services
    AuthService,
    ActionService,
    MenuService,
    ProfileService,
    UserPreferenceService,
    ContactsService,
    ModalsService,
    DashboardService,

    // Common Models
    CodesModel,
    CodeType,
    MenuModel,
    SecurityModel,
    StateModel,
    UserModel,
    UserModelService,
    DashboardModel,

    // Save
    CopyFormService,
    DirtyFormService,
    SaveFormService,

    // API Helpers
    ApiCache,
    ApiHelper,
    ApiExceptionHandlerService,
    Api,

    // Shared APIs
    ApprovalsApi,
    DocumentsApi,

    // Associate APIs
    AddressApi,
    AssociateApi,
    AssociateBusinessAssociateAssetApi,
    AssociateBusinessAssociateContactExternalApi,
    AssociateBusinessAssociateContactInternalApi,
    AssociateContactAddressApi,
    AssociateContactApi,
    AssociateContactAssetBaApi,
    AssociateContactBusinessAssociateApi,
    AssociateContactNotificationApi,
    AssociateContactProfileApi,
    AssociateContactSecurityApi,
    AssociateGlobalContactContactDetailsApi,
    BaCombineHistory,
    BaCombineHistoryBackward,
    BaContact,
    BaCredit,
    BaHistoryApi,
    BaRelated,
    BaRelatedBackward,
    BaSecurityApi,
    BusinessAssociate,
    BusinessAssociateAddressApi,
    BusinessAssociateApi,
    BusinessAssociateAssetContactApi,
    BusinessAssociateCombineApi,
    Contact,
    ContactInternalContactApi,
    DesigneeApi,
    GlobalContactUpdateApi,
    PrimaryContactApi,
    ServiceRequesterApi,
    UnassignedUsersApi,

    // Allocation APIs
    AllocationApi,
    AllocationTransferApi,
    AllocationPinnedLocationApi,
    AllocationLocationApi,
    InFieldTransferApi,

    // Capacity Release APIs
    CapacityReleaseApi,
    CapacityReleaseOffersApi,
    CapacityReleaseOfferRatesApi,
    CapacityReleaseOfferLocationsApi,
    CapacityReleaseOfferPreArrangedBidApi,
    CapacityReleaseOfferRankBidsApi,
    CapacityReleaseOfferIndexRateApi,
    CapacityReleaseOfferBidsApi,
    CapacityReleaseOfferBidsLocationsApi,
    CapacityReleaseOfferBidsRatesApi,
    CapacityReleaseOfferBidsMatchBidApi,
    CapacityReleaseOfferAwardsApi,
    CapacityReleaseOfferAwardsLocationsApi,
    CapacityReleaseOfferAwardsRatesApi,
    CapacityReleaseOfferAwardsRecallsApi,
    CapacityReleaseRecallReputsApi,
    CapacityReleaseRecallsApi,
    CapacityReleaseReputsApi,
    CapacityReleaseStorageRelationshipApi,

    // Codes APIs
    CodesApi,

    // Comments APIs
    CommentApi,

    // Invoicing APIs
    InvoicingApi,
    InvoicingLookupsApi,
    ValuationComponentViewApi,
    ValuationMonthlyViewApi,
    ValuationDailyViewApi,
    ValuationApi,
    GLAccountsApi,
    AdjustmentApi,
    IndexPricesApi,
    GlArUploadDataApi,
    ContractAllocationApi,

    // Nominations APIs
    NominationApi,
    NominationConfirmationApi,
    NominationConfirmationLocationApi,
    NominationConfirmationLocationContactApi,
    NominationConfirmationPinnedLocationApi,
    NominationContactApi,
    NominationEdiConfirmationApi,
    NominationEdiConfirmationPinnedLocationApi,
    NominationHistoryApi,
    NominationMatrixApi,
    NominationMatrixContractActivityCodeApi,
    NominationMatrixContractApi,
    NominationMatrixContractPointApi,
    NominationMatrixPinnedContractApi,
    PinnedItemApi,
    NominationTransactionTypeApi,
    SubNominationsApi,
    ProducerApi,
    ScheduledQuantityByCycleApi,

    // Agreement APIs
    AgreementApi,
    AgreementContractApi,
    AgreementRateScheduleApi,
    AgreementContractServiceApi,
    AgreementContractInternalContactsApi,
    AgreementContractExternalContactsApi,
    ContractAssignmentInternalContactsApi,
    ContractAssignmentExternalContactsApi,
    AgreementContractRightsApi,
    AgreementContractPriceApi,
    AgreementContractForwardRelatedApi,
    AgreementContractBackwardRelatedApi,
    AgreementContractAssignmentApi,
    AgreementTransactionApi,
    AgreementTransactionInputsApi,
    AgreementPointLookupApi,
    AgreementTransactionLookupsApi,
    AgreementAssociateToNominationsApi,
    AgreementContractFootnotesApi,
    AgreementContractGlossaryApi,
    ContractSummaryApi,
    ContractSummarySeasonalApi,
    AgreementCapacityReviewApi,
    ContractLockApi,

    // Physical APIs
    PhysicalStatesApi,
    PhysicalLocationApi,
    PhysicalLocationAdjacentLocationsApi,
    PhysicalLocationContractsApi,
    PhysicalLocationExternalContactsApi,
    PhysicalLocationForecastMeterApi,
    PhysicalLocationForecastSegApi,
    PhysicalLocationForecastSegmentsApi,
    PhysicalLocationInternalContactsApi,
    PhysicalLocationAssociatedGroupsApi,
    PhysicalLocationAssociatedMetersApi,
    PhysicalLocationPartyApi,
    PhysicalLocationSurveyApi,
    PhysicalLocationMeterSchedulingSplitApi,
    PhysicalLocationOperatorChoiceApi,
    PhysicalSegmentApi,
    PhysicalSegmentCapacityApi,
    PhysicalSegmentLocationApi,
    PhysicalLocationGroupApi,
    PhysicalLocationGroupLocationApi,
    PhysicalMeterApi,
    PhysicalCapacityApi,
    PhysicalMeterLocationApi,
    PhysicalAssetApi,
    PhysicalApi,
    PhysicalOffSystemPtGroupApi,
    FeatureFlagsApi,

    // Security APIs
    SecurityAuthApi,
    SecurityUserApi,
    SecurityLoginApi,
    SecurityLogoutApi,
    SecurityApi,

    // Security Services
    ActionSecurityService,

    // Workflow APIs
    ScheduleAndViewReportApi,
    ScheduleAndViewWorkflowApi,
    WorkflowBaseApi,
    WorkflowConfigApi,
    WorkflowApi,
    WorkflowFavService,

    // Flowing Gas APIs
    FlowingGasApi,
    LocationImbalApi,
    PostedImbalancesApi,
    ShipperImbalanceApi,
    ContractLocationQuantitiesApi,
    ParkingServiceImbalanceApi,
    CashoutAdjustmentsApi,
    CashoutDetailsApi,
    AllocationExceptionApi,
    DailyKBalApi,
    SchedulingChargeLocationViewApi,
    SchedulingChargeContractViewApi,
    SchedulingChargeWaiverApi,
    GDSImbalanceStorageSchemeApi,
    GDSContractLookupApi,
    StorageBalancesApi,
    GDSImbalanceApi,
    MorningReportApi,
    OBAContractLookupApi,
    MorningReportOutageApi,
    MorningReportStorageApi,
    MorningReportShipScheduleApi,
    PointLookupApi,
    SegmentLookupApi,
    NominationConfirmationApprovalApi,
    ViewPoolBalanceInfoApi,
    MaintainParkingLimitsApi,
    AutomaticSchedulingApi,
    AutomaticSchedulingParamsApi,
    UpdateBalanceDateApi,
    OBAMonitoringApi,
    AutoParkServiceApi,
    AutomaticSchedulingParamsApi,
    CycleProcessStatusApi,
    AllocateDailyApi,
    GroupPointLookupApi,
    ViewImbalanceApi,
    AlertDayMaintenanceApi,
    ImbalanceAdjustmentApi,
    ContractLocationQtyPinnedContractApi,
    LocationImbalPinnedContractApi,
    ShipImbalPinnedContractApi,
    DailyKBalPinnedContractApi,
    OBAImbalPinnedContractApi,
    KLocDailyQtsPinnedContractApi,
    ShipperImbalanceSummaryApi,
    PowerPlantBalancingConfigurationApi,
    StorageMonitoringApi,
    OptionPdaApi,
    // Lookup
    OperationalBusinessAssociate,

    // Reports
    ReportBatchApi,

    // Administration APIs
    AdministrationApi,
    CommunicationApi,
    MessagesApi,
    UsersApi,
    AdminUtilitiesApi,
    TableMaintenanceCodesApi,
    AssetConfigParamApi,
    TableMaintenanceLookupsApi,
    GroupManagementApi,
    ArchiveTableNameApi,
    AssetParamApi,
    DefineRecurPatternsApi,
    EmailTemplatesApi,
    EdiSecurityApi,
    RolesApi,
    DeadlineExtensionApi,
    WorkflowAssetAssociationApi,
    CapacityReleaseIndexApi,
    EdiProcessScheduleApi,
    ConfirmationReportExecutionScheduleApi,
    WorkflowLookupApi,
    CallLogApi,
    IssueSubtopicActionApi,
    CallLogLookupsApi,
    CustomerMaintenanceApi,
    ContactMaintenanceApi,

    //Portfolio Management
    PortfolioManagementApi,
    ForecastContractApi,
    ForecastAmendmentApi,
    SegmentCapacityApi,
    PortfolioSegmentLocationApi,
    PortfolioSegmentApi,
    ForecastContractAmendmentSummaryApi,
    PortfolioValuationDailyViewApi,
    PortfolioValuationMonthlyViewApi,
    PortfolioValuationApi,

    // Structured Products APIs
    StructuredProductsApi,
    ParametersApi,
    ProductServiceApi,
    ProductServiceInputsApi,
    BaseServiceTariffApi,
    RightPriceServiceApi,
    ComponentApi,
    RightTemplateLookupApi,
    PriceTemplateLookupApi,
    StaticParameterLookupApi,
    RateComponentLookupApi,
    VariableParameterLookupApi,
    StaticParameterSearchApi,
    ParameterDomainLookupApi,
    ComponentPointLookupApi,
    ZoneToZoneMatrixRateApi,

    //InfoPost
    InfoPostApi,
    NoticesApi,
    TransactionalPostingsApi,
    TransactionalPostingsCommentApi,

    AssetService,
    UserStoreService,
    UserCacheService,
    UserService2,
    ActionStore,

    // Guards
    FeatureFlagsCanLoadGuard,
    FeatureFlagsCanActivateGuard,

    //Validation
    BaseValidatorService,
    ValidationPipelineFactoryService
  ]
})
export class CoreServicesModule {
  constructor(private injector: Injector) {
    bootstrapServices.forEach((service) => injector.get(service));
  }
}
