import { Component, Inject, OnInit, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { TABLE_EDITOR_DATA } from 'src/ag-grid-wrapper';

@Component({
  selector: 'table-phone-editor',
  template: `
    <div *ngIf="!shouldUseText" class="editor">
      <input #input matInput [(ngModel)]="params.value" uiInputMask="phoneExtension" [uiInputMaskOptions]="{autoUnmask:true,removeMaskOnSubmit:true}" placeholder="">
    </div>
    <div *ngIf="shouldUseText" class="editor">
      <input #input matInput [(ngModel)]="params.value" placeholder="">
    </div>
  `,
  styleUrls: [ './phone-or-text-editor.component.less' ],
})
export class PhoneOrTextEditorComponent implements AfterViewInit {
  @ViewChild('input', { read: ViewContainerRef }) public input: any;
  public shouldUseText: boolean;
  constructor (@Inject(TABLE_EDITOR_DATA) public params: any) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.shouldUseText = this.params.node.data.profileTypeCd === 'PE'
        || this.params.node.data.profileTypeCd === 'SE'
        || this.params.node.data.profileTypeCd === 'IM';
    });
    // Do a setTimeout to allow time for the value to render
    setTimeout((): void => {
      if (this.input && this.input.element && this.input.element.nativeElement) {
        this.input.element.nativeElement.focus();
        this.input.element.nativeElement.select();
      }
    });
  }
}
