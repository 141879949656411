import { Injectable } from '@angular/core';
import {UserService2} from 'cad/common/store/user/services/user.service';
import {Availableasset, CurrentContext, RootObject} from 'cad/common/store/user/user-model';


/**
 * Created by jodell on 12/8/2016.
 */
@Injectable()
export class UserModelService {

  constructor(
    private userService2: UserService2,
  ) {}

  public getUser(): RootObject {
    return this.userService2.getCurrentContext();
  }

  public getCurrentContext(): CurrentContext {
    let root = this.getUser();
    return root? root.currentContext : null;
  }

  public isUserInternal(): string {
    let root = this.getUser();
    return root ? root.internal : null;
  }

  public getCurrentAssetNbr(): number  {
    let cc = this.getCurrentContext();
    return cc ? cc.assetNbr : null;
  }

  public getCurrentAssetAbbr(): string {
    let cc = this.getCurrentContext();
    return cc ? cc.assetAbbr : null;
  }
  
  public getCurrentAssetGroup(): string {
    return this.getCurrentContext() ? this.getCurrentContext().assetGroupCd : null;
  }

  public getAvailableAssets(): Availableasset[]  {
    let root = this.getUser();
    return root ? root.availableassets : null;
  }

  public onAssetChange = (asset: Availableasset): void => {
    //this.userService.onAssetChange(this.getUserModel(), asset);
  }
  public onBAChange = (ba: any): void => {
    //this.userService.onBAChange(this.getUser(), ba);
  }
  public userHasRole = (role: string) => {
    let user = this.getUser();
    return user.userRoles.filter((r) => r.name === role).length !== 0;
  }
  public userHasRoleId = (roleId: number) => {
    let user = this.getUser();
    return user.userRoles.filter((r) => r.id === roleId).length !== 0;
  }
  public userHasOneOrMoreRoles = (roles: string[]) => {
    return roles.some((role) => this.userHasRole(role));
  }
  public isLoggedOut(): boolean {
    let root = this.getUser();
    return root ? !root.loggedIn : true;
  }

  public getRunAsUser = () : string => {
    //console.error('implement me');
    //return this.getUserModel().getRunAsUser();
    return null;
  }

}
