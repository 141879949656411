import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Controller } from 'src/framing/controller';

import { SidenavModel } from './sidenav.model';

@Component({
  selector: 'controller',
  template: '<ui-sidenav-view></ui-sidenav-view>',
})
export class SidenavController extends Controller<SidenavModel, void> {
  constructor(injector: Injector) { super(injector); }
}
