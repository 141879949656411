
import {map} from 'rxjs/operators';
import { ModalOpenOptions } from './modal-feature-types';
import { Injectable, OnDestroy } from '@angular/core';

import { ModalController } from './modal.controller';
import { Observable ,  ReplaySubject } from 'rxjs';

export class ModalCtrlDelegator {
  
  public ctrl: ModalController;
  
  constructor(private ctrl$: Observable<ModalController>) {
    let sub = this.ctrl$.subscribe((c) => {
      this.ctrl = c;
      sub.unsubscribe();
    });
  }

  open(cfg?: ModalOpenOptions): Observable<any> {
    if (this.ctrl) {
      return this.ctrl.open(cfg);
    } else {
      return this.ctrl$.pipe(map((c) => {
        return c.open(cfg);
      }));
    }
  }
}

@Injectable()
export class ModalRegistry implements OnDestroy {
  public ctrls: { [key: string]: ModalCtrlDelegator } = {};
  private ctrls$: { [key: string]: ReplaySubject<ModalController> } = {};

  registerModal(name: string): void {
    this.createCtrlDelegate(name);
  }

  emitModalCtrl(name: string, ctrl: ModalController): void {
    this.ctrls$[name].next(ctrl);
  }

  get(name: string): ModalCtrlDelegator {
    this.createCtrlDelegate(name);
    return this.ctrls[name];
  }

  destroy(name: string): void {
    this.ctrls[name] = null;
    this.ctrls$[name] = null;
  }

  ngOnDestroy(): void {
    this.ctrls = null;
  }

  private createCtrlDelegate(name: string): void {
    if (!this.ctrls$[name]) {
      let subject = new ReplaySubject<ModalController>();
      this.ctrls$[name] = subject;
      this.ctrls[name] = new ModalCtrlDelegator(subject.asObservable());
    }
  }
}
