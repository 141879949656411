import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirm-action',
  templateUrl: './confirm-action.component.html',
})
export class RowConfirmActionComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<RowConfirmActionComponent>) {}

  confirm (): void {
    this.dialogRef.close(true);
  }

  cancel (): void {
    this.dialogRef.close(false);
  }
}
