import { Injectable } from '@angular/core';
import { ObservableStore } from 'cad/common/services/store';
import {ActionStateStore, ProcessState, ValidState} from 'cad/common/store/core/action-store-state';




@Injectable()
export class ActionStore extends ObservableStore<ActionStateStore> {
  constructor() {
    super({ logStateChanges: false, includeStateChangesOnSubscribe: true, trackStateHistory: false });
    const initialState = ActionStore.getInitialState();
    this.setState(initialState, 'init_state');
  }

  private static getInitialState(): ActionStateStore {
    const processState =  {
      isActionRunning: false,
      startAction: false,
      stopAction: false,
    };
    const validState = {
      invalid: false,
      invalidForm: false,
    };
    const searchState = {
      changing: false,
    };
    return { processState, validState, searchState };
  }

  private static runningState(): ProcessState {
    return {
      isActionRunning: true,
      startAction: true,
      stopAction: false,
    };
  }

  private static stopRunningState(): ProcessState {
    return {
      isActionRunning: false,
      startAction: false,
      stopAction: true,
    };
  }

  public startAction(): void {
    this.setState({... this.getState(), processState: ActionStore.runningState() }, 'ACTION_STARTED', true);
  }
  public stopAction(): void {
    this.setState({... this.getState(), processState: ActionStore.stopRunningState() }, 'ACTION_STOPPED', true);
  }

  public validState(valid: boolean): void {
    let validState = {... this.getState().validState, invalid: !valid};
    this.setState({... this.getState(),validState}, 'VALID_STATE', true);
  }

  public validFormState(valid: boolean): void {
    let validState = {... this.getState().validState, invalidForm: !valid};
    this.setState({... this.getState(), validState}, 'VALID_STATE', true);
  }

  public searchStateChange(changeStarted: boolean): void {
    let searchState = {... this.getState().searchState, changing: changeStarted};
    this.setState({... this.getState(), searchState}, 'SEARCH_STATE', true);

  }

}
