import { Directive, Input, forwardRef, Injector, OnInit, OnDestroy } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators, AbstractControl, NgModel } from '@angular/forms';
import {
  CustomValidationObject,
  CustomValidationState
} from 'common/components/form/mat-form-field/custom-validation-object';
import * as _ from 'lodash';

export interface CustomValidatorConfig {
  validateFn(control: NgModel | AbstractControl): boolean;
  name?: string;
  message?: string;
}

@Directive({
  selector: '[uiCustomValidator][ngModel]',
  providers: [ {provide: NG_VALIDATORS, useExisting: forwardRef(() => UiCustomValidatorDirective), multi: true} ]
})
export class UiCustomValidatorDirective implements OnInit, OnDestroy, Validator {

  @Input('uiCustomValidator') public config: CustomValidatorConfig | CustomValidatorConfig[];
  public ngModelRef: NgModel;
  private key: string = 'uiCustomValidator';

  constructor(public injector: Injector) {}

  ngOnInit(): void {
    this.ngModelRef = this.injector.get(NgModel);
  }

  ngOnDestroy(): void {
    this.ngModelRef = null;
  }

  validate(control: AbstractControl): CustomValidationObject {
    let validationObject: CustomValidationObject = {};
    let validatorName: string;
    let requiredValidator: any = Validators.required(control);

    if (requiredValidator !== undefined && requiredValidator !== null) {
      return null;
    }
    if (_.isArray(this.config)) {
      _.forEach(this.config, (item: CustomValidatorConfig, itemIndex: number) => {
        let validationStateObj: CustomValidationState =
          this.getValidationObjectState(this.ngModelRef || control, item);

        if (validationStateObj) {
          validatorName = item.name || this.key;
          validationObject[ validatorName + itemIndex ] = validationStateObj;
        }
      });
    } else if (_.isObject(this.config)) {
      let validationStateObj: CustomValidationState =
        this.getValidationObjectState(this.ngModelRef || control, this.config);

      if (validationStateObj) {
        validatorName = this.config.name || this.key;
        validationObject[ validatorName ] = validationStateObj;
      }
    }

    return _.isEmpty(validationObject) ? null : validationObject;
  }

  private getValidationObjectState(
    control: NgModel | AbstractControl,
    validatorConfig: CustomValidatorConfig
  ): CustomValidationState {
    let validationState: CustomValidationState = null;

    if (control && !_.isNil(validatorConfig)) {
      let isValid: boolean = validatorConfig.validateFn(control);

      validationState = isValid ? null : {
        valid: isValid,
        message: validatorConfig.message
      };
    }

    return validationState;
  }
}
