import { ViewEncapsulation, Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';

@Component({
  moduleId: module.id + '',
  selector: 'row-navigation',
  templateUrl: 'row-navigation.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RowNavigationComponent implements AgRendererComponent {
  public menuItems: any;
  public menuName: string = 'Manage';

  params: any;

  agInit(params: any): void {
    this.params = params;
    this.menuItems = params.colDef.data;
    this.setTitle();
  }

  public onClick = (method: any): void => {
    method(this.params.node.data);
  }

  public isDisabled = (method: any): boolean => {
    if (method) {
      return method(this.params.node.data);
    }
    return false;
  }

  private setTitle = () => {
    const firstSection = this.menuItems.sections[0];
    if (firstSection) {
      this.menuName = this.menuName + ' ' + firstSection.name;
    }
  }

  refresh (params: any): boolean {
    this.params = params;
    return true;
  }
}
