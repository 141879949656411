<ui-modal-header (onClose)="dialogRef.close(true)">Session Timeout</ui-modal-header>

<ui-modal-body>
  <div fxLayout="row" fxLayout.sm="column" class="col-padding">
    The session will time out.  Click 'Keep Alive', if you wish to keep your session active.
  </div>
  <br>
  <div fxLayout="row" fxLayoutAlign="center center" class="col-padding">
    Countdown {{minutesStr}}:{{secondsStr}}
  </div>
</ui-modal-body>

<ui-modal-footer>
  <button mat-raised-button type="button" (click)="logout()">Logout</button>
  <button mat-raised-button type="button" (click)="onNoClick()"  color="primary">Keep Alive</button>
</ui-modal-footer>
