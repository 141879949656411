<div *ngIf="userDetails$|async;let userDetails" fxLayout="column"
     fxLayout.gt-sm="row">
  <div fxFlex="auto"
       fxFlex.gt-sm="30">
    <announcements-dash [userDetails]="userDetails"></announcements-dash>
  </div>
  <div fxFlex="auto"
       fxFlex.gt-sm="70">
    <div fxLayout="row wrap">
        <div fxFlex="50" fxLayout="column"  *ngFor="let dash of (userPrefs$|async)?.dashboards">
          <report-dash *ngIf="dash.name === 'workflow' && hasAuth(dash.name)|async" [vertical]=VerticalEnum.WORKFLOW [userDetails]="userDetails"></report-dash>
          <report-dash *ngIf="dash.name === 'report' && hasAuth(dash.name)|async" [vertical]=VerticalEnum.REPORTING [userDetails]="userDetails"></report-dash>
          <report-fav-dash *ngIf="dash.name === 'report-fav' && hasAuth(dash.name)|async" [vertical]=VerticalEnum.REPORTING [userDetails]="userDetails"></report-fav-dash>
          <ba-dash *ngIf="dash.name === 'ba' && hasAuth(dash.name)|async" [userDetails]="userDetails"></ba-dash>
          <contact-dash *ngIf="dash.name === 'contact' && hasAuth(dash.name)|async" [userDetails]="userDetails"></contact-dash>
          <address-dash *ngIf="dash.name === 'address' && hasAuth(dash.name)|async" [userDetails]="userDetails"></address-dash>
          <nom-dash *ngIf="dash.name === 'nom' && hasAuth(dash.name)|async" [userDetails]="userDetails"></nom-dash>
          <contract-dash *ngIf="dash.name === 'contract' && hasAuth(dash.name)|async" [userDetails]="userDetails"></contract-dash>
          <caprel-dash *ngIf="dash.name === 'caprel' && hasAuth(dash.name)|async" [userDetails]="userDetails"></caprel-dash>
          <offer-review-dashboard *ngIf="dash.name === 'offerreview' && hasAuth(dash.name)|async" [userDetails]="userDetails"></offer-review-dashboard>
        </div>
    </div>
    <div fxLayout="row wrap" *ngIf="showEditDash$|async">
      <ui-button class="borderless" (click)="addDashboard()">
        <ui-md-icon class="big_button" mdIconName="add_box" tooltip="Add dashboards"></ui-md-icon>
      </ui-button>
      <ui-button class="borderless" (click)="exitEditMode()">
        <ui-md-icon class="big_button" mdIconName="done" tooltip="Done editing dashboard"></ui-md-icon>
      </ui-button>
    </div>
  </div>
</div>
