import { Component, EventEmitter, Injector, Output } from '@angular/core';
import { TableColumnBaseEditorComponent } from './../base/base-editor.component';

@Component({
  moduleId: module.id + '',
  selector: 'table-column-select-dropdown-editor',
  templateUrl: './select-dropdown-editor.component.tpl.html'
})
export class TableColumnSelectDropdownEditorComponent extends TableColumnBaseEditorComponent {

  public editDropdownValueId: string;
  public editDropdownValueLabel: string;
  public editDropdownOptionsArray: any[];
  @Output() public selectClosedEvent: EventEmitter<any> = new EventEmitter<any>();


  constructor(injector: Injector) {
    super(injector);
  }

  agInit(params: any): void {
    super.agInit(params);
    this.editDropdownValueId = this.params.column.colDef.editDropdownValueId;
    this.editDropdownValueLabel = this.params.column.colDef.editDropdownValueLabel;
    this.editDropdownOptionsArray = this.params.column.colDef.editDropdownOptionsArray;
  }

  onSelectClose(): void {
    this.params.api.stopEditing();
  }
}
