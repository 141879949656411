import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { RunAsUserDialogComponent } from 'src/cad/login/dialog/run-as-user.component';
import { RouterService } from 'src/cad/core/services/router/router.service';

@Component({
  template: '',
})
export class RunAsUserContentComponent implements OnDestroy, OnInit {

  private dialogRef: MatDialogRef<RunAsUserDialogComponent>;
  private subscription: Subscription;

  constructor(
    private router: RouterService,
    private dialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    setTimeout(() => {
      this.openDialog();
    }); // delay by one cycle to prevent ExpressionChangedAfterItHasBeenCheckedError error
  }

  public ngOnDestroy(): void {
    if (this.subscription) { this.subscription.unsubscribe(); }
    if (this.dialogRef) { this.dialogRef.close(); }
  }

  private openDialog(): void {
    this.dialogRef = this.dialog.open(RunAsUserDialogComponent, { disableClose: true, minWidth: 600 });
  }
}
