import { ApiHelper } from 'cad/common/services/api/api-helper';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ViewImbalanceApi {
  public readonly endpoint: string = 'flowing-gas/view-imbalance';

  constructor(private apiHelper: ApiHelper) {}

  public filter (data: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/filter`, { body: data });
  }
}
