
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiHelper } from 'src/cad/common/services/api/api-helper';
import { CapacityReleaseOfferAwardsApi } from 'src/cad/common/services/api/capacity-release/awards/awards';
import { CRRecallData } from 'src/cad/capacity-release/shared/interfaces/cr-recall-data';
import { CRRecallLocationData } from 'src/cad/capacity-release/shared/interfaces/cr-recall-location-data';
import { CRRecallAvailQtyData } from 'src/cad/capacity-release/shared/interfaces/cr-recall-avail-qty-data';
import ValidationResult = cad.ValidationResult;
import * as _ from 'lodash';

@Injectable()
export class CapacityReleaseRecallsApi {
  private readonly endpoint: string = 'capacity-release/recalls';

  constructor(
    public capacityReleaseOfferAwardsApi: CapacityReleaseOfferAwardsApi,
    private apiHelper: ApiHelper
  ) {}

  public getById(recallId: number | string): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/' + recallId);
  }

  public find = (params: any): Observable<any> => {
    return this.getById(params.recallID);
  }

  public recent(recallData: any = {}): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/dashboard', { body: recallData });
  }

  public filter(params: any): Observable<any> {
    let returnVal: Observable<any>;

    if (params && _.has(params, 'populator')) {
      let paramsObj: any = _.omit(params, 'populator');
      let lookupActionName: string = _.get(params, 'populator');

      switch (lookupActionName) {
        case 'recallableAwardLookup': {
          returnVal = this.getRecallableAwardData(paramsObj);
          break;
        }
        case 'serviceReqLookup': {
          returnVal = this.getServiceRequesterData(paramsObj);
          break;
        }
        default: {
          break;
        }
      }
    } else {
      returnVal = this.apiHelper.request(this.endpoint + '/filter', { body: params });
    }
    return !_.isNil(returnVal) ? returnVal : observableOf([]);
  }

  public save(recallData: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save', { body: recallData });
  }

  public deleteItem(recallData: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/delete', { body: recallData });
  }

  public getRecallLocations(recallData: any): Observable<CRRecallLocationData[]> {
    if (recallData
      && !_.isNil(recallData.recallID)) {
      return this.apiHelper.request(this.endpoint + '/' + recallData.recallID + '/locations');
    }
    return observableOf([]);
  }

  public saveRecallLocations(recallData: any, recallLocationData: CRRecallLocationData[]): Observable<ValidationResult> {
    if (recallData
      && recallLocationData
      && !_.isNil(recallData.recallID)) {
      return this.apiHelper.request(
        this.endpoint + '/' + recallData.recallID + '/locations/save',
        { body: recallLocationData }
      );
    }
    return observableOf({ successful: false });
  }

  public getRecallableAwardData(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/recallable-award-lookup', { body: params });
  }

  public getServiceRequesterData(params: any): Observable<any> {
    if (this.capacityReleaseOfferAwardsApi) {
      return this.capacityReleaseOfferAwardsApi.getAwardServiceReqData(params);
    }
    return observableOf([]);
  }

  public getRecallReputStatusData(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/reput/status', { body: params });
  }

  public getRecallableVolumesForAward(crRecallData: CRRecallData): Observable<CRRecallAvailQtyData[]> {
    return this.apiHelper.request(this.endpoint + '/recallable-volumes-for-award', { body: crRecallData })
    .pipe(
      map((dataList: CRRecallAvailQtyData[]) => dataList.map((data: CRRecallAvailQtyData) => {
        data.flowDirectionInd = data.flowDirInd;
        data.assocGroupCd = data.assocGroup;
        data.locationAwardQty = data.awardQty;
        data.recallQuantity = data.recallableQty;
        return data;
      }))
    );
  }
}
