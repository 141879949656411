import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'src/cad/common/services/api/api-helper';

@Injectable()
export class CapacityReleaseOfferIndexRateApi {
  private readonly endpoint: string = 'capacity-release/offer/';
  private readonly suffix: string = '/index-rate';

  constructor(private apiHelper: ApiHelper) {}

  public getCrIndex = (auctionId: number | string, crIndexData: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix, { body: crIndexData });
  }

  public save = (auctionId: number | string, offerData: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/save', { body: offerData });
  }
}
