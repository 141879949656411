import { Injectable, Type, OnDestroy, NgZone } from '@angular/core';
import * as _ from 'lodash';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class UiFloatingContentService implements OnDestroy {

  public matDialogRef: MatDialogRef<any>;
  public isOpen: boolean = false;
  private defaultDialogConfig: MatDialogConfig = {
    autoFocus: false,
    height: 'auto',
    width: 'auto',
    maxHeight: '95vh',
    maxWidth: '95vw',
    hasBackdrop: false,
    disableClose: true,
    panelClass: 'ui-floating-content',
    position: {
      top: '20px'
    }
  };

  constructor(
    public ngZone: NgZone,
    public matDialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.close();
    if (this.matDialogRef) {
      this.matDialogRef = null;
    }
  }

  public getDefaultDialogConfig(): MatDialogConfig {
    return _.cloneDeep(this.defaultDialogConfig);
  }

  public open(contentComponent: Type<any>, matDialogConfig?: MatDialogConfig): MatDialogRef<any> {
    let dialogConfig: any = matDialogConfig ? matDialogConfig : this.defaultDialogConfig;

    this.close();
    this.matDialogRef = this.matDialog.open(contentComponent, dialogConfig);
    this.isOpen = true;
    this.ngZone.run(() => {}); // force change detection
    return this.matDialogRef;
  }

  public close(dialogResult?: any): void {
    if (this.matDialogRef && this.isOpen) {
      this.matDialogRef.close(dialogResult);
      this.matDialogRef = null;
      this.isOpen = false;
      this.ngZone.run(() => {}); // force change detection
    }
  }
}
