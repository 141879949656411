export enum DirtyStatus {
  NO_CHANGE,
  NEW,
  UPDATE,
  DELETE,
}

export enum KeyCode {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Delete = 46,
  Spacebar = 32,
  End = 35,
  Home = 36,
  Left = 37,
  Up = 38,
  Right = 39,
  Down = 40,
  Escape = 27,
  Shift = 16,
  Zero = 48,
  Nine = 57,
  Numpad_Zero = 96,
  Numpad_Nine = 105,
  Alpha_a = 65,
  Alpha_z = 90,
  Func_F12 = 123,
}
