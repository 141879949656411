import { AlertsService } from 'cad/core/services/alerts/alerts.service';

export class MessageModel {
  constructor(public message: any) {
    if (!this.message) {
      this.message = '';
    }
  }
}

export class ValidationResultModel {
  public successful: boolean = true;
  public infoMessages: MessageModel[] = [];
  public warningMessages: MessageModel[] = [];
  public errorMessages: MessageModel[] = [];
  public validationResults: any;

  public initialize(validationObj?: ValidationResultModel): void {
    if (validationObj) {
      this.clear();
      _.assign(this, validationObj);
    }
  }

  public getFirstErrorMesage(): string {
    if (!this.successful) {
      if (this.errorMessages.length > 0) {
        return this.errorMessages[0].message;
      }
      if (this.warningMessages.length > 0) {
        return this.warningMessages[0].message;
      }
      if (this.infoMessages.length > 0) {
        return this.infoMessages[0].message;
      }
    }
    return undefined;
  }

  public addInfo = (msg: any): void => {
    if (msg) {
      this.infoMessages.unshift(new MessageModel(msg));
    }
  }

  public addWarning = (msg: any): void => {
    if (msg) {
      this.warningMessages.unshift(new MessageModel(msg));
    }
  }

  public addError = (msg: any): void => {
    if (msg) {
      this.successful = false;
      this.errorMessages.unshift(new MessageModel(msg));
    }
  }

  public clear = (): void => {
    this.successful = true;
    this.infoMessages = [];
    this.warningMessages = [];
    this.errorMessages = [];
  }

  public getInfoColor = (): string => {
    return 'blue';
  }

  public getWarningColor = (): string => {
    return 'orange';
  }

  public getErrorColor = (): string => {
    return 'red';
  }

  public isSuccess = (): boolean => {
    return this.successful;
  }

  public showAlerts = (alertsService: AlertsService): void => {
    _.each(this.infoMessages, (infoMessage) => {
      alertsService.info(infoMessage.message);
    });

    _.each(this.warningMessages, (warningMessage) => {
      alertsService.warning(warningMessage.message);
    });

    _.each(this.errorMessages, (errorMessage) => {
      alertsService.danger(errorMessage.message);
    });
  }

  public mergeMsgs = (validationObj: ValidationResultModel): void => {
    // TODO(ng2) validationObj.successful should be there according to the type but checking for
    // existance anyway as this object comes from js code right now --- check can be removed once everything is TypeScript
    if (_.has(validationObj, 'successful') && validationObj.successful === false) {
      this.successful = validationObj.successful;
    }

    if (validationObj.infoMessages) {
      this.mergeInfoMsgs(validationObj.infoMessages);
    }

    if (validationObj.warningMessages) {
      this.mergeWarningMsgs(validationObj.warningMessages);
    }

    if (validationObj.errorMessages) {
      this.mergeErrorMsgs(validationObj.errorMessages);
    }
  }

  public mergeInfoMsgs = (msgArr: MessageModel[]): void => {
    if (_.isArray(msgArr)) {
      this.infoMessages = this.infoMessages.concat(msgArr);
    }
  }

  public mergeWarningMsgs = (msgArr: MessageModel[]): void => {
    if (_.isArray(msgArr)) {
      this.warningMessages = this.warningMessages.concat(msgArr);
    }
  }

  public mergeErrorMsgs = (msgArr: MessageModel[]) => {
    if (_.isArray(msgArr)) {
      this.errorMessages = this.errorMessages.concat(msgArr);
    }
  }
}

// @Injectable()
// export class ValidationResultModel {

//   constructor(
//     private cadAlerts: AlertsService,
//   ) {}

//   // public validationResult = (validationObj?: ValidationResult): ValidationResult => {
//   //   return new ValidationResult(this.cadAlerts, validationObj);
//   // }
// }
