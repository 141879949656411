<ui-form type="basic"
         (dirtyStatusChanged)="isDirty = $event.dirtyStatus">
  <div fxLayout="column"
       class="md-padding">
    <ui-select uiInput
               name="reductionCdValue"
               placeholder="Reduction Reason"
               [(ngModel)]="reductionCdValue"
               [disabled]="!editable"
               [required]="editable"
               [codeType]="codeType"></ui-select>
    <mat-form-field *ngIf="showComment">
      <input uiInput
             matInput
             type="text"
             name="commentText"
             [(ngModel)]="commentText"
             [disabled]="!editable"
             [required]="editable"
             placeholder="Comments">
    </mat-form-field>
  </div>
</ui-form>
