<ui-loading-bar class="search-loading-bar"></ui-loading-bar>

<div fxLayout="column"
     [class.wrapper-fullscreen]="layoutFullscreen">
  <div fxFlex=""
       fxLayout="row">
    <div fxFlex="25"
         fxLayout="column">
      <div *ngIf="readyForPartials && itemFormTopContent"
           class="form-top-content">
        <ng-container [framingComponentOutlet]="itemFormTopContent"
                      [framingComponentInjector]="lookupInjector"
                      [framingComponentInputs]="{ lookupInjector: lookupInjector }"
                      (onComponent)="onFormTopContentComponent($event)">
        </ng-container>
      </div>
      <!-- form -->
      <div fxFlex=""
           class="form"
           [ngClass]="formClasses"
           fxLayout="column">
        <div *ngIf="layoutFullscreen" class="search-title">{{searchTitle}}</div>
        <ui-query #uiQuery
                  [api]="api"
                  (itemsChange)="setItems($event)"
                  [params]="searchReference ? searchReference.instance.params : {}"
                  (paramKeysChange)="setParamKeys($event)"
                  [formCtrl]="searchForm.form"
                  [constantParams]="constantParams ? constantParams : {}"
                  [allowEmptyParams]="itemModel?.allowEmptySearchParams"
                  [context]="itemModel.name"
                  (loading)="updateUIResults($event)">
        </ui-query>
        <ui-form uiAutoFocus
                 fxFlex
                 skipDisable="true"
                 #searchForm
                 (keyup.enter)="onEnterPressed($event)"
                 (keyup)="onKeyPressed($event)">
          <ng-container *ngIf="readyForPartials"
                        [framingComponentOutlet]="itemSearchForm"
                        [framingComponentInjector]="lookupInjector"
                        [framingComponentInputs]="{ searchLayout: searchLayout, lookupInjector: lookupInjector }"
                        (onComponent)="onSearchComponent($event)">
          </ng-container>
        </ui-form>
      </div>
    </div>
    <!-- results -->
    <div class="results"
         [ngClass]="resultsClasses"
         fxFlex="75"
         fxLayout="column">
      <div fxLayout="row" fxFlex="none">
        <div *ngIf="itemSearchDates" fxFlex>
          <ng-container *ngIf="readyForPartials"
            [framingComponentOutlet]="itemSearchDates"
            [framingComponentInjector]="lookupInjector"
            [framingComponentInputs]="{ lookupInjector: lookupInjector }"
            (onComponent)="onConstantsComponent($event)">
          </ng-container>
        </div>
        <div class="results-title" fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="18px">
          <div>{{items?.length}} Result{{items?.length == 1 ? '' : 's'}}</div>
          <div *ngIf="readyForPartials && itemResultsTopContent">
            <ng-container [framingComponentOutlet]="itemResultsTopContent"
                          [framingComponentInjector]="lookupInjector"
                          [framingComponentInputs]="{ lookupInjector: lookupInjector }"
                          (onComponent)="onResultsTopContentComponent($event)">
            </ng-container>
          </div>
        </div>
      </div>

      <div fxFlex>
        <ui-table #resultsTable
                  fxFlex
                  [data]="items"
                  [id]="'CadSearch_' + itemModel.name"
                  (rowDataChanged)="onRowDataChanged($event)"
                  [onGridDestroyed]="onResultsTableDestroyedFn"
                  [showSingleSelect]="layoutTableLookup"
                  (rowClicked)="select($event)"
                  (sortChanged)="onSortChanged($event)"
                  (filterChanged)="onFilterChanged($event)"
                  (gridReady)="onGridReady($event)"
                  [enableFilter]="true"
                  [enableSorting]="true"
                  [suppressHorizontalScroll]="suppressHorizontalScroll"
                  [overlayNoRowsTemplate]="'<span></span>'"
                  renderFullHeight="true">
          <ng-container [framingComponentOutlet]="itemSearchList"
                        [framingComponentInputs]="{ lookupInjector: lookupInjector }">
          </ng-container>
        </ui-table>
      </div>

      <div class="no-results" *ngIf="items?.length < 1 && !loading">
        <mat-icon md-font-library="material-icons">{{resultIcon}}</mat-icon>
        <div>{{resultMessage}}</div>
      </div>
    </div>
  </div>
  <!-- filters -->
  <div class="filters"
       [ngClass]="filtersClasses"
       fxLayout="row"
       fxLayoutAlign="start center">
    <div fxFlex="25"
         fxLayout="row wrap"
         fxLayoutAlign="center center">
      <button mat-button
              id="btn-search-clear"
              class="filters_clear_button"
              type="button"
              mat-raised-button
              (click)="clearParams()">Clear</button>
      <button mat-button
              id="btn-search-search"
              class="filters_search_button"
              type="button"
              mat-raised-button
              color="primary"
              (click)="searchClicked()"
              [disabled]="!(paramKeys.length!=0 || keyPressed || (itemModel?.allowEmptySearchParams))">Search</button>
    </div>

    <div class="footer_filter_list"
         fxLayout="row"
         fxLayoutAlign="start center"
         fxFlex="75">
      <div><b>{{chipParamKeys.length}} Filter(s)</b></div>

      <mat-chip-list *ngIf="itemView.searchChipTemplate" fxFlex="">
        <mat-chip *ngFor="let paramKey of chipParamKeys; let i=index">
          <ng-container *ngComponentOutlet="itemView.searchChipTemplate; injector: chipInjectors[i]"></ng-container>
          <button mat-button class="filters-chip-remove" (click)="removeParam(paramKey.key)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-list>

      <mat-chip-list *ngIf="!itemView.searchChipTemplate" fxFlex="">
        <mat-chip *ngFor="let param of chipParamKeys">
          <strong>{{param.fieldLabel}}</strong>&nbsp;
          <em>{{param.formattedVal}}</em>
          <button mat-button class="filters-chip-remove" (click)="removeParam(param.key)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-list>
      <div *ngIf="layoutTableLookup">
        <ui-button label="Columns" type="dropdown">
          <ui-button-dropdown *ngFor="let column of resultsTable.columns" (btnClick)="column.toggleVisibility()">
            <mat-checkbox [checked]="!column.hide">{{column.headerName}}</mat-checkbox>
          </ui-button-dropdown>
          <ui-button-dropdown (btnClick)="restoreColumnVisibility()">Restore Defaults</ui-button-dropdown>
        </ui-button>
      </div>
      <ui-button *ngIf="layoutFullscreen"
                 (click)="cadAppController.toggleSearchState()"
                 label="Close"></ui-button>
    </div>
  </div>
</div>
