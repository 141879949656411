/**
 * Created by jrayavarap on 6/29/2017.
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class ShipperImbalanceApi {

  private readonly endpoint: string = 'flowing-gas/imbalance/shipper-imbalance';

  constructor(
    private apiHelper: ApiHelper) {}

  public getHeader = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/header', { body: params });
  }

  public getHeaderAndList = (params: any, type: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/header-and-list/' + type, { body: params });
  }

}
