import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment-timezone';
import { isArray } from 'lodash';

@Injectable()
export class UiMomentDatetimeAdapter extends MomentDateAdapter {
  
  /**
   * @description Whether to include time parts of the date when doing comparisons.
   * @returns {boolean}
   */
  public get includeTime(): boolean {
    return this._includeTime;
  }
  public set includeTime(value: boolean) {
    this._includeTime = value;
  }
  private _includeTime: boolean;
  /**
   * @description Whether to include the seconds of the date when doing comparisons.
   * @returns {boolean}
   */
  public get includeSeconds(): boolean {
    return this._includeSeconds;
  }
  public set includeSeconds(value: boolean) {
    this._includeSeconds = value;
  }
  private _includeSeconds: boolean;
  
  /**
   * @description Gets the hour component of the given date.
   * @param date The date to extract the month from.
   * @returns {number} The hour component.
   */
  getHour(date: Moment): number {
    return date ? date.hours() : null;
  }
  
  /**
   * @description Gets the minute component of the given date.
   * @param date The date to extract the month from.
   * @returns {number} The minute component.
   */
  getMinute(date: Moment): number {
    return date ? date.minutes() : null;
  }
  
  /**
   * @description Gets the second component of the given date.
   * @param date The date to extract the month from.
   * @returns {number} The second component.
   */
  getSecond(date: Moment): number {
    return date ? date.seconds() : null;
  }
  
  /**
   * @description Set the hour component of the given date.
   * @param date The date to extract the month from.
   * @param value The value to set.
   */
  setHour(date: Moment, value: number): void {
    if (date) {
      date.hours(value);
    }
  }
  
  /**
   * @description Set the second component of the given date.
   * @param date The date to extract the month from.
   * @param value The value to set.
   */
  setMinute(date: Moment, value: number): void {
    if (date) {
      date.minutes(value);
    }
  }
  
  /**
   * @description Set the second component of the given date.
   * @param date The date to extract the month from.
   * @param value The value to set.
   */
  setSecond(date: Moment, value: number): void {
    if (date) {
      date.seconds(value);
    }
  }
  
  /**
   * @description Check if two date times have same time.
   * @param first The first datetime to check.
   * @param second The second datetime to check.
   * @override Overrides {@see DateAdapter#sameDate}
   * @returns {boolean} Whether the two date times are equal.
   * Null date times are considered equal to other null date times.
   */
  sameDate(first: Moment | null, second: Moment | null): boolean {
    if (!this.includeTime) {
      return super.sameDate(first, second);
    }
    return super.sameDate(first, second)
      && this.getHour(first) === this.getHour(second)
      && this.getMinute(first) === this.getMinute(second)
      && (this.includeSeconds ? this.getSecond(first) === this.getSecond(second) : true);
  }
  
  /**
   * @description Copy time from a date to a another date.
   * @param toDate
   * @param fromDate
   */
  copyTime(toDate: Moment, fromDate: Moment): void {
    this.setHour(toDate, this.getHour(fromDate));
    this.setMinute(toDate, this.getMinute(fromDate));
    this.setSecond(toDate, this.getSecond(fromDate));
  }
  
  /**
   * @description Compares two dates. Adds time inclusion.
   * @param first The first date to compare.
   * @param second The second date to compare.
   * @override Overrides {@see DateAdapter#compareDate}
   * @returns {number} 0 if the dates are equal, a number less than 0 if the first date is earlier,
   * a number greater than 0 if the first date is later.
   */
  compareDate(first: Moment, second: Moment): number {
    let res: number;
    if (!this.includeTime) {
      return super.compareDate(first, second);
    }
    res = super.compareDate(first, second) ||
      this.getHour(first) - this.getHour(second) ||
      this.getMinute(first) - this.getMinute(second);
    if (this.includeSeconds) {
      res = res || this.getSecond(first) - this.getSecond(second);
    }
    return res;
  }
  
  /**
   * @description Set time by using default values.
   * @param date
   * @param defaultTime List default values [hour, minute, second]
   */
  setTimeByDefaultValues(date: Moment, defaultTime: number[]): void {
    let timeArr: number[] = [];
    if (isArray(defaultTime) && defaultTime.length > 0) {
      timeArr.push.apply(timeArr, defaultTime);
    }
    this.setHour(date, timeArr[ 0 ] || 0);
    this.setMinute(date, timeArr[ 1 ] || 0);
    this.setSecond(date, timeArr[ 2 ] || 0);
  }
}
