
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import * as _ from 'lodash';

@Injectable()
export class DefineRecurPatternsApi {

  public readonly endpoint: string = 'administration/table-maintenance/define-recur-patterns';

  constructor(
    private apiHelper: ApiHelper
  ) {}

  public getById(id: number | string): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/' + id);
  }

  public find(params: any): Observable<any> {
    return (params && !_.isNil(params)) ? this.getById(params.recurPatternDefinitionId) : observableOf({});
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/dashboard');
  }

  public save(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save', { body: params });
  }

  public deleteItem(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/delete', { body: params });
  }

  public exportRecurPatternDef(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/export-recur-pattern-def', { body: params });
  }
}
