import { ModalCtrlDelegator } from './../../../features/common/modal/modal-registry.service';
import { Injector } from '@angular/core';
import { AutoUnsubscribables, AutoUnsubscriber } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import { TableColumnLookupDialogComponent } from './lookup-dialog.component';
import * as _ from 'lodash';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

export class TableColumnLookupBaseComponent {
  params: any;
  @AutoUnsubscriber() protected subs: AutoUnsubscribables;

  constructor(
    protected dialog: MatDialog,
    protected injector: Injector) {
  }

  isEditable(): boolean {
    if (this.params) {
      if (!this.params.colDef) {
        return true; // no colDef in TableColumnLookupEditorComponent
      } else if (_.isFunction(this.params.colDef.editable)) {
        return this.params.colDef.editable(this.params);
      } else {
        return this.params.colDef.editable;
      }
    } else {
      return false;
    }
  }

  openDialog($event?: any): void {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    const editable: boolean = this.isEditable();
    if (!editable) {
      console.warn('Column not editable'); // should not be able to get here
      return;
    }

    if(this.params.column.colDef.lookupComponent) {
      let modalWidth: string = _.clone(this.params.column.colDef.lookupModalWidth);
      let modalHeight: string = _.clone(this.params.column.colDef.lookupModalHeight);

      let data: any = {
        component: this.params.column.colDef.lookupComponent,
        itemData: this.params.node.data,
        colDef: this.params.column.colDef,
        injector: this.injector,
        lookupParams: this.params.column.colDef.lookupParams
      };

      let config: MatDialogConfig = {
        width: modalWidth,
        height: modalHeight,
        data,
      };

      let dialogRef: MatDialogRef<any> = this.dialog.open(TableColumnLookupDialogComponent, config);
      this.subs.newSub = dialogRef.afterClosed().subscribe(this.closedDialog);
    } else if (this.params.column.colDef.lookupModalController) {
      let options = this.params.column.colDef.lookupModalOptions;
      // Allow lookupModalOptions to be function to provide dynamic data
      if ( options instanceof Function ) { options = options.call(); }
      this.subs.newSub = (this.params.column.colDef.lookupModalController as ModalCtrlDelegator).open(options).subscribe(this.closedDialog);
    }
  }

  protected closedDialog = (result: any) => {
    if (this.params) {
      if (this.params.column &&
        this.params.column.colDef && result) {
        if(this.params.column.colDef.lookupResultTransform) {
          result = this.params.column.colDef.lookupResultTransform(result);
        }
        this.setLookupRowValue(this.params.column.colDef, result);
        if (this.params.column.colDef.lookupCallback) {
          let event = { data: result, colDef: this.params.column.colDef };
          this.params.column.colDef.lookupCallback.emit(event);
        }
      }
      //Commenting this out.  It is making the cell unfocused.  I want to remove as I would like
      //to see on close of the lookkup the cell is still in focus.
      //this.params.api.rowEdit.stopEditing();
    }
  }

  protected setLookupRowValue(colDef: any, item: any): void {
    let lookupAction: string = colDef.lookupAction;
    switch (lookupAction) {
      case 'row': {
        this.setLookupAssignRow(item);
        break;
      }
      case 'assign': {
        this.setLookupAssignValue(item);
        break;
      }
      default: {
        this.setLookupValue(item);
      }
    }
  }

  protected onSetValue(value: any): void {
    this.params.value = value;
  }

  private setLookupValue(value: any): void {
    this.params.api.valueService.setValue(this.params.node, this.params.column.colId, value);
    this.onSetValue(value);
    this.params.api.refreshCells([ this.params.node ], [ this.params.column.colId ], true);
  }

  private setLookupAssignValue = (item: any): void => {
    this.handleNonAssignableFields(item);
    let cols = this.params.columnApi.getAllColumns();

    /**
     * This adds any additional data returned from the lookup that doesn't
     * exist in the original table data object by diffing the object returned
     * from the lookup with the columns in the table and adding just those fields
     */
    let lookupArr = Object.keys(item);
    let origArr = cols.map((c) => c.colDef.field);
    lookupArr.push(...origArr);
    lookupArr = _.uniq(lookupArr);
    let diff = _.difference(lookupArr, origArr);
    diff.forEach((metaCol) => {
      this.params.node.data[ metaCol ] = item[ metaCol ];
    });

    /**
     * This adds data exists in the original table data object
     */
    cols.forEach((col) => {
      if (!item[ col.colDef.field ]) { return; }
      this.params.api.valueService.setValue(this.params.node, col.colId, item [ col.colDef.field ]);
      if (col.colId === this.params.column.colId) {
        this.onSetValue(item [ col.colDef.field ]);
      }
    });

    this.params.api.refreshCells();
  }

  // Assign the result model to the entire row model
  private setLookupAssignRow = (item: any): void => {
    this.params.node.setData(_.omit(_.cloneDeep(item), [ '$$hashKey', 'dirtyStatus' ]));
    this.params.api.valueService.setValue(this.params.node, this.params.column.colId, item [ this.params.column.colDef.field ]);
  }

  private handleNonAssignableFields = (item: any) : void => {
    delete item.dirtyStatus;
  }
}
