import { Injectable } from '@angular/core';

// TODO(ng2): this is an exact copy of DirtyFormService----guessing is was started as a copy of that class and forgotten
@Injectable()
export class CopyFormService {

  public setDirty = (item: any, isDirty: boolean): void => {
    if ('dirtyStatus' in item) {
      isDirty ? item.dirtyStatus = cad.DirtyStatus.UPDATE : item.dirtyStatus = cad.DirtyStatus.NO_CHANGE;
    }
  }

  public restore = (form: any): void => {
    form.$setPristine();
  }
}
