import { Injectable } from '@angular/core';
import { UserDetail, AvailableAsset } from 'src/cad/common/models/user/user-details-model';
import { UserAsset } from 'src/cad/common/models/user/user-asset';
@Injectable()
export class UserModel {

  private _userName?: string;
  private _firstName?: string;
  private _lastName?: string;
  private _internal?: string;
  private _availableUserDetails: UserDetail[] = [];
  private _userDetail: UserDetail;
  private _userAsset: UserAsset;
  private _loggedOut: boolean;
  private _runAsUser: string;

  public setAvailableUserDetails = (userDetails: UserDetail[]): UserModel => {
    this._availableUserDetails = userDetails;
    this._userName = userDetails[ 0 ].userName;
    this._firstName = userDetails[ 0 ].firstName;
    this._lastName = userDetails[ 0 ].lastName;
    this._internal = userDetails[ 0 ].internal;
    this._loggedOut = false;
    return this;
  }

  public setUserDetail = (userDetail: UserDetail): UserModel => {
    this._userDetail = userDetail;
    return this;
  }

  public getUserDetail = (): UserDetail => {
    return this._userDetail;
  }
  public setUserAsset = (userAsset: UserAsset): UserModel => {
    this._userAsset = userAsset;
    return this;
  }

  public getAvailableUserDetails(): UserDetail[] {
    return this._availableUserDetails;
  }

  public getUserAsset(): UserAsset {
    return this._userAsset;
  }

  public getInternal = (): boolean => {
    if (this._internal === 'Y') {
      return true;
    } else {
      return false;
    }
  }

  public getUserName = (): string => {
    return this._userName;
  }

  public getAvailableAssets = (): AvailableAsset[] => {
    return this.getUserAsset().getAllAvailableAssets();
  }

  public getCurrentBusinessAssociates = (): string[] => {
    let rv = [];
    if (this._userDetail && this._userDetail.currentContext) {
      let assetName = this._userDetail.currentContext.assetName;
      rv = this._userDetail.availablecontexts[ assetName ];
    }
    return rv;
  }

  public getAvailableBusinessAssociatesByAsset = (asset: AvailableAsset): string[] => {
    return this.getUserAsset().getBusinessAssociatesByAsset(asset, this._availableUserDetails);
  }

  public clear = (): void => {
    this._firstName = undefined;
    this._userName = undefined;
    this._firstName = undefined;
    this._lastName = undefined;
    this._internal = undefined;
    this._availableUserDetails = [];
    this._userDetail = undefined;
    if (this._userAsset) { this._userAsset.clear(); }
    this._userAsset = undefined;
    this._loggedOut = true;
  }

  public isLoggedOut = (): boolean => {
    return this._loggedOut;
  }

  public isRunAsUser(): boolean {
    if (this._runAsUser) {
      return true;
    }
    return false;
  }

  public getRunAsUser(): string {
    return this._runAsUser;
  }

  public setRunAsUser(user: string): void {
    this._runAsUser = user;
  }

}
