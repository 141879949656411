import { Component, Output, EventEmitter } from '@angular/core';
import { cloneDeep } from 'lodash';

const pasteFromClipboard = (event: ClipboardEvent): string[][] => {
  return cloneDeep(event.clipboardData.getData('text'))
  .split(String.fromCharCode(13))
  .map((item) => item.replace(/[\n\r]+/g, '').split(String.fromCharCode(9)))
  .filter((item) => item[0] !== '');
};

@Component({
  selector: 'excel-import',
  templateUrl: './excel-import.component.html'
})

export class ExcelImportComponent {
  @Output() import: EventEmitter<string[][]> = new EventEmitter();

  onPaste(event: ClipboardEvent): void {
    this.import.emit(pasteFromClipboard(event));
  }
}
