import { Injector, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AssociationController } from 'src/features/common/association/association.controller';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AssociationApi } from 'src/features/common/association/association-api';
import { MeasuredVolumeExceptionData } from 'src/cad/flowing-gas/shared/interfaces/measured-volume-exception';
import ValidationResult = cad.ValidationResult;

@Injectable()
export class PhysicalLocationAssociatedMetersApi implements AssociationApi {
  private readonly endpoint: string = 'physical/locations/';
  private readonly endpointSuffix: string = '/meters';

  static createAssociationApi(
    apiHelper: ApiHelper,
    associationController: AssociationController,
    injector: Injector,
  ): AssociationApi {
    return new PhysicalLocationAssociatedMetersApi(apiHelper);
  }

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + params.pointCd + this.endpointSuffix + '/filter', { body: params });
  }

  public findAll = (id: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + id + this.endpointSuffix);
  }

  public save = (associations: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + associations[0].pointCd + this.endpointSuffix + '/save', { body: associations });
  }

  public lookup = (id: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + id + this.endpointSuffix + '/lookup');
  }

  public measureVolException(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + 'measured', { body: params });
  }
  
  public deleteMeasuredVolExceptionList(deleteList: MeasuredVolumeExceptionData[]): Observable<ValidationResult> {
    return this.apiHelper.request(this.endpoint + 'measured/delete', { body: deleteList });
  }
}
