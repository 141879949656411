<mat-form-field class="date-picker-container"
                [floatLabel]="'always'"
                internalInput
                #inputWrapper>
  <mat-datepicker-toggle matPrefix
                         [tabIndex]="-1"
                         [for]="matDatepickerRef">
    <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
  </mat-datepicker-toggle>
  <input matInput
         #input
         #inputModelRef="ngModel"
         [attr.tabindex]="disabled ? -1 : 0"
         [(ngModel)]="datepickerInputModel"
         [ngModelOptions]="{ updateOn: updateModelOn }"
         [matDatepicker]="matDatepickerRef"
         [min]="minDate"
         [max]="maxDate"
         [matDatepickerFilter]="datepickerFilter"
         (dateInput)="dateEdit()"
         (dateChange)="onDateChange($event)"
         (blur)="onInputBlur()"
         (keydown.enter)="$event.preventDefault()"
         [placeholder]="placeholder"
         [required]="required"
         [disabled]="disabled">
  <mat-datepicker #matDatepickerRef
                  [startView]="startView"
                  [calendarHeaderComponent]="calendarHeaderComponent"
                  [actionMode]="actionMode"
                  [monthMode]="monthMode"
                  [includeTime]="includeTime"
                  [includeSeconds]="includeSeconds"
                  [meridianMode]="meridianMode"
                  [disableMinute]="disableMinute"
                  [stepHour]="stepHour"
                  [stepMinute]="stepMinute"
                  [stepSecond]="stepSecond"
                  (opened)="onCalendarOpen($event)"
                  (closed)="onCalendarClose($event)"></mat-datepicker>
  <mat-hint *ngIf="hintText"
            [align]="hintAlign">{{hintText}}</mat-hint>
  <mat-error class="mat-error"
             #errorNode></mat-error>
</mat-form-field>
