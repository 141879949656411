<button *ngIf="!params.node.floating" mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon>more_vert</mat-icon>
  <mat-menu #menu="matMenu">
    <div>
      <h4>{{menuName}}</h4>
      <mat-divider></mat-divider>
    </div>
    <button mat-menu-item *ngFor="let item of menuItems.sections">
      <mat-icon>{{item.icon}}</mat-icon>
      <span>{{item.name}}</span>
    </button>

  </mat-menu>
</button>
