import { Injector, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AssociationController } from 'src/features/common/association/association.controller';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AssociationApi } from 'src/features/common/association/association-api';

@Injectable()
export class PhysicalLocationForecastSegApi implements AssociationApi {
  private readonly endpoint: string = 'physical/location/';
  private readonly suffix: string = '/forecast';

  static createAssociationApi(
    apiHelper: ApiHelper,
    associationController: AssociationController,
    injector: Injector,
  ): AssociationApi {
    return new PhysicalLocationForecastSegApi(apiHelper);
  }
  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public findAll = (pointCd: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + pointCd + this.suffix);
  }

  public lookup = (pointCd: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + pointCd + this.suffix + '/segments');
  }

  public save = (segs: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + segs[0].pointCd + this.suffix, { body: segs });
  }
}
