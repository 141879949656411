import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-card-fill',
  templateUrl: './card-fill.component.html',
  styleUrls: [ './card-fill.component.less' ],
})
export class UiLayoutCardFillComponent {

  @Input() public headline: string;

  constructor() {}
}
