import { Injector, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AssociationController } from 'src/features/common/association/association.controller';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AssociationApi } from 'src/features/common/association/association-api';

@Injectable()
export class PhysicalLocationContractsApi implements AssociationApi {
  private readonly endpoint: string = 'physical/locations/';
  private readonly suffix: string = '/contracts';

  static createAssociationApi(
    apiHelper: ApiHelper,
    associationController: AssociationController,
    injector: Injector,
  ): AssociationApi {
    return new PhysicalLocationContractsApi(apiHelper);
  }

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public findAll = (pointCd: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + pointCd + this.suffix + '/current');
  }

  public getContractHistory = (pointCd: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + pointCd + this.suffix + '/history');
  }
}
