import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'ui-article',
  templateUrl: './article.component.html',
  styleUrls: [ './article.component.less' ],
})
export class UiLayoutArticleComponent {

  @Input() public class: string;
  @Input() public heading: string;

  constructor() {
    this.class = this.class || '';
  }
}
