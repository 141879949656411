import { Injectable, Injector, NgZone } from '@angular/core';
import { ModalController } from 'src/features/common/modal/modal.controller';
import { ModalModel } from 'src/features/common/modal/modal.model';
import { ModalFeature } from 'src/features/common/modal/modal.feature';

@Injectable()
export class ModalRegistryRegistrationService {

  constructor(
    private injector: Injector,
    private ngZone: NgZone
  ) {  }

  getModalController(modalModel: ModalModel, modalConfigOverRides?: ModalModel): ModalController {
    let config: ModalModel = _.cloneDeep(modalModel);
    _.assign(config, modalConfigOverRides );
    return this.setModalController(config);
  }

  setModalController(model: ModalModel): ModalController {
    let modalController: ModalController;
    if (!(model && model.lookupInputs && model.lookupInputs.inputs)) {
      throw new Error('Invalid modal config:\n ' + JSON.stringify(model));
    }
    let config = _.cloneDeep(model);
    config = {...ModalFeature.defaultModelStatic, ...config};
    modalController = new ModalController(this.injector, this.ngZone);
    modalController.initializeController(config.name, config, {});
    modalController.initializeFactoryInjector(this.injector);
    return modalController;
  }
}
