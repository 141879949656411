export const cadZone = () => {
  const BLACKLISTED_ZONE_EVENTS: string[] = [
    'addEventListener:mouseenter',
    'addEventListener:mouseleave',
    'addEventListener:mousemove',
    'addEventListener:mouseout',
    'addEventListener:mouseover',
    'addEventListener:mousewheel',
    'addEventListener:scroll',
    'requestAnimationFrame',
  ];
  return Zone.current.fork({
    name: 'blacklist',
    onScheduleTask: (delegate: ZoneDelegate, current: Zone, target: Zone, task: Task): Task => {
      if (task.type !== 'eventTask') { return delegate.scheduleTask(target, task); }
      let classList = task.data &&
                    (task.data as any).target &&
                    (task.data as any).target.classList &&
                    (task.data as any).target.classList.length;

      let isGridEvent: boolean = false;

      if (classList) {
        let tmp = [];
        for (let i = 0; i < classList; i++) {
          tmp.push((task.data as any).target.classList[i]);
        }
        if (tmp.join('').indexOf('ag-') > -1) {
          isGridEvent = true;
        }
      }

      // Blacklist scroll, mouse, and request animation frame events.
      if (BLACKLISTED_ZONE_EVENTS.some((name) => task.source.indexOf(name) > -1) && isGridEvent) {
        task.cancelScheduleRequest();
        // Schedule task in root zone, note Zone.root != target,
        // "target" Zone is Angular. Scheduling a task within Zone.root will
        // prevent the infinite digest cycle from appearing.
        return Zone.root.scheduleTask(task);
      } else {
        return delegate.scheduleTask(target, task);
      }
    }
  });
};
