import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject ,  timer } from 'rxjs';
import { IdleService } from 'cad/core/services/session/idle-service';
import { switchMap, tap } from 'rxjs/operators';

@Injectable()
export class IdleInterceptor implements HttpInterceptor {
  private readonly tickPeriod: number = 60000;
  private reset: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  private readonly filteredUrlPatterns: string[] = [
    'logout',
    'login',
    'index.html',
    'proxy.html' ];

  constructor(private idleService: IdleService) {
    this.reset.pipe(switchMap(() => timer(0, this.tickPeriod)))
      .subscribe((tick) => this.idleService.idleSession(tick));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(tap((event) => {
        if (event instanceof HttpResponse && !this.shouldBypass(event.url)) {
          this.reset.next(null);
        }
      }
    ));
  }

  private shouldBypass(url: string): boolean {
    for(let ignoreString of this.filteredUrlPatterns) {
      if (url.toLowerCase().includes(ignoreString.toLowerCase())) {
        return true;
      }
    }
    return false;
  }
}
