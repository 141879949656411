<div class="sidenav" fxFlex fxLayout="column">
  <ul class="sidenav-menu">
    <li *ngFor="let sectionItem of sections; let i = index"
        class="parent-list-item {{sectionItem.className || ''}}"
        [hidden]="sectionItem.hide"
        #sectionItemList>
      <h2 class="menu-heading mat-subhead"
          *ngIf="sectionItem.type === 'heading'"
          id="heading_{{ sectionItem.name}}">
        {{sectionItem.name}}
        <mat-icon >vert_more</mat-icon>
      </h2>
      <ui-nav-link *ngIf="sectionItem.type === 'link'"
                   [section]="sectionItem"></ui-nav-link>
      <ui-nav-toggle *ngIf="sectionItem.type === 'toggle'"
                     (onToggle)="toggleMenu($event, sectionItem)"
                     [section]="sectionItem"></ui-nav-toggle>
      <ui-nav-href *ngIf="sectionItem.type === 'href'"
                     [section]="sectionItem"></ui-nav-href>
    </li>
  </ul>
</div>
<div fxLayout class="site-sidenav-footer" (click)="toggleSideNav()">
  <mat-icon *ngIf="!isSideNavOpen"
            aria-hidden="true">chevron_right</mat-icon>
  <mat-icon *ngIf="isSideNavOpen"
            aria-hidden="true">chevron_left</mat-icon>
</div>
