
import {filter} from 'rxjs/operators';
/**
 * @name uiLoginButton
 * @module common.components.toolbar.uiLoginMenu
 *
 * @description
 * `<ui-login-menu>`
 *
 * @param {string} heading
 * @param {string} class
 * @param {string} top
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SecurityApi } from 'cad/common/services/api/security/security';
import { RouterService } from 'src/cad/core/services/router/router.service';
import { AuthInterceptorService } from 'common/services/http-interceptor/auth-interceptor-service';
import { UserStoreService } from 'cad/common/store/core/services/user-store.service';
import { RootObject } from 'cad/common/store/user/user-model';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import { UserService2 } from 'src/cad/common/store/user/services/user.service';
import { SecurityLoginApi } from 'src/cad/common/services/api/security/login/login';
import { assets } from 'src/common/types/cad.constants';

const RUN_AS_ROLE = 'Run-As Authorized';

@Component({
  selector: 'ui-login-menu',
  templateUrl: './header-toolbar-login-menu.component.html',
  styleUrls: [ './header-toolbar-login-menu.component.less' ],
})
export class HeaderToolbarLoginMenuComponent implements OnInit, OnDestroy {
  runAs: Boolean = false;
  public user: any = {};
  public enableEditProfile: boolean;
  // public html: any;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: RouterService,
    private securityApi: SecurityApi,
    private loginApi: SecurityLoginApi,
    private authInterceptor: AuthInterceptorService,
    private userModelService: UserModelService,
    private userService: UserService2,
    private currentContextService: UserStoreService,
  ) { }

  public ngOnInit(): void {
    this.subscriptions.push(this.currentContextService.stateChanged.pipe(
      filter((userState) => userState.state))
      .subscribe((userDetail) => this.onUserContext(userDetail.state.user)));
    this.subscriptions.push(this.authInterceptor.unauthorized.subscribe(() => {
      if (this.router.url !== '/login') {
        setTimeout(() => { // without this timeout, the navigation to /login breaks the login dialog (don't know why)
          this.router.navigate([ '/login' ]);
        });
      }
    }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public openMenu = ($mdOpenMenu: any, event: Event, element: Element): void => {
    $mdOpenMenu(event);
  }

  public loginUser(): void {
    this.router.navigate([ '/login' ]);
  }

  public logoutUser(): void {
    const { runAsUser } = this.userService.getUserStoreState();
    if (runAsUser) {
      this.userService.setRunAsUser(null);
      this.subscriptions.push(this.loginApi.loginUserNTLM().subscribe());
    } else {
      this.subscriptions.push(this.securityApi.logout.logoutUser().subscribe());
    }
  }

  public editDashboard(): void {
    this.router.navigate([ '/dashboard' ], { queryParams: { edit: 1 } });
  }

  public editUserProfile(): void {
    this.router.navigate([ '/user-profile' ]);
  }

  public runAsUser(): void {
    this.router.navigate([  '/run-as-user' ]);
  }

  private onUserContext(user: RootObject): void {
    this.user = user;
    if (user) {
      this.user.name = user.firstName + ' ' + user.lastName;
      if (this.userModelService && this.userModelService.getUser() && this.userModelService.getUser().userRoles) {
        this.runAs = this.userModelService.getUser().userRoles.some((item) => item.name === RUN_AS_ROLE);
      }
      this.enableEditProfile = this.userModelService.getCurrentAssetNbr() !== assets.FGT;
    }
  }
}
