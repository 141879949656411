import { Component, Input, OnInit, OnChanges, forwardRef, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CodesModel } from 'src/cad/common/models/codes/codes-model';
import { UIControlValueAccessor } from 'common/components/form/control-value-accessor';
import { AutoUnsubscriber, AutoUnsubscribables } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import { sortBy } from 'lodash';

let symSupport: boolean = (typeof Symbol === 'function' && typeof Symbol() === 'symbol');

@Component({
  selector: 'ui-multi-checkbox',
  templateUrl: './multi-checkbox.component.html',
  styleUrls: [ './multi-checkbox.component.less' ],
  providers: [ { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UiMultiCheckboxComponent), multi: true } ],
})
export class UiMultiCheckboxComponent extends UIControlValueAccessor implements OnInit, OnChanges {
  @Input() flexAmount: number = 25;
  @Input() code: string;
  @Input() itemValue: any;
  @Input() itemLabel: string;
  @Input() itemLabelFilter: any;
  @Input() itemLayout: any = 'row wrap';
  @Input() label: string;
  @Input() selectOnSingle: boolean;
  @Input() showCheckedOnDisabled: boolean = false;
  @Input() sorted: boolean = true;

  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  public checkedSym: any = symSupport ? Symbol('checked') : '__checked__';
  public disabledSym: any= symSupport ? Symbol('disabled') : '__disabled__';

  private _items: any[] = [];
  private _disabledItems: any[] = [];


  constructor(private codesModel: CodesModel) {
    super();
  }

  @Input()
  set items(value: any[]) {
    this._items = this.initNewItemsList(value);
    if(this.sorted) {
      this._items = sortBy(this._items, (c): string => { return c[this.itemLabel]; });
    }
  }

  get items(): any[] {
    return this._items || [];
  }

  @Input()
  set disabledItems (value: any[]) {
    this._disabledItems = value;
    this.items.forEach((el) => {
      el[this.disabledSym] = this.isItemDisabled(el);
      if (el[this.disabledSym]) {
        el[this.checkedSym] = this.showCheckedOnDisabled;
      }
    });
  }

  get disabledItems (): any[] {
    return this._disabledItems || [];
  }

  public ngOnInit(): void {
    if (this.code) {
      this.subs.newSub =
        this.codesModel.getCodesForType(this.code).subscribe((codes) => {
          this.items = codes;
        });
    }
  }
  
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if ((changes.items
        && this.selectOnSingle
        && changes.items.currentValue
        && changes.items.currentValue.length === 1)
        || (changes.selectOnSingle
        && changes.selectOnSingle.currentValue
        && this.items.length === 1)) {
        this.setIndividualElement(true, 0);
      }
    }
  }

  public isItemSelected = (item: any): any => {
    if (!this.items.length) { return; }
    let foundItem = _.find(this.items, item);
    if (foundItem && foundItem[this.checkedSym]) {
      return true;
    }
    return false;
  }

  public isItemDisabled = (item: any): boolean => {
    if (!this.disabledItems.length) { return false; }
    let value = this.itemValue ? item[this.itemValue] : item;
    return _.indexOf(this.disabledItems, value) > -1;
  }

  public setIndividualElement(val: boolean, idx: number): void {
    this.items[idx][this.checkedSym] = val;
    this.setModel();
  }

  public setModel (): void {
    let items = _.cloneDeep(this.items);
    // trigger change detection when called from code
    setTimeout(() => {
      this.model =
        items
          .filter((item) => item[this.checkedSym])
          .map((item) => this.stripSymbols(item));
    });
  }

  private stripSymbols(item: any): any {
    delete item[this.checkedSym];
    delete item[this.disabledSym];
    return item;
  }

  private initNewItemsList(list: any[]): any[] {
    let copied = _.cloneDeep(list);
    return copied.map((item, i, a) => {
      item[this.checkedSym] = this.isItemSelected(item);
      item[this.disabledSym] = this.isItemDisabled(item);
      return item;
    });
  }
}
