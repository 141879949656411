import { Directive, ElementRef, OnInit, Input, Renderer2 } from '@angular/core';

/*
This directive is to allow us to easily bind to the src param of an embed component.  If we set the src directly, we will
get an 'unsafe value used in a resource URL context' if we point to a different base url.  binding using the method of
 [src]="sanitizer.bypassSecurityTrustResourceUrl(link)" will result in multiple calls.  Here we want set the src property
 from the embedSrc property to avoid this problem.

 Usage Example:
 <embed [embedSrc]="itemData.myPdfLink" type="application/pdf">

 */

@Directive ({
  selector: 'embed',
})

export class EmbedDirective implements OnInit {

  @Input() embedSrc: string;

  constructor(public el: ElementRef, public renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.renderer.setProperty(this.el.nativeElement, 'src', this.embedSrc);
  }
}
