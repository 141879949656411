
import {of as observableOf, throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { PhysicalLocationGroupLocationApi } from 'cad/common/services/api/physical/location-group/location/location-group-location';
import { ItemApi } from 'src/features/common/item/item-api';

@Injectable()
export class PhysicalLocationGroupApi extends ItemApi {
  private readonly endpoint: string = 'physical/location-groups/group-detail';

  constructor(
    public locationGroupLocation: PhysicalLocationGroupLocationApi,
    private apiHelper: ApiHelper,
  ) {
    super();
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/dashboard');
  }

  public getById = (id: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/' + id);
  }

  public find(params: any): Observable<any> {
    return this.getById(params.code);
  }

  public save(data: any): Observable<any> {
    if (data.code) {
      return this.apiHelper.request(this.endpoint + '/' + data.code, { body: data });
    } else {
      return observableThrowError('Not enough info to save'); // Validation should have taken care of this
    }
  }

  public saveAssociatedLocations = (data: any): Observable<any> => {
    if (data && data[0].pointGroupCd) {
      return this.apiHelper.request(this.endpoint + '/' + data[0].pointGroupCd + '/locations', { body: data });
    } else {
      return observableThrowError('Not enough info to save'); // Validation should have taken care of this
    }
  }

  public deleteItem(item: any): Observable<any> {
    return observableOf({msg:'*** DELTE NOT IMPLEMENTED HERE ***'});
  }
}
