<h1 matDialogTitle>Run as User</h1>
<form (ngSubmit)="runAsSubmit()" #loginForm="ngForm">
  <mat-dialog-content fxLayout="column">
    <div *ngFor="let alert of alerts">{{alert.msg}}</div><!--TODO(ng2): style this as an alert-->
    <mat-form-field>
      <input matInput placeholder="User Name" required name="username" [(ngModel)]="username" #usernameModel="ngModel">
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions fxFlex fxLayoutAlign="space-around" >
    <ui-button option="primary" (click)="runAsSubmit()" type="submit" class="mat-primary" mat-button [disabled]="runAsUser">Run As User</ui-button>
    <ui-button type="button" mat-button (click)="clearRunAs()" >Stop Run As</ui-button>
    <ui-button type="button" mat-button (click)="cancel()" >Cancel</ui-button>
  </mat-dialog-actions>
</form>
