import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import {ApiCache} from 'cad/common/services/api/api-cache';

@Injectable()
export class NominationTransactionTypeApi {
  private readonly endpoint: string = 'nomination/transaction-type';

  constructor(private apiHelper: ApiHelper,
              private apiCache: ApiCache) {
  }

  public findAll = (): Observable<any> => {
    return this.apiHelper.request(this.endpoint);
  }

  public getLookupSettings(): any {
    return {
      FG: (options) => {
        let criteria = {body: options, cache: this.apiCache.forSession, cacheTag: 'tt_fg' };
        return this.apiHelper.request('nomination/nomtransaction-types/filter', criteria);
      },

      defaultGroup: (options) => {
        let criteria = {cache: this.apiCache.forSession, cacheTag: 'tt_default' };
        return this.apiHelper.request('nomination/transaction-type', criteria);
      }
    };
  }
}
