import { Injectable } from '@angular/core';
import { AgreementContractRightsApi } from 'cad/common/services/api/agreement/contract/rights/rights';
import { AgreementRateScheduleApi } from 'cad/common/services/api/agreement/rate-schedule/rate-schedule';

@Injectable()
export class AgreementApi {
  constructor(
    public rightsApi: AgreementContractRightsApi,
    public rateSchedule: AgreementRateScheduleApi,
  ) {}
}
