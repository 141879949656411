import * as _ from 'lodash';
import { ValidationResultModel } from '../../models/validation-result/validation-result-model';
import { Injectable } from '@angular/core';

@Injectable()
export class BaseValidatorService {

  constructor() {
  }

  public required = (property: string, entity: any,  result: ValidationResultModel, propertyDisplayName?: string): ValidationResultModel => {
    return this.validate(property,
      () => entity[property] === undefined || entity[property] === null || entity[property] === '',
      `required property ${propertyDisplayName ? propertyDisplayName : property} is not defined` ,
      result);
  }

  public maxLength = (property: string, maxLength: number, entity: any,  result: ValidationResultModel, propertyDisplayName?: string): ValidationResultModel => {
    return this.validate(property,
      () => typeof entity[property] === 'string' && entity[property].length > maxLength,
      `length of property ${propertyDisplayName ? propertyDisplayName : property} is larger than max length ${maxLength}` ,
      result);
  }

  public lessThan = (lessThan: number, property: string,  entity: any[],  result: ValidationResultModel, propertyDisplayName?: string): ValidationResultModel => {
    return this.validate(property,
      () => +entity[property] > lessThan,
      `value of property ${propertyDisplayName ? propertyDisplayName : property} is larger than ${lessThan}`,
      result);
  }

  public greaterThan = (greaterThan: number, property: string,  entity: any[],  result: ValidationResultModel, propertyDisplayName?: string): ValidationResultModel => {
    return this.validate(property,
      () => +entity[property] <= greaterThan,
      `value of property ${propertyDisplayName ? propertyDisplayName : property} is less than ${greaterThan}`,
      result);
  }

  public greaterThanOrEqual = (greaterThan: number, property: string,  entity: any[],  result: ValidationResultModel, propertyDisplayName?: string): ValidationResultModel => {
    return this.validate(property,
      () => +entity[property] < greaterThan,
      `value of property ${propertyDisplayName ? propertyDisplayName : property} is less than ${greaterThan}`,
      result);
  }

  public isInteger = (property: string, entity: any,  result: ValidationResultModel, propertyDisplayName?: string): ValidationResultModel => {
    return this.validate(property,
      () => typeof entity[property] === 'number' && !Number.isInteger(entity[property]),
      `property ${propertyDisplayName ? propertyDisplayName : property} should be whole number` ,
      result);
  }

  public expressionMatch = (regExp: RegExp, property: string, entity: any[], result: ValidationResultModel, propertyDisplayName?: string): ValidationResultModel => {
    return this.validate(property,
      ()=> entity[property] && entity[property].match && entity[property].match(regExp) ,
      `property ${propertyDisplayName ? propertyDisplayName : property} contains invalid characters`,
      result);
  }

  public checkingNullValues(property: string, entity: object, result: ValidationResultModel): ValidationResultModel {
    return this.validate(property,
      () => entity.hasOwnProperty(property) ? !this.hasAValue(entity[property]) : true,
      `property ${property} has no value`,
      result);
  }

  public hasAValue(value: any) : boolean {
    return value !== null && value !== undefined && value !== '';
  }

  public validate = (property: string = null, validateFunc: () => boolean, errorMessage: string, result: ValidationResultModel ): ValidationResultModel => {
    if ( result.successful === undefined ) {
      result.successful = true;
    }

    if(!result.validationResults) {
      result.validationResults = {};
      result.validationResults[property] = { isValid: true, messages: []};
    } else if(!result.validationResults[property]) {
      result.validationResults[property] = { isValid: true, messages: []};
    }

    const validateFunctionResult = validateFunc();
    if(validateFunctionResult) {
      result.successful = false;
      if(property !== null) {
        result.validationResults[property].isValid = false;
        result.validationResults[property].messages.push(errorMessage);
      } else {
        result.addError(errorMessage);
      }
    }
    return result;
  }


}
