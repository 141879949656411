import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ItemApi } from 'src/features/common/item/item-api';
import {ApiCache} from 'src/cad/common/services/api/api-cache';

@Injectable()
export class GLAccountsApi extends ItemApi {

  private readonly endpoint: string = 'invoices/glaccounts';

  constructor(
    private apiHelper: ApiHelper,
    private apiCache: ApiCache,
  ) {
    super();
  }

  public find(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/find', { body: params });
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/dashboard');
  }

  public save(item: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save', { body: item });
  }

  public deleteItem(item: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/delete', { body: item });
  }

  public rateComponents(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/rate-comp');
  }

  public affiliateBA(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/affiliate-ba');
  }

}
