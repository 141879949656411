import { Injectable } from '@angular/core';

import { SecurityModel } from 'cad/common/models/security/security-model';
import { SecurityActionContextService } from 'cad/core/services/security/security-action-context.service';
import * as _ from 'lodash';

export interface ActionGroupState {
  enabled: boolean;
  visible: boolean;
}

export interface ActionGroup {
  all?: ActionGroupState;
  [s: string]: ActionGroupState;
}

@Injectable({
  providedIn: 'root'
})
export class ActionSecurityService {

  constructor(
    public securityModel: SecurityModel,
    private securityActionContextService: SecurityActionContextService,
  ) {}

  /*
   * Based on a context and group we will return back what can be done.
   * Example pass in businessAssociate and actionButton for a given user this method will return:
   *    'Read Only Role'
   *    businessAssociate.actionbutton.all.disable
   *    businessAssociate.actionbutton.retrieve.enable
   *    'Full Access Role'
   *    businessAssociate.actionbutton.all.enable
   * param context - The represents the area, business area if you will, that will be getting.
   * param group - This will be 'actionbutton', 'CustomerActiviity'
   */
  public getContextGroup(group: any, context?: any): ActionGroup {
    let actionGroup: ActionGroup = {};
    let actionContext: any = context;
    
    if (!actionContext && this.securityActionContextService) {
      actionContext = this.securityActionContextService.getActionContext();
    }
    if (!_.isEmpty(actionContext)) {
      let contextAuthList: any = this.getContext(actionContext);
      
      if (contextAuthList) {
        actionGroup = contextAuthList[ group ];
      } else {
        console.error(`Security Error: '${actionContext}' is not a valid actionContext.${!context
          ? ' Please check SecurityFeature settings in your item module and ensure'
          + ' that you are providing a valid actionContext.'
          : ''}`);
      }
    }
    return actionGroup;
  }

  public isViewable(): boolean {
    let rv = true;

    return rv;
  }

  public isDisabled(): boolean {
    let rv = true;

    return rv;
  }

  private getContext(context: any): any {
    let rv = {};
    let protectionMap = this.securityModel.getAuthList();
    if (!_.isEmpty(protectionMap)) {
      rv = protectionMap[context];
    }
    return rv;
  }
}
