import {
  Component, Input, Output, OnInit, EventEmitter, ElementRef, ViewChild
} from '@angular/core';
import * as _ from 'lodash';

export const SELECT_HEADER_FILTER_ROW_HEIGHT: number = 48;
export const SELECT_HEADER_SELECTION_ROW_HEIGHT: number = 48;

@Component({
  selector: 'ui-select-header-filter',
  templateUrl: './select-header-filter.component.html',
  styleUrls: [ './select-header-filter.component.less' ],
  exportAs: 'uiSelectHeaderFilter',
})
export class UiSelectHeaderFilterComponent implements OnInit {

  @ViewChild('input', { read: ElementRef }) public input: ElementRef;

  @Input() name: string = 'uiSelectHeaderFilter';
  @Input() showSelectionRow: boolean = true;
  @Input() multiple: boolean;
  @Input() startsWith: boolean = false;
  @Input('caseSensitive') isCaseSensitive: boolean = false;
  @Input() valueProperty: string;
  @Input() viewValueProperty: string;
  @Input()
  get optionItems(): any[] {
    return this._optionItems;
  }
  set optionItems(value: any[]) {
    if (!_.isEqual(value, this._optionItems)) {
      if (_.isArray(this._optionItems)) {
        this._optionItems.splice(0);
      }
      this._optionItems = value;
      this.optionItemsChange.emit(this._optionItems);
    }
  }

  @Input()
  get selectionModel(): any {
    return this._selectionModel;
  }
  set selectionModel(value: any) {
    if (!_.isEqual(value, this._selectionModel)) {
      if (_.isArray(this._selectionModel)) {
        this._selectionModel.splice(0);
      }
      this._selectionModel = value;
      if (this.selectionsOnly
        && (_.isNil(this._selectionModel)
        || (_.isArray(this._selectionModel) && this._selectionModel.length === 0))) {
        setTimeout(() => {
          this.selectionsOnly = false;
        });
      }
      this.selectionModelChange.emit(this._selectionModel);
    }
    this.setSelectionText();
  }

  @Input()
  get selectionsOnly(): boolean {
    return this._selectionsOnly;
  }
  set selectionsOnly(value: boolean) {
    this._selectionsOnly = value;
    this.optionItems = this.queryItems(this.headerFilterSearchText);
  }

  @Output() selectionModelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() optionItemsChange: EventEmitter<any> = new EventEmitter<any>();

  public originalItems: any[];
  public selectionText: string;
  public headerFilterRegExp: RegExp = null;
  get headerFilterSearchText(): string {
    return this._headerFilterSearchText;
  }
  set headerFilterSearchText(value: string) {
    this._headerFilterSearchText = value;
    this.optionItems = this.queryItems(this._headerFilterSearchText);
  }
  private _optionItems: any[];
  private _selectionModel: any;
  private _selectionsOnly: boolean;
  private _headerFilterSearchText: string = null;

  constructor() {}

  ngOnInit(): void {
    this.originalItems = _.cloneDeep(this.optionItems);
  }

  setSelectionText(): void {
    if (!this.multiple) {
      let selectedItem: any = this.findItemByViewValue(this.selectionModel);
      this.selectionText = (selectedItem && !_.isNil(selectedItem[ this.valueProperty ]))
        ? `Selected: ${selectedItem[ this.viewValueProperty ]} (${selectedItem[ this.valueProperty ]})`
        : `Selected: [None]`;
    } else {
      this.selectionText = (_.isArray(this.selectionModel)
          ? this.selectionModel.length
          : 0) + ' Selected';
    }
  }

  clearHeaderFilterSearchText(): void {
    this.headerFilterSearchText = null;
  }

  clearSelectionModel(): void {
    if (_.isArray(this.selectionModel)) {
      this.selectionModel.splice(0);
      this.selectionModel = [];
    } else {
      this.selectionModel = undefined;
    }
    if (this.selectionsOnly) {
      this.selectionsOnly = null;
    }
    this.clearHeaderFilterSearchText();
  }

  findItemByViewValue(itemValue: any): any {
    return _.find(this.originalItems, (option: any): boolean => {
      return _.isEqual(option[ this.valueProperty ], itemValue);
    });
  }

  queryItems(query: string): any[] {
    let queryArray: any[] = this.selectionsOnly ? this.getSelectedItems() : this.originalItems;
    let returnList: any[];
    if (!_.isUndefined(query) && _.isArray(queryArray)) {
      this.headerFilterRegExp = RegExp((this.startsWith ? '^' : '') + this.escRegExp(query || ''), (this.isCaseSensitive ? '' : 'i'));
      returnList = queryArray.filter((item: any): boolean => {
        if (this.headerFilterRegExp.test(item[ this.valueProperty ])) {
          return this.headerFilterRegExp.test(item[ this.valueProperty ]);
        }
        return this.headerFilterRegExp.test(item[ this.viewValueProperty ]);
      });
    } else {
      returnList = queryArray;
    }
    return _.isArray(returnList) ? returnList : [];
  }

  public getSelectedItems(): any[] {
    return (_.isArray(this.selectionModel))
      ? this.originalItems.filter((item: any): boolean => {
        return this.selectionModel.indexOf(item[ this.valueProperty ]) > -1;
      })
      : [];
  }

  private escRegExp(stringValue: string): string {
    return stringValue.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
  }
}
