import { Injector } from '@angular/core';
import { CacheService } from 'ng2-cache';
import { CacheStoragesEnum } from 'ng2-cache/src/enums/cache-storages.enum';

export class CacheFactory {

  private setOptions: any = {};

  public static createCache(name: string, options?: any): CacheFactory {
    let injector = Injector.create([ {
      provide: CacheService,
      useClass: CacheService,
      deps: [ ]
    } ]);
    let cacheService = injector.get(CacheService);
    return new CacheFactory(name, options, cacheService);
  }

  /*
  * constructor is for internal use. please use static createCache() function externally.
  */
  constructor(
    public name: string,
    private options: any,
    private cacheService: CacheService) {
    if (!this.options) {
      this.options = {};
    }

    if (this.options.storageMode && this.options.storageMode === 'sessionStorage') {
      this.cacheService = this.cacheService.useStorage(CacheStoragesEnum.SESSION_STORAGE);
    } else if (this.options.storageMode && this.options.storageMode === 'localStorage') {
      this.cacheService = this.cacheService.useStorage(CacheStoragesEnum.LOCAL_STORAGE);
    } else {
      this.cacheService = this.cacheService.useStorage(CacheStoragesEnum.MEMORY);
    }

    if (this.options.maxAge) {
      this.setOptions.maxAge = this.options.maxAge;
    }

    this.cacheService.setGlobalPrefix(name);
  }

  public set (key: string, data: any, tag?: string): boolean {
    return this.cacheService.set(key, data, { tag });
  }

  public put = (key: string, data: any, tag?: string): boolean => {
    let options = _.clone(this.setOptions);
    if (tag) {
      options.tag = tag;
    }
    return this.cacheService.set(key, data, options);
  }

  public get = (key: any): any => {
    return this.cacheService.get(key);
  }

  public remove = (key: any): void => {
    this.cacheService.remove(key);
  }

  public removeTag = (tag: string): void => {
    this.cacheService.removeTag(tag);
  }
}
