<ui-card [headline]="headline$|async" [cardToolBarItems]="menuItems$|async" [isExpanded]="true">
  <div *ngIf="itemData$|async;let itemData;">
    <ui-table *ngIf="itemData.length > 0"  (rowClicked)="onRowClicked($event)" [data]="itemData" fxLayout="column">
      <ui-table-column name="baAbbrName" headerName="BA Abbr"></ui-table-column>
      <ui-table-column name="baName" headerName="BA Name"></ui-table-column>
      <ui-table-column name="dunsNbr" headerName="DUNS"></ui-table-column>
    </ui-table>
  </div>
</ui-card>

