import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ItemApi } from 'src/features/common/item/item-api';

@Injectable()
export class ComponentApi implements ItemApi {

  public readonly endpoint: string = 'product/component';

  constructor(
    private apiHelper: ApiHelper
  ) {}

  public find(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/find`, { body: params });
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/filter`, { body: params });
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/recent`);
  }

  public save(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/save`, { body: params });
  }

  public deleteItem(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/delete`, { body: params });
  }
}
