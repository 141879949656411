import { NgModule } from '@angular/core';
import { SharedModule } from 'cad/shared/shared.module';
import { DashboardComponentsModule } from 'cad/dashboard/views/content/components/dashboard-components.module';
import { DashboardContentComponent } from './views/content/dashboard-content.component';
import { SelectDashDialogComponent } from './views/content/select-dash-dialog.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    SharedModule,
    DashboardComponentsModule,
    CommonModule,
    RouterModule.forChild([ { path: '', component: DashboardContentComponent } ]),
  ],
  declarations: [
    SelectDashDialogComponent,
    DashboardContentComponent
  ],
  entryComponents: [ SelectDashDialogComponent ]
})
export class DashboardModule {}
