import { tap, map, debounceTime, startWith } from 'rxjs/operators';
import { Component, Input, ViewChild, ViewChildren, QueryList, AfterViewInit, NgZone } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Observable } from 'rxjs';
import { AutoUnsubscriber, AutoUnsubscribables } from 'cad/shared/mixins/auto-unsubscriber.mixin';
import { UiVirtualRepeatComponent } from 'src/common/components/virtual-repeat/virtual-repeat.component';
import { MatAutocomplete, MatAutocompleteTrigger, AUTOCOMPLETE_PANEL_HEIGHT, AUTOCOMPLETE_OPTION_HEIGHT } from '@angular/material/autocomplete';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'ui-autocomplete-menu',
  templateUrl: './autocomplete-menu.component.html',
  styleUrls: [ 'autocomplete-menu.component.less' ],
})
export class AutocompleteMenuComponent implements AfterViewInit {
  @Input() public requiredValue: boolean = false;
  @Input() public searchByWord: boolean = false;
  @Input() public items: any;
  @Input() public buttonLabel: any;
  @Input() public onItemSelected: Function;
  @Input()
  get selectedItem(): any {
    return this._selectedItem;
  }
  set selectedItem(value: any) {
    this._selectedItem = value;
    if (!_.isEqual(this._selectedItem, this.searchText) && !_.isNil(this._selectedItem)) {
      this.searchText = this._selectedItem;
      this.closeAutoSelect = true;
    }
  }
  @ViewChild('ngModel') ngModelRef: NgModel;
  @ViewChild(MatAutocomplete) mdAutocompleteRef: MatAutocomplete;
  @ViewChild(UiVirtualRepeatComponent) uiVirtualRepeatRef: UiVirtualRepeatComponent;
  @ViewChildren(MatOption) options: QueryList<MatOption>;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  public searchText: string = '';
  private closeAutoSelect: boolean = false;
  public virtualItems: any[] = [];
  toHighlightText: string = '';
  public filteredItems: Observable<any[]>;
  public readonly AUTOCOMPLETE_OPTION_HEIGHT: number = AUTOCOMPLETE_OPTION_HEIGHT;
  public readonly AUTOCOMPLETE_PANEL_HEIGHT: number = AUTOCOMPLETE_PANEL_HEIGHT;
  public readonly ITEMS_TO_BUFFER: number = 2;
  private _selectedItem: any = '';
  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  constructor(private ngZone: NgZone) {}

  ngAfterViewInit(): void {
    this.filteredItems = this.ngModelRef.valueChanges.pipe(
      startWith(null),
      debounceTime(500),
      map((value: any) => {
        return value ? this.filterItems(value) : this.items.slice();
      }),
      tap(() => {
        if (this.uiVirtualRepeatRef) {
          setTimeout(() => {
            this.uiVirtualRepeatRef.refresh(false);
          });
        }
      }));
    if (this.mdAutocompleteRef) {
      this.mdAutocompleteRef.options = this.options;
      this.mdAutocompleteRef.ngAfterContentInit();
    }
    if (this.options) {
      this.subs.newSub = this.options.changes.pipe(startWith(null)).subscribe(() => {
        // Defer setting the value in order to avoid the "Expression
        // has changed after it was checked" errors from Angular.
        Promise.resolve().then(() => {
          this.setSelectionByValue(this.selectedItem);
        });
      });
    }
  }

  public filterItems = (searchText: string): any => {
    this.toHighlightText = searchText;
    /* tslint:disable:no-param-reassign */
    searchText = searchText.toLowerCase();
    /* tslint:enable:no-param-reassign */

    return _.filter(this.items, (item: string) => {
      if (this.searchByWord) {
        return item.toLowerCase().includes(searchText);
      } else {
        return _.startsWith(item.toLowerCase(), searchText);
      }
    });
  }

  public onPanelOpenChange = (isOpen: boolean): void => {
    this.setSelectionByValue(this.selectedItem);
    if (isOpen && this.uiVirtualRepeatRef) {
      this.uiVirtualRepeatRef.refresh(false);
    }
    if (this.closeAutoSelect) {
      this.autocomplete.closePanel();
      this.closeAutoSelect = false;
    }
  }

  public onSelectionChanged = (changeObj: MatOptionSelectionChange): void => {
    if (changeObj.isUserInput) {
      this.onItemSelected(changeObj.source.value);
    }
  }

  public getOptionByValue(value: any): MatOption {
    if (this.options) {
      return this.options.find((option: MatOption) => {
        return _.isEqual(_.toLower(option.value), _.toLower(value));
      });
    }
    return undefined;
  }

  public setOptionActive(option: MatOption): void {
    if (option && this.options) {
      if (!option.active
        && this.mdAutocompleteRef
        && this.mdAutocompleteRef._keyManager) {
        this.mdAutocompleteRef._keyManager.setActiveItem(this.options.toArray().indexOf(option));
      }
    }
  }

  public setSelectionByValue(value: any): void {
    const correspondingOption = this.getOptionByValue(value);
    if (correspondingOption) {
      (correspondingOption as any)._selected = true;
      this.ngZone.run(() => {
        this.setOptionActive(correspondingOption);
      });
    }
  }

  public valueChange($event: any): void {
    if ($event.target.value === '') {
      this.toHighlightText = '';
    }
  }
}
