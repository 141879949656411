import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toDate' })
export class ToDatePipe implements PipeTransform {
  transform(stringDate: string, args: string[]): any {
    return (moment(stringDate) as any)._d;
  }
}

@Pipe({ name: 'toFormattedDate' })
export class ToFormattedDatePipe implements PipeTransform {
  transform(stringDate: string, dateFormat: string): any {
    return moment(stringDate).format(dateFormat || 'MM/DD/YY');
  }
}

@Pipe({ name: 'toDateTimeLocal' })
export class ToFormattedDateTimeLocalPipe implements PipeTransform {
  transform(stringDate: string): any {
    if (stringDate) {
      return moment(stringDate).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return stringDate;
    }
  }
}
