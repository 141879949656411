import {
  Directive, OnChanges, ElementRef, Input, SimpleChanges, NgZone, Injector, ContentChildren, QueryList, AfterViewInit,
  Optional, Host, OnInit,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { UiDatepickerComponent } from 'src/common/components/form/datepicker/datepicker.component';
import { AutoUnsubscribables, AutoUnsubscriber } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';


const elementsToDisable: string[] = [
  'mat-form-field input.mat-input-element',
  'button',
  'mat-checkbox',
  'mat-radio-group',
  'mat-datepicker',
  'ui-select',
  'mat-select',
  'floating-mat-fab',
  'ui-datepicker',
];

const elementsToHide: string[] = [
  'mat-fab-speed-dial',
  'ui-float-button',
];

@Directive({
  selector: 'form[uiDisable], ui-form[uiDisable], ui-card[uiDisable], mat-card[uiDisable]',
})
export class UiDisableDirective implements OnChanges {
  @AutoUnsubscriber() private subs: AutoUnsubscribables;
  @Input('uiDisable') private isDisabled: boolean = true;
  public ngFormRef: NgForm;
  constructor(
    private host: ElementRef,
    public ngZone: NgZone,
    private injector: Injector,
  ) {
    if(host.nativeElement.name === 'form') {
      this.ngFormRef = this.injector.get(NgForm);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDisabled) {
      if (changes.isDisabled.currentValue) {
        this.disableAll();
        this.hideAll();
      }
    }
  }

  private hideAll(): void {
    elementsToHide.forEach((elTagName) => {
      this.hideElements(this.host.nativeElement.querySelectorAll(elTagName));
    });
  }

  /**
   * Disables everything in the given element.
   *
   * @param {HTMLElement} element
   */
  private disableAll(): void {
    elementsToDisable.forEach((elTagName) => {
      this.disableElements(this.host.nativeElement.querySelectorAll(elTagName));
    });
    // Cut Zone portion of this at least
    this.runOnStableZone(() => {
      if (this.ngFormRef && this.ngFormRef.control) {
        let ctrls = this.ngFormRef.control.controls || {};
        Object.keys(ctrls).map((name) => ctrls[name].disable());
      }
    });
  }

  /**
   * Enables everything in the given element.
   *
   * @param {HTMLElement} element
   */
  private enableAll(): void {
    elementsToDisable.forEach((elTagName) => {
      this.enableElements(this.host.nativeElement.getElementsByTagName(elTagName));
    });
  }

  /**
   * Callback used to prevent user clicks.
   *
   * @param {Event} event
   * @returns {boolean}
   */
  private preventDefault(event: any): boolean {
    for (let atts of event.target.attributes) {
      if (atts.name === 'skip-disable') {
        return true;
      }
    }

    event.stopPropagation();
    event.preventDefault();

    return false;
  }

  /**
   * Disables given elements.
   *
   * @param {Array.<HTMLElement>|NodeList} elements List of dom elements that must be disabled
   */
  private disableElements(elements: NodeListOf<Element>): void {

    this.forNotSkipped(elements, (element) => {
      if( element.localName === 'ui-table') {
        // let card: AssociationComponent = this.injector.get(AssociationComponent);
        // this.table = card.injector.get(TableComponent);
        // this.table.disableEdit = true;
      } else if(!element.hasAttribute('skip-disable')) {
        (element as any).disabled = true;
        element.setAttribute('disabled', 'disabled');
        element.setAttribute('aria-disabled', 'true');
      }
    });
  }

  private hideElements(elements: NodeListOf<Element>): void {
    this.forNotSkipped(elements, (element: HTMLElement) => {
      element.style.display = 'none';
    });
  }

  /**
   * Enables given elements.
   *
   * @param {Array.<HTMLElement>|NodeList} elements List of dom elements that must be enabled
   */
  private enableElements(elements: NodeListOf<Element>): void {
    let els = Array.prototype.slice.call(elements);

    els.forEach((element) => {
      this.enableElement(element);

    });
  }
  /**
   * @description Ensures that the fn is invoked when angular zone stabilizes
   * @public
   * @param fn
   */
  // This should be able to be dropped
  private runOnStableZone(fn: Function): void {
    const subscription: any = this.ngZone.onStable.subscribe(() => {
      fn();
      subscription.unsubscribe();
    });
  }
  private forNotSkipped(elements: NodeListOf<Element>, callback: (el: Element) => void): void {
    /* tslint:disable:prefer-for-of */
    let len = elements.length;

    for (let i = 0; i < len; i++) {
      let shouldDisable = true;

      for (let j = 0; j < elements[i].attributes.length; j++) {
        let atts = elements[i].attributes[j];

        if (atts.name === 'skip-disable') {
          shouldDisable = false;
          this.skipChildren(elements[i].childNodes);
          continue;
        }
      }

      if (shouldDisable) {
        callback(elements[i]);
      }
    }
    /* tslint:enable:prefer-for-of */
  }
  private skipChildren(elements: NodeList): void {
    let els = Array.prototype.slice.call(elements);
    els.forEach((element) => {
      if(element.hasAttribute) {
        element.setAttribute('skip-disable', 'true');
        this.enableElement  (element);
      }
      if(element.hasChildNodes()) {
        this.skipChildren(element.childNodes);
      }
    });
  }

  private enableElement(element: Element): void {
    (element as any).disabled = false;
    element.removeAttribute('disabled');
    element.removeAttribute('aria-disabled');
  }
}
