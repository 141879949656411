import { Injectable } from '@angular/core';
import { NomBaseDatesUtil } from 'src/cad/nominations/common/utils/nom-base-date/nom-base-date';

@Injectable()
export class FutureNomDateUtil {
  constructor() {
    return FutureNomDateFactory;
  }
}

class FutureNomDateFactory extends NomBaseDatesUtil {
  constructor(private c: any) {
    super();
    this.ctrl = c;
  }

  public getDefaultStartDate = (): string => {
    return moment().add(2, 'month').startOf('month').startOf('day').format();
  }

  public searchBeginDateChanged = (ctrl?: any): any => {
    let obj = this.ctrl ? this.ctrl : ctrl;
    return (sDate: any, oldVal: any): void => {
      /* tslint:disable:no-param-reassign */
      if (oldVal) {
        oldVal = moment(oldVal);
      }
      if (sDate) {
        sDate = moment(sDate);
      }
      /* tslint:enable:no-param-reassign */

      if (sDate && oldVal && !sDate.isSame(oldVal) && obj.searchEndDate) {
        let startDate = sDate.startOf('day');
        let endDate = moment(obj.searchEndDate).startOf('day');
        let defaultStartDate = moment(this.getDefaultStartDate());

        if (startDate.isBefore(defaultStartDate)) {
          obj.searchBeginDate = defaultStartDate.format();
        } else if (startDate.isAfter(endDate)) {
          obj.searchEndDate = startDate.endOf('month').format();
        } else {
          obj.searchEndDate = startDate.endOf('month').startOf('day').format();
        }
        this.setStateParamDates(obj);
      }
    };
  }

  public searchEndDateChanged = (ctrl?: any): any => {
    let obj = this.ctrl ? this.ctrl : ctrl;
    return (eDate: any, oldVal: any): void => {
      /* tslint:disable:no-param-reassign */
      if (oldVal) {
        oldVal = moment(oldVal);
      }
      if (eDate) {
        eDate = moment(eDate);
      }
      /* tslint:enable:no-param-reassign */

      if (eDate && oldVal && !eDate.isSame(oldVal) && obj.searchBeginDate) {
        let startDate = moment(obj.searchBeginDate).startOf('day');
        let endDate = eDate.startOf('day');
        let defaultStartDate = moment(this.getDefaultStartDate());

        if (endDate.isBefore(defaultStartDate)) {
          obj.searchBeginDate = defaultStartDate.format();
          obj.searchEndDate = defaultStartDate.format();
        } else if (endDate.isBefore(startDate)) {
          obj.searchBeginDate = endDate.startOf('month').startOf('day').format();
        } else if (startDate.month() < endDate.month() || startDate.year() < endDate.year()) {
          obj.searchBeginDate = endDate.startOf('month').startOf('day').format();
        }

        this.setStateParamDates(obj);
      }
    };
  }

}
