
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import {ApiCache} from 'cad/common/services/api/api-cache';
import { map, take } from 'rxjs/operators';
import {ApiRequestOptionsArgs} from 'cad/common/services/api/interfaces/api-request-options-args';

@Injectable()
export class NominationMatrixContractActivityCodeApi {

  private readonly endpoint: string = 'nomination/nomination-matrix/contract/activity-code';

  constructor(
    private apiHelper: ApiHelper, private apiCache: ApiCache,
  ) {}

  public findAll = (id: string | number, invalidateCache: boolean = false): Observable<any> => {
    const options: ApiRequestOptionsArgs = invalidateCache?
      { invalidateCache, cache: this.apiCache.forSession, cacheTag: 'activityCodes'+id }
      : { cache: this.apiCache.forSession, cacheTag: 'activityCodes'+id };
    return this.apiHelper.requestQuiet(this.endpoint + '/filter/' + id,
      options);
  }


  public filter = (params?: any): Observable<any> => {
    let criteria = {
      assetNbr: params.assetNbr,
      contractId: params.contractId,
      pathType: params.pathType
    };
    return this.findAll(criteria.contractId).pipe(
      map((activities) => {
        return activities.filter((act) => act.pathType === params.pathType);
      }));
  }

  public find(contractId: string | number, activityId: string | number) : Observable<any> {
    if(contractId && activityId) {
      return this.apiHelper.request(this.endpoint + '/find/' + contractId + '/' + activityId );
    }
    return observableOf({});

  }

}
