import {
  Component, Input, OnInit, ViewChild, Injector
} from '@angular/core';
import { ConfigService } from 'src/cad/core/services/config/config.service';
import { RouterService } from 'src/cad/core/services/router/router.service';
import { HeaderToolbarMenuComponent } from '../header-toolbar-menu/header-toolbar-menu.component';

@Component({
  selector: 'ui-menu-item',
  templateUrl: './header-toolbar-menu-item.component.html',
  styleUrls: [ './header-toolbar-menu-item.component.less' ],
})
export class HeaderToolbarMenuItemComponent implements OnInit {

  @Input() public item: any;
  @ViewChild('childMenu', { static: true }) public childMenu: HeaderToolbarMenuItemComponent;

  public shouldShow: boolean = true;
  private parentMenuRef: HeaderToolbarMenuComponent;

  constructor(
    private injector: Injector,
    private router: RouterService,
    private configService: ConfigService,
  ) {
    this.parentMenuRef = this.injector.get(HeaderToolbarMenuComponent);
  }

  public ngOnInit(): void {
    if (this.configService.cadConfig.hideMenus) {
      for (let hideMenu of this.configService.cadConfig.hideMenus) {
        this.item.find((menuItem) => {
          if(menuItem.label === hideMenu) {
            menuItem.visible = false;
            menuItem.enabled = false;
          }
        });
      }
    }
  }

  public navigate(src:any): void {
    if (!src.children) {
      this.router.navigate([ src.state ]);
    }
  }

  public hasEnabledChildren(item: any): boolean {
    if (!item.children) {
      return false;
    }
    for (let child of item.children) {
      if (child.visible) {
        return true;
      }
    }
    return false;
  }
}
