<ui-card [headline]="headline$|async" [cardToolBarItems]="menuItems$|async" [isExpanded]="true">
  <div *ngIf="itemData$|async;let itemData;">
    <ui-table *ngIf="itemData.length > 0" (rowClicked)="onRowClicked($event)" [data]="itemData" fxLayout="column">
      <ui-table-column name="addrLine1" headerName="Address Line 1"></ui-table-column>
      <ui-table-column name="addrLine2" headerName="Address Line 2"></ui-table-column>
      <ui-table-column name="addrLine3" headerName="Address Line 3"></ui-table-column>
    </ui-table>
  </div>
</ui-card>

