import { Injectable } from '@angular/core';
import { NomBaseDatesUtil } from 'src/cad/nominations/common/utils/nom-base-date/nom-base-date';

@Injectable()
export class SchedQtyDateUtil {
  constructor() {
    return SchedQtyDateFactory;
  }
}

class SchedQtyDateFactory extends NomBaseDatesUtil {
  constructor(private c: any) {
    super();
    this.ctrl = c;
  }

  public setDefaultDates = (ctrl?: any): void => {
    let obj = this.ctrl ? this.ctrl : ctrl;
    if (!obj.searchBeginDate || !obj.searchEndDate) {
      _.assign(obj, {
        searchBeginDate: this.getDefaultStartDate(),
        searchEndDate: this.getDefaultStartDate(),
      });
    }
  }

  public getDefaultStartDate = (): string => {
    return moment().startOf('day').format();
  }

  public searchBeginDateChanged = (ctrl?: any): any => {
    let obj = this.ctrl ? this.ctrl : ctrl;
    return (sDate: any, oldVal?: any): void => {
      if (sDate) {
        obj.searchEndDate = moment(sDate).format();
        this.setStateParamDates(obj);
      }
    };
  }

  public searchEndDateChanged = (ctrl?: any): any => {
    let obj = this.ctrl ? this.ctrl : ctrl;
    return (eDate?: any, oldVal?: any): void => {
      this.setStateParamDates(obj);
    };
  }

}
