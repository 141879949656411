import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AssociateBusinessAssociateContactExternalApi } from 'cad/common/services/api/associate/business-associate/contact/external/associate-business-associate-contact-external.api';
import { AssociateBusinessAssociateContactInternalApi } from 'cad/common/services/api/associate/business-associate/contact/internal/associate-business-associate-contact-internal.api';

@Injectable()
export class BaContact {

  public endpoint: string = 'associate/business-associate/';
  public suffix: string = '/contact';

  constructor(
    public internal: AssociateBusinessAssociateContactInternalApi,
    public exnternal: AssociateBusinessAssociateContactExternalApi,
    private apiHelper: ApiHelper,
  ) {}

  // ========================================
  // contacts
  // ========================================

  public save = (baContacts: any[]): Observable<any> => {
    return this.apiHelper.request(this.endpoint + baContacts[0].baId + this.suffix, { body: baContacts });
  }

  public findAll = (id: string | number): Observable<any> => {
    return this.apiHelper.request(this.endpoint + id + this.suffix);
  }
}
