<ui-form [skipDisable]="true"(onSave)="submit()">
  <ui-modal-header (close)="cancel()">{{controller.model.title}}</ui-modal-header>
  <ui-loading-bar class="search-loading-bar"></ui-loading-bar>
  <ui-modal-body *ngIf="controller.model.type === 'form'">
    <ng-template [framingComponentOutlet]="controller.view.modalBody" [framingComponentInjector]="modalInjector" (onComponent)="onComponent($event)"></ng-template>
  </ui-modal-body>

  <ui-modal-body *ngIf="controller.model.type === 'basic'">
    <p [innerHTML]="controller?.view?.modalBody"></p>
  </ui-modal-body>

  <ui-modal-body *ngIf="controller.model.type === 'lookup'">
    <dynamic-lookup
      [inputs]="controller.model.lookupInputs"
      [controller]="controller"
      [instanceType]="instanceType"
      [defaultSearchParams]="defaultSearchParams"
      [constSearchParams]="constSearchParams">
    </dynamic-lookup>
  </ui-modal-body>

  <ui-modal-footer>
    <button *ngIf="controller.model.cancelButton" mat-button mat-raised-button (click)="cancel()" type="button" skip-disable>{{controller.model.cancelButtonText}}</button>
    <button *ngIf="controller.model.type !== 'basic'" mat-button mat-raised-button type="submit" color="primary" skip-disable>{{controller.model.okButtonText}}</button>
    <button *ngIf="controller.model.type === 'basic'" mat-button mat-raised-button type="button" color="primary" (click)="submit()" skip-disable>{{controller.model.okButtonText}}</button>
  </ui-modal-footer>
</ui-form>
