<div class="ui-option-detail-wrapper"
     [class.ui-option-detail-expanded]="isExpanded()">
  <div class="ui-option-detail-row"
       [ngClass]="{
         'ui-option-selected': matOptionRef?.selected,
         'ui-option-disabled': matOptionRef?.disabled,
         'ui-option-active': matOptionRef?.active
       }"
       fxLayout="row"
       fxLayoutAlign="start center">
    <ng-content></ng-content>
    <button class="ui-option-detail-expand-button"
            type="button"
            mat-button
            mat-icon-button
            [matTooltip]="isExpanded() ? 'Collapse Details' : 'Expand Details'"
            matTooltipPosition="right"
            disableRipple="true"
            (click)="toggleExpanded()">
      <mat-icon>{{isExpanded() ? 'expand_less' : 'expand_more'}}</mat-icon>
    </button>
  </div>
  <div class="ui-option-detail-container">
    <div class="ui-option-detail-content mat-elevation-z3"
         *ngIf="isExpanded()"
         [@expandCollapseTrigger]="'expanded'"
         (@expandCollapseTrigger.done)="onExpansionDone()">
      <table class="mat-table">
        <thead class="mat-head">
        <tr class="mat-row">
          <th class="mat-column">Property Name</th>
          <th class="mat-column">Property Value</th>
        </tr>
        </thead>
        <tbody class="mat-body">
        <tr class="mat-row"
            *ngFor="let itemProp of itemProperties">
          <td class="mat-cell">{{itemProp}}</td>
          <td class="mat-cell">{{optionItem[itemProp]}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
