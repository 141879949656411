import { ChangeDetectorRef, Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PendingInterceptor } from 'common/services/http-interceptor/pending-interceptor';
import * as _ from 'lodash';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';

@Component({
  selector: 'ui-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: [ './loading-bar.component.less' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingBarComponent implements OnInit, OnDestroy {
  @Input()
  public filteredUrlPatterns: string[] = [ 'login', 'index.html' ];

  public mode: string = 'indeterminate';
  public value: number = 0;
  public showBar: boolean = false;

  private subscription: Subscription = new Subscription();
  counter: number = 0;

  constructor(
    private pendingRequestInterceptorService: PendingInterceptor,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(this.pendingRequestInterceptorService
      .pendingRequestsStatus
      .subscribe((val:any) => {
        this.showBar = val;
        this.cdr.detectChanges();
      })
    );
    this.subscription.add(
      this.router.events.subscribe((event) => {
        switch(event.constructor) {
          case RouteConfigLoadStart: {
            this.counter++;
            break;
          }
          case RouteConfigLoadEnd: {
            this.counter--;
            break;
          }
        }
        this.showBar = !!this.counter;
        this.cdr.detectChanges();
      })
    );
    if (!(this.filteredUrlPatterns instanceof Array)) {
      throw new TypeError('`filteredUrlPatterns` must be an array.');
    }

    if (this.filteredUrlPatterns.length) {
      this.filteredUrlPatterns.forEach((e) => {
        let regExpVal = new RegExp(e);
        if (!_.find(this.pendingRequestInterceptorService.filteredUrlPatterns, (val: any) => _.isEqual(val, regExpVal))) {
          this.pendingRequestInterceptorService.filteredUrlPatterns.push(regExpVal);
        }
      });
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
