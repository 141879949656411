import { Injectable, Injector } from '@angular/core';
import { UploadApiBase } from './upload-api-base';
import { Observable } from 'rxjs';
import { ServiceCompntMatrixData } from 'src/cad/structured-products/shared/interfaces/service-compnt-matrix-data';

@Injectable()
export class WaterApi extends UploadApiBase {
  constructor(injector: Injector) {
    super(injector, 'water');
  }
}

@Injectable()
export class LiquidTransportApi extends UploadApiBase {
  constructor(injector: Injector) {
    super(injector, 'liquid-transport');
  }
}

@Injectable()
export class LiquidSeperationApi extends UploadApiBase {
  constructor(injector: Injector) {
    super(injector, 'liquid-seperation');
  }
}

@Injectable()
export class RetrogradeAggregationApi extends UploadApiBase {
  constructor(injector: Injector) {
    super(injector, 'retrograde-aggregation');
  }
}

@Injectable()
export class RetrogradeSeperationApi extends UploadApiBase {
  constructor(injector: Injector) {
    super(injector, 'retrograde-seperation');
  }
}

@Injectable()
export class BasicSedimentWaterApi extends UploadApiBase {
  constructor(injector: Injector) {
    super(injector, 'basic-sediment-water');
  }
}

@Injectable()
export class PTPMatrixRateExceptionApi extends UploadApiBase {
  constructor(injector: Injector) {
    super(injector, 'product/ptp-matrix-rate-exception');
  }
}

@Injectable()
export class UploadPtrDataDetailApi extends UploadApiBase {
  constructor(injector: Injector) {
    super(injector, 'allocation/upload-ptr-data-detail');
  }
}

@Injectable()
export class ZoneToZoneMatrixRateApi extends UploadApiBase {
  constructor(injector: Injector) {
    super(injector, 'product/zone-to-zone-matrix-rate');
  }
  
  public filter(filterData: ServiceCompntMatrixData): Observable<ServiceCompntMatrixData[]> {
    return this.apiHelper.request(`${this.endpoint}/getRateComponents`, { body: filterData });
  }
}

@Injectable()
export class ReportBatchContractApi extends UploadApiBase {
  constructor(injector: Injector) {
    super(injector, 'reporting/report-batch-contract');
  }
}

@Injectable()
export class ReportBatchLocationApi extends UploadApiBase {
  constructor(injector: Injector) {
    super(injector, 'reporting/report-batch-location');
  }
}

@Injectable()
export class ReportBatchRateScheduleApi extends UploadApiBase {
  constructor(injector: Injector) {
    super(injector, 'reporting/report-batch-rate-schedule');
  }
}

@Injectable()
export class ReportBatchServiceApi extends UploadApiBase {
  constructor(injector: Injector) {
    super(injector, 'reporting/report-batch-service');
  }
}

@Injectable()
export class ReportBatchSegmentApi extends UploadApiBase {
  constructor(injector: Injector) {
    super(injector, 'reporting/report-batch-segment');
  }

  segmentReport(): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/segment-report`);
  }

  segmentName(name: string): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/segment?name=${name}`);
  }
}
