import { EmitterService } from 'cad/core/services/emitter/emitter.service';
import { Injectable, EventEmitter } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { SecurityModel } from 'src/features/common/security/security.model';
import { RouterService } from 'src/cad/core/services/router/router.service';

import * as _ from 'lodash';

@Injectable()
export class SecurityActionContextService {

  private actionContext: string;

  private subscriptions: Subscription[] = [];

  public getActionContext(): string { return this.actionContext; }

  constructor(
    private router: RouterService,
    private emitterService: EmitterService,
  ) {
    this.subscriptions.push(this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.onNavigationEnd();
      }
    }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private onNavigationEnd(): void {
    this.actionContext = undefined;
    this.resolveActionContext(this.router.routerState.snapshot.root);
    this.emitterService.actionContextResolved.next(true);
  }

  private resolveActionContext(snapshot: ActivatedRouteSnapshot, lastCollectedFrom?: SecurityModel): void {
    if (snapshot.data && (snapshot.data as any).SecurityModel) {
      const securityModel = (snapshot.data as any).SecurityModel as SecurityModel;
      if (securityModel.actionContext && lastCollectedFrom !== securityModel) {
        if (!this.actionContext) {
          /* tslint:disable:no-param-reassign */
          lastCollectedFrom = securityModel;
          /* tslint:enable:no-param-reassign */
          this.actionContext = securityModel.actionContext;
          // console.log(`Action context set to ${this.actionContext}`);
        } else {
          console.error('Multiple security action contexts set in route');
          this.actionContext = undefined;
          return;
        }
      }
    }
    for (let child of snapshot.children) {
      this.resolveActionContext(child, lastCollectedFrom);
    }
  }
}
