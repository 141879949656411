import { Injector, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AssociationController } from 'src/features/common/association/association.controller';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AssociationApi } from 'src/features/common/association/association-api';

@Injectable()
export class PhysicalSegmentCapacityApi implements AssociationApi {
  private readonly endpoint: string = 'physical/segment/';
  private readonly suffix: string = '/capacity';

  static createAssociationApi(
    apiHelper: ApiHelper,
    associationController: AssociationController,
    injector: Injector,
  ): AssociationApi {
    return new PhysicalSegmentCapacityApi(apiHelper);
  }

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public findAll = (code: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + code + this.suffix);
  }

  public save = (itemData: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + itemData[0].code + this.suffix + '/save', { body: itemData });
  }
}
