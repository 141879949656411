import { Injectable } from '@angular/core';

@Injectable()
export class CodeType {
  public USER_CREDIT_TYPE: string = 'USERCREDITTYPE';
  public CONTRACT_STATUS: string = 'PRTFCONTRACTSTATUS';
  public CREDIT_SOURCE: string = 'CREDITSOURCE';
  public INVOICE_METHOD: string = 'INVOICECOMMTYPE';
  public NOTIFICATION: string = 'NOTIFICATIONTYPE';
  public POINT_INTERNAL_ROLES: string = 'POINTINTERNALROLE';
  public POINT_EXTERNAL_ROLES: string = 'POINTEXTERNALROLE';
  public POINT_CONTACT_REPORT_ROUTING: string = 'REPORTROUTING';
  public INVOICE_MESSAGE_TYPE: string = 'INVMSGTYPE';

  public allCodes: string[] = [
    this.USER_CREDIT_TYPE,
    this.CREDIT_SOURCE,
    this.CONTRACT_STATUS,
    this.INVOICE_METHOD,
    this.NOTIFICATION,
    this.POINT_INTERNAL_ROLES,
    this.POINT_EXTERNAL_ROLES,
    this.POINT_CONTACT_REPORT_ROUTING,
    this.INVOICE_MESSAGE_TYPE
  ];
}
