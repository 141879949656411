import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ItemApi } from 'src/cad/common/components/items/item/item-api';

@Injectable()
export class ReportBatchApi implements ItemApi {
  public endpoint: string = 'reporting/report-batch';
 
  constructor(private apiHelper: ApiHelper) {}

  save(item: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/save`, { body: item });
  }

  deleteItem(item: any): Observable<any> {
    throw new Error('Method not implemented.');
  }

  filter(params: Partial<any>): Observable<any[]> {
    return this.apiHelper.request(`${this.endpoint}/filter`, { body: params });
  }

  find(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/find`, { body: params });
  }

  recent(): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/recent`);
  }

  locationOperatorLookup(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/location-operator-lookup`, { body: params });
  }
}
