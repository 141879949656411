import { Type } from '@angular/core';
import { Feature } from 'src/framing/feature';
import { FramingNgModule } from 'src/framing/framing-ng-module.ts';
import { Controller } from 'src/framing/controller';
import { SharedModule } from 'cad/shared/shared.module';
import { ModalController } from './modal.controller';
import { ModalModel } from './modal.model';
import { ModalView } from './modal.view';
import { ModalRegistry } from './modal-registry.service';

export class ModalFeature extends Feature<ModalModel, ModalView> {

  public get featureName(): string { return 'Modal'; }

  /**
   * Default configuration override.
   */
  public get defaultModel(): ModalModel {
    return {
      name: `${this.featureName}${Math.random()}`,
      ...ModalFeature.defaultModelStatic
    } as ModalModel;
  }

  static defaultModelStatic: Partial<ModalModel> = {
    title: 'Enter Title',
    okButtonText: 'SELECT',
    cancelButtonText: 'CANCEL',
    cancelButton: true,
    type: 'basic',
    enableFilter: false
  };

  /**
   * Default configuration override.
   */
  public get defaultView(): ModalView {
    return {};
  }

  public get defaultController(): Type<ModalController> {
    return ModalController;
  }

  /**
   * The frame function.
   */
  public build(framing: FramingNgModule): void {
    framing
      .imports([
        SharedModule,
        // ModalFeatureModule
      ])
      .provide([
        this.theModel.api,
        ModalRegistry,
      ]);
  }

  public framerOnControllerInit(controller: Controller<ModalModel, ModalView>): void {
  }
}
