import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHelper} from 'cad/common/services/api/api-helper';


@Injectable()
export class CycleProcessStatusApi {

  private readonly endpoint: string = 'flowing-gas/scheduling/cycle-process-status';

  constructor(
    private apiHelper: ApiHelper,
  ) {
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/filter', {body: params});
  }

  public rerun(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/rerun-process', {body: params});
  }

}
