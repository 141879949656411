import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';

@Component({
  moduleId: module.id + '',
  selector: 'table-column-icon-render',
  templateUrl: './icon-renderer.component.tpl.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableColumnIconRendererComponent implements AgRendererComponent {

  public iconName: any = 'cancel';
  public iconClass: any;
  public params: any;

  agInit(params: any): void {
    this.setIconMapValues(params);
  }

  refresh(params: any): boolean {
    this.setIconMapValues(params);
    return true;
  }

  private setIconMapValues(params: any): void {
    this.params = params;
    const iconValueMap: any = params.column.colDef.iconValueMap;
    this.iconName = iconValueMap[ params.value ] ? iconValueMap[ params.value ] [ 0 ] : '';
    this.iconClass = iconValueMap[ params.value ] ? iconValueMap[ params.value ][ 1 ] : '';
  }

}
