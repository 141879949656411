<mat-card class="ui-card-fill"
         fxLayout="column" fxFlex="1 1 100%">
  <mat-card-header >
    <mat-card-title fxFlex>
      <span class="mat-title">{{headline}}</span>
    </mat-card-title>
    <div class="button-spacer"></div>
    <div class="header-events"><ng-content select="[ui-card-fill-header-content]"></ng-content></div>
  </mat-card-header>
  <mat-card-content fxFlex="1 1 100%" fxFill="100%">
    <ng-content select="[ui-card-fill-content]"></ng-content>
    <!--div layout="row" fxFlex="none" style="overflow: hidden">
      <div layout="row" fxFlex="auto" fxLayoutAlign="start center">
        <span fxFlex="auto"></span>
        <span fxFlex>&nbsp;</span>
      </div>
      <div fxLayout="row"
           fxFlex="30"
           fxLayoutAlign="end center"
      ></div>
    </div>
    <div fxLayout="column" fxFlex="100"></div-->
  </mat-card-content>
</mat-card>
