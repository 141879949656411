<ui-fullscreen-header
  [title]="associationController.model.heading"
  (onClose)="onDialogClose()">
  <button
    mat-button
    [disabled]="selectedContentIndex === 0"
    class="ui-toolbar-modal-button"
    type="button"
    (click)="previousTab()">
    <mat-icon fontSet="material-icons">arrow_back</mat-icon>
  </button>

  <div fxFlex></div>
</ui-fullscreen-header>
