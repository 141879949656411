
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LookupApi } from 'src/features/common/modal/modal-feature-types';
import { ApiHelper } from 'src/cad/common/services/api/api-helper';
import { PointData } from 'src/cad/contracts/shared/interfaces/point-data';
import { PointLookupData } from 'src/cad/contracts/shared/interfaces/point-lookup';
import { isNil, get, omit } from 'lodash';

@Injectable()
export class AgreementPointLookupApi implements LookupApi {
  private readonly endpoint: string = 'agreement/contract';

  constructor(private apiHelper: ApiHelper) {}

  filter(params: Partial<PointLookupData>): Observable<PointData[]> {
    let returnVal: Observable<PointData[]>;
    if (params) {
      let paramsObj: PointData = omit(params, 'populator') as PointData;
      let lookupActionName: string = get(params, 'populator');

      switch (lookupActionName) {
        case 'relatedFirmPointLookup': {
          returnVal = this.findPoints(paramsObj);
          break;
        }
        default: {
          returnVal = this.getPointLookupData(lookupActionName, paramsObj);
          break;
        }
      }
    }
    return !isNil(returnVal) ? returnVal : observableOf([]);
  }

  getPointLookupData(type: string, params: PointData): Observable<PointData[]> {
    return this.apiHelper.request(`${ this.endpoint }/rights/point/lookup/${ type }`, { body: params });
  }

  findPoints(filter: PointData): Observable<PointData[]> {
    return this.apiHelper.request(`${ this.endpoint }/find-points`, { body: filter });
  }
}
