<span>
  <button
    mat-button
    matTooltip="User Profile"
    [matMenuTriggerFor]="menu"
    aria-label="Open login menu"
  >
    <mat-icon>{{ user?.loggedIn ? "person" : "person_outline" }}</mat-icon>
  </button>
  <mat-menu
    #menu="matMenu"
    class="login-menu"
    positionX="after"
    positionY="below"
    [overlapTrigger]="false"
  >
    <button mat-menu-item *ngIf="!user?.loggedIn" (click)="loginUser()">
      <span>Login</span>
    </button>
    <button mat-menu-item *ngIf="user?.loggedIn" (click)="logoutUser()">
      <span>Log Out {{ user?.name }}</span>
    </button>
    <!--    <button *ngIf="user.name" mat-menu-item>
      <span>Change BA</span>
    </button>
    -->
    <!--<button mat-menu-item>-->
    <!--<span>Account Settings</span>-->
    <!--</button>-->
    <button mat-menu-item *ngIf="user?.loggedIn && runAs" (click)="runAsUser()">
      <span>Run As User</span>
    </button>
    <button *ngIf="user?.userName" mat-menu-item (click)="editDashboard()">
      <span>Edit Dashboard</span>
    </button>
    <button *ngIf="enableEditProfile" mat-menu-item (click)="editUserProfile()">
      <span>Edit User Profile</span>
    </button>
    <!--<button mat-menu-item>-->
    <!--<span>Help</span>-->
    <!--</button>-->
    <div></div>
  </mat-menu>
</span>
