<ui-form include-save="false" (onSave)="saveAssociations()" (dirtyStatusChanged)="isDirty=$event.dirtyStatus">  
  <ui-modal-header *ngIf="!associationController.model.modalTitle" (onClose)="closeModal()">
    ASSOCIATE {{associationController.fromController.model.name}} WITH {{associationController.model.toModel.name}}
  </ui-modal-header>
  <ui-modal-header *ngIf="associationController.model.modalTitle" (onClose)="closeModal()">{{associationController.model.modalTitle}}</ui-modal-header>
  <ui-modal-body>
    <ng-container
      [framingComponentOutlet]="associationController.view.form"
      [framingComponentInputs]="{ associationController: associationController }"
      [framingComponentInjector]="formOnlyInjector"
      (onComponent)="onFormReady($event)">
    </ng-container>
  </ui-modal-body>
  <ui-modal-footer>
    <button mat-button
            mat-raised-button
            (click)="closeModal()"
            type="button">CANCEL
    </button>
    <button mat-button
            mat-raised-button
            [disabled]="isSubmitDisabled()"
            color="primary"
            class="mat-primary"
            type="submit">SUBMIT
    </button>
  </ui-modal-footer>
</ui-form>
