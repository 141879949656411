import { Injectable } from '@angular/core';
import { RowNode, RowNodeTransaction } from '@ag-grid-community/core';
import { UiGridApi } from './ui-grid-api';
import { UiAddRowNode } from 'src/ag-grid-wrapper/interfaces/ui-add-row-node';
import { TableComponent } from '../table/table.component';
import * as _ from 'lodash';

@Injectable()
export class TableRowEditAddFactory {

  constructor() {}

  public unRegisterGridListener(table: TableComponent): void {
    this.removeAddRowApi(table.api);
  }

  public onGridApiRegistered(table: TableComponent): void {
    this.setAddRowApi(table.api);
  }

  public addRow(api: UiGridApi): any {
    return (newRowData: any, scrollToFocusCol?: string): number => {
      if (newRowData) {
        _.assign(newRowData, {
          dirtyStatus: 1,
        });
        let rowNodeTrans: RowNodeTransaction = api.updateRowData({
          add: [ newRowData ],
          addIndex: null,
          update: null,
          remove: null
        });

        if (rowNodeTrans
          && _.isArray(rowNodeTrans.add)
          && rowNodeTrans.add[ 0 ]) {
          const rowIdx: number = rowNodeTrans.add[ 0 ].rowIndex;
          this.newRow(api, rowIdx, scrollToFocusCol);
          return rowIdx;
        }
      }
      return null;
    };
  }

  public insertRow(api: UiGridApi): any {
    return (index: number, newRowData: any, scrollToFocusCol?: string): number => {
      if (newRowData) {
        _.assign(newRowData, {
          dirtyStatus: 1,
        });
        api.updateRowData({add: [ newRowData ], addIndex: index, update: null, remove: null});
        const rowIdx: number = index;
        this.newRow(api, rowIdx, scrollToFocusCol);
        return rowIdx;
      }
      return null;
    };
  }

  private newRow(api: UiGridApi, rowIdx: number, scrollToFocusCol?: string): void {
    if (api) {
      const rowNode: RowNode = api.getModel().getRow(rowIdx);

      if (!_.isNil(rowNode)) {
        _.assign((rowNode as UiAddRowNode), {
          newValidation: true,
          isNew: true
        });
        if (api.rowEdit) {
          api.rowEdit.setGridRowDirty(api, rowNode);
        }
        api.refreshCells({ rowNodes: [ rowNode ] });
      }
      api.ensureIndexVisible(rowIdx);
      if (scrollToFocusCol) {
        setTimeout(() => {
          api.ensureColumnVisible(scrollToFocusCol);
          api.setFocusedCell(rowIdx, scrollToFocusCol, null);
          api.startEditingCell({
            rowIndex: rowIdx,
            colKey: scrollToFocusCol,
          });
        });
      }
    }
  }

  private setAddRowApi(api: UiGridApi): void {
    _.defaultsDeep(api, {
      rowEdit: {
        addRow: this.addRow(api),
        insertRow: this.insertRow(api),
      }
    });
  }

  private removeAddRowApi(api: UiGridApi): void {
    if (api && api.rowEdit) {
      delete api.rowEdit.addRow;
      delete api.rowEdit.insertRow;
    }
  }
}
