<div>
  <ui-card id="{{cardId}}"
           #uiCardRef
           [headline]="cardTitle"
           [skipDisable]="skipDisable"
           [cardToolBarItems]="!readOnly ? cardToolBarItems : null"
           [(isFullHeight)]="isFullHeight"
           [enableMax]="enableMax"
           [enableExpand]="enableExpand"
           [context]="context">
    <ng-container *ngIf="contentComponent"
                  [framingComponentOutlet]="contentComponent"
                  (onComponent)="onContentReady($event)">
    </ng-container>
    <ng-content *ngIf="!contentComponent"></ng-content>
    <button *ngIf="!readOnly && showAdd"
            mat-mini-fab
            type="button"
            (click)="onAddClicked($event)"
            [disabled]="(readOnly || !isAddEnabled || !this.canPerformAction('SAVE'))"
            class="floating-mat-fab">
      <mat-icon class="material-icons">add</mat-icon>
    </button>
  </ui-card>
</div>
