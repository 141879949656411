<a mat-button
   disableRipple="true"
   (click)="toggle()"
   [attr.aria-expanded]="section.isSubMenuOpen"
   [ngClass]="{'active': ( section.isSubMenuOpen && !section.pages ),
               'is-open' : section.isSubMenuOpen }">
  <div fxFlex
       fxLayout="row"
       class="button-wrapper">
    <mat-icon [matTooltip]="section.name" [matTooltipPosition]="'right'">{{section.icon}}</mat-icon>
    <div class="section-name" fxFlex>{{section.name}}</div>
    <mat-icon *ngIf="section.pages"
             [attr.aria-hidden]="true"
             class="md-toggle-icon">
      keyboard_arrow_down
    </mat-icon>
  </div>
</a>
<ul #toggleList
    id="docs-menu-{{section.name}}"
    [ngClass]="(section.isSubMenuOpen) ? 'menu-toggle-list-open' : 'menu-toggle-list-close'">
  <li *ngFor="let page of section.pages"
      [hidden]="page.hide">
    <ui-nav-link (onSelection)="setPageActive(page)" [section]="page"></ui-nav-link>
  </li>
</ul>
