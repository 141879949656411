import {
  Directive,
  Inject,
  Input,
  ElementRef,
  Renderer2,
  OnDestroy,
  NgZone,
  AfterViewInit
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as _ from 'lodash';

@Directive({
  selector: '[uiDraggableModal]',
  exportAs: 'uiDraggableModal'
})
export class DraggableModalDirective implements OnDestroy, AfterViewInit {
  @Input('uiDraggableModal') directiveValue: any;
  @Input('uiDraggableModalContainer') public draggedElementSelector: string = '.cdk-overlay-pane';
  @Input('uiDraggableModalOpacity') public dragOpacity: string = '1.0';

  public triggerElementRef: ElementRef;
  public triggerElement: HTMLElement;
  public draggedElement: HTMLElement;

  public isDragging: boolean = false;
  private startX: number = 0;
  private startY: number = 0;
  private mouseCursorX: number = 0;
  private mouseCursorY: number = 0;
  private _mousedownListener: (event: MouseEvent) => void = (): void => {};
  private _mousemoveListener: (event: MouseEvent) => void = (): void => {};
  private _mouseupListener: (event: MouseEvent) => void = (): void => {};
  private _losecaptureListener: (event: Event) => void = (): void => {};

  constructor(
    @Inject(DOCUMENT) private documentRef: Document,
    elementRef: ElementRef,
    private renderer: Renderer2,
    private ngZone: NgZone
  ) {
    this.triggerElementRef = elementRef;
  }

  public ngAfterViewInit(): void {
    if ( this.isDragEnabled() ) {
      this.triggerElement = this.triggerElementRef.nativeElement;
      this.draggedElement = this.triggerElement.closest(this.draggedElementSelector ) as HTMLElement;
      this.addTriggerElementStyles();
      this.addEventListeners();
    }
  }

  public isDragEnabled() : boolean {
    return _.isNil(this.directiveValue) || coerceBooleanProperty(this.directiveValue);
  }

  private addTriggerElementStyles(): void {
    this.renderer.setStyle(this.triggerElement, 'cursor', 'move');
    this.renderer.setStyle(this.draggedElement, 'position', 'absolute');
  }

  private removeTriggerElementStyles(): void {
    this.renderer.removeStyle(this.triggerElement, 'cursor');
    this.renderer.removeStyle(this.draggedElement, 'position');
  }

  private addDraggedElementStyles(): void {
    this.renderer.setStyle(this.draggedElement, 'z-index', '9999');
    this.renderer.addClass(this.draggedElement, 'ui-draggable-modal');
  }

  private removeDraggedElementStyles(): void {
    this.renderer.removeStyle(this.draggedElement, 'z-index');
    this.renderer.removeClass(this.draggedElement, 'ui-draggable-modal');
  }

  private addEventListeners(): void {
    this._mousedownListener = this.onMouseDown.bind(this);
    this._mousemoveListener = this.onMouseMove.bind(this);
    this._mouseupListener = this.onMouseUp.bind(this);
    this._losecaptureListener = this.onLoseCapture.bind(this);
    this.ngZone.runOutsideAngular(() => {
      this.triggerElement.addEventListener('mousedown', this._mousedownListener);
      this.triggerElement.addEventListener('losecapture', this._losecaptureListener);
      this.documentRef.addEventListener('mouseup', this._mouseupListener, true);
      if (this.hasSetCapture()) {
        this.triggerElement.addEventListener('mousemove', this._mousemoveListener, true);
      } else {
        this.documentRef.addEventListener('mousemove', this._mousemoveListener, true);
      }
    });
  }

  private removeEventListeners(): void {
    this.triggerElement.removeEventListener('mousedown', this._mousedownListener);
    this.triggerElement.removeEventListener('losecapture', this._losecaptureListener);
    this.documentRef.removeEventListener('mouseup', this._mouseupListener, true);
    if (this.hasSetCapture()) {
      this.triggerElement.removeEventListener('mousemove', this._mousemoveListener, true);
    } else {
      this.documentRef.removeEventListener('mousemove', this._mousemoveListener, true);
    }
  }

  private onMouseDown(event: MouseEvent): void {
    this.addTriggerElementStyles();
    this.addDraggedElementStyles();

    this.addChildrenOpacity();
    this.renderer.setStyle(this.triggerElement, 'msUserSelect', 'none');
    this.renderer.setStyle(this.triggerElement, 'webkitUserSelect', 'none');
    // get the mouse cursor position at startup
    this.mouseCursorX = event.clientX;
    this.mouseCursorY = event.clientY;
    this.startX = this.draggedElement.offsetLeft;
    this.startY = this.draggedElement.offsetTop;
    this.isDragging = true;
    if (this.hasSetCapture()) {
      (this.triggerElement as any).setCapture();
    }
  }

  private onMouseMove(event: MouseEvent): void {
    let newX: number;
    let newY: number;

    if (!this.isDragging) {
      return;
    }
    // calculate the new cursor position
    newX = this.startX + (event.clientX - this.mouseCursorX);
    newY = this.startY + (event.clientY - this.mouseCursorY);
    // set the element's new position
    this.positionDragElement(newX, newY, this.draggedElement);
  }

  private onMouseUp(event: MouseEvent): void {
    this.removeDraggedElementStyles();
    this.isDragging = false;
    if (_.isFunction((this.documentRef as any).releaseCapture)) {
      (this.documentRef as any).releaseCapture();
    }
    this.removeChildrenOpacity();
    this.renderer.removeStyle(this.triggerElement, 'msUserSelect');
    this.renderer.removeStyle(this.triggerElement, 'webkitUserSelect');
  }

  private onLoseCapture(event: Event): void {
    this.isDragging = false;
  }

  private positionDragElement(x: number, y: number, dragEl: HTMLElement): void {
    let browserWidth: number = this.getBodyDimension(false) - 2;
    let browserHeight: number = this.getBodyDimension(true) - 2;
    // put clone vertically in middle of cursor
    let top: number = y;
    // horizontally, place cursor just right of icon
    let left: number = x;
    let documentEl: HTMLElement = (this.documentRef && this.documentRef.documentElement)
      ? this.documentRef.documentElement
      : document.documentElement;
    let windowScrollY: number = window.pageYOffset || documentEl.scrollTop;
    let windowScrollX: number = window.pageXOffset || documentEl.scrollLeft;
    // check clone is not positioned outside of the browser
    if (browserWidth > 0) {
      if ((left + dragEl.clientWidth) > (browserWidth + windowScrollX)) {
        left = browserWidth + windowScrollX - dragEl.clientWidth;
      }
    }
    left -= _.defaultTo(_.parseInt(dragEl.style.marginLeft), 0);
    if (left < 0) {
      left = 0;
    }
    if (browserHeight > 0) {
      if ((top + dragEl.clientHeight) > (browserHeight + windowScrollY)) {
        top = browserHeight + windowScrollY - dragEl.clientHeight;
      }
    }
    top -= _.defaultTo(_.parseInt(dragEl.style.marginTop), 0);
    if (top < 0) {
      top = 0;
    }
    this.renderer.setStyle(dragEl, 'left', left + 'px');
    this.renderer.setStyle(dragEl, 'top', top + 'px');
  }

  private hasSetCapture(): boolean {
    return (this.triggerElement && _.isFunction((this.triggerElement as any).setCapture));
  }

  private addChildrenOpacity(): void {
    if (this.draggedElement && this.draggedElement.children) {
      _.forEach(this.draggedElement.children, (child: HTMLElement) => {
        this.renderer.setStyle(child, 'opacity', this.dragOpacity);
      });
    }
  }

  private removeChildrenOpacity(): void {
    if (this.draggedElement && this.draggedElement.children) {
      _.forEach(this.draggedElement.children, (child: HTMLElement) => {
        this.renderer.removeStyle(child, 'opacity');
      });
    }
  }

  private getBodyDimension(isHeight?: boolean): number {
    let dimensionProp: string = isHeight ? 'Height' : 'Width';

    if (this.documentRef && this.documentRef.body) {
      return this.documentRef.body[ 'client' + dimensionProp ];
    }
    if (window && window[ 'inner' + dimensionProp ]) {
      return window[ 'inner' + dimensionProp ];
    }
    if (this.documentRef
      && this.documentRef.documentElement
      && this.documentRef.documentElement[ 'client' + dimensionProp ]) {
      return this.documentRef.documentElement[ 'client' + dimensionProp ];
    }

    return -1;
  }

  public ngOnDestroy(): void {
    if ( this.isDragEnabled() ) {
      this.removeEventListeners();
      this.removeTriggerElementStyles();
      this.removeDraggedElementStyles();
      this.triggerElement = null;
      this.draggedElement = null;
    }
  }
}

