import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class AgreementContractBackwardRelatedApi {

  private readonly endpoint: string = 'agreement/contract/related';

  constructor(private apiHelper: ApiHelper) { }

  public findAll = (id: number): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/${ id }/backward`);
  }
}
