
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LookupApi } from 'src/features/common/modal/modal-feature-types';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class UnassignedUsersApi implements LookupApi {

  private readonly endpoint: string = 'associate/contact/';
  private readonly suffix: string = '/security';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public filter(params: any): Observable<any> {
    let returnVal: Observable<any>;
    if (params) {
      let paramsObj: any = _.omit(params, 'populator');
      returnVal = this.findUsers(paramsObj);
    }
    return !_.isNil(returnVal) ? returnVal : observableOf([]);
  }

  public findUsers = (data: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + data.userId + this.suffix + '/unassigned-users', {body: data});
  }

}
