import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class BusinessAssociateAddressApi {

  private readonly endpoint: string = 'associate/business-associate/';
  private suffix: string = '/address';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public findAll = (baId: string | number): Observable<any> => {
    return this.apiHelper.request(this.endpoint + baId + this.suffix);
  }

  public save = (baAddresses: any[]): Observable<any> => {
    return this.apiHelper.request(this.endpoint + baAddresses[0].baId + this.suffix, { body: baAddresses });
  }
}
