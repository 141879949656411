import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ItemApi } from 'src/features/common/item/item-api';

@Injectable()
export class ParametersApi implements ItemApi  {

  public readonly endpoint: string = 'product/parameter';

  constructor(
    private apiHelper: ApiHelper
  ) {}

  public find(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/find`, { body: params });
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/filter`, { body: params });
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/recent`);
  }

  public findParameter(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/findparameter`, { body: params });
  }

  public findDomain(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/finddomain`, { body: params });
  }

  public save(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/save`, { body: params });
  }

  public saveParameter(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/saveparameter`, { body: params });
  }
  public saveDomain(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/savedomain`, { body: params });
  }
  public deleteItem(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/delete`, { body: params });
  }

  public getParamDomainList(): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/paramDomainList`);
  }
  
  public getStaticParamLookupData(): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/staticparameterlookup`);
  }

  public getVariableParamLookupData(): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/variableparameterlookup`);
  }
}
