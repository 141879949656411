import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class BusinessAssociateAssetContactApi {

  private readonly endpoint: string = 'associate/business-associate';
  private endpointSuffix: string = 'asset/contact';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public findAll = (id: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/' + id + '/' + this.endpointSuffix);
  }

  public save = (dataList: any[]): Observable<any> => {
    if (dataList.length && dataList[0].baId) {
      return this.apiHelper.request(this.endpoint + '/' + dataList[0].baId + '/' + this.endpointSuffix, { method: 'POST', body: dataList });
    } else {
      return this.apiHelper.request(this.endpoint, { method: 'PUT', body: dataList });
    }
  }
}
