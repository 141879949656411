import { UpdateableData } from 'src/cad/shared/interfaces/updateable-data';

export class ContactNotification implements UpdateableData {
  contactId: number;
  notificationTypeCd: string;
  profileTypeCd: string;
  effBeginDt: string;
  effEndDt: string;
  assetNbr: number;
  bAId: number;
  dirtyStatus: number;
  updater: string;
  updateDt: string;
}
