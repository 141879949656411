/* 
** TODO:  replace commonJS imports here with ES module imports
*/
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as cad from 'common/types/cad.constants';
import { cadZone } from './cad-zone';
import { cadDateUtilInstance } from 'common/utils/date-util';
import { AppModule } from './cad/app.module';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { environment } from 'src/environments/environment';
import * as jquery from 'jquery';
import * as moment from 'moment-timezone';

// vendor.ts
(window as any).xhook.disable();
import './polyfills';
(window as any).xhook.enable();
// TODO:  is hammerjs needed?  If so, should import via ES module instead of commonJS
// https://angular.io/api/platform-browser/HammerModule
// import 'hammerjs/hammer';
(window as any).jquery = jquery;
(window as any).moment = moment;
(window as any)._ = _;
window.onerror = (errorMsg, url, lineNumber, columnNumber, errorObject) => {
  let errMsg;
  // check the errorObject as IE and FF don't pass it through (yet)
  if (errorObject && errorObject !== undefined) {
    errMsg = errorObject.message;
  } else {
    errMsg = errorMsg;
  }
  console.error('Error: ' + errMsg);
};
// end vendor.ts

// plugins.ts
(() => {
  let method;
  let noop = () => {};
  let methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn',
  ];
  let length = methods.length;
  let console = ((window as any).console = window.console || {});
  while (length--) {
    method = methods[length];
    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
})();

// Specific to IE
let ua = window.navigator.userAgent;
let oldIe = ua.indexOf('MSIE ');
let newIe = ua.indexOf('Trident/');
if ((oldIe > -1) || (newIe > -1)) {
  let d = document.getElementsByTagName('html')[0];
  d.className += ' ie';
}
// end plugins.ts

LicenseManager.setLicenseKey('CompanyName=SHI International Corp_on_behalf_of_Energy Transfer Partners, L.P.,LicensedGroup=ITCA,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-011166,ExpiryDate=15_October_2021_[v2]_MTYzNDI1MjQwMDAwMA==7fbb9a3c701eea39ea6a246c349b8558');

interface MomentWritable {
  defaultFormat: string; // by default TypeScript complains when trying to set defaulFormat which it thinks is read-only
}

if (environment.isReleaseBuild) {
  enableProdMode();
  console.warn('Production mode enabled');
  console.log = () => {};
  console.info = () => {};
  console.warn = () => {};
  console.group = () => {};
}

(moment as MomentWritable).defaultFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZZ'; // set default format in moment
(window as any).cad = cad; // make this a globally available module as cad
(window as any).dateUtil = cadDateUtilInstance; // make this a globally available function as dateUtil
cadDateUtilInstance(); // force construction of singleton
cadZone().run(() => {
  platformBrowserDynamic().bootstrapModule(AppModule).then((platformRef) => {
    console.log('Bootstrap done');
  });
});
