import {AlertsService} from 'cad/core/services/alerts/alerts.service';
import {AutoUnsubscriber, AutoUnsubscribables} from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import {UsersApi} from 'src/cad/common/services/api/administration/users/users';
import {AssociateContactApi} from 'cad/common/services/api/associate/contact/contact';
import {UserModelService} from 'src/cad/common/services/user/user-model-service';
import {Component, OnInit} from '@angular/core';
import {ActionService} from 'src/cad/common/services/security/action.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'personal-information',
  templateUrl: './personal-information.component.html',
})
export class PersonalInformationComponent implements OnInit {

  public item: any;
  public disabled: boolean = true;
  @AutoUnsubscriber() private subs: AutoUnsubscribables;


  constructor(private api: UsersApi,
              public mdDialog: MatDialog,
              private cadAlerts: AlertsService,
              private userModelService: UserModelService,
              private associateContactApi: AssociateContactApi,
              private actionService: ActionService) {
  }

  ngOnInit(): void {
    this.populateForm();
  }


  populateForm(): void {
    let userId = this.userModelService.getUser().userId;
    this.item = this.userModelService.getUser();
    this.subs.newSub = this.associateContactApi.find(userId).subscribe((data) => this.item = data);
  }


  showErrors(resp: any): void {
    if (resp.errorMessages && resp.errorMessages.length) {
      resp.errorMessages.forEach((err) => {
        this.cadAlerts.danger(err.message);
      });
    }
  }
}
