import { Component, ChangeDetectionStrategy, Injector, ChangeDetectorRef } from '@angular/core';
import { FeatureComponent } from 'src/framing/feature.component';
import { CadAppController } from '../../cad-app.controller';
import { CadAppModel } from '../../cad-app.model';
import { CadAppView } from '../../cad-app.view';
import {ConfigService} from 'cad/core/services/config/config.service';

@Component({
  selector: 'ui-global-footer',
  templateUrl: './footer.component.html',
  styleUrls: [ './footer.component.less' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent extends FeatureComponent<CadAppModel, CadAppView, CadAppController> {
  footerDate: string = dateUtil().getPrettyPrintDateTime();
  public envMessage: string = '';
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public configService: ConfigService,
    controller: CadAppController,
    injector: Injector,
  ) {
    super(controller, injector);
    if (this.configService.cadConfig.isDev) {
      this.envMessage = this.configService.cadConfig.envName;
    }
  }

  ngOnInit(): void {
    // this.subs.newSub = Observable.interval(1000).subscribe(() => this.refreshFooterDate());
  }

  public refreshFooterDate(): void {
    // using local change detector ref call here instead of the controller markForCheck so that change detection is confined to this component
    // instead of the entire CadAppController
    this.footerDate = dateUtil().getPrettyPrintDateTime();
    this.changeDetectorRef.markForCheck();
  }


}
