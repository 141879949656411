
import {throwError as observableThrowError,  Observable } from 'rxjs';
import {Injectable, Injector} from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ItemApi } from 'src/features/common/item/item-api';
import {RouterService} from 'src/cad/core/services/router/router.service';

@Injectable()
export class MorningReportOutageApi extends ItemApi {
  private readonly endpoint: string = 'flowing-gas/scheduling/morning-report-outage';

  constructor(
    public apiHelper: ApiHelper,
    public routerService: RouterService
  ) {
    super();
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/dashboard');
  }

  public findAll = (code: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/' + code);
  }


  public find(params: any): Observable<any> {
    let paramsObj: any = params;
    if (this.routerService) {
      let navigationData: any = this.routerService.getNavigationData();

      if (!_.isNil(navigationData)) {
        paramsObj = navigationData;
      }
    }
    return this.apiHelper.request(`${ this.endpoint }`, { body: paramsObj });
  }

  public save(data: any): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/save`, { body: data });
  }

  public saveOutage = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/outage-save', { body: params });
  }

  public deleteItem(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/delete', { body: !_.isArray(params) ? [ params ] : params });
  }

  public getGDSImbalanceDetails(data: any): Observable<any> {
    if (data) {
      return this.apiHelper.request(this.endpoint + '/find', { body: data });
    } else {
      return observableThrowError('Please select a record to view details'); // Validation should have taken care of this
    }
  }
}
