import { Component, Inject, AfterViewInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { TABLE_EDITOR_DATA } from 'src/ag-grid-wrapper';
import { TableColumnCustomEditorComponent } from 'src/ag-grid-wrapper/column/custom/custom-editor.component';
import { UiDatepickerComponent } from 'src/common/components/form/datepicker/datepicker.component';
import * as moment from 'moment-timezone';
import { isFunction } from 'lodash';

@Component({
  selector: 'table-datepicker-editor',
  template: `
    <div class="datepicker-editor editor">
      <ui-datepicker #datepickerRef="uiDatepicker"
                     [displayMode]="displayMode"
                     [minDate]="customCellConfig?.minDate"
                     [maxDate]="customCellConfig?.maxDate"
                     [(ngModel)]="params.value"
                     [placeholder]="''">
      </ui-datepicker>
    </div>
  `,
  styleUrls: [ './datepicker-editor.component.less' ]
})
export class DatepickerEditorComponent implements AfterViewInit, OnDestroy {
  @ViewChild('datepickerRef') public datepickerRef: UiDatepickerComponent;
  customCellConfig: any;
  inputElementRef: HTMLInputElement;
  public displayMode: string = 'date';

  constructor (
    @Inject(TABLE_EDITOR_DATA) public params: any,
    public elementRef: ElementRef,
    @Inject(TableColumnCustomEditorComponent) public tableColumnCustomEditorComponent: TableColumnCustomEditorComponent
  ) {
    if (this.tableColumnCustomEditorComponent) {
      // Override the cellEditor's getValue method to fix datepicker input value change
      // since the model is not being updated on time
      this.tableColumnCustomEditorComponent.getValue = this.getValue.bind(this);
    }
    if (this.params
      && this.params.column
      && this.params.column.getColDef()) {
      this.customCellConfig = (isFunction(this.params.column.getColDef().customCellConfig)
        ? this.params.column.getColDef().customCellConfig(this.params)
        : this.params.column.getColDef().customCellConfig);
    }
    if (this.customCellConfig && this.customCellConfig.maxDate) {
      if (!moment(this.params.value).isBefore(this.customCellConfig.maxDate)) {
        this.customCellConfig.maxDate = this.params.value;
      }
    }

    if (params && params.column && params.column.getColDef() && params.column.getColDef().cellFilter) {
      let filter: string = params.column.getColDef().cellFilter;
      if (filter === 'date' || filter === 'full' || filter === 'time') {
        this.displayMode = filter;
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.elementRef && this.elementRef.nativeElement) {
      this.inputElementRef = this.elementRef.nativeElement.querySelector('input');
    }
    setTimeout(() => {
      if (this.inputElementRef) {
        this.inputElementRef.focus();
        this.inputElementRef.select();
      }
    });
  }

  ngOnDestroy(): void {
    this.inputElementRef = null;
  }

  getValue(): any {
    if (!this.params) {
      return null;
    }
    if (this.datepickerRef
      && this.datepickerRef.dateAdapter
      && (!this.datepickerRef.datepickerInputModel
        || this.datepickerRef.dateAdapter.isValid(this.datepickerRef.datepickerInputModel))) {
      this.params.value = this.datepickerRef.datepickerInputModel
        ? dateUtil().getNonMomentParsedDate(this.datepickerRef.datepickerInputModel).format(this.datepickerRef.outputFormat)
        : null;
    }
    return this.params.value;
  }
}
