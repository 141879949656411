import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dunsNumber' })
export class DunsNumberPipe implements PipeTransform {
  transform(value: string, args?: string[]): any {
    if (value) {
      if (value.length >= 9) {
        return value.substr(0, 2) + '-' + value.substr(2, 3) + '-' + value.substr(5);
      } else if (value.length >= 5) {
        return value.substr(0, 2) + '-' + value.substr(2, 3) + '-' + value.substr(5);
      } else if (value.length >= 2) {
        return value.substr(0, 2) + '-' + value.substr(2);
      }
    }
    return value;
  }
}
