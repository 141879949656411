import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class ValuationComponentViewApi {
  private readonly endpoint: string = 'settlement/valuation/component';
  constructor(
    private apiHelper: ApiHelper,
  ) {}

  // ========================================
  // implementation
  // ========================================

  public find = (itemData: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint, { body: itemData });
  }
}
