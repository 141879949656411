import { Injectable } from '@angular/core';
import { Observable ,  Subject ,  ReplaySubject } from 'rxjs';
import { DashboardConfig } from 'src/cad/common/services/dashboard/dashboard-config.model';

@Injectable()
export class DashboardModel {
  public get dashboardItems$(): Observable<DashboardConfig[]> { return this.dashboardItemsSubject; }

  public dashboardItemsSubject: Subject<DashboardConfig[]> = new ReplaySubject(1);
}
