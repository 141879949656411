import { Directive, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';

import { FocusTrap } from '@angular/cdk/a11y';

@Directive({
  selector: '[uiAutoFocus]',
})
export class AutoFocusDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}
  public ngAfterViewInit (): void {
    let el = this.el.nativeElement;
    if (el.tagName === 'INPUT' || el.tagName === 'UI-SELECT' || el.tagName === 'UI-MULTI-SELECT' || el.tagName === 'TEXTAREA') {
      this.setFocusOn(el);
    } else {
      let input = this.el.nativeElement.querySelector('input,ui-select,ui-multi-select,textarea');
      if (input) {
        this.setFocusOn(input);
      }
    }
  }

  public setFocusOn(el: HTMLElement): void {
    //Timeout because ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'false'. Current value: 'true'
    setTimeout(() => {
      el.setAttribute('cdkFocusRegionstart', '');
      //should consider el.setAttribute('cdk-focus-initial', ''); although above seems to be working for now.
      el.focus();
    });
  }
}
