<div fxLayout="row"
     fxLayoutGap="8px"
     fxLayoutAlign="start center"
     class="filter-container">
  <mat-form-field fxFlex
                  *ngIf="columnType === 'number' || columnType === 'currency'">
    <input matInput
           type="number"
           [(ngModel)]="filterCriteria"
           placeholder="Filter"/>
    <button mat-button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            class="clear-button"
            (click)="onClearFilter.emit()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field fxFlex
                  class="date-picker-container"
                  [floatLabel]="'always'"
                  *ngIf="columnType === 'date'">
    <mat-datepicker-toggle matPrefix
                           [tabIndex]="-1"
                           [for]="matDatepickerRef">
      <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
    </mat-datepicker-toggle>
    <input matInput
           [ngModel]="filterCriteria"
           [matDatepicker]="matDatepickerRef"
           (dateChange)="filterCriteria = $event?.value"
           placeholder="Filter">
    <mat-datepicker #matDatepickerRef>
    </mat-datepicker>
    <input matInput
           type="date"
           *ngIf="filterType === 'inRange'"
           [(ngModel)]="dateRangeTo"
           placeholder="Filter"/>
  </mat-form-field>
  <div fxFlex
       *ngIf="columnType === 'checkbox'|| columnType === 'checkbox-boolean'">
    <mat-checkbox [(ngModel)]="checkboxState"
                  (change)="checkboxChange($event)"
                  [(indeterminate)]="indeterminate">
      Filter By: {{indeterminate ? 'None' :
      checkboxState ? 'Checked' : 'Unchecked'}}
    </mat-checkbox>
  </div>
  <mat-form-field fxFlex
                  *ngIf="columnType !== 'checkbox'
                  && columnType !== 'checkbox-boolean'
                  && columnType !== 'number'
                  && columnType !== 'currency'
                  && columnType !== 'date'">
    <input matInput
           [(ngModel)]="filterCriteria"
           placeholder="Filter"/>
    <button mat-button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            class="clear-button"
            (click)="onClearFilter.emit()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <button mat-button
          mat-icon-button
          aria-label="Filter Type"
          #filterTrigger="matMenuTrigger"
          [matMenuTriggerFor]="filterTypeMenu"
          class="menu-action-button"
          *ngIf="columnType !== 'checkbox' && columnType !== 'checkbox-boolean'">
    <mat-icon>filter_list</mat-icon>
  </button>
  <button mat-button
          *ngIf="columnType === 'date'"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          class="clear-button"
          (click)="onClearFilter.emit()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-menu [overlapTrigger]="false"
          #filterTypeMenu="matMenu">
  <button mat-menu-item
          *ngFor="let filterTypeItem of filterTypeList"
          (click)="setFilterType($event, filterTypeItem.type)"
          [ngClass]="filterTypeItem.type === filterType ? 'selected' : 'unselected'">{{filterTypeItem.name}}
  </button>
</mat-menu>
