import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfigService } from '../core/services/config/config.service';
import { UserModelService } from '../common/services/user/user-model-service';

@Injectable()
export class RedirectGuard implements CanActivate {
  constructor(private configSvc: ConfigService, private userModelService: UserModelService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    window.open(`${this.configSvc.cadConfig.infoPostBaseUrl}/${this.userModelService.getCurrentContext().assetAbbr}`);
    return false;
  }
}
