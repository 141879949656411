export enum FilterOptionsEnum {
  EQUALS = 'equals',
  NOTEQUAL = 'notEqual',
  CONTAINS = 'contains',
  NOTCONTAINS = 'notContains',
  STARTSWITH = 'startsWith',
  ENDSWITH = 'endsWith',
  LESSTHAN = 'lessThan',
  LESSTHANOREQUAL = 'lessThanOrEqual',
  GREATERTHAN = 'greaterThan',
  GREATERTHANOREQUAL = 'greaterThanOrEqual',
  INRANGE = 'inRange',
  EMPTY = 'empty'
}
