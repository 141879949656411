import { Injectable, Injector } from '@angular/core';
import { IdleSessionComponent } from 'cad/core/services/session/component/idle-session.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SecurityLogoutApi } from 'cad/common/services/api/security/logout/logout';
import { UserModelService } from 'cad/common/services/user/user-model-service';
import { LoggedOutWarn } from 'cad/core/services/session/component/logged-out-warn';
import { take } from 'rxjs/operators';

@Injectable()
export class IdleService {
  private timeout: number = 60;  //number of ticks (minutes) to timeout external
  private readonly timeoutWarning: number = 5;  //warn when this many ticks left
  private readonly tickPeriod: number = 60000;  //length of ticks in ms (60000 is 1 minute)
  private dialogRef: MatDialogRef<IdleSessionComponent>;
  private logoutDialogRef: MatDialogRef<LoggedOutWarn>;
  private userModelService: UserModelService;
  private securityLogoutApi: SecurityLogoutApi;

  constructor(private injector: Injector, public dialog: MatDialog) { }

  public idleSession(tick: number) : void {
    if(!this.userModelService) { this.userModelService = this.injector.get(UserModelService); }
    if(!this.userModelService.getUser() || this.userModelService.isLoggedOut()) {
      return;
    }
    const internalMultiplier = this.userModelService.isUserInternal() === 'Y' ? 8 : 1;

    switch (tick) {
      case (this.timeout * internalMultiplier - this.timeoutWarning) : {
        console.log('idle warning');
        this.dialogRef = this.dialog.open(IdleSessionComponent, {
          data: {
            timeToTimeout: this.timeoutWarning,
            tickPeriod: this.tickPeriod
          }
        });

        this.dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
          if(result) {
            // not needed.  Relying on client update checker, which is on a five minute timer.
            // this.keepAlive();
          } else {
            this.logout();
          }
        });
        break;
      }
      case (this.timeout * internalMultiplier) : {
        this.dialogRef.close(false);
        this.logout();
        break;
      }
    }
  }

  private logout(): void {
    if(!this.securityLogoutApi) { this.securityLogoutApi =  this.injector.get(SecurityLogoutApi); }
    if(!this.userModelService.isLoggedOut()) {
      this.securityLogoutApi.logoutUser().pipe(take(1)).subscribe();
      this.logoutDialogRef = this.dialog.open(LoggedOutWarn);
    }
  }

}
