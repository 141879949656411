import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ApiHelper} from 'cad/common/services/api/api-helper';
import {Workflow} from 'cad/administration/table-maintenance/workflow-associations/shared/interfaces/workflow';
import {
  WorkflowAssetAssociation
} from 'cad/administration/table-maintenance/workflow-associations/shared/interfaces/workflow-asset-association';
import ValidationResult = cad.ValidationResult;
import {
  EdiProcessSchedule
} from 'cad/administration/table-maintenance/edi-process-schedule/shared/interfaces/edi-process-schedule';
import {NumberCodeValueData, RBSCodeData} from 'cad/shared/interfaces/code-value-data';

@Injectable()
export class EdiProcessScheduleApi {

  private readonly endpoint: string = 'administration/table-maintenance/ediProcessExecSchedule';

  constructor(
    private apiHelper: ApiHelper) {
  }

  public getAll(): Observable<EdiProcessSchedule[]> {
    return this.apiHelper.request(this.endpoint +'/dashboard');
  }

  public saveEdiProcessSchedule(params: EdiProcessSchedule[]): Observable<ValidationResult<EdiProcessSchedule[]>> {
    return this.apiHelper.request(this.endpoint + '/save', {body: params});
  }

  public getTxnCd(): Observable<RBSCodeData[]> {
    return this.apiHelper.request(this.endpoint + '/txncd');
  }

}
