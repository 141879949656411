<div class="ui-fullscreen-header md-modal-toolbar">
  <div class="topBar">
    <ng-content></ng-content>
    <ui-alerts-button></ui-alerts-button>
    <button mat-button class="mat-icon-button" type="button" (click)="close()">
      <mat-icon md-font-set="material-icons">close</mat-icon>
    </button>
  </div>
  <div class="md-title">
    {{ title }}
  </div>
</div>
