import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiCache } from 'cad/common/services/api/api-cache';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AlertsService } from 'cad/core/services/alerts/alerts.service';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import { ContractSummaryApi } from '../../../agreement/contract-summary/contract-summary';
import { ContractSummarySeasonalApi } from '../../../agreement/contract-summary/contract-summary-seasonal';
import { ContractFilterData } from 'src/cad/contracts/shared/interfaces/contract-filter';

@Injectable()
export class ForecastContractAmendmentSummaryApi extends ContractSummaryApi {

  readonly forecastContractAmendmentSummaryEndpoint: string = 'portfolio/forecast/forecast-contract-amendment-summary';
  
  constructor(
    public apiHelper: ApiHelper,
    public apiCache: ApiCache,
    public alertService: AlertsService,
    public userModelService: UserModelService,
    public contractSummarySeasonalApi: ContractSummarySeasonalApi
  ) {
    super(apiHelper, apiCache, userModelService, contractSummarySeasonalApi);
  }

  public filter(params: Partial<ContractFilterData>): Observable<ContractFilterData[]> {
    return this.apiHelper.request(
      `${ this.forecastContractAmendmentSummaryEndpoint }/filter`,
      { body: { ...params, isContract: this.contractType } }
      );
  }
}
