import { Component, Inject, AfterViewInit } from '@angular/core';
import { TABLE_RENDERER_DATA } from 'src/ag-grid-wrapper';

@Component({
  selector: 'table-phone-renderer',
  template: `
    <div class="inner-cell">
      <div *ngIf="shouldUseText"
           class="inner-cell-dropdown-value">{{params.value}}</div>
      <div *ngIf="!shouldUseText"
           class="inner-cell-dropdown-value">{{params.value| phoneNumber}}</div>
    </div>
  `,
})
export class PhoneOrTextRendererComponent implements AfterViewInit {
  
  public shouldUseText: boolean;
  
  constructor(@Inject(TABLE_RENDERER_DATA) public params: any) { }
  
  ngAfterViewInit(): void {
    this.shouldUseText = this.params.node.data.profileTypeCd === 'PE'
      || this.params.node.data.profileTypeCd === 'SE'
      || this.params.node.data.profileTypeCd === 'IM';
  }
}
