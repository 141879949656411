export enum VerticalEnum {
  SETTLEMENT = 'settlement',
  REPORTING = 'reporting',
  WORKFLOW = 'workflow',
  INFOPOST = 'infopost',
  PHYSICAL = 'physical',
  WORKLOAD = 'workload',
  BACKEND = 'backend',
  VALUE_DOMAIN_SETS = 'value domain sets',
  VALIDATIONS = 'Validations',
  COMMON_RECURRING = 'common - recurring',
  FLOW = 'flow',
  SETTLEMENT_BASE = 'settlement - base',
  REPORTING_BASE = 'reporting - base',
  EDI = 'edi',
  FLOWING_GAS = 'flowing gas',
  SECURITY = 'security',
  REPORTING_RECURRING = 'reporting - recurring',
  SCHEDULING = 'Scheduling',
  RIGHTS = 'rights',
  AGREEMENT = 'agreement',
  COMMON = 'common',
  UTILITY = 'utility',
  ASSOCIATE = 'associate',
  ENVOY = 'envoy',
  NOMINATION = 'nomination',
  AUCTION = 'auction'
}
