
import {
  Component,
  Injector,
  Input,
  Output,
  ElementRef,
  OnInit,
  OnDestroy,
  ContentChild,
  ContentChildren,
  ViewChild,
  EventEmitter,
  HostBinding,
  QueryList
} from '@angular/core';
import { EmitterService } from 'src/cad/core/services/emitter/emitter.service';
import { ActionService } from 'src/cad/common/services/security/action.service';
import { AutoUnsubscribables, AutoUnsubscriber } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import { TableComponent } from 'src/ag-grid-wrapper/table/table.component';
import { UiExpandableDirective } from 'src/common/components/expandable/expandable.directive';
import * as _ from 'lodash';
import { SidenavService } from 'src/common/services/sidenav/sidenav';

@Component({
  selector: 'ui-card',
  templateUrl: './card.component.html',
  styleUrls: [ './card.component.less' ],
})
export class UiLayoutCardComponent implements OnInit, OnDestroy {

  @Input() public headline: string;
  @Input() public subhead: string;
  @Input() public cardToolBarItems: string;
  @Input() public enableMax: boolean = false;
  @Input() public enableExpand: boolean = true;
  @Input() public skipDisable: boolean = false;
  @Input() public context: string = 'ui-content';

  /**
   * This is an experimental fullscreen option.  If you use this, it will
   * give you a pure css fullscreen.  This feature gives you the same functionality
   * as enableMax but will work in scenarios where enableMax does not.  Because
   * enableMax is a little buggy, we should eventually switch to this implementation
   * once it's fully tested.
   *
   * This feature SHOULD NOT be used in conjunction with the enableMax option.
   */
  @Input() public allowFullscreen: boolean = false;
  @Input() @HostBinding('class.fullscreen-card') public isFullscreen: boolean = false;
  @Input()
  public get isFullHeight(): boolean {
    return this._isFullHeight;
  }
  public set isFullHeight(val: boolean) {
    this._isFullHeight = _.defaultTo(val, false);
    Promise.resolve().then(() => {
      this.tableComponentList.forEach((tableComponent: TableComponent) => {
        tableComponent.shrinkToDataHeight = !this._isFullHeight;
        tableComponent.renderFullHeight = this._isFullHeight;
      });
      this.isFullHeightChange.emit(this._isFullHeight);
      if (this._isFullHeight) {
        this.sidenavService.setStateById(this.element.nativeElement.id);
      }
    });
  }

  @Input()
  public get isExpanded(): boolean {
    return this._isExpanded;
  }
  public set isExpanded(val: boolean) {
    this._isExpanded = val;
    this.isExpandedChange.emit(this._isExpanded);
  }
  @Output() public isFullHeightChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public isExpandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ContentChild('uiCardFooter') public cardFooter: QueryList<ElementRef>;
  @ViewChild('uiExpandableRef') public uiExpandableRef: UiExpandableDirective;
  @ContentChildren(TableComponent, { descendants: true }) public contentTableList: QueryList<TableComponent>;

  public get tableComponentList(): TableComponent[] {
    return this.contentTableList ? this.contentTableList.toArray() : [];
  }
  public showFoooter: boolean = false;
  public disabled: boolean = true;
  public hasAdd: boolean;
  public cardToolbarPadding: number = 10;
  private _isExpanded: boolean = true;
  private _isFullHeight: boolean = false;
  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  constructor(
    public injector: Injector,
    private actionService: ActionService,
    private emitterService: EmitterService,
    private sidenavService: SidenavService,
    private element: ElementRef
  ) { }

  ngOnInit(): void {
    this.subs.newSub =
      this.emitterService.actionContextResolved.subscribe(() => {
        this.disableForm();
      });
    this.sidenavService.registerCard(this.element);
  }

  ngAfterViewInit(): void {
    this.showFoooter = !_.isNil(this.cardFooter);
    setTimeout(() => {
      this.hasAdd = this.element
        && this.element.nativeElement
        && !!this.element.nativeElement.querySelector('.mat-card-content button.mat-mini-fab');
      this.cardToolbarPadding = this.hasAdd ? 80 : 10;
      if (this.allowFullscreen) {
        this.cardToolbarPadding = Math.max(this.cardToolbarPadding - 40, 10);
      }
    });
  }

  ngOnDestroy(): void {
    this.isFullHeightChange.complete();
    this.isFullHeightChange = null;
    this.isExpandedChange.complete();
    this.isExpandedChange = null;
    this.cardToolBarItems = null;
  }

  public isDisabled(): boolean {
    if (this.skipDisable) { return false; }
    return !this.actionService.canPerformAction(cad.actionConstants.ACTION_SAVE);
  }

  public executeAction(event: any, action: Function): void {
    /**
     * if we let this event bubble, the card will collapse
     * instead of running the desired action.
     */
    event.stopPropagation();
    // Stop editing on child tables to ensure the cells have the updated value
    this.tableComponentList.forEach((tableComp: TableComponent) => {
      if (tableComp.api
        && tableComp.api.rowEdit
        && tableComp.api.rowEdit.hasEditingCells()) {
        tableComp.api.stopEditing();
      }
    });
    setTimeout(() => action());
  }

  private disableForm(): void {
    this.disabled = this.isDisabled();
  }

  public makeFullscreen(evt: any): void {
    evt.stopPropagation();
    this.isFullscreen = !this.isFullscreen;
    this.isFullHeightChange.emit(this._isFullHeight);
    if (this.isFullscreen) {
      this.sidenavService.setStateById(this.element.nativeElement.id);
    }
  }

  getStatus(item: any): boolean {
    if (item.isShow instanceof Function) {
      return item.isShow();
    } else {
      return true;
    }
  }
}
