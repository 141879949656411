
import {concatMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { FeatureFlagsService } from 'src/common/services/feature-flags/feature-flags.service';
import * as _ from 'lodash';

@Injectable()
export class ApprovalsApi {
  
  private readonly endpoint: string = 'agreement/contract/approvals';
  private readonly assetLiveEndpoint: string = 'bedrock/approval';

  constructor(
    private apiHelper: ApiHelper,
    private featureFlagsService: FeatureFlagsService
  ) {}

  public filter(params: any): Observable<any> {
    if (this.featureFlagsService) {
      return this.featureFlagsService.isFeatureActive('asset-live').pipe(
      concatMap((result: any): Observable<any> => result
        ? this.apiHelper.request(`${ this.assetLiveEndpoint }/filter`, { body: params })
        : this.apiHelper.request(`${ this.endpoint }/filter`, { body: params })));
    } else {
      return this.apiHelper.request(`${ this.endpoint }/filter`, { body: params });
    }
  }

  public findAll(approvalId: number, businessTypeCd: string): Observable<any> {
    if (this.featureFlagsService) {
      return this.featureFlagsService.isFeatureActive('asset-live').pipe(
      concatMap((result: any): Observable<any> => result
        ? this.apiHelper.request(`${ this.assetLiveEndpoint }/${ approvalId }/${ businessTypeCd }`)
        : this.apiHelper.request(`${ this.endpoint }/${ approvalId }`)));
    } else {
      return this.apiHelper.request(`${ this.endpoint }/${ approvalId }`);
    }
  }

  public save(approvalList: any[], quiet?: boolean): Observable<any> {
    let approvalId: number | string;
  
    if (_.isArray(approvalList)) {
      if (!_.isEmpty(approvalList)) {
        approvalId = approvalList[ 0 ].agreementId;
      }
      approvalList.forEach((item: any) => {
        if (item) {
          if (_.isNil(item.statusCV)) {
            item.statusCV = {};
          }
          item.statusCV.cd = item.statusCd;
        }
      });
    }
    if (this.featureFlagsService) {
      return this.featureFlagsService.isFeatureActive('asset-live').pipe(
      concatMap((result: any): Observable<any> => {
        if (result) {
          if (_.isArray(approvalList) && !_.isEmpty(approvalList)) {
            approvalId = approvalList[ 0 ].approvalId;
          }
          return this.apiHelper.request(`${ this.assetLiveEndpoint }/${ approvalId }`, { body: approvalList, quiet });
        } else {
          return this.apiHelper.request(`${ this.endpoint }/${ approvalId }`, { body: approvalList, quiet });
        }
      }));
    } else {
      return this.apiHelper.request(`${ this.endpoint }/${ approvalId }`, { body: approvalList, quiet });
    }
  }

  public getHistory(approvalId: number, businessTypeCd: string): Observable<any> {
    if (this.featureFlagsService) {
      return this.featureFlagsService.isFeatureActive('asset-live').pipe(
      concatMap((result: any): Observable<any> => result
        ? this.apiHelper.request(`${ this.assetLiveEndpoint }/${ approvalId }/${ businessTypeCd }/history`)
        : this.apiHelper.request(`${ this.endpoint }/${ approvalId }/history`)));
    } else {
      return this.apiHelper.request(`${ this.endpoint }/${ approvalId }/history`);
    }
  }
}
