import { Component, ContentChild, Input, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { TableComponent } from 'src/ag-grid-wrapper/table/table.component';

@Component({
  selector: 'ui-search-results',
  templateUrl: './search-results.component.html',
})
export class SearchResultsComponent implements OnChanges {

  @ContentChild(TableComponent) public tableComponent: TableComponent;
  @Input() public params: any;
  @Input() public results: any[] = [];

  public paramKeys: string[] = [];

  constructor() {}

  public getFieldLabel(field: string): void {
    // return ctrl.search.formGroups['ctrl_params_' + field] ? ctrl.search.formGroups['ctrl_params_' + field].label : field;
  }

  ngOnChanges(changes: any): void {
    if(changes.results && changes.results.currentValue) {
      this.paramKeys = Object.keys(this.params);
    }
  }

  public getFieldValue(field: any): any {
    let value = this.params[field];

    if (_.isDate(value)) {
      value = moment(value).format('MM/DD/YYYY');
    }

    return value;
  }

  public removeParam(param: string): void {
    delete this.params[param];
    this.paramKeys = this.paramKeys.filter((p) => this.params[p]);
  }

  public clearParams(): void {
    if (this.params) {
      Object.keys(this.params).forEach((key) => {
        delete this.params[key];
      });
    }
    this.results = [];
    this.paramKeys = [];
  }
}
