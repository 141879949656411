<div class="ui-updater-field">
  <span>{{label}}&nbsp;</span>
  <ng-template #contactTemplate>
    <a *ngIf="!noLink"
       href="javascript:;"
       (click)="navigateToContact()">{{userData.fullName}}</a>
    <span *ngIf="noLink">{{userData.fullName}}</span>
  </ng-template>
  <ng-container *ngIf="!isUpdaterContact; else contactTemplate">
    <span>{{userName}}</span>
  </ng-container>
  <span>&nbsp;{{updateDateStr}}</span>
</div>
