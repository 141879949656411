import { Component, ComponentRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AssociationController } from '../../association.controller';
import { FullscreenDialogComponent } from '../fullscreen-dialog/fullscreen-dialog.component';

@Component({
  selector: 'ui-footer-association-dialog',
  templateUrl: './fullscreen-dialog-footer.component.html',
  styleUrls: [ './fullscreen-dialog-footer.component.less' ],
})
export class FullscreenDialogFooterComponent implements OnDestroy {
  @Input() isFormDirty: boolean = false;
  @Input() selectedContentIndex: number;
  @Input() associationController: AssociationController;
  @Input() selectedTabIdx: number;
  @Output() nextContent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() handleSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleNewItem: EventEmitter<any> = new EventEmitter<any>();

  public chipComponentRefs: ComponentRef<any>[] = [];

  constructor(
    private associationDialogComponent: FullscreenDialogComponent,
  ) {
  }

  ngOnDestroy(): void {
    this.chipComponentRefs.forEach((item) => item.destroy());
    this.chipComponentRefs.splice(0);
  }

  public onChipReady(compRef: ComponentRef<any>, index: number): void {
    compRef.onDestroy(() => {
      let compIndex = this.chipComponentRefs.indexOf(compRef);
      if (compIndex > -1) {
        this.chipComponentRefs.splice(compIndex, 1);
      }
    });
    if (this.associationController.model) {
      compRef.instance.valueProperty = this.associationController.model.modalChipValueProperty
        || this.associationController.model.primaryKey
        || 'id';
      compRef.instance.viewValueProperty = this.associationController.model.modalChipViewValueProperty
        || this.associationController.model.primaryKey
        ? this.associationController.model.primaryKey.replace(/Cd$/g, 'Name')
        : 'name';
    }
    this.chipComponentRefs.splice(index, 1, compRef);
  }

  public disableSubmit(): boolean {
    return !(this.associationDialogComponent.uiFormComponent.form.valid && this.isFormDirty);
  }

  nextPage(): void {
    this.nextContent.emit(true);
  }

  submit(event: any): void {
    this.handleSubmit.emit(event);
  }

  submitNewItem(): void {
    this.handleNewItem.emit();
  }

  cancel(): void {
    if (this.associationDialogComponent) {
      this.associationDialogComponent.tabIndexChange(0);
    }
  }

  clear(): void {
    if (this.associationDialogComponent.searchReference && this.associationDialogComponent.searchReference.instance) {
      this.associationDialogComponent.searchReference.instance.params = {};
    }
  }

  removeSelectedItem(item: any): void {
    const index = this.associationController.selectedItems.indexOf(item, 0);
    if (index > -1) {
      this.associationController.selectedItems.splice(index, 1);
      this.associationController.chipInjectors.splice(index, 1);
    }
    if (this.selectedTabIdx === 0
      && this.selectedContentIndex === 1
      && this.associationController.selectedItems.length === 0) {
      this.nextContent.emit(false);
    }
  }
  public isEqual(obj1: any, obj2: any): boolean {
    return _.isEqual(obj1, obj2);
  }
}
