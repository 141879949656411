import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class SchedulingChargeWaiverApi {
  private readonly endpoint: string = 'flowing-gas/scheduling-charge/scheduling-charge-waiver';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  // ========================================
  // implementation
  // ========================================

  public filter = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public findAll = (): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/filter', { });
  }

  public findSchedulingCharge = (data: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/find', {body: data });
  }

  public save = (data: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/save', {body: data });
  }

}
