
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'workflowStatusIconColor' })
export class WorkflowStatusIconColorPipe implements PipeTransform {
  transform(status: string): any {
    if (status == undefined) { return ''; }
    return cad.statusMap[status][1];
  }
}

@Pipe({ name: 'workflowStatusIconName' })
export class WorkflowStatusIconNamePipe implements PipeTransform {
  transform(status: string): any {
    if (status == undefined) { return ''; }
    return cad.statusMap[status][0];
  }
}
