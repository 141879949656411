import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ApiHelper} from 'cad/common/services/api/api-helper';
import {Workflow} from 'cad/administration/table-maintenance/shared/interfaces/workflow';

@Injectable()
export class WorkflowApi {

  private readonly endpoint: string = '/workflow';

  constructor(
    private apiHelper: ApiHelper) {
  }

  public filter(params: Workflow): Observable<Workflow[]> {
    return this.apiHelper.request(this.endpoint +'/find',{ body: params });
  }

}
