import { NgModule } from '@angular/core';
import { YesNoPipe } from 'src/cad/common/filters/yes-no/yesno.pipe';
import { WorkflowStatusIconNamePipe, WorkflowStatusIconColorPipe } from 'src/cad/common/filters/workflow-status/workflow.status.pipe';
import { PhoneNumberPipe } from 'src/cad/common/filters/phone-number/phone-number.pipe';
import { NoSpacePipe } from 'src/cad/common/filters/no-space/no-space.pipe';
import { HumanizeDocPipe } from 'src/cad/common/filters/humanize-doc/humanize-doc.pipe';
import { ETCodePipe } from 'src/cad/common/filters/et-codes/et-codes.pipe';
import { DunsNumberPipe } from 'src/cad/common/filters/duns-number/duns-number.pipe';
import { ToDatePipe, ToFormattedDatePipe, ToFormattedDateTimeLocalPipe } from 'src/cad/common/filters/dates/date.pipe';
import { CapitalizePipe } from 'src/cad/common/filters/capitalize/capitalize.pipe';
import { MapToIterable } from 'src/cad/common/filters/mapToIterable/map-to-iterable.pipe';
import { SafeUrlPipe } from 'src/cad/common/filters/safe-url/safe-url.pipe';
import { FilterByNamePipe } from 'src/cad/common/filters/filter-by-name/filter-by-name.pipe';
import { DeleteDirtyStatusPipe } from 'src/cad/common/filters/non-delete-dirty-status/non-delete-dirty-status.pipe';
import { SecurityDisabledPipe } from 'src/cad/common/filters/security-disabled/security-disabled.pipe';
import { HighlightTextPipe } from 'cad/common/filters/highlight-text/highlight-text.pipe';

@NgModule({
  declarations: [
    YesNoPipe,
    WorkflowStatusIconNamePipe,
    WorkflowStatusIconColorPipe,
    PhoneNumberPipe,
    NoSpacePipe,
    HumanizeDocPipe,
    ETCodePipe,
    DunsNumberPipe,
    ToDatePipe,
    ToFormattedDatePipe,
    ToFormattedDateTimeLocalPipe,
    CapitalizePipe,
    MapToIterable,
    SafeUrlPipe,
    FilterByNamePipe,
    DeleteDirtyStatusPipe,
    SecurityDisabledPipe,
    HighlightTextPipe
  ],
  exports: [
    YesNoPipe,
    WorkflowStatusIconNamePipe,
    WorkflowStatusIconColorPipe,
    PhoneNumberPipe,
    NoSpacePipe,
    HumanizeDocPipe,
    ETCodePipe,
    DunsNumberPipe,
    ToDatePipe,
    ToFormattedDatePipe,
    ToFormattedDateTimeLocalPipe,
    CapitalizePipe,
    MapToIterable,
    SafeUrlPipe,
    FilterByNamePipe,
    DeleteDirtyStatusPipe,
    SecurityDisabledPipe,
    HighlightTextPipe
  ]
})
export class PipesModule {}
