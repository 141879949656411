import {ModalModel} from 'src/features/common/modal/modal.model';
import {BusinessAssociateApi} from 'cad/common/services/api/associate/business-associate/business-associate';
import {AllocateDailyApi} from 'cad/common/services/api/flowing-gas/allocate-daily/allocate-daily';
import {NominationMatrixContractApi} from 'cad/common/services/api/nomination/nomination-matrix/contract/contract';
import {NominationMatrixContractPointApi} from 'cad/common/services/api/nomination/nomination-matrix/contract/point/nomination-matrix-contract-point.api';
import {NominationMatrixContractActivityCodeApi} from 'cad/common/services/api/nomination/nomination-matrix/contract/activity-code/activity-code';
import {OperationalBusinessAssociate} from 'cad/common/services/api/lookup/operational-business-associate';
import { FilterOptionsEnum } from 'common/enums/filter-options.enum';


export class ModalConfigs {
  public static readonly businessAssociateItemModel: ModalModel = {
    name: 'businessAssociateLookup',
    title: 'Business Associate Lookup',
    type: 'lookup',
    api: BusinessAssociateApi,
    multiSelection: false,
    okButtonText: 'SELECT',
    lookupInputs: {
      inputs: [
        { type: 'text', propertyName: 'baName', name: 'BA Name' },
        { type: 'text', propertyName: 'baAbbrName', name: 'BA Abbr.' },
        { type: 'numberStr', propertyName: 'baId', name: 'BA Nbr.'},
        { type: 'text', propertyName: 'dunsNbr', name: 'BA Duns' },
        { type: 'select', propertyName: 'baTypeCd', name: 'BA Type Desc', codeType: 'BATYPE'},
      ]
    },
    dialogConfig: {
      width: '80%',
    },
    returnObjMap: {
      baName: 'baName',
      baId: 'baId',
      baAbbrName: 'baAbbrName',
      dunsNbr: 'dunsNbr',
      effBeginDt: 'effBeginDt',
      effEndDt: 'effEndDt'
    },
    transformObjFn: (row: any): any => {
      return {...row, upDnID: row.baId};
    }
  };

  public static readonly confirmationPointLookupModel: ModalModel = {
    autoForExternal: true,
    name: 'pointLookup',
    title: 'Location Lookup',
    type: 'lookup',
    api: AllocateDailyApi,
    showSearchButton: true,
    okButtonText: 'Select',
    cancelButton: true,
    cancelButtonText: 'Cancel',
    lookupInputs: {
      inputs: [
        { type: 'text', propertyName: 'pointCd', name: 'Location', disableInForm: false, hideInForm: false },
        { type: 'text', propertyName: 'pointName', name: 'Loc Name', disableInForm: false, hideInForm: false },
        { type: 'text', propertyName: 'assetAbbr', name: 'Asset Abbr', disableInForm: false, hideInForm: false },
        { type: 'select', propertyName: 'pointTypeCd', name: 'Type', disableInForm: false, hideInForm: false, codeType: 'POINTTYPE' },
        { type: 'select', propertyName: 'zoneCd', name: 'Zone', disableInForm: false, hideInForm: false, codeType: 'ZONE' },
        { type: 'select', propertyName: 'flowDirInd', name: 'Dir Flo', disableInForm: false, hideInForm: false, codeType: 'DIRFLOW' },
        { type: 'select', propertyName: 'gatheringTransmissionInd', name: 'G/T', disableInForm: false, hideInForm: false, codeType: 'GATHTRN' },
        { type: 'text', propertyName: 'operatorAgentName', name: 'Oper Agent', disableInForm: false, hideInForm: false },
        { type: 'text', propertyName: 'operatorName', name: 'Operator', disableInForm: false, hideInForm: false },
        { type: 'text', propertyName: 'producerName', name: 'Producer', disableInForm: false, hideInForm: false },
        { type: 'text', propertyName: 'analystLastName', name: 'Analyst', disableInForm: false, hideInForm: false },
        { type: 'text', propertyName: 'countyName', name: 'County', disableInForm: true, hideInForm: false },
        { type: 'states', propertyName: 'stateCd', name: 'State', disableInForm: false, hideInForm: false },
        { type: 'select', propertyName: 'statusCd', name: 'Status', disableInForm: false, hideInForm: false,
          codeType: 'POINTSTATUS', defaultFilterType: 'text', defaultFilterOperator: FilterOptionsEnum.EQUALS, defaultFilter: 'ACTIVE' }
      ]
    },
    dialogConfig: {
      width: '90%',
    },
    returnObjMap: { pointCd: 'pointCd', pointName: 'pointName' }
  };

  public static readonly c2cContractLookupModel: ModalModel = {
    name: 'contractLookup',
    title: 'Contract Lookup',
    type: 'lookup',
    api: NominationMatrixContractApi,   // search by serviceName or serviceId doesn't work with nomination/nomination-matrix/contract/filter
    lookupInputs: {
      inputs: [
        { type: 'numberStr', propertyName: 'contractId', name: 'Svc Req K', sortObj: { direction: 'asc', priority: 0 } },
        { type: 'rateschedules', propertyName: 'rateScheduleCd', name: 'Rate Schedule' },
        { type: 'select', propertyName: 'statusCd', name: 'Status', codeType: 'CONTRACT_STATUS_C2C' },
        { type: 'text', propertyName: 'serviceRequesterName', name: 'Svc Req', addWildCard:'prefix_and_suffix' },
      ]
    },
    dialogConfig: {
      width: '80%',
    },
    okButtonText: 'SELECT',
    returnObjMap: {
      contractId: 'contractId'
    }
  };

  public static readonly contractLookupModel: ModalModel = {
    name: 'contractLookup',
    title: 'Contract Lookup',
    type: 'lookup',
    api: NominationMatrixContractApi,   // search by serviceName or serviceId doesn't work with nomination/nomination-matrix/contract/filter
    lookupInputs: {
      inputs: [
        { type: 'numberStr', propertyName: 'contractId', name: 'Svc Req K', sortObj: { direction: 'asc', priority: 0 } },
        { type: 'select', propertyName: 'replInd', name: 'Repl Ind', codeType: 'CONTRACTTYPEIND' },
        { type: 'rateschedules', propertyName: 'rateScheduleCd', name: 'Rate Sched' },
        { type: 'serviceNames', propertyName: 'serviceName', name: 'Description', disableInForm: true },
        { type: 'select', propertyName: 'statusCd', name: 'Status', codeType: 'CONTRACTSTATUS' },
        { type: 'text', propertyName: 'serviceRequesterName', name: 'Svc Req Name', addWildCard:'prefix_and_suffix' },
        { type: 'text', propertyName: 'serviceRequesterAgentName', name: 'Svc Req Agent' },
        { type: 'number', propertyName: 'mdcq', name: 'MDCQ/MSQ', disableInForm: true },
        { type: 'text', propertyName: 'mrpl', name: 'MRPL' },
        { type: 'text', propertyName: 'mdpl', name: 'MDPL' },
        { type: 'text', propertyName: 'internalAnalyst', name: 'Internal Analyst' },
        { type: 'date', propertyName: 'terminationDt', name: 'Term Date', disableInForm: true, hideInForm: true },
      ]
    },
    dialogConfig: {
      width: '80%',
    },
    okButtonText: 'SELECT',
    returnObjMap: {
      contractId: 'contractId'
    }
  };

  public static readonly receiptNominationsContractLookupModel: ModalModel = {
    name: 'contractLookup',
    title: 'Contract Lookup',
    type: 'lookup',
    api: NominationMatrixContractApi,   // search by serviceName or serviceId doesn't work with nomination/nomination-matrix/contract/filter
    autoSearch: true,
    enableFilter: true,
    lookupInputs: {
      inputs: [
        { type: 'numberStr', propertyName: 'contractId', name: 'Svc Req K', sortObj: { direction: 'asc', priority: 0 }, hideInForm: true, suppressFilter: false },
        { type: 'select', propertyName: 'replInd', name: 'Repl Ind', codeType: 'CONTRACTTYPEIND', hideInForm: true, suppressFilter: false },
        { type: 'rateschedules', propertyName: 'rateScheduleCd', name: 'Rate Sched', hideInForm: true, suppressFilter: false },
        { type: 'serviceNames', propertyName: 'serviceName', name: 'Description', disableInForm: true, hideInForm: true, suppressFilter: false },
        { type: 'select', propertyName: 'statusCd', name: 'Status', codeType: 'CONTRACTSTATUS', hideInForm: true, suppressFilter: false },
        { type: 'text', propertyName: 'serviceRequesterName', name: 'Svc Req Name', addWildCard:'prefix_and_suffix', hideInForm: true, suppressFilter: false },
        { type: 'text', propertyName: 'serviceRequesterAgentName', name: 'Svc Req Agent', hideInForm: true, suppressFilter: false },
        { type: 'number', propertyName: 'mdcq', name: 'MDCQ/MSQ', disableInForm: true, hideInForm: true, suppressFilter: false },
        { type: 'text', propertyName: 'mrpl', name: 'MRPL', hideInForm: true, suppressFilter: false },
        { type: 'text', propertyName: 'mdpl', name: 'MDPL', hideInForm: true, suppressFilter: false },
        { type: 'text', propertyName: 'internalAnalyst', name: 'Internal Analyst', hideInForm: true, suppressFilter: false },
        { type: 'date', propertyName: 'terminationDt', name: 'Term Date', disableInForm: true, hideInForm: true, suppressFilter: false },
      ]
    },
    dialogConfig: {
      width: '80%',
    },
    okButtonText: 'SELECT',
    returnObjMap: {
      contractId: 'contractId'
    }
  };

  public static readonly nomContractPointLookupModel: ModalModel = {
    name: 'pointLookup',
    title: 'Location Lookup',
    type: 'lookup',
    api: NominationMatrixContractPointApi,
    okButtonText: 'Select',
    lookupInputs: {
      inputs: [
        { type: 'text', propertyName: 'pointCd', name: 'Location' },
        { type: 'text', propertyName: 'pointName', name: 'Loc Name'},
        { type: 'text', propertyName: 'assetAbbr', name: 'Asset Abbr'},
        { type: 'select', propertyName: 'pointTypeCd', name: 'Type', codeType:'POINTTYPE' },
        { type: 'select', propertyName: 'zoneCdCd', name: 'Zone', codeType: 'ZONE' },
        { type: 'text', propertyName: 'tabsAreaCd', name: 'TABS' },
        { type: 'select', propertyName: 'flowDirCd', name: 'Dir Of Flow', codeType: 'DIRFLOW' },
        { type: 'select', propertyName: 'gatheringTransmissionIndCd', name: 'G/T', codeType: 'GATHTRN' },
        { type: 'select', propertyName: 'primarySecondaryIndCd', name: 'P/S', codeType: 'PRIMARYIND' },
        { type: 'text', propertyName: 'operatorAgentName', name: 'Oper Agent' },
        { type: 'text', propertyName: 'operatorName', name: 'Operator' },
        { type: 'text', propertyName: 'analystLastName', name: 'Analyst' },
        { type: 'text', propertyName: 'countyCV.value', name: 'County Name' },
        { type: 'text', propertyName: 'stateCV.value', name: 'State' },
      ]
    },
    dialogConfig: {
      width: '90%',
    },
    returnObjMap: { pointCd: 'pointCd', pointName: 'pointName' }

  };

  public static readonly activityCodeItemModel: ModalModel = {
    name: 'activityCodeLookup',
    title: 'Activity Code Lookup',
    type: 'lookup',
    api: NominationMatrixContractActivityCodeApi,
    multiSelection: false,
    autoSearch: true,
    okButtonText: 'SELECT',
    enableFilter: true,
    lookupInputs: {
      inputs: [
        { type: 'number', propertyName: 'activityNbr', name: 'Act Cd', hideInForm: true },
        { type: 'text', propertyName: 'transactionTypeCd', name: 'TT', hideInForm: true },
        { type: 'text', propertyName: 'receiptPointCd', name: 'Rec Loc Prop', hideInForm: true },
        { type: 'text', propertyName: 'receiptPtDrnNbr', name: 'Rec Loc', hideInForm: true },
        { type: 'text', propertyName: 'receiptPtName', name: 'Rec Loc Name', hideInForm: true },
        { type: 'text', propertyName: 'receiptRef1Name', name: 'Up Name', hideInForm: true },
        { type: 'text', propertyName: 'receiptRef1DunsNbr', name: 'Up ID', hideInForm: true },
        { type: 'text', propertyName: 'receiptRef2Cd', name: 'Up K', hideInForm: true },
        { type: 'text', propertyName: 'deliveryPointCd', name: 'Del Loc Prop', hideInForm: true },
        { type: 'text', propertyName: 'deliveryPtDrnNbr', name: 'Del Loc', hideInForm: true },
        { type: 'text', propertyName: 'deliveryPtName', name: 'Del Loc Name', hideInForm: true },
        { type: 'text', propertyName: 'deliveryRef1Name', name: 'Dn Name', hideInForm: true },
        { type: 'text', propertyName: 'deliveryRef1DunsNbr', name: 'Dn ID', hideInForm: true },
        { type: 'text', propertyName: 'deliveryRef2Cd', name: 'Dn K', hideInForm: true },
        { type: 'text', propertyName: 'packageId', name: 'Package Id', hideInForm: true },
      ]
    },

    dialogConfig: {
      width: '80%',
    },

  };

  public static readonly operationalBusinessAssociateItemModel: ModalModel = {
    name: 'operationalbusinessAssociateLookup',
    title: 'OBA/DPOA Contract Lookup',
    type: 'lookup',
    api: OperationalBusinessAssociate,
    multiSelection: false,
    okButtonText: 'SELECT',
    lookupInputs: {
      inputs: [
        { type: 'numberStr', propertyName: 'contractId', name: 'Contract ID' },
        { type: 'text', propertyName: 'serviceRequesterName', name: 'Service Requestor Name', hideInForm:true },
        { type: 'date', propertyName: 'flowStartDate', name: 'Flow Start Date', hideInTable:true },
        { type: 'select', propertyName: 'rateScheduleCd', name: 'Rate Schedule', hideInTable:false, codeType: 'OBACONTRACTLOOKUP' },
        { type: 'number', propertyName: 'baId', name: 'BA Number', hideInTable:true },
        { type: 'number', propertyName: 'assetNbr', name: 'Assert Number', hideInForm:true, hideInTable:true},
      ]
    },
    dialogConfig: {
      width: '80%',
    },
    returnObjMap: {
      id: 'contractId',
      rateScheduleCd:'rateScheduleCd',
      baId: 'baId',
    },
    transformObjFn: (row: any): any => {
      return row;
    }
  };

  public static readonly pointLookupModel: ModalModel = {
    name: 'pointLookup',
    title: 'Location Lookup',
    type: 'lookup',
    api: AllocateDailyApi,
    showSearchButton: true,
    okButtonText: 'Select',
    cancelButton: true,
    cancelButtonText: 'Cancel',
    lookupInputs: {
      inputs: [
        { type: 'text', propertyName: 'pointCd', name: 'Location' },
        { type: 'select', propertyName: 'pointClassCd', name: 'Point Class', codeType:'POINTCLASS' },
        { type: 'select', propertyName: 'pointTypeCd', name: 'Point Type', codeType: 'POINTTYPE' },
        { type: 'text', propertyName: 'pointName', name: 'Point Name' },
        { type: 'text', propertyName: 'stateCd', name: 'State' },
        { type: 'text', propertyName: 'countyName', name: 'County Name' },
        { type: 'text', propertyName: 'segmentName', name: 'Segment' },
        { type: 'select', propertyName: 'statusCd', name: 'Status', codeType: 'POINTSTATUS' },
        { type: 'select', propertyName: 'flowDirCd', name: 'Dir Of Flow', codeType: 'DIRFLOW' },
        { type: 'text', propertyName: 'operatorName', name: 'Operator' },
        { type: 'text', propertyName: 'operatorAgentName', name: 'Operator Agent' },
        { type: 'number', propertyName: 'assetNumber', name: 'Asset Number', hideInTable: true, hideInForm: true },
      ]
    },
    dialogConfig: {
      width: '90%',
    },
    returnObjMap: { pointCd: 'pointCd', pointName: 'pointName' }

  };

}
