import { Directive, Input, OnInit } from '@angular/core';
import { TableColumnComponent } from 'src/ag-grid-wrapper';
import {TableColumnDefaultEditorComponent} from 'src/ag-grid-wrapper/column/default/default-editor.component';
import {DatepickerEditorComponent} from 'cad/shared/table-renderers/datepicker/datepicker-editor.component';
import {UiGridApi} from 'src/ag-grid-wrapper/ui-grid-api/ui-grid-api';
import {TableComponent} from 'src/ag-grid-wrapper/table/table.component';
import {SelectCodeEditorComponent} from 'cad/shared/table-renderers/select-code/select-code-editor.component';
import {TimeEditorComponent} from 'cad/shared/table-renderers/time/time-editor.component';

@Directive({
  selector: 'ui-table-column[dynamicTypeEditor]',
})
export class DynamicTypeEditorDirective implements OnInit {
  private _dynamicType: string;

  constructor(public tableColumn: TableColumnComponent) {}

  @Input()
  set dynamicType(val: string) {
    this._dynamicType = val;
    this.updateType();
  }

  get dynamicType(): string {
    return this._dynamicType;
  }

  ngOnInit(): void {
    this.updateType();
  }

  private updateType(): void {
    if (this.dynamicType) {
      this.initializeType();
      this.refreshTableColDefs();
    }
  }

  /**
   * This method will make sure that the defined tableColumn (assuming there were changes to it) is refreshed into the table and all
   * editor/renderer changes are taken and applied
   */
  private refreshTableColDefs(): void {
    if ((this.tableColumn as any).tableComponent.api) {
      this.tableColumn.configureColumn();
      let table: TableComponent = (this.tableColumn as any).tableComponent;
      let api: UiGridApi = table.api;
      let colDef = this.tableColumn.toColDef();

      table.columnDefs.forEach((col, i) => {
        if (col.field === this.tableColumn.field) {
          table.columnDefs[i] = colDef;
        }
      });
      api.setColumnDefs(table.columnDefs);
      api.sizeColumnsToFit();
      // api.refreshCells(); - Do not appear to need this anymore
    }
  }
  private initializeType(): any {
    this.tableColumn.cellFilter = null;
    this.tableColumn.valueFormatter = null;
    if (this.dynamicType === 'String') {
      this.tableColumn.type = 'string';
      this.tableColumn.customEditor = TableColumnDefaultEditorComponent;
    }  else if (this.dynamicType === 'Y/N') {
      this.tableColumn.type =  'custom';
      this.tableColumn.customEditor = SelectCodeEditorComponent;
      this.tableColumn.cellFilter = 'YESNO';
    } else if (this.dynamicType === 'Date') {
      this.tableColumn.type =  'custom';
      this.tableColumn.cellFilter =  'date';
      this.tableColumn.customEditor = DatepickerEditorComponent;
    } else if (this.dynamicType === 'DateTime') {
      this.tableColumn.type =  'custom';
      this.tableColumn.cellFilter = 'full';
      this.tableColumn.customEditor = DatepickerEditorComponent;
    } else if (this.dynamicType === 'Time') {
      this.tableColumn.type =  'custom';
      this.tableColumn.customEditor = TimeEditorComponent;
    } else {
      this.tableColumn.type = 'string';
      this.tableColumn.customEditor = TableColumnDefaultEditorComponent;
    }
  }

}
