
import { throwError as observableThrowError, of as observableOf, Observer, Observable } from 'rxjs';

import { catchError, timeoutWith, concatMap, finalize, share } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import { AlertsService } from 'cad/core/services/alerts/alerts.service';
import { CacheFactory } from 'common/services/cache-factory/cache-factory';
import { EmitterService } from 'cad/core/services/emitter/emitter.service';
import { ConfigService } from 'cad/core/services/config/config.service';
import { ApiExceptionHandlerService } from 'src/cad/common/services/api/api-exception-handler';
import ValidationResult = cad.ValidationResult;
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpResponseTypeEnum } from './api-enums';
import { ApiRequestOptionsArgs } from './interfaces/api-request-options-args';
import { isObject } from 'lodash';

const defaultTimeout: number = 120000;

@Injectable()
export class ApiHelper {
  private pendingRequestCache: { [ key: string ]: Observable<any> } = {};

  constructor(
    private ngZone: NgZone,
    private alerts: AlertsService,
    private httpClient: HttpClient,
    private emitterService: EmitterService,
    private configService: ConfigService,
    private apiExceptionHandler: ApiExceptionHandlerService,
  ) { }

  public getFullUrl = (request: string): string => {
    return this.configService.cadConfig.secondaryUrl + this.configService.cadConfig.baseApiUrl + request;
  }

  public request(endpoint: string, options?: ApiRequestOptionsArgs): Observable<any> {
    if (!options) {
      options = {};
    }
    let options2 = this.buildRequestOptions(options);

    let requestUrl = this.getFullUrl(endpoint);
    let requestMethod: string | any = (options && options.method) ? options.method : ((options.body == undefined) ? 'GET' : 'POST');

    // shared pending requests and use cache for GET and JSONP requests ONLY
    let cacheToken: string;
    let cache: CacheFactory;
    if (requestMethod === 'GET' || requestMethod === 'POST' || requestMethod === 'JSONP') {
      if (options.cache) {
        cache = options.cache;
        if (options.invalidateCache) {
          delete options.invalidateCache;
          cache.remove(requestUrl + '-' + JSON.stringify(options));
        }
      }
      //invalidate cache
      cacheToken = requestUrl + '-' + JSON.stringify(options);
    }

    const pendingRequestObservable = cacheToken ? this.pendingRequestCache[ cacheToken ] : undefined;
    if (pendingRequestObservable) {
      console.warn(`Multiple in-flight HTTP requests to '${requestUrl}'. Using shared observable.`);
      return pendingRequestObservable; // if there is already a pending request, return its shared observable
    }

    let subscribedTo = false;
    const observable: Observable<any> = Observable.create((observer: Observer<any>) => {
      subscribedTo = true;
      let innerObservable: Observable<any>;

      let cachedResp = cache ? cache.get(cacheToken) : undefined;
      if (!_.isNil(cachedResp)) {
        console.log(`Using cached response for '${requestUrl}'`);
        innerObservable = observableOf(cachedResp);
      } else {
        innerObservable = this.httpClient.request(requestMethod, requestUrl, options2).pipe(
          /** Map the http response to its body and handle decode exceptions.  Add the the response to the cache if requested */
          concatMap(
            (response) => {
              this.checkEmptyBody(response, requestUrl);
              this.checkFailBody(response);
              return this.processBody(response, cache, requestUrl, cacheToken, endpoint, options);
            },
          ),
          /** Timeout http call */
          timeoutWith(
            options.timeout ? options.timeout : defaultTimeout,
            observableThrowError('Timeout exceeded')),
          /** Attempt to process the error */
          catchError((error) => {
            console.log(`Error response received from '${requestUrl}'` + '\n' + JSON.stringify(error));
            return this.processFirstLevelError(error, requestUrl, options);
          }),
          /** Create error alert if !options.quiet */
          catchError((error) => {
            return this.processSecondLevelError(error, options);
          }), );
      }

      /**
       * Subscribe to the response with this observer
       */
      innerObservable.subscribe(observer);
    }).pipe(
      finalize(() => {
        if (cacheToken) { delete this.pendingRequestCache[ cacheToken ]; } // remove the observer from the observer's cache
        this.ngZone.run(() => { }); // force change detection & full render of the DOM -- when there are simulataneous in-flight HTTP requests,
        // Angular can fail to render after the response
      }),
      share()
    );

    this.pendingRequestCache[ cacheToken ] = observable;

    setTimeout(() => {
      if (!subscribedTo) {
        console.warn(`API call to '${requestUrl}' NOT subscribed to`);
      }
    });

    return observable;
  }

  /** Checks the body of the response and makes sure it is not empty.  If so, it throws an error */
  public checkEmptyBody = (response: any, requestUrl: string) => {
    if (_.isEmpty(response)) {
      console.error(`Empty response received from server for URL: ${requestUrl}`);
      response.status = 404;
      response.statusText = 'Not Found';
      throw response;
    }
  }

  public checkFailBody(response: any): void {
    if (response && response.body && isObject(response.body) && ('success' in response.body) && response.body.success === false) {
      throw response.body;
    }
  }

  /** Attempts to convert the body of the response to JSON and return that Observable.  If it fails, it looks to see if there was some form
   * of authentication taking place through F5 and resends the request, returning that as an Observable */
  public processBody(response: any, cache: any, requestUrl: string, cacheToken: string, endpoint: string, options?: ApiRequestOptionsArgs): Observable<any> {
    try {
      const body = response.body;
      if (cache) { // put response body into cache
        console.log(`Caching response for '${requestUrl}'`);
        cache.put(cacheToken, _.clone(body), options.cacheTag);
      }
      return observableOf(body);
    } catch (e) {
      //If this was a redirect for re-authorization, make the request again to make it seamless to the user
      if (response.body.match(/.*Your browser does not support JavaScript, Press Continue to proceed.*dummy.*/i) !== null
        && response.headers.get('server') === 'BigIP'
        && options.headers.get('ManualRedirect') !== 'true') {
        options.headers.append('ManualRedirect', 'true');
        return this.request(endpoint, options);
      } else {
        throw 'Failed to process your request'; // body is not JSON
      }
    }
  }

  public processFirstLevelError = (error: any, requestUrl: string, options?: ApiRequestOptionsArgs): Observable<any> => {
    let result: ValidationResult;
    result = this.apiExceptionHandler.handleException(error, options);
    return observableOf(result);
  }

  public processSecondLevelError = (error: any, options?: ApiRequestOptionsArgs): Observable<any> => {
    if (_.isString(error)) {
      if (!options.quiet) {
        this.alerts.danger(`Please retry your request, an error occurred:  ${error}`);
      }
    }
    throw error;
  }

  public requestQuiet(endpoint: string, options?: ApiRequestOptionsArgs): Observable<any> {
    if (!options) {
      options = {};
    }
    options.quiet = true;
    return this.request(endpoint, options);
  }

  public url = (endpoint: string, map: any = {}): string => {
    return endpoint.replace(/\/\:\w+/g, (key) => {
      let cleanKey = key.substr(2); // remove '/:'
      return _.isNil(map[ cleanKey ]) ? '' : '/' + encodeURIComponent(map[ cleanKey ].toString());
    });
  }

  private buildRequestOptions(options: ApiRequestOptionsArgs): any {
    let options2: {
      body?: any;
      headers?: HttpHeaders | {
        [ header: string ]: string | string[];
      };
      observe: string;
      params?: HttpParams | {
        [ param: string ]: string | string[];
      };
      reportProgress?: boolean;
      responseType: HttpResponseTypeEnum;
      withCredentials?: boolean;
      quiet?: boolean;
    } = {
      observe: 'response',
      responseType: options.responseType || HttpResponseTypeEnum.JSON,
      reportProgress: false,
      body: options.body,
      withCredentials: false,
      quiet: options.quiet ? options.quiet : undefined,
      params: options.params || undefined,
    };

    return options2;
  }
}
