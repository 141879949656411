import {ApiHelper} from 'cad/common/services/api/api-helper';
import {Observable} from 'rxjs';
import {OptionPda} from 'cad/flowing-gas/common/models/option-pda';
import {Injectable} from '@angular/core';
import ValidationResult = cad.ValidationResult;

@Injectable()
export class OptionPdaApi {

  private readonly endpoint: string = 'allocation/predetermined-allocation';

  constructor(
        private apiHelper: ApiHelper) {
  }

  public find(params: Partial<OptionPda>): Observable<OptionPda[]> {
    return this.apiHelper.request(this.endpoint + '/filter', {body: params});
  }

  public save(params: OptionPda[]): Observable<ValidationResult<OptionPda[]>> {
    return this.apiHelper.request(`${this.endpoint}/submit`, { body: params });
  }

}
