<ui-card [id]="associationController.model.name + 'Association'"
         [headline]="associationController.model.heading"
         [(isFullHeight)]="isFullHeight"
         [cardToolBarItems]="cardToolbarItems"
         [enableMax]="true">
  <div fxLayout="column"
       fxFill>

    <ng-container
      [framingComponentOutlet]="associationController.view.filterComponent"
      [framingComponentInputs]="{ associationController: associationController }">
    </ng-container>

    <div fxLayout="row"
         *ngIf="associationController.model.listFabActionMenu && associationController.model.listFabActionMenu.length && associationController.model.showFab"
         fxLayoutAlign="end center">

      <ui-float-button iconName="more_vert"
                       mainTooltip="Menu"
                       direction="left"
                       toolTipPosition="above"
                       [actionItems]="associationController.model.listFabActionMenu"
                       (onFunc)="callFunc($event)">
      </ui-float-button>
    </div>
    <ui-table
      fxLayout="column"
      [id]="associationController.model.name + 'Grid'"
      [data]="associationController.items"
      (rowUpdated)="hasDirtyRows($event)"
      [showSingleSelect]="associationController.model.listShowSelect"
      [showMultiSelect]="associationController.model.listMultiSelect"
      [enableRowAutoSave]="associationController.model.listEnableAutosave"
      [onSaveRow]="gridRowSaveAssociation"
      (gridReady)="associationController.onGridReady($event)"
      [shrinkToDataHeight]="!isFullHeight"
      [renderFullHeight]="isFullHeight"
      [rowSelection]="associationController.model.listRowSelection"
      (selectedRows)="onSelectedRows($event)"
      [actionMenu]="associationController.model.listActionMenu"
      [validationVisual]="associationController.model.showValidationErrors"
      [rowDetailComponent]="associationController.model.childGrid"
      [showExpand]="associationController.model.showExpand"
      [masterDetail]="true"
      [showDelete]="associationController.model.showDelete"
      (itemsRemoved)="deleteRow($event)"
      (cellValueChanged)="associationController.onCellValueChanged($event)"
      (cellEditingStarted)="associationController.onCellEditStarted($event)"
      [enableFilter]="associationController.model.listEnableFiltering">

      <ng-container
        [framingComponentOutlet]="associationController.view.list"
        [framingComponentInputs]="{ associationController: associationController }">
      </ng-container>
    </ui-table>
  </div>

  <button mat-mini-fab type="button" (click)="openModal()" class="floating-mat-fab"
          *ngIf="associationController.model.showAdd && !associationController.model.readOnly">
    <mat-icon class="material-icons">add</mat-icon>
  </button>
</ui-card>
