import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ParameterDomainData } from 'src/cad/structured-products/shared/interfaces/parameter-domain-data';

@Injectable()
export class ParameterDomainLookupApi {
  
  private readonly endpoint: string = 'product/parameter';
  
  constructor(
    private apiHelper: ApiHelper
  ) {}
  
  public filter(): Observable<ParameterDomainData[]> {
    return this.apiHelper.request(`${this.endpoint}/paramDomainList`);
  }
}
