import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { NominationConfirmationLocationContactApi } from 'cad/common/services/api/nomination/confirmation/location/contact/contact';

@Injectable()
export class NominationConfirmationLocationApi {

  private readonly endpoint: string = 'nomination/confirmation/location';

  constructor(
    public contact: NominationConfirmationLocationContactApi,
    private apiHelper: ApiHelper,
  ) {}

  public filter = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }
}
