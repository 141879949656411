
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Route, CanLoad } from '@angular/router';
import { FeatureFlagsService } from 'src/common/services/feature-flags/feature-flags.service';

@Injectable()
export class FeatureFlagsCanLoadGuard implements CanLoad {

  constructor(private featureFlagsService: FeatureFlagsService) {}
  
  canLoad(route: Route): Observable<boolean> {
    if (this.featureFlagsService
      && route
      && route.data
      && route.data.featFlags) {
      return this.featureFlagsService.isFeatureActive(route.data.featFlags);
    }
    return observableOf(false);
  }
}
