
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import * as _ from 'lodash';
import RBSServerMessageData = mplus.RBSServerMessageData;

@Injectable()
export class MessagesApi {

  private readonly endpoint: string = 'administration/messages';

  constructor(
    private apiHelper: ApiHelper
  ) {}

  public getById(id: number | string): Observable<any> {
    if (_.isNil(id)) {
      return observableOf({});
    }
    return this.apiHelper.request(this.endpoint + '/' + id);
  }

  public find(params: any): Observable<any> {
    return (params && !_.isNil(params)) ? this.getById(params.messageId) : observableOf({});
  }

  public filter(params: any): Observable<any> {
    let returnVal: Observable<any>;

    if (params && _.has(params, 'populator')) {
      let paramsObj: any = _.omit(params, 'populator');
      let lookupActionName: string = _.get(params, 'populator');

      switch (lookupActionName) {
        case 'allContactsLookup': {
          returnVal = this.getLookupAllContacts(paramsObj);
          break;
        }
        case 'businessAssociateLookup': {
          returnVal = this.getLookupBusinessAssociate(paramsObj);
          break;
        }
        default: {
          break;
        }
      }
    } else {
      returnVal = this.apiHelper.request(this.endpoint + '/filter', { body: params });
    }
    return !_.isNil(returnVal) ? returnVal : observableOf([]);
  }

  public recent(): Observable<any> {
    // Dashboard/Recent does not apply to Message
    return observableOf([]);
  }

  public save(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save', { body: params });
  }

  public deleteItem(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/delete', { body: params });
  }

  public getLookupBusinessAssociate(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/lookup-business-associate', { body: params });
  }

  public getLookupAllContacts(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/lookup-all-contacts', { body: params });
  }

  public getAnnouncements(): Observable<RBSServerMessageData> {
    return this.apiHelper.requestQuiet(this.endpoint + '/announcements');
  }
}
