import { Injectable } from '@angular/core';
import ValidationResult = cad.ValidationResult;
import ValidationResultMessage = cad.ValidationResultMessage;
import { AlertsService } from 'src/cad/core/services/alerts/alerts.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ApiExceptionHandlerService {
  constructor(private alerts: AlertsService) {
  }


  public handleException(error:any, options: any): ValidationResult {
    let result: ValidationResult = {successful: false};
    if(error instanceof HttpErrorResponse) {
      result = this.handleHttErrorResponse(error, options);
    } else if (error === 'Timeout exceeded') {
      result = this.handleTimeout(error, options);
    } else {
      result = this.handleServerException(error, options);
    }
    return result;
  }
  public handleHttErrorResponse(error: HttpErrorResponse, options: any): ValidationResult {
    switch (error.status) {
      case 401: { this.handleUnauthorized(error, options); break; }
      case 0: { this.handleConnectionReset(error, options); break; }
      case 400: { this.handleBadRequest(error, options); break; }
      case 500: { this.handleBadRequest(error, options); break; }
    }
    let result = ApiExceptionHandlerService.createValidationResult(error.error);
    if (!options.quiet) {
      if (error && error.error && error.error.errors) {
        this.alerts.danger(error.error.errors.join('\n'));
      }
    }
    return result;
  }
  public handleServerException(error: any, options: any): ValidationResult {
    switch (error.status) {
      case 401: { this.handleUnauthorized(error, options); break; }
      case 0: { this.handleConnectionReset(error, options); break; }
      case 400: { this.handleBadRequest(error, options); break; }
      case 500: { this.handleBadRequest(error, options); break; }
    }

    try {
      const body = error.json();
      let result = ApiExceptionHandlerService.createValidationResult(body);
      if (!options.quiet) {
        if (error.data && error.data.errors) {
          this.alerts.danger(error.data.errors.join('\n'));
        } else if (body.errors) {
          this.alerts.danger(body.errors.join('\n'));
        } else {
          this.alerts.danger(error);
        }
      }
      return result;
    } catch (e) {
      throw 'Failed to create human readable error from the server.'; // error body is not JSON
    }
  }

  private handleConnectionReset(error: any, options: any): void {
    if (!options.quiet) {
      throw 'Connection to the server failed.  Please retry.';
    }
  }

  private handleUnauthorized(error: any, options: any): void {
    if (!options.quiet) {
      throw 'User is not authorized.  Your ID/Password combination is invalid or you may have timed out.';
    }
  }

  private handleTimeout(error: any, options: any): ValidationResult {
    if (!options.quiet) {
      throw 'Your request has timed out.';
    } else {
      return { successful: false, errorMessages: [ { message: 'Timeout exceeded' } ] };
    }
  }

  private static createValidationResult(body: any): ValidationResult {
    return  {
      successful: false,
      messages: ApiExceptionHandlerService.createValidationResultMessages(body),
    };
  }

  private static createValidationResultMessages(body: any): ValidationResultMessage[] {
    let resultMessages: ValidationResultMessage[] = [];
    _.forEach(body.errors, (error) => {
      let errorObj: ValidationResultMessage = {
        message: error,
        severity: 1,
      };
      resultMessages.push(errorObj);
    });
    ApiExceptionHandlerService.checkForWAFRejectionMessage(body);
    return resultMessages;
  }

  private static checkForWAFRejectionMessage(body: any): void {
    let supportId: string = null;
    try {
      if (body && body.text && body.text.indexOf('Request Rejected') > 0 && body.text.indexOf('support ID is') > 0) {
        let pos: number = body.text.indexOf('ID is:');
        supportId = body.text.substring(pos + 7, body.text.indexOf('<', pos));
      }
    } catch (e) {
      //do nothing
    }

    if (supportId) {
      throw 'Your request was blocked.  Please contact customer support and reference support ID ' + supportId;
    }
  }

  private handleBadRequest(error: any, options: any): void {
    if (!options.quiet) {
      let validations = '';
      _.forEach(error.error.validations, (val: any) => {
        validations += val.field + ' '+ val.message + '; ';
      });

      _.forEach(error.error.errors, (errorMessage) => {
        validations += errorMessage + '; ';
      });
      throw 'Bad request. '+ validations;
    }
  }
}
