import { Injectable } from '@angular/core';
import { AdministrationApi } from 'cad/common/services/api/administration/administration';
import { AgreementApi } from 'cad/common/services/api/agreement/agreement';
import { AllocationApi } from 'src/cad/common/services/api/flowing-gas/allocation/allocation.service';
import { ApiCache } from 'cad/common/services/api/api-cache';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AssociateApi } from 'cad/common/services/api/associate/associate';
import { CodesApi } from 'cad/common/services/api/codes/codes';
import { CommentApi } from 'cad/common/services/api/comment/comment';
import { InvoicingApi } from 'cad/common/services/api/invoicing/invoices/invoicing';
import { NominationApi } from 'cad/common/services/api/nomination/nomination';
import { PhysicalApi } from 'cad/common/services/api/physical/physical';
import { SecurityApi } from 'cad/common/services/api/security/security';
import { WorkflowApi } from 'cad/common/services/api/workflow/workflow';
import { FlowingGasApi } from 'cad/common/services/api/flowing-gas/flowing-gas';
import { CapacityReleaseApi } from 'cad/common/services/api/capacity-release/capacity-release';
import {GLAccountsApi} from 'src/cad/common/services/api/invoicing/glaccounts/glaccounts';
import { PortfolioManagementApi } from './portfolio-management/portfolio-management';
import { InfoPostApi } from './infopost/infopost';
import {StructuredProductsApi} from 'src/cad/common/services/api/structured-products/structured-products';

@Injectable()
export class Api {
  constructor(
    public cache: ApiCache,
    public helper: ApiHelper,
    public administration: AdministrationApi,
    public agreement: AgreementApi,
    public allocation: AllocationApi,
    public associate: AssociateApi,
    public capacityRelease: CapacityReleaseApi,
    public codes: CodesApi,
    public comment: CommentApi,
    public invoicing: InvoicingApi,
    public nomination: NominationApi,
    public physical: PhysicalApi,
    public security: SecurityApi,
    public workflow: WorkflowApi,
    public flowingGas: FlowingGasApi,
    public glAccounts: GLAccountsApi,
    public portfolioManagement: PortfolioManagementApi,
    public infopost: InfoPostApi,
    public structuredProducts: StructuredProductsApi,
  ) {}
}
