
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { LookupApi } from 'src/features/common/modal/modal-feature-types';
import { ServiceRequesterApi } from 'src/cad/common/services/api/associate/service-requester/service-requester';
import { AgreementContractServiceApi } from 'src/cad/common/services/api/agreement/contract/service/agreement-contract-service.api';
import { BusinessAssociateApi } from 'cad/common/services/api/associate/business-associate/business-associate';
import * as _ from 'lodash';

@Injectable()
export class InvoicingLookupsApi implements LookupApi {

  private readonly endpoint: string = 'settlement';

  constructor(
    public apiHelper: ApiHelper,
    public serviceRequesterApi: ServiceRequesterApi,
    public agreementContractServiceApi: AgreementContractServiceApi,
    public businessAssociateApi: BusinessAssociateApi
  ) {}

  public filter(params: any): Observable<any> {
    let returnVal: Observable<any>;
    if (params) {
      let paramsObj: any = _.omit(params, 'populator');
      let lookupActionName: string = _.get(params, 'populator');

      switch (lookupActionName) {
        case 'adjustmentPointLookup': {
          returnVal = this.getAdjustmentPointLookupData(paramsObj);
          break;
        }
        case 'serviceReqLookup': {
          returnVal = this.getServiceRequesterLookupData(paramsObj);
          break;
        }
        case 'billableContractLookup': {
          returnVal = this.getBillableContractLookupData(paramsObj);
          break;
        }
        case 'serviceTemplateLookup': {
          returnVal = this.getServiceTemplateLookupData(paramsObj);
          break;
        }
        case 'baLookup': {
          returnVal = this.getBaLookupData(paramsObj);
          break;
        }
        default: {
          break;
        }
      }
    }
    return !_.isNil(returnVal) ? returnVal : observableOf([]);
  }

  public getAdjustmentPointLookupData(params: any): Observable<any> {
    return this.apiHelper.request(
      `${this.endpoint}/adjustment/adjustment-point-lookup`,
      { body: params }
    );
  }

  public getServiceRequesterLookupData(params: any): Observable<any> {
    if (this.serviceRequesterApi) {
      return this.serviceRequesterApi.filter(params);
    }
    return observableOf([]);
  }

  public getBillableContractLookupData(params: any): Observable<any> {
    return this.apiHelper.request(
      `${this.endpoint}/adjustment/billable-contract-lookup`,
      { body: params }
    );
  }

  public getServiceTemplateLookupData(params: any): Observable<any> {
    if (this.agreementContractServiceApi) {
      return this.agreementContractServiceApi.filter(params);
    }
    return observableOf([]);
  }

  public getBaLookupData(params: any): Observable<any> {
    if (this.businessAssociateApi) {
      return this.businessAssociateApi.filter(params);
    }
    return observableOf([]);
  }
}
