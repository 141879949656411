import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { BusinessAssociateAddressApi } from 'cad/common/services/api/associate/business-associate/address/address';
import { AssociateBusinessAssociateAssetApi } from 'cad/common/services/api/associate/business-associate/asset/asset';
import { BaCombineHistory } from 'cad/common/services/api/associate/business-associate/combine-history/combine-history';
import { BaContact } from 'cad/common/services/api/associate/business-associate/contact/ba-contact';
import { PrimaryContactApi } from 'cad/common/services/api/associate/business-associate/primary-contact/primary-contact';
import { BaRelatedBackward } from 'cad/common/services/api/associate/business-associate/related-backward/related';
import { BaRelated } from 'cad/common/services/api/associate/business-associate/related/related';

import { FilterByName } from 'cad/common/services/api/filter-by-name';
import { FilterByAbbr } from 'cad/common/services/api/filter-by-abbr';

@Injectable()
export class BusinessAssociateApi implements FilterByName, FilterByAbbr {
  private readonly endpoint: string = 'associate/business-associate';

  constructor(
    public address: BusinessAssociateAddressApi,
    public asset: AssociateBusinessAssociateAssetApi,
    public contact: BaContact,
    public baCombineHistory: BaCombineHistory,
    public baPrimaryContact: PrimaryContactApi,
    public baRelated: BaRelated,
    public baRelatedBackward: BaRelatedBackward,
    private apiHelper: ApiHelper,
  ) {}

  // ========================================
  // Common methods - Implemented in Scaffold ItemApi
  // ========================================

  public filterByName(baName: string): Observable<any> {
    return this.filter({ baName });
  }

  public filterByAbbr(baAbbrName: string): Observable<any> {
    return this.filter({ baAbbrName });
  }

  public filter = (params?: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public associationFilter = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/filter/active', { body: params });
  }

  public recent = (params?: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/dashboard');
  }
}
