import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'src/cad/common/services/api/api-helper';
import { CRBidData } from 'src/cad/capacity-release/shared/interfaces/cr-bid-data';

@Injectable()
export class CapacityReleaseOfferBidsMatchBidApi {
  private readonly endpoint: string = 'capacity-release/offer/';
  private readonly suffix: string = '/bids';
  private readonly suffix2: string = '/match-bid';

  constructor(private apiHelper: ApiHelper) {}

  public getMatchBids(auctionId: number | string, bidId: number | string): Observable<any> {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/' + bidId + this.suffix2);
  }

  public matchBid(auctionId: number | string, bidId: number | string, matchBidData: CRBidData): Observable<any> {
    return this.apiHelper.request(
      this.endpoint + auctionId + this.suffix + '/' + bidId + this.suffix2,
      { body: matchBidData, quiet: true }
    );
  }
}
