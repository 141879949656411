
import {map, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ItemApi } from 'src/features/common/item/item-api';
import { LookupApi } from 'src/features/common/modal/modal-feature-types';

@Injectable()
export class TransactionalPostingsApi extends ItemApi implements LookupApi {


  public endpoint: string = 'infopost/transactional-postings';

  constructor(private apiHelper: ApiHelper) {
    super();
  }

  private convertIncoming( item: any): any {
    const clone = _.cloneDeep(item);
    clone.commentInd = clone.commentInd === 'Y' ? true : false;
    return clone;
  }

  private convertOutgoing( item: any): any {
    const clone = _.cloneDeep(item);
    clone.commentInd = clone.commentInd === true ? 'Y' : 'N';
    clone.dUNSNbr = clone.dunsnbr;
    return clone;
  }


  public filter(params: any): Observable<any[]> {
    return this.apiHelper.request(`${ this.endpoint }/filter`, { body: params }).pipe(tap(
      ( items: any ) => {
        items.forEach( (item: any) => {
          return this.convertIncoming(item);
        });
      }
    ));
  }


  public getById = (postSeqNbr: any, contractId: any): Observable<any> => {
    return this.find({ postSeqNbr, contractId });
  }

  public find(params: any): Observable<any> {
    const formattedParams = this.convertOutgoing(params);
    return this.apiHelper.request(`${ this.endpoint }/filter`, { body: formattedParams }).pipe(map( (val: any) => this.convertIncoming(val[0]) ));
  }

  public getTransactionDetials(params: any): Observable<any> {
    const formattedParams = this.convertOutgoing(params);
    return this.apiHelper.request(`${ this.endpoint }/details`, { body: formattedParams });
  }

  public getTransactionComments(params: any): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/${params.contractId}/comments`, { method: 'GET' });
  }

  public deleteItem(item: any): Observable<any> {
    const formattedParams = this.convertOutgoing(item);
    return this.apiHelper.request(`${ this.endpoint }/delete`, { body: formattedParams });
  }

  public findAll = () : Observable<mplus.ContractData[]> => {
    return this.filter({});
  }

  recent(): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/recent`, { method: 'GET' });
  }

  save(item: any): Observable<any> {
    const formattedItem = this.convertOutgoing(item);
    return this.apiHelper.request(`${ this.endpoint }/save`, { body: formattedItem });
  }

  saveDetails(items: any): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/save-details`, { body: items });
  }

}
