import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'src/cad/common/services/api/api-helper';

@Injectable()
export class CapacityReleaseOfferRatesApi {
  private readonly endpoint: string = 'capacity-release/offer/';
  private readonly suffix: string = '/rates';

  constructor(private apiHelper: ApiHelper) {}

  public getById = (auctionId: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix);
  }

  public save = (auctionId: number | string, rateDataList: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/save', { body: rateDataList });
  }

  public getRatesDetail = (auctionId: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/detail');
  }

  public getPathRateDetail = (auctionId: number | string, pathId: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + auctionId + this.suffix + '/path-rate-detail/' + pathId);
  }
}
