import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';


@Injectable()
export class PointLookupApi {

  private readonly endpoint: string = 'flowing-gas/scheduling/point-lookup';

  constructor(
    private apiHelper: ApiHelper,

  ) {}

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public findAll = (): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/find');
  }
}
