
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ContractFilterData } from 'src/cad/contracts/shared/interfaces/contract-filter';
import { ContractLock } from 'src/cad/contracts/shared/interfaces/contract-lock';
import ValidationResult = cad.ValidationResult;
import { isNil } from 'lodash';

@Injectable()
export class ContractLockApi {

  private readonly endpoint: string = 'agreement/contract-lock';

  constructor(
    public apiHelper: ApiHelper
  ) {}
  
  public filter(params: Partial<ContractFilterData>): Observable<ContractFilterData[]> {
    return this.apiHelper.request(`${ this.endpoint }/filter`, { body: params });
  }
  
  public retrieveItems(contractId: number | string): Observable<ContractLock[]> {
    if (isNil(contractId)) {
      return observableOf([]);
    }
    return this.apiHelper.request(`${ this.endpoint }/${ contractId }`);
  }
  
  public saveItems(contractId: number | string, dataList: ContractLock[]): Observable<ValidationResult<ContractLock[]>> {
    if (!isNil(contractId) && dataList && dataList.length > 0) {
      return this.apiHelper.request(`${ this.endpoint }/${ contractId }/save`, { body: dataList });
    }
    return observableOf({ successful: false });
  }
}
