import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterByName' })
export class FilterByNamePipe implements PipeTransform {
  transform(potentialMatchList: any[], matchString: string, potentialMatchParameter: string): any {
    if(matchString && (matchString.trim().length > 0)) {
      let re = new RegExp('^' + matchString, 'i');
      return potentialMatchList.filter((matchee) => matchee[potentialMatchParameter].toString().match(re) ? true : false);
    } else {
      return potentialMatchList;
    }
  }
}
