
import {map, startWith, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserModelService } from 'src/cad/common/services/user/user-model-service';
import { Observable } from 'rxjs';
import {UserStoreService} from 'cad/common/store/core/services/user-store.service';

@Injectable()
export class LayoutService {

  constructor(
    private userStore: UserStoreService,
    private userModelService: UserModelService,
  ) {}

  public buildLayout(layout: any): any {
    return this.updateLayout(layout);
  }

  public layoutStream(layout: any): Observable<any> {
    return this.userStore.stateChanged.pipe(
      filter((userDetail)=> userDetail.state && userDetail.state.user),
      startWith(this.updateLayout(layout)),
      map(() => this.updateLayout(layout)),);
  }

  public updateLayout(layout: any): any {
    const results = {};

    const ctx = this.userModelService.getCurrentContext();
    if (_.isEmpty(ctx)) { return results; }

    const pipelineGroupKey = ctx.assetGroupCd;
    const pipelineKey = ctx.assetAbbr;
    const baKey = ctx.businessAssociateId;

    if (layout) {
      if (layout.defaults) {
        _.assign(results, layout.defaults);
      }
      if (layout.pipelineGroups && pipelineGroupKey && layout.pipelineGroups[ pipelineGroupKey ]) {
        _.assign(results, layout.pipelineGroups[ pipelineGroupKey ]);
      }
      if (layout.pipelines && pipelineKey && layout.pipelines[ pipelineKey ]) {
        _.assign(results, layout.pipelines[ pipelineKey ]);
      }
      if (layout.businessAssociates && baKey && layout.businessAssociates[ baKey ]) {
        _.assign(results, layout.businessAssociates[ baKey ]);
      }
    }

    return results;
  }
}
