import { Component, ContentChild, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { MatOption, MAT_OPTION_PARENT_COMPONENT } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect, MatSelectChange, SELECT_ITEM_HEIGHT_EM, SELECT_PANEL_MAX_HEIGHT } from '@angular/material/select';
import { MatOptionHelpers } from 'common/utils/mat-option-helpers';
import * as _ from 'lodash';
import { Observable, of as observableOf } from 'rxjs';
import { debounceTime, delay, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { VirtualKeyManager } from 'src/cad/shared/forms/select/features/virtual-key-manager/virtual-key-manager';
import { UiVirtualRepeatComponent } from 'src/common/components/virtual-repeat/virtual-repeat.component';
import { SELECT_HEADER_FILTER_ROW_HEIGHT, SELECT_HEADER_SELECTION_ROW_HEIGHT, UiSelectHeaderFilterComponent } from '../features/select-header-filter/select-header-filter.component';

export const SELECT_MAX_OPTIONS_DISPLAYED: number = 5;

/*
This component serves to add filtering functionality and performance upgrades (especially when loading lots of values)
  using MatSelect as a base and modifying what gets projected into the CDK overlay.

Need to be careful to monitor this component since it relies on specific MatSelect properties and methods that
  may change as Angular is upgraded
*/

@Component({
  selector: 'ui-mat-virtual-select',
  templateUrl: './virtual-select.component.html',
  styleUrls: [ 'virtual-select.component.less', './mat-select-styles/select.scss', ],
  providers: [
    { provide: MatFormFieldControl, useExisting: forwardRef(() => UiMatVirtualSelectComponent) },
    { provide: MAT_OPTION_PARENT_COMPONENT, useExisting: forwardRef(() => UiMatVirtualSelectComponent) },
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'uiMatVirtualSelect',
})
export class UiMatVirtualSelectComponent extends MatSelect {

  @Input()
  get originalOptionList(): any[] {
    return this._originalOptionList;
  }
  set originalOptionList(value: any[]) {
    this._originalOptionList = value;
    this.updateKeyManagerItems(this._originalOptionList);
    if (this._keyManager) {
      this._keyManager.setOriginalItems(_.cloneDeep(this.keyManagerOriginalItems));
    }
    if (this.uiVirtualRepeat) {
      this.uiVirtualRepeat.refresh(false);
      (this as any)._changeDetectorRef.detectChanges();
    }
  }
  @Input() public showCloseButton: boolean;
  @Input() valueProperty: string;
  @Input() viewValueProperty: string;
  @Input() itemsToBuffer: number = 2;
  @Input()
  get virtualOptionList(): any[] {
    return this._virtualOptionList;
  }
  set virtualOptionList(value: any[]) {
    if (value) {
      this._virtualOptionList = value;
      Promise.resolve().then(() => {
        this.virtualOptionListChange.emit(this._virtualOptionList);
      });
    }
  }
  @Output() virtualOptionListChange: EventEmitter<any> = new EventEmitter<any>();
  @ContentChild(UiSelectHeaderFilterComponent) uiSelectHeaderFilterRef: UiSelectHeaderFilterComponent;
  @ViewChild(UiVirtualRepeatComponent) uiVirtualRepeat: UiVirtualRepeatComponent;
  @ViewChild('virtualPanel', { read: ElementRef }) panel: ElementRef;

  public selectPanelMaxHeight: number = SELECT_PANEL_MAX_HEIGHT;
  public uiSelectHeaderHeight: number = 0;
  public optionItemHeight: number = 0;
  public keyManagerOriginalItems: any[] = [];
  public _keyManager: VirtualKeyManager<MatOption>;
  private _originalOptionList: any[] = [];
  private _virtualOptionList: any[];

  /**
   * @override Overrides parent class's (MatSelect) method also executes the parent method
   */
  ngOnInit(): void {
    /** Method overrides to work with the virtual options */
    (this as any)._initKeyManager = this.initKeyManager;
    (this as any)._highlightCorrectOption = this.highlightCorrectOption;
    (this as any)._initializeSelection = this.initializeSelection;
    (this as any)._getOptionIndex = this.getOptionIndex;
    (this as any)._propagateChanges = this.propagateChanges;
    (this as any)._getItemCount = this.getItemCount;
    super.ngOnInit(); // mandatory
    // This check was previously called in _onPanelDone(), but that method was removed in exchange for calling
    // the _panelDoneAnimatingStream EventEmitter in ngOnInit
    // We need `distinctUntilChanged` here, because some browsers will
    // fire the animation end event twice for the same animation. See:
    // https://github.com/angular/angular/issues/24084
    this._panelDoneAnimatingStream
    .pipe(distinctUntilChanged(), takeUntil((this as any)._destroy))
    .subscribe(() => {
      if (!this.panelOpen) {
        this.virtualOptionList = this.getSelectedItems();
      }
    });
    this.panelClass = 'ui-mat-virtual-select-panel';
  }

  /**
   * @override Overrides parent class's (MatSelect) method also executes the parent method
   */
  ngAfterContentInit(): void {
    super.ngAfterContentInit();
    if (this.uiSelectHeaderFilterRef) {
      this.uiSelectHeaderHeight = SELECT_HEADER_FILTER_ROW_HEIGHT;
      if (this.uiSelectHeaderFilterRef.showSelectionRow) {
        this.uiSelectHeaderHeight += SELECT_HEADER_SELECTION_ROW_HEIGHT;
      }
    }
  }

  /**
   * @override Overrides parent class's (MatSelect) method also executes the parent method
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._keyManager.destroy();
  }

  /**
   * @description Converts the 'originalOptionList' array items into
   * { value: item[valueProperty], viewValue: item[viewValueProperty] } Objects for the virtual key manager.
   * @param items
   */
  updateKeyManagerItems(items: any[]): void {
    this.keyManagerOriginalItems.splice(0);
    _.forEach(items, (item: any) => {
      if (_.isObject(item)) {
        this.keyManagerOriginalItems.push(_.zipObject(
          [ 'value', 'viewValue' ],
          [ item[ this.valueProperty ], item[ this.viewValueProperty ] ]
        ));
      } else {
        this.keyManagerOriginalItems.push({});
      }
    });
  }

  /**
   * @override Overrides parent class's (MatSelect) method also executes the parent method
   */
  writeValue(value: any): void {
    if (this.options) {
      this.virtualOptionList = this.getSelectedItems();
    }
    super.writeValue(value);
  }

  /**
   * @override Overrides parent class's (MatSelect) method also executes the parent method
   */
  open(): void {
    super.open();
    this.optionItemHeight = this._triggerFontSize * SELECT_ITEM_HEIGHT_EM;
    if (this.uiSelectHeaderHeight) {
      this._offsetY -= this.uiSelectHeaderHeight;
    }
    (this as any)._changeDetectorRef.markForCheck();
  }

  /**
   * @override Overrides parent class's (MatSelect) method also executes the parent method
   */
  _onAttached(): void {
    (this as any)._changeDetectorRef.detectChanges();
    super._onAttached();
    this.uiVirtualRepeat.refresh(false);
    (this as any)._changeDetectorRef.detectChanges();
  }

  /**
   * @description Emits change event to set the model value.
   * @override Overrides parent class's (MatSelect) '_propagateChanges' private method
   * @param fallbackValue
   */
  propagateChanges(fallbackValue?: any): void {
    let valueToEmit: any = null;

    if (Array.isArray(this.selected) && this.multiple) {
      let selectedItems: any[] = this.getSelection();
      if (this.options) {
        this.options.toArray().forEach((option: MatOption) => {
          let itemIndex: number = selectedItems.indexOf(option.value);
          if (!option.selected && itemIndex > -1) {
            selectedItems.splice(itemIndex, 1);
          }
        });
      }
      valueToEmit = _.unionWith(this.selected.map((option: MatOption) => {
        return option.value;
      }), selectedItems, _.isEqual).sort();
    } else {
      valueToEmit = this.selected ? (this.selected as MatOption).value : fallbackValue;
    }
    (this as any)._value = valueToEmit;
    this._onChange(valueToEmit);
    this.selectionChange.emit(new MatSelectChange(this, valueToEmit));
    this.valueChange.emit(valueToEmit);
    (this as any)._changeDetectorRef.markForCheck();
  }

  /**
   * @description Sets up a key manager to listen to keyboard events on the overlay panel.
   * @override Overrides parent class's (MatSelect) '_initKeyManager' private method.
   */
  initKeyManager(): void {
    this.updateKeyManagerItems(this.originalOptionList);
    this._keyManager = new VirtualKeyManager<MatOption>(
      this.options,
      _.cloneDeep(this.keyManagerOriginalItems),
      (this as any)._ngZone,
      (index: number) => this.scrollIndexIntoView(index)
    );
    if (!this.uiSelectHeaderFilterRef) {
      this._keyManager = this._keyManager.withTypeAhead();
    }
    this._keyManager.tabOut.pipe(takeUntil((this as any)._destroy)).subscribe(() => this.close());
    this._keyManager.change.pipe(takeUntil((this as any)._destroy), debounceTime(600)).subscribe(() => {
      if (!this.panelOpen && !this.multiple && this._keyManager.activeItem) {
        this._keyManager.activeItem._selectViaInteraction();
      }
    });
  }

  /**
   * @description Initializes the selection based on the ngControl value.
   * @override Overrides parent class's (MatSelect) '_initializeSelection' private method
   */
  initializeSelection(): void {
    // Defer setting the value in order to avoid the "Expression
    // has changed after it was checked" errors from Angular.
    Promise.resolve().then(() => {
      if (!this.multiple && this.panelOpen) {
        this._selectionModel.clear();
        const correspondingOption = (this as any)._selectValue(this.ngControl ? this.ngControl.value : this.value);
        (this as any)._changeDetectorRef.markForCheck();
      } else {
        (this as any)._setSelectionByValue(this.ngControl ? this.ngControl.value : this.value);
      }
      this.stateChanges.next();
    });
  }

  /**
   * @description Highlights the selected item. If no option is selected, it will highlight the first item instead.
   * @override Overrides parent class's (MatSelect) '_highlightCorrectOption' private method
   */
  highlightCorrectOption(): void {
    if (this._selectionModel.isEmpty()) {
      this._keyManager.setFirstItemActive();
    } else {
      let selectedOptionIndex: any = this.options.reduce((result: number, current: MatOption, index: number) => {
        return result === undefined ? (this._selectionModel.selected[ 0 ] === current ? index : undefined) : result;
      }, undefined);
      this._keyManager.setActiveItem(selectedOptionIndex!);
    }
  }

  /**
   * @description Gets the index of the provided option in the option list.
   * @param option
   * @returns {number}
   * @override Overrides parent class's (MatSelect) '_getOptionIndex' private method
   */
  getOptionIndex(option: MatOption): number | undefined {
    return this.findItemIndex(option.value) > -1 ? this.findItemIndex(option.value) : undefined;
  }

  /**
   * @description Calculates the amount of items in the select. This includes options and group labels.
   * @returns {number}
   * @override Overrides parent class's (MatSelect) '_getItemCount' private method
   */
  getItemCount(): number {
    return this.originalOptionList.length + this.optionGroups.length;
  }

  /**
   * @description Scrolls the option matched with the provided 'index' into view.
   * @param index
   * @returns {any}
   */
  scrollIndexIntoView(index: number): Observable<any> {
    if (index > -1) {
      if (this.uiVirtualRepeat && this.panel && this.panel.nativeElement) {
        const itemHeight = this.optionItemHeight;
        const labelCount = MatOptionHelpers.countGroupLabelsBeforeOption(index, this.options, this.optionGroups);
        const scrollOffset = (index + labelCount) * itemHeight;
        const panelTop = this.panel.nativeElement.scrollTop;

        if (scrollOffset < panelTop) {
          return this.uiVirtualRepeat.scrollTo(scrollOffset);
        } else if (scrollOffset + itemHeight > panelTop + SELECT_PANEL_MAX_HEIGHT) {
          return this.uiVirtualRepeat.scrollTo(Math.max(0, scrollOffset - SELECT_PANEL_MAX_HEIGHT + itemHeight));
        }
      } else {
        if (!this.panelOpen) {
          let startIndex: number = this._calculateBufferedStartIndex(index);
          let endIndex: number = this._calculateBufferedEndIndex(startIndex);
          this.virtualOptionList = this.originalOptionList.slice(startIndex, endIndex);
          (this as any)._changeDetectorRef.markForCheck();
          return observableOf(undefined).pipe(delay(100));
        }
      }
    }
    return observableOf(undefined);
  }

  getSelectedItems(): any[] {
    let startIndex: number = this._calculateBufferedStartIndex(this.findItemIndex(this.getSelection()[ 0 ]));
    let endIndex: number = this._calculateBufferedEndIndex(startIndex);
    if (this.multiple && this.getSelection().length > 0 && !_.isNil(this.valueProperty)) {
      return _.filter(this.originalOptionList, (optionItem: any) => {
        return this.getSelection().indexOf(optionItem[ this.valueProperty ]) > -1;
      });
    }
    return this.originalOptionList.slice(startIndex, endIndex);
  }

  getSelection(): any[] {
    if (this.ngControl && !_.isNil(this.ngControl.value)) {
      return _.isArray(this.ngControl.value)
        ? this.ngControl.value
        : [ this.ngControl.value ];
    }
    return [];
  }

  /**
   * @description Searches and returns the index of the passed itemValue based on the [ valueProperty ]
   * property of the originalOptionList array items.
   * @public
   * @param itemValue
   * @returns {number}
   */
  findItemIndex(itemValue: any): number {
    if (_.isNil(this.valueProperty)) { return -1; }
    return _.findIndex(this.originalOptionList, (option: any): boolean => {
      return _.isEqual(option[ this.valueProperty ], itemValue);
    });
  }

  /**
   * @description Calculates the valid start index including the buffered item count.
   * @param index
   * @returns {number}
   * @private
   */
  private _calculateBufferedStartIndex(index: number): number {
    return Math.max(0, Math.max(0, index) - this.itemsToBuffer);
  }

  /**
   * @description Calculates the valid end index based on the provided 'startIndex'
   * including the buffered item count.
   * @param startIndex
   * @returns {number}
   * @private
   */
  private _calculateBufferedEndIndex(startIndex: number): number {
    return Math.max(0, startIndex) + (SELECT_MAX_OPTIONS_DISPLAYED + this.itemsToBuffer);
  }
}
