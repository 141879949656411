import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirm-navigation-modal',
  templateUrl: './confirm-navigation-modal.component.html',
  styleUrls: [ './confirm-navigation-modal.component.less' ]
})
export class ConfirmNavigationModalComponent {
  public confirmationText: string = 'The page you are currently on has unsaved information.  If you continue you will lose anything that has not been saved';

  constructor(
    public dialogRef: MatDialogRef<ConfirmNavigationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(data.confirmationText) {
      this.confirmationText = data.confirmationText;
    }
  }

  public continueNavigation(): void {
    this.dialogRef.close(true);
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

}
