import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class ProfileTypeListService {
  constructor() {
  }

  private profileTypeList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  private notificationTypeList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);

  currentProfileTypeList:any = this.profileTypeList.asObservable();
  currentNotificationTypeList:any = this.notificationTypeList.asObservable();

  public setProfileTypeList(profiles: any[]):void {
    this.profileTypeList.next(profiles);
  }

  public setNotificationTypeList(profiles: any[]):void {
    this.notificationTypeList.next(profiles);
  }

}
