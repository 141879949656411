import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ApiHelper} from 'cad/common/services/api/api-helper';

@Injectable()
export class NominationConfirmationLocationContactApi {

  private readonly endpoint: string = 'nomination/confirmation/location/contact';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public getById = (id: string | number, assetNbr?: number): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/' + id + '/' + assetNbr);
  }

}
