import { Component, ViewChild, ElementRef, Optional, Inject, Input, OnInit, EventEmitter, Output, Injector } from '@angular/core';

import { LookupController } from  'src/features/common/lookup/lookup.controller';

@Component({
  selector: 'ui-table-lookup',
  templateUrl: './table-lookup.component.html',
  styleUrls: [ './table-lookup.component.less' ],
})
export class TableLookupComponent {

  @Input() lookupInjector: Injector;
  @Input() lookupController: LookupController;

  @Output() public resultSelected: EventEmitter<any> = new EventEmitter<any>();

  public onSelect(data: any): void {
    this.resultSelected.emit(data);
  }
}
