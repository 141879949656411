import { ComponentType } from '@angular/cdk/portal';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Injector, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild, forwardRef, Injectable } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel, ValidationErrors, Validator, ValidatorFn, Validators} from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MatMomentDateAdapterOptions } from '@angular/material-moment-adapter';
import { take } from 'rxjs/operators';
import { UIControlValueAccessor } from '../control-value-accessor';
import { Validatable } from './../mat-form-field/validatable';
import { AutoUnsubscribables, AutoUnsubscriber } from 'cad/shared/mixins/auto-unsubscriber.mixin';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { UiCalendarHeaderComponent } from './calendar-header.component';
import { DatepickerDisplayModeEnum, StartViewEnum, UpdateModelOnEnum } from './datepicker.constants';
import { UiMomentDatetimeAdapter } from './moment-datetime-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { MatDatepickerInput } from 'common/components/form/mat-datepicker/datepicker-input';
import { MatDatepicker } from 'common/components/form/mat-datepicker/datepicker';
import { MatCalendarView } from 'common/components/form/mat-datepicker/calendar';
import { MatDatepickerInputEvent } from 'common/components/form/mat-datepicker/datepicker-input-base';

const { MONTH, DATE, DATETIME, FULL, TIME } = DatepickerDisplayModeEnum;

@Injectable()
export class MatMomentDateFormats {

  public parse: any = { ...MAT_MOMENT_DATE_FORMATS.parse };
  public display: any = { ...MAT_MOMENT_DATE_FORMATS.display };

  constructor() {}
}

@Component({
  selector: 'ui-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: [ './datepicker.component.less' ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { strict: false } },
    { provide: DateAdapter, useClass: UiMomentDatetimeAdapter, deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ] },
    { provide: MAT_DATE_FORMATS, useClass: MatMomentDateFormats },
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UiDatepickerComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => UiDatepickerComponent), multi: true },
    { provide: Validatable, useExisting: forwardRef(() => UiDatepickerComponent) }
  ],
  exportAs: 'uiDatepicker'
})
export class UiDatepickerComponent extends UIControlValueAccessor
  implements OnInit, AfterViewInit, OnChanges, OnDestroy, Validatable, Validator {

  @ViewChild('inputWrapper', { read: ElementRef }) public input: ElementRef;
  @ViewChild('input', { read: ElementRef }) public inputElementRef: ElementRef;
  @ViewChild('matDatepickerRef', { read: MatDatepicker }) public matDatepickerRef: MatDatepicker<moment.Moment>;
  @ViewChild(MatDatepickerInput) public matDatepickerInputRef: MatDatepickerInput<moment.Moment>;
  @ViewChild('errorNode') public errorNode: ElementRef;
  @ViewChild('inputModelRef') public inputModelRef: NgModel;
  @Input() public disabled: boolean;
  @Input() public required: boolean;
  @Input() public placeholder: string = 'MM-dd-yyyy';
  @Input() public updateModelOn: UpdateModelOnEnum = UpdateModelOnEnum.CHANGE;
  @Input() public displayMode: DatepickerDisplayModeEnum = DATE;
  /**
   * @description Whether to parse the timezone when writing a date time value, that has a timezone offset, to the model.
   * @example If the user's local browser is on EST then the string '2022-02-03T10:46:12.232-0600' that gets assigned
   * to the datepicker model will parse the EST timezone offset, so the input value of '2022-02-03T10:46:12.232-0600'
   * will be correctly displayed as '02-03-2022 10:46 AM' instead of '02-03-2022 09:46 AM'
   */
  @Input() public parseZone: boolean;
  /**
   * @description Moment Date display format of the datepicker input value.
   * @see MatDateFormats
   * See the Moment.js docs for the meaning of the formats.
   * @see https://momentjs.com/docs/#/displaying/format/
   */
  @Input() public displayFormat: string;
  /**
   * @description Moment Date parse format of the datepicker input value.
   * @default Defaults to the value of {@member UiDatepickerComponent.displayFormat}
   * @see MatDateFormats
   * See the Moment.js docs for the meaning of the formats.
   * @see https://momentjs.com/docs/#/displaying/format/
   */
  @Input() public parseFormat: string;
  /**
   * @description Moment Date format of the model upon selecting a valid date value.
   * See the Moment.js docs for the meaning of the formats.
   * @see https://momentjs.com/docs/#/displaying/format/
   */
  @Input() public outputFormat: string;
  /**
   * @description The view that the calendar should start in.
   * @default Defaults to {@enum StartViewEnum.YEAR} if value of {@member monthMode} is 'true',
   * otherwise defaults to {@enum StartViewEnum.MONTH}
   */
  @Input() public startView: MatCalendarView;
  /**
   * @description An input indicating the type of the custom header component for the calendar, if set.
   */
  @Input() public calendarHeaderComponent: ComponentType<any> = UiCalendarHeaderComponent;
  @Input() public minDate: string = dateUtil().getDefaultStartDate();
  @Input() public maxDate: string = dateUtil().getDefaultEndDate();
  @Input() public dateAdapterOptions: MatMomentDateAdapterOptions;
  @Input() public datepickerFilter: (date: moment.Moment | null) => boolean;
  @Input() public hintText: string;
  @Input() public hintAlign: 'start' | 'end' = 'start';
  /**
   * @description Whether the datepicker calendar uses action mode opposed to click auto apply mode. Setting this
   * to 'true' will render datepicker content action buttons (submit and cancel).
   * @default 'true' if {@member UiDatepickerComponent.includeTime} is true.
   */
  @Input()
  public get actionMode(): boolean {
    return this._actionMode || this.includeTime;
  }
  public set actionMode(value: boolean) {
    this._actionMode = value;
  }
  /**
   * Start of: Datepicker Time Feature properties
   */
  /**
   * @description Whether to include the time parts of the date when doing comparisons. Setting this to 'true'
   * will render datepicker content time editor inputs and enables action mode.
   * @default 'true' if {@member UiDatepickerComponent.displayMode} is one of the time modes:
   * {@enum DatepickerDisplayModeEnum.DATETIME} |
   * {@enum DatepickerDisplayModeEnum.FULL} |
   * {@enum DatepickerDisplayModeEnum.TIME}.
   */
  @Input()
  public get includeTime(): boolean {
    return this._includeTime || _.includes([ DATETIME, FULL, TIME ], this.displayMode);
  }
  public set includeTime(value: boolean) {
    this._includeTime = value;
  }
  /**
   * @description Whether to include the seconds of the date on time mode calendar and when doing comparisons.
   * @default true
   */
  @Input() public includeSeconds: boolean = true;
  /**
   * @description Whether to use AM/PM time format on time mode calendar. Setting this to 'true' will render
   * datepicker content AM/PM toggle button.
   */
  @Input() public meridianMode: boolean;
  /**
   * @description Whether to disable the minutes of the date on time mode calendar.
   */
  @Input() public disableMinute: boolean;
  /**
   * @description Time part increment step sizes.
   * @default Defaults to 1 upon change.
   */
  @Input() public stepHour: number;
  @Input() public stepMinute: number;
  @Input() public stepSecond: number;
  /**
   * End of: Datepicker Time Feature properties
   */

  @Output() public dateChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onEdit: EventEmitter<any> = new EventEmitter<any>();
  /**
   * @description Represents Form Control model value of the Mat Datepicker Input {@see MatDatepickerInput}.
   */
  public datepickerInputModel: any;
  /**
   * @description Determines whether month only selection should be used instead of days.
   * @default 'true' if {@member UiDatepickerComponent.displayMode} is equal to {@enum DatepickerDisplayModeEnum.MONTH}.
   */
  public get monthMode(): boolean {
    return this.displayMode === MONTH;
  }
  public get opened(): boolean {
    return this.matDatepickerRef && this.matDatepickerRef.opened;
  }
  private validatorOnChangeFn = () => { };
  private validatorFn: ValidatorFn | null = Validators.compose([
    this.requiredValidator.bind(this),
    this.parseValidator.bind(this),
    this.minValidator.bind(this),
    this.maxValidator.bind(this),
    this.filterValidator.bind(this)
  ]);
  private _actionMode: boolean;
  private _includeTime: boolean;
  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  constructor(
    public injector: Injector,
    @Inject(MAT_DATE_FORMATS) public matDateFormats: MatDateFormats,
    @Inject(DateAdapter) public dateAdapter: UiMomentDatetimeAdapter,
    @Inject(MAT_MOMENT_DATE_ADAPTER_OPTIONS) public matMomentDateAdapterOptions: MatMomentDateAdapterOptions,
    private ngZone: NgZone
  ) {
    super();
    moment.tz.setDefault('America/Chicago');
  }

  public ngOnInit(): void {
    this.processDisplayMode();
    if (this.matMomentDateAdapterOptions && this.dateAdapterOptions) {
      _.assign(this.matMomentDateAdapterOptions, this.dateAdapterOptions);
    }
  }

  public ngAfterViewInit(): void {
    if (this.inputModelRef && this.inputModelRef.statusChanges) {
      this.subs.newSub = this.inputModelRef.statusChanges.subscribe(() => this.validatorOnChangeFn());
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    _.forEach(changes, (changeObj: SimpleChange, key: string) => {
      if (!_.isNil(changeObj) && !changeObj.isFirstChange()) {
        switch (key) {
          case 'displayMode': {
            if (!_.isEqual(changeObj.currentValue, changeObj.previousValue)) {
              this.runOnStableZone(() => this.processDisplayMode());
            }
            break;
          }
          case 'displayFormat': {
            if (!_.isEqual(changeObj.currentValue, changeObj.previousValue)) {
              if (this.matDateFormats) {
                this.matDateFormats.display.dateInput = changeObj.currentValue;
              }
            }
            break;
          }
          case 'parseFormat': {
            if (!_.isEqual(changeObj.currentValue, changeObj.previousValue)) {
              if (this.matDateFormats) {
                this.matDateFormats.parse.dateInput = changeObj.currentValue;
              }
            }
            break;
          }
          case 'meridianMode': {
            if (!_.isEqual(changeObj.currentValue, changeObj.previousValue)) {
              if (!_.isNil(this.displayFormat)) {
                this.displayFormat = this.displayFormat.replace(' A', '').replace(' a', '');
              }
              if (!_.isNil(this.outputFormat)) {
                this.outputFormat = this.outputFormat.replace(' A', '').replace(' a', '');
              }
            }
            break;
          }
          case 'includeTime': {
            if (!_.isEqual(changeObj.currentValue, changeObj.previousValue)) {
              if (this.dateAdapter) {
                this.dateAdapter.includeTime = changeObj.currentValue;
              }
            }
            break;
          }
          case 'includeSeconds': {
            if (!_.isEqual(changeObj.currentValue, changeObj.previousValue)) {
              if (this.dateAdapter) {
                this.dateAdapter.includeSeconds = changeObj.currentValue;
              }
              if (!_.isNil(this.displayFormat)) {
                this.displayFormat = this.displayFormat.replace(':ss', '').replace(':s', '');
              }
              if (!_.isNil(this.outputFormat)) {
                this.outputFormat = this.outputFormat.replace(':ss', '').replace(':s', '');
              }
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.inputElementRef) {
      this.inputElementRef = null;
    }
    if (this.errorNode) {
      this.errorNode = null;
    }
    if (this.input) {
      this.input = null;
    }
  }

  /**
   * @description Implemented as part of {@see Validator}.
   * @param fn
   */
  public registerOnValidatorChange(fn: () => void): void {
    this.validatorOnChangeFn = fn;
  }

  /**
   * @description Implemented as part of {@see ControlValueAccessor}.
   * @param value
   */
  public writeValue(value: any): void {
    let dateValue: moment.Moment = this.getEpochDSTDate(value);

    if (dateValue && dateValue.format(this.displayFormat) !== this.getEpochDSTDate(moment(this._model)).format(this.displayFormat)) {
      this._model = value;
      this.datepickerInputModel = (this.parseZone ? dateValue.parseZone().local() : dateValue);
    } else if (!value) {
      this._model = null;
      this.datepickerInputModel = null;
    }
  }

  /**
   * @description Implemented as part of {@see Validator}.
   * @param control
   * @returns {ValidationErrors|null}
   */
  public validate(control: AbstractControl): ValidationErrors | null {
    return this.validatorFn ? this.validatorFn(control) : null;
  }

  public onCalendarOpen(event: any): void {
    this.onOpen.emit(event);
  }

  public onCalendarClose(event: any): void {
    this.onClose.emit(event);
  }

  public dateEdit(): void {
    this.onEdit.emit(true);
  }

  public onDateChange(event: MatDatepickerInputEvent<moment.Moment>): void {
    let shouldEmit: boolean = false;
    let dateValue: moment.Moment = event
      ? this.getNormalizedDate(this.getEpochDSTDate(event.value))
      : null;

    // Update the control model value and emit the change event only if we have a valid date value or empty input value
    if (this.inputModelRef
      && !this.inputModelRef.invalid
      && dateValue
      && dateValue.format(this.outputFormat) !== this.getNormalizedDate(this.getEpochDSTDate(moment(this.model))).format(this.outputFormat)) {
      // Valid date value
      this.model = dateValue.format(this.outputFormat);
      shouldEmit = true;
    } else if (this.inputElementRef && this.inputElementRef.nativeElement && !this.inputElementRef.nativeElement.value) {
      // Empty input value, clear the model
      this.model = null;
      shouldEmit = true;
    }
    if (shouldEmit) {
      this.dateChanged.emit(this.model);
    }
  }

  public onInputBlur(): void {
    this.onBlur();
    this.runOnStableZone(() => this.validatorOnChangeFn());
  }

  public processDisplayMode(): void {
    if (!this.startView) {
      this.startView = this.monthMode ? StartViewEnum.YEAR : StartViewEnum.MONTH;
    }
    if (!this.displayFormat) {
      this.displayFormat = this.getDefaultDisplayFormat(this.displayMode);
    }
    if (!this.parseFormat) {
      this.parseFormat = this.displayFormat;
    }
    if (!this.outputFormat) {
      this.outputFormat = this.getDefaultOutputFormat(this.displayMode);
    }
    if (this.dateAdapter) {
      this.dateAdapter.includeTime = this.includeTime;
      this.dateAdapter.includeSeconds = this.includeSeconds;
    }
    if (this.matDateFormats) {
      this.matDateFormats.parse.dateInput = this.parseFormat;
      this.matDateFormats.display.dateInput = this.displayFormat;
    }
  }

  private requiredValidator(control: AbstractControl): ValidationErrors | null {
    if (this.inputModelRef && this.inputModelRef.hasError('required')) {
      return (this.inputElementRef && this.inputElementRef.nativeElement && this.inputElementRef.nativeElement.value)
        ? { required: { valid: false, message: 'Invalid date value' } }
        : { required: { valid: false, message: 'You did not enter a field' } };
    }
    return null;
  }

  private parseValidator(control: AbstractControl): ValidationErrors | null {
    return (this.inputModelRef && this.inputModelRef.hasError('matDatepickerParse'))
      ? { parseDate: { valid: false, message: 'Invalid date value' } }
      : null;
  }

  private minValidator(control: AbstractControl): ValidationErrors | null {
    return (this.inputModelRef && this.inputModelRef.hasError('matDatepickerMin'))
      ? { minDate: { valid: false, message: 'Date cannot be less than ' + moment(this.minDate).format(this.includeTime ? 'LLL' : 'LL') } }
      : null;
  }

  private maxValidator(control: AbstractControl): ValidationErrors | null {
    return (this.inputModelRef && this.inputModelRef.hasError('matDatepickerMax'))
      ? { maxDate: { valid: false, message: 'Date cannot be greater than ' + moment(this.maxDate).format(this.includeTime ? 'LLL' : 'LL') } }
      : null;
  }

  private filterValidator(control: AbstractControl): ValidationErrors | null {
    return (this.inputModelRef && this.inputModelRef.hasError('matDatepickerFilter'))
      ? { filterDate: { valid: false, message: 'Date filter error' } }
      : null;
  }

  /**
   * @description Ensures that the fn is invoked when angular zone stabilizes.
   * @private
   * @param fn
   */
  private runOnStableZone(fn: Function): void {
    this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
      fn();
    });
  }

  /**
   * @description Gets the default value for the {@member UiDatepickerComponent.displayFormat}
   * @param displayMode
   * @returns {string}
   */
  private getDefaultDisplayFormat(displayMode: DatepickerDisplayModeEnum): string {
    let returnFormat: string;

    if (displayMode === MONTH) {
      returnFormat = 'MM-YYYY';
    } else if (displayMode === DATE) {
      returnFormat = 'MM-DD-YYYY';
    } else if (displayMode === DATETIME || displayMode === FULL) {
      returnFormat = 'MM-DD-YYYY'
        + (this.meridianMode ? ' hh:mm' : ' HH:mm')
        + (this.includeSeconds ? ':ss' : '')
        + (this.meridianMode ? ' A' : '');
    } else if (displayMode === TIME) {
      returnFormat = (this.meridianMode ? 'hh:mm' : 'HH:mm')
        + (this.includeSeconds ? ':ss' : '')
        + (this.meridianMode ? ' A' : '');
    }
    return returnFormat;
  }

  /**
   * @description Gets the default value for the {@member UiDatepickerComponent.outputFormat}
   * @param displayMode
   * @returns {string}
   */
  private getDefaultOutputFormat(displayMode: DatepickerDisplayModeEnum): string {
    let returnFormat: string;

    if (displayMode === MONTH) {
      returnFormat = 'YYYY-MM-DD';
    } else if (displayMode === DATE) {
      returnFormat = 'YYYY-MM-DD';
    } else if (displayMode === DATETIME || displayMode === FULL) {
      // Setting the format to null will format it to the default Moment format of 'YYYY-MM-DDTHH:mm:ss.SSSZZ'
      // Example of the default format: '2020-06-24T13:50:52.023-0500'
      returnFormat = null;
    } else if (displayMode === TIME) {
      returnFormat = (this.meridianMode ? 'hh:mm' : 'HH:mm')
        + (this.includeSeconds ? ':ss' : '')
        + (this.meridianMode ? ' A' : '');
    }
    return returnFormat;
  }

  private getNormalizedDate(sourceDate: moment.Moment): moment.Moment {
    return (this.monthMode && sourceDate && this.dateAdapter)
      ? this.dateAdapter.createDate(this.dateAdapter.getYear(sourceDate), this.dateAdapter.getMonth(sourceDate), 1)
      : (this.parseZone ? sourceDate.parseZone().local() : sourceDate);
  }

  private getEpochDSTDate(dateValue: string | moment.Moment): moment.Moment {
    // Fix for Moment Epoch DST calculation not being available past year 2037
    return dateValue
      ? (dateUtil().getNonMomentParsedDate(dateValue).year() > 2037
        ? dateUtil().getNonMomentParsedDate(dateValue).parseZone()
        : dateUtil().getNonMomentParsedDate(dateValue))
      : null;
  }
}
