import {ConfirmationDataModel} from 'cad/nominations/confirmation/confirmation-matrix/shared/confirmation-data.model';

export interface ConfirmationStateStore {
  searchCriteria: SearchCriteria;
  confirmationData: ConfirmationDataModel;
}

export class SearchCriteria {
  public constructor(init?:Partial<SearchCriteria>) {
    Object.assign(this, init);
    if(!this.beginDate) {
      if(moment().get('hour') > 12) {
        this.beginDate = moment().add('day', 1).format('YYYY-MM-DD');
        this.endDate = moment().add('day', 1).format('YYYY-MM-DD');
      } else {
        this.beginDate = moment().format('YYYY-MM-DD');
        this.endDate = moment().format('YYYY-MM-DD');
      }
    }
  }

  beginDate: string;
  endDate: string;
  id: string;
  type: ConfirmationSearchTypeEnum;
  confirmCycleId: number;
  assetNbr: number;
  baId: string;
  currentBaID: string;

  toApiParams() : any {
    return  {
      flowDate: this.beginDate,
      flowBeginDate: this.beginDate,
      flowEndDate: this.endDate,
      pointCd: this.type === ConfirmationSearchTypeEnum.Confirmation ? this.id : null,
      baId: this.type === ConfirmationSearchTypeEnum.BaId ? this.id : null,
      obaId: this.type === ConfirmationSearchTypeEnum.ObaId ? this.id : null,
      confirmCycleId: this.confirmCycleId,
      assetNbr: this.assetNbr,
      currentBaID: this.type === (ConfirmationSearchTypeEnum.ObaId) && this.id ? this.currentBaID : null,
    };
  }
}

export enum ConfirmationSearchTypeEnum {
  Confirmation = 'CONF',
  TPConfirmation = 'TPCONF',
  location ='LOC',
  BA = 'ba',
  BaId = 'baId',
  Prefinal = 'preFinal',
  ObaId = 'obaId',
  pointCd = 'pointCd',
}

export enum ConfirmationTabsEnum {
  ConfirmationTab = 0 ,
  DateRangeTab,
}

export enum DirectionOfFlowEnum {
  Received = 'R',
  Delivered = 'D',
}

export enum ConfirmationEntityTypeName {
  OBA = 'OBA/DPOA'
}

export enum ReductionReasonEnum {
  ExtDeliveryConfRR = 'DEL_CONF_RR_CD_EXT',
  ExtReceiptConfRR = 'REC_CONF_RR_CD_EXT',
  IntDeliveryConfRR = 'DEL_CONF_RR_CD_INT',
  IntReceiptConfRR = 'REC_CONF_RR_CD_INT'
}
