import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ApiCache } from 'src/cad/common/services/api/api-cache';
import { UserModelService } from 'cad/common/services/user/user-model-service';
import WorkflowConfigData = mplus.WorkflowConfigData;
import { CodeValueData } from 'cad/shared/interfaces/code-value-data';

@Injectable()
export class WorkflowConfigApi {
  private readonly endpoint: string = 'workflow';

  constructor(
    private apiHelper: ApiHelper,
    private apiCache: ApiCache,
    private userModelService: UserModelService
  ) { }

  public findReports(): Observable<WorkflowConfigData[]> {
    return this.apiHelper.request(
      this.endpoint + '/find',
      {
        body: { workFlowVertical: 'reporting'},
        cache: this.apiCache.forSession,
        cacheTag: this.endpoint + '/find' + this.userModelService.getCurrentContext().assetNbr.toString() + this.userModelService.getUser().userName
      });
  }

  public find(): Observable<WorkflowConfigData[]> {
    return this.apiHelper.request(
      this.endpoint + '/find',
      {
        cache: this.apiCache.forSession,
        cacheTag: this.endpoint + '/find' + this.userModelService.getCurrentContext().assetNbr.toString() + this.userModelService.getUser().userName

      });
  }

  public findVerticals(): Observable<CodeValueData[]> {
    return this.apiHelper.request(
      this.endpoint + '/findVerticals',
      {
        cache: this.apiCache.forSession,
        cacheTag: this.endpoint + '/findVerticals' + this.userModelService.getCurrentContext().assetNbr.toString() + this.userModelService.getUser().userName
      });
  }

}


