
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AutoUnsubscribables, AutoUnsubscriber } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import { FeatureFlagsService } from 'src/common/services/feature-flags/feature-flags.service';
import * as _ from 'lodash';

// Usage
// <div *featureFlags="'asset-live' or ['caprel-seasonality','contract-seasonality']">
//    ... whatever content you want to show or hide based of the asset-live flag
// </div>
//

@Directive({
  selector: '[featureFlags]'
})
export class FeatureFlagsDirective implements OnInit {
  
  @Input() featureFlags: string | string[];
  @Input() featureFlagsOr: string | string[];
  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  constructor(
    private vcr: ViewContainerRef,
    private tpl: TemplateRef<any>,
    private featureFlagsService: FeatureFlagsService) {
  }

  ngOnInit(): void {
    let featureFlagObs: Observable<any>[] = [];
    let featureFlagList: string[] = _.isArray(this.featureFlags)
      ? this.featureFlags
      : !_.isNil(this.featureFlags) ? [ this.featureFlags ] : [];
    let featureFlagOrList: string[] = _.isArray(this.featureFlagsOr)
      ? this.featureFlagsOr
      : !_.isNil(this.featureFlagsOr) ? [ this.featureFlagsOr ] : [];
  
    if (featureFlagList.length > 0) {
      featureFlagObs.push(this.featureFlagsService.isFeatureActive(featureFlagList));
    }
    if (featureFlagOrList.length > 0) {
      featureFlagObs.push(this.featureFlagsService.isFeatureActive(featureFlagOrList));
    }
    if (featureFlagObs.length > 0) {
      this.subs.newSub = observableForkJoin(featureFlagObs).subscribe((results: any) => {
        if (_.isArray(results) && _.some(results)) {
          this.vcr.createEmbeddedView(this.tpl);
        }
      });
    }
  }
}
