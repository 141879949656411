import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { AllocationTransferApi } from 'cad/common/services/api/allocation/transfer/transfer';
import TransferContractData = mplus.TransferContractData;
import { cadDateUtilInstance } from 'common/utils/date-util';

@Injectable()
export class InFieldTransferApi extends AllocationTransferApi {

  public recent(): Observable<TransferContractData[]> {
    return this.apiHelper.request(this.endpoint + '/dashboard', {body: {transferTypeCd: 'INF'}});
  }

  public getDefaultTransferDate(): Observable<string> {
    return of(cadDateUtilInstance().getToday());
  }
}
