import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: `input[type='number'][max][formControlName],[max][formControl],[max][ngModel]`,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MaxNumberValidatorDirective,
      multi: true
    }
  ]
})
export class MaxNumberValidatorDirective implements Validator {
  private _validator: ValidatorFn;
  @Input() public set max(value: string) {
    this._validator = Validators.max(+value);
  }

  validate(control: AbstractControl): { [ key: string ]: any } {
    return this._validator(control);
  }
}
