<mat-calendar-header #matCalendarHeaderRef="matCalendarHeader">
  <button mat-button
          mat-icon-button
          type="button"
          class="mat-calendar-today-button"
          (click)="onToday()"
          matTooltip="Jump to Current Date Time">
    <mat-icon>today</mat-icon>
  </button>
</mat-calendar-header>
