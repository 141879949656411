<ui-fullscreen>
  <!--[includeSave]="false"-->
  <ui-form [skipSaveMessage]="associationController.model.modalSkipSaveMessage"
           fxLayout="column"
           fxFlexFill=""
           (dirtyStatusChanged)="isDirty=$event.dirtyStatus">
    <ui-loading-bar></ui-loading-bar>
    <ui-header-association-dialog (nextTab)="incrementContent($event)"
                                  [selectedContentIndex]="selectedContentIndex"
                                  [associationController]="associationController"></ui-header-association-dialog>
    <ui-body-association-dialog [selectedContentIndex]="selectedContentIndex"
                                [associationController]="associationController"
                                [selectedTabIdx]="selectedTabIdx"
                                (tabSelected)="selectedTab($event)"
                                (tabIndex)="tabIndexChange($event)"
                                [fromController]="fromController"
                                [createInjector]="createInjector"
                                [(searchResults)]="searchResults"
                                [data]="data"></ui-body-association-dialog>
    <ui-footer-association-dialog [(selectedContentIndex)]="selectedContentIndex"
                                  [selectedTabIdx]="selectedTabIdx"
                                  (nextContent)="incrementContent($event)"
                                  [isFormDirty]="isDirty"
                                  [associationController]="associationController"
                                  (handleSubmit)="saveAssociations()"
                                  (handleNewItem)="saveNewItem()"></ui-footer-association-dialog>
  </ui-form>
</ui-fullscreen>
