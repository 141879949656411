import { Component, Input, OnInit } from '@angular/core';
import { UiQueryDirective } from '../query/query.directive';

@Component({
  selector: 'ui-search',
  template: './search.component.html',
})
export class UiSearchComponent implements OnInit {
  @Input() public formGroups: any = {}; // bound by bindings
  @Input() public parentCtrl: any; // bound by bindings

  public query: UiQueryDirective; // bound by require

  public ngOnInit = () => {
    if (this.parentCtrl) { // parent ctrl requires the query controller
      this.parentCtrl.query = this.query;
    }
  }

  public addFormGroup = (formGroup: any): void => {
    this.formGroups[formGroup.data.for] = formGroup;
  }
}
