import {Component, Input, Output, ViewChild, EventEmitter, ElementRef, OnChanges, SimpleChanges, AfterViewInit, OnDestroy} from '@angular/core';
import * as MediumEditor from 'medium-editor';
import { AutoUnsubscribables, AutoUnsubscriber } from '../mixins/auto-unsubscriber.mixin';

/**
 * MediumEditor component
 * Usage :
 * <medium-editor [(html)]="data" [config]="{...}" ></medium-editor>
 */
@Component({
  selector: 'medium-editor',
  templateUrl: './medium-editor.component.html',
  styleUrls: [ './medium-editor.component.less', ]
})
export class MediumContentEditor implements AfterViewInit, OnDestroy, OnChanges {

  ngOnDestroy(): void {
    if( this.editor ) {
      this.editor.destroy();
      this.editor = null;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.host.nativeElement );
    this.handleChanges(this.html);
  }

  @Input() config : any;
  @Input() html: string = '';
  @Input() showRaw?: boolean = false;
  @Output() htmlChange: EventEmitter<string> = new EventEmitter();
  @AutoUnsubscriber() private subs: AutoUnsubscribables;
  @ViewChild('host') host : ElementRef;
  private editor: MediumEditor;
  @Input() disabled: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.html) {
      if(changes.html.currentValue) {
        this.handleChanges(changes.html.currentValue);
      }
    }
  }

  // these manipulations of the body, head, and html tags were necessary
  // for the medium editor.  it would remove the tags as a security measure
  // otherwise
  private convertHtml(html: string) : string {
    return html.replace('<body', '<ydob')
        .replace('</body>', '</ydob>')
        .replace('<head', '<deah')
        .replace('<html', '<lmth')
        .replace('</html', '</lmth')
        .replace('</head>', '</deah>');
  }

  private reverseConvertedHtml(html: string) : string {
    return html.replace( '<ydob','<body')
        .replace('</ydob>','</body>')
        .replace('<deah','<head')
        .replace('<lmth','<html')
        .replace('</lmth', '</html',)
        .replace('</deah>', '</head>',);
  }

  handleChanges( html:string ) : void {
    const convertedHtml = this.convertHtml(html);
    if( !this.editor ) {
      this.editorInit(this.config, convertedHtml);
    } else if ( this.editor && this.editor.elements[0].innerHTML !== convertedHtml ) {
      this.editor.elements[0].innerHTML = convertedHtml;
    }
  }

  private editorInit( config: any, html: string ) : void {
    this.editor = new MediumEditor(this.host.nativeElement, this.config);
    this.editor.setContent(html);
    this.subs.newSub = this.editor.subscribe('editableInput',  (event, editable)=> {
      const editedHtml = this.reverseConvertedHtml(editable.innerHTML);
      this.html = editedHtml;
      this.htmlChange.emit(editedHtml);
    });
  }
}
