import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class EmptyStringInterceptor implements HttpInterceptor {

  constructor() {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let result = this.omitByRecursivelyInPlace(req.body, (v, k) => {
      return v === '';
    });
    let updatedReq = req.clone({body: result});
    return next.handle(updatedReq);
  }
  private omitByRecursivelyInPlace(value: any, iteratee: any): any {
    _.each(value, (v, k) => {
      if(iteratee(v, k)) {
        _.unset(value, k);
      } else if(_.isObject(v)) {
        this.omitByRecursivelyInPlace(v, iteratee);
      }
    });
    return value;
  }

}
