<ui-table [data]="[data]"
          [actionMenu]="actionMenu">
  <ui-table-column name="pointCd" headerName="Location" width="80"> </ui-table-column>

  <ui-table-column name="locationName" headerName="Location Name">
  </ui-table-column>

  <ui-table-column name="recipientName" headerName="Conf Pty">
  </ui-table-column>

  <ui-table-column name="directionOfFlow" headerName="Dir Flo" width="50">
  </ui-table-column>
  <ui-table-column name="oprtgQty" headerName="Oprtg Cap"> </ui-table-column>
  <ui-table-column name="cycleStr" headerName="Cycle" width="50"> </ui-table-column>
</ui-table>
