
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class AdminUtilitiesApi {

  public readonly endpoint: string = 'administration/admin-utilities';

  constructor(
    private apiHelper: ApiHelper
  ) {}

  public getById(id: number | string): Observable<any> {
    // Find/getById does not apply to Admin Utilities
    return observableOf({});
  }

  public find(params: any): Observable<any> {
    // Find/getById does not apply to Admin Utilities
    return observableOf({});
  }

  public filter(params: any): Observable<any> {
    // Filter/Search does not apply to Admin Utilities
    return observableOf({});
  }

  public recent(): Observable<any> {
    // Dashboard/Recent does not apply to Admin Utilities
    return observableOf([]);
  }

  public save(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/save', { body: params });
  }

  public deleteItem(params: any): Observable<any> {
    // Delete does not apply to Admin Utilities
    return observableOf({});
  }

  public flushCacheTypes(params: any): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/flush-cache', { body: params });
  }

  public saveServerLoggingLevel(params: any): Observable<any> {
    return this. apiHelper.request(this.endpoint + '/save-logging-level', { body: params });
  }

  public saveServerTracingLevel(params: any): Observable<any> {
    return this. apiHelper.request(this.endpoint + '/save-server-tracing-level', { body: params });
  }

  public saveUserTracingLevel(params: any): Observable<any> {
    return this. apiHelper.request(this.endpoint + '/save-user-tracing-level', { body: params });
  }
}
