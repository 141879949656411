
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable, Optional } from '@angular/core';
import { ACTIVE_ITEM } from 'common/types/cad.constants';
import { ItemApiConfig } from 'src/features/common/item/item.model';
import { ItemApi } from 'src/features/common/item/item-api';
import { ApiCache } from 'cad/common/services/api/api-cache';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import * as _ from 'lodash';

@Injectable()
export class ItemApiService {

  public constructor(
    private apiCache: ApiCache,
    private apiHelper: ApiHelper,
    @Optional() private itemApi: ItemApi,
    private itemApiConfig: ItemApiConfig
  ) {}

  public getById(id: any): Observable<any> {
    return this.find({ [this.itemApiConfig.primaryKey]: id });
  }

  public getByCompositeId(compositeId: { [key: string]: string | number | boolean; }): Observable<any> {
    // the composite parameters come from the path in the route which is encoded as a uri.
    // when the id is a key:string object the values of the object have to be decoded in order to be sent
    // to the server properly as http post body.
    const params = _.isObject(compositeId) ? _.mapValues(compositeId, decodeURI) : compositeId;
    if (this.itemApi) {
      return this.itemApi.find(params);
    } else {
      return this.find(params);
    }
  }

  public find(params: any): Observable<any> {
    if (this.itemApi) {
      return this.itemApi.find(params);
    } else if (this.itemApiConfig.endpoint.indexOf(':') === -1) {
      return this.apiHelper.request(this.apiHelper.url(this.itemApiConfig.endpoint + '/find'), {body: params });
    } else if (this.itemApiConfig.endpoint) {
      return this.apiHelper.request(this.apiHelper.url(this.itemApiConfig.endpoint, params));
    } else {
      console.error('api or endpoint must be set in ItemModel for find()');
      return observableOf(undefined);
    }
  }

  public associationFilter(params: any): Observable<any> {
    if (this.itemApiConfig.endpoint) {
      // FIXME(ng2) - what does this do exactly?
      // if (this.itemApiConfig.associationEndpoint && this.itemApiConfig.associationEndpoint.endpoint) {
      //   return this.apiHelper.request(this.apiHelper.url(this.itemApiConfig.associationEndpoint.endpoint + '/filter'), { body: params });
      // }

      return this.apiHelper.request(this.apiHelper.url(this.itemApiConfig.endpoint + '/filter'), { body: params });
    } else {
      console.error('endpoint must be set in ItemModel for associationFilter()');
      return observableOf(undefined);
    }
  }

  public filter(params: any): Observable<any> {
    if (this.itemApi) {
      return this.itemApi.filter(params);
    } else if (this.itemApiConfig.endpoint) {
      return this.apiHelper.request(this.apiHelper.url(this.itemApiConfig.endpoint) + '/filter', { body: params });
    } else {
      console.error('api or endpoint must be set in ItemModel for filter()');
      return observableOf(undefined);
    }
  }

  public dashboard(): Observable<any> {
    if (this.itemApiConfig.endpoint) {
      return this.apiHelper.request(this.apiHelper.url(this.itemApiConfig.endpoint) + '/dashboard');
    } else {
      console.error('endpoint must be set in ItemModel for dashboard()');
      return observableOf(undefined);
    }
  }

  public recent(): Observable<any> {
    if (this.itemApi) {
      return this.itemApi.recent();
    } else {
      return this.dashboard();
    }
  }

  public save(item: any): Observable<any> {
    if (this.itemApi) {
      return this.itemApi.save(item);
    } else if (this.itemApiConfig.endpoint) {
      if (item[this.itemApiConfig.primaryKey]) {
        return this.apiHelper.request(this.apiHelper.url(this.itemApiConfig.endpoint, item), { body: item });
      } else {
        return this.apiHelper.request(this.apiHelper.url(this.itemApiConfig.endpoint), { method: 'PUT', body: item });
      }
    } else {
      console.error('api or endpoint must be set in ItemModel for save()');
      return observableOf(undefined);
    }
  }

  public deleteItem(item: any): Observable<any> {
    if (this.itemApi) {
      return this.itemApi.deleteItem(item);
    } else if (this.itemApiConfig.endpoint) {
      if (item[this.itemApiConfig.primaryKey]) {
        return this.apiHelper.request(this.apiHelper.url(this.itemApiConfig.endpoint, item), { method: 'DELETE', body: item });
      } else {
        return this.apiHelper.request(this.apiHelper.url(this.itemApiConfig.endpoint), { method: 'DELETE', body: item });
      }
    } else {
      console.error('api or endpoint must be set in ItemModel for deleteItem()');
      return observableOf(undefined);
    }
  }

  /**
   * @description Retrieves the active itemData from localStorage cache
   * @returns active itemData or null
   */
  public getActiveItemData(): any {
    let activeItemValue: any = null;

    if (this.apiCache) {
      activeItemValue = this.apiCache.for4Hours.get(ACTIVE_ITEM.CACHE_KEY);
    }

    return activeItemValue;
  }

  /**
   * @description Puts the active itemData into the localStorage cache
   * @param itemData
   */
  public setActiveItemData(itemData: any): void {
    if (this.apiCache) {
      if (!_.isNil(itemData)) {
        this.apiCache.for4Hours.put(ACTIVE_ITEM.CACHE_KEY, this.getProcessedData(itemData));
      } else {
        this.apiCache.for4Hours.remove(ACTIVE_ITEM.CACHE_KEY);
      }
    }
  }

  public getProcessedData(data: any): any {
    if (this.itemApiConfig && _.isArray(this.itemApiConfig.excludePKProperties)) {
      return _.omit(data, this.itemApiConfig.excludePKProperties);
    }
    return data;
  }
}
