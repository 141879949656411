import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Component, DoCheck, EventEmitter, HostListener, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { UiMatVirtualSelectComponent } from 'src/cad/shared/forms/select/virtual-select/virtual-select.component';
import { UiFormBaseSelectComponent } from '../base/base-select.component';
import { UiSelectHeaderFilterComponent } from '../features/select-header-filter/select-header-filter.component';

@Component({
  selector: 'ui-select',
  templateUrl: './select.component.html',
  styleUrls: [ './select.component.less' ],
  exportAs: 'uiSelect',
})
export class UiFormSelectComponent extends UiFormBaseSelectComponent implements AfterViewInit, DoCheck {

  /** Flag to enable/disable virtual options */
  @Input()
  get enableVirtualOptions(): boolean { return this._isVirtual; }
  set enableVirtualOptions(value: boolean) {
    if (!this.suppressVirtualOptions && this._isVirtual !== coerceBooleanProperty(value)) {
      this._isVirtual = coerceBooleanProperty(value);
    }
  }
  /** Flag to enable/disable header typeahead filter feature */
  @Input() enableHeaderFilter: boolean;
  /** Flag to enable/disable showing of the header selection and results row */
  @Input() showHeaderSelectionRow: boolean;
  /** Flag to turn off virtual options feature */
  @Input() suppressVirtualOptions: boolean = false;
  /** options limit to turn on the virtual options */
  @Input() virtualActivationIndex: number = 15;
  @Input('useStartsWithFilter') useStartsWithFilter: boolean;
  @Input('useCaseSensitiveFilter') useCaseSensitiveFilter: boolean;
  @Output('change') selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();

  @ViewChild('matSelectRef') matSelectRef: UiMatVirtualSelectComponent | MatSelect;
  @ViewChild(UiSelectHeaderFilterComponent) uiSelectHeaderFilterRef: UiSelectHeaderFilterComponent;
  @ViewChildren(UiMatVirtualSelectComponent) virtualChildrenComponent: QueryList<UiMatVirtualSelectComponent>;

  @HostListener('focusin') onHostFocus(): void {
    this.onFocus();
  }

  public get renderedItems(): any[] { return this._renderedItems; }
  public set renderedItems(value: any[]) {
    this._renderedItems = value;
  }

  private _isVirtual: boolean;
  private _renderedItems: any[];
  private _typeaheadCompletedSubscription: Subscription = Subscription.EMPTY;

  ngAfterViewInit(): void { }

  ngDoCheck(): void {
    if (this.ngControlRef
      && this.matSelectRef
      && this.matSelectRef.ngControl
      && this.matSelectRef.ngControl.control) {
      if (this.ngControlRef.untouched) {
        this.matSelectRef.ngControl.control.markAsUntouched();
      }
      if (this.ngControlRef.touched) {
        this.matSelectRef.ngControl.control.markAsTouched();
      }
      if (this.ngControlRef.pristine) {
        this.matSelectRef.ngControl.control.markAsPristine();
      }
      if (this.ngControlRef.dirty) {
        this.matSelectRef.ngControl.control.markAsDirty();
      }
      this.matSelectRef.ngDoCheck();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._typeaheadCompletedSubscription.unsubscribe();
  }

  onFocus = (): void => {
    if (this.matSelectRef) {
      this.matSelectRef.focus();
    }
  }

  onSelectBlur(event: any): boolean {
    let shouldBlur: boolean = true;
    if (event && event.target && !this.enableHeaderFilter) {
      if (this.matSelectRef
        && this.matSelectRef._elementRef
        && event.target === this.matSelectRef._elementRef.nativeElement
        && this.matSelectRef.panelOpen) {
        this.matSelectRef.focus();
        shouldBlur = false;
      }
    }
    return shouldBlur;
  }

  onPanelOpen(): void {
    if (this.enableHeaderFilter
      && this.uiSelectHeaderFilterRef
      && this.uiSelectHeaderFilterRef.input) {
      this.uiSelectHeaderFilterRef.input.nativeElement.focus();
    }
  }

  onPanelClose(): void {
    if (this.enableHeaderFilter) {
      if (this.uiSelectHeaderFilterRef) {
        if (this.uiSelectHeaderFilterRef.selectionsOnly) {
          this.uiSelectHeaderFilterRef.selectionsOnly = null;
        }
        this.uiSelectHeaderFilterRef.clearHeaderFilterSearchText();
        this.changeDetectorRef.detectChanges();
      }
      if (this.multiple) {
        (this.matSelectRef as any)._propagateChanges();
      }
    }
  }

  public optionListChanged(optionList: any[]): void {
    super.optionListChanged(optionList);
    // enable virtual options if the option items count is greater than the 'vsActivationIndex'
    this.enableVirtualization(this.optionList.length > this.virtualActivationIndex);
  }

  enableVirtualization(enable: boolean): void {
    if (this.enableVirtualOptions !== enable) {
      this.enableVirtualOptions = enable;
      this.runOnStableZone(() => {
        if (this.matSelectRef) {
          // In IE11 clicking on the panel's scrollbar focuses on the panel element
          // causing typeahead not to function since typeahead listens on key events
          // on the select element. This fixes the above issue for IE11
          let originalOnBlurFn: () => void = this.matSelectRef._onBlur;
          this.matSelectRef._onBlur = (): void => {
            if (this.onSelectBlur(event)) {
              originalOnBlurFn.call(this.matSelectRef);
            }
          };
          (this.matSelectRef.selectionChange as any) = this.selectionChange;
          this.matSelectRef.registerOnTouched((): any => {
            if (this.matSelectRef.ngControl && this.matSelectRef.ngControl.control) {
              this.matSelectRef.ngControl.control.markAsTouched();
            }
            this.onTouchedCallback();
          });
        }
      });
    }
  }
}
