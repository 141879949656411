import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import {
  ColDef, Column, Events, IHeaderParams, RowNode
} from '@ag-grid-community/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { UiGridApi } from 'src/ag-grid-wrapper';
import { AutoUnsubscribables, AutoUnsubscriber } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import { HeaderFilterType } from 'src/ag-grid-wrapper/interfaces/header-filter-type';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';

interface MyParams extends IHeaderParams {
  menuIcon: string;
  defaultFilterType: string;
  showSortOrder: boolean;
  allowSelectAll: boolean;
  columnCheckBoxStateChanged: EventEmitter<boolean>;
}

@Component({
  moduleId: module.id + '',
  selector: 'filter-header-cell-renderer',
  templateUrl: './filter-header.component.html',
  styleUrls: [ './filter-header.component.less' ]
})
export class TableHeaderFilterRendererComponent implements IHeaderAngularComp, AfterViewInit, OnDestroy {

  public params: MyParams;
  public gridApi: UiGridApi;
  public column: Column;
  public colDef: ColDef;
  public sorted: string;
  public columnType: string | string[];
  public filterTypeList: HeaderFilterType[];
  public filterType1: string;
  public filterType2: string;
  public filterCriteria1: string = '';
  public filterCriteria2: string = '';
  public filterOperator: string = 'AND';
  public dateRangeTo1: string;
  public dateRangeTo2: string;
  public mode: 'filter' | 'sort';
  public isFilteredOn: boolean;
  public isOpen: boolean = false;
  public sortOrderValue: string;
  public showSortIndex: boolean;
  public headerIconIsActive: boolean = false;
  public checkboxSelectAllState: boolean = false;
  public allowSelectAll: boolean = false;
  public checkboxSelectAllStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public showSecondCondition: boolean;

  public textTypes: any[] = [
    { type: 'equals', name: 'Equals' },
    { type: 'notEqual', name: 'Doesn\'t Equal' },
    { type: 'startsWith', name: 'Starts With' },
    { type: 'endsWith', name: 'Ends With' },
    { type: 'contains', name: 'Contains' },
    { type: 'notContains', name: 'Doesn\'t Contain' }
  ];

  public dateTypes: any[] = [
    { type: 'equals', name: 'Equals' },
    { type: 'greaterThan', name: 'Greater Than' },
    { type: 'lessThan', name: 'Less Than' },
    { type: 'notEqual', name: 'Doesn\'t Equal' },
    // TODO (John): Add Range Support
    // { type: 'inRange', name: 'In Range' },
  ];

  public numberTypes: any[] = [
    { type: 'equals', name: 'Equals' },
    { type: 'greaterThan', name: 'Greater Than' },
    { type: 'greaterThanOrEqual', name: 'Greater Than Or Equal To' },
    { type: 'lessThan', name: 'Less Than' },
    { type: 'lessThanOrEqual', name: 'Less Than Or Equal To' },
    { type: 'notEqual', name: 'Doesn\'t Equal' },
    // TODO (John): Add Range Support
    // { type: 'inRange', name: 'In Range' },
  ];
  @AutoUnsubscriber() private subs: AutoUnsubscribables;
  @ViewChild('filterContainerRef', { read: ElementRef }) private filterContainerRef: ElementRef;
  private sortChangedHandler: Function;
  private onMultiSortOrderHandler: Function;
  private filterActiveChangedHandler: Function;

  constructor() {
    this.sortChangedHandler = this.onSortChanged.bind(this);
    this.onMultiSortOrderHandler = this.setMultiSortOrder.bind(this);
    this.filterActiveChangedHandler = this.onFilterActiveChanged.bind(this);
  }

  agInit(params: MyParams): void {
    this.processParams(params);
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.gridApi) {
        if (this.gridApi.getFilterInstance(this.column) && this.gridApi.getFilterInstance(this.column).getModel()) {
          if (this.gridApi.getFilterInstance(this.column).getModel().condition1
            || this.gridApi.getFilterInstance(this.column).getModel().condition2) {
            if (!_.isEmpty(this.gridApi.getFilterInstance(this.column).getModel().condition1.filter)) {
              this.filterCriteria1 = this.gridApi.getFilterInstance(this.column).getModel().condition1.filter;
            }
            if (!_.isEmpty(this.gridApi.getFilterInstance(this.column).getModel().condition2.filter)) {
              this.filterCriteria2 = this.gridApi.getFilterInstance(this.column).getModel().condition2.filter;
            }
          } else if (!_.isEmpty(this.gridApi.getFilterInstance(this.column).getModel().filter)) {
            this.filterCriteria1 = this.gridApi.getFilterInstance(this.column).getModel().filter;
          } else if (!_.isEmpty(this.gridApi.getFilterInstance(this.column).getModel().dateFrom)) {
            this.filterCriteria1 = this.gridApi.getFilterInstance(this.column).getModel().dateFrom;
          }
          this.updateFilteredOn();
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.clearParams();
    this.sortChangedHandler = undefined;
    this.onMultiSortOrderHandler = null;
  }

  public onMenuOpened(): void {
    setTimeout(() => {
      if (this.filterContainerRef && this.filterContainerRef.nativeElement.querySelector('input')) {
        this.filterContainerRef.nativeElement.querySelector('input').focus();
      }
    });
  }

  public onSortRequested(event: any): void {
    let order: any = 'asc';
    if (this.column) {
      if (this.column.isSortAscending()) {
        order = 'desc';
      } else if (this.column.isSortDescending()) {
        order = '';
      }
    }
    if (event && this.params && this.params.enableSorting) {
      this.params.setSort(order, event.shiftKey);
    }
  }

  updateCheckBoxRows(event: any) : void {
    const colId = this.column.getColId();
    this.checkboxSelectAllStateChange.emit(event.checked);
    this.gridApi.forEachNode((rowNode: RowNode) => {
      rowNode.setDataValue(colId, event.checked);
    });
  }

  public onSortChanged(): void {
    let sortOrder: any = '';
    if (this.column) {
      if (this.column.isSortAscending()) {
        sortOrder = 'asc';
      } else if (this.column.isSortDescending()) {
        sortOrder = 'desc';
      }
    }
    this.sorted = sortOrder;
  }

  public onFilterActiveChanged(): void {
    if (!(this.column && this.column.isFilterActive())) {
      this.filterCriteria1 = '';
      this.filterCriteria2 = '';
      this.filterOperator = 'AND';
      this.updateFilteredOn();
    }
  }

  public refresh(params: any): boolean {
    this.processParams(params);
    return true;
  }

  public onFilter(filterVal: any): void {
    let newFilterModel: any;
    let currentFilterModel: any = {};
    let colId: string;
    let filterProp: string;
    if ([ 'date' ].indexOf(this.columnType as string) < 0) {
      filterProp = 'filter';
    } else if (this.columnType === 'date') {
      filterProp = 'dateFrom';
    } else {
      filterProp = null;
    }
    if (this.gridApi) {
      if (this.column) {
        colId = this.column.getColId();
      }
      currentFilterModel = this.gridApi.getFilterModel() || {};
      if (!_.isNil(colId) && !_.isNil(filterProp)) {
        if (this.showSecondCondition) {
          newFilterModel = _.assign(currentFilterModel, {
            [ colId ]: {
              operator: this.filterOperator,
              condition1: {
                [ filterProp ]: this.columnType === 'date'
                  ? moment(this.filterCriteria1).format('YYYY-MM-DD')
                  : this.filterCriteria1,
                type: this.filterType1
              },
              condition2: {
                [ filterProp ]: this.columnType === 'date'
                  ? moment(this.filterCriteria2).format('YYYY-MM-DD')
                  : this.filterCriteria2,
                type: this.filterType2
              }
            }
          });
        } else {
          newFilterModel = _.assign(currentFilterModel, {
            [ colId ]: {
              type: this.filterType1,
              [ filterProp ]: this.columnType === 'date' ? moment(filterVal).format('YYYY-MM-DD') : filterVal
            }
          });
        }
      }
      this.gridApi.setFilterModel(newFilterModel);
      this.gridApi.onFilterChanged();
    }
    this.updateFilteredOn();
  }

  public onClearFilter(isFirstCondition: boolean): void {
    let currentFilterModel: any;
    let colId: string;

    if (this.column) {
      colId = this.column.getColId();
    }
    if (!_.isNil(colId) && this.gridApi) {
      currentFilterModel = this.gridApi.getFilterModel() || {};
      this.gridApi.setFilterModel(_.assign(currentFilterModel, {
        [ colId ]: (!isFirstCondition && currentFilterModel[ colId ].condition1)
          ? currentFilterModel[ colId ].condition1
          : null
      }));
    }
    if (isFirstCondition) {
      this.filterCriteria1 = '';
      this.filterOperator = 'AND';
    }
    this.filterCriteria2 = '';
    this.updateFilteredOn();
  }

  public isFilterEnabled(): boolean {
    return this.column && this.column.isFilterAllowed();
  }

  private setMode(): void {
    this.mode = this.isFilterEnabled() ? 'filter' : 'sort';
  }

  private setFilterTypes(): void {
    let filterName: string;
    let filterType: string;

    if (this.colDef && _.isString(this.colDef.filter)) {
      filterName = this.colDef.filter;
    }
    if (filterName === 'date' || this.columnType === 'date') {
      this.filterTypeList = this.dateTypes;
      filterType = 'equals';
    } else if (filterName === 'number'
      || this.columnType === 'number'
      || this.columnType === 'currency') {
      this.filterTypeList = this.numberTypes;
      filterType = 'equals';
    } else if (  this.columnType === 'checkbox' || this.columnType === 'checkbox-boolean') {
      filterType = 'equals';
    } else {
      this.filterTypeList = this.textTypes;
      filterType = 'startsWith';
    }
    if (this.params && !_.isNil(this.params.defaultFilterType)) {
      filterType = this.params.defaultFilterType;
    }
    this.filterType1 = filterType;
    this.filterType2 = filterType;
  }

  private processParams(params: MyParams): void {
    if (this.params !== params) {
      this.clearParams();
      this.params = params;
      if (this.params) {
        this.gridApi = this.params.api;
        if (this.gridApi) {
          this.gridApi.addEventListener(Events.EVENT_SORT_CHANGED, this.onMultiSortOrderHandler);
        }
        this.column = this.params.column;
        if (this.column) {
          this.colDef = this.column.getColDef();
          this.column.addEventListener(Events.EVENT_SORT_CHANGED, this.sortChangedHandler);
          this.column.addEventListener(Column.EVENT_FILTER_ACTIVE_CHANGED, this.filterActiveChangedHandler);
          if (this.colDef) {
            this.columnType = this.colDef.type || 'text';
          }
        }
        if(params.allowSelectAll) {
          this.allowSelectAll = true;
          this.checkboxSelectAllStateChange = params.columnCheckBoxStateChanged;
          this.subs.newSub = this.checkboxSelectAllStateChange.subscribe((state) => {
            this.checkboxSelectAllState = state;
          });
        }
      }
      this.setFilterTypes();
      this.setMode();
      this.onSortChanged();
      this.setMultiSortOrder();
    }
  }

  private clearParams(): void {
    if (!_.isNil(this.columnType)) {
      this.columnType = null;
    }
    if (!_.isNil(this.colDef)) {
      this.colDef = null;
    }
    if (!_.isNil(this.column)) {
      this.column.removeEventListener(Events.EVENT_SORT_CHANGED, this.sortChangedHandler);
      this.column.removeEventListener(Column.EVENT_FILTER_ACTIVE_CHANGED, this.filterActiveChangedHandler);
      this.column = null;
    }
    if (!_.isNil(this.gridApi)) {
      this.gridApi.removeEventListener(Events.EVENT_SORT_CHANGED, this.onMultiSortOrderHandler);
      this.gridApi = null;
    }
    if (!_.isNil(this.params)) {
      this.params = null;
    }
  }

  private setMultiSortOrder(): void {
    if (this.params
      && this.column
      && this.params.showSortOrder) {
      let allColumnsWithSorting: {
        colId: string;
        sort: string;
      }[] = this.gridApi.getSortModel();
      let indexThisCol: number = _.findIndex(allColumnsWithSorting, { colId: this.column.getColId() });
      let moreThanOneColSorting: boolean = allColumnsWithSorting.length > 1;
      this.showSortIndex = this.column.isSorting() && moreThanOneColSorting;
      if (indexThisCol >= 0) {
        this.sortOrderValue = (indexThisCol + 1).toString();
      } else {
        this.sortOrderValue = '';
      }
    }
  }

  public iconClick(): void {
    this.headerIconIsActive = !this.headerIconIsActive;
  }

  public updateFilteredOn(): void {
    this.isFilteredOn = !((_.isNil(this.filterCriteria1) || this.filterCriteria1 === '')
    && (_.isNil(this.filterCriteria2) || this.filterCriteria2 === ''));
    this.showSecondCondition = this.columnType !== 'checkbox'
      && this.columnType !== 'checkbox-boolean'
      && !_.isNil(this.filterCriteria1)
      && this.filterCriteria1 !== ''
      && !(this.colDef
      && this.colDef.filterParams
      && this.colDef.filterParams.suppressAndOrCondition);
  }
}
