import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';
import { PhysicalAssetApi } from 'cad/common/services/api/physical/asset/asset';
import { PhysicalLocationGroupApi } from 'cad/common/services/api/physical/location-group/location-group';
import { PhysicalLocationApi } from 'cad/common/services/api/physical/location/location';
import { PhysicalMeterApi } from 'cad/common/services/api/physical/meter/meter';
import { PhysicalSegmentApi } from 'cad/common/services/api/physical/segment/segment';
import { PhysicalStatesApi } from 'cad/common/services/api/physical/states/states';
import { PhysicalCapacityApi } from 'cad/common/services/api/physical/capacity/capacity';

@Injectable()
export class PhysicalApi {
  constructor(
    public states: PhysicalStatesApi,
    public location: PhysicalLocationApi,
    public segment: PhysicalSegmentApi,
    public locationGroup: PhysicalLocationGroupApi,
    public meter: PhysicalMeterApi,
    public capacity: PhysicalCapacityApi,
    public asset: PhysicalAssetApi,
    private apiHelper: ApiHelper,
  ) {}

  public getAllSeasons(): Observable<any> {
    // TODO: Uncomment the line below and remove the line below it once this endpoint is added to the MO Mplus branch
    // return this.apiHelper.request('physical/all-seasons');
    return this.apiHelper.request('physical/all-seasons', { quiet: true });
  }

  public queryAssets = (params: any): Observable<any> => {
    return this.apiHelper.request('physical/asset');
  }

  public getAssetContactCombine = (): Observable<any> => {
    return this.apiHelper.request('physical/asset-contact-combine');
  }
}
