import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class CommentApi {

  private readonly endpoint: string = '/comments';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public filter = (vertical: string, params: any): Observable<any> => {
    return this.apiHelper.request(vertical + this.endpoint + '/filter', { body: params });
  }

  public findAll = (vertical: string, params: any): Observable<any> => {
    return this.apiHelper.request(vertical + this.endpoint + '/' + params.type + '/' + params.id);
  }

  public save = (vertical: string, comment: any): Observable<any> => {
    if (comment.id) {
      return this.apiHelper.request(vertical + this.endpoint + '/' + comment.id, { body: comment });
    } else {
      return this.apiHelper.request(vertical + this.endpoint, { method: 'PUT', body: comment });
    }
  }

  public saveAll = (vertical: string, comments: any): Observable<any> => {
    return this.apiHelper.request(vertical + this.endpoint, { body: comments });
  }

  public delete = (vertical: string, comment: any): Observable<any> => {
    return this.apiHelper.request(vertical + this.endpoint, { method: 'DELETE', body: comment });
  }
}
