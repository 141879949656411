import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';


@Directive({
  selector: '[numbersOnly]',
  exportAs: 'numbersOnly',
})
export class NumberDirective {

  private nbrPattern: RegExp = /\D/g;

  constructor(public ngModel: NgModel) {}

  @HostListener('keydown', [ '$event' ])
  public onKeyDown(e: KeyboardEvent): void {
    if ([ 46, 8, 9, 27, 13 ].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }

  @HostListener('paste', [ '$event' ])
  public onPaste(e: ClipboardEvent): void {
    let pastedData: string = e.clipboardData.getData('text');
    // if the user pastes non numeric data we do not allow the paste
    if (pastedData.match(this.nbrPattern)) {
      e.preventDefault();
      this.ngModel.control.setValue(pastedData.replace(this.nbrPattern,''));
    }
  }

}
