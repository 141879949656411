
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injector, Injectable } from '@angular/core';

import { AssociationController } from 'src/features/common/association/association.controller';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AssociationApi } from 'src/features/common/association/association-api';

import { AlertsService } from 'cad/core/services/alerts/alerts.service';

@Injectable()
export class PhysicalLocationAssociatedGroupsApi implements AssociationApi {
  private readonly endpoint: string = 'physical/locations/';
  private readonly endpointSuffix: string = '/location-groups';

  static createAssociationApi(
    apiHelper: ApiHelper,
    associationController: AssociationController,
    injector: Injector,
  ): AssociationApi {
    return new PhysicalLocationAssociatedGroupsApi(apiHelper, injector.get(AlertsService));
  }

  constructor(
    private apiHelper: ApiHelper,
    private alerts: AlertsService,
  ) {}

  public filter(params: any): Observable<any> {
    return this.apiHelper.request('physical/location-groups/group-detail/filter', { body: params });
  }

  public findAll = (id: number | string): Observable<any> => {
    return this.apiHelper.request(this.endpoint + id + this.endpointSuffix);
  }

  public search = (id: number | string): Observable<any> => {
    return this.apiHelper.request('physical/locations/' + id + '/location-groups/all');
  }

  public save = (itemData: any): Observable<any> => {
    if (!_.isEmpty(itemData) && itemData.length > 0) {
      return this.apiHelper.request(this.endpoint + itemData[0].pointCd + this.endpointSuffix, { method: 'POST', body: itemData });
    } else {
      return observableThrowError('Selected object is empty');
    }
  }
}
