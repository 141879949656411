
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class RightPriceServiceApi {

  public readonly endpoint: string = 'product/right-price-service';

  constructor(
    private apiHelper: ApiHelper
  ) {}

  public find(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/find`, { body: params });
  }

  public filter(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/filter`, { body: params });
  }

  public recent(): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/recent`);
  }

  public save(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/save`, { body: params });
  }

  public deleteItem(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/delete`, { body: params });
  }

  public getById = ( id: number | string ): Observable<any> => {
    return this.apiHelper.request( this.endpoint + '/' + id ).pipe(map( ( point ) => {
      point.pointCd = point.code;
      return point;
    } ));
  }


  public findRights(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/findrights`, { body: params });
  }

  public findPrices(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/findprices`, { body: params });
  }

  public findInputs(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/findinputs`, { body: params });
  }

  public saveRights(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/saverights`, { body: params });
  }

  public savePrices(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/saveprices`, { body: params });
  }
  public saveInputs(params: any): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/saveinputs`, { body: params });
  }

  public getInputTemplatesForLookup(): Observable<any> {
    return this.apiHelper.request(`${this.endpoint}/inputslookup`);
  }

}
