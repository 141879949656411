
import {of as observableOf,  Observable } from 'rxjs';
import { Injector, Injectable } from '@angular/core';

import { AssociationController } from 'src/features/common/association/association.controller';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AssociationApi } from 'src/features/common/association/association-api';

@Injectable()
export class PhysicalLocationSurveyApi implements AssociationApi {
  private readonly endpoint: string = 'physical/locations/';
  private readonly endpointSuffix: string = '/survey';

  static createAssociationApi(
    apiHelper: ApiHelper,
    associationController: AssociationController,
    injector: Injector,
  ): AssociationApi {
    return new PhysicalLocationSurveyApi(apiHelper);
  }

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public findAll = (pointCd: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + pointCd + this.endpointSuffix);
  }

  public find = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + params.pointCd + this.endpointSuffix + '/' + params.surveyId);
  }

  public filter = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + params.pointCd + this.endpointSuffix + '/filter', { body: params });
  }

  public save = (associations: any): Observable<any> => {
    if (!_.isArray(associations)) {
      return this.apiHelper.request(this.endpoint + associations.pointCd + this.endpointSuffix, { body: [ associations ] });
    } else {
      return this.apiHelper.request(this.endpoint + (associations[ 0 ] as any).pointCd + this.endpointSuffix, { body: associations });
    }
  }

  public recent = (): Observable<any> => {
    return observableOf(undefined);
  }

  public deleteItem = (item: any): Observable<any> => {
    return observableOf(undefined);
  }
}
