/**
 * Created by jrayavarap on 8/4/2017.
 */
import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'location-contacts-common',
  templateUrl: './location-contacts-common.component.html',
  styleUrls: [ './location-contacts-common.component.less' ]
})
export class LocationContactsCommonComponent implements OnChanges {
  @Input() contactObj: any;
  @Input() headLine: string;
  @Input() type: string;
  @Input() label: string;
  @Input() confirmationInfo: boolean;

  public fullName: string;

  public ngOnChanges(): void {
    let fName = (this.contactObj && this.contactObj.firstName) ? this.contactObj.firstName : '';
    let lName = (this.contactObj && this.contactObj.lastName) ? this.contactObj.lastName : '';
    if(fName !== '' || lName !== '') {
      this.fullName = fName + ' ' + lName;
    } else {
      this.fullName = '';
    }
  }
}
