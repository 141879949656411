import { UiGridApi } from './ui-grid-api';
import { Injectable } from '@angular/core';
import { GridApi, ColDef, RowNode, RowSelectedEvent } from '@ag-grid-community/core';
import { TableComponent } from '../table/table.component';
import { isUndefined } from 'util';
import * as _ from 'lodash';
import { NetExpression } from 'src/ag-grid-wrapper/interfaces/net-expression';

@Injectable()
export class TableRowFooterAggregationFactory {

  constructor() {}

  public unRegisterGridListener(table: TableComponent): void {
  }

  public onGridApiRegistered(table: TableComponent): void {
    if (table.api && table.api.rowEdit) {
      const data = table.api.rowEdit.getAllRowsData();
      if (data.length > 0) {
        this.setBottomRowData(table.api, null);
      } else {
        table.api.setPinnedBottomRowData([]);
      }
    } else {
      return;
    }
    table.subs.newSub = table.cellValueChanged.asObservable().subscribe(this.onCellValueChanged.bind(this));
    table.subs.newSub = table.modelUpdated.asObservable().subscribe(this.onModelUpdated(table.api).bind(this));
    if (table.showSingleSelect) {
      table.subs.newSub = table.rowSelected.asObservable().subscribe(this.onRowSelected.bind(this));
    }
  }

  public onModelUpdated(api: UiGridApi): () => void {
    return (): void => {
      const data = api.rowEdit.getAllRowsData();
      if (data.length > 0) {
        this.setBottomRowData(api, null);
      } else {
        api.setPinnedBottomRowData([]);
      }
    };
  }

  //The same function can be used for totals or subtotals with selected row index
  public getCellValuesByIndex(api: UiGridApi, field: string, index: number, onlyFiltered?: boolean): number[] {
    let cellValueArray: number[] = [];
    (onlyFiltered ? api.forEachNodeAfterFilter : api.forEachNode).call(api, (rowNode: RowNode) => {
      if (this.isNaNNullCheck(index)) {
        if (index >= rowNode.rowIndex) {
          if (rowNode.data && !isNaN(rowNode.data[ field ]) && !isUndefined(rowNode.data[ field ])) {
            cellValueArray.push(Number(rowNode.data[ field ]));
          }
        }
      } else {
        if (rowNode.data && !isNaN(rowNode.data[ field ]) && !isUndefined(rowNode.data[ field ])) {
          cellValueArray.push(Number(rowNode.data[ field ]));
        }
      }
    });
    return cellValueArray;
  }

  public getCellValuesWithFilter(api: UiGridApi, field: string, filter: (rowData: any) => boolean, onlyFiltered?: boolean): any {
    let cellValueArray: any[] = [];
    (onlyFiltered ? api.forEachNodeAfterFilter : api.forEachNode).call(api, (rowNode: RowNode) => {
      if (rowNode.data && filter(rowNode.data) ) {
        if (!_.isNil(rowNode.data[field])) {
          cellValueArray.push(Number(rowNode.data[field]));
        }
      }
    });
    return cellValueArray;
  }

  public getCellValues(api: UiGridApi, field: string, onlyFiltered?: boolean): any {
    let cellValueArray: any[] = [];
    (onlyFiltered ? api.forEachNodeAfterFilter : api.forEachNode).call(api, (rowNode: RowNode) => {
      if (rowNode.data && !_.isNil(rowNode.data[field])) {
        cellValueArray.push(Number(rowNode.data[field]));
      }
    });
    return cellValueArray;
  }

  public onRowSelected(event: RowSelectedEvent): void {
    if (event && event.node && event.node.isSelected()) {
      this.setBottomRowData(event.api, event.node.rowIndex);
    }
  }

  private setBottomRowData(api: UiGridApi, index: number): any {
    let columnDefs = this.getColDefs(api); // private member access
    let col: any;
    let numColumns = columnDefs.length;
    let footerRows : any[] = [ {} ];
    const footerMap : any = {};
    let footerRowLabelColumnField: string;
    const internalOrderFieldName = '**footerOrderingField**';

    for (let i = 0; i < numColumns; i++) {
      col = columnDefs[ i ] as ColDef;

      if(col.footerRowLabelColumn) {
        footerRowLabelColumnField = col.field;
      } else if (!_.isNil(col.aggregationType) || !_.isNil(col.subTotalLabel)) {
        const values = this.getCellValues(api, col.field);
        if(!footerMap.Header) {
          footerMap.Header = {};
        }
        footerMap.Header[col.field] = col.headerName;
        if(col.aggregationType) {
          if(!footerMap.Total) {
            footerMap.Total = {};
          }
          footerMap.Total[col.field] = this.getColumnCalcValue(api, col.field, col.aggregationType, values, col.aggregationColumns);
        }
        if (index !== null) {
          if(!footerMap.SubTotal) {
            footerMap.SubTotal = {};
          }
          if (col.aggregationType) {
            const subTotalValues =  this.getCellValuesByIndex(api, col.field, index);
            footerMap.SubTotal[col.field] = this.getColumnCalcValue(api, col.field, col.aggregationType, subTotalValues, col.aggregationColumns, null, index);
          }
          if (col.subTotalLabel) {
            footerMap.SubTotal[col.field] = col.subTotalLabel + ' ' + this.calcSubTotalLabel(api, col.field, index);
          }
        }
      } else if ( col.footerConfiguration && col.footerConfiguration.length > 0 ) {

        footerRows[0][col.field] = col.headerName;
        footerRows[0][internalOrderFieldName] = -10000000;
        col.footerConfiguration.forEach( (config) => {
          let footerRow = footerRows.find( (x: any) => x[footerRowLabelColumnField] === config.label);
          if (!footerRow) {
            const newRowIndex = footerRows.push({}) - 1;
            footerRow = footerRows[newRowIndex];
          }
          let values: number[] =  [];
          if (config.filter) {
            values = this.getCellValuesWithFilter(api, col.field, config.filter, config.onlyFiltered);
          } else {
            values = this.getCellValues(api, col.field, config.onlyFiltered);
          }
          footerRow[col.field] = this.getColumnCalcValue(api, col.field, config.type , values, null, config.aggFunction, null, config.onlyFiltered);
          footerRow[footerRowLabelColumnField] = config.label;
          footerRow[internalOrderFieldName] = !_.isNil(config.order) ? config.order : 999999999;
        });


      }
      if(!_.isNil(col.totalLabel)) {
        if(!footerMap.Total) {
          footerMap.Total = {};
        }
        footerMap.Total[col.field] = col.totalLabel;

        if(!footerMap.Header) {
          footerMap.Header = {};
        }
      }
    }

    // the presence of items in the footer map indicated that the aggregationType option was used on some column
    // You cannot mix defining a footer using aggregationType and on a different column defining the footerConfiguration
    if (Object.keys(footerMap).length > 0) {
      footerRows = [];
      if(footerMap.Header) {
        footerRows.push(footerMap.Header);
      }
      if(footerMap.SubTotal) {
        footerRows.push(footerMap.SubTotal);
      }
      if(footerMap.Total) {
        footerRows.push(footerMap.Total);
      }
    } else {
      footerRows = footerRows.sort((a: any, b: any) => {
        const result = a[internalOrderFieldName] < b[internalOrderFieldName] ? -1 : a[internalOrderFieldName] > b[internalOrderFieldName] ? 1 : 0 ;
        return result;
      });
    }
    api.setPinnedBottomRowData(footerRows);
  }

  private getColDefs(api: UiGridApi): any {
    let colDef = (api as any).gridCore.gridOptions.columnDefs;
    const colDefs = [];
    _.forEach((colDef), (col) => {
      if(col.children) {
        _.forEach((col.children), (cols) => {
          colDefs.push(cols);
        });
      } else {
        colDefs.push(col);
      }
    });
    return colDefs;
  }

  private getColumnDef(api: UiGridApi, field: string): ColDef {
    let colDefs = this.getColDefs(api);
    for (let colDef of colDefs) {
      if (colDef.field === field) { return colDef; }
    }
    return undefined;
  }

  // Function to calculate aggregation value based on type and selected row index
  private getColumnCalcValue(api: UiGridApi, field: string, aggregationType: string, values: number[],
                             aggregationColumns?: string, aggFunction?: ( values: number[] ) => number, index? : number,
                             onlyFiltered?: boolean): any {
    switch (aggregationType) {
      case 'sum': {
        return this.getColumnValuesSum(values);
      }
      case 'avg': {
        return this.getColumnValuesAvg(values);
      }
      case 'avgRound': {
        return this.getColumnValuesAvgRound(values);
      }
      case 'max': {
        return this.getColumnValuesMax(values);
      }
      case 'min': {
        return this.getColumnValuesMin(values);
      }
      case 'first': {
        return this.getColumnValuesFirst(values);
      }
      case 'last': {
        return this.getColumnValuesLast(values);
      }
      case 'net': {
        return this.getColumnValuesNet(api, field, onlyFiltered, index);
      }
      case 'imbalancePct' : {
        let columnNames = aggregationColumns.split(',');
        const imbalanceSum = _.sum(this.getCellValuesByIndex(api, columnNames[0], index, onlyFiltered));
        const scheduledNet = this.getColumnValuesNet(api, columnNames[1], onlyFiltered, index);
        return this.getColumnValuesImbalancePct(imbalanceSum, scheduledNet);
      }
      case 'custom': {
        const rowData = onlyFiltered ? api.rowEdit.getAllRowsDataAfterFilter() : api.rowEdit.getAllRowsData();
        return this.getColumnValuesCustom(rowData, aggFunction, field);
      }
      default: {
        return 0;
      }
    }
  }


  private getColumnValuesSum(values: number[]): number {
    let sum =  _.sum(values);
    return (isNaN(sum) || isUndefined(sum)) ? 0 : sum;
  }

  private getColumnValuesAvg( values: number[]): number {
    if (values.length > 0) {
      return this.getColumnValuesSum( values) / values.length; // private member access
    } else {
      return 0;
    }

  }

  // To get average values to round off
  private getColumnValuesAvgRound(values: number[]): number {
    if (values.length > 0) {
      return Math.round(this.getColumnValuesSum( values) / values.length); // private member access
    } else {
      return 0;
    }
  }

  private isNaNNullCheck(index: number): boolean {
    return index !== null && !isNaN(index);
  }

  private getColumnValuesMax( values: number[]): number {
    return Math.max.apply(null, values);
  }

  private getColumnValuesMin(values: number[]): number {
    return Math.min.apply(null, values);
  }

  private getColumnValuesFirst(values: number[]): number {
    return values.length > 0 ? values[ 0 ] : null;
  }

  private getColumnValuesLast(values: number[]): number {
    return values.length > 0 ? values[ values.length - 1 ] : null;
  }

  // Custom calculation for a table footer in flowing gas
  private getColumnValuesImbalancePct(imbSum: number, scheduledNet: number): any {
    let result : any = '';
    if(Math.abs(scheduledNet) > 0 ) {
      result = Math.abs(100*imbSum/scheduledNet).toFixed(2).toString();
    }
    return result? result.concat('%') : result;
  }

  private getColumnValuesCustom( rowData: any[], aggFunction: ( rowData: any[], field: string ) => number, field: string ): number {
    if(aggFunction) {
      return aggFunction(rowData, field);
    } else {
      return 0;
    }

  }

  /*
   aggregationTypeNetExpressionModel
   {
   keyCol: 'column1',
   key1: 'D',
   key2: 'R'
   }
   */
  private getColumnValuesNet(api: UiGridApi, field: string, onlyFiltered?: boolean, index?: number): number {
    let net: number = 0;
    let colDef = this.getColumnDef(api, field);
    if (colDef && (colDef as any).aggregationTypeNetExpression) {
      let netExpression: NetExpression = (colDef as any).aggregationTypeNetExpression;
      let key1Values = this.getCellValuesByKey(api, field, netExpression.keyCol, netExpression.key1, onlyFiltered, index);
      let key2Values = this.getCellValuesByKey(api, field, netExpression.keyCol, netExpression.key2, onlyFiltered, index);
      net = Math.abs(_.sum(key1Values) - _.sum(key2Values));
    } else {
      throw 'aggregationTypeNetExpression is required for net aggregation of ' + field;
    }
    return net;
  }

  private getCellValuesByKey(api: UiGridApi, field: string, keyField: string, keyFieldValue: string, onlyFiltered?: boolean, index?: number): number[] {
    let eligibleRowData: any[] = [];
    if(onlyFiltered) {
      api.forEachNodeAfterFilter((rowNode) => {
        if(isNaN(index) || index >= rowNode.rowIndex) { eligibleRowData.push(rowNode.data); }
      });
    } else {
      api.forEachNode((rowNode) => {
        if(isNaN(index) || index >= rowNode.rowIndex) { eligibleRowData.push(rowNode.data); }
      });
    }


    let cellValueArray = eligibleRowData
      .filter((data) => data && data[keyField] === keyFieldValue)
      .map((data) => data[field]);

    return cellValueArray;
  }

  private onCellValueChanged($event: any): void {
    this.setBottomRowData($event.api, null);
  }

  private calcSubTotalLabel(api: GridApi, field: string, index: number): any {
    let label: string = '';
    api.forEachNode((rowNode: RowNode) => {
      if (rowNode.data) {
        if (rowNode.rowIndex === 0) {
          label = rowNode.data[ field ];
        }
        if (rowNode.rowIndex === index) {
          label = label + ' - ' + rowNode.data[ field ];
        }
      }
    });
    return label;
  }

}
