
import {of as observableOf, Observable} from 'rxjs';
/**
 * Created by jrayavarap on 5/2/2018.
 */
import {Injectable} from '@angular/core';
import {ApiHelper} from 'src/cad/common/services/api/api-helper';
import { ContractTransactionLookupData } from 'src/cad/flowing-gas/imbalance/ship-imbal/daily-k-bal/interfaces/contract-transaction-lookup-data';
import { PtpAllocationSearchGroupWSData } from 'src/cad/flowing-gas/imbalance/ship-imbal/daily-k-bal/interfaces/ptp-allocation-search-group-ws-data';
import { omit, get, isNil } from 'lodash';

@Injectable()
export class DailyKBalApi {

  private readonly endpoint: string = 'flowing-gas/imbal/ship-imbal/daily-k-bal';

  constructor(
    private apiHelper: ApiHelper) { }

  public getHeader = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/header', { body: params});
  }

  public getHeaderAndList = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/header-and-list/', { body: params });
  }
  
  public filter(params: PtpAllocationSearchGroupWSData): Observable<ContractTransactionLookupData[]> {
    let returnVal: Observable<ContractTransactionLookupData[]>;
    if (params) {
      let paramsObj: any = omit(params, 'populator');
      let lookupActionName: string = get(params, 'populator');
      
      switch (lookupActionName) {
        case 'contractTransactionLookupData': {
          returnVal = this.retrieveContractTransactionLookupData(paramsObj);
          break;
        }
        default: {
          break;
        }
      }
    }
    return !isNil(returnVal) ? returnVal : observableOf([]);
  }
  
  public retrieveContractTransactionLookupData(filter: PtpAllocationSearchGroupWSData): Observable<ContractTransactionLookupData[]> {
    return this.apiHelper.request(`${ this.endpoint }/contract-transaction-lookup`, { body: filter });
  }
}
