
import {filter, debounceTime,  take, map } from 'rxjs/operators';
import {
  Component,
  Injector,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
  OnInit,
  AfterViewInit,
  OnChanges,
  OnDestroy,
  Type,
  ViewChild,
  ViewEncapsulation,
  NgZone,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { ESCAPE, TAB } from '@angular/cdk/keycodes';
import { Observable ,  Subject ,  of ,  Subscription } from 'rxjs';
import { AutoUnsubscribables, AutoUnsubscriber } from 'cad/shared/mixins/auto-unsubscriber.mixin';
import { ModalController } from 'src/features/common/modal/modal.controller';
import { UIControlValueAccessor } from '../control-value-accessor';
import { LookupApi, ModalOpenOptions } from 'src/features/common/modal/modal-feature-types';
import { ModalModel } from 'src/features/common/modal/modal.model';
import { Validatable } from './../mat-form-field/validatable';
import { AutocompleteLookupModel } from './autocomplete-lookup.model';
import { UiVirtualRepeatComponent } from 'src/common/components/virtual-repeat/virtual-repeat.component';
import { VirtualKeyManager } from 'src/cad/shared/forms/select/features/virtual-key-manager/virtual-key-manager';
import * as _ from 'lodash';
import { MatOptionHelpers } from 'common/utils/mat-option-helpers';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  AUTOCOMPLETE_OPTION_HEIGHT,
  AUTOCOMPLETE_PANEL_HEIGHT,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ui-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: [ './lookup.less' ],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UiLookupComponent), multi: true },
    { provide: Validatable, useExisting: UiLookupComponent },
  ],
})
export class UiLookupComponent extends UIControlValueAccessor implements OnInit, AfterViewInit, OnChanges, OnDestroy, Validatable {

  @ViewChild('inputWrapper', { read: ElementRef }) public input: ElementRef;
  @ViewChild('errorNode') public errorNode: ElementRef;
  @ViewChild('ngModel') public inputModelRef: NgModel;
  @ViewChild(MatAutocomplete) public matAutocompleteRef: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) public matAutocompleteTriggerRef: MatAutocompleteTrigger;
  @ViewChild(UiVirtualRepeatComponent) uiVirtualRepeatRef: UiVirtualRepeatComponent;
  @ViewChild('virtualPanel', { read: ElementRef }) panel: ElementRef;
  /**
   * Object to bind (one or two way) to lookup selection if only a single value
   * is needed from the lookup, this can be omitted altogether.
   */
  @Input() public lookupItem: any;
  @Output() public lookupItemChange: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Lookup Type - useful for when multiple lookups are using the
   * same modal component but need different logic depending on
   * what data is being updated.
   */
  @Input() public lookupType: string;
  /**
   * Modal to show
   */
  @Input() public modalComponent: Type<any>;
  /**
   * The name of the form control
   */
  @Input() public name: string;
  /**
   * Disable input field but not lookup button
   */
  @Input()
  get inputLocked(): boolean | undefined { return this._inputLocked; }
  set inputLocked(value: boolean | undefined) {
    this._inputLocked = value ? true : undefined;
  }
  /**
   * Optionally set lookupItem = {} when text field is cleared
   */
  @Input() public clearItemOnDelete: boolean = false;
  /**
   * Can be css values ex: 300px, 80%, etc...
   */
  @Input() public modalWidth: string = '75%';
  /**
   * Can be css values ex: 300px, 80%, etc...
   */
  @Input() public modalHeight: string = 'auto';
  /**
   * Disabled button and input
   */
  @Input() public disabled: boolean;
  /**
   *
   */
  @Input() public required: boolean;
  /**
   * Input placeholder
   */
  @Input() public placeholder: string;
  /**
   * Modal Controller for using ModalFeature
   */
  @Input() public modalController: ModalController;
  /**
   * default search params
   */
  @Input() public defaultSearchParams: { [key: string]: number | string };
  /**
   * constant search params
   */
  @Input() public constSearchParams: { [key: string]: number | string };
  /***
   * item data to get id to make api call
   */
  @Input() public itemData: any;
  /**
   *  params to know if lookup used in table
   */
  @Input() public tableLookup: boolean;
  @Input() public updateModelOn: 'change' | 'blur' = 'blur';
  @Input() public hintText: string;
  /**
   * @description Flag to enable/disable autocomplete feature.
   */
  @Input() public useAutocomplete: boolean;
  /**
   * @description Autocomplete feature model configurations {@see AutocompleteLookupModel}.
   */
  @Input() public autocompleteModel: AutocompleteLookupModel;
  /**
   * @description Option item list for the AutocompleteLookup to be queried on search text change.
   */
  @Input() public autocompleteOptionItems: any[];
  /**
   * @description set to false if you do not want to use the search look up.  This will remove the button.
   */
  @Input() public useModal: boolean = true;
  @Output() public blur: EventEmitter<Event> = new EventEmitter<Event>();
  @HostListener('focus')
  public onFocus(): void {
    if (this.input && this.input.nativeElement) {
      this.input.nativeElement.focus();
    }
  }
  public ngModelRef: NgModel;
  public get autocompleteOptions(): any[] {
    return this._autocompleteOptions;
  }
  public set autocompleteOptions(value: any[]) {
    if (_.isArray(this._autocompleteOptions)) {
      this._autocompleteOptions.splice(0);
    }
    this._autocompleteOptions = value;
    if (this.useAutocomplete && this.inputModelRef) {
      this._filterTextSubject.next(this.inputModelRef.value);
    }
  }
  public get origAutocompleteOptions(): any[] {
    return this._origAutocompleteOptions;
  }
  public set origAutocompleteOptions(value: any[]) {
    if (_.isArray(this._origAutocompleteOptions)) {
      this._origAutocompleteOptions.splice(0);
    }
    this._origAutocompleteOptions = value;
  }
  public autocompleteApi: LookupApi;
  public filteredOptions$: Observable<any[]>;
  public virtualItems: any[] = [];
  public lookupModalOpen: boolean;
  public autocompleteOpen: boolean;
  public activeLookupChars: string;
  public origActiveLookupChars: string;
  public virtualPanelWidth: number;
  public isNil: (item: any) => boolean = _.isNil;
  public readonly AUTOCOMPLETE_OPTION_HEIGHT: number = AUTOCOMPLETE_OPTION_HEIGHT;
  public readonly AUTOCOMPLETE_PANEL_HEIGHT: number = AUTOCOMPLETE_PANEL_HEIGHT;
  public readonly ITEMS_TO_BUFFER: number = 2;
  private _inputLocked: boolean | undefined;
  private _autocompleteOptions: any[] = [];
  private _origAutocompleteOptions: any[] = [];
  private _autocompleteOptionsSub: Subscription;
  private _filterTextSubject: Subject<string> = new Subject<string>();
  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  constructor(
    private injector: Injector,
    private _dialog: MatDialog,
    private ngZone: NgZone,
    private renderer2: Renderer2
  ) {
    super();
  }

  public ngOnInit(): void {
    this.ngModelRef = this.injector.get(NgModel);
    if (this.useAutocomplete) {
      this.filteredOptions$ = this._filterTextSubject.asObservable()
      .pipe(
        map((searchText: string) => {
          let items: any[] = searchText
            ? this.filterOptions(searchText)
            : (_.isArray(this.autocompleteOptions) ? this.autocompleteOptions.slice() : []);
          if (this.matAutocompleteRef
            && this.autocompleteModel
            && this.matAutocompleteRef._keyManager) {
            (this.matAutocompleteRef._keyManager as VirtualKeyManager<MatOption>).setOriginalItems(
              items.map((item) => _.zipObject(
                [ 'value', 'viewValue' ],
                [ item[ this.autocompleteModel.valueProperty ], item[ this.autocompleteModel.valueProperty ] ]
              ))
            );
            this.runOnStableZone(() => {
              if (this.autocompleteOpen) {
                if (this.uiVirtualRepeatRef) {
                  this.uiVirtualRepeatRef.refresh(false);
                  this.uiVirtualRepeatRef.changeDetectorRef.detectChanges();
                }
                // Delay the activation of the first option till the virtual options are fully rendered.
                setTimeout(() => this.matAutocompleteRef._keyManager.setFirstItemActive());
              }
            });
          }
          return items;
        })
      );
      this.initAutocompleteModel();
    }
  }

  public ngAfterViewInit(): void {
    if (this.inputModelRef) {
      this.inputModelRef.control.asyncValidator = this.ngModelRef.control.asyncValidator;
    }
    if (this.useAutocomplete) {
      this.initAutocompleteComponent();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.autocompleteOptionItems
      && !_.isEqual(changes.autocompleteOptionItems.currentValue, changes.autocompleteOptionItems.previousValue)) {
      this.updateOptions(changes.autocompleteOptionItems.currentValue);
    }
  }

  public ngOnDestroy(): void {
    // TODO: Remove the following complete() line when we upgrade rxjs to 6+ and completing performance testing
    this._filterTextSubject.complete();
    if (this.modalComponent) {
      this.modalComponent = null;
    }
    if (this.modalController) {
      this.modalController = null;
    }
    if (this._autocompleteOptionsSub) {
      this._autocompleteOptionsSub.unsubscribe();
    }
    if (this.autocompleteApi) {
      this.autocompleteApi = null;
    }
    if (this.errorNode) {
      this.errorNode = null;
    }
    if (this.input) {
      this.input = null;
    }
    if (this.matAutocompleteRef && this.matAutocompleteRef._keyManager) {
      (this.matAutocompleteRef._keyManager as VirtualKeyManager<MatOption>).destroy();
    }
  }

  public openModal(event?: any): void {
    if (this.useAutocomplete && event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.lookupModalOpen = true;
    if (this.modalComponent) {
      let dialogRef = this._dialog.open(this.modalComponent, {
        width: this.modalWidth,
        height: this.modalHeight,
        data: {
          lookupItem: this.lookupItem ? _.cloneDeep(this.lookupItem) : this.model,
          lookupType: this.lookupType,
        },
      });
      this.subs.newSub = dialogRef.afterClosed().subscribe((data) => {
        // Delay setting the open flag due to onChange callback need to finish updating the model
        setTimeout(() => this.lookupModalOpen = false);
        if (!data) { return; }
        this.updateValue(data.lookupItem);
      });
    } else if (this.modalController) {
      let modalConfig: ModalOpenOptions = {
        instanceType: this.lookupType,
        defaultSearchParams: this.defaultSearchParams,
        constSearchParams: this.constSearchParams,
        itemData: this.itemData,
        tableLookup: this.tableLookup,
      };
      this.subs.newSub = this.modalController.open(modalConfig).subscribe((data) => {
        // Delay setting the open flag due to onChange callback need to finish updating the model
        setTimeout(() => this.lookupModalOpen = false);
        if (!data) { return; }
        this.updateValue(data);
      });
    } else {
      this.lookupModalOpen = false;
      console.error(`No modal component was provided for ${this.placeholder} lookup.
        Make sure you are passing a component into the [modalComponent] attribute of ui-lookup or passing a modal feature controller into the [modalComponent] attribute.`);
    }
  }

  public updateValue(value: any): void {
    if (this.lookupItem) {
      this.updateLookupItem(value);
    } else {
      this.updateModel(value);
    }
  }

  public updateModel(value: any, emitOnChange: boolean = true): void {
    this.onTouchedCallback();
    if (emitOnChange) {
      this.onChangeCallback(value);
    }
    this.model = value;
  }

  public updateLookupItem(value: any, emitOnChange: boolean = true): void {
    this.onTouchedCallback();
    if (emitOnChange) {
      this.onChangeCallback(value);
    }
    if (!this.tableLookup) {
      let item: any = _.cloneDeep(this.lookupItem);
      _.assign(item, value);
      this.lookupItem = item;
      this.lookupItemChange.emit(this.lookupItem);
    }
  }

  public onTextBlur(): void {
    this.onTouchedCallback();
  }

  /**
   * Autocomplete Methods
   */

  public initAutocompleteModel(): void {
    if (this.useAutocomplete) {
      // If no autocompleteModel provided or some properties are missing then attempt to create the autocompleteModel
      // from the modalController's model, if exists
      if ((_.isNil(this.autocompleteModel) || _.some(
          [ 'api', 'returnObjMap', 'transformObjFn' ],
          (prop: string) => _.isNil(this.autocompleteModel[ prop ])
        )) && this.modalController) {
        let modalControllerModel: ModalModel = this.modalController.model
          ? this.modalController.model
          : ((this.modalController as any).ctrl
            ? (this.modalController as any).ctrl.model
            : null);

        if (modalControllerModel) {
          if (_.isNil(this.autocompleteModel)) {
            this.autocompleteModel = {};
          }
          _.assign(this.autocompleteModel, {
            api: !_.isNil(this.autocompleteModel.api) ? this.autocompleteModel.api : modalControllerModel.api,
            returnObjMap: !_.isNil(this.autocompleteModel.returnObjMap)
              ? this.autocompleteModel.returnObjMap
              : modalControllerModel.returnObjMap,
            transformObjFn: !_.isNil(this.autocompleteModel.transformObjFn)
              ? this.autocompleteModel.transformObjFn
              : modalControllerModel.transformObjFn,
          });
        }
      }
      this.autocompleteModel = _.assign({
        valueProperty: 'value',
        minLookupChars: 2,
        addWildCard: true,
        filterType: 'startsWith',
        caseSensitive: false
      }, this.autocompleteModel);
      if (this.autocompleteModel.api) {
        this.autocompleteApi = this.injector.get(this.autocompleteModel.api);
      }
      if (this.autocompleteModel.minLookupChars > 0) {
        this.hintText = `Min ${this.autocompleteModel.minLookupChars} characters to show options${this.hintText ? (', ' + this.hintText) : ''}`;
      }
      this._autocompleteOptionsSub = Subscription.EMPTY;
    }
  }

  public initAutocompleteComponent(): void {
    if (this.useAutocomplete) {
      if (this.matAutocompleteTriggerRef) {
        let originalOpenPanelFn: () => void = (this.matAutocompleteTriggerRef as any)._attachOverlay;
        let originalClosePanelFn: () => void = this.matAutocompleteTriggerRef.closePanel;
        let originalHandleKeydownFn: (event: KeyboardEvent) => void = this.matAutocompleteTriggerRef._handleKeydown;

        (this.matAutocompleteTriggerRef as any)._attachOverlay = (): void => {
          originalOpenPanelFn.call(this.matAutocompleteTriggerRef);
          this.onAutocompleteOpen();
        };
        this.matAutocompleteTriggerRef.closePanel = (): void => {
          originalClosePanelFn.call(this.matAutocompleteTriggerRef);
          this.onAutocompleteClose();
        };
        this.matAutocompleteTriggerRef._handleKeydown = (event: KeyboardEvent): void => {
          let keyCode: number = event.keyCode;

          if (this.matAutocompleteTriggerRef.panelOpen) {
            switch (keyCode) {
              case ESCAPE: {
                this.resetAutocompleteModel();
                (this.matAutocompleteTriggerRef as any)._escapeEventStream.next();
                break;
              }
              case TAB: {
                // Select the autocomplete's active option
                if (this.matAutocompleteTriggerRef.activeOption) {
                  this.matAutocompleteTriggerRef.activeOption._selectViaInteraction();
                }
                event.preventDefault();
                break;
              }
            }
          }
          if (keyCode !== ESCAPE) {
            originalHandleKeydownFn.call(this.matAutocompleteTriggerRef, event);
          }
        };
        (this.matAutocompleteTriggerRef as any)._resetActiveItem = (): void => {
          // Do not reset activeItem when keyManager items (autocomplete.options) change
        };
        (this.matAutocompleteTriggerRef as any)._scrollToOption = (): void => {
          // Do nothing here, the scrolling is handled by scrollIndexIntoView function due to virtual options
        };
      }
      if (this.matAutocompleteRef) {
        this.matAutocompleteRef._keyManager = new VirtualKeyManager<MatOption>(
          this.matAutocompleteRef.options,
          [],
          this.ngZone,
          this.scrollIndexIntoView.bind(this)
        );
        if (this.matAutocompleteRef.options) {
          this.subs.newSub = this.matAutocompleteRef.options.changes.pipe(
          debounceTime(200))
          .subscribe(() => this.runOnStableZone(() => {
            this.virtualPanelWidth = (this.autocompleteModel
            && this.autocompleteModel.autoWidth
            && this.panel
            && this.panel.nativeElement)
              ? this.panel.nativeElement.scrollWidth
              : null;
            this.setSelectionByValue(this.model);
          }));
        }
      }
      if (this.inputModelRef && this.inputModelRef.valueChanges) {
        this.subs.newSub = this.inputModelRef.valueChanges.pipe(
        filter((value: any) => this.autocompleteOpen),
        debounceTime(200),)
        .subscribe((value: any) => this.updateAutocomplete(value));
      }
    }
  }

  public onAutocompleteOpen(): void {
    if (this.useAutocomplete && !this.autocompleteOpen) {
      this.autocompleteOpen = true;
      this.updateAutocomplete(this.model);
      setTimeout(() => {
        if (this.matAutocompleteRef
          && this.matAutocompleteRef.panel
          && this.matAutocompleteRef.panel.nativeElement
          && this.matAutocompleteRef.panel.nativeElement.parentElement) {
          if (this.autocompleteModel && this.autocompleteModel.autoWidth) {
            this.renderer2.addClass(this.matAutocompleteRef.panel.nativeElement.parentElement, 'ui-autocomplete-lookup-auto-width');
          } else {
            this.renderer2.removeClass(this.matAutocompleteRef.panel.nativeElement.parentElement, 'ui-autocomplete-lookup-auto-width');
          }
        }
      });
    }
  }

  public onAutocompleteClose(): void {
    if (this.useAutocomplete && this.autocompleteOpen) {
      this.autocompleteOpen = false;
      // Cancel pending Api call
      this._autocompleteOptionsSub.unsubscribe();
      if (this.inputModelRef && !_.isEqual(this.inputModelRef.value, this.model)) {
        // The autocomplete input value is NOT equal to the model value (the autocomplete input value
        // was not changed by user click/selection)
        if (_.isNil(this.inputModelRef.value) || this.inputModelRef.value === '') {
          // If the autocomplete input value is empty then update the model value
          this.updateAutocompleteValue(this.inputModelRef.value);
        } else {
          // Otherwise reset the autocomplete input value to the model value
          this.resetAutocompleteModel();
        }
      }
    }
  }

  public onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    if (event && event.option) {
      this.updateAutocompleteValue(event.option.value);
    }
  }

  public isOptionDisabled(option: any): boolean {
    if (this.autocompleteModel && this.autocompleteModel.disabledOptions) {
      return (_.findIndex(this.autocompleteModel.disabledOptions, (optionItem: any) => {
        return _.isString(optionItem) ? optionItem === option[ this.autocompleteModel.valueProperty ]
          : optionItem[ this.autocompleteModel.valueProperty ] === option[ this.autocompleteModel.valueProperty ];
      }) > -1);
    } else {
      return false;
    }
  }

  public updateAutocompleteValue(value: any): void {
    if (this.useAutocomplete && this.autocompleteModel) {
      if (this.lookupItem) {
        let optionValue: any = this.autocompleteModel.returnObjMap
          ? this.transformData(this.getOptionItem(value) || {})
          : { [ this.autocompleteModel.valueProperty ]: value };
        this.updateValue(optionValue);
      }
      this.updateModel(value, false);
      if (this.autocompleteModel.minLookupChars > 0 && (_.isNil(value) || value === '')) {
        // If the minLookupChars > 0 and the value is empty then empty the (autocompleteOptions and origAutocompleteOptions) and
        // (activeLookupChars and origActiveLookupChars) pairs
        this.autocompleteOptions = [];
        this.activeLookupChars = null;
      }
      if (!_.isEqual(this.autocompleteOptions, this.origAutocompleteOptions)) {
        // Store the origAutocompleteOptions
        this.origAutocompleteOptions = _.cloneDeep(this.autocompleteOptions);
      }
      if (!_.isEqual(this.activeLookupChars, this.origActiveLookupChars)) {
        // Store the origActiveLookupChars
        this.origActiveLookupChars = _.cloneDeep(this.activeLookupChars);
      }
    }
  }

  public updateAutocomplete(value: any): void {
    if (this.useAutocomplete && this.autocompleteModel) {
      let optionsObs$: Observable<any[]>;
      let areMinCharsEqual: boolean = _.isString(value)
        && _.isString(this.activeLookupChars)
        && _.isEqual(
          this.getCaseAppliedValue(value).substr(0, this.autocompleteModel.minLookupChars),
          this.getCaseAppliedValue(this.activeLookupChars)
        );

      if (_.size(value) >= this.autocompleteModel.minLookupChars && !areMinCharsEqual) {
        let stringVal: string = !_.isNil(value) ? value : '';

        // If length of the value is greater than or equal to minLookupChars and (autocompleteOptions IS empty
        // and minLookupChars sub string of value is NOT equal to activeLookupChars) then populate options
        // from the Api or from the optionItems.
        this._autocompleteOptionsSub.unsubscribe();
        if (this.autocompleteOptionItems) {
          // If autocompleteOptionItems is provided then do not populate options from the Api
          optionsObs$ = of(this.autocompleteOptionItems);
        } else if (this.autocompleteApi) {
          optionsObs$ = this.autocompleteApi.filter(_.assign({
            [ this.autocompleteModel.valueProperty ]: this.autocompleteModel.addWildCard
              ? stringVal.substr(0, this.autocompleteModel.minLookupChars) + '%'
              : stringVal.substr(0, this.autocompleteModel.minLookupChars)
          }, this.constSearchParams));
        }
        if (optionsObs$) {
          this._autocompleteOptionsSub = optionsObs$.subscribe((items: any) => {
            this.activeLookupChars = stringVal.substr(0, this.autocompleteModel.minLookupChars);
            this.updateOptions(items);
          });
        }
      } else {
        // Otherwise do not populate options from the Api just filter existing options
        this._filterTextSubject.next(value);
      }
    }
  }

  /**
   * @description Resets the autocomplete input value to the model value
   */
  public resetAutocompleteModel(): void {
    if (this.inputModelRef) {
      this.inputModelRef.control.setValue(this.model);
    }
    if (!_.isEqual(this.autocompleteOptions, this.origAutocompleteOptions)) {
      // Reset the autocompleteOptions to the original value
      this.autocompleteOptions = _.cloneDeep(this.origAutocompleteOptions);
    }
    if (!_.isEqual(this.activeLookupChars, this.origActiveLookupChars)) {
      // Reset the activeLookupChars to the original value
      this.activeLookupChars = _.cloneDeep(this.origActiveLookupChars);
    }
  }

  public filterOptions(searchText: string): any[] {
    return this.autocompleteModel && _.isArray(this.autocompleteOptions)
      ? this.autocompleteOptions.filter((option: any) => {
        let filterMethod: string = this.autocompleteModel.filterType === 'contains'
          ? 'includes'
          : this.autocompleteModel.filterType;

        return option
          && !_.isNil(searchText)
          && !_.isNil(option[ this.autocompleteModel.valueProperty ])
          && _[ filterMethod ](
            this.getCaseAppliedValue(option[ this.autocompleteModel.valueProperty ]),
            (this.autocompleteModel.addWildCard && searchText.endsWith('%'))
              ? this.getCaseAppliedValue(searchText.slice(0, -1))
              : this.getCaseAppliedValue(searchText)
          );
      })
      : [];
  }

  /**
   * @description Generates parsed autocompleteOptions array from the provided optionItems
   * @public
   * @param optionItems
   */
  public updateOptions(optionItems: any[]): void {
    let optionItemsClone: any[];

    if (_.isArray(optionItems)) {
      optionItemsClone = _.cloneDeep(optionItems);
      this.removeInvalidOptions(optionItemsClone);
      this.convertOptionsToObjects(optionItemsClone);
      if (this.shouldInsertEmptyOption(optionItemsClone)) {
        optionItemsClone.unshift({
          [ this.autocompleteModel.idProperty ]: null,
          [ this.autocompleteModel.valueProperty ]: null
        });
      }
    }
    this.autocompleteOptions = !_.isNil(optionItemsClone) ? optionItemsClone : [];
  }

  /**
   * @description Retrieves the option item object based on the provided optionValue
   * @public
   * @param optionValue
   */
  public getOptionItem(optionValue: any): any {
    return this.autocompleteModel && _.isArray(this.autocompleteOptions)
      ? (_.find(this.autocompleteOptions, { [ this.autocompleteModel.valueProperty ]: (_.isObject(optionValue)
        ? optionValue[ this.autocompleteModel.valueProperty ]
        : optionValue) }) || null)
      : null;
  }

  public setSelectionByValue(value: any): void {
    let correspondingOption: MatOption = this.getMatOptionByValue(value);

    if (correspondingOption) {
      if (!correspondingOption.selected) {
        (correspondingOption as any)._selected = true;
      }
    }
  }

  public getMatOptionByValue(value: any): MatOption {
    if (this.matAutocompleteRef && this.matAutocompleteRef.options) {
      return this.matAutocompleteRef.options.find((option: MatOption) => option && _.isEqual(option.value, value));
    }
    return null;
  }

  /**
   * @description Scrolls the option matched with the provided 'index' into view.
   * @param index
   * @returns Observable<number>
   */
  public scrollIndexIntoView(index: number): Observable<number> {
    if (index > -1
      && this.uiVirtualRepeatRef
      && this.matAutocompleteRef
      && this.panel) {
      const labelCount: number = MatOptionHelpers.countGroupLabelsBeforeOption(index, this.matAutocompleteRef.options, this.matAutocompleteRef.optionGroups);
      const scrollOffset: number = (index + labelCount) * AUTOCOMPLETE_OPTION_HEIGHT;
      const panelTop: number = this.panel.nativeElement.scrollTop;

      if (scrollOffset < panelTop) {
        return this.uiVirtualRepeatRef.scrollTo(scrollOffset);
      } else if (scrollOffset + AUTOCOMPLETE_OPTION_HEIGHT > panelTop + AUTOCOMPLETE_PANEL_HEIGHT) {
        return this.uiVirtualRepeatRef.scrollTo(Math.max(0, scrollOffset - AUTOCOMPLETE_PANEL_HEIGHT + AUTOCOMPLETE_OPTION_HEIGHT));
      }
    }
    return of(null);
  }

  private transformData(data: any): any {
    let returnObj: any;
    let transformObj: any;

    if (this.autocompleteModel) {
      returnObj = {};
      transformObj = {};
      if (_.isFunction(this.autocompleteModel.returnObjMap)) {
        transformObj = this.autocompleteModel.returnObjMap(data, this.lookupType);
      } else {
        transformObj = this.autocompleteModel.returnObjMap;
      }
      Object.keys(transformObj).forEach((key: string, index: number) => returnObj[ key ] = data[ transformObj[ key ] ]);
      if (this.autocompleteModel.transformObjFn && _.isFunction(this.autocompleteModel.transformObjFn)) {
        returnObj = this.autocompleteModel.transformObjFn(returnObj, this.lookupType);
      }
    }
    return !_.isNil(returnObj) ? returnObj : data;
  }

  /**
   * @description Converts the primitive type options array items into
   * { [valueProperty]: optionItemValue, [idProperty]: optionItemValue } Objects
   * @private
   * @param options
   */
  private convertOptionsToObjects(options: any[]): void {
    if (this.autocompleteModel) {
      _.forEach(options, (optionItem: any, optionIndex: number) => {
        if (!_.isObject(optionItem)) {
          options[ optionIndex ] = _.zipObject(
            [ this.autocompleteModel.idProperty, this.autocompleteModel.valueProperty ],
            [ optionItem, optionItem ]
          );
        }
        if (_.isUndefined(options[ optionIndex ][ this.autocompleteModel.idProperty ])) {
          options[ optionIndex ][ this.autocompleteModel.idProperty ]
            = options[ optionIndex ][ this.autocompleteModel.valueProperty ];
        } else if (_.isUndefined(options[ optionIndex ][ this.autocompleteModel.valueProperty ])) {
          options[ optionIndex ][ this.autocompleteModel.valueProperty ]
            = options[ optionIndex ][ this.autocompleteModel.idProperty ];
        }
      });
    }
  }

  /**
   * @description Removes invalid option items from the options
   * @private
   * @param options
   */
  private removeInvalidOptions(options: any[]): void {
    _.remove(options, (optionItem: any) => {
      return _.isEmpty(optionItem);
    });
  }

  private getCaseAppliedValue(value: any): string {
    return (this.autocompleteModel
    && !this.autocompleteModel.caseSensitive
    && _.isString(value))
      ? value.toLowerCase()
      : value;
  }

  private shouldInsertEmptyOption(optionList: any[]): boolean {
    return (this.autocompleteModel
    && this.autocompleteModel.addEmptyRow
    && optionList
    && (optionList.length === 0 || (optionList.length > 0
    && !_.isNil(optionList[ 0 ][ this.autocompleteModel.valueProperty ]))));
  }

  /**
   * @description Ensures that the fn is invoked when angular zone stabilizes
   * @public
   * @param fn
   */
  public runOnStableZone(fn: Function): void {
    this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
      fn();
    });
  }
}
