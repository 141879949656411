import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, ElementRef, HostBinding, Input, NgZone, OnChanges, OnDestroy, OnInit, Optional, Renderer2, Self, SimpleChange, SimpleChanges, ɵisObservable as isObservable, ɵisPromise as isPromise } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';
import * as _ from 'lodash';
import { from, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CodesModel } from 'src/cad/common/models/codes/codes-model';
import { UIControlValueAccessor } from 'src/common/components/form/control-value-accessor';

@Component({ template: '' })
export abstract class UiFormBaseSelectComponent extends UIControlValueAccessor implements OnInit, OnChanges, OnDestroy {

  /** code table code type to get values and codes from */
  @Input() codeType: string;
  /** code table code type ASSET to get values and codes from - optional - by default this is your current asset */
  @Input() codeTypeAsset: number;
  /** If not pulling from the codes table, use this source data bound object */
  @Input('sourceFn') sourceData: any;
  /** If sourceData/sourceFn is a function then this Params object is passed to the sourceData/sourceFn function */
  @Input() sourceFnParams: any;
  /** A function to compare the option values with the selected values.
   * The first argument is a value from an option. The second is a value from the selection.
   * A boolean should be returned */
  @Input() compareWith: (o1: any, o2: any) => boolean;
  /**
   * A custom sort function for sorting options.
   * @default Sorts based on lower case [ viewValueProperty ] property.
   */
  @Input() sortOptionsFn: (options: any[]) => any[] = this.sortOptions.bind(this);
  @Input() sort: boolean = true;
  /** [Value/Id/Code/Index] representation property of each object */
  @Input() valueProperty: string = 'cd';
  /** [View Value/Description/Display Text] representation property of each object */
  @Input() viewValueProperty: string = 'value';
  /** Form control name */
  @Input() name: string;
  /** Label placeholder when no value is selected */
  @Input() placeholder: string;
  /** Whether to float the placeholder text */
  @Input() floatLabel: FloatLabelType;
  /** Turns on/off the form control's 'required' property */
  @Input() required: any;
  /** Turns on/off the form control's 'disabled' property */
  @Input() disabled: any;
  /** Turns on/off the form control's 'readonly' property */
  @Input()
  get readonly(): any { return this._readonly; }
  set readonly(value: any) { this._readonly = coerceBooleanProperty(value); }
  /** Turns on/off the multiple selection of the mat-select */
  @Input() multiple: boolean;
  /** Array of options that should be disabled */
  @Input() disabledOptions: any[];
  /** add an empty option to the list */
  @Input()
  get addEmptyRow(): any { return this._addEmptyRow; }
  set addEmptyRow(value: any) {
    if (this._addEmptyRow !== coerceBooleanProperty(value)) {
      this._addEmptyRow = coerceBooleanProperty(value);
    }
  }
  @Input() hintText: string;
  @Input() hintAlign: string = 'start';
  @HostBinding('attr.tabindex') public tabIndex: number = 0;
  public optionList: any[] = [];
  protected subscriptions: Subscription[] = [];
  private sourceDataSubscription: Subscription;
  private _readonly: boolean;
  protected _addEmptyRow: boolean = true;

  protected static isObservable(obj: any): boolean {
    return isObservable(obj);
  }

  protected static isPromise(obj: any): boolean {
    return isPromise(obj);
  }

  constructor(
    public elementRef: ElementRef,
    public changeDetectorRef: ChangeDetectorRef,
    public ngZone: NgZone,
    public renderer: Renderer2,
    @Self() @Optional() public ngControlRef: NgControl,
    private codesModel: CodesModel
  ) {
    super();
    if (this.ngControlRef) {
      this.ngControlRef.valueAccessor = this;
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        let changeObj: SimpleChange = changes[ propName ];
        switch (propName) {
          case 'sourceData': {
            _.isFunction(changeObj.currentValue) ? this.buildOptionsFromSourceData(changeObj.currentValue(this.sourceFnParams))
              : this.buildOptionsFromSourceData(changeObj.currentValue);
            break;
          }
          case 'codeTypeAsset': {
            this.buildOptionsFromCodeType(this.codeType);
            break;
          }
          case 'codeType': {
            this.buildOptionsFromCodeType(changeObj.currentValue);
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    if (this.sourceDataSubscription) {
      this.sourceDataSubscription.unsubscribe();
    }
  }

  /**
   * @description This function is called when the control status changes to or from "DISABLED".
   * Depending on the value, it will enable or disable the appropriate DOM element.
   *
   * @see ControlValueAccessor interface
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * @description Searches and returns the index of the passed itemValue based on the [ valueProperty ]
   * property of the optionList array items
   * @public
   * @param itemValue
   * @returns {number}
   */
  public findItemIndex(itemValue: any): number {
    return _.findIndex(this.optionList, (option: any): boolean => {
      return _.isEqual(option[ this.valueProperty ], itemValue);
    });
  }

  /**
   * @description Generates parsed optionList array from the passed optionItems
   * @public
   * @param optionItems
   */
  public updateOptions(optionItems: any[]): void {
    /*** Override this method for custom manipulations and/or additional logic ***/
    if (_.isArray(optionItems)) {
      this.changeDetectorRef.detach();
      this.optionList.splice(0);
      this.optionList = optionItems;
      this.removeInvalidOptions(this.optionList);
      this.convertOptionsToObjects(this.optionList);
      if(this.sort) {
        this.optionList = this.sortOptionsFn(this.optionList);
      }
      this.checkAddEmptyOption();
      this.changeDetectorRef.reattach();
      this.optionListChanged(this.optionList);
    }
  }

  /**
   * @description Called when optionList array is updated
   * @public
   * @param optionList
   */
  public optionListChanged(optionList: any[]): void {
    /*** Override this method for additional logic when options are updated ***/
    if (!this.multiple && this.required && this.optionList.length === 1) {
      this.model = this.optionList[ 0 ][ this.valueProperty ];
    }
  }

  /**
   * @description Sorts options based on lower case [ viewValueProperty ] property
   * @public
   * @param options
   * @returns {T[]}
   */
  public sortOptions(options: any[]): any[] {
    /*** Override this method for custom sort logic ***/
    return _.sortBy(options, (optionItem: any) => {
      return _.toLower(optionItem[ this.viewValueProperty ]);
    });
  }

  /**
   * @description Determines whether the passed option is disabled or not
   * @public
   * @param option
   * @returns {boolean}
   */
  public isOptionDisabled(option: any): boolean {
    if (this.disabledOptions) {
      return (_.findIndex(this.disabledOptions, (optionItem) => {
        return (optionItem === option[ this.valueProperty ] || _.isEqual(optionItem, option));
      }) > -1);
    } else {
      return false;
    }
  }

  /**
   * @description Ensures that the fn is invoked when angular zone stabilizes
   * @public
   * @param fn
   */
  public runOnStableZone(fn: Function): void {
    this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
      fn();
    });
  }

  private checkAddEmptyOption(): void {
    if (this.multiple || (!this.multiple && this.required && this.optionList.length === 1)) {
      this.addEmptyRow = false;
    } else {
      if (this.checkInsertEmptyOption()) {
        let row = {};
        row[ this.valueProperty ] = null;
        row[ this.viewValueProperty ] = null;
        this.optionList.unshift(row);
      }
    }
  }

  private checkInsertEmptyOption(): boolean {
    if (this.addEmptyRow) {
      if (!(this.required && this.optionList.length === 1)) {
        return (this.optionList.length === 0 || (this.optionList.length > 0
        && (this.optionList[ 0 ][ this.valueProperty ] !== null
        && this.optionList[ 0 ][ this.viewValueProperty ] !== null)));
      }
    }
    return false;
  }

  /**
   * @description Pulls the options from the source data object passed in
   * @private
   * @param data
   */
  private buildOptionsFromSourceData(data: any): void {
    if (this.sourceDataSubscription) {
      this.sourceDataSubscription.unsubscribe();
    }
    if (UiFormBaseSelectComponent.isObservable(data)) {
      this.sourceDataSubscription = data.subscribe((items: any) => {
        this.updateOptions(items);
      });
    } else if (UiFormBaseSelectComponent.isPromise(data)) {
      this.sourceDataSubscription = from(data).subscribe((items: any) => {
        this.updateOptions(items);
      });
    } else if (_.isFunction(data)) {
      this.updateOptions(data());
    } else {
      this.updateOptions(data);
    }
  }

  /**
   * @description Builds the options from the codeType defined for the codes cache
   * @private
   * @param codeType
   */
  private buildOptionsFromCodeType(codeType: any): void {
    if (!codeType) {
      return;
    }
    if (this.sourceDataSubscription) {
      this.sourceDataSubscription.unsubscribe();
    }
    if (this.codeTypeAsset) {
      this.sourceDataSubscription = this.codesModel.getCodesForTypeByAsset(codeType, this.codeTypeAsset).subscribe((codes) => {
        this.updateOptions(codes);
      });
    } else {
      this.sourceDataSubscription = this.codesModel.getCodesForType(codeType).subscribe((codes) => {
        this.updateOptions(codes);
      });
    }
  }

  /**
   * @description Converts the primitive type optionList array items into
   * { [valueProperty]: optionItemValue, [viewValueProperty]: optionItemValue } Objects
   * @private
   * @param options
   */
  private convertOptionsToObjects(options: any[]): void {
    _.forEach(options, (optionItem: any, optionIndex: number) => {
      if (!_.isObject(optionItem)) {
        options[ optionIndex ] = _.zipObject([ this.viewValueProperty, this.valueProperty ], [ optionItem, optionItem ]);
      }
      if (_.isUndefined(options[ optionIndex ][ this.viewValueProperty ])) {
        options[ optionIndex ][ this.viewValueProperty ] = options[ optionIndex ][ this.valueProperty ];
      } else if (_.isUndefined(options[ optionIndex ][ this.valueProperty ])) {
        options[ optionIndex ][ this.valueProperty ] = options[ optionIndex ][ this.viewValueProperty ];
      }
    });
  }

  /**
   * @description Removes invalid option items from the options
   * @private
   * @param options
   */
  private removeInvalidOptions(options: any[]): void {
    _.remove(options, (optionItem: any) => {
      return _.isEmpty(optionItem);
    });
  }
}