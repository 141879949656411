
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { ActionService } from 'cad/common/services/security/action.service';
import { EmitterService } from 'cad/core/services/emitter/emitter.service';
import { forkJoin as observableForkJoin, Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutoUnsubscribables, AutoUnsubscriber } from 'src/cad/shared/mixins/auto-unsubscriber.mixin';
import { UiForm } from './form';

@Component({
  selector: 'ui-form',
  templateUrl: './form.component.html',
  styleUrls: [ './form.less' ],
})
export class UiFormComponent implements OnInit, OnDestroy, AfterViewInit, UiForm {

  @Output() public onSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() public dirtyStatusChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() public type: string = 'horizontal';
  @Input() public skipDisable: boolean = false;
  @Input() public skipSaveMessage: boolean = false;
  @Input() public id: string;

  @ViewChild(NgForm, { static: true }) public form: NgForm;

  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  public classType: string;
  public submitted: boolean;
  public disabled: boolean = true;
  private formDirty: boolean = false;

  constructor(
    private actionService: ActionService,
    private emitterService: EmitterService,
  ) { }

  public ngOnInit(): void {
    if (this.type !== 'basic') {
      this.setClassType(`form-${this.type}`);
    }
    this.subs.newSub =
      this.emitterService.uiFormSubmitterClicked.subscribe((formId: string) => {
        if (!formId || formId === this.id) {
          this.onSubmit(this.form);
        }
      });
    this.subs.newSub =
      this.emitterService.actionContextResolved.subscribe(() => {
        this.disableForm();
      });
  }

  public addControl(model: NgModel): void {
    this.form.addControl(model);
  }

  public removeControl(model: NgModel): void {
    if (this.form) {
      this.form.removeControl(model);
    }
  }

  public ngOnDestroy(): void {
    this.form = null;
    this.classType = null;
    this.onSave = null;
  }

  public ngAfterViewInit(): void {
    this.formDirty = this.form.dirty;
    this.subs.newSub =
      this.form.statusChanges.subscribe((newStatus) => {
        if (!(newStatus === 'DISABLED') && this.form && this.form.dirty !== this.formDirty) {
          this.formDirty = this.form.dirty;
          this.dirtyStatusChanged.emit({ dirtyStatus: this.formDirty });
        }
      });
  }

  public isDisabled(): boolean {
    if (this.skipDisable) { return false; }
    return !this.actionService.canPerformAction(cad.actionConstants.ACTION_SAVE);
  }

  public onSubmit(form: NgForm): void {
    if (this.onSave && form.valid) {
      this.onSave.emit({ form: this.form, skipSaveMessage: this.skipSaveMessage });
    } else {
      this.triggerValidation(form);
    }
  }

  public setClassType = (value: string): void => {
    this.classType = value;
  }

  public validate(): Observable<boolean> {
    return this.triggerValidation(this.form).pipe(map((data) => {
      return this.form.valid;
    }));
  }

  public markPristine(): void {
    _.each(this.form.controls, (control) => {
      control.markAsPristine();
      control.markAsUntouched();
    });
    this.formDirty = this.form.dirty;
    this.dirtyStatusChanged.emit({ dirtyStatus: this.formDirty });
  }

  private disableForm(): void {
    this.disabled = this.isDisabled();
  }

  private triggerValidation(form: NgForm): Observable<any> {
    let obs: any[] = [];
    for (const key in form.controls) {
      if (form.controls.hasOwnProperty(key)) {
        let ctrl = form.controls[ key ];
        ctrl.reset(ctrl.value);
        ctrl.markAsDirty();
        ctrl.markAsTouched();
        ctrl.updateValueAndValidity({ emitEvent: true });
        if (ctrl.asyncValidator) {
          obs.push(ctrl.asyncValidator(ctrl));
        }
      }
    }
    return obs.length ? observableForkJoin(obs) : observableOf([]);
  }
}
