import * as _ from 'lodash'; 
const defaultFields = [ 'modifiedFields','validatedStatus' ];

export const isObjectEqual = (original: any, modified: any): boolean => {
  return _.isEqual(original, modified);
};

export const partialObject = (extra: string[] = [], fields: string[] = defaultFields): any => {
  return (obj: any) => Object.keys(obj).filter((item) => fields.concat(extra).indexOf(item) === -1).reduce((a,c) => ({...a, [c]: obj[c]}),{});
};

export const cloneObject = (object: any): any => {
  return _.cloneDeep(object);
};

export const objectPropToArray = (item: any) : string[] => {
  return Object.keys(item);
};

export const requiredRows = (fields: string[]) => (data: any[]): boolean => {
  const result = data.filter((it) => requiredFields(fields)(it));
  return !!result.length;
};

export const requiredFields = (fields: string[]) => (data: any): boolean => {
  const result = fields.filter((it) => isNullOrEmpty(data[it]));
  return !!result.length;
};

export const requiredData = (fields: string[], data: any[]): boolean => {
  const result = data.filter((it) => requiredFields(fields)(it));
  return !result.length;
};

export const mergeInto = (source: any, target: any): any => {
  return Object.keys(source).reduce((ac, it) => {
    ac[it] = target[it] || source[it];
    return ac;
  },{});
};

export const isNullOrEmpty = (value: any, item: any[] = [ null,undefined,'' ]): boolean => {
  return item.indexOf(value) !== -1;
};

export const hasDuplicate = (fields: string[], items: any[]): any[] => {
  const duplicateMap: Map<string, any[]> = new Map();
  items.forEach((item) => {
    const id = fields.reduce((acc,curr) => acc.concat(item.data[ curr ]),''); 
    if (duplicateMap.has(id)) {
      duplicateMap.get(id).push(item);
    } else {
      duplicateMap.set(id,[ item ]);
    }
  });
  return Array.from(duplicateMap.values()).filter((item) => item.length > 1).reduce((acc,curr) => acc.concat(curr),[]);
};

export const parseNumber = (value: string): number => {
  const result = value ? parseInt(value,10) : null; 
  return isNaN(result) ? null : result;
};
