import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ConfigService } from 'cad/core/services/config/config.service';
import { RBSCodeData } from 'src/cad/shared/interfaces/code-value-data';
import { BaImbalPostAuthorizationData } from 'src/cad/flowing-gas/auth-posted-imbal/interfaces/ba-imbal-post-authorization-data';
import { PostedImbalSearchData } from 'src/cad/flowing-gas/trade-posted-imbal/interfaces/posted-imbal-search-data';
import ValidationResult = cad.ValidationResult;
import * as moment from 'moment-timezone';

@Injectable()
export class PostedImbalancesApi {

  public endpoint: string = 'flow/imbalance';

  constructor(
    private apiHelper: ApiHelper,
    private configService: ConfigService,
  ) {}

  // ========================================
  // implementation
  // ========================================

  public filter = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/filter', { body: params });
  }

  public imbTrades = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/imb-trades', { body: params });
  }

  public saveAuth = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/auth-save', { body: params });
  }
  
  public saveAuthList(saveData: BaImbalPostAuthorizationData[]): Observable<ValidationResult> {
    return this.apiHelper.request(this.endpoint + '/auth-save-list', { body: saveData });
  }

  public saveTrade = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/trade-save', { body: params });
  }

  public loadAuth = (params: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/auth-data', { body: params });
  }
  
  public getAuthDataList(filterData: PostedImbalSearchData): Observable<BaImbalPostAuthorizationData[]> {
    return this.apiHelper.request(this.endpoint + '/auth-data-list', { body: filterData });
  }

  public viewContacts = (contractId: number): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/view-contacts'+'/'+contractId);
  }

  public getIsAssetImbalanceCalcMethod(): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/isAssetImbalanceCalcMethodAllocVsSched', { method : 'GET' });
  }
  
  public getNewBaImbalPostAuthorizationData(filterData: PostedImbalSearchData): Observable<BaImbalPostAuthorizationData> {
    return this.apiHelper.request(this.endpoint + '/getNewBaImbalPostAuthorizationData', { body: filterData });
  }

  public viewContactinfo = (data: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + '/getServiceRequesterContact' , { body: data });
  }
  
  public getTradableCategoryCodes(): Observable<RBSCodeData[]> {
    return this.apiHelper.request(this.endpoint + '/getTradableCategoryCodes', { method : 'GET' });
  }
  
  public isAssetCrossTradeFeePayment(tradePeriod: string = null): Observable<boolean> {
    let tradePeriodDate: moment.Moment = moment(tradePeriod);
    
    return tradePeriodDate.isValid()
      ? this.apiHelper.request(`${this.endpoint}/isAssetCrossTradeFeePayment/${tradePeriodDate.format('MM-DD-YYYY')}`)
      : of(false);
  }
}
