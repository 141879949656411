import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'ui-split-pane',
  templateUrl: './split-pane.component.html',
  styleUrls: [ './split-pane.component.less' ],
})
export class UiSplitPaneComponent implements AfterViewInit, OnDestroy {
  @ViewChild('resizeHandle', { read: ElementRef }) private handle: ElementRef;

  private mouseDownListener: Function;
  private mouseUpListener: Function;

  private topPane: HTMLElement;
  private bottomPane: HTMLElement;
  private splitPane: HTMLElement;
  private resizeHandle: HTMLElement;

  constructor(public el: ElementRef, private renderer: Renderer2, public ngZone: NgZone) {
    this.splitPane = this.el.nativeElement;
  }

  ngAfterViewInit(): void {
    this.bottomPane = this.splitPane.querySelector('.bottom-pane') as HTMLElement;
    this.topPane = this.splitPane.querySelector('.top-pane') as HTMLElement;
    this.resizeHandle = this.splitPane.querySelector('.resize-handle') as HTMLElement;

    this.mouseDownListener = this.renderer.listen(this.handle.nativeElement, 'mousedown', (downEvt: MouseEvent) => {
      this.ngZone.runOutsideAngular(() => {
        let docHeight = document.querySelector('html').clientHeight;
        let splitPaneHeight = this.splitPane.clientHeight;
        let heightDiff = (docHeight - splitPaneHeight) / 2;
        let handleHeight = this.resizeHandle.clientHeight;
        this.splitPane.onmousemove = (moveEvt: MouseEvent) => {
          if (this.topPane) {
            this.topPane.style.overflow = 'hidden';
            this.topPane.style.userSelect = 'none';
            // this.topPane.style.msUserSelect = 'none';
          }
          if (this.bottomPane) {
            this.bottomPane.style.userSelect = 'none';
            let height = (docHeight - heightDiff);
            this.bottomPane.style.height = ((height - moveEvt.clientY) + handleHeight) + 'px';
          }
        };
      });
      this.mouseUpListener = this.renderer.listen(this.splitPane, 'mouseup', (evt: MouseEvent) => {
        this.splitPane.onmousemove = null;
        if (this.topPane) {
          this.topPane.style.overflow = 'auto';
          this.bottomPane.style.userSelect = 'auto';
          this.topPane.style.userSelect = 'auto';
          // this.topPane.style.msUserSelect = 'auto';
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.topPane = null;
    this.bottomPane = null;
    this.splitPane = null;

    if (typeof this.mouseDownListener === 'function') {
      this.mouseDownListener();
    }
    if (typeof this.mouseUpListener === 'function') {
      this.mouseUpListener();
    }
  }

  hideBottomPane(): void {
    this.bottomPane.style.height = '0';
  }

  showBottomPane(): void {
    this.bottomPane.style.height = '50%';
  }
}
