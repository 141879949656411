import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHelper } from 'cad/common/services/api/api-helper';

@Injectable()
export class BusinessAssociate {

  private readonly endpoint: string = 'associate/address/';
  private suffix: string = '/ba';

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public findAll = (addressId: string | number): Observable<any> => {
    return this.apiHelper.request(this.endpoint + addressId + this.suffix);
  }

  public save = (baAddresses: any[]): Observable<any> => {
    return this.apiHelper.request(this.endpoint + baAddresses[0].addressId + this.suffix, { body: baAddresses });
  }

  public update = (data: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + data.addressId + this.suffix + '/update', { body: data });
  }

}
