import { Injectable, Type, OnInit, OnDestroy } from '@angular/core';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

export interface BottomSheetConfig {
  sheet: Type<any>;
  height: string;
  data: any;
}

interface SheetPosition {
  top?: string;
  bottom?: string ;
  left?: string;
  right?: string;
}

const TOP: SheetPosition = { top: '0px' };
const BOTTOM: SheetPosition = { bottom: '0px' };
const LEFT: SheetPosition = { top: '0px', bottom: '0px', left: '0px' };
const RIGHT: SheetPosition = { top: '0px', bottom: '0px', right: '0px' };

@Injectable()
export class UiBottomSheetService implements OnInit, OnDestroy {
  public isShowing: boolean = false;

  minimized: boolean = false;
  private dialogRef: MatDialogRef<any>;
  private animationFinished: boolean;

  constructor(public mdDialog: MatDialog) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  public show(config: BottomSheetConfig): void {
    if (this.dialogRef) {
      this.hide();
    }
    this.dialogRef = this.mdDialog.open(config.sheet, {
      height: config.height,
      hasBackdrop: false,
      width: '100%',
      maxWidth: '100%',
      panelClass: 'ui-bottomsheet',
      position: {
        bottom: '0px',
      },
      data: config.data,
    });
    this.isShowing = true;
    this.minimized = false;
  }

  public hide(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null;
      this.isShowing = false;
    }
  }

  attachToTop(): void {
    this.dialogRef.updatePosition(TOP).updateSize('100%', '40%');
  }

  attachToBottom(): void {
    this.dialogRef.updatePosition(BOTTOM).updateSize('100%', '40%');
  }

  attachToRight(): void {
    this.dialogRef.updatePosition(RIGHT).updateSize('40%', '100%');
  }

  attachToLeft(): void {
    this.dialogRef.updatePosition(LEFT).updateSize('40%', '100%');
  }

  shrink(): void {
    this.animationFinished = false;
    this.minimized = true;
    this.dialogRef.updateSize('100%', '64px');
  }

  grow(): void {
    this.animationFinished = true;
    this.minimized = false;
    this.dialogRef.updateSize('100%');
  }

  toggleMinimized(): void {
    if (this.minimized) {
      this.grow();
    } else {
      this.shrink();
    }
  }
}

