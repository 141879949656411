import { ModalController } from 'src/features/common/modal/modal.controller';
import { ModalOpenOptions } from 'src/features/common/modal/modal-feature-types';
import { TableColumnCustomRendererComponent } from './custom/custom-renderer.component';
import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ContentChildren,
  EventEmitter,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  SkipSelf,
  Type,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import {
  Constants,
  ICellRendererFunc, ICellRendererParams, IFilterComp, IsColumnFunc, RowNode, ValueGetterParams, _ as Utils
} from '@ag-grid-community/core';
import { AgGridColumn } from '@ag-grid-community/angular';
import * as _ from 'lodash';

import { TableComponent } from '../table/table.component';
import { TableColumnCheckboxEditorComponent } from './checkbox/checkbox-editor.component';
import { TableColumnToggleRenderer } from './checkbox/checkbox-renderer.component';
import { TableColumnCurrencyRendererComponent } from './currency/currency-renderer.component';
import { TableColumnCustomEditorComponent } from './custom/custom-editor.component';
import { dateTimeValueFormatter, dateValueFormatter } from './date/date-formatter';
import { TableColumnDefaultEditorComponent } from './default/default-editor.component';
import { defaultCellRenderer } from './default/default-renderer.component';
import { emailFormatter } from './email-address/email-formatter';
import { TableColumnIconRendererComponent } from './icon/icon-renderer.component';
import { linkFormatter } from './link/link-formatter';
import { TableColumnClickActionRenderer } from './click-action/click-action-renderer.component';
import { TableColumnLookupEditorComponent } from './lookup/lookup-editor.component';
import { numberValueFormatter, numberStringValueFormatter } from './numeric/number-formatter';
import { currencyValueFormatter } from './currency/currency-formatter';
import { TableColumnNumericEditorComponent } from './numeric/numeric-editor.component';
import { TableColumnSelectDropdownEditorComponent } from './select-dropdown/select-dropdown-editor.component';
import { TableColumnSlideToggleEditorComponent } from './slide-toggle/slide-toggle-editor.component';
import { TableColumnSlideToggleRendererComponent } from './slide-toggle/slide-toggle-renderer.component';
import { TableHeaderFilterRendererComponent } from 'src/ag-grid-wrapper/header/filter/filter-header.component';
import { CurrencyPipe } from '@angular/common';
import { TableColumnLabelboxEditorComponent } from 'src/ag-grid-wrapper/column/labelbox/labelbox-editor.component';
import { RowActionMenuComponent } from 'src/ag-grid-wrapper/row/action-menu/action-menu.component';
import * as moment from 'moment-timezone';
import { CheckBoxBooleanFilter } from 'src/cad/shared/table-renderers/checkbox-boolean-filter/checkbox-boolean-filter';
import { ColumnFooterConfiguration } from 'src/ag-grid-wrapper/interfaces/footer-configuration';
import { NetExpression } from 'src/ag-grid-wrapper/interfaces/net-expression';

@Component({
  selector: 'ui-table-column',
  template: '',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TableColumnComponent extends AgGridColumn implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  /**
   * @href {string} Only use if type="link".  Used to create a link as the cell-renderer.  the href value
   *                will be the base of the url and the cell value will be added to the end.
   *
   * @customRenderer {Type<any>} Only use if type="custom".  Used to add a custom cell-renderer.
   *
   * @customEditor {Type<any>} Only use if type="custom".  Used to add a custom cell-editor.
   *
   * @aggregationType {string} Only use if type is number and the table is set to show the footer row.
   *                           The calculation type used for the footer value aggregation.
   *                           Valid calculations are 'sum', 'avg', 'max', 'min', 'net'.
   *
   * @aggregationTypeNetExpression {any}  Only use if aggregationType is 'net'.  The result would be the some of the
   *                                      of the keyCol where key1 matched the value minus the sum of the values where
   *                                      key2 matched.
   *                                      {
   *                                          keyCol: 'column1', the column to lookup the keys from
   *                                          key1: 'D', - would be the column value. all rows would be summed for 'D'
   *                                          key2: 'R' - would be the column value. all rows would be summed for 'D'
   *                                      }
   *
   *
   * @cellClass {string} Override the cell class used for the column
   *
   * @displayName {string} The display name of the column
   *
   * @editDropdownCode {string}
   *
   * @editDropdownOptionsArray {any[]}  Only use if type is 'select'.  An array of options to be displayed
   *                                         in the select dropdown.
   *
   * @editDropdownValueId {string} Only use if type is 'select'.  The value to set the row item to when an
   *                               item is selected from the dropdown
   *
   * @editDropdownValueLabel {string} Only use if type is 'select'  The description of the item displayed in the
   *                                  dropdown.
   *
   * @editable {boolean} Optional default is false. sets whether the column is enabled for editing.
   *
   * @floatingCellRenderer {any} Optional. An override for the ag grid floating cell renderer.
   *
   * @iconValueMap {any} An object map for a column of type icon.
   *
   *                      KEY = value of column
   *                      KEY: ['name of material icon', 'css class name to apply for icon']
   *
   *                      `let STATUS_MAP: any = {
   *                               KEY: [ {string}, {string}],
   *                             };`
   *
   * @lookupAction {string} Optional. valid options are 'assign', 'key'. value is 'key' by default.
   *                       assign will assign the entire object selected overwriting values for
   *                       corresponding object variables.
   *
   * @lookupCallback {Promise<any>} Optional call back function that is fired when editing a lookup is completed.
   *                            This can be used to trigger a web service call in order to lookup additional information
   *                           about the value hand entered into a cell or to validate that the hand entered data is
   *                           valid input.
   *
   * @lookupComponent {ComponentFactory<any>} Required if the row is of type lookup. The Component Factory that will be
   *                                          dynamically injected into the modal. displayed in the lookup modal.
   *
   * @lookupModalHeight {string} Optional The height of the lookup modal. value is '80%' by default.
   *
   * @lookupModalWidth {string}  Optional The width of the lookup modal. value is '80%' by default.
   *
   * @lookupModalTitle {string}  The Title to be displayed for the lookup modal.
   *
   * @lookupType {string} A unique string used to indicate what setting the lookup is being used it.  For example if you
   *                      are using the same lookup in 2 different tables and they both need to be handled just a little
   *                      bit different in each scenario, you can use this as a way to guide the logic in the modal itself.
   *
   * @minWidth {number} Optional. 50 by default.  The minimum with a column can be resized to.
   *
   * @name {any} the object field for the column.
   *
   * @toggleModelType {string} Optional Only needed if the column type is 'checkbox' or 'slide-toggle'.
   *                      Used to denote the type of model valid values are yesno, onezero.
   *                      yesno will make the toggle select use Y and N.
   *                      onezero will make the toggle select use 1 and 0.
   *                      default will make the toggle select use true and false.
   *
   * @type {string} Optional, default is text. valid options are 'text', 'number', 'date', 'select', 'lookup',
   *                'checkbox', 'slide-toggle', 'link', 'currency', 'numberStr', 'custom'.
   *
   *
   */

  @Input() public customRenderer: Type<any>;
  @Input() public customEditor: Type<any>;
  @Input() public customCellConfig: any | ((params: any) => any);

  @Input() public href: string;
  @Input() public clickAction: (rowData: any) => void;

  // Columns needed to custom calculations
  @Input() public aggregationColumns: string;

  @Input() public aggregationType: string;
  @Input() public aggregationTypeNetExpression: NetExpression;

  @Input() public cellClass: string | string[]| ((cellClassParams:any) => string | string[]) = '';
  @Input() public cellEditor: any;
  @Input() public cellFilter: any;

  @Input() public headerName: string;
  @Input() public data: any;
  @Input() public headerClass: string = '';
  @Input() public headerIcon: string;
  @Input() public headerClick: EventEmitter<any> = new EventEmitter();

  @Input() public menuTabs: string[] = [];

  @Input() public editDropdownCode: string;
  @Input() public editDropdownOptionsArray: any[];
  @Input() public editDropdownValueId: string;
  @Input() public editDropdownValueLabel: string;
  @Input() public editable: boolean | IsColumnFunc;
  @Input() public minlength: any;
  @Input() public maxlength: any;

  @Input() public centered: boolean = false;

  @Input() public suppressFilter: boolean;
  @Input() public filter: string | { new(): IFilterComp } | boolean;
  @Input() public filterParams: any;
  @Input() public filterType: string;

  @Input() public floatingCellRenderer: any;
  @Input() public floatingFilterComponentParams: any;

  @Input() public iconValueMap: any;
  @Input() public hide: boolean = false;
  @Input() public lookupAction: string = '';
  @Output() public lookupCallback: EventEmitter<any> = new EventEmitter();
  @Input() public lookupComponent: ComponentFactory<any>;
  @Input() public lookupModalController: ModalController;
  @Input() public lookupModalOptions: ModalOpenOptions;
  @Input() public lookupModalHeight: string = '80%';
  @Input() public lookupModalWidth: string = '80%';
  @Input() public lookupModalTitle: string;
  @Input() public lookupType: string;
  @Input() public lookupForceDialog: boolean = false;
  @Input() public lookupParams: any;
  @Input() public lookupResultTransform: (result: any ) => any;

  @Input() public width: number;
  @Input() public minWidth: number = 50;
  @Input() public maxWidth: number;

  @Input('name') public field: any;
  @Input() public valueGetter: any;
  @Input() public newValueHandler: (params: any) => boolean;

  @Input() public toggleModelType: string;
  @Input() public sort: any;
  @Input() public sortedAt: number;
  @Input() public comparator: (valueA: any, valueB: any, nodeA?: RowNode, nodeB?: RowNode, isInverted?: boolean) => number;
  @Input() public subTotalLabel: string;
  @Input() public footerConfiguration: ColumnFooterConfiguration[];
  @Input() public totalLabel: string;
  @Input()
  public set sortObj(value: any) {
    if (value) {
      this.sort = value.direction;
      this.sortedAt = value.priority;
    }
  }
  @Input()
  public set suppressSorting(value: boolean) {
    if (value && this.sortable) {
      this.sortable = !this.sortable;
    }
  }
  @Input() public footerRowLabelColumn: boolean = false;
  @Input() public type: string;
  @Input() public required: boolean = false;
  @Input() public suppressSizeToFit: boolean;
  @Input() public suppressMovable: boolean = false;
  @Input() public highLightNegavtiveNumbers: boolean = false;
  @Input() public cellClassRules: any = {
    'cell-editable': (params: any) => {
      let editable = false;
      if(!params.node.isRowPinned()) {
        if (_.isFunction(params.colDef.editable)) {
          editable = params.colDef.editable(params);
        } else {
          editable = params.colDef.editable;
        }
      }
      return editable;
    },
    'error-cell': (params: any): any => {
      let retVal;
      if (params.node.errorCells && params.node.errorCells.length) {
        retVal = params.node.errorCells.indexOf(this.headerName) > -1 ? 'error-cell' : '';
      }
      return retVal;
    },
    centered: (params: any) => {
      return params.colDef.centered;
    },
    editable: (params: any): boolean => {
      if (params
        && params.api
        && params.api.columnController
        && params.colDef
        && params.node) {
        const gridCol: any = params.api.columnController.getGridColumn(params.colDef.colId);
        return gridCol && gridCol.isCellEditable(params.node);
      }
      return false;
    },
    'highlight-negative-numbers': (params: any, ): any => {
      if(params.colDef.highLightNegavtiveNumbers && params.colDef.type === 'number') {
        let retVal;
        if(params.value && params.value < 0 ) {
          retVal = true;
        }
        return retVal;
      }
    },
    'footer-header-cell': (params: any) => {
      if (params && params.node) {
        const footerOrderingField: string = '**footerOrderingField**';
        return (params.node.rowPinned === Constants.PINNED_BOTTOM
        && params.node.data
        && params.node.data[ footerOrderingField ] === -10000000);
      }
      return false;
    },
  };

  @Input() public pinnedRowCellRenderer: ICellRendererFunc = (params: any): any => {
    if ((params.colDef.subTotalLabel && params.colDef.subTotalLabel !== params.value && params.value !== 0) ||
        (params.colDef.aggregationType && params.node.rowPinned) ||
        (params.colDef.footerConfiguration && params.node.rowPinned) ||
        (params.colDef.footerRowLabelColumn && params.node.rowPinned && params.value)

        ) {
      const footerOrderingField = '**footerOrderingField**';
      const isHeader = (params.node && params.node.data && params.node.data[footerOrderingField] === -10000000);

      const { value, valueFormatted, colDef: {type} } = params;
      let result = !_.isNil(valueFormatted) ? valueFormatted : !_.isNil(value) ? value : '';
      const footerConfig = params && params.colDef && params.colDef.footerConfiguration ? params.colDef.footerConfiguration.find(( c ) => c.header && c.header[value] ) : null;
      if(isHeader && footerConfig) {
        result = footerConfig.header[value];
      } else {
        switch(type) {
          case 'currency': {
            result = this.getCurrencySum(!_.isNil(value) ? value : '');
            break;
          }
          case 'number': {
            result = _.isNumber(result) ? result.toFixed(2) : result;
          }
        }
      }

      return `
        <div class="${isHeader ? 'footer-header-cell-content' : 'footer-cell'}">
          <div class="${isHeader ? 'footer-header-cell-title' : 'footer-cell-title'}">
            ${ result }
          </div>
        </div>
      `;
    }
    return '';
  }
  @Input() public fireChangeOnSelect: boolean = false;
  @Input() public valueFormatter: (params: ICellRendererParams) => string;
  @Input() public allowSelectAll: boolean = false;
  @Input() public headerComponentFramework: any = TableHeaderFilterRendererComponent;
  @Input() public cellActiveTooltip: string = '';
  @Input() public cellInactiveTooltip: string = '';
  @Input() public headerTooltip: string = null;
  @Input() public headerActiveTooltip: string = '';
  @Input() public headerInactiveTooltip: string = '';
  @Input() public columnCheckBoxState: boolean = false;
  @Input() public useCustomHeaderFilter: boolean;
  @Input() public useCustomFilterParams: boolean = true;
  @Input() public useNumberAsCurrencyExport: boolean;
  @Output() public columnCheckBoxStateChange: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @ContentChildren(TableColumnComponent) public childColumns: QueryList<TableColumnComponent>;

  private isChild: boolean = false;

  private getCurrencySum(value: any): string {
    return _.isNumber(value) ? `<div style="text-align:right">${ this.currencyPipe.transform(value, 'USD') }<span></span></div>` : value;
  }

  constructor(public tableComponent: TableComponent,
              private currencyPipe: CurrencyPipe,
              @SkipSelf() @Host() @Optional() private parentCol: TableColumnComponent) {
    super();
    if (parentCol) {
      this.isChild = true;
    }
  }

  ngOnInit(): void {
    if (this.centered) {
      this.addCellClass('centered');
      this.headerClass += ' centered';
    }

    if (this.isChild) {
      this.headerClass += ' child-column-header';
    }

    if (this.tableComponent.disableEdit) {
      this.editable = false;
    }
    if (this.suppressFilter) {
      this.filter = false;
      this.filterFramework = null;
    } else if (this.headerName !== ''
      && !this.filter
      && this.tableComponent
      && this.tableComponent.enableFilter) {
      // If enableFilter global flag is set to true on the table component level then assign default text filter
      // to all user defined (columns except actionMenu, singleSelect, showExpand etc) columnDefs.
      this.filter = 'agTextColumnFilter';
    }
  }

  ngAfterViewInit(): void {
    this.configureColumn();
    if (this.childColumns.length) {
      this.headerClass += ' group-header-parent';
    }
    /**
     * because of the nested structure of parent/child columns,
     * we will let the parent column handle creating the column
     * defintion and adding it to the table
     */
    if (!this.isChild) {
      this.tableComponent.appendColumn(this.toColDef());
    }
  }

  ngOnDestroy(): void {
    if (this.tableComponent) {
      this.tableComponent.removeColumn(this.toColDef());
    }
    this.cellRenderer = null;
    this.cellRendererFramework = null;
    this.cellEditorFramework = null;
    this.valueFormatter = null;
    this.customEditor = null;
    this.customRenderer = null;
    this.customCellConfig = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.columnCheckBoxState && !changes.columnCheckBoxState.isFirstChange()) {
      this.columnCheckBoxStateChange.emit(changes.columnCheckBoxState.currentValue);
    }
  }

  public toggleVisibility(): void {
    this.hide = !this.hide;
    this.tableComponent.columnApi.setColumnVisible(this.field, !this.hide);
  }

  public setVisibility(visible: boolean): void {
    this.hide = !visible;
    this.tableComponent.columnApi.setColumnVisible(this.field, !this.hide);
  }

  public addCellClass(cellClass: string): void {
    _.assign(this.cellClassRules, { [cellClass]: () => true });
  }

  public removeCellClass(cellClass: string): void {
    delete this.cellClassRules[cellClass];
  }

  public configureColumn(): void {
    this.setColumnTypeEditor();
    this.setColumnCellFilter();
    if (this.filterType) {
      if (!this.headerComponentParams) {
        this.headerComponentParams = {};
      }
      this.headerComponentParams.defaultFilterType = this.filterType;
    }
    if (this.tableComponent && !_.isNil(this.tableComponent.showSortOrder)) {
      if (!this.headerComponentParams) {
        this.headerComponentParams = {};
      }
      this.headerComponentParams.showSortOrder = this.tableComponent.showSortOrder;
    }
    if (this.type === 'checkbox-boolean') {
      if (!this.tableComponent.frameworkComponents) {
        this.tableComponent.frameworkComponents = {};
      }
      this.tableComponent.frameworkComponents.checkboxBooleanFilter = CheckBoxBooleanFilter ;
    }
    if(this.allowSelectAll) {
      if (!this.headerComponentParams) {
        this.headerComponentParams = {};
      }
      this.headerComponentParams.allowSelectAll = true;
      if(!_.isNil(this.columnCheckBoxStateChange)) {
        this.headerComponentParams.columnCheckBoxStateChanged = this.columnCheckBoxStateChange;
      }
    }
  }

  private setColumnTypeEditor (): void {
    if (this.editable) {
      switch (this.type) {
        case 'checkbox-boolean':
        case 'checkbox': {
          this.cellEditorFramework = TableColumnCheckboxEditorComponent;
          this.centered = true;
          break;
        }
        case 'lookup': {
          this.cellEditorFramework = TableColumnLookupEditorComponent;
          break;
        }
        case 'currency':
        case 'number': {
          this.cellEditorFramework = this.customEditor ? TableColumnCustomEditorComponent : TableColumnNumericEditorComponent;
          this.addCellClass('ag-numeric-cell');
          this.headerClass += ' ag-numeric-cell';
          break;
        }
        case 'numberStr': {
          this.cellEditorFramework = this.customEditor ? TableColumnCustomEditorComponent : TableColumnDefaultEditorComponent;
          break;
        }
        case 'select': {
          this.cellEditorFramework = TableColumnSelectDropdownEditorComponent;
          this.addCellClass('select-dropdown');
          break;
        }
        case 'slide-toggle': {
          this.cellEditorFramework = TableColumnSlideToggleEditorComponent;
          break;
        }
        case 'date': {
          this.cellEditorFramework = TableColumnCustomEditorComponent;
          break;
        }
        case 'dateTime': {
          this.cellEditorFramework = TableColumnCustomEditorComponent;
          break;
        }
        case 'labelbox': {
          this.cellEditorFramework = TableColumnLabelboxEditorComponent;
          break;
        }
        case 'custom': {
          if (this.customEditor) {
            this.cellEditorFramework = TableColumnCustomEditorComponent;
          } else {
            this.cellEditorFramework = TableColumnDefaultEditorComponent;
          }
          break;
        }
        default: {
          this.cellEditorFramework = TableColumnDefaultEditorComponent;
        }
      }
    }
  }

  private setColumnCellFilter(): void {
    if (_.isNil(this.cellRenderer)) {
      this.cellRenderer = defaultCellRenderer;
    }
    switch (this.type) {
      case 'checkbox-boolean': {
        this.cellRenderer = TableColumnToggleRenderer;
        this.centered = true;
        this.filter = 'checkboxBooleanFilter';
        this.filterParams = {
          filterType: 'equals'
        };
        this.toggleModelType = 'truefalse';
        break;
      }
      case 'checkbox': {
        this.cellRenderer = TableColumnToggleRenderer;
        this.centered = true;
        this.filter = 'text';
        this.filterParams = {
          filterType: 'equals'
        };
        break;
      }
      case 'link': {
        this.valueFormatter = linkFormatter;
        this.filter = 'text';
        break;
      }
      case 'email': {
        this.valueFormatter = emailFormatter;
        this.filter = 'text';
        break;
      }
      case 'dateTime' : {
        this.valueFormatter = dateTimeValueFormatter;
        this.addCellClass('dateTimeType');
        this.filter = 'date';
        this.filterParams = {
          comparator: (filterDateMidnight, cellValue) => {
            let cellDate: moment.Moment = moment(cellValue, 'YYYY-MM-DD');
            let filterDate: moment.Moment = moment(filterDateMidnight, 'YYYY-MM-DD');
            // Now that both parameters are Date objects, we can compare
            if (cellDate.isBefore(filterDate)) {
              return -1;
            } else if (cellDate.isAfter(filterDate)) {
              return 1;
            } else {
              return 0;
            }
          }
        };
        if (_.isNil(this.comparator)) {
          this.comparator = this.dateSortComparator.bind(this);
        }
        break;
      }
      case 'date': {
        this.valueFormatter = dateValueFormatter;
        this.addCellClass('dateType');
        this.filter = 'date';
        this.filterParams = {
          comparator: (filterDateMidnight, cellValue) => {
            let cellDate: moment.Moment = moment(cellValue);
            let filterDate: moment.Moment = moment(filterDateMidnight);
            // Now that both parameters are Date objects, we can compare
            if (cellDate.isBefore(filterDate)) {
              return -1;
            } else if (cellDate.isAfter(filterDate)) {
              return 1;
            } else {
              return 0;
            }
          }
        };
        if (_.isNil(this.comparator)) {
          this.comparator = this.dateSortComparator.bind(this);
        }
        break;
      }
      case 'number': {
        this.addCellClass('ag-numeric-cell');
        this.headerClass += ' ag-numeric-cell';
        this.valueFormatter = numberValueFormatter;
        this.filter = 'number';
        if (_.isNil(this.filterValueGetter)) {
          this.filterValueGetter = this.numberFilterValueGetter.bind(this);
        }
        if (_.isNil(this.comparator)) {
          this.comparator = this.numberSortComparator.bind(this);
        }
        break;
      }
      case 'numberStr': {
        this.valueFormatter = numberStringValueFormatter;
        this.filter = 'text';
        if (_.isNil(this.filterValueGetter)) {
          this.filterValueGetter = this.numberFilterValueGetter.bind(this);
        }
        if (_.isNil(this.comparator)) {
          this.comparator = this.numberSortComparator.bind(this);
        }
        break;
      }
      case 'icon': {
        this.cellRendererFramework = TableColumnIconRendererComponent;
        delete this.cellRenderer;
        break;
      }
      case 'slide-toggle': {
        this.cellRendererFramework = TableColumnSlideToggleRendererComponent;
        delete this.cellRenderer;
        break;
      }
      case 'currency': {
        this.cellRendererFramework = TableColumnCurrencyRendererComponent;
        this.valueFormatter = currencyValueFormatter;
        this.filter = 'number';
        delete this.cellRenderer;
        break;
      }
      case 'labelbox': {
        this.cellRendererFramework = TableColumnLabelboxEditorComponent;
        delete this.cellRenderer;
        break;
      }
      case 'action-menu': {
        this.headerName = '';
        this.field = 'actionMenu';
        this.cellRendererFramework = RowActionMenuComponent;
        this.width = 30;
        this.maxWidth = 30;
        this.minWidth = 30;
        this.pinnedRowCellRenderer = () => '';
        this.addCellClass('ag-grid-action-menu-cell');
        delete this.cellRenderer;
        break;
      }
      case 'custom': {
        if (this.customRenderer) {
          delete this.cellRenderer;
          this.cellRendererFramework = TableColumnCustomRendererComponent;
          console.warn('CUSTOM RENDERER USED');
        }
        if (this.valueFormatter && !this.useCustomHeaderFilter) {
          this.filter = 'text';
          this.filterParams = {
            textCustomComparator: this.getFormattedCellComparator(),
          };
        }
        break;
      }
    }
    if (!_.isNil(this.clickAction)) {
      this.cellRendererFramework = TableColumnClickActionRenderer;
      delete this.cellRenderer;
    }
    if (this.tableComponent
      && this.tableComponent.groupSuppressAutoColumn
      && !_.isNil(this.showRowGroup)) {
      if (!_.isNil(this.cellRendererParams)) {
        this.cellRendererParams.innerRendererParams = _.omit(
          this.cellRendererParams,
          [ 'innerRenderer', 'innerRendererParams', 'innerRendererFramework' ]
        );
      } else {
        this.cellRendererParams = {};
      }
      if (!_.isNil(this.cellRendererFramework)) {
        this.cellRendererParams.innerRendererFramework = this.cellRendererFramework;
      }
      this.cellRendererParams.innerRenderer = this.cellRenderer;
      this.cellRenderer = 'agGroupCellRenderer';
    }
  }
  private notFloating (params: any): boolean {
    return !params.node.floating;
  }

  public getHeaderComponent(): any {
    return this.headerComponent;
  }

  private getFormattedCellComparator(): Function {
    return (filter, value, filterText) => {
      let formattedValue = this.valueFormatter(value).toLowerCase();
      let filterTextLowerCase = filterText.toLowerCase();
      switch (filter) {
        case 'contains': {
          return formattedValue.indexOf(filterTextLowerCase) >= 0;
        }
        case 'notContains': {
          return formattedValue.indexOf(filterTextLowerCase) === -1;
        }
        case 'equals': {
          return formattedValue === filterTextLowerCase;
        }
        case 'notEqual': {
          return formattedValue !== filterTextLowerCase;
        }
        case 'startsWith': {
          return formattedValue.indexOf(filterTextLowerCase) === 0;
        }
        case 'endsWith': {
          let index = formattedValue.lastIndexOf(filterTextLowerCase);
          return index >= 0 && index === (formattedValue.length - filterTextLowerCase.length);
        }
        default: {
          // should never happen
          console.warn('invalid filter type ' + filter);
          return false;
        }
      }
    };
  }

  public dateSortComparator(valueA: any, valueB: any, nodeA: RowNode, nodeB: RowNode, isInverted?: boolean): number {
    let returnValue: number = 0;

    if (nodeA.data.dirtyStatus === cad.DirtyStatus.NEW
      && nodeB.data.dirtyStatus !== cad.DirtyStatus.NEW) {
      returnValue = 1;
    } else if (nodeA.data.dirtyStatus !== cad.DirtyStatus.NEW
      && nodeB.data.dirtyStatus === cad.DirtyStatus.NEW) {
      returnValue = -1;
    } else {
      let valueAMissing: boolean = _.isNil(valueA) || valueA === '';
      let valueBMissing: boolean = _.isNil(valueB) || valueB === '';
      if (valueAMissing && valueBMissing) {
        returnValue = 0;
      }
      if (valueAMissing) {
        returnValue = -1;
      }
      if (valueBMissing) {
        returnValue = 1;
      }
      if (moment(valueA).isBefore(moment(valueB))) {
        returnValue = -1;
      } else if (moment(valueA).isAfter(moment(valueB))) {
        returnValue = 1;
      } else {
        returnValue = 0;
      }
    }
    return returnValue;
  }

  public numberSortComparator(valueA: any, valueB: any, nodeA: RowNode, nodeB: RowNode, isInverted?: boolean): number {


    let valueAMissing: boolean = !Utils.isNumeric(valueA);
    let valueBMissing: boolean = !Utils.isNumeric(valueB);

    if (valueAMissing && valueBMissing) {
      return 0;
    }
    if (valueAMissing) {
      return -1;
    }
    if (valueBMissing) {
      return 1;
    }
    if (parseFloat(valueA) < parseFloat(valueB)) {
      return -1;
    } else if (parseFloat(valueA) > parseFloat(valueB)) {
      return 1;
    } else {
      return 0;
    }
  }

  public numberFilterValueGetter(params: ValueGetterParams): any {
    let val: any;

    if (params && params.data && params.column) {
      val = _.get(params.data, params.column.getColId());
      return Utils.isNumeric(val)
        ? parseFloat(val)
        : val;
    }
    return null;
  }
}
