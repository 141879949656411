import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { CapacityReleaseIndex } from 'src/cad/administration/table-maintenance/capacity-release-index/shared/interfaces/capacity-release-index';
import ValidationResult = cad.ValidationResult;

@Injectable()
export class CapacityReleaseIndexApi {

  private readonly endpoint: string = 'administration/table-maintenance/cr-index';

  constructor(
    private apiHelper: ApiHelper) {
  }

  public getAll(): Observable<CapacityReleaseIndex[]> {
    return this.apiHelper.request(this.endpoint);
  }

  public saveCRIndexDetail(params: CapacityReleaseIndex[]): Observable<ValidationResult<CapacityReleaseIndex[]>> {
    return this.apiHelper.request(this.endpoint + '/save', { body: params });
  }

  public deleteCRIndexDetail(params: CapacityReleaseIndex): Observable<ValidationResult<CapacityReleaseIndex[]>> {
    return this.apiHelper.request(this.endpoint + '/delete', { body: params });
  }
}
