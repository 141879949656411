import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { Ribbon } from './ribbon';

import * as _ from 'lodash';

@Injectable()
export class RibbonService {

  public get ribbon$(): Observable<Ribbon> { return this.ribbonSubject.asObservable(); }

  private ribbonSubject: ReplaySubject<Ribbon> = new ReplaySubject<Ribbon>(1);
  private ribbons: Ribbon[] = [];

  public pushRibbon(ribbon: Ribbon): Function {
    if (ribbon) {
      const ribbonClone = _.clone(ribbon);
      this.ribbons.push(ribbonClone);
      this.ribbonSubject.next(ribbonClone);
      return () => this.popRibbon(ribbonClone);
    } else {
      return () => {};
    }
  }

  private popRibbon(ribbon: Ribbon): void {
    const last = _.last(this.ribbons) === ribbon;
    this.ribbons = _.reject(this.ribbons, ribbon) as Ribbon[];
    if (last) {
      this.ribbonSubject.next(_.last(this.ribbons));
    }
  }
}
