import {timer as observableTimer, Subscription, Subject } from 'rxjs';
import { Component, Injector, Type } from '@angular/core';
import { Action } from 'src/framing/action';
import { Controller } from 'src/framing/controller';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd } from '@angular/router';
import { CadAppModel } from './cad-app.model';
import { CadAppView } from './cad-app.view';
import { RouterService } from 'cad/core/services/router/router.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EmitterService } from 'src/cad/core/services/emitter/emitter.service';
import { NewVersionSnackbarComponent } from 'src/features/common/cad-app/view/new-version-snackbar/new-version-snackbar.component';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { ConfigService } from 'cad/core/services/config/config.service';
import { takeUntil, switchMap, retry } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'controller',
  template: '<ng-container [ngComponentOutlet]="view.app"></ng-container>',
})
export class CadAppController extends Controller<CadAppModel, CadAppView> {
  private readonly authPollingInterval: number = 300000;
  private readonly versionPollingInterval: number = 300000;
  private unSub: Subject<void> = new Subject<void>();
  private readonly headers: HttpHeaders = new HttpHeaders({
    Pragma: 'no-cache',
  });

  //not too sure why Amar converted this from ApiRequestOptionsArgs or why that interface was not updated.
  private readonly options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    quiet?: boolean;
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  } = {
    headers: this.headers,
    observe: 'response',
    responseType: 'text'
  };

  private routerEventsSub: Subscription;

  constructor(
    private configService: ConfigService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private router: RouterService,
    private apiHelper: ApiHelper,
    private httpClient: HttpClient,
    private emitterService: EmitterService,
    private matSnackBar: MatSnackBar,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy(); // mandatory
    this.unSub.next();
    this.unSub.complete();
    if (this.routerEventsSub) {
      this.routerEventsSub.unsubscribe();
    }
  }

  onControllerInit(): void {
    this.iconRegistry.addSvgIcon('cad-logo', this.sanitizer.bypassSecurityTrustResourceUrl(this.model.footerLogo));

    // TODO: MTE - This is part of the client source update notification feature that no longer works without the old webpack build that may need to be redesigned
    // this.initNewVersionPolling();

    this.initF5AuthenticationPolling();
  }

  initF5AuthenticationPolling(): void {
    // ask F5 not to invalidate our authentication
    observableTimer(this.authPollingInterval, this.authPollingInterval)
      .pipe(
        switchMap(() => this.httpClient.get(this.configService.cadConfig.secondaryUrl + this.configService.cadConfig.baseApiUrl
          + 'user-preference/active', this.options)),
        retry(5),
        takeUntil(this.unSub))
      .subscribe();
  }

  initNewVersionPolling(): void {
    let requestOptions: any = _.cloneDeep(this.options);
    delete requestOptions.observe;

    observableTimer(0, this.versionPollingInterval)
      .pipe(
        switchMap(() => this.httpClient.get('index.html', this.options)),
        retry(5),
        takeUntil(this.unSub))
      .subscribe((response) => {
        if (response && response.body && response.body.indexOf(document.querySelector('body script').outerHTML) === -1) {
          let sbRef = this.matSnackBar.openFromComponent(NewVersionSnackbarComponent, { duration:30000, panelClass: [ 'snackbar-party' ], });
          sbRef.instance.sbRef = sbRef;
        }
      });
  }

  @Action() public toggleSearchState(): void {
    this.model.isSearching = ! this.model.isSearching;
    this.emitterService.toggleSearch.next(this.model.isSearching);
  }

  @Action() public enableSearchState(): void {
    Promise.resolve().then(() => {
      this.model.isSearching = true;
      this.emitterService.toggleSearch.next(this.model.isSearching);
    });
  }

  @Action() public disableSearchState(): void {
    Promise.resolve().then(() => {
      this.model.isSearching = false;
      this.emitterService.toggleSearch.next(this.model.isSearching);
    });
  }

  @Action() public disableSaveButton(): void {
    this.model.disableSaveButton = true;
  }

  @Action() public enableSaveButton(): void {
    this.model.disableSaveButton = false;
  }

  @Action()
  public disableActionMenu(): void {
    this.model.disableActionMenu = true;
  }

  @Action()
  public enableActionMenu(): void {
    this.model.disableActionMenu = false;
  }

  @Action()
  public updateSearchView(searchView: Type<any>, searchInjector: Injector): void {
    this.view.search = searchView;
    this.view.searchInjector = searchInjector;
    this.addSubscriber();
  }

  private addSubscriber(): void {
    if (!this.routerEventsSub) {
      this.routerEventsSub = this.router.events.subscribe((event) => this.handleRouterEvent(event));
    }
  }

  private handleRouterEvent(event: any): void {
    if (event instanceof NavigationEnd) {
      this.disableSearchState();
    }
  }
}
