import { Pipe, PipeTransform } from '@angular/core';
import { RowActionItem } from 'src/ag-grid-wrapper/row/action-menu/action-item';

@Pipe({ name: 'disabledPipe', pure: false })
export class ActionMenuItemDisabledPipe implements  PipeTransform {
  transform(item: RowActionItem, params: any): boolean {
    if (!item.method) {
      // If there is no method then disable this item
      return true;
    }
    if ((item.isDisabled instanceof Function)) {
      return item.isDisabled(params.node.data);
    } else if (item.isDisabled) {
      return item.isDisabled;
    }
    return false;
  }
}
