import { Component, Input, Output, OnInit, EventEmitter, ContentChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'ui-option-detail',
  templateUrl: './option-detail.component.html',
  styleUrls: [ './option-detail.component.less' ],
  animations: [
    trigger('expandCollapseTrigger', [
      state('expanded', style({
        opacity: 1,
        transformOrigin: 'left top',
        transform: 'scaleY(1)'
      })),
      transition(':enter', [
        style({
          opacity: 0,
          transformOrigin: 'left top',
          transform: 'scaleY(0)'
        }),
        animate('0.4s cubic-bezier(0.25, 0.8, 0.25, 1)')
      ]),
      transition(':leave', [
        animate('0.4s cubic-bezier(0.25, 0.8, 0.25, 1)',
          style({
            opacity: 0,
            transform: 'scaleY(0)'
          }))
      ]),
    ]),
  ],
  exportAs: 'uiOptionDetail',
})
export class UiOptionDetailComponent implements OnInit {

  @Input('optionExpanded') expanded: boolean;
  @Input() optionItem: any;
  @Input('optionItemProperties') itemProperties: any[] = null;
  @Output() onExpansionChange: EventEmitter<any> = new EventEmitter<any>();
  @ContentChild(MatOption) matOptionRef: MatOption;

  ngOnInit(): void {
    if (this.optionItem && !_.isArray(this.itemProperties)) {
      this.itemProperties = _.keys(_.omit(this.optionItem, '$$hashKey'));
    }
  }

  toggleExpanded(): void {
    this.expanded = !this.expanded;
  }

  isExpanded(): boolean {
    return this.expanded;
  }

  onExpansionDone(): void {
    this.onExpansionChange.emit(this.expanded);
  }
}
