import { Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import * as _ from 'lodash';

@Component({
  moduleId: module.id + '',
  selector: 'table-column-click-action-renderer',
  templateUrl: './click-action-renderer.component.html'
})
export class TableColumnClickActionRenderer implements AgRendererComponent {
  
  public params: any;
  public cellValue: any;

  public agInit(params: any): void {
    this.params = params;
    this.setCellValue();
  }

  public onClick(event: any): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.params
      && this.params.column
      && this.params.column.getColDef()
      && this.params.node
      && _.isFunction(this.params.column.getColDef().clickAction)) {
      this.params.column.getColDef().clickAction(this.params.node.data);
    }
  }
  
  public refresh(params: any): boolean {
    this.params = params;
    this.setCellValue();
    return true;
  }
  
  private setCellValue(): void {
    this.cellValue = (this.params && !_.isNil(this.params.valueFormatted))
      ? this.params.valueFormatted
      : !_.isNil(this.params.value)
        ? this.params.value
        : '';
  }
}
